import React from "react";
import * as I from "../types";
import * as H from "../../../../hooks";
import * as C from "../../../../Common";
import { TB } from "../../../../Constants";

//#region Types
export type Props = {
    /** Should a non-boolean value be considered as a null */
    distinctFalseFromEmpty?: boolean;
}
//#endregion

export const sort = TB.sortBoolean;
export const type = "agCustomCheckBox";

export const Cell = React.forwardRef<{}, I.CellProps<Props>>((props, ref) => {

    const content = React.useMemo(() => {
        let value = props.getValue?.();
        let bool: null | boolean = null;
        let field = props.colDef?.field;
        let params = props.colDef?.params || {};

        // The current row is a group
        if (props.node.group) {
            if (params.show_grouped) {
                let count_true = 0, count_false = 0, count_empty = 0;
                props.node.allLeafChildren
                    .map(r => r.data?.[field])
                    .forEach(bool => {
                        if (typeof bool !== "boolean") count_empty++;
                        else bool ? count_true++ : count_false++;
                    });
                // Consider the empty as false
                if (!params.distinctFalseFromEmpty) {
                    count_false += count_empty;
                    count_empty = 0;
                }

                let show_data = [
                    { icon: "check", variant: "success", count: count_true },
                    { icon: "times", variant: "danger", count: count_false },
                    { icon: "question", variant: "dark", count: count_empty },
                ].filter(d => d.count > 0);

                if (show_data.length === 0) return null;
                else if (show_data.length === 1) return <i className={`fa fa-${show_data[0].icon} text-${show_data[0].variant}`} />;
                else return <C.Flex className="h-100 fs-100 fw-bold" justifyContent="around" alignItems="center">
                    {show_data.map(d => <span key={d.icon} className={`text-${d.variant}`}>
                        <i className={`fa fa-${d.icon} me-1`} />
                        {d.count}
                    </span>)}
                </C.Flex>;
            }
            // Don't show anything for a grouped row
            else bool = null;
        }
        // Value is already a boolean
        else if (typeof value === "boolean") bool = value;
        // Non-boolean value should not appear as 'false'
        else if (params.distinctFalseFromEmpty) bool = null;
        // Forced conversion to boolean
        else bool = !!value;

        if (bool === null) return null;
        else if (bool) return <i className="fa fa-check text-success" />;
        else return <i className="fa fa-times text-danger" />;
    }, [props]);

    return <div className="text-center fs-120 h-100" children={content} />;
});

export const EditCell = React.forwardRef<I.EditorRef<boolean>, I.EditorProps<Props>>((props, ref) => {
    const editing = H.useBoolean(true);
    const [value, set_value] = React.useState<boolean>(props.value);

    //#region Focus, Quit Editing && ref Forwarding
    React.useImperativeHandle(ref, () => ({ getValue: () => value }), [value]);
    React.useEffect(() => !editing.value && props.api.stopEditing(), [editing.value, props.api]);
    //#endregion

    React.useEffect(() => {
        // Need a timeout to allow us to cancel the edit from the grid
        setTimeout(() => {
            if (props.colDef?.params?.distinctFalseFromEmpty) set_value(p => typeof p === "boolean" ? (p ? false : null) : true);
            else set_value(p => !p);
            editing.setFalse();
        }, 5);
    }, [props.colDef, editing]);

    return null;
});