import React from "react";
import Button from "../Button";
import * as H from "../../../hooks";
import { Flex } from "../../Layout";
import * as R from "./QuickInputRow";
import { Modal as M } from "../../../Components";
import { InputGroup } from "react-bootstrap";
import { T, TB, TC } from "../../../Constants";

//#region Types
type RowData = Record<string, any>;

export type QuickInputProps<A extends RowData> = {
    /** Pre-filled rows */
    rows?: A[];
    /** The format of the columns */
    columns: R.Column<A>[];
    /** Validate the after every change */
    controlled?: boolean;
    /** Disable the functionality to add or delete rows */
    gel_rows?: boolean;
    /** Callback for data verification */
    onCheck?: (data: A[]) => null | T.Errors<A>[];
    /** Callback for data submission */
    onSubmit: (data: A[]) => void;
    /** Auto-focus on the first cell of the first row */
    no_init_focus?: boolean;
    /** Buttons to show besides the 'save' and 'addRow' buttons */
    footer?: React.ReactNode;
    /** Callback to quit the modal */
    onQuit?: () => void;
    /** Show the QuickInput Component in a modal ? */
    popup?: boolean;
    /** Style overrides for a modal component */
    modal?: M.StyleModalProps;
    /** A workaround to access the ref, when the component is displayed via the modal shortcut */
    modal_ref?: React.MutableRefObject<QuickInputRef<A>>;
};

export type QuickInputRef<A extends RowData> = {
    /** Callback to get the values */
    get: () => A[];
    /** Callback to set the values */
    set: React.Dispatch<React.SetStateAction<A[]>>;
};
//#endregion

const RenderQuickInput = <A extends RowData>(props: QuickInputProps<A>, ref: React.ForwardedRef<QuickInputRef<A>>) => {
    const isMobile = H.useIsMobile();
    const [data, setData] = React.useState<A[]>(props.rows || []);
    const rowsRef = React.useRef<React.MutableRefObject<R.RowRef<A>>[]>([]);
    const [errors, setErrors] = React.useState<ReturnType<QuickInputProps<A>["onCheck"]>>(null);

    //#region Handle the refs
    React.useImperativeHandle(ref, () => ({ get: () => data, set: setData }), [data]);
    React.useImperativeHandle(props.modal_ref, () => ({ get: () => data, set: setData }), [data]);
    //#endregion

    React.useEffect(() => {
        if (props.controlled) {
            setData(props.rows || []);
            props.onCheck?.(props.rows || []);
        }
    }, [props]);

    const setter = React.useCallback<typeof setData>(state => {
        if (props.controlled) {
            if (typeof state === "function") props.onSubmit?.(state(props.rows || []));
            else props.onSubmit?.(state);
        }
        else setData(state);
    }, [props]);

    const rows = React.useMemo(() => ({
        /** Add a new row */
        add: (value = {} as A) => setter(p => p.concat(value)),
        /** Update an existing row */
        update: (value: A, index: number) => {
            // Update values
            setter(p => p.map((r, i) => index === i ? value : r));
            // Update Errors
            setErrors(p => {
                if (!Array.isArray(p)) return p;
                return p.map((e, i) => i === index ? {} : e);
            });
        },
        /** Remove a row */
        remove: (index: number) => {
            // Update values
            setter(p => p.filter((r, i) => i !== index));
            // Update Errors
            setErrors(p => {
                if (!Array.isArray(p)) return p;
                return p.filter((e, i) => i !== index);
            });
        },
    }), [setter]);

    const submit = React.useCallback(() => {
        let errors: ReturnType<QuickInputProps<A>["onCheck"]> = null;

        if (typeof props.onCheck === "function") errors = props.onCheck(data);
        if (!Array.isArray(errors)) errors = [];
        let hasErrors = errors.some(e => TB.validObject(e) && Object.keys(e).length > 0);

        if (hasErrors) setErrors(errors);
        else {
            // Send back data
            props.onSubmit?.(data);
            // Reset data
            setData([]);
        }
    }, [props, data]);

    const set_row_ref = React.useCallback((ref: R.RowRef<A>, index: number) => {
        // Initialize a ref if none exists
        if (!rowsRef.current[index]) rowsRef.current[index] = React.createRef();
        rowsRef.current[index].current = ref;
    }, []);

    const set_focus = React.useCallback<R.RowProps<A>["focus_row"]>((row, cell) => {
        if (row > data.length - 1) {
            rows.add();
            setTimeout(() => rowsRef.current?.[row]?.current?.focus?.(cell), 5);
        }
        else rowsRef.current?.[row]?.current?.focus?.(cell);
    }, [data.length, rows]);

    const footer = React.useMemo(() => <Flex className="my-2" justifyContent="start" alignItems="center">
        <InputGroup>
            {!props.controlled && <Button icon="chevron-right" disabled={data.length === 0} onClick={submit} text={TC.GLOBAL_CONFIRM} variant="success" />}
            {!props.gel_rows && <Button icon="plus" onClick={() => rows.add()} text={TC.ADD} />}
            {props.footer}
        </InputGroup>
    </Flex>, [data.length, props.gel_rows, props.controlled, props.footer, rows, submit]);

    return React.createElement(
        props.popup ? M.BlankModal : React.Fragment,
        props.popup ? { ...props.modal, footer, onQuit: props.onQuit } as M.BlankModalProps : null,
        <>
            {!isMobile && <R.HeaderRow<A> gel_rows={props.gel_rows} columns={props.columns} add_button_col={data.length > 0} />}

            {data.map((d, i) => <R.Row<A>
                key={i}
                value={d}
                row_index={i}
                errors={errors?.[i]}
                focus_row={set_focus}
                columns={props.columns}
                gel_rows={props.gel_rows}
                add_row={() => rows.add()}
                remove={() => rows.remove(i)}
                ref={ref => set_row_ref(ref, i)}
                onChange={v => rows.update(v, i)}
                no_init_focus={props.no_init_focus}
            />)}

            {data.length === 0 && !props.gel_rows && <R.Row<A> columns={props.columns} onChange={rows.add} />}

            {!props.popup && footer}
        </>
    );
};

const QuickInput = React.forwardRef(RenderQuickInput) as <A extends RowData>(props: QuickInputProps<A> & { ref?: React.ForwardedRef<QuickInputRef<A>> }) => React.ReactElement;
export default QuickInput;