import React from "react";
import * as C from "../../../Common";
import { T, TB, TC } from "../../../Constants";
import { StyleModalProps, default as Modal } from "../BlankModal";

type NameMailValue = Record<"name" | "email", string>;

export type NameMailProps = {
    /** Callback for cancellation */
    quit?: () => void;
    /** Callback for confirmation */
    validate?: (values: NameMailValue) => void;
    /** Extra params to change the modal */
    modal?: StyleModalProps;
    /** Default value */
    value?: NameMailValue;
    /** Disable the mail input */
    disable_mail?: boolean;
}

const NameMail: React.FC<NameMailProps> = ({ validate, ...props }) => {
    const [errors, set_errors] = React.useState<T.Errors<NameMailValue>>({});
    const [values, set_values] = React.useState<NameMailValue>(props.value || { name: "", email: "" });

    const try_validate = React.useCallback(() => {
        let new_errors = {} as typeof errors;

        if (!TB.validString(values.name)) new_errors.name = TC.GLOBAL_REQUIRED_FIELD;
        if (!TB.validString(values.email)) new_errors.email = TC.GLOBAL_REQUIRED_FIELD;
        else if (!TB.validateMail(values.email)) new_errors.email = TC.REG_MAIL_INVALID;

        if (Object.keys(new_errors).length > 0) set_errors(new_errors);
        else validate?.(values);
    }, [values, validate]);

    const footer = React.useMemo(() => <C.Flex alignItems="end">
        <C.Button onClick={props.quit} className="mx-2" variant="danger" text={TC.GLOBAL_CANCEL} />
        <C.Button onClick={try_validate} text={TC.GLOBAL_CONFIRM} />
    </C.Flex>, [try_validate, props.quit]);

    return <Modal
        {...props.modal}
        footer={footer}
        onQuit={props.quit}
        size={props.modal?.size || "sm"}
        title={props.modal?.title || TC.MAIL_NAME_INFOS}
    >
        <C.Form.TextField
            required
            error={errors.name}
            value={values.name}
            label={TC.GLOBAL_NAME}
            onChange={name => set_values(p => ({ ...p, name }))}
        />

        <C.Form.TextField
            isEmail
            required
            error={errors.email}
            value={values.email}
            label={TC.TI_MAIL_LABEL}
            disabled={props.disable_mail}
            onChange={email => set_values(p => ({ ...p, email }))}
        />
    </Modal>;
}

const WrappedNameMail: React.FC<NameMailProps> = props => <C.ReduxWrapper children={<NameMail {...props} />} />;

export default WrappedNameMail;