/* /!\ Order needs to stay, otherwise socket doesn't work anymore */
import ReactDOM from 'react-dom';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import "./assets/scss/user.scss";
import "./assets/scss/theme.scss";
import "./Global Style/bootstrap-typeahead-styles.scss";
import './helpers/initFA';
import { Provider } from "react-redux";
import { store as async_store } from "./Redux";
import * as serviceWorker from "./serviceWorker";
import RouterSwitch from './Layouts/RouterSwitch';
import Main from './Main';

async_store.then(async store => {
  ReactDOM.render(<Provider store={store}>
    <Main>
      <RouterSwitch />
    </Main>
  </Provider>, document.getElementById("root"));

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
});