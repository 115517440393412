import * as S from "../../../services";
import { DndProvider } from "react-dnd";
import ActionsView from "./Actions_view";
import { Flex, Form, Button } from "../../../Common";
import { ActionItemProps } from "./ActionItem";
import { Row, Col } from "react-bootstrap";
import { useAuth, useRights } from "../../../hooks";
import { NavigateFunction } from "react-router-dom";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FP, RIGHTS, T, TB, TC } from "../../../Constants";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Alerts, askConfirm, BlankModal, BlankModalProps } from "../../Modal";

type Event = ReturnType<T.API.Actions.GetPlanner>["events"][number];
type Action = ReturnType<T.API.Actions.GetPlanner>["actions"][number];
type EventData = Omit<Parameters<T.API.Actions.UpdateEvent>[0]["event"], "id">;

export type EventFormProps = {
    /** Display the form as a popup */
    popUp?: boolean;
    /** An existing event to update */
    event: Event;
    /** A list of actions available for the event */
    actions: Action[];
    /** The options for the modal */
    modalProps?: Omit<BlankModalProps, "children" | "footer" | "showHeader">;
    /** A callback for when the event is saved */
    onSave?: (event?: Event) => void;
    /** Can't use useNavigate in this component, so use a callback instead */
    navigate?: NavigateFunction;
    /** A callback for the deletion of an event */
    onRemove?: (event: Event, actions: Action[]) => void;
}

const getEventData = (event: Event): EventData => {
    let calendar = TB.getArray(event?.calendar);
    if (calendar.length === 0) calendar.push({ end: "", start: "" });
    return {
        calendar,
        owner: TB.getString(event?.owner),
        title: TB.getString(event?.title),
        actions: TB.getArray(event?.actions)
    };
};

const EventForm: React.FC<EventFormProps> = ({ navigate, onSave, onRemove, ...props }) => {
    const rights = useRights();
    const [{ userId }] = useAuth();
    const [errors, setErrors] = useState<Partial<EventData>>({});
    const [eventData, setEventData] = useState<EventData>(getEventData(props.event));

    //#region Rights
    const hasEventWriteRight = useMemo(() => {
        if (props.event.owner === userId) return rights.isRightAllowed(RIGHTS.ACTION.WRITE_EVENTS);
        return rights.isRightAllowed(RIGHTS.ACTION.WRITE_OTHER_EVENTS);
    }, [rights, userId, props.event?.owner]);

    const ticketRights = useMemo(() => ({
        canCloseOwnReg: rights.isRightAllowed(RIGHTS.ACTION.WRITE_REG_ACTIONS),
        canCloseOtherReg: rights.isRightAllowed(RIGHTS.ACTION.WRITE_OTHER_REG_ACTION),
        canCloseOwnMaintenance: rights.isRightAllowed(RIGHTS.ACTION.WRITE_MAINTENANCE_ACTION),
        canCloseOtherMaintenance: rights.isRightAllowed(RIGHTS.ACTION.WRITE_OTHER_MAINTENANCE_ACTION),
    }), [rights]);

    const canCloseTickets = useMemo(() => eventData.actions.some(a => {
        if (a.type === "reg") {
            if (a.owner === userId) return ticketRights.canCloseOwnReg;
            else return ticketRights.canCloseOtherReg;
        }
        else {
            if (a.owner === userId) return ticketRights.canCloseOwnMaintenance;
            else return ticketRights.canCloseOtherMaintenance;
        }
    }), [eventData.actions, ticketRights, userId]);
    //#endregion

    //#region Form
    const editCalendar = useCallback((date: string, index: number, prop: "end" | "start") => {
        setEventData(p => ({
            ...p,
            calendar: p.calendar.map((c, i) => i === index ? { ...c, [prop]: date } : c),
        }));
        setErrors(p => ({
            ...p,
            calendar: TB.getArray(p.calendar).map((c, i) => i === index ? { ...c, [prop]: undefined } : c),
        }));
    }, []);

    const removeCalendarEntry = useCallback((index: number) => {
        setEventData(p => {
            let calendar = p.calendar.filter((x, i) => i !== index);
            if (calendar.length === 0) calendar = [{ start: '', end: "" }];
            return { ...p, calendar };
        });
        setErrors(p => ({
            ...p,
            calendar: TB.getArray(p.calendar).filter((c, i) => i !== index),
        }));
    }, []);

    const addCalendarEntry = useCallback(() => setEventData(p => ({ ...p, calendar: p.calendar.concat({ end: "", start: "" }) })), []);

    const form = useMemo(() => <div>
        <Row>
            <Col md={6}>
                <Form.TextField
                    label={TC.EVENT_NAME}
                    value={eventData.title}
                    error={{ code: errors.title }}
                    onChange={title => setEventData(p => ({ ...p, title }))}
                />
            </Col>
        </Row>
        <Row>
            {eventData.calendar.map((cal, iCal) => <React.Fragment key={iCal}>
                <Col md={1}>
                    <Flex className="w-100 h-100" alignItems="center" justifyContent="center">
                        <strong># {iCal + 1}</strong>
                    </Flex>
                </Col>
                <Col md={5}>
                    <Form.DateTime
                        enableTime
                        value={cal.start}
                        label={TC.EVENT_START}
                        error={{ code: errors.calendar?.[iCal]?.start }}
                        onChange={date => editCalendar(date, iCal, "start")}
                    />
                </Col>
                <Col md={5}>
                    <Form.DateTime
                        enableTime
                        value={cal.end}
                        label={TC.EVENT_END}
                        error={{ code: errors.calendar?.[iCal]?.end }}
                        onChange={date => editCalendar(date, iCal, "end")}
                    />
                </Col>
                <Col md={1}>
                    <Flex className="w-100 h-100" alignItems="center" justifyContent="center">
                        <Button variant="danger" icon={{ icon: "times" }} size="sm" onClick={() => removeCalendarEntry(iCal)} />
                    </Flex>
                </Col>
            </React.Fragment>)}
            <Col md={12}>
                <Flex alignItems="center" justifyContent="end">
                    <Button onClick={addCalendarEntry} icon={{ icon: "plus" }} />
                </Flex>
            </Col>
        </Row>
    </div>, [eventData.calendar, eventData.title, errors.title, errors.calendar, editCalendar, addCalendarEntry, removeCalendarEntry]);
    //#endregion

    //#region Actions
    const availableActions = useMemo(() => {
        let scheduled = eventData.actions.map(a => a.id);
        return props.actions.filter(a => !scheduled.includes(a.id));
    }, [eventData.actions, props.actions]);

    const onDropAction = useCallback((isScheduled: boolean, action: ActionItemProps["action"]) => {
        setEventData(p => {
            // Remove the action from the array, if it was in it
            if (!isScheduled) {
                if (p.actions.some(a => a.id === action.id)) return { ...p, actions: p.actions.filter(a => a.id !== action.id) };
                return p;
            }
            // Add the action if it was not already in it
            else {
                if (p.actions.some(a => a.id === action.id)) return p;
                return { ...p, actions: p.actions.concat(action) };
            }
        });
    }, []);

    //@ts-ignore
    const actionBox = useMemo(() => <DndProvider backend={HTML5Backend}>
        <Row className="mt-3">
            <Col md={6}>
                <ActionsView
                    hideSort
                    height={500}
                    drag="container"
                    actions={availableActions}
                    onDrop={action => onDropAction(false, action)}
                />
            </Col>
            <Col md={6}>
                <ActionsView
                    hideSort
                    height={500}
                    drag="container"
                    actions={eventData.actions}
                    onDrop={action => onDropAction(true, action)}
                />
            </Col>
        </Row>
    </DndProvider>, [eventData.actions, availableActions, onDropAction]);
    //#endregion

    //#region Save
    useEffect(() => {
        if (errors.title && eventData.title) setErrors(p => ({ ...p, title: undefined }));
    }, [errors.title, eventData.title]);

    const existingEvent = useMemo(() => TB.mongoIdValidator(props.event?.id), [props.event?.id]);

    const save = useCallback(() => {
        let newErrors = {} as typeof errors;
        if (!TB.validString(eventData.title)) newErrors.title = TC.GLOBAL_REQUIRED_FIELD;

        eventData.calendar.forEach((cal, iCal) => {
            let [start, end] = [cal.start, cal.end].map(date => TB.getDate(date));
            let [hasStart, hasEnd] = [start, end].map(date => !!date);

            if (!(hasStart && hasEnd)) {
                if (!newErrors.calendar) newErrors.calendar = [];
                newErrors.calendar[iCal] = {};
                if (!hasStart) newErrors.calendar[iCal].start = TC.GLOBAL_REQUIRED_FIELD;
                if (!hasEnd) newErrors.calendar[iCal].end = TC.GLOBAL_REQUIRED_FIELD;
            }
            else if (start.getTime() > end.getTime()) {
                if (!newErrors.calendar) newErrors.calendar = [];
                newErrors.calendar[iCal] = { start: TC.EVENT_ERROR_START_HIGHER_END, end: TC.EVENT_ERROR_END_LOWER_START };
            }
        });

        if (Object.keys(newErrors).length > 0) setErrors(newErrors);
        else S.updateEvent({ event: { ...eventData, id: props.event.id } })
            .then(({ data }) => onSave?.(data))
            .catch(Alerts.updateError);
    }, [props.event.id, eventData, onSave]);

    const remove = useCallback(() => {

        const removeActionTooPromise = new Promise<boolean>((resolve, reject) => {
            // Sure to delete ?
            askConfirm().then(confirmed => {
                if (confirmed) {
                    let hasAction = props.event.actions.length > 0;
                    if (!hasAction) resolve(false);
                    // Ask if remove actions too
                    else askConfirm({ text: TC.EVENT_DELETE_ALSO_ACTIONS, noText: TC.GLOBAL_NO, yesText: TC.GLOBAL_YES }).then(confirmed2 => {
                        if (typeof confirmed2 === "boolean") resolve(confirmed2);
                        else reject();
                    });
                }
                else reject();
            });
        });

        removeActionTooPromise.then(removeActions => {
            S.removeEvents({ ids: props.event.id, removeActions }).then(({ data }) => {
                onRemove(props.event, removeActions ? [] : props.event.actions);
            }).catch(Alerts.deleteError);
        })
            // User cancelled
            .catch(() => { });
    }, [props.event, onRemove]);

    const closeTickets = useCallback(() => navigate?.(`/ticket/event/${props.event.id}`, { replace: true }), [navigate, props.event.id]);

    const footer = useMemo(() => <Flex className="w-100" alignItems="center" justifyContent="between">
        {hasEventWriteRight && existingEvent && <Button variant="danger" onClick={remove}>
            {TC.GLOBAL_DELETE}
        </Button>}
        {canCloseTickets && existingEvent && <Button onClick={closeTickets}>
            {TC.EVENT_CLOSE}
        </Button>}
        {hasEventWriteRight && <Button onClick={save}>
            {TC.GLOBAL_SAVE}
        </Button>}
    </Flex>, [hasEventWriteRight, canCloseTickets, existingEvent, save, closeTickets, remove]);
    //#endregion

    if (props.popUp) return <BlankModal
        {...props.modalProps}
        footer={footer}
        onQuit={() => onSave?.()}
        size={props.modalProps?.size || "md"}
        maxBodyHeight={props.modalProps?.maxBodyHeight || "75vh"}
        title={TB.getString(props.modalProps?.title, FP.EVENTS_FORM)}
    >
        {form}
        {actionBox}
    </BlankModal>

    return <>
        {form}
        {actionBox}
    </>;
}

export default EventForm;