import React from "react";
import Select from "./Select";
import NumField from "./NumFields";
import { Button } from "../../Item";
import { Flex } from "../../Layout";
import * as H from "../../../hooks";
import { T, TB, TC } from "../../../Constants";
import { ComponentWrapper, InputProps } from "../Input";

export type FrequencyProps = InputProps & {
    /** Removes the reset button */
    noReset?: boolean;
    /** Possible extra unit of time */
    extraUnit?: T.AllowArray<"h" | "m">;
};

type Freq = ReturnType<typeof TB.splitFrequency>;

const UNITS = [
    { label: TC.GP_GROUP_DAY, value: "d" },
    { label: TC.GP_GROUP_WEEK, value: "w" },
    { label: TC.GP_GROUP_MONTH, value: "M" },
    { label: TC.GP_GROUP_YEAR, value: "y" },
];

const EXTRA_UNITS = [
    { label: TC.GP_GROUP_SINGLE_MINUTE, value: "m" },
    { label: TC.GP_GROUP_HOUR, value: "h" },
];

const Frequency: React.FC<FrequencyProps> = ({ onChange, ...props }) => {
    const lg = H.useLanguage();
    const frequency = React.useMemo(() => TB.splitFrequency(props.value), [props.value]);
    const isDisabled = React.useMemo(() => props.disabled || props.noOverride, [props.disabled, props.noOverride]);

    const tr_freq = React.useMemo(() => {
        let tr_freq = TB.translate_frequency(props.value);
        if (tr_freq?.ref) {
            if (tr_freq.amount === 1) return `1x / ${lg.getStaticText(tr_freq.ref)}`;
            else return `1x / ${tr_freq.amount} ${lg.getStaticText(tr_freq.ref)}`;
        }
        else return null;
    }, [lg, props.value]);

    const options = React.useMemo(() => {
        if (!props.extraUnit) return UNITS;
        let extra_units = TB.arrayWrapper(props.extraUnit) as string[];

        let extra_options = [];
        for (let option of EXTRA_UNITS) {
            if (extra_units.includes(option.value)) extra_options.push(option);
        }
        return extra_options.concat(UNITS);
    }, [props.extraUnit]);

    const changeFrequency = React.useMemo(() => ({
        num: (num?: Freq["num"]) => {
            if (typeof num !== "number" || num < 1) onChange?.("1" + (frequency?.unit || "d"));
            else onChange?.(num + (frequency?.unit || "d"));
        },
        unit: (unit?: Freq["unit"]) => {
            if (!unit) unit = frequency?.unit || "d";
            let num = typeof frequency?.num === "number" ? frequency.num.toString() : "1";
            onChange?.(num + unit);
        },
        reset: () => onChange?.(""),
    }), [onChange, frequency]);

    return <ComponentWrapper {...props} disabled={isDisabled}>
        <Flex direction="column">
            <Flex>
                <NumField
                    noBottomMargin
                    disabled={isDisabled}
                    value={frequency?.num}
                    readonly={props.readonly}
                    onChange={changeFrequency.num}
                />
                <Select
                    noBottomMargin
                    options={options}
                    disabled={isDisabled}
                    value={frequency?.unit}
                    readonly={props.readonly}
                    customClass="flex-grow-1"
                    onChange={changeFrequency.unit}
                    typeahead={{ hideClearButton: true }}
                />
                {!props.noReset && <Button icon="times" size="sm" variant="danger" onClick={changeFrequency.reset} />}
            </Flex>
            {tr_freq && <div className="text-end" children={tr_freq} />}
        </Flex>
    </ComponentWrapper>
}

export default Frequency;