import React from "react";
import * as M from "../Modal";
import * as H from "../../hooks";
import * as C from "../../Common";
import * as S from "../../services";
import * as BS from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import InnerHtml from "dangerously-set-html-content";
import { PANELS, TABS, TB, TC } from "../../Constants";

const PortfolioIdCard: React.FC = () => {
    const lg = H.useLanguage();
    const isDark = H.useDark();
    const rights = H.useRights();
    H.useCrumbs(TC.GLOBAL_PORTFOLIO);
    const [searchParams] = useSearchParams();
    H.useAuth({ tabName: TABS.TAB_PORTFOLIO });
    const [year, set_year] = React.useState("now");
    const [html, set_html, status] = H.useAsyncState("");
    const [{ portfolio, roots }, , setRoots] = H.useRoots();

    //#region Roots
    const urlRoots = React.useMemo(() => searchParams.get("roots"), [searchParams]);
    const urlPortfolio = React.useMemo(() => searchParams.get("portfolio"), [searchParams]);

    React.useEffect(() => {
        let multiRoots = TB.getString(urlRoots).split(",");
        if (TB.mongoIdValidator(urlPortfolio)) setRoots({ portfolio: urlPortfolio });
        else if (TB.multiMongoIdValidator(multiRoots) && multiRoots.length > 0) setRoots({ roots: multiRoots });
        return () => setRoots();
    }, [urlPortfolio, urlRoots, setRoots]);
    //#endregion

    //#region Resource
    const restrictedAccess = React.useMemo(() => !rights.isPanelAllowed(PANELS.PORTFOLIO_GRAPHS_ENERGY), [rights]);

    const getDashboardHtml = React.useCallback((showLoader = true) => {
        let isSubscribed = true;
        let unmount: (Function | null) = null;
        if (!TB.mongoIdValidator(portfolio) && roots.length === 0) set_html("", "error");
        else if (rights.loaded) {
            if (showLoader) unmount = M.renderLoader();

            let report_params = {
                year: year,
                lang: lg.prop,
                id: portfolio || "",
                restricted: restrictedAccess,
                roots: TB.arrayWrapper(roots).join() || "",
            } as any;

            if (year !== "now") {
                let year_num = parseInt(year);
                report_params.from = new Date("01/01/" + year).toISOString();
                report_params.to = new Date("01/01/" + (year_num + 1)).toISOString();
            }

            // S.html_report({ template: "PortfolioDEV", params: report_params })
            S.html_report({ template: "Portfolio", params: report_params })
                .then(({ data }) => {
                    if (isSubscribed) {
                        if (TB.validString(data)) set_html(data, "done");
                        else set_html("", "error");
                    }
                })
                .catch(() => isSubscribed && set_html("", "error"))
                .finally(() => unmount?.());
        }

        return () => { isSubscribed = false };
    }, [portfolio, roots, restrictedAccess, year, rights.loaded, lg.prop, set_html]);

    const htmlVariables = React.useMemo(() => [
        { code: "@dark@", value: isDark ? "true" : "false" },
    ], [isDark]);

    const updatedHtml = React.useMemo(() => {
        if (!TB.validString(html)) return "";
        let updated = html;
        for (let { code, value } of htmlVariables) updated = TB.replaceStringPart(updated, code, value);
        return updated;
    }, [html, htmlVariables]);

    React.useEffect(() => {
        getDashboardHtml(false);
        return () => set_html("", "load");
    }, [getDashboardHtml, set_html]);
    //#endregion

    //#region Year Selector
    const years = React.useMemo(() => {
        let options: C.ButtonSelectProps["options"] = [{ value: "now", label: lg.getStaticText(TC.PORTFOLIO_LAST_YEAR), variant: "primary" }];
        let now_year = new Date().getFullYear(), limit_year = 2016;

        while (now_year >= limit_year) {
            let year_str = now_year.toString();
            options.push({ value: year_str, label: year_str, variant: "primary" });
            now_year--;
        }
        return options;
    }, [lg]);

    React.useEffect(() => set_html("", "load"), [year, set_html]);
    //#endregion

    return <C.Spinner status={status} min_load_size="300px">
        <div className="w-100 position-relative">
            <BS.Row className="mb-3">
                <BS.Col md={4}>
                    <C.ButtonSelect
                        size="sm"
                        value={year}
                        options={years}
                        default_value="now"
                        onChange={set_year}
                    />
                </BS.Col>
            </BS.Row>
            <InnerHtml className="w-100 h-100" html={updatedHtml} />
        </div>
    </C.Spinner>
}

export default PortfolioIdCard;