import React from 'react';
import { useDispatch } from 'react-redux';
import ProfileDropdown from './ProfileDropdown';
import LanguageDropdown from "./LanguageDropdown";
// import AppContext from '../../../Context/Context';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Nav/* , Tooltip, OverlayTrigger  */ } from 'react-bootstrap';
import { ADD_PORTAL, REMOVE_PORTAL } from '../../../reducers/Portals';

const TopNavRightSideNavItem = () => {
  const dispatch = useDispatch();
  const navBarTopFreeSpaceRef = React.useRef<HTMLDivElement | null>(null);
  // const { config: { isDark, isRTL }, setConfig } = React.useContext(AppContext);

  /* const toolTip = React.useCallback((props) => <Tooltip {...props} id="ThemeColor">
    {isDark ? 'Switch to light theme' : 'Switch to dark theme'}
  </Tooltip>, [isDark]); */

  React.useEffect(() => {
    dispatch(ADD_PORTAL("topNavBar", navBarTopFreeSpaceRef))
    return () => { dispatch(REMOVE_PORTAL("topNavBar")) };
  }, [dispatch]);

  return <Nav navbar className="navbar-nav-icons ms-auto flex-row align-items-center" as="ul" >
    <div ref={navBarTopFreeSpaceRef}></div>

    <LanguageDropdown />
    <div className='px-2' />

    {/* <Nav.Item as={'li'}>
      <Nav.Link className="px-2 theme-control-toggle" onClick={() => setConfig('isDark', !isDark)} >
        <OverlayTrigger key="left" placement={isRTL ? 'bottom' : 'left'} overlay={toolTip} >
          <div className="theme-control-toggle-label">
            <FontAwesomeIcon icon={isDark ? 'sun' : 'moon'} className="fs-0" />
          </div>
        </OverlayTrigger>
      </Nav.Link>
    </Nav.Item> */}

    <ProfileDropdown />
  </Nav>
};

export default TopNavRightSideNavItem;