import React from "react";
import * as M from "../Modal";
import * as H from "../../hooks";
import * as C from "../../Common";
import * as S from "../../services";
import * as R from "../../reducers";
import { TC } from "../../Constants";
import * as BS from "react-bootstrap";
import { useDispatch } from "react-redux";

const Profile: React.FC = () => {
  H.useCrumbs(TC.PROFILE);
  const [{ user }] = H.useAuth();
  const dispatch = useDispatch();


  const updateTreeView = React.useCallback(() => {
    S.toggleUserMenuView().then(({ data }) => {
      dispatch(R.setUser({ ...user, data: { ...user.data, hideUnavailable: data } }));
    }).catch(M.Alerts.updateError);
  }, [dispatch, user]);

  const change_pwd = React.useCallback(() => {
    S.reset_password()
      .then(() => M.renderAlert({ type: "info", message: TC.PWD_CHANGE_MAIL_SENT }))
      .catch(M.Alerts.loadError);
  }, []);

  return <div>
    <header className="jumbotron">
      <h3>
        <strong>{user?.data?.name}</strong>
      </h3>
    </header>

    <div>
      <p><strong>Email:</strong> {user?.data?.email || " -"}</p>
    </div>

    <BS.ButtonGroup>
      <C.Button
        icon="edit"
        onClick={change_pwd}
        text={TC.REG_EDIT_PASSWORDS}
      />

      <C.Button
        variant="secondary"
        onClick={updateTreeView}
        text={TC.PROFILE_UNACCESSIBLE_VIEW}
      />
    </BS.ButtonGroup>
  </div>;
};

export default Profile;