import React from 'react';
import * as H from '../../hooks';
import * as BS from 'react-bootstrap';

//#region Types
type TitleProps = {
    /** The header tag additional custom classes */
    className?: string;
    /** Extra content to be displayed under the header tag */
    children?: React.ReactNode;
    /** The reference of the text in the header */
    titleTag?: React.ReactNode;
    /** Title tag breakpoint's size */
    breakPoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
};

type FalconCardHeaderProps = {
    /** The reference to the text content of the header */
    title: string;
    /** Use the 'light' mode, opposite to 'dark' mode */
    light?: boolean;
    /** Extra classes for the Card Header */
    className?: string;
    /** Extra classes for the Title component */
    titleClass?: string;
    /** Extra content to be shown under the title */
    children?: React.ReactNode;
    /** The content to show next to the title */
    endEl?: React.ReactElement;
    /** Content to be shown in the title, a reference or React content */
    titleTag?: React.ReactNode;
    /** Title tag breakpoint's size */
    breakPoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
};
//#endregion

export const Title: React.FC<TitleProps> = props => {
    const lg = H.useLanguage();

    const classes = React.useMemo(() => {
        let classes = [] as string[];
        if (!props.breakPoint) classes.push("mb-0");
        else classes.push(`mb-${props.breakPoint}-0`);
        if (props.className) classes.push(props.className);
        return classes.join(" ");
    }, [props]);

    return <>
        <h5 className={classes}>
            {typeof props.titleTag === "string" ? lg.getStaticText(props.titleTag) : props.titleTag}
        </h5>
        {props.children}
    </>
};

export const FalconCardHeader: React.FC<FalconCardHeaderProps> = props => {

    const classes = React.useMemo(() => {
        let classes = [] as string[];
        if (props.light) classes.push("bg-light");
        if (props.className) classes.push(props.className);
        return classes.join(" ");
    }, [props]);

    return <BS.Card.Header className={classes}>
        {props.endEl ? <BS.Row className="align-items-center">
            <BS.Col>
                <Title breakPoint={props.breakPoint} titleTag={props.titleTag} className={props.titleClass} >
                    {props.title}
                </Title>
                {props.children}
            </BS.Col>
            <BS.Col {...{ [props.breakPoint || 'xs']: 'auto' }} className={`text${props.breakPoint ? `-${props.breakPoint}` : ''}-right`} >
                {props.endEl}
            </BS.Col>
        </BS.Row>
            : <Title breakPoint={props.breakPoint} titleTag={props.titleTag} className={props.titleClass}>
                {props.title}
            </Title>}
    </BS.Card.Header>
};