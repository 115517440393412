import './modal.scss';
import React from "react";
import * as H from '../../hooks';
import * as C from '../../Common';
import renderInContainer from './getContainer';

type LoaderProps = {
    isPopUp?: boolean;
    message?: string | null;
    lowerZIndex?: boolean;
}

const Loader: React.FC<LoaderProps> = ({ message = null, isPopUp = true, lowerZIndex = false }) => {
    const lg = H.useLanguage();

    const messageClassName = React.useMemo(() => "loader_message" + (isPopUp ? "" : "_inline"), [isPopUp]);
    const modalLoaderStyle = React.useMemo(() => lowerZIndex ? { zIndex: 1000 } : undefined, [lowerZIndex]);
    const messageSpan = React.useMemo(() => message && <span className={messageClassName} children={lg.getStaticText(message)} />, [message, messageClassName, lg]);

    return isPopUp
        ? <div className="loader_bg_modal" style={modalLoaderStyle}>
            {messageSpan}
            <div className="loader" />
        </div>
        : <div className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: "5rem" }}>
            {messageSpan}
            <div className="loader inline-loader" />
        </div>;
}

const WrappedLoader: React.FC<LoaderProps> = ({ ...props }) => <C.ReduxWrapper children={<Loader {...props} />} />;
export default WrappedLoader;

export const renderLoader = (message?: string) => {
    let [render, dismount] = renderInContainer(undefined, 1004);
    if (render && dismount) {
        render(<WrappedLoader message={message} />);
        return dismount;
    }
}