import React from "react";
import IdCard from "./IDCard";
import { Spinner } from "../../Common";
import Portfolio from "./PortfolioIdCard";
import { useAsyncState, useRoots } from "../../hooks";

type TypeCard = "portfolio" | "id_card";

const HomeCard: React.FC = props => {
    const [roots] = useRoots();
    const [type_card, set_type_card, type_card_status] = useAsyncState<TypeCard>();
    React.useEffect(() => set_type_card("id_card", "done"), [roots, set_type_card]);

    return <Spinner status={type_card_status}>
        {type_card === "id_card" ? <IdCard /> : <Portfolio />}
    </Spinner>
}

export default HomeCard;