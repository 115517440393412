import moment, { Moment } from "moment";
import { getDate, splitFrequency } from "../ToolBox";

type DateTypes = Date | Moment | string;

export const DAILY = "J";
export const WEEKLY = "H";
export const WEEKLY_2 = "2H";
export const MONTHLY = "M";
export const BI_MONTHLY = "2M";
export const TRI_MONTHLY = "T";
export const SIX_MONTHLY = "S";
export const ANNUALLY = "A";
export const ANNUALLY_2 = "2A";
export const ANNUALLY_3 = "3A";
export const ANNUALLY_4 = "4A";
export const ANNUALLY_5 = "5A";
export const ANNUALLY_8 = "8A";
export const ANNUALLY_10 = "10A";
export const ANNUALLY_15 = "15A";
export const UNSPECIFIED = "SN";

//#region Utils
export const IS_FREQUENCY_CODE_NO_SN = (freq: any): freq is string => splitFrequency(freq) !== null;

export const DATE_RANGE_PER_FREQUENCY = (date: DateTypes, frequency: string, forkPercentage = 1) => {
    let validDate = getDate(date);
    if (validDate === null || typeof forkPercentage !== "number") return null;
    if (forkPercentage <= 0) forkPercentage = 1;
    else if (forkPercentage >= 100) forkPercentage = 99;

    let freq = splitFrequency(frequency);
    if (!freq) return null;

    let nextDate = moment(date).add(freq.num, freq.unit);
    let duration = moment.duration(freq.num * forkPercentage, freq.unit);

    return {
        nextDate: nextDate.toISOString(),
        end: moment(nextDate).add(duration.asDays(), 'd').toISOString(),
        start: moment(nextDate).subtract(duration.asDays(), 'd').toISOString(),
    }
}

export const addSubstFrequency = (frequency: string, date: DateTypes, isAdd = true) => {
    let okDate = getDate(date), freq = splitFrequency(frequency);
    if (okDate === null || freq === null) return null;
    if (isAdd) return moment(okDate).add(freq.num, freq.unit).toISOString();
    else return moment(okDate).subtract(freq.num, freq.unit).toISOString();
}

export const frequencyToMilliseconds = (frequency: string) => {
    let freq = splitFrequency(frequency);
    if (freq === null) return -1;
    return moment.duration(freq.num, freq.unit).asMilliseconds();
}
//#endregion

//#region Not OK
export const FREQUENCY_OPTIONS = [
    { value: DAILY, label: "Journalier" },
    { value: WEEKLY, label: "Hebdomadaire" },
    { value: WEEKLY_2, label: "BiHebdomadaire" },
    { value: MONTHLY, label: "Mensuel" },
    { value: TRI_MONTHLY, label: "Trimestriel" },
    { value: SIX_MONTHLY, label: "Semestriel" },
    { value: ANNUALLY, label: "Annuel" },
    { value: ANNUALLY_2, label: "Tout les 2 ans" },
    { value: ANNUALLY_3, label: "Tout les 3 ans" },
    { value: ANNUALLY_4, label: "Tout les 4 ans" },
    { value: ANNUALLY_5, label: "Tout les 5 ans" },
    { value: ANNUALLY_8, label: "Tout les 8 ans" },
    { value: ANNUALLY_10, label: "Tout les 10 ans" },
    { value: ANNUALLY_15, label: "Tout les 15 ans" },
    { value: UNSPECIFIED, label: "Non Spécifié" },
];

export const LOW_FREQUENCY_OPTIONS = [
    { value: DAILY, label: "Journalier" },
    { value: WEEKLY, label: "Hebdomadaire" },
    { value: WEEKLY_2, label: "BiHebdomadaire" },
    { value: MONTHLY, label: "Mensuel" },
    { value: TRI_MONTHLY, label: "Trimestriel" },
    { value: SIX_MONTHLY, label: "Semestriel" },
];
//#endregion