import React from 'react'
import { TC } from "../Constants";
import * as M from "../Components/Modal";

type UseCopy = (params?: {
    /** Show an alert in case of success/failure ? */
    alert?: boolean | "success" | "failure";
}) => [(text: string, event?: React.MouseEvent) => Promise<boolean>, string | null];

const useCopyToClipboard: UseCopy = params => {
    const [copiedText, setCopiedText] = React.useState<ReturnType<UseCopy>[1]>(null);

    const alerts = React.useMemo(() => ({
        success: params?.alert === "success" || params?.alert === true,
        failure: params?.alert === "failure" || params?.alert === true,
    }), [params?.alert]);

    const copy: ReturnType<UseCopy>[0] = React.useCallback(async (text, event) => {
        if (!navigator?.clipboard) return false;
        // Prevent default event action
        if (event) event.preventDefault?.();
        // Try to save to clipboard then save it in the state if worked
        try {
            await navigator.clipboard.writeText(text);
            setCopiedText(text);
            if (alerts.success) M.renderAlert({ type: "success", message: TC.GLOBAL_COPIED });
            return true;
        }
        catch (error) {
            setCopiedText(null);
            if (alerts.failure) M.renderAlert({ type: "error", message: TC.GLOBAL_ERROR });
            return false;
        }
    }, [alerts]);

    return [copy, copiedText]
}

export default useCopyToClipboard