import React from "react";

export type CRVAProps = {}

const CRVA: React.FC<CRVAProps> = () => {
    return <div>
        CRVA
    </div>;
};

export default CRVA;