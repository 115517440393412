import _ from "lodash";
import { T, TB } from "../../Constants";
import * as Redux from "../../reducers";
import { v4 as uuid, validate } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";

//#region Types
type UpdateStore = (context: Partial<T.FormContext>) => void;
type UseFormProviderResults = [string, T.FormContext, UpdateStore, Record<string, any>];
//#endregion

const getDefaultContext = (): T.FormContext => ({
    data: {},
    hidden: [],
    certif: [],
    errors: {},
    options: [],
    updates: [],
    readOnly: false,
    descriptions: [],
    hideAddButton: [],
    partial_certif: [],
    previous_edits: [],
});

const useFormProvider = (init?: T.FormContext): UseFormProviderResults => {
    const dispatch = useDispatch();
    const [key, setKey] = useState("");
    const [waiting, setWaiting] = useState<Partial<T.FormContext>>(null);
    const contextStore = useSelector((state: T.ReduxSelector) => state.formContext);

    useEffect(() => {
        // Create a context and store the key
        if (!validate(key)) {
            let k = uuid();
            dispatch(Redux.setFormStore({ key: k, context: init || getDefaultContext() }));
            setKey(k);
        }
        else if (TB.validObject(init)) dispatch(Redux.updateFormStore({ key, context: init }));
    }, [dispatch, key, init]);

    useEffect(() => {
        // Update the last state that was queued if key is not ready
        if (validate(key) && waiting) {
            dispatch(Redux.updateFormStore({ key, context: waiting }));
            setWaiting(null);
        }
    }, [dispatch, key, waiting]);

    const updateStore = useCallback<UpdateStore>(context => {
        if (!TB.validObject(context)) return;
        else if (!key) setWaiting(context);
        else dispatch(Redux.updateFormStore({ key, context }));
    }, [dispatch, key]);

    const context = useMemo<T.FormContext>(() => contextStore.filter(s => s.key === key)[0]?.context || getDefaultContext(), [contextStore, key]);

    useEffect(() => {
        return () => {
            if (validate(key)) dispatch(Redux.removeFormStore(key));
        }
    }, [dispatch, key]);

    const fullData = useMemo(() => {
        let data = _.cloneDeep(context.data);
        for (let u of context.updates) _.set(data, u.prop, u.value);
        return data;
    }, [context.data, context.updates]);

    return [key, context, updateStore, fullData];
};

export default useFormProvider;