import Form from "./form";
import React from "react";
import moment from "moment";
import * as M from "../Modal";
import * as H from "../../hooks";
import * as C from "../../Common";
import * as S from "../../services";
import * as BS from "react-bootstrap";
import { Skeleton } from "@mui/material";
import { FP, RESOURCE, T, TB, TC } from "../../Constants";

export type NoteManagerProps = {
    /** The _id of origin to find a note */
    origin: string;
    /** A default value for the "subject" property */
    defaultSubject?: string;
    /** Callback when a note is created on the origin _id */
    on_new_note?: (note: T.Submission<T.NoteData>) => void;
    /** Callback when a note is deleted on the origin _id */
    on_delete_note?: (id: string) => void;
};

const TEXT_CODES = [
    TC.NOTES_CREATED_THE, TC.NOTES_EDITED_THE, TC.NOTES_BY, TC.NOTE_SHOW_X_REPLIES, TC.NOTE_HIDE_REPLIES, FP.NOTES_PATH,
    TC.TEMPLATE_NEW_ITEM_NEW_F
];

const NoteManager: React.FC<NoteManagerProps> = ({ origin, defaultSubject, on_new_note, on_delete_note }) => {
    const lg = H.useLanguage(TEXT_CODES);
    const hasUsedDefaultSubject = H.useBoolean(false);
    const addNote = H.useBoolean(TB.validString(defaultSubject));
    const [showResponses, setShowResponses] = React.useState<string[]>([]);
    const [notes, setNotes, noteStatus] = H.useAsyncState<T.API.Form.Notes[]>([]);

    //#region Language
    React.useEffect(() => {
        let ids: string[] = [];

        const recursive = (note: typeof notes[number]) => {
            ids.push(note.note._id);
            ids.push(note.note.owner);
            ids.push(...note.note.data.tagsData.map(t => t._id));
            for (let reply of note.responses) recursive(reply);
        }

        notes.forEach(recursive);
        if (ids.length > 0) lg.fetchObjectTranslations(ids);
    }, [notes, lg]);
    //#endregion

    //#region Fetch Notes
    React.useEffect(() => {
        let isSubscribed = true;

        if (TB.mongoIdValidator(origin)) S.getNotesHistory({ origin })
            .then(({ data }) => isSubscribed && setNotes(data, "done"))
            .catch(() => setNotes([], "error"));
        else setNotes([], "done");

        return () => { isSubscribed = false };
    }, [origin, setNotes]);
    //#endregion

    //#region Skeleton & Error
    const skeleton = React.useMemo(() => [...new Array(5)].map((x, i) => <Skeleton key={i} variant="rectangular" height={"25px"} />), []);

    const banner = React.useMemo(() => {
        if (noteStatus === "load") return skeleton;
        if (noteStatus === "error") return <C.ErrorBanner type="danger" textCode={TC.GLOBAL_FAILED_LOAD} />;
        return null;
    }, [noteStatus, skeleton]);
    //#endregion

    //#region Callbacks
    const events = React.useMemo(() => ({
        save_new: (note?: T.Submission<T.NoteData>) => {
            if (note) S.getNotesHistory({ notes: note._id })
                .then(({ data }) => {
                    if (TB.validString(defaultSubject)) hasUsedDefaultSubject.setFalse();
                    if (note.data.origin === origin) on_new_note?.(note);
                    setNotes(p => p.concat(data));
                    addNote.setFalse();
                })
                .catch(M.Alerts.updateError);
        },
        edit: (id: string, parent?: string) => {
            M.renderFormModal<T.NoteData>({ path: FP.NOTES_PATH, modalProps: { size: "sm" }, submissionId: id }).then(note => {
                if (note) S.getNotesHistory({ notes: note._id })
                    .then(({ data }) => setNotes(p => {
                        if (TB.mongoIdValidator(parent)) return p.map(parentNote => {
                            if (parentNote.note._id !== parent) return parentNote;
                            return {
                                ...parentNote,
                                responses: parentNote.responses.map(n => data.filter(dbN => dbN.note._id === n.note._id)[0] || n)
                            }
                        });
                        else return p.map(n => data.filter(dbN => dbN.note._id === n.note._id)[0] || n);
                    }))
                    .catch(M.Alerts.updateError);
            });
        },
        reply: (origin: string) => {
            let submission = [{ prop: "origin", value: origin }];
            M.renderFormModal<T.NoteData>({
                path: FP.NOTES_PATH,
                title: TC.NOTE_NEW_REPLY,
                modalProps: { size: "sm" },
                forcedSubmission: submission,
            }).then(reply => {
                if (reply) S.getNotesHistory({ notes: origin })
                    .then(({ data }) => setNotes(p => p.map(n => data.filter(dn => dn.note._id === n.note._id)[0] || n)))
                    .catch(M.Alerts.updateError);
            });
        },
        remove: (id: string, parent?: string) => {
            M.askConfirm().then(confirmed => {
                if (confirmed) S.removeNote(id).then(() => {
                    if (!parent) on_delete_note?.(id);
                    setNotes(p => {
                        if (parent) return p.map(n => {
                            if (n.note._id !== parent) return n;
                            return { ...n, responses: n.responses.filter(rn => rn.note._id !== id) }
                        });
                        else return p.filter(n => n.note._id !== id);
                    });
                }).catch(M.Alerts.deleteError);
            });
        },
        toggle_responses: (id: string) => setShowResponses(p => {
            if (!p.includes(id)) return p.concat(id);
            else return p.filter(n => n !== id);
        })
    }), [setNotes, on_delete_note, on_new_note, hasUsedDefaultSubject, addNote, defaultSubject, origin]);
    //#endregion

    //#region History Presentation
    const renderDate = React.useCallback((created: string, edited: string, by: typeof notes[number]["note"]["data"]["ownerData"]) => {
        let c = lg.getStaticText(TC.NOTES_CREATED_THE, moment(created).format("DD/MM/YY HH:mm"));
        let e = "";
        if (created !== edited) e = lg.getStaticText(TC.NOTES_EDITED_THE, moment(edited).format("DD/MM/YY HH:mm"));

        let owner = lg.getTextObj(by._id, by.prop, by.label);
        let b = lg.getStaticText(TC.NOTES_BY, owner);

        return [c + " " + b, e].filter(TB.validString).join(", ");
    }, [lg]);

    const openFile = React.useCallback((file: T.File, files: T.File[]) => {
        let images = files.filter(f => f.type.includes("image")).map(f => ({ title: f.originalName, src: f.url }));

        if (file.type.includes("image")) M.renderCarousel({ images, defaultSrc: file.url });
        else window.open(file.url, "_blank");
    }, []);

    const renderNote = React.useCallback((note: typeof notes[number], parent = null) => <BS.ListGroup.Item key={note.note._id}>
        <div className="ms-2 me-auto">
            <C.Flex alignItems="center" justifyContent="between">
                <div>
                    <span className="fw-bold fs-110">
                        {lg.getTextObj(note.note._id, "subject", note.note.data.subject)}
                    </span>
                    <span className="ms-2 text-muted fs-85">
                        {renderDate(note.note.created, note.note.modified, note.note.data.ownerData)}
                    </span>
                </div>
                <BS.ButtonGroup size="sm">
                    <BS.Button variant="secondary" onClick={() => events.edit(note.note._id, parent)}>
                        <i className="fa fa-pencil-alt"></i>
                    </BS.Button>
                    {!parent && <BS.Button variant="primary" onClick={() => events.reply(note.note._id)}>
                        <i className="fa fa-reply"></i>
                    </BS.Button>}
                    <BS.Button variant="danger" onClick={() => events.remove(note.note._id, parent)}>
                        <i className="fa fa-times"></i>
                    </BS.Button>
                </BS.ButtonGroup>
            </C.Flex>
            {lg.getTextObj(note.note._id, "note", note.note.data.note)}
        </div>

        <C.Flex className="my-2">
            {note.note.data.files.map(f => <BS.Figure className="me-2 text-center pointer" onClick={() => openFile(f, note.note.data.files)} key={f.name}>
                <BS.Figure.Image width={40} height={80} src={RESOURCE.IMAGE_BASED_ON_TYPE(f.type, f.url)} />
                <BS.Figure.Caption>
                    {f.originalName}
                </BS.Figure.Caption>
            </BS.Figure>)}
        </C.Flex>

        <C.Flex className="mt-2" alignItems="center" justifyContent={note.responses.length === 0 ? "end" : "between"}>
            {note.responses.length > 0 && <button onClick={() => events.toggle_responses(note.note._id)} className="btn-link btn-none">
                {showResponses.includes(note.note._id)
                    ? <>
                        <i className="fa fa-caret-up me-2"></i>
                        {lg.getStaticText(TC.NOTE_HIDE_REPLIES)}
                    </>
                    : <>
                        <i className="fa fa-caret-down me-2"></i>
                        {lg.getStaticText(TC.NOTE_SHOW_X_REPLIES, note.responses.length)}
                    </>}
            </button>}

            <C.Flex wrap="wrap">
                {note.note.data.tagsData.map(t => <BS.Badge className="ms-1" key={t._id} bg="primary" pill>
                    {lg.getElemObj(t._id, t.prop, t.label)}
                </BS.Badge>)}
            </C.Flex>
        </C.Flex>

        {!parent && <BS.Collapse in={showResponses.includes(note.note._id)}>
            <div className="mt-2">
                {note.responses.map(n => renderNote(n, note.note._id))}
            </div>
        </BS.Collapse>}
    </BS.ListGroup.Item>, [lg, showResponses, renderDate, events, openFile]);

    const noteHistory = React.useMemo(() => <BS.ListGroup className="my-3">
        {notes.map(n => renderNote(n))}
        <BS.ListGroup.Item>
            <C.Flex justifyContent="end">
                <BS.Button onClick={addNote.setTrue}>
                    {lg.getStaticText(TC.TEMPLATE_NEW_ITEM_NEW_F, FP.NOTES_PATH)}
                </BS.Button>
            </C.Flex>
        </BS.ListGroup.Item>
    </BS.ListGroup>, [renderNote, notes, addNote, lg]);
    //#endregion

    //#region Form
    const showHistory = React.useMemo(() => notes.length === 0 || addNote.value, [notes.length, addNote.value]);

    const forcedSubmission = React.useMemo(() => {
        let defaultSub = [{ prop: "origin", value: origin }];
        if (TB.validString(defaultSubject) && !hasUsedDefaultSubject.value) defaultSub.push({ prop: "subject", value: defaultSubject });
        return defaultSub;
    }, [hasUsedDefaultSubject.value, defaultSubject, origin]);
    //#endregion

    return <>
        {banner}
        {!banner && <>
            {showHistory
                ? <div className="p-3">
                    <Form<T.NoteData>
                        path={FP.NOTES_PATH}
                        onSave={events.save_new}
                        forcedSubmission={forcedSubmission}
                    />
                </div>
                : noteHistory
            }
        </>}
    </>;
}

export default NoteManager;