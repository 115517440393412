import React from 'react';
import { TC } from '../../Constants';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../hooks';

const TEXT_CODES = [TC.GLOBAL_HOME, TC.LAYOUT_UNAVAILABLE];

const Unavailable: React.FC = props => {
    const lg = useLanguage(TEXT_CODES, true);

    return <Card className="text-center">
        <Card.Body className="p-5">
            <div className="display-1 text-danger fs-error">
                <i className='fa fa-ban'></i>
            </div>
            <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
                {lg.getStaticElem(TC.LAYOUT_UNAVAILABLE)}
            </p>
            <Link className="btn btn-primary btn-sm mt-3" to="/">
                <i className='fa fa-home me-2'></i>
                {lg.getStaticElem(TC.GLOBAL_HOME)}
            </Link>
        </Card.Body>
    </Card>
};

export default Unavailable;