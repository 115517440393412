import { Dropdown } from 'react-bootstrap';
import { TC, T, LANG } from "../../../Constants";
import { useLanguage } from "../../../hooks";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useMemo, useState } from 'react';
import { getFlagResources } from "../../../services/user.service";
import { setLanguage } from '../../../reducers';

type Resource = {
    forms: T.FormType[];
    languages: T.LanguageType[];
    loadingState: "done" | "error" | "load";
}

const TEXT_CODES = [TC.GLOBAL_FAILED_LOAD];

const LanguageDropdown = ({ ...props }) => {
    const dispatch = useDispatch();
    const { getStaticElem } = useLanguage(TEXT_CODES);
    const { language } = useSelector((redux: T.ReduxSelector) => redux);
    const [{ languages, loadingState }, setLanguages] = useState<Resource>({ languages: [], forms: [], loadingState: "load" });

    //#region Fetch resources
    useEffect(() => {
        let isSubscribed = true;
        getFlagResources().then(({ data }) => {
            if (isSubscribed) {
                if (data?.hasFailed) setLanguages(p => ({ ...p, loadingState: "error" }));
                else setLanguages({ languages: data?.flags, forms: data?.forms, loadingState: "done" });
            }
        });

        return () => { isSubscribed = false };
    }, []);
    //#endregion

    //#region User
    const completedLanguages = useMemo(() => LANG.ALL_LANGUAGES.map(({ code, lg, index }) => {
        let icon = languages.filter(l => l.data.code === code)[0]?.data?.icon?.[0]?.url;
        return { icon, code, lg, index };
    }), [languages]);

    const flagsItem = useMemo(() => <>
        {completedLanguages.map(l => <Dropdown.Item className={l.index === language ? "active" : ""} key={l.code} onClick={() => dispatch(setLanguage(l.index))}>
            <img className='w-25 me-2' alt={l.code} title={l.lg} src={l.icon} />
            <span className='text-wrap'>{l.lg}</span>
        </Dropdown.Item>)}
    </>, [completedLanguages, dispatch, language]);

    const loadItem = useMemo(() => <Dropdown.Item className="text-center">
        <i className="fa fa-spinner fa-spin"></i>
    </Dropdown.Item>, []);

    const errorItem = useMemo(() => <div className="alert alert-danger m-1">
        {getStaticElem(TC.GLOBAL_FAILED_LOAD)}
    </div>, [getStaticElem]);

    const currentLanguage = useMemo(() => completedLanguages.filter(l => l.index === language)[0], [completedLanguages, language]);
    //#endregion

    return <Dropdown navbar as="div">
        <Dropdown.Toggle bsPrefix="toggle" as="div" className="pe-0 ps-2 nav-link pointer" >
            <div className="p-1 border rounded bg-white">
                <img style={{ width: "30px" }} src={currentLanguage.icon} title={currentLanguage.lg} alt={currentLanguage.code} />
                <i className='fa fa-chevron-down ms-2'></i>
            </div>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-caret dropdown-menu-card dropdown-menu-end">
            <div className="bg-white rounded-2 py-2 dark__bg-1000">
                {loadingState === "done" && flagsItem}
                {loadingState === "load" && loadItem}
                {loadingState === "error" && errorItem}
            </div>
        </Dropdown.Menu>
    </Dropdown>
};

export default LanguageDropdown;