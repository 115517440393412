import React from "react";
import { T, TB } from "../../../Constants";
import { useLanguage } from "../../../hooks";
import { Tab, Tabs as TabsBootStrap } from "react-bootstrap";
import { InputProps, Input, ComponentWrapper } from "../Input";

export type TabsProps = InputProps & {
    components?: {
        label?: string;
        key?: string;
        components?: T.FormComponentsMain[];
    }[];
}

const Tabs: React.FC<TabsProps> = ({ components, onChange, ...props }) => {
    const lg = useLanguage();
    const defaultKey = React.useMemo(() => components?.[0]?.key, [components]);
    const vComponents = React.useMemo(() => TB.getArray(components).filter(c => TB.validString(c?.key)), [components]);

    return <ComponentWrapper {...props} label={null}>
        <TabsBootStrap className="nav-fill" mountOnEnter defaultActiveKey={defaultKey} >
            {vComponents.map(tab => <Tab key={tab.key} eventKey={tab.key} title={lg.getStaticText(tab.label)} >
                <div className="p-2 border rounded border-2">
                    {TB.getArray(tab.components).map((c, i, a) => <Input
                        {...c}
                        prop={c.key}
                        formId={props.formId}
                        lock_form={props.lock_form}
                        contextKey={props.contextKey}
                        unlock_form={props.unlock_form}
                        submissionId={props.submissionId}
                        noBottomMargin={i === a.length - 1}
                        onAddCertification={props.onAddCertification}
                        onChange={(v, p) => onChange?.(v, p || c.key)}
                    />)}
                </div>
            </Tab>)}
        </TabsBootStrap>
    </ComponentWrapper>
}

export default Tabs;