import { T } from '../Constants';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';
import { RESET_CRUMBS, REPLACE_CRUMBS } from "../reducers";

const useCrumbs = (label: string, url?: string) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const crumb = useMemo(() => ({ link: url || pathname, label }), [label, url, pathname]);

    useEffect(() => {
        dispatch(RESET_CRUMBS({ crumb }));
        return () => { dispatch(RESET_CRUMBS()) };
    }, [crumb, dispatch]);

    const clearCrumbs = useCallback(() => dispatch(RESET_CRUMBS()), [dispatch]);
    const resetCrumbs = useCallback(() => dispatch(RESET_CRUMBS({ crumb })), [dispatch, crumb]);
    const updateCrumbs = useCallback((crumb: T.CrumbsRedux, level: number, keepChild?: boolean) => dispatch(REPLACE_CRUMBS({ crumb, level, keepChild })), [dispatch]);

    return { updateCrumbs, resetCrumbs, clearCrumbs };
}

export default useCrumbs;