import _ from "lodash";
import { T, TB } from "../../Constants";
import { CellsTypes } from "./AgGridDefs";
import * as AGCommunity from "ag-grid-community";

//#region Custom Type Interfaces
interface ParamsObj extends T.AnyObject {
    format?: string;
};

interface AgGridColumnProps extends AGCommunity.ColDef {
}
interface AgGridColumnGroupProps extends AGCommunity.ColGroupDef {
}

export interface ExtendedColumnProps extends AgGridColumnProps { params?: ParamsObj };
export interface ExtendedFilterParams extends AGCommunity.IFilterParams { colDef: ExtendedColumnProps };
export interface ExtendedICellRendererParams extends AGCommunity.ICellRendererParams { colDef: ExtendedColumnProps }
//#endregion

//#region Custom Types
type ColumnSingleType = ExtendedColumnProps;
export type ColumnType = ColumnSingleType | ColumnGroupType;
interface ColumnGroupType extends Omit<AgGridColumnGroupProps, "children"> { children: ColumnSingleType[] };

export type ContextMenuItem = ContextMenuStringItems | ContextMenuCustomItem;
type ContextMenuStringItems = 'autoSizeAll' | 'separator' | 'expandAll' | 'contractAll' | 'copy' | 'copyWithHeaders' | 'copyWithGroupHeaders' | 'paste' | 'resetColumns' | 'export' | 'csvExport' | 'excelExport' | 'chartRange';

type ContextMenuCustomItem = {
    name: string;
    action?: () => void;
    icon?: string;
    cssClasses?: string[];
    tooltip?: string;
    disabled?: boolean;
    subMenu?: ContextMenuItem[];
    shortcut?: string;
    checked?: boolean;
}
//#endregion

//#region Type Guards
const isValidSingleColumn = (obj: any): obj is ColumnSingleType => TB.validObject(obj) && (TB.validString(obj.colId) || TB.validString(obj.field)) && !Array.isArray(obj.children);
const isValidColumn = (obj: any): obj is ColumnType => [isValidSingleColumn, isValidGroupColumn].some(fn => fn(obj));
const isValidGroupColumn = (obj: any): obj is ColumnGroupType => TB.validObject(obj) && Array.isArray(obj.children) && obj.children.every(isValidSingleColumn);
//#endregion

//#region Column Updater
type updateParameters = {
    remove?: boolean;
    params?: T.AnyObject;
    nonEditable?: boolean;
    renderParams?: T.AnyObject;
    formatFn?: AGCommunity.ValueFormatterFunc;
    valueGetter?: AGCommunity.ValueGetterFunc;
    filterFormatFn?: AGCommunity.ValueGetterFunc;
};

type HeaderGetterFn = (code?: string, field?: string, params?: Record<string, any>) => string;
interface updateObj extends updateParameters { field: string | string[] };
type updateCellParamsFn = (columns: ColumnType[], updates: updateObj[], headerValueGetter?: HeaderGetterFn) => ColumnType[];

const TYPE_PROVIDING_FILTERS = [CellsTypes.TYPE_DATE, CellsTypes.TYPE_COLOR, CellsTypes.TYPE_NUMBER];

export const updateCellParams: updateCellParamsFn = (columns, updates, headerValueGetter) => {
    if (!Array.isArray(columns)) return [];
    if (!Array.isArray(updates)) return columns;

    let objectItems = [];
    updates
        .filter(upd => TB.validObject(upd) && (TB.validString(upd.field) || TB.isArrayString(upd.field)))
        /* @ts-ignore */
        .forEach(({ field, ...updates }) => objectItems = objectItems.concat(Array.isArray(field) ? field.map(f => [f, updates]) : [[field, updates]]));

    let grouped = _.groupBy(objectItems, elem => elem?.[0]);
    /* @ts-ignore */
    let paramsObj = Object.fromEntries(Object.entries(grouped).map(([field, array]) => [field, _.merge(...array.map(a => a[1]))]));

    const editItems = (col: ColumnSingleType) => {
        let newCol = _.clone(col);
        let parameters = paramsObj[col.field ?? ""];
        let { params, formatFn, filterFormatFn, valueGetter, renderParams, remove, nonEditable }: updateParameters = TB.validObject(parameters) ? parameters : {};

        if (remove) return null;
        else {
            if (TB.validObject(renderParams)) newCol.params = renderParams;
            if (TB.validObject(params)) newCol.cellEditorParams = params;
            if (nonEditable) newCol.editable = false;
            if (typeof formatFn === "function") newCol.valueFormatter = formatFn;
            if (typeof valueGetter === "function") newCol.valueGetter = valueGetter;
            if (typeof filterFormatFn === "function") newCol.filterValueGetter = filterFormatFn;
            if (typeof headerValueGetter === "function") newCol.headerValueGetter = a => headerValueGetter(a.colDef.headerName, col.field, col.params);
            if (newCol.filter === true && TYPE_PROVIDING_FILTERS.includes(TB.getString(newCol.type) || "")) newCol.filter = undefined;
            return newCol;
        }
    }

    return columns.map(col => {
        if (isValidSingleColumn(col)) return editItems(col);
        else if (isValidGroupColumn(col)) return {
            ...col,
            children: col.children.map(editItems).filter(isValidColumn),
            headerValueGetter: typeof headerValueGetter === "function" ? a => headerValueGetter?.(a.colDef.headerName) : () => col.headerName,
        };
        else return null;
    }).filter(isValidColumn);
}
//#endregion