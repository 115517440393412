import React from "react";
import * as H from "../../hooks";
import { TB } from "../../Constants";
import * as BS from "react-bootstrap";

export type TipContainerProps = {
    /** The content of the tooltip */
    tipContent?: string | React.ReactNode;
    /** The element that should trigger the tooltip */
    children?: React.ReactElement;
    /** Also use the focus trigger, for keyboard-only users */
    use_focus_trigger?: boolean;
}

const TipContainer: React.FC<TipContainerProps> = props => {
    const lg = H.useLanguage();

    const content = React.useMemo(() => {
        if (typeof props.tipContent === "string") return lg.getStaticText(props.tipContent);
        return props.tipContent;
    }, [props.tipContent, lg]);

    const renderToolTip = React.useCallback((p) => {
        return <BS.Tooltip {...p} className={TB.getString(p.className) + " z-index-higher-modal"} children={content} />;
    }, [content]);

    const trigger = React.useMemo<BS.OverlayTriggerProps["trigger"]>(() => {
        if (props.use_focus_trigger) return ["hover", "focus"];
        return "hover";
    }, [props.use_focus_trigger]);

    if (!content) return props.children;
    else return <BS.OverlayTrigger overlay={renderToolTip} trigger={trigger} children={props.children} />;
}

export default TipContainer;