import React from "react";
import * as Form from "../../Form";
import * as H from "../../../hooks";
import { Flex } from "../../Layout";
import * as BS from "react-bootstrap";
import { TC } from "../../../Constants";
import { default as Button } from "../Button";
import * as M from "../../../Components/Modal";

//#region Types
type IgnoredValue = {
    /** The property where the value can be found */
    prop: string;
    /** The value deemed ignorable */
    value: string;
    /** A label of the property */
    label: string;
    /** Should the value be ignored ? */
    ignored: boolean;
};

export type ValueIgnorerProps = {
    /** The values that are deemed 'ignorable' */
    values: IgnoredValue[];
    /** Callback for when the user is done */
    onSave: (values: IgnoredValue[]) => void;
    /** Should this be rendered in a popup ? */
    popup?: boolean;
    /** Extra modal parameters */
    modal?: M.StyleModalProps;
    /** Callback for the closure of the modal */
    onQuit?: () => void;
}
//#endregion

const ValueIgnorer: React.FC<ValueIgnorerProps> = props => {
    const lg = H.useLanguage();
    const [values, set_values] = React.useState(props.values || []);
    React.useEffect(() => set_values(props.values || []), [props.values]);

    const toggle = React.useCallback((prop: string, value: string, ignored?: boolean) => {
        if (typeof ignored == "boolean") set_values(p => p.map(v => {
            if (v.prop !== prop || v.value !== value) return v;
            return { ...v, ignored };
        }));
    }, []);

    const content = React.useMemo(() => <BS.Row className="m-1">
        {values.map(v => <BS.Col md={4} key={v.prop + v.value} className="border">
            <Flex direction="column">
                <div>
                    {lg.getStaticText(TC.IMPORT_IGNORE_VALUES_PROP)} : {v.label}
                </div>
                <div>
                    {lg.getStaticText(TC.IMPORT_IGNORE_VALUES_VAL)} : {v.value}
                </div>
                <div>
                    <Form.RadioBool
                        noBottomMargin
                        value={v.ignored}
                        labelPosition="top"
                        name={v.prop + v.value}
                        label={TC.IMPORT_IGNORE_VALUES_IGNORE}
                        onChange={i => toggle(v.prop, v.value, i)}
                    />
                </div>
            </Flex>
        </BS.Col>)}
    </BS.Row >, [values, lg, toggle]);

    const footer = React.useMemo(() => <Flex justifyContent="end">
        <Button
            icon="save"
            text={TC.GLOBAL_CONFIRM}
            onClick={() => props.onSave?.(values)}
        />
    </Flex>, [props, values]);

    if (props.popup) return <M.BlankModal
        {...props.modal}
        footer={footer}
        onQuit={props.onQuit}
        size={props.modal?.size || "md"}
        title={props.modal?.title || TC.PARASITE_VALUES}
    >
        {content}
    </M.BlankModal>;
    return <div>
        {content}
        <div className="mt-2">
            {footer}
        </div>
    </div>;
}

export default ValueIgnorer;