import _ from "lodash";
import React from "react";
import * as M from "../Modal";
import * as H from "../../hooks";
import * as C from "../../Common";
import * as S from "../../services";
import { T, TC } from "../../Constants";
import { FormCheck } from "react-bootstrap";
import { ComponentWrapper } from "../../Common/Form";
import { TreeItem, TreeView } from "@material-ui/lab";

//#region Types
type RoleFormProps = {
    role?: T.AccessRole;
    readOnly?: boolean;
    onSave?: (role?: T.AccessRole) => void;
    /** Show the properties that are 'special' */
    show_special?: boolean;
    /** The pre-loaded list of packages */
    packages?: T.PCK.Package[];
    /** The pre-loaded list of user's access */
    userAccesses?: Selected;
}

type Requirements = T.PCK.Right["requires"];
type Selected = T.API.Access.FlatAccessRole;
type SelectedProps = keyof T.API.Access.FlatAccessRole;
//#endregion

//#region Constants
const getRole = (role?: T.AccessRole): T.AccessRole => {
    if (role) return role;
    return {
        _id: "",
        name: "",
        owner: "",
        packages: [],
        createdAt: "",
        updatedAt: "",
        description: "",
        isGeneric: false,
    }
};

const getInitSelected = (): Selected => ({ packages: [], pages: [], panels: [], rights: [] });

const TEXT_CODES = [TC.GLOBAL_SAVE, TC.GLOBAL_CANCEL];
//#endregion

const RoleForm: React.FC<RoleFormProps> = ({ onSave, readOnly, ...props }) => {
    const [{ isAdmin }] = H.useAuth();
    const isSaving = H.useBoolean(false);
    const lg = H.useLanguage(TEXT_CODES);
    const [selected, setSelected] = React.useState(getInitSelected());
    const [role, setRole] = React.useState<T.AccessRole>(getRole(props.role));
    const [packages, setPackages, pck_status] = H.useAsyncState<T.PCK.Package[]>([]);
    const [userAccesses, setUserAccesses, access_status] = H.useAsyncState(getInitSelected());

    const status = React.useMemo(() => [pck_status, access_status], [pck_status, access_status]);
    const inputState = React.useMemo(() => ({ disabled: isSaving.value || readOnly, readOnly }), [isSaving.value, readOnly]);

    //#region Set Defaults
    React.useEffect(() => {
        if (Array.isArray(props.role?.packages)) {
            let selection: Selected = { packages: [], pages: [], panels: [], rights: [] };
            for (let pck of props.role.packages) {
                if (pck?.code) selection.packages.push(pck.code);

                if (Array.isArray(pck.rights)) {
                    for (let right of pck.rights) selection.rights.push(right);
                }

                if (Array.isArray(pck.pages)) for (let page of pck.pages) {
                    if (page?.code) selection.pages.push(page.code);
                    if (Array.isArray(page.panels)) for (let panel of page.panels) {
                        if (panel?.code) selection.panels.push(panel.code);
                        if (Array.isArray(panel?.rights)) for (let right of panel.rights) {
                            if (right) selection.rights.push(right);
                        }
                    }
                }
            }
            setSelected(selection);
        }
    }, [props.role]);
    //#endregion

    //#region Fetch User Accesses
    React.useEffect(() => {
        let isSubscribed = true;
        if (props.userAccesses) setUserAccesses(props.userAccesses, "done");
        else S.getAllUserAccesses()
            .then(({ data }) => isSubscribed && setUserAccesses(data, "done"))
            .catch(() => isSubscribed && setUserAccesses(getInitSelected(), "error"));
        return () => {
            isSubscribed = false;
            setUserAccesses(getInitSelected(), "load");
        };
    }, [setUserAccesses, props.userAccesses]);
    //#endregion

    //#region Fetch Data Packages
    React.useEffect(() => {
        let isSubscribed = true;
        if (props.packages) setPackages(props.packages, "done");
        else S.getPackages()
            .then(({ data }) => isSubscribed && setPackages(data, "done"))
            .catch(() => isSubscribed && setPackages([], "error"));
        return () => {
            isSubscribed = false;
            setPackages([], "load");
        };
    }, [setPackages, props.packages]);
    //#endregion

    //#region Flatten Packages
    const allPages = React.useMemo(() => _.flatten(packages.map(p => p.pages)), [packages]);
    const allPanels = React.useMemo<T.PCK.Tab[]>(() => _.flattenDeep(packages.map(p => p.pages.map(pg => pg.tabs))), [packages]);
    //#endregion

    //#region Toggle Selection
    const requirementsDictionary = React.useMemo(() => {
        let dictionary = {} as Record<string, Selected>;

        const updateDictionary = (key: string, prop: string, type: SelectedProps) => {
            if (!dictionary[key]) dictionary[key] = getInitSelected();
            if (!dictionary[key][type].includes(prop)) dictionary[key][type].push(prop);
        };

        const handleRequirements = (prop: string, type: SelectedProps, requires?: Requirements) => {
            if (requires) {
                if (requires.packages) requires.packages.forEach(key => updateDictionary(key, prop, type));
                if (requires.pages) requires.pages.forEach(key => updateDictionary(key, prop, type));
                if (requires.panels) requires.panels.forEach(key => updateDictionary(key, prop, type));
                if (requires.rights) requires.rights.forEach(key => updateDictionary(key, prop, type));
            }
        }

        for (let pck of packages) {
            for (let page of pck.pages) {
                for (let panel of page.tabs) {
                    handleRequirements(panel.code, "panels", panel.requires);
                    for (let right of panel.rights) {
                        handleRequirements(right.code, "rights", right.requires);
                    }
                }
            }

            for (let right of pck.rights) {
                handleRequirements(right.code, "rights", right.requires);
            }
        }

        return dictionary;
    }, [packages]);

    const findRelated = React.useCallback((code: string, prop: SelectedProps): Partial<Selected> => {
        if (prop === "packages") {
            let pack = packages.filter(pck => pck.code === code)[0];
            if (!pack) return {};
            return {
                packages: [code],
                pages: pack.pages.map(t => t.code),
                panels: _.flatten(pack.pages.map(p => p.tabs.map(t => t.code))),
                rights: _.flattenDeep(pack.pages.map(p => p.tabs.map(t => t.rights.map(r => r.code)))),
            }
        }
        else if (prop === "pages") {
            let page = allPages.filter(p => p.code === code)[0];
            if (!page) return {};
            return {
                pages: [code],
                panels: page.tabs.map(t => t.code),
                rights: _.flatten(page.tabs.map(t => t.rights.map(r => r.code))),
            }
        }
        else if (prop === "panels") {
            let panel = allPanels.filter(tab => tab.code === code)[0];
            if (!panel) return {};
            return { panels: [code], rights: panel.rights.map(r => r.code) };
        }
        else return { rights: [code] };
    }, [allPages, allPanels, packages]);

    const toggleCode = React.useCallback((code: string, prop: SelectedProps) => {
        setSelected(p => {
            let isRemove = p[prop].includes(code);
            if (isRemove) {
                let toRemoveHeired = findRelated(code, prop);
                let toRemoveConditional = requirementsDictionary[code];

                let toRemove = {
                    packages: _.concat(toRemoveHeired.packages || [], toRemoveConditional?.packages || []),
                    pages: _.concat(toRemoveHeired.pages || [], toRemoveConditional?.pages || []),
                    panels: _.concat(toRemoveHeired.panels || [], toRemoveConditional?.panels || []),
                    rights: _.concat(toRemoveHeired.rights || [], toRemoveConditional?.rights || []),
                };

                return {
                    packages: toRemove.packages ? p.packages.filter(c => !toRemove.packages.includes(c)) : p.packages,
                    pages: toRemove.pages ? p.pages.filter(c => !toRemove.pages.includes(c)) : p.pages,
                    panels: toRemove.panels ? p.panels.filter(c => !toRemove.panels.includes(c)) : p.panels,
                    rights: toRemove.rights ? p.rights.filter(c => !toRemove.rights.includes(c)) : p.rights,
                }
            }
            else return { ...p, [prop]: p[prop].concat(code) };

        })
    }, [findRelated, requirementsDictionary]);
    //#endregion

    //#region Pre-requisite
    const checkPrerequisite = React.useCallback((requires?: Requirements) => {
        if (!requires) return true;
        let passPkg = requires.packages ? requires.packages.every(pck => selected.packages.includes(pck)) : true;
        let passPages = requires.pages ? requires.pages.every(page => selected.pages.includes(page)) : true;
        let passPanels = requires.panels ? requires.panels.every(panel => selected.panels.includes(panel)) : true;
        let passRights = requires.rights ? requires.rights.every(right => selected.rights.includes(right)) : true;
        return passPkg && passPages && passPanels && passRights;
    }, [selected]);
    //#endregion

    //#region Render
    const renderRight = React.useCallback((right: T.PCK.Right) => {
        let canBeSelected = userAccesses.rights.includes(right.code) && checkPrerequisite(right.requires);
        if (!canBeSelected) return null;

        let isSelected = selected.rights.includes(right.code);

        let label = <C.Flex alignItems="center" justifyContent="start">
            <C.IconTip icon="user-shield" className="me-2" tipContent={right.description || TC.ROLE_FORM_RIGHT} />
            <span className="me-3">{lg.getStaticElem(right.name)}</span>
            <FormCheck type="switch" disabled={inputState.disabled} checked={isSelected} onChange={() => toggleCode(right.code, "rights")} />
        </C.Flex>

        return <TreeItem key={right.code} nodeId={right.code} label={label} />;
    }, [toggleCode, lg, inputState.disabled, selected.rights, userAccesses.rights, checkPrerequisite]);

    const renderTab = React.useCallback((tab: T.PCK.Tab) => {
        let canBeSelected = userAccesses.panels.includes(tab.code) && checkPrerequisite(tab.requires);
        if (!canBeSelected) return null;

        let isSelected = selected.panels.includes(tab.code);

        let label = <C.Flex alignItems="center" justifyContent="start">
            <C.IconTip icon="th-large" className="me-2" tipContent={TC.ROLE_FORM_TAB} />
            <span className="me-3">{lg.getStaticElem(tab.name)}</span>
            <FormCheck type="switch" disabled={inputState.disabled} checked={isSelected} onChange={() => toggleCode(tab.code, "panels")} />
        </C.Flex>

        return <TreeItem key={tab.code} disabled={!isSelected} nodeId={tab.code} label={label}>
            {isSelected && tab.rights.map(renderRight)}
        </TreeItem>
    }, [renderRight, toggleCode, checkPrerequisite, lg, inputState.disabled, selected.panels, userAccesses.panels]);

    const renderPage = React.useCallback((page: T.PCK.Page) => {
        if (page.is_special && !props.show_special) return null;
        let canBeSelected = userAccesses.pages.includes(page.code);
        if (!canBeSelected) return null;

        let isSelected = selected.pages.includes(page.code);

        let label = <C.Flex alignItems="center" justifyContent="start">
            <C.IconTip icon="file-code" className="me-2" tipContent={TC.ROLE_FORM_PAGE} />
            <span className="me-3">{lg.getStaticElem(page.name)}</span>
            <FormCheck type="switch" disabled={inputState.disabled} checked={isSelected} onChange={e => toggleCode(page.code, "pages")} />
        </C.Flex>;

        return <TreeItem key={page.code} disabled={!isSelected} nodeId={page.code} label={label}>
            {isSelected && page.tabs.map(renderTab)}
        </TreeItem>
    }, [renderTab, toggleCode, lg, inputState.disabled, selected.pages, userAccesses.pages, props.show_special]);

    const renderPackage = React.useCallback((pck: T.PCK.Package) => {
        let canBeSelected = userAccesses.packages.includes(pck.code);
        if (!canBeSelected) return null;

        let isSelected = selected.packages.includes(pck.code);

        let label = <C.Flex alignItems="center" justifyContent="start">
            <C.IconTip icon="cube" className="me-2" tipContent={TC.ROLE_FORM_PACKAGE} />
            <span className="me-3">{lg.getStaticElem(pck.name)}</span>
            <FormCheck type="switch" disabled={inputState.disabled} checked={isSelected} onChange={() => toggleCode(pck.code, "packages")} />
        </C.Flex>;

        return <TreeItem key={pck.code} disabled={!isSelected} nodeId={pck.code} label={label}>
            {isSelected && <>
                {pck.pages.map(renderPage)}
                {pck.rights.map(renderRight)}
            </>}
        </TreeItem>
    }, [renderPage, toggleCode, renderRight, lg, inputState.disabled, selected.packages, userAccesses.packages]);
    //#endregion

    //#region To Role Format
    const formatSelection = React.useCallback(() => {
        let newRole: T.AccessRole = { ...role, packages: [] };

        for (let pckg of packages) {
            if (selected.packages.includes(pckg.code)) {
                let pckgAccess: T.AccessRolePackage = {
                    code: pckg.code,
                    pages: [],
                    rights: [],
                };

                for (let right of pckg.rights) {
                    if (selected.rights.includes(right.code)) pckgAccess.rights.push(right.code);
                }

                for (let page of pckg.pages) {
                    if (selected.pages.includes(page.code)) {
                        let pageAccess: T.AccessRolePage = {
                            code: page.code,
                            panels: [],
                        };

                        for (let tab of page.tabs) {
                            if (selected.panels.includes(tab.code)) {
                                let tabAccess: T.AccessRolePanel = {
                                    code: tab.code,
                                    rights: []
                                };

                                for (let right of tab.rights) {
                                    if (selected.rights.includes(right.code)) tabAccess.rights.push(right.code);
                                }

                                pageAccess.panels.push(tabAccess);
                            }
                        }
                        pckgAccess.pages.push(pageAccess);
                    }
                }
                newRole.packages.push(pckgAccess);
            }
        }

        return newRole;
    }, [packages, selected, role]);

    const saveRole = React.useCallback(() => {
        isSaving.setTrue();
        let role = formatSelection();

        S.saveAccessRole(role)
            .then(newRole => {
                if (newRole.data === "no_context_left") M.renderAlert({ type: "warning", message: TC.ROLE_FORM_NO_CONTEXT_APPLICABLE_MSG });
                else onSave?.(newRole.data)
            })
            .catch(M.Alerts.updateError)
            .finally(isSaving.setFalse);
    }, [isSaving, formatSelection, onSave]);
    //#endregion

    return <C.Spinner status={status}>
        <C.Form.TextField
            {...inputState}
            value={role.name}
            label={TC.ROLE_FORM_NAME}
            onChange={e => setRole(p => ({ ...p, name: e }))}
        />
        <C.Form.TextField
            rows={4}
            textArea
            autoExpand
            {...inputState}
            label={TC.ROLE_FORM_DESC}
            value={role.description}
            onChange={e => setRole(p => ({ ...p, description: e }))}
        />

        <C.Form.CheckBox
            labelPosition="left"
            value={role.isGeneric}
            label={TC.ROLE_FORM_IS_GENERIC}
            disabled={inputState.disabled || !isAdmin}
            readonly={inputState.readOnly || !isAdmin}
            onChange={isGeneric => setRole(p => ({ ...p, isGeneric }))}
        />

        <ComponentWrapper {...inputState} label={TC.ROLE_FORM_AFFECTATION}>
            <div className="p-2 bg-light border rounded">
                <TreeView defaultCollapseIcon={<C.IconTip icon="chevron-down" />} defaultExpandIcon={<C.IconTip icon="chevron-right" />}>
                    {packages.map(renderPackage)}
                </TreeView>
            </div>
        </ComponentWrapper>

        {!inputState.readOnly && <C.Flex className="mb-3" justifyContent="end">
            <C.Button className="me-3" variant="danger" disabled={isSaving.value} onClick={() => onSave?.(null)}>
                <>
                    <C.IconTip icon="times" className="me-2" />
                    {lg.getStaticText(TC.GLOBAL_CANCEL)}
                </>
            </C.Button>

            <C.Button disabled={isSaving.value} onClick={saveRole}>
                <>
                    <C.IconTip icon="save" className="me-2" spin={isSaving.value} spinIcon="spinner" />
                    {lg.getStaticText(TC.GLOBAL_SAVE)}
                </>
            </C.Button>
        </C.Flex>}

    </C.Spinner>;
}

export default RoleForm;