import React from "react";
import { InputProps, ComponentWrapper } from "../Input";
import { FormCheck, FormCheckProps } from "react-bootstrap";

export type CheckBoxProps = InputProps & {
    /** Type of checkbox */
    check_type?: FormCheckProps["type"];
    /** A custom style for a non-switch checkbox */
    checkbox_style?: React.CSSProperties;
};

export type CheckBoxRef = {
    /** The html input ref element */
    input: HTMLInputElement;
}

const CheckBox = React.forwardRef<CheckBoxRef, CheckBoxProps>(({ onChange, disabled, noOverride, ...props }, ref) => {
    const check = React.useRef<HTMLInputElement>(null);

    React.useImperativeHandle(ref, () => ({ input: check.current }), []);
    const isDisabled = React.useMemo(() => disabled || noOverride, [disabled, noOverride]);
    const labelFullWidth = React.useMemo(() => props.labelPosition === "left" || props.labelFullWidth, [props.labelPosition, props.labelFullWidth]);

    const input_props = React.useMemo<FormCheckProps>(() => ({
        ref: check,
        disabled: isDisabled,
        checked: !!props.value,
        onChange: e => onChange?.(e.target.checked),
    }), [onChange, isDisabled, props.value]);

    return <ComponentWrapper {...props} type="checkbox" labelFullWidth={labelFullWidth} labelPosition={props.labelPosition || "left"} disabled={isDisabled}>
        {props.check_type === "switch"
            ? <FormCheck {...input_props} type="switch" />
            : <FormCheck.Input {...input_props} style={props.checkbox_style} type={props.check_type} />}
    </ComponentWrapper>
});

export default CheckBox;