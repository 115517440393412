// import moment from "moment";
import * as T from "../Types";
import { REGEX } from "../Data";
import * as TB from "../ToolBox";

//#region Utils
const isWorkBook = (excel: T.WorkBook | T.WorkSheet): excel is T.WorkBook => Array.isArray(excel.SheetNames);

const extractWsInfos = (ref: string) => {
    if (!TB.validString(ref)) return null;
    let [start, end] = ref.split(":");

    let okStart = TB.validString(start) && start.match(REGEX.EXCEL_CELL);
    let okEnd = TB.validString(end) && end.match(REGEX.EXCEL_CELL);

    if (!okStart || !okEnd) return null;

    let endCol = end.replace(/\d+/, "");
    let startCol = start.replace(/\d+/, "");
    let endNum = parseInt(end.replace(/\D+/, ""));
    let startNum = parseInt(start.replace(/\D+/, ""));

    if (isNaN(endNum) || isNaN(startNum)) return null;
    return { start: { col: startCol, num: startNum }, end: { col: endCol, num: endNum } };
}
//#endregion

const excelValueGetterWorkSheet = (ws: T.WorkSheet, columns: T.ImportFormat, startAt: number, ignoredRows: number[]) => {
    let info = extractWsInfos(ws["!ref"]);
    if (info === null) return null;

    let nbEntries = (info.end.num - startAt) + 1;
    let values: T.AnyObject[] = [...new Array(nbEntries)].map(x => ({}));
    let keyColumns = Object.entries(columns).filter(([prop, data]) => TB.validString(data.key));

    // Loop through Cols
    for (let [prop, colData] of keyColumns) {
        let col = colData.key;
        // Loop through Rows
        for (let row = 0; row < nbEntries; row++) {
            if (!ignoredRows.includes(row)) {
                let cell: T.SheetCellValue = ws[col + (row + startAt)];
                let value = cell?.v as any;

                if ((cell?.t as any) === "d" || colData.type === "date") values[row][prop] = /* cell.w || */ cell?.v;
                else if (TB.validString(value)) values[row][prop] = value.trim();
                else if (typeof value === "number" && !isNaN(value)) values[row][prop] = value;
            }
        }
    }

    let nonEmptyValues = values.filter(v => Object.keys(v).length > 0);
    return nonEmptyValues;
}

const excelValueGetterWorkbook = (wb: T.WorkBook, columns: T.ImportFormat, startAt: number) => {
    let a: T.AnyObject[] = [];
    return a;
}

const excelValueGetter = (excel: T.WorkBook | T.WorkSheet, columns: T.ImportFormat, startAt: number, ignoredRows: number[]) => {
    if (isWorkBook(excel)) return excelValueGetterWorkbook(excel, columns, startAt);
    return excelValueGetterWorkSheet(excel, columns, startAt, ignoredRows);
}

export default excelValueGetter;