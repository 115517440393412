import React from "react";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import * as S from "../../../services";
import { FP, T, TC } from "../../../Constants";

export type EquipPartFormProps = {
    /** Disable all inputs */
    read_only?: boolean;
    /** The current values of the equipment */
    equip_data: T.EquipmentData;
    /** The id of the equipment */
    equip_id: string;
    /** The list of props to render */
    cdm_props: ReturnType<T.API.Utils.Missions.ControlListMissionCDM>[number]["cdm_props"];
    /** A callback to update the equipment data */
    set_equip_data: (prop: keyof T.EquipmentData, value: any) => void;
    /** The options for criticity and vetusty */
    options: Record<"vetusty" | "criticity", T.Option[]>;
    /** The lifespan inherited by the gamme */
    lifespan?: number;
}

type Components = ReturnType<T.API.Form.GetFormComponentsData>;

const EquipPartForm: React.FC<EquipPartFormProps> = ({ set_equip_data, ...props }) => {
    const [forms] = H.useFormIds();
    const [components, set_components, status] = H.useAsyncState<Components>({});
    const equip_form_id = React.useMemo(() => forms[FP.EQUIPEMENT_FORM], [forms]);

    const prop_list = React.useMemo(() => {
        let always_on = ["criticity", "dateInstallation", "vetusty", "expectedLifespan", "estimated_end_of_life"];
        let list = props.cdm_props.filter(p => !always_on.includes(p));
        return list;
    }, [props.cdm_props]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getFormComponentsData({ path: FP.EQUIPEMENT_FORM, item: props.equip_id, props: prop_list })
            .then(resp => isSubscribed && set_components(resp.data, "done"))
            .catch(() => isSubscribed && set_components({}, "error"));
        return () => {
            isSubscribed = false;
            set_components({}, "load");
        }
    }, [set_components, prop_list, props.equip_id]);

    const render_input = React.useCallback((prop: string) => {
        let component = components[prop];
        if (!component) return null;
        /* @ts-ignore Useless to try and work out the types */
        return <C.Form.Input
            {...component}
            disabled={props.read_only}
            value={props.equip_data?.[prop]}
            onChange={value => set_equip_data(prop as any, value)}
            label={{ _id: equip_form_id, prop, text: prop } as any}
        />;
    }, [components, equip_form_id, set_equip_data, props.read_only, props.equip_data]);

    return <C.Spinner status={status}>
        <BS.Row>
            <BS.Col>
                <C.Form.Select
                    disabled={props.read_only}
                    options={props.options.criticity}
                    value={props.equip_data?.criticity}
                    label={{ _id: equip_form_id, prop: "criticity" }}
                    onChange={value => set_equip_data("criticity", value)}
                />
            </BS.Col>
            <BS.Col>
                <C.Form.DateTime
                    disabled={props.read_only}
                    value={props.equip_data?.dateInstallation}
                    label={{ _id: equip_form_id, prop: "dateInstallation" }}
                    onChange={value => set_equip_data("dateInstallation", value)}
                />
            </BS.Col>
        </BS.Row>
        <BS.Row>
            <BS.Col>
                <C.Form.Select
                    disabled={props.read_only}
                    options={props.options.vetusty}
                    value={props.equip_data?.vetusty}
                    label={{ _id: equip_form_id, prop: "vetusty" }}
                    onChange={value => set_equip_data("vetusty", value)}
                />
            </BS.Col>
            <BS.Col>
                <C.Form.NumField
                    suffix={TC.FORM_SUFFIX_YEARS}
                    value={props.equip_data?.expectedLifespan}
                    label={{ _id: equip_form_id, prop: "expectedLifespan" }}
                    onChange={value => set_equip_data("expectedLifespan", value)}
                    disabled={props.read_only || (typeof props.lifespan === "number" && props.equip_data?.expectedLifespan === props.lifespan)}
                />
            </BS.Col>
        </BS.Row>
        <BS.Row>
            <BS.Col></BS.Col>
            <BS.Col>
                <C.Form.NumField
                    disabled={props.read_only}
                    value={props.equip_data?.estimated_end_of_life}
                    label={{ _id: equip_form_id, prop: "estimated_end_of_life" }}
                    onChange={value => set_equip_data("estimated_end_of_life", value)}
                />
            </BS.Col>
        </BS.Row>

        {prop_list.length > 0 && <BS.Row className="mt-3" children={prop_list.map(prop => <BS.Col md={6} key={prop} children={render_input(prop)} />)} />}
    </C.Spinner>;
}

export default EquipPartForm;