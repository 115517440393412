import './QR_Entity.scss';
import moment from "moment";
import { Dialog } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { FP, TB, TC } from "../../../Constants";
import * as US from "../../../services/user.service";
import ImgUploader from '../../FileUploader/ImgUploader';
import CardHeader from '../../cards/card-header/CardHeader';
import { useAuth, useFormIds, useLanguage } from '../../../hooks';
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

const TEXT_CODES = [TC.QRE_OLD_VAL, TC.QRE_NEW_VAL, TC.GLOBAL_CONFIRM];

const QrTemp = ({ ...props }) => {
    //#region State
    const params = useParams();
    const [forms] = useFormIds();
    const submitButton = useRef();
    const [entity, setEntity] = useState();
    const [newVal, setNewVal] = useState('');
    const [{ user }, { navigate }] = useAuth();
    const [building, setBuilding] = useState();
    const [imgInput, setImgInput] = useState();
    const [countdown, setCountdown] = useState();
    const { getStaticElem } = useLanguage(TEXT_CODES);
    const [{ value, date }, setOldVal] = useState({});
    //#endregion

    const entityId = useMemo(() => params?.id, [params]);

    //#region User
    useEffect(() => {
        let onkeyDown = e => e.keyCode === 13 || e.keyCode === "Enter" ? submitButton.current?.click?.() : undefined;
        document.onkeydown = onkeyDown;
        return () => document.onkeydown = undefined;
    }, []);

    useEffect(() => TB.mongoIdValidator(entityId) ? undefined : navigate("/", { replace: true }), [navigate, entityId]);
    //#endregion

    //#region Forms
    const entityFormId = useMemo(() => forms[FP.ENTITY_FORM], [forms]);
    const buildFormId = useMemo(() => forms[FP.BUILDING_FORM], [forms]);
    //#endregion

    //#region Countdown
    useEffect(() => typeof countdown === "number" && countdown > 0 ? setTimeout(() => setCountdown(countdown - 1), 1000) : undefined, [countdown]);
    useEffect(() => countdown === 0 ? navigate((user === null ? "/login" : "/"), { replace: true }) : undefined, [countdown, user, navigate]);
    //#endregion

    //#region Entity
    useEffect(() => {
        const getEntity = async () => {
            let reply = await US.readSubUnLogged({ _id: entityId, form: entityFormId });
            return TB.mongoIdValidator(reply?.data?._id) ? reply.data : null;
        }
        if (TB.multiMongoIdValidator([entityId, entityFormId])) getEntity().then(setEntity);
    }, [entityId, entityFormId]);

    useEffect(() => {
        const getBuildings = async () => {
            let reply = await US.getAncestryNodeUnLogged(entityId, [buildFormId]);
            return Array.isArray(reply.data) ? reply.data : null;
        }
        if (TB.multiMongoIdValidator([entityId, buildFormId])) getBuildings().then(setBuilding);
    }, [entityId, buildFormId]);
    //#endregion

    //#region Value
    const updateValue = useCallback(async () => {
        if (isNaN(parseFloat(newVal))) alert('Valeur non correct');
        else if (typeof imgInput !== "string") alert("Manque image");
        else {
            // await tempSQLUpdate(entityId, newVal);
            let response = await US.updateManualReading(entityId, newVal, imgInput, user?._id);
            if (response?.data?.hasFailed) alert('Update Failed');
            else setCountdown(10);
        }
    }, [entityId, newVal, imgInput, user]);

    useEffect(() => {
        const getReading = async () => {
            let reply = await US.readManualReading(entityId);
            if (reply?.data?.hasFailed) return { date: null, value: null };
            return reply.data;
        }

        if (TB.mongoIdValidator(entityId)) getReading().then(setOldVal);
    }, [entityId]);
    //#endregion

    //#region Error etc..
    const validId = useMemo(() => TB.mongoIdValidator(entityId), [entityId]);

    const errorLoad = useMemo(() => [entity, building].includes(null), [entity, building]);
    const isLoading = useMemo(() => !errorLoad && [entity, building].includes(undefined), [errorLoad, entity, building]);
    const allGood = useMemo(() => validId && !(errorLoad || isLoading), [validId, errorLoad, isLoading]);

    const errorScreen = useMemo(() => errorLoad ? <div className="err">ERROR</div> : undefined, [errorLoad]);
    const loadScreen = useMemo(() => isLoading ? <div className="loader"></div> : undefined, [isLoading]);
    //#endregion

    //#region HTML
    const header = useMemo(() => `${entity?.data?.name} (${building?.map?.(b => b?.data?.name)?.join?.(', ')})`, [entity, building]);

    const imagePanel = useMemo(() => <div className='imgUploader'>
        <ImgUploader setBase64={val => Array.isArray(val) ? setImgInput(val[0]) : setImgInput(undefined)} />
        {typeof imgInput === "string" ? <img src={imgInput} alt="index" /> : <div></div>}
    </div>, [imgInput]);

    const oldInfosPanel = useMemo(() => <div className='oldInfosPanel'>
        <span>{getStaticElem(TC.QRE_OLD_VAL)} :</span>
        <span>{isNaN(parseFloat(value)) ? "N/A" : value}</span>
        <span>{isNaN(Date.parse(new Date(date))) ? "" : `  (${moment(date).format("DD/MM/YY HH:mm")})`}</span>
    </div>, [value, date, getStaticElem]);

    const inputPanel = useMemo(() => <div className='inputPanel'>
        <label>{getStaticElem(TC.QRE_NEW_VAL)} <span style={{ color: 'red' }}>*</span> :</label>
        <input type='textfield' value={newVal} onChange={e => setNewVal(e.target.value.replace(",", "."))} />
        <button ref={submitButton} onClick={updateValue}>{getStaticElem(TC.GLOBAL_CONFIRM)}</button>
    </div>, [newVal, getStaticElem, updateValue]);

    const countdownModal = useMemo(() => <Dialog
        onClose={() => setCountdown(0)}
        open={typeof countdown === "number" && countdown > 0}
    >
        <h1>SUCCESS</h1>
        <p>Vous allez être redirigé dans {countdown} secondes</p>
    </Dialog>, [countdown]);

    const mainScreen = useMemo(() => {
        if (!allGood) return;
        return <div className="cardMain">
            <CardHeader header={header}>
                {oldInfosPanel}
                {imagePanel}
                {inputPanel}
            </CardHeader>
            {countdownModal}
        </div>
    }, [header, imagePanel, allGood, countdownModal, inputPanel, oldInfosPanel]);
    //#endregion

    return <div>{!validId ? <div>Id invalid</div> : <div>{mainScreen}{loadScreen}{errorScreen}</div>}</div>
}

export default QrTemp;