/* ------- Actions ------- */
/** The Calendar panel */
export const CALENDAR_PANEL = "CALENDAR_PANEL";
/** The actions table panel */
export const TABLE_ACTION = "TABLE_ACTION";

/* ------- Reg ------- */
/** The reg tab for the building id card page */
export const TABS_IDS_CARD_REG = "TABS_IDS_CARD_REG";
/** The Main panel for the Reg Actions table */
export const TABLE_REG_ACTIONS = "TABLE_REG_ACTIONS";
/** The main panel for the reg docs table */
export const TABLE_REG_DOCS = "TABLE_REG_DOCS";
/** The reg panel in the building's editor */
export const BUILDING_EDITOR_REG_PANEL = "BUILDING_EDITOR_REG_PANEL";

/* ------- Energy ------- */
/** The energy tab for the building id card page */
export const TABS_BUILD_ENERGY = "TABS_BUILD_ENERGY";
/** Extra Graphs for a portfolio's dashboard */
export const PORTFOLIO_GRAPHS_ENERGY = "PORTFOLIO_GRAPHS_ENERGY";
/** The Main panel for the energy tags table */
export const TABLE_NRJ_TAGS = "TABLE_NRJ_TAGS";
/** The main table for alarms */
export const TABLE_NRJ_ALARMS = "TABLE_NRJ_ALARMS";

/* ------- Energy ------- */
/** The tech elements tab in the building id card */
export const TABS_BUILD_TECH = "TABS_BUILD_TECH";

/* ------- Tech ------- */
/** The main panel for the tree view */
export const MAIN_TREE_PANEL = "MAIN_TREE_PANEL";
/** The main panel for the table of equipments */
export const TABLE_EQUIPMENTS = "TABLE_EQUIPMENTS";
/** The main panel for the table of emplacements */;
export const TABLE_EMPLACEMENTS = "TABLE_EMPLACEMENTS";
/** The main panel for the table of equipment types */
export const TABLE_EQUIPMENTS_TYPE = "TABLE_EQUIPMENTS_TYPE";

/* ------- Misc ------- */
/** The main panel for the table of remarques */
export const TABLE_REMARQUES = "TABLE_REMARQUES";
/** The main panel for the table of contracts */
export const TABLE_CONTRACTS = "TABLE_CONTRACTS";
/** The main panel for the table of users */
export const TABLE_USERS = "TABLE_USERS";
/** The main panel for the table of notes */
export const TABLE_NOTES = "TABLE_NOTES";