import ReactDOM from 'react-dom';
import DefaultComp from './NoRenderComp';
import { ReactComponent } from 'react-formio';

export default class RegStatuses extends ReactComponent {
    static get builderInfo() {
        return {
            weight: 1,
            icon: "table",
            group: "Custom",
            documentation: "",
            title: "RegStatuses",
            schema: RegStatuses.schema(),
        };
    }

    static schema = () => ReactComponent.schema({ type: "RegStatuses", label: "RegStatuses" });
    attachReact = element => ReactDOM.render(<DefaultComp />, element);
    detachReact = element => element ? ReactDOM.unmountComponentAtNode(element) : undefined;
}