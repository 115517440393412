import React from "react";
import * as H from '../../../hooks';
import { Flex } from "../../Layout";
import * as S from "../../../services";
import * as M from '../../../Components/Modal';
import 'react-dropzone-uploader/dist/styles.css';
import { ComponentWrapper, InputProps } from "../Input";
import { DropzonePreview, GetPreviewUrl } from "../Misc";
import { Button, Card, Col, Row } from 'react-bootstrap';
import { RESOURCE as R, T, TB, TC, URL as URL_C } from '../../../Constants';
import Dropzone, { IFileWithMeta, IMeta, StatusValue } from 'react-dropzone-uploader';

//#region Types
export type FileUploaderProps = InputProps & {
    /** Should this input only accept images */
    image?: boolean;
    /** The max size of the file, ex: 15MB */
    fileMaxSize?: string;
    /** The min size of the file, ex: 1MB */
    fileMinSize?: string;
    /** The expected pattern for the file type */
    filePattern?: any;
    /** Can upload multiple file ? */
    multiple?: boolean;
    /** Ask for a confirmation modal when removing an existing file */
    confirm_remove?: boolean;
    /** The direction of the flex container */
    direction?: "row" | "column";
    /** Should the names and sizes of the files be shown ? */
    hide_info?: boolean;
}

type OnStatusChange = (file: IFileWithMeta, status: StatusValue, allFiles: IFileWithMeta[]) => void;
//#endregion

const TEXT_CODES = [TC.GLOBAL_FILE_UPLOAD, TC.GLOBAL_NEW];

const FileUploader: React.FC<FileUploaderProps> = ({ onChange, lock_form, unlock_form, fileMaxSize = "500MB", ...props }) => {
    const lg = H.useLanguage(TEXT_CODES);
    const cancelled = React.useRef<string[]>([]);
    const [lock_status, set_lock_status] = React.useState<Record<string, boolean>>({});
    const isDisabled = React.useMemo(() => props.disabled || props.noOverride, [props.disabled, props.noOverride]);

    //#region Load the files already submitted
    const vFiles = React.useMemo(() => TB.getArray(props.value).filter(TB.isFile), [props.value]);
    const dbFiles = React.useMemo(() => vFiles.filter(f => f.storage !== "temp"), [vFiles]);
    //#endregion

    //#region New Files Comeback
    const getIdName = React.useCallback((id: string, name: string) => {
        let splitted = name.split(".");
        let extension = splitted[splitted.length - 1];
        if (extension) extension = "." + extension;
        else extension = "";
        return id + extension;
    }, []);

    const getFileFormatted = React.useCallback((meta: IMeta, file: File): T.File => ({
        type: meta.type,
        size: file.size,
        storage: "temp",
        originalName: meta.name,
        name: getIdName(meta.id, meta.name),
        url: URL_C.APP_DOMAIN + URL_C.FILE_API + "temp/" + meta.id,
    }), [getIdName]);

    React.useEffect(() => {
        if (Object.values(lock_status).every(v => v === false)) unlock_form?.();
    }, [lock_status, unlock_form]);

    const onChangeStatus = React.useCallback<OnStatusChange>(({ meta, file, ...r }, status, files) => {
        let all_files = files.map(f => getFileFormatted(f.meta, f.file));
        if (status === "uploading") {
            lock_form?.();
            set_lock_status(p => ({ ...p, [meta.id]: true }));
        }
        else if (status === "error_upload") set_lock_status(p => ({ ...p, [meta.id]: false }));
        else if (status === "rejected_file_type") M.renderAlert({ type: "warning", message: TC.FILE_UPLOAD_WRONG_TYPE });
        else if (status === "done") {
            let formatted_file = getFileFormatted(meta, file);
            // Check if file was correctly uploaded
            S.tempFileExists(formatted_file.name).then(({ data }) => {
                // Upload successful
                if (data) onChange?.(vFiles.filter(f => f.storage !== 'temp').concat(all_files).filter(f => !cancelled.current.includes(f.name)));
                // Failed Upload
                else {
                    r.remove?.();
                    M.Alerts.failedFileUpload();
                    cancelled.current.push(formatted_file.name);
                }
            })
                .catch(M.Alerts.failedFileUpload)
                .finally(() => set_lock_status(p => ({ ...p, [meta.id]: false })));
        }
        else if (status === "removed") {
            let removedIdName = getIdName(meta.id, meta.name);
            onChange?.(vFiles.filter(f => f.storage !== "temp" || f.name !== removedIdName));
        }
    }, [getFileFormatted, onChange, getIdName, lock_form, vFiles]);
    //#endregion

    //#region Preview & ...
    const getPreviewUrl = React.useCallback<GetPreviewUrl>((meta, status) => status === "done" ? URL_C.FILE_API + "temp/" + meta.meta.id : "", []);

    const size = React.useMemo(() => {
        let max = TB.humanSizeFormatToBytes(fileMaxSize);
        let min = TB.humanSizeFormatToBytes(props.fileMinSize);

        return {
            min: typeof min === "number" ? min : undefined,
            max: typeof max === "number" ? max : undefined,
        };
    }, [fileMaxSize, props.fileMinSize]);
    //#endregion

    //#region Existing Files
    const removedFiles = React.useMemo(() => vFiles.filter(f => f.storage === "removed").map(f => f.name).filter(TB.validString), [vFiles]);
    const getSrc = React.useCallback((file: T.File) => file.type.includes("image") ? file.url : R.RESOURCE_URL(R.NO_IMG_FOUND), []);

    const toggleExistingFile = React.useCallback((name: string) => {
        let toggle = () => {
            let new_files = vFiles.map(f => {
                // Not the file concerned
                if (f.name !== name) return f;
                // File was deleted
                if (f.storage === "removed") return { ...f, storage: "local" };
                // Delete the file
                else return { ...f, storage: "removed" };
            });
            onChange?.(new_files);
        }

        if (props.confirm_remove) M.Confirms.remove_file()
            .then(confirmed => confirmed && toggle());
        else toggle();
    }, [vFiles, onChange, props.confirm_remove]);

    const getRenderProps = React.useCallback((name: string) => {
        let removed = removedFiles.includes(name);

        return {
            opacity: removed ? 0.5 : 1,
            variant: removed ? "primary" : "danger",
            icon: removed ? "rotate-left" : "times",
        }
    }, [removedFiles]);

    const renderExistingItem = React.useCallback((file: T.File) => {
        let render_props = getRenderProps(file.name);

        return <Col key={file.name} xl={props.multiple ? 3 : undefined} md={props.multiple ? 4 : undefined} sm={props.multiple ? 6 : undefined}>
            <Card className='position-relative' style={{ opacity: render_props.opacity }}>
                <Card.Img
                    variant="top"
                    src={getSrc(file)}
                    className='pointer p-1'
                    style={{ objectFit: "contain" }}
                    height={props.multiple ? "100px" : "150px"}
                    onClick={() => window.open(file.url, "_blank")}
                />
                {!props.hide_info && <Card.Body>
                    <Card.Title
                        className='pointer'
                        children={file.originalName}
                        onClick={() => window.open(file.url, "_blank")}
                    />
                    <Card.Text className='text-muted'>{TB.bytesToHumanSizeFormat(file.size)}</Card.Text>
                </Card.Body>}
                {!isDisabled && <Button size="sm" variant={render_props.variant} className='m-1 position-absolute top-0 start-0' onClick={() => toggleExistingFile(file.name)}>
                    <i className={'fa fa-' + render_props.icon}></i>
                </Button>}
            </Card>
        </Col>
    }, [isDisabled, getRenderProps, toggleExistingFile, getSrc, props.multiple, props.hide_info]);
    //#endregion

    const is_mobile = React.useMemo(() => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile Safari/i.test(navigator.userAgent), []);

    const accept = React.useMemo(() => {
        if (!props.image) return props.filePattern;
        else {
            let accept = "image/*";
            if (is_mobile) accept += ";capture=camera";
            return accept
        }
    }, [props.image, props.filePattern, is_mobile]);

    const allowNoMore = React.useMemo(() => !props.multiple && dbFiles.length !== 0 && removedFiles.length === 0, [props.multiple, dbFiles.length, removedFiles.length]);

    return <ComponentWrapper {...props} disabled={isDisabled} >
        <Flex direction={props.direction || "column"} className="flex-grow-1">
            {/* Pre-existing files */}
            {dbFiles.length > 0 && <Row className='p-2 mb-3' children={dbFiles.map(renderExistingItem)} />}

            {/* New Files */}
            <Dropzone
                multiple
                accept={accept}
                canCancel={false}
                minSizeBytes={size.min}
                maxSizeBytes={size.max}
                SubmitButtonComponent={null}
                onChangeStatus={onChangeStatus}
                disabled={isDisabled || allowNoMore}
                maxFiles={props.multiple ? undefined : 1}
                inputContent={lg.getStaticText(TC.GLOBAL_FILE_UPLOAD)}
                inputWithFilesContent={lg.getStaticText(TC.GLOBAL_NEW)}
                PreviewComponent={(props) => <DropzonePreview {...props} getPreviewUrl={getPreviewUrl} />}
                getUploadParams={({ meta }) => ({ method: "POST", url: URL_C.FILE_API + "temp/stream/" + meta.id, catch: M.Alerts.loadError })}

            />
        </Flex>
    </ComponentWrapper>
}

export default FileUploader;