export const EXCEL_CELL = /[A-Z]{1,3}\d+/g;
export const EXCEL_ROW_REGEX_LOOSE = /[0-9]/g;
export const DURATION_REGEX = /^\d?\d:[0-5]\d$/g;
export const EXCEL_COL_REGEX_LOOSE = /[A-Z]{1,3}/g;
export const EXCEL_COL_REGEX_STRICT = /^[A-Z]{1,3}$/g;
export const MONGO_ID_REGEX_LOOSE = /[\dA-Fa-f]{24}/g;
export const MONGO_ID_REGEX_STRICT = /^[\dA-Fa-f]{24}$/g;
export const MATH_EVAL_PARAM = /[esblfc]\.[a-zA-Z_0-9]+/g;
export const TIME_HOUR_REGEX = /^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/g;
export const OMNICLASS_REGEX = /[0-9]{2}-[0-9]{2}(\s[0-9]{2}){2,5}(\sD)?/ig;
export const FILE_SIZE_REGEX = /^(\d*\.?\d+)((?=[KMGT])([KMGT])(?:i?B)?|B?)$/i;
export const COLOR_REGEX = /^#([0-9a-fA-F]{6}|[0-9a-fA-F]{3}|[0-9a-fA-F]{8})$/;
export const PASSWORD_STRENGTH = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/g;
export const MAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g
export const UUID_REGEX_LOOSE = /[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/i;
export const UUID_REGEX_STRICT = /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
export const URL_REGEX = /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()[\]{};:'".,<>?«»“”‘’]))/g;