import { URL, TB } from "../Constants";

export const BLANK_FORMIO_FORMAT = TB.blankSub;

//#region MOBILE DEFINITION
export const MIN_WIDTH_MOBILE_DISPLAY = 500;
export const MIN_HEIGHT_MOBILE_DISPLAY = 500;
//#endregion

//#region ADDRESSES & URL
//Adresses
export const SERVER = URL.APP_DOMAIN;
export const FORMIO_API = '/formio';
export const FORMIO_SERVER = SERVER + FORMIO_API;
export const SOCKET_PATH = "/endapi/";

//The debut of the link for an edit
export const LINK_FORMIO_EDIT_DEBUT = FORMIO_SERVER + "/#/form/";

//#endregion

//#region FORMS + FORMS ANSWERS
//Formio types of forms
export const FORMIO_FORM_TYPE_FORM = "form";

//#region NEW FORMIO & CHILDREN COMPONENTS

//Main component

//FormViewer
export const FORM_VIEWER_BUTTONS_DIV = "formViewerButtonsDiv";
export const FORM_VIEWER_BUTTONS = "formViewerButtons";
export const FORM_VIEWER_SAVE = "formViewerSave";
export const FORM_VIEWER_CANCEL = "formViewerCancel";
export const FORM_VIEWER_FORM_DIV = "formViewerFormDiv";
export const FORM_VIEWER_FORM_BUILDER = "formViewerFormBuilder";

//#region Form.io default values

//Default action attributed to a new form
export const GET_DEFAULT_ACTION = (formId, formTitle) => ({
    handler: ["before"],
    method: ["create", "update"],
    priority: 10,
    deleted: null,
    name: "save",
    title: "Save Submission",
    form: formId,
    machineName: `${formTitle}:save`,
    __v: 0,
    settings: {}
});

//Formio access forms
export const FORMIO_SUBMISSION_ACCESS_RESOURCE_DEFAULT = [
    { roles: [], type: "create_all" },
    { roles: ["6026534f15c3836e0a480cf7"], type: "read_all" },
    { roles: ["6026534f15c3836e0a480cf7"], type: "update_all" },
    { roles: ["6026534f15c3836e0a480cf7"], type: "delete_all" },
    { roles: ["6026534f15c3836e0a480cf7"], type: "create_own" },
    { roles: ["6026534f15c3836e0a480cf7"], type: "read_own" },
    { roles: ["6026534f15c3836e0a480cf7"], type: "update_own" },
    { roles: ["6026534f15c3836e0a480cf7"], type: "delete_own" }
];

export const FORMIO_SUBMISSION_ACCESS_FORM_DEFAULT = [
    { roles: [], type: "create_all" },
    { roles: ["6026534f15c3836e0a480cf7"], type: "read_all" },
    { roles: ["6026534f15c3836e0a480cf7"], type: "update_all" },
    { roles: ["6026534f15c3836e0a480cf7"], type: "delete_all" },
    { roles: ["6026534f15c3836e0a480cf7"], type: "create_own" },
    { roles: ["6026534f15c3836e0a480cf7"], type: "read_own" },
    { roles: ["6026534f15c3836e0a480cf7"], type: "update_own" },
    { roles: ["6026534f15c3836e0a480cf7"], type: "delete_own" }
];

export const FORMIO_ACCESS_RESOURCE_DEFAULT = [{ roles: ["6026534f15c3836e0a480cf6", "6026534f15c3836e0a480cf7", "6026534f15c3836e0a480cf8"], type: "read_all" }];
export const FORMIO_ACCESS_FORM_DEFAULT = [{ roles: ["6026534f15c3836e0a480cf6", "6026534f15c3836e0a480cf7", "6026534f15c3836e0a480cf8"], type: "read_all" }];

export const EDIT_FORM = "edit";
export const CREATE_FORM = "create";
//#endregion