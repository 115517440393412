import * as C from "../../Common";
import * as S from "../../services";
import { T, TB, TC } from "../../Constants";
import InnerHtml from "dangerously-set-html-content";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAsyncState, useAuth, useLanguage, useRoots } from "../../hooks";

//#region Types
type Config = {
    /** Group by */
    group: T.NRJ.TimeGroups;
    /** The time to get data from and to */
    time: T.NRJ.TimeSelection;
    /** The name of the template */
    template: string;
}
//#endregion

//#region Constants
const TEXT_CODES = [TC.GLOBAL_CONFIRM_PARAMS, TC.DATASET_COL_TIME, TC.DATASET_COL_VALUE];

const TIME_GROUPS: T.Option<{}, T.NRJ.TimeGroups>[] = [
    { label: TC.GP_GROUP_MIN, value: "minute" },
    { label: TC.GP_GROUP_HOUR, value: "hour" },
    { label: TC.GP_GROUP_DAY, value: "day" },
    { label: TC.GP_GROUP_WEEK, value: "week" },
    { label: TC.GP_GROUP_MONTH, value: "month" },
    { label: TC.GP_GROUP_YEAR, value: "year" },
];
//#endregion

const DataChart: React.FC = props => {
    useAuth({ onlyAdmin: true });
    const [context] = useRoots();
    const lg = useLanguage(TEXT_CODES);
    const [html, setHtml, htmlStatus] = useAsyncState("", "done");
    const [config, setConfig] = useState<Config>({ group: "hour", time: { interval: "15 DAY" }, template: "" });

    //#region Load HTML
    const time = useMemo(() => TB.getFromToUnix(config.time), [config.time]);

    const load = useCallback(() => {
        setHtml("", "load");
        S.html_report({ template: config.template, params: { context: JSON.stringify(context), ...time } })
            .then(({ data }) => {
                if (TB.validString(data)) setHtml(data, "done");
                else setHtml("", "error");
            })
            .catch(() => setHtml("", "error"))
    }, [config.template, time, context, setHtml]);
    //#endregion

    //#region Config
    const configBar = useMemo(() => <C.Flex alignItems="end" justifyContent="between">
        <div>
            <C.TimeSelector
                to={config.time.to}
                from={config.time.from}
                interval={config.time.interval}
                onChangeDatePicker={time => setConfig(p => ({ ...p, time }))}
                onChangeInterval={interval => setConfig(p => ({ ...p, time: { interval } }))}
            />
        </div>
        <div>
            <C.Form.Select
                noBottomMargin
                value={config.group}
                options={TIME_GROUPS}
                label={TC.GLOBAL_TIME_GROUP}
                onChange={group => setConfig(p => ({ ...p, group }))}
            />
        </div>
        <div className="flex-grow-1">
            <C.Form.TextField
                noBottomMargin
                label="Template"
                value={config.template}
                onChange={template => setConfig(p => ({ ...p, template }))}
            />
        </div>
        <div>
            <C.Button onClick={load} icon={{ spin: htmlStatus === "load", icon: "check", spinIcon: "spinner" }} text={TC.CONFIRM} />
        </div>
    </C.Flex>, [config, htmlStatus, load]);
    //#endregion

    //#region Language
    useEffect(() => lg.getOptionsStatic(TIME_GROUPS), [lg]);
    //#endregion

    return <div className="w-100 h-100">
        {configBar}
        <C.Spinner status={htmlStatus}>
            <InnerHtml className="w-100 h-100" html={html} />
        </C.Spinner>
    </div>;
}

export default DataChart;