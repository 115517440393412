import renderInContainer from "../getContainer";
import { Sankey, SankeyProps } from "../Components";

export type RenderSankey = (params: Omit<SankeyProps, "quit" | "validate">) => Promise<void>;

const renderSankey: RenderSankey = params => new Promise(resolve => {
    const [render, dismount] = renderInContainer();
    if (render && dismount) {
        const quit: SankeyProps["quit"] = () => dismount(() => resolve(null));
        render(<Sankey {...params} quit={quit} />);
    }
    else resolve(null);
});

export default renderSankey;