import React from 'react'
import useEventListener from "./useEventListener";

type Handler = (event: MouseEvent) => void

const useOnClickOutside = <T extends HTMLElement = HTMLElement>(ref: React.RefObject<T>, handler: Handler, mouseEvent: 'mousedown' | 'mouseup' = 'mousedown',): void => {
    useEventListener(mouseEvent, event => {
        const el = ref?.current
        // Do nothing if clicking ref's element or descendent elements
        if (!el || el.contains(event.target as Node)) return;
        handler(event)
    });
}

export default useOnClickOutside