import React from "react";
import { TB } from "../../Constants";
import * as BS from "react-bootstrap";

type FlatLabelInputProps = {
    label: string;
    className?: string;
    required?: boolean;
    labelWidth?: number;
    children?: React.ReactNode;
}

const FlatLabelInput: React.FC<FlatLabelInputProps> = props => <BS.Row className={`mb-2 align-items-center ${TB.getString(props.className)}`}>
    <BS.Col md={TB.getNumber(props.labelWidth, 3)}>
        <BS.Form.Label className="m-0">
            {props.label}
            {props.required && <span className="text-danger ms-2">*</span>}
        </BS.Form.Label>
    </BS.Col>
    <BS.Col md={12 - TB.getNumber(props.labelWidth, 3)}>
        {props.children}
    </BS.Col>
</BS.Row>

export default FlatLabelInput;