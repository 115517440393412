import React from "react";
import * as M from "../BlankModal";
import * as C from "../../../Common";
import { TC } from "../../../Constants";
import { DataSets as D } from "../../Form";

export type ModalFormulaProps = Omit<D.FormulaShellProps, "no_expand"> & {
    /** Extra modal styles */
    modal?: M.StyleModalProps
}

const ModalFormula: React.FC<ModalFormulaProps> = props => {
    const [formula, set_formula] = React.useState(props.value || "");

    const footer = React.useMemo(() => <C.Flex justifyContent="end">
        <C.Button text={TC.GLOBAL_CONFIRM} icon="check" onClick={() => props.onChange?.(formula)} />
    </C.Flex>, [props, formula]);

    return <M.default
        {...props.modal}
        footer={footer}
        size={props.modal?.size || "lg"}
        onQuit={() => props.onChange?.(null)}
        title={props.modal?.title || TC.DATASET_FORMULAS}
        maxBodyHeight={props.modal?.maxBodyHeight || "75vh"}
    >
        <div style={{ height: "70vh" }}>
            <D.FormulaShell {...props} no_expand onChange={set_formula} value={formula} />
        </div>
    </M.default>;
}

export default ModalFormula;