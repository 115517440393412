import _ from "lodash";
import * as B from "../Settings/Base.Settings";

const SETTINGS = {
    ColorPicker: {
        ColorPicker: [
            B.conditionalOverride,
            { key: "display", components: B.ignoreKeys(["labelPosition", "placeholder", "tabindex", "modalEdit", "autofocus"]) },
            {
                key: "data", components: [
                    ...B.customValuesPanels,
                    ...B.ignoreKeys(["multiple", "persistent", "protected", "dbIndex", "encrypted", "redrawOn", "calculateServer"])
                ]
            },
            {
                key: "validation", components: [
                    ...B.JS_VALIDATION_SETTINGS,
                    ...B.ignoreKeys(["validate.customMessage"])
                ]
            }
        ]
    },
    WellSettings: {
        well: [
            B.conditionalOverride,
            { key: "display", components: B.ignoreKeys(["modalEdit", "label", "hideLabel"]) }
        ]
    },
    TabsSettings: {
        tabs: [
            B.conditionalOverride,
            { key: "display", components: B.ignoreKeys(["label", "hideLabel", "modalEdit"]) }
        ]
    },
    PanelSettings: {
        panel: [
            B.conditionalOverride,
            { key: "display", components: B.ignoreKeys(["modalEdit"]) }
        ]
    },
    ColumnsSettings: {
        columns: [
            B.conditionalOverride,
            { key: "display", components: B.ignoreKeys(["autoAdjust", "hideLabel", "modalEdit"]) }
        ]
    },
    ContentSettings: {
        content: [
            B.conditionalOverride,
            { key: "display", components: B.ignoreKeys(["label", "modalEdit", "refreshOnChange"]) }
        ]
    },
    TableFormSettings: {
        table: [
            B.conditionalOverride,
            { key: "display", components: B.ignoreKeys(["cloneRows", "modalEdit"]) }
        ]
    },
    HTMLElementSettings: {
        htmlelement: [
            B.conditionalOverride,
            { key: "display", components: B.ignoreKeys(["label", "refreshOnChange", "modalEdit"]) }
        ]
    },
    HiddenSettings: {
        hidden: [
            { key: "display", components: B.ignoreKeys(["label", "modalEdit"]) },
            {
                key: "data",
                components: [
                    ...B.customValuesPanels,
                    ...B.ignoreKeys(["persistent", "protected", "dbIndex", "encrypted", "redrawOn", "calculateServer"]),
                ]
            },
        ]
    },
    DataMapSettings: {
        datamap: [
            B.conditionalOverride,
            { key: "display", components: B.ignoreKeys(["labelPosition", "keyBeforeValue", "autofocus", "modalEdit"]) },
            {
                key: "data",
                components: [
                    ...B.customValuesPanels,
                    ...B.ignoreKeys(["persistent", "protected", "dbIndex", "encrypted", "redrawOn", "calculateServer"])
                ]
            },
            {
                key: "validation",
                components: [
                    ...B.JS_VALIDATION_SETTINGS,
                    ...B.ignoreKeys(["unique", "errorLabel", "validateOn", "validate.customMessage"])
                ]
            },
        ]
    },
    DataGridSettings: {
        datagrid: [
            B.conditionalOverride,
            { key: "display", components: B.ignoreKeys(["labelPosition", "reorder", "enableRowGroups", "initEmpty", "tabindex", "autofocus", "modalEdit"]) },
            {
                key: "data",
                components: [
                    ...B.customValuesPanels,
                    ...B.ignoreKeys(["persistent", "protected", "dbIndex", "encrypted", "redrawOn", "calculateServer"])
                ]
            },
            {
                key: "validation",
                components: [
                    ...B.JS_VALIDATION_SETTINGS,
                    ...B.ignoreKeys(["errorLabel", "validateOn", "validate.customMessage"])
                ]
            },
        ]
    },
    ContainerSettings: {
        container: [
            B.conditionalOverride,
            { key: "validation", ignore: true },
            { key: "display", components: B.ignoreKeys(["labelPosition", "modalEdit"]) },
            {
                key: "data",
                components: [
                    ...B.customValuesPanels,
                    ...B.ignoreKeys(["persistent", "protected", "dbIndex", "encrypted", "redrawOn", "calculateServer"])
                ]
            }
        ]
    },
    TimeSettings: {
        time: [
            B.conditionalOverride,
            { key: "display", components: B.ignoreKeys(["labelPosition", "modalEdit", "tabindex", "autofocus", "inputType"]) },
            {
                key: "validation",
                components: [
                    ...B.JS_VALIDATION_SETTINGS,
                    ...B.ignoreKeys(["unique", "errorLabel", "validateOn", "validate.customMessage"])
                ]
            },
            {
                key: "data",
                components: [
                    ...B.customValuesPanels,
                    { key: 'minHour', weight: 0, type: 'textfield', input: true, label: 'Time min', placeholder: 'hh:mm' },
                    { key: 'maxHour', weight: 0, type: 'textfield', input: true, label: 'Time max', placeholder: 'hh:mm' },
                    ...B.ignoreKeys(["multiple", "dataFormat", "persistent", "protected", "dbIndex", "encrypted", "redrawOn", "calculateServer"])
                ]
            }
        ]
    },
    DateTimeSettings: {
        datetime: [
            B.conditionalOverride,
            { key: "date", components: B.ignoreKeys(["enableDate", "enableMinDateInput", "enableMaxDateInput"]) },
            { key: "display", components: B.ignoreKeys(["labelPosition", "displayInTimezone", "useLocaleSettings", "allowInput", "placeholder", "tabindex", "autofocus", "modalEdit", "shortcutButtons"]) },
            {
                key: "data",
                components: [
                    ...B.customValuesPanels,
                    ...B.ignoreKeys(["multiple", "persistent", "protected", "dbIndex", "encrypted", "redrawOn", "calculateServer", "customOptions"]),
                ]
            },
            {
                key: 'validation',
                components: [
                    ...B.JS_VALIDATION_SETTINGS,
                    ...B.ignoreKeys(["unique", "validateOn", "errorLabel", "validate.customMessage"]),
                ]
            }
        ]
    },
    SignatureSettings: {
        signature: [
            { key: "data", ignore: true },
            { key: "validation", ignore: true },
            { key: "display", components: B.ignoreKeys(["labelPosition", "description", "tooltip", "customClass", "tabindex", "disabled", "modalEdit", "tableView"]) },
        ]
    },
    emailSettings: {
        email: []
    },
    SelectSettings: {
        select: [
            B.conditionalOverride,
            { key: "display", components: B.ignoreKeys(["labelPosition", "widget", "placeholder", "tabindex", "uniqueOptions", "modalEdit", "autofocus"]) },
            {
                key: "data", components: [
                    {
                        type: 'select',
                        input: true,
                        key: 'redrawOptionsOn',
                        label: 'Redraw Options On',
                        weight: 600,
                        tooltip: 'Redraw the options if another component changes',
                        dataSrc: 'custom',
                        valueProperty: 'value',
                        data: {
                            custom: context => {
                                var values = [];
                                values.push({ label: 'Any Change', value: 'data' });
                                context.utils.eachComponent(context.instance.options.editForm.components, (component, path) => {
                                    if (component.key !== context.data.key) values.push({ label: `${component.label || component.key} (${component.key})`, value: path });
                                });
                                return values;
                            }
                        },
                    },
                    {
                        type: 'textfield',
                        input: true,
                        key: 'propOut',
                        label: 'Properties to not load',
                        weight: 10,
                        tooltip: 'The properties to avoid loading (ex: a base64 image!)',
                        multiple: true,
                        conditional: { json: { in: [{ var: "data.dataSrc" }, ["resource"]] } }
                    },
                    ...B.customValuesPanelsExtraVar("<tr><th>options</th><td>The list of options currently available.</td></tr>"),
                    ...B.ignoreKeys([
                        "dataType", "idPath", "refreshOn", "refreshOnBlur", "clearOnRefresh", "searchEnabled", "selectThreshold", "readOnlyValue",
                        "customOptions", "useExactSearch", "persistent", "protected", "dbIndex", "encrypted", "calculateServer", "lazyLoad",
                        "selectValues", "disableLimit", "sort", "authenticate", "ignoreCache", "selectFields", "reference",
                    ])
                ]
            },
            {
                key: "validation",
                components: [
                    ...B.JS_VALIDATION_SETTINGS,
                    ...B.ignoreKeys(["errorLabel", "validate.customMessage", "validateOn", "validate.select"])
                ]
            }
        ]
    },
    textAreaSettings: {
        textarea: []
    },
    CheckboxSettings: {
        checkbox: [
            B.conditionalOverride,
            { key: "display", components: B.ignoreKeys(["labelWidth", "labelMargin", "shortcut", "inputType", "tabindex", "autofocus", "modalEdit"]) },
            {
                key: "validation",
                components: [
                    ...B.JS_VALIDATION_SETTINGS,
                    ...B.ignoreKeys(["validate.required", "errorLabel", "validate.customMessage"]),
                ]
            },
            {
                key: "data",
                components: [
                    ...B.customValuesPanels,
                    ...B.ignoreKeys(["persistent", "protected", "dbIndex", "encrypted", "redrawOn", "calculateServer"]),
                ]
            },
        ]
    },
    NumFieldSettings: {
        number: [
            B.conditionalOverride,
            { key: "display", components: B.ignoreKeys(["labelPosition", "tabindex", "autofocus", "mask", "autocomplete", "spellcheck", "modalEdit"]) },
            {
                key: 'data',
                components: [
                    ...B.customValuesPanels,
                    ...B.ignoreKeys(["persistent", "delimiter", "requireDecimal", "inputFormat", "protected", "dbIndex", "encrypted", "redrawOn", "calculateServer"]),
                ]
            },
            {
                key: "validation",
                components: [
                    ...B.JS_VALIDATION_SETTINGS,
                    ...B.ignoreKeys(["validateOn", "errorLabel", "validate.customMessage"]),
                ]
            }
        ]
    },
    SelectBoxSettings: {
        selectboxes: [
            B.conditionalOverride,
            { key: "display", components: B.ignoreKeys(["labelPosition", "optionsLabelPosition", "tabindex", "autofocus", "modalEdit"]) },
            {
                key: "data",
                components: [
                    ...B.customValuesPanels,
                    ...B.ignoreKeys(["persistent", "protected", "dbIndex", "encrypted", "redrawOn", "calculateServer"])
                ]
            },
            {
                key: 'validation',
                components: [
                    ...B.JS_VALIDATION_SETTINGS,
                    ...B.ignoreKeys(["validate.minSelectedCount", "validate.maxSelectedCount", "validate.customMessage", "errorLabel", "validate.onlyAvailableItems", "minSelectedCountMessage", "maxSelectedCountMessage"])
                ]
            }
        ]
    },
    TextFieldSettings: {
        textfield: [
            B.conditionalOverride,
            { key: "api", components: B.ignoreKeys(["tags", "properties"]) },
            { key: "display", components: B.ignoreKeys(["labelPosition", "widget.type", "autofocus", "allowMultipleMasks", "inputMask", 'tabindex', "showWordCount", "showCharCount", "modalEdit"]) },
            {
                key: "data", components: [
                    ...B.ignoreKeys(["persistent", "protected", "dbIndex"]),
                    ...B.customValuesPanels,
                ]
            },
        ],
    },
    RadioButtonsSettings: {
        radio: [
            B.conditionalOverride,
            { key: "display", components: B.ignoreKeys(["labelPosition", "optionsLabelPosition", "tabindex", "autofocus", "modalEdit"]) },
            {
                key: "data", components: [
                    ...B.customValuesPanels,
                    ...B.ignoreKeys(["dataType", "persistent", "protected", "dbIndex", "encrypted", "redrawOn", "calculateServer"])
                ]
            },
            {
                key: 'validation', components: [
                    ...B.JS_VALIDATION_SETTINGS,
                    ...B.ignoreKeys(["validate.onlyAvailableItems", "errorLabel", "validate.customMessage"])
                ]
            }
        ]
    },
    PasswordFieldSettings: {
        password: [
            B.conditionalOverride,
            { key: "data", components: B.ignoreKeys(["case", "redrawOn", "calculateServer", "allowCalculateOverride"]) },
            { key: "display", components: B.ignoreKeys(["labelPosition", "placeholder", "prefix", "suffix", "tabindex", "autocomplete", "showWordCount", "showCharCount", "mask", 'autofocus', "spellcheck", "modalEdit"]) },
            {
                key: "validation",
                components: [
                    ...B.JS_VALIDATION_SETTINGS,
                    ...B.ignoreKeys(["validateOn", "errorLabel", "validate.customMessage"]),
                ]
            }
        ]
    },
    FileUploadCompSettings: {
        file: [
            B.conditionalOverride,
            { key: "display", components: B.ignoreKeys(["labelPosition", "tabindex", "autofocus", "modalEdit"]) },
            { key: "data", components: B.ignoreKeys(["persistent", "protected", "dbIndex", "encrypted", "redrawOn", "calculateServer", "allowCalculateOverride", "customDefaultValuePanel", "calculateValuePanel"]) },
            {
                key: "validation",
                components: [
                    ...B.JS_VALIDATION_SETTINGS,
                    ...B.ignoreKeys(["unique", "errorLabel", "validateOn", "validate.customMessage"])
                ]
            },
            {
                key: "file",
                components: [
                    {
                        type: 'select',
                        input: true,
                        key: 'newStorage',
                        label: 'Storage',
                        placeholder: 'Select your file storage provider',
                        weight: 0,
                        tooltip: 'Which storage to save the files in.',
                        valueProperty: 'value',
                        dataSrc: "values",
                        data: {
                            values: [
                                { label: "URL", value: 'url' },
                                { label: "Base 64", value: 'base64' },
                                { label: "Local Application", value: 'local' },
                            ]
                        }
                    },
                    {
                        type: 'textfield',
                        input: true,
                        key: 'newUrl',
                        label: 'Url',
                        weight: 8,
                        placeholder: 'Enter the url to post the files to.',
                        tooltip: "URL must accept GET, POST and DELETE requests",
                        conditional: { json: { in: [{ var: "data.newStorage" }, ["url"]] } }
                    },
                    {
                        type: "htmlelement",
                        tag: "div",
                        weight: 9,
                        content: `
                            <p>Les variables accessibles dans la construction de l'URL sont : </p>
                            <ul>
                                <li><b>data</b> : L'objet data de la soumission</li>
                                <li><b>_id</b> : L'_id de la soumission </li>
                                <li><b>name</b> : Le nom de l'image, précédé d'un id unique</li>
                            </ul>
                            <p>Pour accéder à ces variables, il faut les entourer du signe '$$'</p>
                            <small><i>Ex : </i>http://mydomain.com/api/$$data.name$$</small>
                        `,
                        conditional: { json: { in: [{ var: "data.newStorage" }, ["url"]] } }
                    },
                    ...B.ignoreKeys(["fileNameTemplate", "privateDownload", "storage", "url", "dir", "fileKey"])
                ]
            },
        ]
    },
}

const settingsList = Object.values(SETTINGS);

const noLogicLayout = [
    { key: 'logic', ignore: true },
    { key: 'layout', ignore: true },
    { key: "api", components: B.ignoreKeys(["tags", "properties"]) }
];

const SETTINGS_APPLIED_DEFAULTS = Object.fromEntries(
    Object.entries(_.merge(...settingsList))
        .map(([key, array]) => [key, (array || []).concat(noLogicLayout)])
);

const BASE_EDIT_FORM = {
    editForm: SETTINGS_APPLIED_DEFAULTS,
    builder: {
        layout: { components: Object.fromEntries(["fieldset"].map(key => [key, false])) },
        data: { components: Object.fromEntries(["tree", "editgrid"].map(key => [key, false])) },
        premium: { components: Object.fromEntries(["recaptcha", "resource", "custom", "form"].map(key => [key, false])) },
        advanced: { components: Object.fromEntries(["survey", "currency", "url", "phoneNumber", "tags", "day"].map(key => [key, false])) },
    }
};

export default BASE_EDIT_FORM;