import _ from "lodash";
import { useMemo } from "react";
import { validate } from "uuid";
import { T, TB } from "../../Constants";
import { useSelector } from "react-redux";

const getDefaultContext = (): T.FormContext => ({
    data: {},
    hidden: [],
    certif: [],
    errors: {},
    options: [],
    updates: [],
    readOnly: false,
    descriptions: [],
    hideAddButton: [],
    previous_edits: [],
    partial_certif: [],
});

const useFormConsumer = (prop: string, storeKey?: string): T.FormConsumerData => {
    const contextStore = useSelector((state: T.ReduxSelector) => state.formContext);
    const context = useMemo<T.FormContext>(() => contextStore.filter(s => s.key === storeKey)[0]?.context || getDefaultContext(), [contextStore, storeKey]);

    const [srcType, noOverride] = useMemo<[T.Sockets.Form.FormUpdateSrc, boolean]>(() => {
        let update = context.updates.find(u => u.prop === prop);
        return [update?.type || "db", !!update?.override || !!context.readOnly || update?.disable];
    }, [context.updates, context.readOnly, prop]);

    const fullSubmission = useMemo(() => {
        let copyData = _.cloneDeep(context.data);
        for (let update of context.updates) _.set(copyData, update.prop, update.value);
        return copyData;
    }, [context.data, context.updates]);

    const value = useMemo(() => {
        let update = context.updates.find(u => u.prop === prop);
        if (update) return update.value;
        return _.get(fullSubmission, prop);
    }, [context.updates, prop, fullSubmission]);

    const hidden = useMemo(() => {
        let state = _.find(context.hidden, h => h.prop === prop);
        return state ? state.hidden : false;
    }, [context.hidden, prop]);

    const hideAddButton = useMemo(() => {
        let state = _.find(context.hideAddButton, h => h.prop === prop);
        return state ? state.hidden : false;
    }, [context.hideAddButton, prop]);

    const last_certification = useMemo(() => {
        let last_partial = context.partial_certif.find(c => c.prop === prop);
        let last_saved = context.certif.find(c => c.prop === prop);
        return last_partial || last_saved;
    }, [prop, context.partial_certif, context.certif]);

    const description = useMemo(() => context.descriptions.find(d => d.prop === prop), [context.descriptions, prop]);
    const options = useMemo(() => context.options.filter(o => o.prop === prop)[0]?.options || [], [context.options, prop]);
    const last_edit = useMemo(() => context.previous_edits.find(e => e.property === prop), [context.previous_edits, prop]);

    return {
        value,
        hidden,
        srcType,
        options,
        last_edit,
        noOverride,
        hideAddButton,
        fullSubmission,
        last_certification,
        readonly: context.readOnly,
        error: context.errors[prop] || null,
        description: description?.description,
        description_style: description?.description_style,
        validContext: TB.validString(prop) && validate(storeKey),
    }
};

export default useFormConsumer;