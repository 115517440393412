import _ from "lodash";
import * as T from "../Types";
import { arrayWrapper } from "./array";
import { mongoIdValidator } from "./id";

export const getGroupMembers = (group: T.AllowArray<T.Submission<T.GroupData>>) => {
    let groups = arrayWrapper(group);
    let collect = groups.map(g => [g.data.admin, g.data.members]);
    return _.uniq(_.flattenDeep(collect)).filter(mongoIdValidator);
}

export const groupContainsUser = (group: T.Submission<T.GroupData>, user: string) => group.owner === user || group.data.admin?.includes?.(user) || group.data?.members?.includes?.(user);