import React, { FC, useMemo } from "react";
import { Preview, IPreviewProps, StatusValue, IFileWithMeta } from "react-dropzone-uploader";

export type GetPreviewUrl = (meta: IFileWithMeta, status: StatusValue) => string;

export type DropzonePreviewProps = IPreviewProps & {
    getPreviewUrl: GetPreviewUrl;
}

const DropzonePreview: FC<DropzonePreviewProps> = ({ getPreviewUrl, meta, ...props }) => {

    const url = useMemo(() => {
        if (meta.type.includes("image")) return getPreviewUrl?.(props.fileWithMeta, meta.status) || "";
        return meta.previewUrl;
    }, [meta, getPreviewUrl, props.fileWithMeta]);

    return <Preview {...props} meta={{ ...meta, previewUrl: url }} isUpload={meta.percent < 100} />;
}

export default DropzonePreview;