import { LanguageProps } from "../Types";

type LgItem = {
    /** A hard-coded name for the language */
    lg: string;
    /** The code to identify the language's flag */
    code: string;
    /** The language's index number */
    index: number;
    /** The property of the languages in a translation's object */
    prop: LanguageProps;
}

//#region Indexes
export const FR = 0;
export const EN = 1;
export const DU = 2;
export const IT = 3;
export const SP = 4;
export const DE = 5;
export const FR_BE = 6;
export const EN_US = 7;
//#endregion

//#region Prop
export const DU_PROP = "nl";
export const IT_PROP = "it";
export const SP_PROP = "es";
export const AR_PROP = "ar";
export const DE_PROP = "de";
export const FR_PROP = "fr_fr";
export const EN_PROP = "en_us";
export const FR_BE_PROP = "fr_be";
export const EN_UK_PROP = "en_uk";

export const ALL_PROPS: LanguageProps[] = [FR_PROP, FR_BE_PROP, EN_UK_PROP, EN_PROP, DU_PROP, DE_PROP, SP_PROP, IT_PROP];
//#endregion

export const MOMENT_LOCAL = (language = 0) => {
    switch (language) {
        case FR: return 'fr';
        case FR_BE: return 'fr';
        case IT: return 'it';
        case DU: return 'nl';
        case SP: return 'es';
        case DE: return 'de';
        case EN: return 'en-gb';
        default: return 'en-gb';
    }
};

export const getHtmlLanguageCode = (language?: LanguageProps | number) => {
    if (typeof language === "number") {
        if (language === FR) return 'fr';
        else if (language === IT) return 'it';
        else if (language === DU) return 'nl';
        else if (language === DE) return 'de';
        else if (language === SP) return 'es';
        else if (language === EN) return 'en';
        else if (language === FR_BE) return 'fr';
        else return 'en';
    }
    else if (language === "nl") return "nl";
    else if (language === "it") return "it";
    else if (language === "es") return "es";
    else if (language === "de") return "de";
    else if (language === "fr_fr") return "fr";
    else if (language === "en_us") return "en";
    else if (language === "fr_be") return "fr";
    else if (language === "en_uk") return "en";
    else return "en";
};

export const get_lang_label = (prop: LanguageProps) => ALL_LANGUAGES.filter(l => l.prop === prop)[0]?.lg || "";

export const ALL_LANGUAGES: LgItem[] = [
    { lg: "Français (FR)", code: "FR", index: FR, prop: FR_PROP },
    { lg: "Français (BE)", code: "FR-BE", index: FR_BE, prop: FR_BE_PROP },
    { lg: "English", code: "EN", index: EN, prop: EN_UK_PROP },
    { lg: "English (USA)", code: "EN-US", index: EN_US, prop: EN_PROP },
    { lg: "Nederlands", code: "DU", index: DU, prop: DU_PROP },
    { lg: "Italiano", code: "IT", index: IT, prop: IT_PROP },
    { lg: "Español", code: "SP", index: SP, prop: SP_PROP },
    { lg: "Deutsch", code: "DE", index: DE, prop: DE_PROP },
];

export const LANGUAGES_INDEXES = ALL_LANGUAGES.map(l => l.index);