import { FilePicker, FilePickerProps } from "../Components";
import renderInContainer from "../getContainer";

export type AskFileModalProps = Omit<FilePickerProps, "onValidate" | "onQuit">;

const askFileModal = (params?: AskFileModalProps) => new Promise<Parameters<FilePickerProps["onValidate"]>[0]>(resolve => {
    const [render, dismount] = renderInContainer();

    if (render && dismount) {
        const onClose = () => dismount(() => resolve(null));
        const onValidate: FilePickerProps["onValidate"] = files => dismount(() => resolve(files));
        render(<FilePicker
            {...params}
            onQuit={onClose}
            onValidate={onValidate}
        />);
    }
    else resolve(null);
});

export default askFileModal;