import * as T from "../Types";
import * as N from "./number";

/**
 * Filter the results of the data API to only keep the target with at least one valid data
 */
export const api_results_with_data = (data: T.Query.APIResults): T.Query.APIResults => {
    if (!Array.isArray(data) || data.length === 0) return [];
    else return data.filter(d => {
        // No datapoints means no data
        if (!Array.isArray(d.datapoints) || d.datapoints.length === 0) return false;
        // Not as single value is a number, means no data
        else if (!d.datapoints.some(dp => typeof dp[0] === "number" && !isNaN(dp[0]))) return false;
        // Data is valid
        else return true;
    });
}

/**
 * Get the total sum of an array of datapoints from the data API
 */
export const api_results_datapoints_sum = (data: T.Query.APIResults[number]): number => {
    if (!Array.isArray(data.datapoints) || data.datapoints.length === 0) return 0;
    else return data.datapoints.reduce((acc_dp, dp) => acc_dp + N.getNumber(dp[0], 0), 0);
}

/**
 * Get the total sum of a whole set of results from the data API
 */
export const api_results_sum = (data: T.Query.APIResults): number => {
    if (!Array.isArray(data) || data.length === 0) return 0;
    else return data.reduce((acc, d) => acc + api_results_datapoints_sum(d), 0);
}