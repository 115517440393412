import { TB, T } from "../Constants";
import { useCallback, useState } from "react";

type State<A extends string> = T.AsyncStates<A>;
type SetAsyncStatus<S extends string = ""> = (state: State<S>) => void;
export type SetAsyncState<A = any, S extends string = ""> = (data: ((p: A) => A) | A | undefined, state?: State<S>) => void;
type useAsyncStateReturn<A = any, S extends string = ""> = [A, SetAsyncState<A, S>, State<S>, SetAsyncStatus<S>];

const isCb = <A>(p: any): p is ((p: A) => A) => typeof p === "function";

const useAsyncState = <A = any, S extends string = "">(init?: A, initState?: State<S>): useAsyncStateReturn<A, S> => {
    const [data, setData] = useState<A | undefined>(init);
    const [state, setState] = useState<State<S>>(initState || "load");

    const setAsyncState = useCallback<SetAsyncState<A, S>>((data, state) => {
        if (isCb<A>(data)) setData(p => data(p));
        else setData(data);
        if (TB.validString(state)) setState(state);
    }, []);

    const setStatus = useCallback<SetAsyncStatus<S>>(status => {
        if (TB.validString(status)) setState(status);
    }, []);

    return [data, setAsyncState, state, setStatus];
}

export default useAsyncState;