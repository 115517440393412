import { TB } from "../../Constants";
import { FC, ReactNode, useMemo } from 'react';

type BackgroundProps = {
    style?: {};
    image: string;
    video?: string[];
    className?: string;
    children?: ReactNode;
    overlay?: string | boolean;
    position?: string | { x: string, y: string };
}

const Background: FC<BackgroundProps> = ({ image, overlay, position, video, className, style }) => {

    const bgStyle = useMemo(() => ({
        ...style,
        backgroundImage: `url(${image})`,
    }), [image, style]);

    const fullBgStyle = useMemo(() => {
        if (typeof position === 'string') return { ...bgStyle, backgroundPosition: position };
        else if (TB.validObject(position)) return {
            ...bgStyle,
            backgroundPositionX: position.x,
            backgroundPositionY: position?.y,
        }
        else return bgStyle;
    }, [bgStyle, position]);

    const videoArray = useMemo(() => Array.isArray(video) ? video.filter(TB.validString) : [], [video]);
    const containerClass = useMemo(() => `bg-holder ${TB.validString(overlay) ? "overlay-" + overlay : ""} ${TB.validString(className) ? className : ""}`, [overlay, className]);

    return <div className={containerClass} style={fullBgStyle}>
        {videoArray.length > 0 && <video className="bg-video" autoPlay loop muted playsInline>
            {videoArray.map((src, index) => <source key={index} src={src} type={`video/${src.split('.').pop()}`} />)}
        </video>}
    </div>
};

export default Background;
