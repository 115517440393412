import * as S from "../../services";
import { T, TB } from "../../Constants";
import { InputGroup } from "react-bootstrap";
import InnerHtml from "dangerously-set-html-content";
import { Button, Flex, Form, Spinner } from "../../Common";
import { useAsyncState, useRoots, useCrumbs } from "../../hooks";
import React, { useCallback, useEffect, useMemo, useState } from "react";

export type RentersDashboardProps = {};
type Renters = ReturnType<T.API.Report.Dashboard.GetRenters>;

const RentersDashboard: React.FC<RentersDashboardProps> = props => {
    const [context] = useRoots();
    useCrumbs("todo Locataires");
    const [selected, setSelected] = useState([]);
    const [html, setHtml, htmlStatus] = useAsyncState("", "done");
    const [renters, setRenters, status] = useAsyncState<Renters>([]);

    //#region Renters List
    useEffect(() => {
        let isSubscribed = true;
        S.getRenters(context)
            .then(({ data }) => isSubscribed && setRenters(data, "done"))
            .catch(() => isSubscribed && setRenters([], "error"));
        return () => { isSubscribed = false };
    }, [context, setRenters]);

    const rentersOptions = useMemo(() => renters.map(r => ({
        value: r.id,
        label: r.name,
        rental: r.rental.map(r => r.name).join(", "),
        enseigne: r.enseigne.map(e => e.name).join(", "),
    })), [renters]);
    //#endregion

    //#region Select
    const typeaheadProps = useMemo<Form.SelectProps["typeahead"]>(() => ({
        renderItem: (a: typeof rentersOptions[number]) => <div>
            {a.label}
            {a.enseigne && <span className="text-muted mx-2">({a.enseigne})</span>}
            {a.rental && <span className="fw-bold fs-75 me-2">{a.rental}</span>}
        </div>,
    }), []);

    const load = useCallback(() => {
        setHtml("", "load");
        S.html_report({ template: "PortfolioEnseigne", params: { context: JSON.stringify(context), renter: selected } })
            .then(({ data }) => {
                if (TB.validString(data)) setHtml(data, "done");
                else setHtml("", "error");
            })
            .catch(() => setHtml("", "error"))
    }, [context, selected, setHtml]);

    const selectAll = useCallback(() => setSelected(rentersOptions.map(r => r.value)), [rentersOptions]);
    //#endregion

    return <Spinner status={status}>
        <Flex direction="column" className="w-100">
            <div className="mb-3">
                <InputGroup className="h-100">
                    <Button size="sm" onClick={load}>
                        load
                    </Button>
                    <Form.Select
                        multiple
                        noBottomMargin
                        value={selected}
                        onChange={setSelected}
                        options={rentersOptions}
                        customClass="flex-grow-1"
                        typeahead={typeaheadProps}
                    />
                    <Button size="sm" variant="danger" onClick={selectAll}>
                        *
                    </Button>
                </InputGroup>
            </div>
            <div className="flex-grow-1 mb-3">
                <Spinner status={htmlStatus}>
                    <InnerHtml className="w-100 h-100" html={html} />
                </Spinner>
            </div>
        </Flex>
    </Spinner>;
}

export default RentersDashboard;