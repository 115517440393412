import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Navigator } from "../../Navigator";
import { Breadcrumb } from "react-bootstrap";
import { TC, FP, T, TB } from "../../../Constants";
import { useLanguage, useWindowSize } from "../../../hooks";
import React, { FC, useCallback, useEffect, useMemo } from "react";

const CrumbsMenu: FC = ({ ...props }) => {
    const { isMobile } = useWindowSize();
    const { crumbs } = useSelector((redux: T.ReduxSelector) => redux);
    const { getStaticText, fetchStaticTranslations } = useLanguage([]);
    const validCrumbs = useMemo(() => TB.isValidCrumb(crumbs) ? crumbs : undefined, [crumbs]);

    const homeCrumbs = useMemo(() => <Breadcrumb.Item as="div">
        <Navigator />
    </Breadcrumb.Item>, []);

    const crumbSort = useCallback((a: T.CrumbsRedux, b: T.CrumbsRedux) => {
        if (a.isContext && b.isContext) return 0;
        if (b.isContext) return 1;
        if (a.isContext) return -1;
        return 0;
    }, []);

    const crumbsArray = useMemo(() => {
        let array: T.CrumbsRedux[] = [];

        const recursive = (crumb?: T.CrumbsRedux) => {
            if (TB.isValidCrumb(crumb)) {
                array.push(crumb);
                recursive(crumb.child);
            }
        }
        recursive(validCrumbs);
        return array;
    }, [validCrumbs]);

    const textCodesArray = useMemo(() => Object.keys(TC).concat(FP.RESOURCE_FORMS), []);

    useEffect(() => {
        let textCodes = crumbsArray.filter(({ label }) => textCodesArray.includes(label)).map(({ label }) => label);
        fetchStaticTranslations(textCodes);
    }, [crumbsArray, textCodesArray, fetchStaticTranslations]);

    const restCrumbs = useMemo(() => crumbsArray
        .sort(crumbSort)
        .map(({ link, ...r }) => ({ ...r, link: TB.validString(link) ? link : undefined }))
        .map(({ label, link }, i) => {
            let okLabel = textCodesArray.includes(label) ? getStaticText(label) : label;

            if (TB.validString(link)) return <Breadcrumb.Item key={i} linkAs={Link} linkProps={{ to: link }}>{okLabel}</Breadcrumb.Item>;
            return <Breadcrumb.Item key={i}>{okLabel}</Breadcrumb.Item>
        }), [crumbsArray, textCodesArray, crumbSort, getStaticText]);

    if (isMobile) return homeCrumbs;
    return <>
        <Breadcrumb style={{ fontSize: "1.3rem" }} >
            {homeCrumbs}
            {restCrumbs}
        </Breadcrumb>
    </>
}

export default CrumbsMenu;