import { T, TB } from "../../../Constants";
import React, { FC, useCallback, useMemo } from "react";
import { Input, InputProps, ComponentWrapper } from "../Input";

export type ContainerProps = InputProps & {
    components?: T.FormComponentsMain[];
};

const Container: FC<ContainerProps> = ({ components, onChange, ...props }) => {

    const vComponents = useMemo(() => TB.getArray(components).filter(TB.validObject), [components]);
    const onChangeValue = useCallback((val: any, key: string) => onChange?.(val, props.prop + "." + key), [onChange, props.prop]);

    return <ComponentWrapper {...props}>
        {vComponents.map((c, i, a) => <Input
            {...c}
            formId={props.formId}
            prop={props.prop + "." + c.key}
            contextKey={props.contextKey}
            submissionId={props.submissionId}
            noBottomMargin={i === a.length - 1}
            onAddCertification={props.onAddCertification}
            onChange={(v, p) => onChangeValue(v, p || c.key)}
        />)}
    </ComponentWrapper>
}

export default Container;