import _ from "lodash";
import * as T from "../Types";

/**
 * Return all the items that have the maximum value provided by the comparator
 * @param compare A function to use on the items in the array, that will provide the value to compare
 */
export const multipleMax = <A = any>(arr: A[], compare: (item: A) => string | number) => {
    let max = _.maxBy(arr, compare);
    if (max === undefined) return [];
    return arr.filter(i => compare(i) === compare(max));
}

/**
 * Find the elements that is the most present in an array, returns the first found in there is an equality
 */
export const mostFrequent = <A,>(array: A[]): A => _.head(_(array).countBy().entries().maxBy(_.last)) as any;

/**
 * Return params if params is an array, or return an array containing param
 */
export const arrayWrapper = <O = any>(obj: T.AllowArray<O>): O[] => Array.isArray(obj) ? obj : [obj];

/**
 * Check if two array have the same strings or numbers in it
 */
export const arraySimilar = (array1?: (number | string)[], array2?: (number | string)[]) => {
    if (!Array.isArray(array1) && !Array.isArray(array2)) return array1 === array2;
    else if (!Array.isArray(array1) || !Array.isArray(array2)) return false;
    else if (array1.length !== array2.length) return false;
    else {
        let hasValuesInArray2 = array1.every(v => array2.includes(v));
        let hasValuesInArray1 = array2.every(v => array1.includes(v));
        return hasValuesInArray1 && hasValuesInArray2;
    }
}

/**
 * Returns only the elements that are presents in both arrays
 * @description Only performs a simple equality test (===)
 */
export const arrayCommonItem = <A = unknown>(array1: A[], array2: A[]): A[] => {
    let [a1, a2] = [array1, array2].map(a => getArray(a));
    return a1.filter(x => a2.some(y => x === y));
}

/**
 * Transform a value to an array, or a default option if provided
 * @param def The default value, to return if the first parameter is not an array
 */
export const getArray = <O>(array?: O[], def?: O[]): O[] => Array.isArray(array) ? array : (Array.isArray(def) ? def : []);

/**
 * Check if every items of an array are the same
 */
export const allEqualArray = (arr: unknown[]) => Array.isArray(arr) && arr.every(val => val === arr[0]);

/**
 * Move an element from it's original index to an other one
 */
export const moveElement = <A>(array: A[], fromIndex: number, toIndex: number) => {
    // Check the index to avoid array limits
    if (fromIndex < 0 || fromIndex >= array.length || toIndex < 0 || toIndex > array.length) return array;
    const elementToMove = array[fromIndex];
    // If the elements must be inserted after it's original index, adjust the target index
    if (fromIndex < toIndex) toIndex--;
    // Remove the element from it's original target
    array.splice(fromIndex, 1);
    // Insert the element at it's target index
    array.splice(toIndex, 0, elementToMove);
    return array;
}