import React from "react";
import { Flex } from "../../Layout";
import * as H from "../../../hooks";
import * as BS from "react-bootstrap";
import { InputProps } from "./InputTypes";
import { Form } from "../../../Components";
import { FP, T, TB } from "../../../Constants";
import { Feedback, HtmlText } from "../../Item";

const NO_LABEL_COMP = ["panel", "columns", "table", "tabs", "content", "well", "htmlelement", "container"];
const CERTIF_TYPES = ["textarea", "textfield", "time", "select", "checkbox", "datetime", "email", "number", "radioBool"] as InputProps["type"][];

type ComponentProps = InputProps & {
    /** The input to display */
    children?: React.ReactNode;
    /** A function to get the current value of the input in a string format */
    get_str_value?: () => string;
};

const ComponentDefault: React.FC<ComponentProps> = ({ type, srcType, hideLabel, label, labelPosition, description, children, get_str_value, ...props }) => {
    const lg = H.useLanguage();
    const isDark = H.useDark();
    const [forms] = H.useFormIds();

    //#region ToolTip
    const renderToolTip = React.useCallback((p) => <BS.Tooltip {...p} className={TB.getString(p.className) + " z-index-higher-modal"}>
        {typeof props.tooltip === "string"
            ? <HtmlText html={lg.getStaticText(props.tooltip)} />
            : props.tooltip}
    </BS.Tooltip>, [props.tooltip, lg]);

    const tip = React.useMemo(() => props.tooltip && <BS.OverlayTrigger overlay={renderToolTip} children={<i className="fa fa-question-circle ms-2" />} />, [renderToolTip, props.tooltip]);
    //#endregion

    //#region Label
    const noLabel = React.useMemo(() => hideLabel || NO_LABEL_COMP.includes(type), [hideLabel, type]);

    const show_certif = React.useMemo(() => {
        // Can't certify a disabled value
        if (props.disabled || props.readonly) return false;
        // Can't certify a value if no callback provided
        if (typeof props.onAddCertification !== "function") return false;
        // Can only certify some types of submissions
        if (!FP.GROUP_CERTIFIABLE.some(path => forms[path] === props.formId)) return false;
        // Can't certify if no form or submission id provided, or if the type is not certifiable
        return TB.multiMongoIdValidator([props.formId, props.submissionId]) && CERTIF_TYPES.includes(type);
    }, [props.formId, props.submissionId, type, props.onAddCertification, props.disabled, props.readonly, forms]);

    const certif_context = React.useMemo(() => {
        if (!props.submissionId) return null;
        else if (props.formId === forms[FP.REMARQUES_DEFAULTS]) return (props.fullSubmission as T.RemarqueDefault)?.element;
        else return props.submissionId;
    }, [props.submissionId, props.fullSubmission, props.formId, forms]);

    const srcIcon = React.useMemo(() => {
        if (!srcType) return null;
        let icon = <i title={srcType} className="fa fa-database me-2"></i>;
        if (srcType === "default") icon = <i title={srcType} className="fa fa-star me-2"></i>;
        else if (srcType === "manual") icon = <i title={srcType} className="fa fa-keyboard me-2"></i>;
        else if (srcType === "calculated") icon = <i title={srcType} className="fa fa-calculator me-2"></i>;
        return icon;
    }, [srcType]);

    const vLabel = React.useMemo(() => {
        if (noLabel) return null;

        let contentLabel = null;
        if (React.isValidElement(label)) return label;
        else {
            let typeLabel = label as Exclude<typeof label, React.ReactElement>;
            if (typeof typeLabel !== "string" && TB.mongoIdValidator(typeLabel?._id)) contentLabel = lg.getElemObj(typeLabel._id, typeLabel.prop, typeLabel.text || typeLabel.prop);
            else if (typeof typeLabel === "string") contentLabel = lg.getTextObj(props.formId, props.prop, lg.getStaticText(typeLabel));
            let required = props?.validate?.required || props.required ? <span className="text-danger ms-1">*</span> : null;

            return <div className={"mr-3 flex-grow-1" + (isDark ? "text-secondary" : "")}>
                {srcIcon}
                {show_certif && <Form.Certification.ToolTip
                    prop={props.prop}
                    form={props.formId}
                    value={props.value}
                    last_edit={props.last_edit}
                    item_id={props.submissionId}
                    context={{ roots: certif_context }}
                    last_certif={props.last_certification}
                    onCertification={props.onAddCertification}
                    str_value={get_str_value?.() || props.value?.toString?.()}
                />}
                {contentLabel}
                {required}
                {tip}
            </div>
        }
    }, [label, props.last_certification, props.formId, props.last_edit, props.prop, props?.validate?.required, props.onAddCertification, props.required, certif_context, noLabel, srcIcon, lg, tip, isDark, show_certif, get_str_value, props.submissionId, props.value]);
    //#endregion

    //#region Layout
    const labelPlace = React.useMemo(() => noLabel ? "top" : labelPosition || "top", [labelPosition, noLabel]);

    const layout = React.useMemo(() => {
        if (labelPlace === "top" || labelPlace === "bottom") return <BS.Form.Group>
            {labelPlace === "top" && vLabel}
            {children}
            {labelPlace === "bottom" && vLabel}
        </BS.Form.Group>;

        let margin = props.labelMargin ? "mb-2" : "mb-0";
        let maxWidth = props.labelFullWidth ? "80%" : "50%";
        let width = type === "checkbox" || type === "radioBool" ? "75%" : "33%";

        return <Flex justifyContent="between" alignItems="center">
            {labelPlace === "left" && <div className={margin} style={{ maxWidth, width }}>{vLabel}</div>}
            {children}
            {labelPlace === "right" && <div className={`${margin} ms-3`} style={{ maxWidth }}>{vLabel}</div>}
        </Flex>
    }, [children, vLabel, labelPlace, type, props.labelMargin, props.labelFullWidth]);
    //#endregion

    //#region Error
    const [text, isValid] = React.useMemo(() => {
        if (props.validFeedback) return [props.validFeedback, true];
        else if (typeof props.error === "string") return [lg.getStaticText(props.error), false];
        else if (props.error?.code) {
            if (props.error?.template) return [lg.getStaticText(props.error.code, props.error.template), false];
            return [props.error.code, false];
        }
        else return [null, true];
    }, [props.validFeedback, props.error, lg]);
    //#endregion

    //#region Class & Description
    const classes = React.useMemo(() => {
        let classes = [] as string[];
        if (!props.noBottomMargin) classes.push("mb-3");
        if (typeof props.customClass === "string") classes.push(props.customClass);
        return classes.join(" ");
    }, [props.customClass, props.noBottomMargin]);

    const descriptionContent = React.useMemo(() => {
        let classes = "";
        let description_content: React.ReactElement;

        if (props.description_style === "green_tip") classes = "text-success text-end";

        if (!description) return null;
        else if (typeof description === "string") description_content = <div><BS.Form.Text className={classes} children={lg.getStaticElem(description)} /></div>;
        else if (typeof (description as any)?.ref === "string") description_content = <div><BS.Form.Text className={classes} children={lg.getStaticElem((description as any).ref, (description as any).template)} /></div>;
        else if (React.isValidElement(description)) description_content = description;

        return description_content;
    }, [lg, description, props.description_style]);
    //#endregion

    return !props.hidden && <div className={classes}>
        {layout}
        {(!props.disabled || props.show_error_when_disabled) && <Feedback text={text} isValid={isValid} isTextSmall={props.smallErrorText} />}
        {descriptionContent}
    </div>
}

export default ComponentDefault;