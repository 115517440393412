import ReactDOM from 'react-dom';
import DefaultComp from './NoRenderComp';
import { ReactComponent } from 'react-formio';

export default class Actions extends ReactComponent {
    static get builderInfo() {
        return {
            weight: 1,
            group: "Custom",
            title: "Actions",
            documentation: "",
            schema: Actions.schema(),
            icon: "exclamation-triangle",
        };
    }

    static schema = () => ReactComponent.schema({ type: "Actions", label: "Actions" });
    attachReact = element => ReactDOM.render(<DefaultComp />, element);
    detachReact = element => element ? ReactDOM.unmountComponentAtNode(element) : undefined;
}