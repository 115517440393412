import React from "react";
import * as M from "../Modal";
import * as H from "../../hooks";
import * as C from "../../Common";
import * as S from "../../services";
import * as BS from "react-bootstrap";
import * as PM from "../../PurposeModal";
import { FP, T, TB, TC, REGIONS, LT, RESOURCE } from "../../Constants";

//#region Types
type FieldQuestion = { type: "text" };
type RadioBoolQuestion = { type: "radioBool" };
export type UnMountRef = { save?: () => Promise<any>, askSave: boolean };
type NumQuestion = { type: "num", unit?: string, min?: number, max?: number };
type Element<T = Record<string, any>> = T.API.Reg.GetRegTabResultsElements<T>;
type ArrayQuestion = Question<NumQuestion | RadioBoolQuestion | SelectQuestion | FieldQuestion>;
type SelectQuestion = { type: "select", options: { label: string, value: string }[], status: T.AsyncStates };
type Question<A extends {} = {}> = { prop: string, regions?: string[], onChange?: (_id: string) => void } & A;

type RegDataFillerProps = {
    api: React.MutableRefObject<UnMountRef | null>;
    /** The elements found in the context */
    elements: ReturnType<T.API.Reg.GetRegTabElements>["elements"];
    save: (changes: T.DataChange[]) => Promise<any>;
    /** Do not allow any edits */
    read_only?: boolean;
    /** Callback to ask for a reload of the resources */
    reload: () => Promise<any>;
    /** THe current context */
    context?: T.ContextParams;
}
//#endregion

//#region Constants
const TEXT_CODES = [
    FP.BUILDING_FORM, TC.REG_SHOW_ONLY_OCCUPATION, FP.SITE_FORM, FP.EMPLACEMENT_FORM, TC.GLOBAL_FLOOR, TC.GLOBAL_LOCAL, TC.GLOBAL_ZONE, TC.GLOBAL_SAVE,
    TC.GLOBAL_NO_X_CONTEXT,
];
//#endregion

const RegDataFiller: React.FC<RegDataFillerProps> = ({ save, reload, api, ...props }) => {
    const [formIds] = H.useFormIds();
    const banner = H.useBoolean(true);
    const saving = H.useBoolean(false);
    const isMounted = H.useIsMounted();
    const lg = H.useLanguage(TEXT_CODES);
    const showOnlyOccupation = H.useBoolean(true);
    const [changes, setChanges] = React.useState<T.DataChange[]>([]);
    const [countries, setCountries, countryStatus, setCountryStatus] = H.useAsyncState<T.Option[]>([], "load");
    const [regions, setRegions, regionStatus, setRegionStatus] = H.useAsyncState<T.API.Geo.RegionOptions[]>([], "done");
    const [affectations, setAffectations, affectationStatus] = H.useAsyncState<T.Option<{}, T.EmplacementData["actifType"]>[]>([]);

    //#region Language
    const relevantForms = React.useMemo(() => [FP.SITE_FORM, FP.BUILDING_FORM, FP.EMPLACEMENT_FORM].map(p => formIds[p]), [formIds]);

    React.useEffect(() => lg.fetchObjectTranslations(relevantForms), [lg, relevantForms]);
    //#endregion

    //#region Elements
    const elements = React.useMemo(() => props.elements || [], [props.elements]);
    const sites = React.useMemo<Element<T.SiteData>[]>(() => elements.filter(e => e.path === FP.SITE_FORM) as any, [elements]);
    const buildings = React.useMemo<Element<T.BuildingData>[]>(() => elements.filter(e => e.path === FP.BUILDING_FORM) as any, [elements]);
    const emplacementsList = React.useMemo<Element<T.EmplacementData>[]>(() => elements.filter(e => e.path === FP.EMPLACEMENT_FORM) as any, [elements]);

    const emplacements = React.useMemo(() => {
        if (showOnlyOccupation.value) return emplacementsList.filter(e => e.submission.data.isOccupation);
        else return emplacementsList;
    }, [emplacementsList, showOnlyOccupation]);
    //#endregion

    //#region Load Countries, regions & affects
    const currentCountry = React.useMemo(() => {
        let countries = sites.map(s => {
            let changedCountry = changes.filter(c => c._id === s.submission._id && c.prop === "country")[0]?.value;
            return TB.getString(changedCountry, s.submission.data.country);
        }).filter(TB.validString);

        return TB.mostFrequent(countries);
    }, [sites, changes]);

    const currentRegion = React.useMemo(() => {
        let regions = sites.map(s => {
            let changedRegion = changes.filter(c => c._id === s.submission._id && c.prop === "region")[0]?.value;
            return TB.getString(changedRegion, s.submission.data.region);
        }).filter(TB.mongoIdValidator);

        return TB.mostFrequent(regions);
    }, [sites, changes]);

    const activeRegionsCodes = React.useMemo(() => {
        let descendantsRegionsIds = regions.filter(r => r.value === currentRegion)?.[0]?.parents;
        if (!descendantsRegionsIds) return [];
        return regions.filter(r => descendantsRegionsIds.includes(r.value) && TB.validString(r.code)).map(r => r.code);
    }, [regions, currentRegion]);

    React.useEffect(() => {
        let isSubscribed = true;

        S.getCountries()
            .then(({ data }) => isSubscribed && setCountries(data, "done"))
            .catch(() => isSubscribed && setCountryStatus("error"));

        return () => { isSubscribed = false };
    }, [setCountries, setCountryStatus]);

    React.useEffect(() => {
        let isSubscribed = true;
        if (TB.validString(currentCountry)) S.getRegions(currentCountry)
            .then(({ data }) => isSubscribed && setRegions(data, "done"))
            .catch(() => isSubscribed && setRegions([], "error"));
        else setRegions([], "done");

        return () => { isSubscribed = false };
    }, [setRegions, currentCountry]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getEmplacementResource({ type: "emplacement_affectation" })
            .then(({ data }) => isSubscribed && setAffectations(data as typeof affectations, 'done'))
            .catch(() => isSubscribed && setAffectations([], "error"));
        return () => { isSubscribed = false };
    }, [setAffectations]);
    //#endregion

    //#region DataChange
    const onChangeData = React.useCallback((id: string, prop: string, value?: any) => {
        setChanges(p => {
            let exists = p.filter(c => c.prop === prop && c._id === id).length > 0

            // Update Existing entry
            if (exists) return p.map(c => c.prop === prop && c._id === id ? { ...c, value } : c);
            // Add new Entry
            else return p.concat({ _id: id, prop, value });
        })
    }, []);

    const getValue = React.useCallback((id: string, prop: string) => {
        let dataChange = changes.filter(c => c.prop === prop && c._id === id);

        if (dataChange.length > 0) return dataChange[0].value;

        let elem = elements.filter(e => e.submission._id === id);
        if (elem.length > 0) return elem[0].submission.data[prop];
        return;
    }, [elements, changes]);

    const onSave = React.useCallback(() => {
        let promise = save?.(changes);
        saving.setTrue();

        if (promise) promise
            .then(() => isMounted() && setChanges([]))
            .finally(() => isMounted() && saving.setFalse());
        return promise;
    }, [changes, saving, save, isMounted]);

    React.useEffect(() => {
        if (api) {
            if (api.current === null) api.current = { save: onSave, askSave: changes.length > 0 };
            else {
                api.current.save = onSave;
                api.current.askSave = changes.length > 0;
            }
        }
    }, [onSave, changes.length, api]);

    const saveIcon = React.useMemo<C.ButtonProps["icon"]>(() => saving.value ? ({ icon: "circle-notch", spin: true }) : "save", [saving.value]);
    //#endregion

    //#region Question
    const renderQuestion = React.useCallback((q: ArrayQuestion, id: string, path: string) => {
        let htmlQuestion = null;
        if (Array.isArray(q.regions) && !q.regions.some(r => activeRegionsCodes.includes(r))) return null;

        let value = getValue(id, q.prop);

        const onChange = (value?: any) => {
            q.onChange?.(id);
            onChangeData(id, q.prop, value);
        }

        if (q.type === "radioBool") htmlQuestion = <C.Form.RadioBool
            value={value}
            name={id + q.prop}
            onChange={onChange}
            disabled={props.read_only}
        />;
        else if (q.type === "select") htmlQuestion = <C.Form.ComponentWrapper disabled={props.read_only} customClass="flex-grow-1 ml-3">
            <C.TypeAhead
                options={q.options}
                selectedItems={value}
                disabled={props.read_only}
                onChange={o => onChange(o?.[0]?.value)}
            />
        </C.Form.ComponentWrapper>
        else if (q.type === "num") htmlQuestion = <C.Form.ComponentWrapper disabled={props.read_only} customClass="flex-grow-1 ml-3">
            <BS.InputGroup>
                <BS.Form.Control disabled={props.read_only} type="number" min={q.min} max={q.max} defaultValue={value || ""} onBlur={e => onChange(parseFloat(e.target.value) || "")} />
                {q.unit && <BS.InputGroup.Text children={q.unit} />}
            </BS.InputGroup>
        </C.Form.ComponentWrapper>
        else htmlQuestion = <C.Form.ComponentWrapper disabled={props.read_only} customClass="flex-grow-1 ml-3">
            <BS.InputGroup>
                <BS.Form.Control
                    disabled={props.read_only}
                    defaultValue={value || ""}
                    onBlur={e => onChange(e.target.value || "")}
                />
            </BS.InputGroup>
        </C.Form.ComponentWrapper>

        return <BS.Col key={q.prop} md={6}>
            <C.Flex className="mb-2" alignItems="center" justifyContent="between">
                <BS.Form.Label className="mr-2 mb-0">{lg.getElemObj(formIds[path], q.prop, q.prop)}</BS.Form.Label>
                {htmlQuestion}
            </C.Flex>
        </BS.Col>
    }, [onChangeData, getValue, lg, activeRegionsCodes, formIds, props.read_only]);
    //#endregion

    //#region Sites
    const onChangeCountry = React.useCallback((id: string) => {
        setRegionStatus("load");
        onChangeData(id, "region", undefined);
    }, [setRegionStatus, onChangeData]);

    const siteQuestions = React.useMemo<ArrayQuestion[]>(() => [
        { type: "select", prop: "country", options: countries, status: countryStatus, onChange: onChangeCountry },
        { type: "select", prop: "region", options: regions, status: regionStatus },
        { type: "radioBool", prop: "requiresEtudeSol" },
        { type: "radioBool", prop: "requiresPE" },
        { type: "radioBool", prop: "requiresAvisIncendie" },
        { type: "radioBool", prop: "requiresGrosConsommateurRBC", regions: [REGIONS.BE_RBC] },
    ], [countries, regions, regionStatus, countryStatus, onChangeCountry]);

    const renderSiteForm = React.useCallback((site: Element<T.SiteData>) => <BS.Row key={site.submission._id} className="mb-3">
        <BS.Col md={12}>
            <C.Falcon.Title titleTag={site.submission.data.name} />
        </BS.Col>
        <BS.Col md={12}>
            <BS.Row className="mb-2">{siteQuestions.map(q => renderQuestion(q, site.submission._id, FP.SITE_FORM))}</BS.Row>
        </BS.Col>
    </BS.Row>, [siteQuestions, renderQuestion]);
    //#endregion

    //#region Builds
    const buildQuestions = React.useMemo<ArrayQuestion[]>(() => [
        { type: "num", prop: "ENT_TECH_BUILD_YEAR", min: 0, max: 9999 },
        { type: "num", prop: "surfaceNFA", unit: "m²", min: 0 },
        { type: "num", prop: "surfaceHEAT", unit: "m²", min: 0 },
        { type: "select", prop: "affectation", options: affectations, status: affectationStatus },
        { type: "radioBool", prop: "publicOccupiedWA", regions: [REGIONS.BE_WAL] },
        { type: "radioBool", prop: "publicOccupiedVLA", regions: [REGIONS.BE_VLA] },
        { type: "radioBool", prop: "publicOccupiedRBC", regions: [REGIONS.BE_RBC] },
        { type: "radioBool", prop: "selling" },
    ], [affectations, affectationStatus]);

    const renderBuildForm = React.useCallback((build: Element<T.BuildingData>) => <BS.Row key={build.submission._id} className="mb-3">
        <BS.Col md={12}>
            <C.Flex alignItems="end">
                <C.Falcon.Title titleTag={build.submission.data.name} />
                {build.full_path && <span className="text-muted ms-2 fs-75">({build.full_path})</span>}
            </C.Flex>
        </BS.Col>
        <BS.Col md={12}>
            <BS.Row className="mb-1">{buildQuestions.map(q => renderQuestion(q, build.submission._id, FP.BUILDING_FORM))}</BS.Row>
        </BS.Col>
    </BS.Row>, [buildQuestions, renderQuestion]);
    //#endregion

    //#region Cells
    const cellQuestions = React.useMemo<ArrayQuestion[]>(() => [
        { type: "radioBool", prop: "isOccupation" },
        { type: "radioBool", prop: "selling" },
        { type: "radioBool", prop: "employees" },
        { type: "radioBool", prop: "requiresPDERBC" },
        { type: "radioBool", prop: "grandeEntrepriseVLA", regions: [REGIONS.BE_VLA] },
        { type: "radioBool", prop: "asbestosWorksRBC", regions: [REGIONS.BE_RBC] },
        { type: "radioBool", prop: "grandeEntrepriseRBCWA", regions: [REGIONS.BE_RBC, REGIONS.BE_WAL] },
        { type: "select", prop: "actifType", options: affectations, status: affectationStatus },
        { type: "num", prop: "surfaceNFA", unit: "m²", min: 0 },
    ], [affectations, affectationStatus]);

    const getCellType = React.useCallback((cell: Element<T.EmplacementData>) => {
        let icon: C.IconTipProps["icon"], text: string;

        if (cell.submission.data.type === "floor") {
            text = TC.GLOBAL_FLOOR;
            icon = { url: RESOURCE.RESOURCE_URL(RESOURCE.ICON_SUB.DEFAULT_FLOOR) };
        }
        else if (cell.submission.data.type === "local") {
            text = TC.GLOBAL_LOCAL;
            icon = { url: RESOURCE.RESOURCE_URL(RESOURCE.ICON_SUB.DEFAULT_LOCAL) };
        }
        else if (cell.submission.data.type === "zone") {
            text = TC.GLOBAL_ZONE;
            icon = { url: RESOURCE.RESOURCE_URL(RESOURCE.ICON_SUB.DEFAULT_ZONE) };
        }
        else if (cell.submission.data.type === "parking") {
            icon = "parking";
            text = TC.GLOBAL_LABEL_PARKING;
        }
        else return null;
        return <C.IconTip className="me-2" tipContent={text} icon={icon} />
    }, []);

    const renderCellForm = React.useCallback((cell: Element<T.EmplacementData>) => <BS.Row key={cell.submission._id} className="mb-3">
        <BS.Col md={12}>
            <C.Flex alignItems="end">
                {getCellType(cell)}
                <C.Falcon.Title titleTag={cell.submission.data.name} />
                {cell.full_path && <span className="text-muted ms-2 fs-75">({cell.full_path})</span>}
            </C.Flex>
        </BS.Col>
        <BS.Col md={12}>
            <BS.Row className="mb-1">{cellQuestions.map(q => renderQuestion(q, cell.submission._id, FP.EMPLACEMENT_FORM))}</BS.Row>
        </BS.Col>
    </BS.Row>, [cellQuestions, renderQuestion, getCellType]);

    const add_emplacement = React.useCallback(() => {

        PM.renderBuildingSelect({ context: props.context }).then(building => {
            M.renderLightTree({ linkRestriction: { linkType: LT.LINK_TYPE_OWN, objForm: FP.EMPLACEMENT_FORM }, root: building, selection: building }).then(parent => {
                if (parent) M.renderFormModal<T.EmplacementData>({ path: FP.EMPLACEMENT_FORM, forcedSubmission: [{ prop: "isOccupation", value: true }] }).then(emp => {
                    if (emp) {
                        saving.setTrue();
                        S.attachNode({ parent, children: emp._id, type: LT.LINK_TYPE_OWN })
                            .then(() => reload().finally(saving.setFalse))
                            .catch(e => {
                                saving.setFalse();
                                M.Alerts.updateError(e);
                            });
                    }
                });
            });
        });
    }, [props.context, reload, saving]);
    //#endregion

    //#region No Data
    const emptyElements = React.useMemo(() => {
        if ([sites, buildings, emplacements].every(x => x.length === 0)) return <C.ErrorBanner type="info" message="todo no item" />;
        return null;
    }, [sites, buildings, emplacements]);

    const getEmptyCategory = React.useCallback((path: string) => <C.Flex alignItems="center" justifyContent="center" style={{ minHeight: "100px" }}>
        {lg.getStaticElem(TC.GLOBAL_NO_X_CONTEXT, path)}
    </C.Flex>, [lg]);
    //#endregion

    return <div className="px-3 position-relative">
        {!props.read_only && !emptyElements && <div className="position-sticky top-0 start-100 pt-3" style={{ width: "fit-content", zIndex: 50 }}>
            <C.Button onClick={onSave} disabled={changes.length === 0} icon={saveIcon} text={TC.GLOBAL_SAVE} />
        </div>}

        {banner.value && <C.ErrorBanner close={banner.setFalse} type="warning" textCode={TC.REG_BANNER_WARNING} />}

        {emptyElements}

        {!emptyElements && <>
            <BS.Row>
                <BS.Col md={12}>
                    <h2>{lg.getStaticElem(FP.SITE_FORM)}</h2>
                </BS.Col>
                <BS.Col md={12}>
                    {sites.length === 0 ? getEmptyCategory(FP.SITE_FORM) : sites.map(renderSiteForm)}
                </BS.Col>
            </BS.Row>

            <BS.Row>
                <BS.Col md={12}>
                    <h2>{lg.getStaticElem(FP.BUILDING_FORM)}</h2>
                </BS.Col>
                <BS.Col md={12}>
                    {buildings.length === 0 ? getEmptyCategory(FP.BUILDING_FORM) : buildings.map(renderBuildForm)}
                </BS.Col>
            </BS.Row>

            <BS.Row>
                <BS.Col md={12}>
                    <C.Flex alignItems="center">
                        <h2>{lg.getStaticElem(FP.EMPLACEMENT_FORM)}</h2>
                        <C.Button onClick={add_emplacement} disabled={props.read_only} className="mx-2" size="sm" icon="plus" />
                        <BS.InputGroup>
                            <BS.Form.Check type="switch" checked={showOnlyOccupation.value} onChange={showOnlyOccupation.toggle} />
                            <BS.Form.Label className="mb-0 ms-2">{lg.getStaticElem(TC.REG_SHOW_ONLY_OCCUPATION)}</BS.Form.Label>
                        </BS.InputGroup>
                    </C.Flex>
                </BS.Col>
                <BS.Col md={12}>
                    {emplacements.length === 0 ? getEmptyCategory(FP.EMPLACEMENT_FORM) : emplacements.map(renderCellForm)}
                </BS.Col>
            </BS.Row>
        </>}
    </div>;
}

export default RegDataFiller;