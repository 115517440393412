import React from "react";
import * as G from "../Grid";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as S from "../../../services";
import { CellsTypes as CT } from "../AgGridDefs";
import { FP, T, TB, TC } from "../../../Constants";

type Row = ReturnType<T.API.Utils.Energy.ListStationsTags>[number];

export type DataCorrectProps = {
    /** The context to search the stations and tags in */
    context?: T.ContextParams;
    /** The id to give the table */
    origin: string;
}

export const DataCorrect: React.FC<DataCorrectProps> = props => {
    const lg = H.useLanguage();
    const [rows, set_rows, status] = H.useAsyncState<Row[]>([]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.listStationsTags(props.context)
            .then(({ data }) => isSubscribed && set_rows(data, "done"))
            .catch(() => isSubscribed && set_rows([], "error"));
        return () => {
            isSubscribed = false;
            set_rows([], "load");
        }
    }, [props.context, set_rows]);

    const columns = React.useMemo<G.TableProps<Row>["columns"]>(() => [
        { field: "station_name", headerName: TC.DATA_CORR_STATION_NAME },
        { field: "name", headerName: TC.DATA_CORR_TAG_NAME },
        { field: "station_id", headerName: TC.DATA_CORR_STATION_ID },
        { field: "tag_id", headerName: TC.DATA_CORR_TAG_ID },
        { field: "comment_", headerName: TC.DATA_CORR_COMMENT },
        { field: "aggregate_jool", headerName: TC.DATA_CORR_AGG },
        { field: "factor", headerName: TC.DATA_CORR_FACTOR, type: CT.TYPE_NUMBER },
        { field: "datasets.names", headerName: TC.TAB_ENTITY_TABLE },
        { field: "datasets.sites", headerName: FP.SITE_FORM },
        { field: "datasets.buildings", headerName: FP.BUILDING_FORM },
        { field: "datasets.floors", headerName: TC.BR_TAB_LABEL_FLOORS },
        { field: "datasets.locals", headerName: TC.LOCAL },
    ], []);

    const events = React.useMemo(() => ({
        correct: (selected_rows: T.AllowArray<Row>) => {
            let rows = TB.arrayWrapper(selected_rows);
            let unmount = M.renderLoader();
            let pairs = rows.map(r => ({ station: r.station_id, tag: r.tag_id }));
            S.checkCorrection(pairs).then(({ data }) => {
                let colors = TB.generateHexColorCodes(data.length);
                let chart_options = {
                    series: [],
                    xAxis: { type: 'time' },
                    legend: { /* type: 'scroll', */ data: [] },
                    tooltip: { trigger: 'axis', axisPointer: { type: "cross" } },
                    yAxis: { type: 'value', axisLabel: { formatter: '{value}' } },
                    toolbox: {
                        feature: {
                            restore: { show: true },
                            dataZoom: {
                                yAxisIndex: false,
                                icon: {
                                    zoom: 'path://', // hack to remove zoom button
                                    back: 'path://', // hack to remove restore button
                                }
                            }
                        }
                    }
                };

                for (let i = 0; i < data.length; i++) {
                    let item = data[i];
                    let linked_row = rows.find(r => TB.getNumber(r.station_id) === item.station_id && TB.getNumber(r.tag_id) === item.tag_id);
                    let name = linked_row ? `${linked_row.station_name} - ${linked_row.name}` : `Station ${item.station_id} - Tag ${item.tag_id}`;

                    let og_name = lg.getStaticText(TC.DATA_CORR_OG) + " " + name;
                    let corrected_name = lg.getStaticText(TC.DATA_CORR_CORRECTION) + " " + name;
                    chart_options.legend.data.push({ name: og_name }, { name: corrected_name });

                    chart_options.series.push(
                        {
                            smooth: true,
                            type: 'line',
                            name: og_name,
                            showSymbol: false,
                            itemStyle: { color: colors[i] },
                            data: item.orginal_data.map(d => [d[1], d[0]]),
                        },
                        {
                            smooth: true,
                            type: 'line',
                            showSymbol: false,
                            name: corrected_name,
                            lineStyle: { type: "dashed" },
                            itemStyle: { color: colors[i] },
                            data: item.datapoints.map(d => [d[1], d[0]]),
                        },
                    );
                }

                let chart = <C.Echarts
                    className="h-100"
                    option={chart_options}
                    ref={ref => {
                        let chart = ref?.getEchartsInstance?.();
                        chart?.dispatchAction?.({
                            type: 'takeGlobalCursor',
                            key: 'dataZoomSelect',
                            dataZoomSelectActive: true,
                        });
                        chart?.on?.("restore", () => {
                            chart?.dispatchAction?.({
                                type: 'takeGlobalCursor',
                                key: 'dataZoomSelect',
                                dataZoomSelectActive: true,
                            });
                            chart?.setOption?.(chart_options);
                        });
                        chart?.on?.('click', (params: any) => {
                            if (params.componentType === 'markArea') chart?.dispatchAction?.({
                                type: 'dataZoom',
                                dataZoomIndex: 0,
                                startValue: params.data.coord[0][0],
                                endValue: params.data.coord[1][0]
                            })
                        });
                    }}
                />;

                M.renderBlankModal<"accepted" | "rejected">({
                    children: chart,
                    isFullScreen: true,
                    title: TC.DATA_CORR_TITLE,
                    renderFooter: resolve => <C.Flex alignItems="end">
                        <C.Button
                            icon="check"
                            variant="primary"
                            text={TC.DATA_CORR_ACCEPT_CHANGES}
                            onClick={() => resolve("accepted")}
                        />
                        <C.Button
                            icon="times"
                            className="ms-2"
                            variant="danger"
                            text={TC.DATA_CORR_REJECT_CHANGES}
                            onClick={() => resolve("rejected")}
                        />
                    </C.Flex>,
                }).then(response => {
                    let value = response || "rejected";
                    S.assessCorrections({ pairs, state: value === "accepted" ? "accept" : "reject" })
                        .catch(M.Alerts.updateError);
                });
            })
                .catch(M.Alerts.loadError)
                .then(() => unmount?.());
        }
    }), [lg]);

    const context_menu = React.useCallback<G.TableProps<Row>["getContextMenuItems"]>(params => {
        let row = params.node?.data;
        let items = [] as ReturnType<G.TableProps<Row>["getContextMenuItems"]>;
        let selection = (params.api?.getSelectedNodes?.() || []).map(r => r.data);

        if (row) items.push({
            action: () => events.correct(row),
            icon: "<i class='fa fa-check'></i>",
            name: lg.getStaticText(TC.DATA_CORR_SELECT_ROW),
        });
        if (selection.length > 1) items.push({
            action: () => events.correct(selection),
            icon: "<i class='fa fa-check-double'></i>",
            name: lg.getStaticText(TC.DATA_CORR_SELECT_ROWS, selection.length),
        });

        if (items.length > 0 && params.defaultItems?.length > 0) items.push("separator", ...params.defaultItems);
        return items;
    }, [events, lg]);

    return <div className="w-100">
        <C.Spinner error={status === "error"}>
            <G.Table<Row>
                rows={rows}
                status={status}
                columns={columns}
                rowSelection="multiple"
                sideBar="filters_columns"
                columns_base="all_but_edit"
                groupDisplayType="singleColumn"
                getContextMenuItems={context_menu}
                adaptableId={props.origin || "data_correct_table"}
            />
        </C.Spinner>
    </div>;
}

export const DataCorrectContext: React.FC = () => {
    const [roots] = H.useRoots();
    H.useAuth({ onlyAdmin: true });
    H.useCrumbs(TC.DATA_CORR_TITLE);
    return <DataCorrect origin="data_correct_table" context={roots} />;
};