import renderInContainer from "../getContainer";
import { QuickInput2, QuickInputProps2 } from "../../../Common";

type Row = Record<string, any>;

export type RenderQuickInput<A extends Row = Row> = (params: Omit<QuickInputProps2<A>, "onQuit" | "onSubmit" | "popup">)
    => Promise<Parameters<QuickInputProps2<A>["onSubmit"]>[0]>;

const renderQuickInput: <T extends Row >(params: Parameters<RenderQuickInput<T>>[0]) => Promise<Awaited<ReturnType<RenderQuickInput<T>>>> = params => new Promise(resolve => {
    type RowType = typeof params["rows"][number];
    const [render, dismount] = renderInContainer();
    if (render && dismount) {
        let onClose = () => dismount?.(() => resolve(null));
        let onConfirm: QuickInputProps2<RowType>["onSubmit"] = rows => dismount?.(() => resolve(rows));
        render(<QuickInput2<RowType> {...params} popup onQuit={onClose} onSubmit={onConfirm} />);
    }
    else resolve(null);
});

export default renderQuickInput;