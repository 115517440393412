import React from "react";
import { useDrag } from "react-dnd";
import ActionItem, { ActionItemProps } from "./ActionItem";

export type DraggableContainerActionProps = ActionItemProps;

const DraggableContainerAction: React.FC<DraggableContainerActionProps> = props => {
    const [{ isDragging }, dragRef] = useDrag({
        type: 'action',
        item: props.action,
        collect: monitor => ({ isDragging: monitor.isDragging() }),
    });

    return <div className={isDragging ? "border-secondary" : ""} ref={dragRef}>
        <ActionItem {...props} />
    </div>;
};

export default DraggableContainerAction;