import { FORMATS, T, TB } from "../../../Constants";
import ExcelMapping from "../../ExcelMapping/ExcelMapping";
import { renderBlankModal, BlankModalFnProps } from "../BlankModal";
import { askExcelImport, ExcelModalImportFnProps } from "../ExcelImport";

type ExcelMapperParams<A extends string = string> = ExcelModalImportFnProps & Omit<BlankModalFnProps, "renderContent" | "title" | "footer"> & {
    factorChange?: boolean;
    allowSwapping?: boolean;
    allowIgnoring?: boolean;
    columnFormat: T.ImportFormat<A>;
    /** The key to store the previous column format in the local storage */
    save?: string;
    /** Invert the direction of the table, to make it look like the excel */
    invert?: boolean;
    /** Callback to allow the creation of a new option via the mapper */
    onAddColumns?: (text: string) => Promise<null | T.ImportFormat<A>>;
}

type ExcelMapperResults<A extends string = string> = {
    dataLastLine: number;
    dataFirstLine: number;
    columns: T.ImportFormat<A>;
    ignoredRows: number[];
}

const renderExcelMapper = <A extends Record<string, any> = Record<string, any>>(params: ExcelMapperParams<Extract<keyof A, string>>) => new Promise<null | T.AnyObject[]>(resolve => {
    let container = document.getElementById("modalContainer")!;
    if (!TB.validObject(params)) resolve(null);
    if (container !== null) askExcelImport(params).then(workbook => {
        if (!workbook) resolve(null);
        else {
            // Apply the previous keys that were saved
            if (params.save) {
                let keys_str = localStorage.getItem(params.save);
                if (TB.validString(keys_str)) {
                    let keys = JSON.parse(keys_str) as Record<keyof A, string>;
                    for (let prop of Object.keys(params.columnFormat)) {
                        let key = keys[prop];
                        if (key) params.columnFormat[prop].key = key;
                    }
                }
            }

            renderBlankModal({
                ...params,
                /* @ts-ignore */
                renderContent: resolve => <ExcelMapping {...params} sheet={workbook} close={() => resolve(null)} onValidate={resolve} />
            })
                .then((mapping: ExcelMapperResults | null) => {
                    if (!mapping) resolve(null);
                    else {
                        let { columns, dataFirstLine, ignoredRows } = TB.getObject(mapping);
                        // Save the keys for next usage
                        if (params.save) {
                            let to_save_keys = Object.fromEntries(
                                Object.entries(columns)
                                    .map(([prop, col]) => [prop, col.key])
                                    .filter(([prop, key]) => TB.validString(key))
                            ) as Record<keyof A, string>;
                            localStorage.setItem(params.save, JSON.stringify(to_save_keys));
                        }

                        let values = FORMATS.excelValueGetter(workbook, columns, dataFirstLine, ignoredRows);
                        resolve(values);
                    }
                });
        }
    });
    else resolve(null);
});

export default renderExcelMapper;