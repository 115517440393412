import _ from "lodash";
import React from "react";
import Flex from "./Flex";
import { Alert } from "../Item";
import * as R from "../../reducers";
import { T } from "../../Constants";
import * as Redux from "react-redux";
import * as BS from "react-bootstrap";

const MAX_SIMULTANEOUS_ALERTS = 5;

const AlertsContainer: React.FC = () => {
    const alerts = Redux.useSelector((state: T.ReduxSelector) => state.alerts);
    const shown_alert = React.useMemo(() => _.take(alerts, MAX_SIMULTANEOUS_ALERTS), [alerts]);

    const dismissAlert = React.useCallback((type: Parameters<T.AlertRedux["on_dismiss"]>[0], alert: T.AlertRedux) => {
        // Call the dismiss function originally provided
        if (alert.on_dismiss) alert.on_dismiss(type);
        // Dismiss the alert
        R.dismissAlert(alert.id);
    }, []);

    return <div className="position-relative" style={{ zIndex: 2000 }}>
        <Flex className="m-2 position-fixed" justifyContent="end" style={{ right: "0", top: "0" }}>
            <BS.ToastContainer>
                {shown_alert.map(a => <Flex key={a.id} justifyContent="end" children={<Alert {...a} on_dismiss={type => dismissAlert(type, a)} />} />)}
            </BS.ToastContainer>
        </Flex>
    </div>;
}

export default AlertsContainer;