import * as TC from "../textCode";
import { EquipGammeData, Option } from "../Types";

export const ROOF = "23-13 39 11";
export const WALLS = "23-13 33 15";
export const IRVE = "23-35 21 11 D";
export const WINDOWS = "23-13 33 27";
export const UN_CLASSED = "00-00 00 00";
export const PARKING_LIGHTS = "23-35 47 11";

/** Electricity Counter */
export const ELEC_CPT = "23-35 25 11";
/** Heat Counter Hot */
export const HEAT_CPT_HOT = "23-27 15 17 11 11 D";
/** Heat Counter Cold */
export const HEAT_CPT_COLD = "23-27 15 17 11 13 D";
/** Heat Remote Counter Hot */
export const HEAT_REMOTE_CPT_HOT = "23-27 15 17 13 11 D";
/** Heat Remote Counter Cold */
export const HEAT_REMOTE_CPT_COLD = "23-27 15 17 13 13 D";
/** Gas Counter */
export const GAS_CPT = "23-27 15 17 15 D";
/** Water Counter */
export const WATER_CPT = "23-27 15 18 13 D";
/** Cooling System */
export const COOLING_SYSTEM = "23-33 09 00 D";
/** Venting System */
export const VENTING_SYSTEM = "23-33 07 00 D";
/** Heating System */
export const HEATING_SYSTEM = "23-33 05 00 D";

/** The red flag categories */
export const RED_FLAG_CAT = [
    { value: "hot", label: TC.RED_FLAG_CAT_HOT },
    { value: "cold", label: TC.RED_FLAG_CAT_COLD },
    { value: "air", label: TC.RED_FLAG_CAT_AIR },
    { value: "mood", label: TC.RED_FLAG_CAT_MOOD },
    { value: "auto_reg", label: TC.RED_FLAG_CAT_AUTO_REG },
    { value: "fire", label: TC.RED_FLAG_CAT_FIRE },
    { value: "sanitary", label: TC.RED_FLAG_CAT_SANITARY },
    { value: "elec", label: TC.RED_FLAG_CAT_ELEC },
    { value: "hoists", label: TC.RED_FLAG_CAT_HOISTS },
    { value: "architecture", label: TC.RED_FLAG_CAT_ARCHITECTURE },
] as Option<{}, EquipGammeData["red_flag"]>[];

/** The 'active' possible actions */
export const ACTIVE = [
    { value: "edl_tech", label: TC.ACTIVE_EDL_TECH },
    { value: "edl_arch", label: TC.ACTIVE_EDL_ARCH },
    { value: "mat_inventory", label: TC.ACTIVE_MAT_INVENTORY },
    { value: "4_plus", label: TC.ACTIVE_4_PLUS },
] as Option<{}, EquipGammeData["active"][number]>[];