import React from 'react';

type UseTimer = (initialSeconds?: number, step?: number) => [number, () => void, () => void, () => void, boolean];

const useTimer: UseTimer = (initialSeconds = -1, step = 1000) => {
    const timerRef = React.useRef(null);
    const [isActive, setIsActive] = React.useState(false);
    const [secondsLeft, setSecondsLeft] = React.useState(initialSeconds);

    const startTimer = React.useCallback(() => setIsActive(true), []);

    const pauseTimer = React.useCallback(() => {
        if (isActive) {
            setIsActive(false);
            clearInterval(timerRef.current);
        }
    }, [isActive]);

    const resetTimer = React.useCallback(() => {
        clearInterval(timerRef.current);
        setSecondsLeft(initialSeconds);
        setIsActive(false);
    }, [initialSeconds]);

    React.useEffect(() => {
        if (isActive) {
            timerRef.current = setInterval(() => {
                setSecondsLeft((prev) => {
                    if (prev <= 0) {
                        clearInterval(timerRef.current);
                        setIsActive(false);
                        return prev;
                    }
                    return prev - (step / 1000);
                });
            }, step);
        }

        return () => clearInterval(timerRef.current);
    }, [isActive, step]);

    return [secondsLeft, startTimer, pauseTimer, resetTimer, isActive];
};

export default useTimer;
