import { T, TC } from "../../Constants";

export const format: T.ImportFormat = {
    name: { label: TC.GLOBAL_NAME, required: true },
    floor: { label: "Etage", required: true },
    local: { label: "Local", required: true },
    brand: { label: "Marque" },
    model: { label: "Modele de l'equipement" },
    quantity: { label: "Quantité" },
    dateInstallation: { label: "Année d'installation" },
    inGoodCondition: { label: "En bon état" },
    category: { label: "Code omniclass" },
    state: { label: "Etat" },
    criticity: { label: "Criticité" },
    failureCriticity: { label: "Criticité de la panne" },
    vetusty: { label: "Vétusté" },
    underContract: { label: "Sous Contrat" },
    remarques: { label: "Remarques" },
    expectedLifespan: { label: "Durée de vie" },
    propName1: { label: "Propriété X", pair: "propValuePair1" },
    value1: { label: "Valeur X", pair: "propValuePair1" },
    propName2: { label: "Propriété Y", pair: "propValuePair2" },
    value2: { label: "Valeur Y", pair: "propValuePair2" },
    propName3: { label: "Propriété Z", pair: "propValuePair3" },
    value3: { label: "Valeur Z", pair: "propValuePair3" },
}