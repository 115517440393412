import { TB } from "../Constants";
import * as US from '../services/user.service';

const massDelete = (id: string, includeId = false) => new Promise<null | string[]>((resolve, reject) => {
    if (!TB.mongoIdValidator(id)) reject(null);
    else US.advancedSearch(id, undefined, true, undefined, undefined, includeId).then(treeReply => {
        if (!Array.isArray(treeReply.data)) reject(null);
        else {
            let ids: string[] = treeReply.data.filter(TB.mongoIdValidator);
            if (ids.length === 0) resolve([]);
            else US.deleteSubmissionsAndRelatives(ids).then(({ data }) => {
                if (data?.ok === 1) resolve(ids);
                else reject(null);
            }).catch(reject)
        }
    }).catch(reject);
});

export default massDelete;