import axios from "axios";
import { T, URL } from "../../Constants";

const { RENT_API } = URL;

type LinkBail = T.API.ToAxios<T.API.Utils.Rent.LinkBail>;
type RemoveBail = T.API.ToAxios<T.API.Utils.Rent.RemoveBail>;
type GetRentInfo = T.API.ToAxios<T.API.Utils.Rent.GetRentInfo>;
type UpdateBailField = T.API.ToAxios<T.API.Utils.Rent.UpdateBailField>;
type GetRenterOptions = T.API.ToAxios<T.API.Utils.Rent.GetRenterOptions>;
type UpdateBailRenter = T.API.ToAxios<T.API.Utils.Rent.UpdateBailRenter>;
type UpdateBailEnseigne = T.API.ToAxios<T.API.Utils.Rent.UpdateBailEnseigne>;
type UpdateRenterFranchised = T.API.ToAxios<T.API.Utils.Rent.UpdateRenterFranchised>;

/** Get the basic rent info about an emplacement */
export const getRentInfo: GetRentInfo = id => axios.post(RENT_API + "infos", { id });
/** Create the links between a bail, a renter and an enseigne */
export const linkBail: LinkBail = params => axios.post(RENT_API + "link/bail", params);
/** Update a field on a bail submission */
export const update_bail_field: UpdateBailField = params => axios.post(RENT_API + "edit/bail", params);
/** Edit the link between a bail and a renter */
export const updateBailRenter: UpdateBailRenter = params => axios.post(RENT_API + "link/renter", params);
/** Edit the link between a renter and an enseigne */
export const updateBailEnseigne: UpdateBailEnseigne = params => axios.post(RENT_API + "link/enseigne", params);
/** Update a renter's franchised status */
export const updateRenterFranchised: UpdateRenterFranchised = params => axios.post(RENT_API + "renter/franchised", params);
/** Deletes a bail, and the links associated with it */
export const removeBail: RemoveBail = params => axios.post(RENT_API + "delete/bail", params);
/** Get the available renters and enseignes for the user */
export const getRenterOptions: GetRenterOptions = () => axios.get(RENT_API + "options");