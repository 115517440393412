import React from "react";
import Button from "./Button";
import * as Form from "../Form";
import * as H from "../../hooks";
import * as S from "../../services";
import { Flex, Spinner } from "../Layout";
import * as M from "../../Components/Modal";
import { T, TB, TC } from "../../Constants";
import { ButtonGroup, Col, Row, Table } from "react-bootstrap";
import _ from "lodash";

//#region Edit
type Edit = Pick<T.Schedule, "from" | "to"> & {
    /** The index of the edited time */
    index: number;
}

export type ScheduleProps = {
    /** Callback fired after validation */
    onSave?: (schedule: T.Schedule[]) => void;
    /** Do not allow edition */
    readOnly?: boolean;
    /** The default Schedule */
    value?: T.Schedule[];
    /** The current context, to find the presets in */
    context: T.ContextParams;
}
//#endregion

const DAYS: T.Option<{}, number>[] = [
    { value: 0, label: TC.SCHEDULE_MONDAY },
    { value: 1, label: TC.SCHEDULE_TUESDAY },
    { value: 2, label: TC.SCHEDULE_WEDNESDAY },
    { value: 3, label: TC.SCHEDULE_THURSDAY },
    { value: 4, label: TC.SCHEDULE_FRIDAY },
    { value: 5, label: TC.SCHEDULE_SATURDAY },
    { value: 6, label: TC.SCHEDULE_SUNDAY },
    { value: 7, label: TC.SCHEDULE_HOLIDAY },
];

const DEFAULT_SCHEDULE: T.Schedule = { from: "00:00", to: "23:59", days: [] };

const Schedule: React.FC<ScheduleProps> = props => {
    const lg = H.useLanguage([TC.SCHEDULE_OVERLAP]);
    const [edit, setEdit] = React.useState<Edit>(null);
    const [errors, setErrors] = React.useState<T.Errors<Edit>>({});
    const [schedule, setSchedule] = React.useState<T.Schedule[]>([{ ...DEFAULT_SCHEDULE }]);
    const [overlaps, setOverlaps] = React.useState<{ schedule: T.Schedule, day: number }[]>([]);
    const [active_preset, setActivePreset] = React.useState<Record<"site" | "index", string>>(null);
    const [preset, setPresets, presets_status] = H.useAsyncState<ReturnType<T.API.Utils.Energy.GetPresetsSchedule>>({ presets: [], sites: [] });

    //#region Load Presets
    React.useEffect(() => {
        let isSubscribed = true;

        if (props.context) S.getPresetsSchedule(props.context)
            .then(({ data }) => isSubscribed && setPresets(data, "done"))
            .catch(() => isSubscribed && setPresets({ presets: [], sites: [] }, "error"));
        else setPresets({ presets: [], sites: [] }, "done");
        return () => { isSubscribed = false };
    }, [props.context, setPresets]);

    const preset_options = React.useMemo<T.Option[]>(() => preset.presets.map((p, i) => {
        let value = p.site + "_" + p.index;
        return { ...p, label: p.name, value };
    }), [preset.presets]);

    const presets = React.useMemo(() => ({
        add: () => M.askPrompt({ modal: { className: "z-index-higher-modal" } }).then((name: string) => {
            if (name) {
                let sitePromise = new Promise<string>(resolve => {
                    if (preset.sites.length === 1) resolve(preset.sites[0]?.value);
                    else M.askSelect({ options: preset.sites, modalClass: "z-index-higher-modal", isRequired: true }).then(site => resolve(site));
                });

                sitePromise.then(site => {
                    if (site) S.addPresetSchedule({ site, name, schedule })
                        .then(() => {
                            let index = NaN;

                            setPresets(p => {
                                let max_index = _.maxBy(p.presets.filter(s => s.site === site), s => s.index)?.index;

                                if (typeof max_index === "number") index = max_index + 1;
                                else index = 0;

                                return {
                                    ...p,
                                    presets: p.presets.concat({ site, name, schedule, index })
                                };
                            });

                            setActivePreset({ site, index: index.toString() });
                        })
                        .catch(M.Alerts.updateError);
                });
            }
        }),
        load: (value: string) => {
            if (TB.validString(value)) {
                let [site, index] = value.split("_");
                let index_num = TB.getNumber(index);

                if (TB.validString(site) && TB.validString(index)) {
                    setActivePreset({ site, index });
                    let schedule = preset.presets.filter(p => p.site === site && index_num === p.index)[0]?.schedule
                    setSchedule(schedule || []);
                }
                else setActivePreset(null);
            }
            else setActivePreset(null);
        },
        edit: () => {
            let index_num = TB.getNumber(active_preset.index);
            let current = preset.presets.filter(p => p.site === active_preset.site && p.index === index_num)[0];

            M.askPrompt({ defaultVal: current?.name, modal: { className: "z-index-higher-modal" } }).then((name: string) => {
                if (name) S.addPresetSchedule({ site: active_preset.site, schedule, name, index: index_num })
                    .then(() => setPresets(p => ({
                        ...p,
                        presets: p.presets.map(s => {
                            if (s.site === active_preset.site && s.index === index_num) return { ...s, name, schedule };
                            else return s;
                        })
                    })))
                    .catch(M.Alerts.updateError);
            });
        },
        remove: () => {
            let index_num = TB.getNumber(active_preset.index);
            M.askConfirm({ modal: { className: "z-index-higher-modal" } }).then(confirmed => {
                if (confirmed) S.removePresetSchedule({ site: active_preset.site, index: index_num })
                    .then(() => {
                        setPresets(p => ({
                            ...p,
                            presets: p.presets
                                .filter(s => s.site !== active_preset.site || s.index !== index_num)
                                .map(s => {
                                    if (s.site !== active_preset.site || s.index < index_num) return s;
                                    else return { ...s, index: s.index - 1 };
                                })
                        }));
                        setActivePreset(null);
                    })
                    .catch(M.Alerts.deleteError);
            })
        },
    }), [preset, schedule, active_preset, setPresets]);

    const preset_value = React.useMemo(() => {
        if (!active_preset) return null;
        else return active_preset.site + "_" + active_preset.index;
    }, [active_preset]);
    //#endregion

    //#region Default Value
    React.useEffect(() => {
        if (Array.isArray(props.value)) setSchedule(props.value);
        else setSchedule([{ ...DEFAULT_SCHEDULE }]);
    }, [props.value]);
    //#endregion

    //#region Col Times Edit
    React.useEffect(() => {
        if (edit?.from) setErrors(p => ({ ...p, from: undefined }));
    }, [edit?.from]);

    React.useEffect(() => {
        if (edit?.to) setErrors(p => ({ ...p, to: undefined }));
    }, [edit?.to]);

    const edit_col = React.useCallback(() => {
        if (!edit) return;
        let new_errors: typeof errors = {};
        let [v_from, v_to] = [edit.from, edit.to].map(TB.durationToSeconds);

        if (v_to === null || v_from === null) {
            if (v_to === null) new_errors.to = TC.GLOBAL_REQUIRED_FIELD;
            if (v_from === null) new_errors.from = TC.GLOBAL_REQUIRED_FIELD;
        }
        else if (v_from === v_to) {
            new_errors.to = TC.SCHEDULE_NO_EQUAL;
            new_errors.from = TC.SCHEDULE_NO_EQUAL;
        }
        else if (v_from > v_to) {
            new_errors.to = TC.SCHEDULE_TO_HIGHER_FROM;
            new_errors.from = TC.SCHEDULE_FROM_LOWER_TO;
        }

        if (Object.keys(new_errors).length > 0) setErrors(new_errors);
        else {
            setSchedule(p => p.map((s, i) => i === edit.index ? { ...s, from: edit.from, to: edit.to } : s));
            setErrors({});
            setEdit(null);
        }
    }, [edit]);

    const remove_col = React.useCallback((index: number) => {
        setEdit(null);
        setSchedule(p => p.filter((s, i) => i !== index))
    }, []);
    //#endregion

    //#region Edits
    const allow_edit_cell = React.useMemo(() => !props.readOnly && !edit, [props.readOnly, edit]);
    const add_col = React.useCallback(() => setSchedule(p => p.concat({ from: "08:00", to: "17:00", days: [] })), []);

    const check_unavailable = React.useCallback((index: number, day: number) => {
        let data = schedule.filter((s, i) => i === index)[0];
        if (!data) return true;

        let time_to = TB.durationToSeconds(data.to),
            time_from = TB.durationToSeconds(data.from);

        let unavailable = false;
        schedule.forEach((s, i) => {
            if (i !== index && !unavailable && s.days.includes(day)) {
                let col_to = TB.durationToSeconds(s.to),
                    col_from = TB.durationToSeconds(s.from);

                // Check for overlap
                let overlap_to = col_from < time_to && time_to < col_to;
                let overlap_from = col_from < time_from && time_from < col_to;
                let overlap_to_reversed = time_from < col_to && col_to < time_to;
                let overlap_from_reversed = time_from < col_from && col_from < time_to;

                unavailable = overlap_from || overlap_to || overlap_from_reversed || overlap_to_reversed;
            }
        });

        return unavailable;
    }, [schedule]);

    const toggle_col = React.useCallback((index: number, day: number) => setSchedule(p => p.map((s, i) => {
        if (i !== index) return s;
        else if (s.days.includes(day)) return { ...s, days: s.days.filter(d => d !== day) };
        else return { ...s, days: s.days.concat(day) };
    })), []);
    //#endregion

    //#region Language
    React.useEffect(() => lg.getOptionsStatic(DAYS as any), [lg]);
    //#endregion

    //#region Render
    const render_cell = React.useCallback((day: typeof DAYS[number], schedule: T.Schedule, index: number) => {
        let is_selected = schedule.days.includes(day.value);
        let is_unavailable = check_unavailable(index, day.value);
        let can_edit = allow_edit_cell && (is_selected || !is_unavailable);

        let backgroundColor = "#e20000";
        if (is_selected) backgroundColor = "#4eae53";
        else if (is_unavailable) backgroundColor = "#666b75";

        let className = "";
        if (can_edit) className += "hover-opacity pointer";

        return <td
            key={index}
            className={className}
            style={{ backgroundColor }}
            onDoubleClick={() => can_edit && toggle_col(index, day.value)}
        ></td>
    }, [allow_edit_cell, toggle_col, check_unavailable]);
    //#endregion

    //#region Submit
    const submit = React.useCallback(() => {
        let new_overlaps = [] as typeof overlaps;

        schedule.forEach((s, i) => {
            for (let day of s.days) {
                let unavailable = check_unavailable(i, day);
                if (unavailable) new_overlaps.push({ schedule: s, day });
            }
        });

        if (new_overlaps.length === 0) {
            setOverlaps([]);
            props.onSave?.(schedule);
        }
        else setOverlaps(new_overlaps);
    }, [check_unavailable, schedule, props]);
    //#endregion

    return <>
        {props.context && <Spinner status={presets_status}>
            <Row className="align-items-center">
                <Col>
                    <Form.Select
                        value={preset_value}
                        onChange={presets.load}
                        options={preset_options}
                        label={TC.ALARM_SCHEDULE_PRESETS}
                    />
                </Col>
                <Col>
                    <ButtonGroup>
                        <Button onClick={presets.add} icon="plus" tip={TC.ADD} />
                        <Button onClick={presets.edit} icon="pencil-alt" tip={TC.GLOBAL_EDIT} disabled={!active_preset} />
                        <Button onClick={presets.remove} icon="times" variant="danger" tip={TC.GLOBAL_DELETE} disabled={!active_preset} />
                    </ButtonGroup>
                </Col>
            </Row>
        </Spinner>}

        <Table bordered hover>
            <thead>
                <tr>
                    <th></th>
                    {schedule.map((s, i) => <th
                        key={i}
                        className="text-center fw-bold fs-85"
                        style={props.readOnly ? undefined : { cursor: "pointer" }}
                        onDoubleClick={() => !props.readOnly && setEdit({ from: s.from, to: s.to, index: i })}
                    >
                        {s.from} - {s.to}
                    </th>)}
                </tr>
            </thead>
            <tbody>
                {DAYS.map(day => <tr key={day.value}>
                    <th>
                        {lg.getStaticText(day.label)}
                    </th>
                    {schedule.map((s, i) => render_cell(day, s, i))}
                </tr>)}
            </tbody>
        </Table>

        {edit && <Row className="align-items-center">
            <Col>
                <Form.Time
                    value={edit.from}
                    label={TC.SCHEDULE_FROM}
                    error={{ code: errors.from || '' }}
                    onChange={from => setEdit(p => ({ ...p, from }))}
                />
            </Col>
            <Col>
                <Form.Time
                    value={edit.to}
                    label={TC.SCHEDULE_TO}
                    error={{ code: errors.to || '' }}
                    onChange={to => setEdit(p => ({ ...p, to }))}
                />
            </Col>
            <Col md={1}>
                <Button size="sm" icon="check" onClick={edit_col} />
            </Col>
            <Col md={1}>
                <Button size="sm" icon="times" variant="danger" onClick={() => remove_col(edit.index)} />
            </Col>
        </Row>}

        {overlaps.length > 0 && <div className="text-danger fs-85 mb-3" >
            {overlaps.map((o, i) => <div key={i}>
                {lg.getStaticText(DAYS[o.day].label)} {o.schedule.from}-{o.schedule.to} : {lg.getStaticText(TC.SCHEDULE_OVERLAP)}
            </div>)}
        </div>}

        {!props.readOnly && <Flex alignItems="center" justifyContent="between">
            <Button onClick={add_col} icon="plus" text={TC.ADD_SCHEDULE_RANGE} />
            <Button icon="save" text={TC.GLOBAL_SAVE} onClick={submit} />
        </Flex>}
    </>;
}

export default Schedule;