import './treeSelect.scss';
import { BlankModal } from '../../Modal';
import * as Text from "../../../Constants/text";
import { LT, TB, FP } from "../../../Constants";
import * as US from '../../../services/user.service';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth, useFormIds, useLanguage } from '../../../hooks';
import { MIN_HEIGHT_MOBILE_DISPLAY, MIN_WIDTH_MOBILE_DISPLAY } from '../../../Constants/Constants';

const TreeSelect = ({ isPopUp = true, close, onValidate, createNew, fixedResource, submitRef, ...props }) => {
    //#region State
    const { language } = useLanguage();
    const [{ user, isAdmin }] = useAuth();
    const [forms] = useFormIds();

    const [linkTypes, setLinkTypes] = useState();
    const [{ nodes, links }, setData] = useState({});

    const [selectSite, setSelectedSite] = useState();
    const [selectBuild, setSelectedBuild] = useState();
    const [selectClient, setSelectedClient] = useState();
    const [searchString, setSearchString] = useState("");

    const isMobile = useMemo(() => window.innerHeight <= MIN_HEIGHT_MOBILE_DISPLAY || window.innerWidth <= MIN_WIDTH_MOBILE_DISPLAY, []);
    //#endregion

    //#region Forms
    const siteFormId = useMemo(() => forms[FP.SITE_FORM], [forms]);
    const buildFormId = useMemo(() => forms[FP.BUILDING_FORM], [forms]);
    const enterpriseFormId = useMemo(() => forms[FP.CLIENT_FORM], [forms]);
    const linkTypeFormId = useMemo(() => forms[FP.LINK_TYPE_FORM], [forms]);
    //#endregion

    //#region LinkTypes
    const linkTypesName = useMemo(() => [LT.LINK_TYPE_OWN], []);
    const ownLinkType = useMemo(() => Array.isArray(linkTypes) ? linkTypes.filter(({ data }) => data.type === LT.LINK_TYPE_OWN)?.[0]?._id : undefined, [linkTypes]);

    useEffect(() => {
        const getLinkTypes = async () => {
            let reply = await US.getManySubmissionsFromFilter({ form: linkTypeFormId, "data.type": linkTypesName });
            return Array.isArray(reply.data) ? reply.data : null;
        }
        if (TB.mongoIdValidator(linkTypeFormId)) getLinkTypes().then(setLinkTypes);
    }, [linkTypesName, linkTypeFormId]);
    //#endregion

    //#region User nodes
    useEffect(() => {
        const getNodes = async () => {
            let reply = await US.accessibleNodesUser(user?._id, ownLinkType, undefined, false, true);
            if ([reply?.data?.nodes, reply?.data?.links].every(Array.isArray)) return {
                links: reply.data.links,
                nodes: reply.data.nodes,
            }
            return { links: null, nodes: null };
        }

        if (TB.multiMongoIdValidator([user?._id, ownLinkType, buildFormId, enterpriseFormId, siteFormId]) && !isAdmin) getNodes().then(setData);
    }, [user, isAdmin, ownLinkType, buildFormId, enterpriseFormId, siteFormId]);

    useEffect(() => {
        const getNodes = async () => {
            let replyNodes = await US.getManySubmissionsFromFilter({ form: [enterpriseFormId, siteFormId, buildFormId] });
            if (Array.isArray(replyNodes.data)) {
                if (replyNodes.data.length > 0) {
                    let idList = replyNodes.data.map(({ _id }) => _id);
                    let filter = { input: idList, output: idList, type: ownLinkType, };
                    let replyLinks = await US.getLinksFromFilter(filter);
                    if (Array.isArray(replyLinks.data)) return { nodes: replyNodes.data, links: replyLinks.data };
                }
            }
            return { nodes: null, links: null };
        }
        if (isAdmin && TB.multiMongoIdValidator([buildFormId, enterpriseFormId, ownLinkType, siteFormId])) getNodes().then(setData);
    }, [buildFormId, enterpriseFormId, isAdmin, ownLinkType, siteFormId]);
    //#endregion

    //#region Data Separation
    const sites = useMemo(() => Array.isArray(nodes) && TB.mongoIdValidator(siteFormId) ? nodes.filter(({ form }) => form === siteFormId) : [], [nodes, siteFormId]);
    const buildings = useMemo(() => Array.isArray(nodes) && TB.mongoIdValidator(buildFormId) ? nodes.filter(({ form }) => form === buildFormId) : [], [nodes, buildFormId]);
    const enterprises = useMemo(() => Array.isArray(nodes) && TB.mongoIdValidator(enterpriseFormId) ? nodes.filter(({ form }) => form === enterpriseFormId) : [], [nodes, enterpriseFormId]);
    //#endregion

    //#region Link Handler
    useEffect(() => setSelectedSite(), [selectClient]);
    useEffect(() => setSelectedBuild(), [selectSite, selectClient]);

    // Search string input reinitialize selection
    useEffect(() => searchString.length > 0 ? setSelectedClient() : undefined, [searchString]);

    const checkSimilarity = useCallback(str => TB.checkStringSimilarity(str, searchString) >= 0.5, [searchString]);
    const checkIncludes = useCallback(str => typeof str === "string" ? str.toLowerCase().includes(searchString.toLowerCase()) : false, [searchString]);

    const sortByCloseness = useCallback((a, b) => {
        let aResemblance = TB.checkStringSimilarity(a?.data?.name, searchString);
        let bResemblance = TB.checkStringSimilarity(b?.data?.name, searchString);
        if (aResemblance > bResemblance) return -1;
        if (aResemblance < bResemblance) return 1;
        return 0;
    }, [searchString]);

    const getFullOutputs = useCallback(parentId => {
        let children = [];
        const recursive = ids => {
            if (!Array.isArray(ids) || ids.length === 0) return;
            let outputs = links.filter(({ output, input }) => ids.includes(input) && !children.includes(output)).map(({ output }) => output);
            children.push(...outputs);
            recursive(outputs);
        }
        recursive([parentId]);
        return children;
    }, [links]);

    const getAllInputs = useCallback(childId => {
        let parents = [];
        const recursive = ids => {
            if (!Array.isArray(ids) || ids.length === 0) return;
            let inputs = links.filter(({ output, input }) => ids.includes(output) && !parents.includes(input)).map(({ input }) => input);
            parents.push(...inputs);
            recursive(inputs);
        }
        recursive([childId]);
        return parents;
    }, [links]);

    const toLiftIds = useMemo(() => {
        if (TB.mongoIdValidator(selectClient)) return [];
        if (TB.mongoIdValidator(selectSite)) return getAllInputs(selectSite);
        if (TB.mongoIdValidator(selectBuild)) return getAllInputs(selectBuild);
        return [];
    }, [selectClient, selectSite, selectBuild, getAllInputs]);

    const sortByName = useCallback((a, b) => {
        let selectedValues = [selectClient, selectSite, selectBuild];
        if (selectedValues.includes(a?._id)) return -1;
        if (selectedValues.includes(b?._id)) return 1;

        let toLift = [a?._id, b?._id].filter(x => toLiftIds.includes(x));
        if (toLift.length === 1) return toLift[0] === a?._id ? -1 : 0;

        if (a?.data?.name > b?.data?.name) return 1;
        if (a?.data?.name < b?.data?.name) return -1;
        return 0;
    }, [selectClient, selectSite, selectBuild, toLiftIds]);

    const filterData = useCallback(list => list.filter(({ data }) => [checkSimilarity, checkIncludes].some(funct => funct(data?.name))).sort(sortByCloseness), [checkSimilarity, checkIncludes, sortByCloseness]);
    const availableClients = useMemo(() => searchString.length === 0 ? enterprises.sort(sortByName) : filterData(enterprises), [enterprises, searchString, filterData, sortByName]);

    const availableSites = useMemo(() => {
        if (searchString.length > 0) return filterData(sites);
        if (!TB.mongoIdValidator(selectClient) || !Array.isArray(links)) return sites.sort(sortByName);
        let outputs = getFullOutputs(selectClient);
        return sites.filter(({ _id }) => outputs.includes(_id)).sort(sortByName);
    }, [selectClient, links, sites, getFullOutputs, searchString, filterData, sortByName]);

    const availableBuilds = useMemo(() => {
        if (searchString.length > 0) return filterData(buildings);
        if (![selectClient, selectSite].some(TB.mongoIdValidator) || !Array.isArray(links)) return buildings.sort(sortByName);
        let outputs = getFullOutputs(TB.mongoIdValidator(selectSite) ? selectSite : selectClient);
        return buildings.filter(({ _id }) => outputs.includes(_id)).sort(sortByName);
    }, [selectClient, selectSite, links, buildings, getFullOutputs, searchString, filterData, sortByName]);
    //#endregion

    //#region Search bar
    useEffect(() => setSearchString(""), [selectBuild, selectClient, selectSite]);

    const searchBar = useMemo(() => <div className='searchBarPanel'>
        <input type='textfield' value={searchString} onChange={e => setSearchString(e.target.value)} placeholder={Text.GLOBAL_SEARCH_PLACEHOLDER[language] + "..."} />
        <button onClick={() => setSearchString("")}><i className='fa fa-times'></i></button>
    </div>, [language, searchString]);
    //#endregion

    //#region Selects 
    const boxesList = useMemo(() => [
        { array: availableClients, select: setSelectedClient, value: selectClient, title: Text.GLOBAL_LABEL_ENTERPRISE[language] },
        { array: availableSites, select: setSelectedSite, value: selectSite, title: Text.GLOBAL_LABEL_SITE[language] },
        { array: availableBuilds, select: setSelectedBuild, value: selectBuild, title: Text.GLOBAL_LABEL_BUILD[language] },
    ], [availableBuilds, availableSites, availableClients, selectBuild, selectClient, selectSite, language]);

    const onSelect = useCallback((id, value, select) => {
        if (TB.mongoIdValidator(value) && value === id) select();
        else select(id);
    }, []);

    const selectBoxes = useMemo(() => <div className='boxesContainer'>
        {boxesList.map(({ array, select, value, title }, i) => <div className='boxContainer' key={i}>
            <div className='titleDiv'>
                <span >{title}</span>
            </div>

            <div>
                {array.map(({ _id, data }) => <div className='boxType' key={_id}>
                    <span className={`${value === _id ? "selected" : ""} ${toLiftIds.includes(_id) ? "fst-italic fw-bold" : ""}`} onClick={() => onSelect(_id, value, select)}>{data?.name}</span>
                </div>)}
            </div>
        </div>)}
    </div>, [boxesList, toLiftIds, onSelect]);

    const mobileSelect = useMemo(() => <div>
        {boxesList.map(({ array, select, value, title }, i) => <div key={i} style={{ display: "grid", gridTemplateColumns: "25% 75%", margin: "7.5px 0px" }}>
            <label>{title}</label>
            <select value={value} onChange={e => onSelect(e.target.value, value, select)}>
                <option></option>
                {array.map(({ _id, data }) => <option key={_id} value={_id}>{data.name}</option>)}
            </select>
        </div>)}
    </div>, [boxesList, onSelect]);
    //#endregion

    //#region Validate
    const selectedOption = useMemo(() => {
        if (Array.isArray(fixedResource)) {
            if (fixedResource.includes(FP.BUILDING_FORM)) return selectBuild;
            if (fixedResource.includes(FP.SITE_FORM)) return selectSite;
            if (fixedResource.includes(FP.CLIENT_FORM)) return selectClient;
            return;
        }

        switch (fixedResource) {
            case FP.CLIENT_FORM: return selectClient;
            case FP.SITE_FORM: return selectSite;
            case FP.BUILDING_FORM: return selectBuild;
            default: return [selectBuild, selectSite, selectClient].filter(TB.mongoIdValidator)?.[0];
        }
    }, [selectBuild, selectSite, selectClient, fixedResource]);

    const allowValidation = useMemo(() => TB.mongoIdValidator(selectedOption), [selectedOption]);

    const validate = useCallback(() => {
        if (!TB.mongoIdValidator(selectedOption)) alert("todo pas d'option sélectionnée");
        else onValidate?.(selectedOption);
    }, [selectedOption, onValidate]);

    const validatePanel = useMemo(() => <div className={isPopUp ? "w-100 d-flex justify-content-around" : 'buttonsPanel'} style={isMobile ? { margin: "20px 0px" } : {}}>
        {typeof createNew === "function" && <button onClick={createNew} className='btn btn-primary'>{Text.G_CREATE_NEW[language]}</button>}
        <button ref={submitRef} disabled={!allowValidation} className='btn btn-primary stop-hiding' onClick={validate}>{Text.GLOBAL_CONFIRM[language]}</button>
    </div>, [language, isMobile, allowValidation, validate, createNew, isPopUp, submitRef]);
    //#endregion

    //#region Loading & errors
    const loadFailed = useMemo(() => [linkTypes, nodes, links].includes(null), [linkTypes, nodes, links]);
    const loading = useMemo(() => !loadFailed && [linkTypes, nodes, links].includes(undefined), [loadFailed, linkTypes, nodes, links]);
    const allOk = useMemo(() => !(loadFailed || loading), [loading, loadFailed]);

    const errorScreen = useMemo(() => loadFailed ? <div className='err'>Error</div> : undefined, [loadFailed]);
    const loadScreen = useMemo(() => loading ? <div className='loader'></div> : undefined, [loading]);
    const mainScreen = useMemo(() => !allOk ? undefined : <div className='p-0 mainContainerTreeSelect'>
        {searchBar}
        {isMobile ? mobileSelect : selectBoxes}
        {isPopUp ? undefined : validatePanel}
    </div>, [allOk, mobileSelect, isMobile, searchBar, selectBoxes, validatePanel, isPopUp]);
    //#endregion

    //#region popUp
    const popUpTreeSelect = useMemo(() => <BlankModal onQuit={close} size="lg" title={"Selection"} footer={allOk ? validatePanel : undefined}>
        {mainScreen}
        {errorScreen}
        {loadScreen}
    </BlankModal>, [errorScreen, loadScreen, mainScreen, allOk, validatePanel, close]);
    //#endregion

    return isPopUp ? popUpTreeSelect : <div className='h-100'>{mainScreen}{errorScreen}{loadScreen}</div>;
}

export default TreeSelect;