import { TB, TC } from "../../../Constants";
import React, { useMemo, useState } from "react";
import { StyleModalProps, default as Modal } from "../BlankModal";
import { ReduxWrapper, Form, Flex, Button } from "../../../Common";

export type TimeModalProps = {
    /** Callback for closing the modal */
    onClose?: () => void;
    /** Callback for validation of the value */
    onConfirm: (time?: Date | null) => void;
    /** Extra params to change the modal */
    modal?: StyleModalProps;
    /** A default date */
    date?: string | number | Date;
    /** Must there be a value to confirm ? */
    required?: boolean;
    /** Props for the input */
    timeProps?: Form.DateTimeProps;
}

const TimeModal: React.FC<TimeModalProps> = ({ onConfirm, ...props }) => {
    const [date, setDate] = useState(props.date);

    const dateObj = useMemo(() => TB.getDate(date), [date]);
    const canConfirm = useMemo(() => props.required ? dateObj !== null : true, [props.required, dateObj]);

    const footer = useMemo(() => <Flex justifyContent="end">
        {!props.required && <Button icon={{ icon: "times" }} className="me-2" variant="secondary" onClick={() => onConfirm?.(null)}>
            {TC.GLOBAL_IGNORE}
        </Button>}
        <Button icon={{ icon: "check" }} disabled={!canConfirm} onClick={() => onConfirm?.(dateObj)}>
            {TC.GLOBAL_CONFIRM}
        </Button>
    </Flex>, [canConfirm, dateObj, onConfirm, props.required]);

    return <Modal
        {...props.modal}
        footer={footer}
        onQuit={props.onClose}
        size={props.modal?.size || "md"}
        title={props.modal?.title || TC.MODAL_TIME_TITLE}
    >
        <Form.DateTime
            {...props.timeProps}
            value={date}
            onChange={setDate}
            label={props.timeProps?.label || TC.MODAL_TIME_LABEL}
        />
    </Modal>;
}

const WrappedTimeModal: React.FC<TimeModalProps> = props => <ReduxWrapper>
    <TimeModal {...props} />
</ReduxWrapper>;

export default WrappedTimeModal;