import { TB } from "../../Constants";
import Background from './Background';
import { Container } from 'react-bootstrap';
import { FC, ReactNode, useMemo } from 'react';

type SectionProps = {
    fluid?: boolean,
    bg?: string,
    image?: string,
    overlay?: boolean | string,
    position?: string | { x: string, y: string },
    video?: string[],
    bgClassName?: string,
    className?: string,
    children?: ReactNode,
}

const Section: FC<SectionProps> = ({ fluid = false, bg, image, overlay, position, video, bgClassName, className, children, ...props }) => {
    const bgProps = useMemo(() => ({
        video,
        overlay,
        position,
        image: TB.validString(image) ? image : "",
        className: TB.validString(bgClassName) ? bgClassName : undefined
    }), [image, overlay, bgClassName, position, video]);

    const sectionClassName = useMemo(() => `${TB.validString(bg) ? "bg-" + bg : ""} ${TB.validString(className) ? className : ""}`, [className, bg]);

    return <section className={sectionClassName} {...props}>
        {image && <Background {...bgProps} />}

        <Container fluid={fluid}>
            {children}
        </Container>
    </section>;
};

export default Section;
