import React from "react";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as S from "../../../services";
import { T, TB, TC } from "../../../Constants";

export type SendMailFormProps = {
    /** The id of the mission */
    mission_id: string;
    /** A list of intervenants email addresses */
    intervenants: string[];
    /** The name of the report's file */
    report_name: string;
    /** Show the form in a popup */
    popup?: boolean;
    /** Extra style properties to customise the modal */
    modal?: M.StyleModalProps;
    /** Callback after validation */
    on_validate?: (mail?: Mail) => void;
    /** View an existing mail info in read-only */
    mail?: Mail;
}

type Mail = T.Mission_Task_Report["versions"][0]["sent"][0];
const no_extension = (filename: string) => filename.split(".").slice(0, -1).join(".");

const SendMailForm: React.FC<SendMailFormProps> = ({ on_validate, ...props }) => {
    const loaded_default_body = H.useBoolean(false);
    const [errors, set_errors] = React.useState<T.Errors<Mail>>({});
    const disabled = React.useMemo(() => !!props.mail, [props.mail]);
    const [mail_options, set_mail_options] = React.useState<string[]>(props.intervenants || []);
    const [mail, set_mail] = React.useState<Mail>(props.mail || { body: "", cc: [], date: new Date().toISOString(), user_copy: false, object: no_extension(props.report_name), to: props.intervenants || [] });

    React.useEffect(() => {
        let isSubscribed = true;
        if (!loaded_default_body.value && !mail.body) {
            S.getDefaultReportMailBody({ file: props.report_name, mission: props.mission_id }).then(body => {
                if (isSubscribed) {
                    set_mail(p => ({ ...p, body: body.data }));
                    loaded_default_body.setTrue();
                }
            })
                .catch(M.Alerts.loadError);

            return () => {
                isSubscribed = false;
            }
        }
        else loaded_default_body.setTrue();
    }, [loaded_default_body, mail.body, props.mission_id, props.report_name]);

    const options = React.useMemo(() => {
        let all_options: T.Option[] = mail_options.map((mail) => ({ value: mail, label: mail }));
        let cc_options = all_options.filter(o => !mail.to.includes(o.value));
        let to_options = all_options.filter(o => !mail.cc.includes(o.value));
        return { cc: cc_options, to: to_options };
    }, [mail_options, mail.to, mail.cc]);

    const validate = React.useCallback(() => {
        let new_errors: typeof errors = {};
        // No destinataire or invalid destinataire
        if (mail.to.length === 0) new_errors.to = TC.MISSION_REPORT_MAIL_FORM_ERROR_NO_RECIPIENT;
        else if (mail.to.some(m => !TB.validateMail(m))) new_errors.to = TC.MISSION_REPORT_MAIL_FORM_ERROR_INVALIDATE_MAILS;
        // Invalid cc
        if (mail.cc.some(m => !TB.validateMail(m))) new_errors.cc = TC.MISSION_REPORT_MAIL_FORM_ERROR_INVALIDATE_MAILS;
        // No mail object
        else if (!mail.object) new_errors.object = TC.MISSION_REPORT_MAIL_FORM_ERROR_NO_OBJECT;
        // Apply errors or validate data
        if (Object.keys(new_errors).length > 0) set_errors(new_errors);
        else on_validate?.(mail);
    }, [mail, on_validate]);

    const footer = React.useMemo(() => !disabled && <C.Flex justifyContent="end">
        <C.Button icon="paper-plane" onClick={validate} size="sm" text={TC.MISSION_REPORT_MAIL_FORM_SEND} />
    </C.Flex>, [validate, disabled]);

    const add_option = React.useCallback((mail: string, is_cc = false) => {
        if (!TB.validateMail(mail)) M.renderAlert({ type: "warning", message: TC.MISSION_REPORT_MAIL_FORM_INVALID_EMAIL });
        else if (mail_options.includes(mail)) M.renderAlert({ type: "info", message: TC.MISSION_REPORT_MAIL_FORM_DUPE_MAIL });
        else {
            set_mail_options(p => p.concat(mail));
            if (is_cc) set_mail(p => ({ ...p, cc: p.cc.concat(mail) }));
            else set_mail(p => ({ ...p, to: p.to.concat(mail) }));
        }
    }, [mail_options]);

    return React.createElement(
        props.popup ? M.BlankModal : React.Fragment,
        props.popup ? {
            ...props.modal,
            footer: footer,
            height: "500px",
            size: props.modal?.size || "md",
            onQuit: () => on_validate?.(null),
            title: props.modal?.title || TC.MISSION_REPORT_MAIL_FORM_TITLE,
        } as M.BlankModalProps : null,
        <C.Spinner loading={!loaded_default_body.value} min_load_size="15rem">
            <C.Form.Select
                multiple
                addResource
                value={mail.to}
                error={errors.to}
                disabled={disabled}
                labelPosition="left"
                options={options.to}
                label={TC.MISSION_REPORT_MAIL_FORM_RECIPIENT}
                tooltip={TC.MISSION_REPORT_MAIL_FORM_NEW_MAIL_TIP}
                typeahead={{ onAddOption: m => add_option(m, false) }}
                onChange={(value) => set_mail(p => ({ ...p, to: value }))}
            />

            <C.Form.Select
                multiple
                addResource
                value={mail.cc}
                error={errors.cc}
                disabled={disabled}
                labelPosition="left"
                options={options.cc}
                label={TC.MISSION_REPORT_MAIL_FORM_CC}
                tooltip={TC.MISSION_REPORT_MAIL_FORM_NEW_MAIL_TIP}
                typeahead={{ onAddOption: m => add_option(m, true) }}
                onChange={(value) => set_mail(p => ({ ...p, cc: value }))}
            />

            {/* <C.Form.RadioBool
                name="user_copy"
                disabled={disabled}
                value={mail.user_copy}
                label={TC.MISSION_REPORT_MAIL_FORM_USER_COPY}
                onChange={(value) => set_mail(p => ({ ...p, user_copy: value }))}
            /> */}

            <C.Form.TextField
                disabled={disabled}
                value={mail.object}
                labelPosition="left"
                error={errors.object}
                label={TC.MISSION_REPORT_MAIL_FORM_OBJECT}
                onChange={(value) => set_mail(p => ({ ...p, object: value }))}
            />

            <C.Form.RichText
                tools="underline"
                value={mail.body}
                disabled={disabled}
                error={errors.body}
                labelPosition="left"
                label={TC.MISSION_REPORT_MAIL_FORM_BODY}
                style={{ width: '100%', height: '10rem' }}
                onChange={(value) => set_mail(p => ({ ...p, body: value }))}
            />

            {!props.popup && footer}
        </C.Spinner>
    );
}

export default SendMailForm;