import _ from "lodash";
import React from "react";
import * as M from "../Modal";
import * as RD from "../RegDoc";
import * as H from "../../hooks";
import * as C from "../../Common";
import * as RX from "react-redux";
import * as S from "../../services";
import * as R from "../../reducers";
import * as BS from "react-bootstrap";
import EnergyDashboard from "./Energy";
import * as DOM from "react-router-dom";
import MissionReport from "./MissionReport";
import { BuildEditor } from "../BuildEditor";
import * as FTB from "../../Constants/FileToolBox";
import EquipmentIndicators from "./EquipmentsIndicators";
import { FP, MISSION, PANELS, RIGHTS, TABS, TB, TC } from "../../Constants";

//#region Types
type Tab = {
    /** The name of the tab */
    label: string;
    /** Show or not the tab */
    hide?: boolean;
    /** If tab is shown, disable it or not */
    disabled?: boolean;
    /** The unique tab code */
    code: "general" | "missions" | "energy" | "reglementation" | "tech";
}

type ContextData = {
    /** The building currently selected */
    current_build?: string;
    /** The list of buildings available in the current context */
    buildings: Awaited<ReturnType<typeof S.getContextBuildings>>["data"],
    /** The currently activated tab */
    tab: Tab["code"];
};
//#endregion

const IDCard: React.FC = () => {
    const lg = H.useLanguage();
    const rights = H.useRights();
    H.useCrumbs(TC.PANEL_ID_CARD);
    const [context] = H.useRoots();
    const dispatch = RX.useDispatch();
    const show_editor = H.useBoolean(false);
    const hide_options = H.useBoolean(false);
    const [url_params] = DOM.useSearchParams();
    const hide_disabled = H.useHideUnavailable();
    const [html, set_html, html_status] = H.useAsyncState("");
    const [context_data, set_context_data, data_status] = H.useAsyncState<ContextData>({ buildings: [], tab: "general" });

    //#region Get context's data
    const [current_context, is_url] = React.useMemo<[typeof context, boolean]>(() => {
        let url_root = url_params.get("root");
        if (TB.mongoIdValidator(url_root)) return [{ roots: url_root }, true];
        else return [context, false];
    }, [url_params, context]);

    React.useEffect(() => {
        let isSubscribed = true;
        // Find the context's building
        S.getContextBuildings(current_context)
            .then(({ data }) => isSubscribed && set_context_data({ buildings: data, tab: "general", current_build: data[0]?.value || null }, "done"))
            .catch(() => isSubscribed && set_context_data({ buildings: [], tab: "general", current_build: null }, "error"));
        return () => {
            isSubscribed = false;
            set_context_data({ buildings: [], tab: "general", current_build: null }, "load");
        }
    }, [current_context, set_context_data]);

    H.useEffectOnce(() => {
        // If the context comes from the url, select it
        if (is_url) dispatch(R.selectContextItem({ path: FP.BUILDING_FORM, item: current_context.roots as string }));
    });
    //#endregion

    //#region Get the id card's HTML
    const get_dashboard = React.useCallback((root = context_data.current_build) => S.html_report({
        params: { buildingId: root, lang: lg.prop },
        template: "ID_CARD_DEFAULT(clone)",
    }), [lg.prop, context_data.current_build]);

    React.useEffect(() => {
        let isSubscribed = true;
        // Load the HTML
        if (context_data.current_build) get_dashboard(context_data.current_build)
            .then(({ data }) => isSubscribed && set_html(data, "done"))
            .catch(() => isSubscribed && set_html("", "done"));
        return () => {
            isSubscribed = false;
            set_html("", "load");
        }
    }, [context_data.current_build, set_html, get_dashboard]);
    //#endregion

    //#region Sorting & Conditions
    const sorted_buildings = React.useMemo(() => _.sortBy(context_data.buildings, "label"), [context_data.buildings]);

    const show_banner = React.useMemo(() => ({
        html: html_status === "done" && data_status === "done" && !context_data.current_build,
        context: data_status === "done" && context_data.buildings.length === 0,
    }), [context_data, html_status, data_status]);
    //#endregion

    //#region Other Actions
    const context_name = React.useMemo(() => {
        let building_option = context_data.buildings.filter(b => b.value === context_data.current_build)[0];
        return building_option?.label || "";
    }, [context_data]);

    const actions = React.useMemo(() => ({
        to_editor: show_editor.setTrue,
        tickets: () => FTB.downloadJsReportFile("ticketReport", { extension: "docx", name: context_name || "NO_NAME" }, { root: context_data.current_build }),
        xml: () => {
            let unmount = M.renderLoader(lg.getStaticText(TC.GLOBAL_GENERATING_FILE));
            S.generateXML({ root: context_data.current_build, fileName: context_name, exportFiles: true })
                .catch(() => M.Alerts.loadError({ message: TC.GLOBAL_EXPORT_ERROR_OCCURRED }))
                .finally(unmount);
        },
        sustainability: () => {
            let unmount = M.renderLoader();
            S.getSustainabilityData(context_data.current_build).then(({ data }) => {
                M.askReportSurface({ build_surface: data }).then(params => {
                    if (params) {
                        let api_params = {
                            to: params.to,
                            from: params.from,
                            country: data.country,
                            surface: params.surface,
                            buildingname: data.name,
                            building: context_data.current_build,
                        };

                        let download_options: Parameters<typeof FTB.downloadJsReportFile>[1] = {
                            extension: "docx",
                            name: "Sustainability - " + api_params.buildingname,
                        };

                        FTB.downloadJsReportFile("SustainabilityHTML", download_options, api_params)
                    }
                });
            })
                .catch(M.Alerts.loadError)
                .finally(unmount);
        },
        cells_report: () => M.askReportCells({ building: context_data.current_build }).then(cells_params => {
            if (cells_params) {
                let api_params = {
                    to: cells_params.to,
                    from: cells_params.from,
                    cells: cells_params.cells,
                    group: cells_params.group,
                    buildingname: cells_params.name,
                    building: context_data.current_build,
                };

                let download_options: Parameters<typeof FTB.downloadJsReportFile>[1] = {
                    extension: "docx",
                    name: "Cells - " + api_params.buildingname,
                };

                FTB.downloadJsReportFile("cellsRepport", download_options, api_params)
            }
        }),
        set_dashboard: () => {
            // Mark the html as loading
            set_html("", "load");
            get_dashboard()
                .then(({ data }) => set_html(data, "done"))
                .catch(() => set_html("", "error"));
        },
    }), [context_data.current_build, context_name, lg, show_editor, get_dashboard, set_html]);

    const actions_list = React.useMemo(() => {
        let allow_refresh = TB.mongoIdValidator(context_data.current_build) && html_status !== "load";
        let allowEdit = allow_refresh && rights.isPageAllowed(TABS.BUILDING_EDITOR);
        let allowTicketReport = allow_refresh && rights.isRightAllowed(RIGHTS.ACTION.TICKET_REPORT);

        let hideEdit = hide_disabled && !allowEdit;
        let hideReport = hide_disabled && !allowTicketReport;

        return [
            { label: TC.GLOBAL_REFRESH, icon: "sync", fn: actions.set_dashboard, disabled: !(allow_refresh && context_data.tab === "general") },
            { label: TC.GLOBAL_EDIT, icon: "pencil-alt", fn: actions.to_editor, disabled: !allowEdit, hide: hideEdit },
            { label: TC.ID_CARD_TICKET_WORD, icon: "file-word", fn: actions.tickets, disabled: !allowTicketReport, hide: hideReport },
            { label: TC.BUILD_ID_CARD_XML_EXPORT, icon: "file-download", fn: actions.xml, disabled: false },
            { label: TC.SUSTAINABILITY_REPORT_MODAL, icon: "file-word", fn: actions.sustainability, disabled: false },
            { label: TC.CELLS_REPORT_TILE, icon: "file-word", fn: actions.cells_report, disabled: false },
        ].filter(a => !a.hide);
    }, [actions, context_data.current_build, context_data.tab, hide_disabled, html_status, rights]);
    //#endregion

    //#region Tabs & Panels
    const tabs = React.useMemo(() => {
        let root = context_data.current_build;
        let mission_report_rights = MISSION.MissionTypes.map(m => m.report_right);

        const showTechTab = rights.isPanelAllowed(PANELS.TABS_BUILD_TECH, root);
        const showRegTab = rights.isPanelAllowed(PANELS.TABS_IDS_CARD_REG, root);
        const showEnergyTab = rights.isPanelAllowed(PANELS.TABS_BUILD_ENERGY, root);
        const show_mission_tab = mission_report_rights.some(r => rights.isRightAllowed(r, root));

        return [
            { code: "general", label: TC.BR_TAB_LABEL_GENERAL },
            { code: "missions", label: TC.ID_CARD_PANEL_MISSION_REPORT, disabled: !show_mission_tab, hide: hide_disabled && !show_mission_tab },
            { code: "energy", label: TC.GLOBAL_ENERGY, disabled: !showEnergyTab, hide: hide_disabled && !showEnergyTab },
            { code: "reglementation", label: TC.CRUMB_REG, disabled: !showRegTab, hide: hide_disabled && !showRegTab },
            { code: "tech", disabled: !showTechTab, label: TC.BUILD_EDIT_ELEM_TECH, hide: hide_disabled && !showTechTab },
        ].filter(t => !t.hide) as Tab[];
    }, [context_data.current_build, hide_disabled, rights]);
    //#endregion

    return <C.Flex direction="column" className="w-100">

        {show_banner.context
            ? <C.ErrorBanner type="warning" textCode={TC.GLOBAL_NO_CONTEXT} />
            : <C.Spinner status={data_status}>
                {context_data.buildings.length > 1 && <BS.Row className="g-1 mb-2">
                    {/* Hide / Show the options */}
                    {<BS.Col md={3} sm={6} xl={2}>
                        <C.Button
                            variant="secondary"
                            className="w-100 h-100"
                            onClick={hide_options.toggle}
                            style={{ textOverflow: "ellipsis" }}
                            text={hide_options.value ? TC.ID_CARD_SHOW_OPTIONS : TC.ID_CARD_HIDE_OPTIONS}
                        />
                    </BS.Col>}
                    {/* The list of buildings available */}
                    {!hide_options.value && sorted_buildings.map(b => <BS.Col key={b.value} md={3} sm={6} xl={2}>
                        <C.Button
                            text={b.label}
                            className="w-100 h-100"
                            active={b.value === context_data.current_build}
                            style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                            onClick={() => set_context_data(p => ({ ...p, current_build: b.value }))}
                        />
                    </BS.Col>)}
                </BS.Row>}

                {show_editor.value
                    ? <BuildEditor build_id={context_data.current_build} on_quit={show_editor.setFalse} />
                    : <>
                        <C.Flex className="mb-2 w-100">
                            <BS.Row className="g-2 flex-grow-1">
                                {tabs.map(t => !t.hide && <BS.Col key={t.code} md={4} sm={6} xl={2}>
                                    <C.Button
                                        variant="info"
                                        text={t.label}
                                        disabled={t.disabled}
                                        className="w-100 h-100"
                                        active={t.code === context_data.tab}
                                        style={{ textOverflow: "ellipsis" }}
                                        onClick={() => set_context_data(p => ({ ...p, tab: t.code }))}
                                    />
                                </BS.Col>)}
                            </BS.Row>
                            <BS.Dropdown className="ms-1">
                                <BS.Dropdown.Toggle variant="secondary" size="sm" children={<i className="fa fa-ellipsis-v me-2" />} />
                                <BS.Dropdown.Menu>
                                    {actions_list.map((a, i) => <BS.Dropdown.Item key={i} disabled={a.disabled} onClick={a.fn}>
                                        <i className={"me-2 fa fa-" + a.icon}></i>
                                        {lg.getStaticText(a.label)}
                                    </BS.Dropdown.Item>)}
                                </BS.Dropdown.Menu>
                            </BS.Dropdown>
                        </C.Flex>

                        {context_data.tab === "general" && <>
                            {show_banner.html
                                ? <C.ErrorBanner type="info" textCode={TC.GLOBAL_NO_BUILD_CONTEXT} />
                                : <C.Spinner status={html_status} children={<C.HtmlText html={html} />} />}
                        </>}

                        {context_data.tab === "missions" && <MissionReport asset={context_data.current_build} />}
                        {context_data.tab === "energy" && <EnergyDashboard building={context_data.current_build} />}
                        {context_data.tab === "tech" && <EquipmentIndicators root={context_data.current_build} />}
                        {context_data.tab === "reglementation" && <RD.RegDashboard building={context_data.current_build} />}
                    </>}
            </C.Spinner>}
    </C.Flex>;
}

export default IDCard;