import './Event.scss';
import _ from "lodash";
import { TC, TB } from '../../../Constants';
import { useLanguage } from "../../../hooks";
import { useEffect, useMemo, useRef } from "react";
import { Draggable } from '@fullcalendar/interaction';
import Accordion from "../../cards/Accordion/Accordion";

function Event({ title, structs, data, color = "#f5f5f5", deleteFunc, isInternal = true, onDoubleClick, onClick, draggable = true, editFunc }) {
    const { getStaticText } = useLanguage([TC.GLOBAL_LABEL_EQUIPMENT]);
    const myRef = useRef();

    useEffect(() => draggable ? new Draggable(myRef.current, { create: false }) : undefined, [draggable]);
    const eventTitle = useMemo(() => `${title}${isInternal ? "" : " (externe)"}`, [title, isInternal]);

    //#region Delete
    const showDelete = useMemo(() => typeof deleteFunc === 'function', [deleteFunc]);

    const deleteButton = useMemo(() => !showDelete ? undefined : <div className="trash">
        <i onClick={deleteFunc} className="fa fa-trash"></i>
    </div>, [showDelete, deleteFunc]);
    //#endregion

    //#region EquipmentName
    const equipmentName = useMemo(() => {
        let name = _.find(structs, ({ isName }) => isName)?.value;
        if (TB.validString(name)) return name;
        return getStaticText(TC.GLOBAL_LABEL_EQUIPMENT);
    }, [structs, getStaticText]);

    const header = useMemo(() => !TB.validString(equipmentName) ? eventTitle : <div className="fw-normal">
        <div className="border-bottom border-1 pb-1 mb-1">{equipmentName}</div>
        <div>{eventTitle}</div>
    </div>, [equipmentName, eventTitle]);
    //#endregion

    const textColorEdited = useMemo(() => TB.isColorWhite(color) ? "#000" : "#fff", [color]);

    return <div className={'event ' + (showDelete ? "separated" : "")} data-event={data} ref={myRef} onClick={onClick}>
        {deleteButton}
        <div className="eventItem">
            <Accordion header={header} color={color} textColor={textColorEdited} onDoubleClick={onDoubleClick} editFunc={editFunc}>
                <div>
                    {structs.filter(({ value }) => value !== null).map((struct, index) => <div className="line" key={index}>
                        <h6>{struct.label} :</h6>
                        <p className='value'>{struct.value}</p>
                    </div>)}
                </div>
            </Accordion>
        </div>
    </div>
}

export default Event;