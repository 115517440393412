import "./Slide.scss";
import "./Element.scss"
import Menu from "./Menu";
import React from "react";
import { FP, T, TB } from "../../../../Constants";

const isEntity = (node: T.NodeDia): node is T.NodeDia<T.EntityData> => node.path === FP.ENTITY_FORM;

const Node: React.FC<T.DiaElementProps> = ({ node, hidden, rights, onPick, ...props }) => {
  const [sideOpen, setSideOpen] = React.useState(false);

  const entityIcon = React.useMemo(() => {
    if (isEntity(node)) {
      switch (node.data.typedecompteur) {
        case "MANUAL": return <i className='me-2 fa fa-clipboard'></i>;
        case "CALCULATED": return <i className='me-2 fa fa-calculator'></i>;
        case "AUTOMATIC_READING": return <i className='me-2 fa fa-database'></i>;
        default: return null;
      }
    }
  }, [node]);

  const imgUrl = React.useMemo(() => TB.iconIdToUrl(node.icon), [node.icon]);
  const color = React.useMemo(() => TB.getColor(node.color) || "#FFFFFF", [node.color]);
  const hiddenStackEffect = React.useMemo(() => `0 1px 1px rgba(0,0,0,0.15),0 10px 0 -5px ${color},0 10px 1px -4px rgba(0,0,0,0.15),0 20px 0 -10px ${color},0 20px 1px -9px rgba(0,0,0,0.15)`, [color]);

  return <div className="mainDivElement defaultElem" key={node.id} style={{ backgroundColor: color, boxShadow: hidden ? hiddenStackEffect : "" }}>
    <button className='slideMenuButton' onClick={() => !props.noMenu && setSideOpen(true)}>
      <div className="name">
        <p>{entityIcon} {node.title}</p>
      </div>
      {imgUrl && <div className={"otherInfos solo"}>
        <div className='img'>
          <img src={imgUrl} alt="" />
        </div>
      </div>}
    </button>

    <Menu open={sideOpen} onClose={setSideOpen} onPick={onPick} node={node} {...props} />
  </div>
}

export default Node;