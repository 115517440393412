import axios from "axios";
import { T, TB, URL } from "../../Constants";

const { TREE_API } = URL;

type NewLinkOptions = T.API.ToAxios<T.API.Utils.Link.NewLinkOptions>;
type GetContextSites = T.API.ToAxios<T.API.Utils.Context.GetContextSites>;
type GetContextOptions = T.API.ToAxios<T.API.Utils.Context.GetContextOptions>;
type ContextDestructure = T.API.ToAxios<T.API.Utils.Context.ContextDestructure>;
type GetContextBuildings = T.API.ToAxios<T.API.Utils.Context.GetContextBuildings>;
type GetContextEquipments = T.API.ToAxios<T.API.Utils.Context.GetContextEquipments>;
type GetContextName = T.API.ToAxios<T.API.Utils.Context.GetContextName<T.ContextParams>>;

export const newLinkOptions: NewLinkOptions = params => axios.post(TREE_API + "link/options", params);

/** Get all the sites found under or over a context (depending on the context) */
export const getContextSites: GetContextSites = context => axios.post(TREE_API + "sites", context);
/** Get all the buildings found under or over a context (depending on the context) */
export const getContextBuildings: GetContextBuildings = context => axios.post(TREE_API + "buildings", context);
/** Get the Equipments found under a context */
export const getContextEquipments: GetContextEquipments = params => axios.post(TREE_API + "equipments", params);
/** Destructure a context to recover it's infos */
export const contextDestructure: ContextDestructure = context => axios.post(TREE_API + "context/destructure", context);
/** Get a context's places options */
export const getContextOptions: GetContextOptions = params => axios.post(TREE_API + "context/options", params);
/** Get a context's name */
export const getContextName: GetContextName = (context) => {
    let params = { portfolio: context?.portfolio, roots: TB.arrayWrapper(context?.roots)?.join() };
    return axios.get(TREE_API + "context/name", { params });
}