import { TB } from "../../../Constants";
import renderInContainer from "../getContainer";
import { MultiLinkModal, MultiLinkModalProps } from "../Components";

export type AskMultiLinkModalProps = Omit<MultiLinkModalProps, "onClose" | "onCreate">;

const askMultiLink = (params?: AskMultiLinkModalProps) => new Promise<Parameters<MultiLinkModalProps["onCreate"]>[0]>(resolve => {
    let [render, dismount] = renderInContainer();
    if (!TB.validObject(params)) params = { input: "" };
    if (render && dismount) {
        let onClose = () => dismount(() => resolve(null));
        let onCreate: MultiLinkModalProps["onCreate"] = link => dismount(() => resolve(link));
        render(<MultiLinkModal {...params} onClose={onClose} onCreate={onCreate} />);
    }
    else resolve(null);
});

export default askMultiLink;