import { IDAList } from "../Types";

export const BOUCHE_PCT = 0.32;
export const M_SQUARE_PER_PERS = 15;
export const FACTOR_SURFACE_TOT = 0.8;
export const FACTOR_SURFACE_VOLUME = 2.7;

export const IDA_1 = "IDA1";
export const IDA_2 = "IDA2";
export const IDA_3 = "IDA3";
export const IDA_4 = "IDA4";
export const NO_IDA = "IDA";

export const GET_AIR_QUALITY_ON_VALUE = (value?: number): IDAList => {
    if (value > 54) return IDA_1;
    if (value > 36) return IDA_2;
    if (value > 22) return IDA_3;
    return IDA_4;
}

export const AIR_QUALITY_STYLE = (airQuality?: IDAList | any) => {
    switch (airQuality) {
        case IDA_1: return '#008000';
        case IDA_2: return '#00FF00';
        case IDA_3: return '#FFA500';
        case IDA_4: return '#FF0000';
        default: return '#000000';
    }
}