import _ from "lodash";
import React from "react";
import * as G from "../Grid";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as S from "../../../services";
import { CellsTypes as CT } from "../AgGridDefs";
import { MISC, T, TABS, TC } from "../../../Constants";

const Options = MISC.InfoBubblesOptions;
type Row = ReturnType<T.API.Access.User.GetAlertsList>[number];

export const Announcements: React.FC = () => {
    const lg = H.useLanguage();
    H.useAuth({ onlyAdmin: true });
    H.useCrumbs(TC.BUBBLE_TABLE_BOARD);
    const loading = H.useBoolean(false);
    const [alerts, set_alerts, status] = H.useAsyncState<Row[]>([]);
    const [pages, set_pages, status_pages] = H.useAsyncState<T.Option[]>([]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getEquipmentResource({ type: "app_pages" })
            .then(({ data }) => isSubscribed && set_pages(data as T.Option[], "done"))
            .catch(() => isSubscribed && set_pages([], "error"));
        return () => {
            isSubscribed = false;
            set_pages([], "load");
        }
    }, [set_pages]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getAlertsList(null)
            .then(({ data }) => isSubscribed && set_alerts(data, "done"))
            .catch(() => isSubscribed && set_alerts([], "error"));
        return () => {
            isSubscribed = false;
            set_alerts([], "load");
        }
    }, [set_alerts]);

    const options = React.useMemo(() => ({
        size: Options.size,
        variants: Options.variant,
        auto_fit: ["test_render"] as (keyof Row | "test_render")[],
        pages: [{ value: "any", label: TC.BUBBLE_FORM_ANY_PAGE_OPT }].concat(pages),
        render_variant: item => <div className={`alert-${item.bs}`} children={item.label} />,
        render: (row: Row) => {
            let title = <C.HtmlText html={lg.getTextObj(row._id, "title", row.title) || ""} />;
            let message = <C.HtmlText html={lg.getTextObj(row._id, "message", row.message) || ""} />;
            // Render the alert
            M.renderAlert({ title, message, size: row.size, type: row.variant, delay: row.duration });
        },
    }), [lg, pages]);

    const rows = React.useMemo(() => alerts.map(a => ({
        ...a,
        page_name: lg.getStaticText(a.page_label),
        variant_name: lg.getStaticText(a.variant),
        title_tr: lg.getTextObj(a._id, "title", a.title),
        message_tr: lg.getTextObj(a._id, "message", a.message),
    })), [alerts, lg]);

    const columns = React.useMemo<G.TableProps<Row>["columns"]>(() => [
        {
            headerName: " ",
            field: "test_render",
            type: CT.TYPE_ACTION_BUTTON,
            params: { action: options.render, buttonProps: { size: "sm", icon: "vial", text: TC.BUBBLE_FORM_TEST_RENDER } },
        },
        {
            field: "page_name",
            type: CT.TYPE_SELECT,
            headerName: TC.BUBBLE_FORM_PAGE,
            params: { auto_fit: true, values: options.pages, loading: status_pages === "load" },
        },
        { field: "title_tr", headerName: TC.BUBBLE_FORM_TITLE_PROP, editable: false },
        { field: "message_tr", headerName: TC.BUBBLE_FORM_MESSAGE, editable: false },
        { field: "expiration_date", headerName: TC.BUBBLE_FORM_EXP_DATE, type: CT.TYPE_DATE, params: { isDateTime: true } },
        {
            type: CT.TYPE_SELECT,
            field: "variant_name",
            headerName: TC.BUBBLE_FORM_VARIANT,
            params: { values: options.variants, typeahead: { renderItem: options.render_variant } },
        },
        { field: "size", headerName: TC.BUBBLE_FORM_SIZE, type: CT.TYPE_SELECT, params: { values: options.size } },
        { field: "duration", headerName: TC.BUBBLE_FORM_DURATION, type: CT.TYPE_NUMBER, params: { min: 1, maxDigit: 0, unit: "s", formatted: true } },
    ], [options, status_pages]);

    const events = React.useMemo(() => ({
        delete: (row: Row) => M.askConfirm().then(confirmed => {
            if (confirmed) {
                loading.setTrue();
                S.deleteAlert(row._id)
                    .then(() => set_alerts(p => p.filter(r => r._id !== row._id)))
                    .catch(M.Alerts.deleteError)
                    .finally(loading.setFalse);
            }
        }),
        edit: (row: Row) => M.renderAlertBubbleForm({ id: row._id }).then(bubble => {
            if (bubble) {
                loading.setTrue();
                S.getAlertsList(bubble._id)
                    .then(({ data }) => set_alerts(p => p.map(r => r._id === bubble._id ? data[0] : r)))
                    .catch(M.Alerts.updateError)
                    .finally(loading.setFalse);
            }
        }),
        add: () => M.renderAlertBubbleForm().then(bubble => {
            if (bubble) {
                loading.setTrue();
                S.getAlertsList(bubble._id)
                    .then(({ data }) => set_alerts(p => p.concat(data)))
                    .catch(M.Alerts.updateError)
                    .finally(loading.setFalse);
            }
        }),
    }), [set_alerts, loading]);

    const context_menu = React.useCallback<G.TableProps<Row>["getContextMenuItems"]>((params) => {
        let items = [{
            action: events.add,
            name: lg.getStaticText(TC.ADD),
            icon: "<i class='fas fa-plus'></i>",
        }] as ReturnType<G.TableProps<Row>["getContextMenuItems"]>;

        if (params.node?.data) items.push(
            {
                name: lg.getStaticText(TC.UPDATE),
                icon: "<i class='fas fa-pencil-alt'></i>",
                action: () => events.edit(params.node.data),
            },
            {
                name: lg.getStaticText(TC.GLOBAL_DELETE),
                action: () => events.delete(params.node.data),
                icon: "<i class='fas fa-times text-danger'></i>",
            },
        );
        if (items.length > 0 && params.defaultItems) items.push("separator");
        if (params.defaultItems) items.push(...params.defaultItems);
        return items;
    }, [lg, events]);

    const on_change = React.useCallback<G.TableProps<Row>["onValueChange"]>((params) => {
        let prop: keyof T.InfosBubble;
        let row = params.data,
            old_value = params.oldValue,
            field = params.colDef.field as keyof typeof rows[number];

        // Set the right prop
        if (field === "variant_name") {
            prop = "variant";
            old_value = row.variant;
        }
        else if (field === "page_name") {
            prop = "page";
            old_value = row.page;
        }
        else prop = field as typeof prop;

        const updatePromise = new Promise<"cancel" | Row[]>((resolve, reject) => {
            if (!_.isEqual(old_value, params.newValue)) {
                let api_params = {
                    field: prop,
                    _id: row._id,
                    old_value: old_value,
                    new_value: params.newValue,
                } as Parameters<typeof S.edit_announcement_field>[0];

                loading.setTrue();

                S.edit_announcement_field(api_params).then(({ data }) => {
                    if (data === "changed") M.askConfirm({ title: TC.UPDATE_FORCE_CHANGE, text: TC.UPDATE_VALUE_UNFRESH }).then(confirmed => {
                        if (confirmed) S.edit_announcement_field({ ...api_params, force_update: true }).then(({ data }) => {
                            if (data === "changed") reject("Error");
                            else resolve(data);
                        }).catch(reject);
                        else resolve("cancel");
                    });
                    else resolve(data);
                }).catch(reject);
            }
            else resolve("cancel");
        });

        updatePromise.then(rows => {
            if (rows !== "cancel") set_alerts(p => {
                let existing_ids = p.map(r => r._id);
                let [to_update, to_add] = _.partition(rows, r => existing_ids.includes(r._id));
                return p
                    .map(r => to_update.filter(row => row._id === r._id)[0] || r)
                    .concat(to_add);
            });
        })
            .catch(M.Alerts.updateError)
            .finally(loading.setFalse);
    }, [loading, set_alerts]);

    return <div className="w-100">
        <C.Spinner error={status === "error"}>
            <G.Table<Row>
                sideBar
                rows={rows}
                status={status}
                columns={columns}
                columns_base="all"
                onValueChange={on_change}
                autoFit={options.auto_fit}
                getContextMenuItems={context_menu}
                adaptableId={TABS.ADMIN_SYS_ALERTS}
                loading={status === "load" || loading.value}
            />
        </C.Spinner>
    </div>;
}