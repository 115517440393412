import React from "react";
import * as I from "../types";
import * as M from "../../../Modal";
import * as H from "../../../../hooks";
import * as BS from "react-bootstrap";
import { TB, TC, T } from "../../../../Constants";
import * as C from "../../../../Common";

export type Props = {};
export const type = "agCustomFile";

export const Cell = React.forwardRef<{}, I.CellProps<Props>>((props, ref) => {
    const lg = H.useLanguage();
    const files = React.useMemo(() => TB.arrayWrapper(props.value || props.getValue?.() || []).filter(TB.isFile), [props]);

    const show_file = React.useCallback(() => {
        // All files are images, render them in a carousel
        if (files.every(f => f.type && f.type.includes("image"))) M.renderCarousel({ images: files.map(f => ({ title: f.originalName, src: f.url })) });
        // Only one file, open it in a new tab
        else if (files.length === 1) window.open(files[0].url, "_blank");
        else {
            const render_file = (file: T.File) => {
                // File is an image, render it as an image
                if (file.type && file.type.includes("image")) return <img className="w-100" style={{ maxHeight: "200px", objectFit: "scale-down" }} src={file.url} alt="" title={file.originalName} />;
                // File is not an image, render it as a download link
                else return <a className="btn btn-primary" href={file.url} target="_blank" rel="noreferrer">
                    <i className="fa fa-file-download me-2" />
                    {lg.getStaticText(TC.GLOBAL_DOWNLOAD)}
                </a>;
            };

            M.renderBlankModal({
                size: "md",
                maxBodyHeight: "55vh",
                title: TC.G_FILES_LABEL,
                children: <>
                    <BS.Row className="g-1 fw-bold">
                        <BS.Col xs={3} children={lg.getStaticText(TC.GLOBAL_NAME)} />
                        <BS.Col xs={3} children={lg.getStaticText(TC.TYPE)} />
                        <BS.Col className="text-center" children={lg.getStaticText(TC.G_FILE_LABEL)} />
                    </BS.Row>

                    {files.map((file, i) => <React.Fragment key={file.name || i}>
                        <hr />
                        <BS.Row className="g-1">
                            <BS.Col xs={3} className="text-break">{file.originalName}</BS.Col>
                            <BS.Col xs={3} className="text-break fs-85">{file.type}</BS.Col>
                            <BS.Col children={<C.Flex justifyContent="end" alignItems="center" children={render_file(file)} />} />
                        </BS.Row>
                    </React.Fragment>)}
                </>,
            });
        }
    }, [files, lg]);

    const files_list = React.useMemo(() => {
        if (files.length === 0) return null;
        else if (files.length === 1) return <span onClick={show_file} className="pointer false_link" children={files[0]?.originalName} />;
        else return <span onClick={show_file} className="pointer false_link" children={lg.getStaticText(TC.CERT_NB_FILES, files.length)} />;
    }, [files, show_file, lg]);

    return <div className="h-100 w-100 text-center" children={files_list} />;
});