import { TB } from "../../../Constants";
import renderInContainer from "../getContainer";
import { LargePrompt, LargePromptProps } from "../Components";

export type AskLargePromptModalProps = Omit<LargePromptProps, "onQuit" | "onValidate">;

const askLargePrompt = (params?: AskLargePromptModalProps) => new Promise<Parameters<LargePromptProps["onValidate"]>[0]>(resolve => {
    const [render, dismount] = renderInContainer();
    if (!TB.validObject(params)) params = {};

    if (render && dismount) {
        let onQuit = () => dismount(() => resolve(null));
        let onValidate: LargePromptProps["onValidate"] = prompt => dismount(() => resolve(prompt));
        render(<LargePrompt {...params} onQuit={onQuit} onValidate={onValidate} />);
    }
    else resolve(null);
});

export default askLargePrompt;