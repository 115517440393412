import React, { useState } from "react";
import { TB, TC } from "../../../Constants";
import { default as Modal, StyleModalProps } from "../BlankModal";
import { Button, Flex, Form, ReduxWrapper } from "../../../Common";

export type LargePromptProps = {
    /** Label of the input */
    label?: string;
    /** Title of the modal */
    title?: string;
    /** Is a value required for validation ? */
    required?: boolean;
    /** The default value */
    defaultVal?: string;
    /** The text to display in the Confirm button */
    confirmText?: string;
    /** Extra params to customize the modal */
    style?: StyleModalProps;
    /** Callback for closing the modal */
    onQuit?: () => void;
    /** Callback after validation of the prompt */
    onValidate?: (str: string) => void;
}

const LargePrompt: React.FC<LargePromptProps> = ({ onValidate, ...props }) => {
    const [error, setError] = useState<Form.InputProps["error"]>();
    const [value, setValue] = React.useState(TB.getString(props.defaultVal));

    //#region Confirm & errors
    React.useEffect(() => setError(null), [value]);

    const validate = React.useCallback((value: string): typeof error => {
        if (props.required && !TB.validString(value)) return { code: TC.GLOBAL_REQUIRED_FIELD };
    }, [props.required]);

    const confirm = React.useCallback(() => {
        let error = validate(value);
        if (error) setError(error);
        else onValidate?.(value);
    }, [validate, onValidate, value]);
    //#endregion

    //#region Footer
    const footer = React.useMemo(() => <Flex justifyContent="end">
        <Button onClick={confirm} icon="save">
            {props.confirmText || TC.GLOBAL_CONFIRM}
        </Button>
    </Flex>, [confirm, props.confirmText]);
    //#endregion

    return <Modal
        {...props.style}
        footer={footer}
        onQuit={props.onQuit}
        size={props.style?.size || "md"}
        title={props.title || props.style?.title || TC.GLOBAL_RENAME}
    >
        <Form.TextField
            textArea
            autoExpand
            error={error}
            value={value}
            label={props.label}
            onChange={setValue}
        />
    </Modal>;
}

const WrapperLargePrompt: React.FC<LargePromptProps> = props => <ReduxWrapper>
    <LargePrompt {...props} />
</ReduxWrapper>;

export default WrapperLargePrompt;