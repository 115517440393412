import React from "react";
import { TB } from "../../../Constants";
import { InputProps, ComponentWrapper } from "../Input";

export type HTMLElementProps = InputProps & {
    tag?: string;
    content?: string;
    className?: string;
    attrs?: { attr: string, value: string }[];
};

const HTMLElement: React.FC<HTMLElementProps> = ({ tag, attrs, content, className, ...props }) => {
    const container = React.useRef<HTMLDivElement>(null);

    const node = React.useMemo(() => {
        if (!TB.validString(tag)) return null;
        let renamedTag = TB.extraNormalizeString(tag).toLowerCase().replace("_", "");
        if (renamedTag.length === 0) return null;

        let node = document.createElement(renamedTag);
        node.innerHTML = TB.getString(content);

        let attributes = TB.getArray(attrs)
            .concat({ attr: "class", value: TB.getString(className) })
            .filter(a => TB.validString(a?.attr) && TB.validString(a.value));

        for (let a of attributes) node.setAttribute(a.attr, a.value);
        return node;
    }, [attrs, className, content, tag]);


    React.useEffect(() => {
        let parent = container.current, child = node;
        if (child && parent) parent.appendChild(child);
        return () => { parent && child && parent.removeChild(child) };
    }, [node]);

    return <ComponentWrapper {...props} children={<div ref={container}/>}/>;
}

export default HTMLElement;