import React from "react";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as S from "../../../services";
import { T, TC } from "../../../Constants";

//#region Types
export type CoverageProps = {
    /** The list of datasets to take into the coverage */
    datasets: string[];
    /** The list of emplacements to use for the surface calculation */
    emplacements: string[];
    /** The epra_code used */
    epra_code: keyof T.Mission["epra"]["categories"];
} & (
        {
            type: "LfL";
            /** The periods to consider as an array */
            periods: Record<"from" | "to" | "name", string>[];
        }
        | {
            type: "period";
            /** The periods to consider as a single object */
            periods: Record<"from" | "to", string>;
        }
    );


type CoverageData = ReturnType<T.API.Utils.Missions.GetCoverage> & ReturnType<T.API.Utils.Missions.GetCoverageLfL>;
//#endregion

const Coverage: React.FC<CoverageProps> = props => {
    const lg = H.useLanguage();
    const [coverage, set_coverage, status] = H.useAsyncState<CoverageData>({ covered_area: NaN, value_2: NaN, total_area: NaN, value: NaN, percentage: NaN });

    React.useEffect(() => {
        if (!Array.isArray(props.datasets) || props.datasets.length === 0) set_coverage({ covered_area: NaN, value_2: NaN, total_area: NaN, value: NaN, percentage: NaN }, "done");
        else if (props.type === "LfL") {
            let periods = props.periods.map(p => [p.from || "", p.to || ""].join());
            S.getCoverageLfL({ datasets: props.datasets.join(), emplacements: props.emplacements.join(), periods, epra_code: props.epra_code })
                .then(({ data }) => set_coverage(data, "done"))
                .catch(e => set_coverage({ covered_area: NaN, total_area: NaN, value: NaN, percentage: NaN, value_2: NaN }, "error"))
        }
        else {
            let period = [props.periods?.from || "", props.periods?.to || ""].join();
            S.getCoverage({ datasets: props.datasets.join(), emplacements: props.emplacements.join(), period, epra_code: props.epra_code })
                .then(({ data }) => set_coverage({ value_2: NaN, ...data }, "done"))
                .catch(e => set_coverage({ covered_area: NaN, total_area: NaN, value: NaN, percentage: NaN, value_2: NaN }, "error"))
        }
    }, [set_coverage, props.emplacements, props.datasets, props.epra_code, props.periods, props.type]);

    const texts = React.useMemo(() => {
        if (props.type === "period") return {
            value: `${lg.getStaticText(TC.EPRA_VALUE_LABEL)} : ${coverage.value ?? "NaN"}`,
            coverage: `${lg.getStaticText(TC.EPRA_COVERAGE_LABEL)} : ${coverage.percentage ?? "NaN"}% ( ${coverage.covered_area} m² / ${coverage.total_area} m² )`,
        }
        else return {
            value: `${lg.getStaticText(TC.EPRA_VALUE_LABEL)} (${props.periods[0].name}) : ${coverage.value ?? "NaN"}`,
            value_2: `${lg.getStaticText(TC.EPRA_VALUE_LABEL)} (${props.periods[1].name}) : ${coverage.value_2 ?? "NaN"}`,
            coverage: `${lg.getStaticText(TC.EPRA_COVERAGE_LABEL)} : ${coverage.percentage ?? "NaN"}% ( ${coverage.covered_area} m² / ${coverage.total_area} m² )`,
        }
    }, [lg, coverage, props.periods, props.type]);

    return <C.Spinner status={status}>
        <div className="text-start">
            <div children={texts.value} />
            {texts.value_2 && <div children={texts.value_2} />}
            <div children={texts.coverage} />
        </div>
    </C.Spinner>
}

export default Coverage;