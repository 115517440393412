import _ from "lodash";
import moment from "moment";
import { Table } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useLanguage } from "../../hooks";
import { ErrorBanner } from "../../Common";
import AppContext from "../../Context/Context";
import { T, TC, FP, TB } from "../../Constants";
import { ActionPlanTable } from "../Gestion/Tables";
import { renderBlankModal, renderFormModal } from "../Modal";
import { ActionPlan, ErrorEval, ErrorProps } from "./ActionPlan/ActionPlanTypes";
import React, { FC, useMemo, useCallback, useContext, useState, useEffect } from "react";

//#region Types
type UpdateObj = {
    updateLocs?: (locs: T.LocationType) => void;
    updateEquips?: (equips: T.EquipmentType) => void;
    updateActions?: (actions: T.RenovationActionType) => void;
}

type ActionPlanResultsProps = {
    yearLaps: number;
    plan: ActionPlan;
    updaters?: UpdateObj;
};

type ErrorInstance = {
    action: string;
    prop: ErrorProps;
    equipment: string;
    wrongFormula: boolean;
    missingTerms: string[];
    fetch?: "wait" | "fail";
}

type CorrectFormula = {
    action: string;
    prop: ErrorProps;
}

type CorrectedSub = {
    props: string[];
    equipment: string;
}
//#endregion

//#region Constants
const errorProps: ErrorProps[] = ["cost", "lifetime", "emergency", "emissions", "energySavings", "energyProduction"];

const TEXT_CODES = [
    TC.G_ACTION, TC.GLOBAL_LABEL_EQUIPMENT, TC.GLOBAL_LABEL_SITE, TC.GP_BUILD_TAB_COST, TC.APS_WRONG_FORMULA, TC.APS_PROP_ERROR, TC.APS_MISSING_PROP,
    TC.APS_SHOW_ERROR_BANNER, TC.APS_ROI, TC.APS_IMPACT, TC.TIMELIFE, TC.APS_REPLACE_EMERGENCY, TC.APS_EMISSIONS, TC.APS_ENERGY_PRODUCTION,
    TC.APS_ENERGY_SAVING, FP.SITE_FORM, FP.BUILDING_FORM, FP.EMPLACEMENT_FORM, TC.APS_NOT_EXISTS_TITLE, TC.APS_NOT_EXISTS, TC.APS_EQUIP_YEAR_LEFT,
    TC.APS_BAIL_YEAR_LEFT, TC.GLOBAL_LABEL_BUILD, TC.APS_ENERGY_GAINED, TC.APS_ENERGY_PRODUCED, TC.APS_METEO_FETCH_ERROR, TC.APS_METEO_FETCH_WAIT,
    TC.APS_METEO_FETCH, TC.GLOBAL_CLICK_HERE, TC.TAB_ADV_MODE, TC.APS_SHOW_VIEW_TABLE
];
//#endregion

const ActionPlanResults: FC<ActionPlanResultsProps> = ({ plan, updaters, yearLaps, ...props }) => {
    const [showTable, setShowTable] = useState(false);
    const [hideFailed, setHideFailed] = useState(false);
    const { config: { isDark } } = useContext(AppContext);
    const { getStaticElem, getStaticText } = useLanguage(TEXT_CODES);
    const [correctedSubs, setCorrectedSubs] = useState<CorrectedSub[]>([]);
    const [correctedFormula, setCorrectedFormula] = useState<CorrectFormula[]>([]);
    const { failed, locations, actionPlan, equipments, actions } = useMemo(() => plan, [plan]);

    //#region Props
    const { updateActions, updateEquips, updateLocs } = useMemo(() => TB.validObject(updaters) ? updaters : {}, [updaters]);
    //#endregion

    //#region Formatter
    const findActionName = useCallback((id: string) => _.find(actions, a => a._id === id)?.data?.name, [actions]);
    const findEquipName = useCallback((id: string) => _.find(equipments, e => e._id === id)?.data?.name, [equipments]);

    const findSiteName = useCallback((id: string) => {
        let loc = _.find(locations, l => l.id === id);
        if (loc && Array.isArray(loc.site)) return loc.site.map(s => s.data.name).join();
        return "";
    }, [locations]);

    const findBuildName = useCallback((id: string) => {
        let loc = _.find(locations, l => l.id === id);
        if (loc && Array.isArray(loc.building)) return loc.building.map(s => s.data.name).join();
        return "";
    }, [locations]);

    const getPositionOverall = useCallback((year: number, index: number) => {
        let length = 1; //Start a one, because 0 index array
        for (let i = 0; i < year; i++) {
            if (Array.isArray(actionPlan[i])) length += actionPlan[i].length;
        }
        return length + index;
    }, [actionPlan]);

    const getYearSum = useCallback((year: number, prop: string) => {
        if (!Array.isArray(actionPlan[year])) return 0;
        return _.sumBy(actionPlan[year], prop);
    }, [actionPlan]);

    const getPropName = useCallback((prop: ErrorProps) => {
        if (prop === "cost") return TC.GP_BUILD_TAB_COST;
        if (prop === "emergency") return TC.APS_REPLACE_EMERGENCY;
        if (prop === "emissions") return TC.APS_EMISSIONS;
        if (prop === "energyProduction") return TC.APS_ENERGY_PRODUCTION;
        if (prop === "energySavings") return TC.APS_ENERGY_SAVING;
        return TC.TIMELIFE;
    }, []);
    //#endregion

    //#region Data Format
    const errorFormat = useMemo(() => {
        let errorInstance: ErrorInstance[] = [];
        for (let f of failed) {
            let genErrorVal = { action: f.action, equipment: f.equipment };
            for (let prop of errorProps) {
                let error: (ErrorEval | undefined) = f[prop];
                if (error) {
                    if (error.invalidFormula) errorInstance.push({ fetch: error.fetch, wrongFormula: true, missingTerms: [], prop, ...genErrorVal });
                    else if (Array.isArray(error.missingTerms)) errorInstance.push({ fetch: error.fetch, wrongFormula: false, prop, missingTerms: error.missingTerms, ...genErrorVal });
                }
            }
        }
        return errorInstance;
    }, [failed]);

    /* @ts-ignore */
    const nonCorrectFailed = useMemo<ErrorInstance[]>(() => {
        let mapped = errorFormat.map(f => {
            if (f.wrongFormula) return !correctedFormula.some(cf => f.action === cf.action && f.prop === cf.prop) ? f : null;
            else {
                let corrected = correctedSubs.filter(cs => cs.equipment === f.equipment);
                if (corrected.length > 0) {
                    let allCorrectedTerms = _.flatten(corrected.map(c => c.props));
                    /* @ts-ignore */
                    let nonCorrectedTerms = f.missingTerms.filter(p => !allCorrectedTerms.includes(p));
                    return nonCorrectedTerms.length === 0 ? null : { ...f, missingTerms: nonCorrectedTerms };
                }
                else return f;
            }
        });
        return mapped.filter(x => x !== null);
    }, [errorFormat, correctedFormula, correctedSubs]);
    //#endregion

    //#region Fill missing data
    useEffect(() => setCorrectedSubs([]), [failed]);
    useEffect(() => setCorrectedFormula([]), [failed]);

    const getTypeInfo = useCallback((missingTerm: string, equipId: string) => {
        let location = _.find(locations, l => l.id === equipId);
        const getId: (prop: string) => string | undefined = prop => location?.[prop]?.[0]?._id;

        if (missingTerm.includes("s.")) return { term: missingTerm, path: FP.SITE_FORM, check: TB.isSite, id: getId("site"), prop: "site" };
        if (missingTerm.includes("e.")) return { term: missingTerm, path: FP.EQUIPEMENT_FORM, check: TB.isValidEquipment, id: equipId };
        if (missingTerm.includes("b.")) return { term: missingTerm, path: FP.BUILDING_FORM, check: TB.isBuilding, id: getId("building"), prop: "building" };
        if (missingTerm.includes("f.")) return { term: missingTerm, path: FP.EMPLACEMENT_FORM, check: TB.isValidEquipment, id: getId("emplacement"), prop: "emplacement" };
        return { term: missingTerm, path: FP.EMPLACEMENT_FORM, check: TB.isEmplacement, id: getId("local"), prop: "local" };
    }, [locations]);

    const correctFormula = useCallback((error: ErrorInstance) => {
        renderFormModal({ submissionId: error.action, path: FP.ACTION_RENOVATION_FORM }).then(action => {
            if (TB.isRenovationAction(action)) {
                updateActions?.(action);
                setCorrectedFormula(p => p.concat({ action: error.action, prop: error.prop }));
            }
        })
    }, [updateActions]);

    const correctSubmission = useCallback(async (error: ErrorInstance) => {
        let typeInfosUnGrouped = error.missingTerms.map(term => getTypeInfo(term, error.equipment));

        let typeInfos = _.flatten(Object.entries(_.groupBy(typeInfosUnGrouped, "id")).map(([id, groupedArray]) => {
            if (!TB.mongoIdValidator(id)) return _.uniqBy(groupedArray, 'path');
            let terms = groupedArray.map(g => g.term).filter(TB.validString);
            return { ...groupedArray[0], terms };
        }));

        let equipUpdates: T.EquipmentType | null = null;
        let correctionsTerms: string[] = [];
        let locUpdates: ({ prop: "site", sub: T.SiteType } | { prop: "building", sub: T.BuildingType } | { prop: "emplacement" | "local", sub: T.EmplacementType })[] = [];

        /* @ts-ignore */
        for (let { path, prop, check, id, terms } of typeInfos) {
            if (TB.mongoIdValidator(id)) {
                let sub = await renderFormModal({
                    path: path,
                    submissionId: id,
                    title: `${findSiteName(error.equipment)} / ${findBuildName(error.equipment)} : ${terms.join()}`
                });

                if (check(sub)) {
                    if (path === FP.EQUIPEMENT_FORM) equipUpdates = sub;
                    /* @ts-ignore */
                    else locUpdates.push({ prop, sub });

                    correctionsTerms.push(...TB.getArray(terms).filter(TB.validString));
                }
            }
            else await renderBlankModal({
                title: `${findSiteName(error.equipment)} / ${findBuildName(error.equipment)} : ${getStaticText(TC.APS_NOT_EXISTS_TITLE)}`,
                children: <div>{getStaticText(TC.APS_NOT_EXISTS, getStaticText(path))}</div>,
            });
        }

        if (locUpdates.length > 0) {
            let loc = _.find(locations, l => l.id === error.equipment);
            if (loc) {
                let updatedLoc = _.cloneDeep(loc);
                locUpdates.forEach(({ prop, sub }) => updatedLoc = _.set(updatedLoc, prop + ".0", sub));
                updateLocs?.(updatedLoc);
            }
        }
        else if (equipUpdates !== null) updateEquips?.(equipUpdates);
        if (correctionsTerms.length > 0) setCorrectedSubs(p => p.concat({ equipment: error.equipment, props: correctionsTerms }));
    }, [getTypeInfo, getStaticText, updateEquips, updateLocs, findBuildName, findSiteName, locations]);

    const correctFailed = useCallback((error: ErrorInstance) => {
        if (error.wrongFormula) correctFormula(error);
        else correctSubmission(error);
    }, [correctFormula, correctSubmission]);
    //#endregion

    //#region Hide Some Columns
    const showFormulaCol = useMemo(() => nonCorrectFailed.some(f => f.wrongFormula), [nonCorrectFailed]);
    const showFetchCol = useMemo(() => nonCorrectFailed.some(f => f.fetch === "fail" || f.fetch === "wait"), [nonCorrectFailed]);
    const hideBailLeft = useMemo(() => actionPlan.every(yp => yp.every(a => typeof a.yearsLeft !== "number")), [actionPlan]);
    //#endregion

    //#region Meteo Fetch
    const waitFetchCell = useMemo(() => <i className="fa fa-hourglass-half" title={getStaticText(TC.APS_METEO_FETCH_WAIT)}></i>, [getStaticText]);
    const failedFetchCell = useMemo(() => <i className="fa fa-times-circle text-danger" title={getStaticText(TC.APS_METEO_FETCH_ERROR)}></i>, [getStaticText]);

    const getFetchCell = useCallback((fetch?: "wait" | "fail") => {
        if (!TB.validString(fetch)) return;
        return fetch === "fail" ? failedFetchCell : waitFetchCell;
    }, [failedFetchCell, waitFetchCell]);
    //#endregion

    //#region Failed Banner
    const failedBanner = useMemo(() => <ErrorBanner size="sm" type="warning" showIcon={false}>
        <div>
            <div className="p-2 pointer hover-opacity" onClick={() => setHideFailed(p => !p)}>
                <i className={`fa fa-chevron-${hideFailed ? "down" : "up"}`}></i>
            </div>
        </div>
        <div className="flex-grow-1">
            {!hideFailed && <Table className="text-center overflow-auto" borderless hover responsive size="sm" style={{ maxHeight: "500px" }}>
                <thead>
                    <tr>
                        <th>{getStaticElem(TC.G_ACTION)}</th>
                        <th>{getStaticElem(TC.GLOBAL_LABEL_SITE)}</th>
                        <th>{getStaticElem(TC.GLOBAL_LABEL_BUILD)}</th>
                        <th>{getStaticElem(TC.GLOBAL_LABEL_EQUIPMENT)}</th>
                        {showFormulaCol && <th>{getStaticElem(TC.APS_WRONG_FORMULA)}</th>}
                        <th>{getStaticElem(TC.APS_PROP_ERROR)}</th>
                        <th>{getStaticElem(TC.APS_MISSING_PROP)}</th>
                        {showFetchCol && <th>{getStaticElem(TC.APS_METEO_FETCH)}</th>}
                    </tr>
                </thead>
                <tbody>
                    {nonCorrectFailed.map((f, i) => <tr key={i} className="pointer" onClick={() => correctFailed(f)}>
                        <td>{findActionName(f.action)}</td>
                        <td>{findSiteName(f.equipment)}</td>
                        <td>{findBuildName(f.equipment)}</td>
                        <td>{findEquipName(f.equipment)}</td>
                        {showFormulaCol && <td><i className={`fa fa-${f.wrongFormula ? "check text-success" : "times text-danger"}`}></i></td>}
                        <td>{getStaticElem(getPropName(f.prop))}</td>
                        <td>{f.missingTerms.join()}</td>
                        {showFetchCol && <td>{getFetchCell(f.fetch)}</td>}
                    </tr>)}
                </tbody>
            </Table>}
            {hideFailed && <div className="ms-2 fs-85">{getStaticElem(TC.APS_SHOW_ERROR_BANNER)}</div>}
        </div>
    </ErrorBanner>, [nonCorrectFailed, showFormulaCol, showFetchCol, hideFailed, findActionName, getFetchCell, findSiteName, findBuildName, getPropName, getStaticElem, findEquipName, correctFailed]);
    //#endregion

    return <>
        {nonCorrectFailed.length > 0 && failedBanner}

        <ErrorBanner type="info">
            <span>{getStaticText(showTable ? TC.APS_SHOW_VIEW_TABLE : TC.TAB_ADV_MODE)} :</span>
            <Button onClick={() => setShowTable(p => !p)} className='border-0 p-0 ms-2' variant="link">{getStaticText(TC.GLOBAL_CLICK_HERE)}</Button>
        </ErrorBanner>

        <div className="my-3" style={{ height: showTable ? "90vh" : undefined }}>
            {showTable && <ActionPlanTable plan={plan} yearLaps={yearLaps} />}
            {!showTable && <Table className="text-center overflow-x-auto" hover bordered responsive variant={isDark ? "dark" : undefined} >
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{getStaticElem(TC.GLOBAL_LABEL_SITE)}</th>
                        <th>{getStaticElem(TC.GLOBAL_LABEL_BUILD)}</th>
                        <th>{getStaticElem(TC.GLOBAL_LABEL_EQUIPMENT)}</th>
                        <th>{getStaticElem(TC.G_ACTION)}</th>
                        {!hideBailLeft && <th>{getStaticElem(TC.APS_BAIL_YEAR_LEFT)}</th>}
                        <th>{getStaticElem(TC.APS_EQUIP_YEAR_LEFT)}</th>
                        <th>{getStaticElem(TC.GP_BUILD_TAB_COST)}</th>
                        <th>{getStaticElem(TC.APS_ROI)}</th>
                        <th>{getStaticElem(TC.APS_ENERGY_GAINED)}</th>
                        <th>{getStaticElem(TC.APS_ENERGY_PRODUCED)}</th>
                        <th>{getStaticElem(TC.APS_IMPACT)}</th>
                    </tr>
                </thead>
                <tbody>
                    {actionPlan.map((yearlyPlan, y) => <React.Fragment key={y}>
                        {yearlyPlan.length > 0 && <>
                            <tr>
                                <td colSpan={2} className="text-center fw-bold">{moment().year() + y + yearLaps}</td>
                                <td colSpan={!hideBailLeft ? 5 : 4}></td>
                                <td className="fst-italic fw-bold">{TB.moneyFormat(getYearSum(y, "cost"))}</td>
                                <td></td>
                                <td className="fst-italic fw-bold">{TB.numberFormat(getYearSum(y, "energySavings") / 1000, "MWh")}</td>
                                <td className="fst-italic fw-bold">{TB.numberFormat(getYearSum(y, "energyProduction") / 1000, "MWh")}</td>
                                <td></td>
                            </tr>
                            {yearlyPlan.map((a, i) => <tr key={i}>
                                <td>{getPositionOverall(y, i)}</td>
                                <td>{findSiteName(a.equipment)}</td>
                                <td>{findBuildName(a.equipment)}</td>
                                <td>{findEquipName(a.equipment)}</td>
                                <td>{findActionName(a.action)}</td>
                                {!hideBailLeft && <td>{a.yearsLeft === null ? "" : a.yearsLeft}</td>}
                                <td>{a.lifeLeft === null ? "" : a.lifeLeft}</td>
                                <td>{TB.moneyFormat(a.cost)}</td>
                                <td>{TB.getNumber(a.roi).toFixed(2)} %</td>
                                <td>{TB.numberFormat(a.energySavings / 1000, "MWh")}</td>
                                <td>{TB.numberFormat(a.energyProduction / 1000, "MWh")}</td>
                                <td>{TB.numberFormat(a.coImpact, "KgCO2/€")}</td>
                            </tr>)}
                        </>}
                    </React.Fragment>)}
                </tbody>
            </Table>}
        </div>
    </>;
}

export default ActionPlanResults;