import { Flex } from '../Layout';
import RadioItem from './RadioItem';
import { SoftBadge } from '../Item';
import { AppContext } from '../../Context';
import { useContext, useMemo } from 'react';
import { RESOURCE as R } from '../../Constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Offcanvas, Button, ButtonGroup, Form } from 'react-bootstrap';

const SettingsPanel = () => {
    const { config: { isFluid, isDark, navbarPosition, navbarStyle, showSettingPanel }, setConfig, configDispatch } = useContext(AppContext);

    const [defaultModeImg, darkModeImg] = useMemo(() => [R.SP_FALCON_DEFAULT, R.SP_FALCON_DARK].map(code => R.RESOURCE_URL(code)), []);
    const [arrowsH, paragraph] = useMemo(() => [R.SP_ARROWS_H, R.SP_LEFT_ARROW, R.SP_PARAGRAPH].map(code => R.RESOURCE_URL(code)), []);
    const [cardImg, transparentImg, invertedImg, vibrantImg] = useMemo(() => [R.SP_CARD, R.SP_DEFAULT, R.SP_INVERTED, R.SP_VIBRANT].map(code => R.RESOURCE_URL(code)), []);

    const navbars = useMemo(() => [
        { name: 'transparent', image: transparentImg },
        { name: 'inverted', image: invertedImg },
        { name: 'card', image: cardImg },
        { name: 'vibrant', image: vibrantImg }
    ], [cardImg, invertedImg, transparentImg, vibrantImg]);

    return <Offcanvas
        show={showSettingPanel}
        onHide={() => setConfig('showSettingPanel', false)}
        placement="end"
        style={{ maxWidth: '22rem' }}
        className="border-0"
    >
        <Offcanvas.Header closeButton closeVariant="white" className="bg-shape settings-panel-header" >
            <Offcanvas.Title as="div" className="py-1 z-index-1 light">
                <div className="d-flex justify-content-between align-items-center mb-1">
                    <h5 className="text-white">
                        <FontAwesomeIcon icon="palette" className="me-2 fs-0" />
                        Settings
                    </h5>
                    <Button variant="primary" size="sm" className="rounded-pill mt-0 mb-0" style={{ fontSize: '12px' }} onClick={() => configDispatch({ type: 'RESET' })} >
                        <FontAwesomeIcon icon="redo-alt" style={{ fontSize: '10px' }} className="me-1" />
                        Reset
                    </Button>
                </div>
                <p className="mb-0 fs--1 text-white opacity-75">
                    Set your own customized style
                </p>
            </Offcanvas.Title>
        </Offcanvas.Header>

        {/* <ScrollBarCustom> */}
        <Offcanvas.Body className="scrollbar">
            <h5 className="fs-0">Color Scheme</h5>
            <p className="fs--1">Choose the perfect color mode for your app.</p>

            <ButtonGroup className="btn-group-navbar-style">
                <RadioItem
                    name="theme-mode"
                    label="light"
                    active={!isDark}
                    onChange={({ target }) => setConfig('isDark', !target.checked)}
                    image={defaultModeImg}
                />
                <RadioItem
                    name="theme-mode"
                    label="dark"
                    active={isDark}
                    onChange={({ target }) => setConfig('isDark', target.checked)}
                    image={darkModeImg}
                />
            </ButtonGroup>

            <hr />

            <Flex justifyContent="between">
                <img src={arrowsH} alt="" width={20} className="me-2 h-100" />
                <div className="flex-1">
                    <h5 className="fs-0">Fluid Layout</h5>
                    <p className="fs--1 mb-0">Toggle container layout system</p>
                </div>
                <Form.Check
                    type="switch"
                    id="fluid-mode-switch"
                    checked={isFluid}
                    onChange={({ target }) => setConfig('isFluid', target.checked)}
                />
            </Flex>
            <hr />

            <Flex>
                <img src={paragraph} alt="" width={20} className="me-2 h-100" />
                <div>
                    <Flex alignItems="center" className="fs-0">
                        Navigation Position
                        <SoftBadge bg="success" pill className="fs--2 ms-2">
                            New
                        </SoftBadge>
                    </Flex>
                    <p className="fs--1 mb-2">
                        Select a suitable navigation system for your web application
                    </p>
                    <Form.Check
                        type="radio"
                        id="verticalNav-radio"
                        label="Vertical"
                        name="NavBarPositionRadioButton"
                        checked={navbarPosition === 'vertical'}
                        onChange={() => setConfig('navbarPosition', 'vertical')}
                        inline
                    />
                    <Form.Check
                        type="radio"
                        id="topNav-radio"
                        label="Top"
                        name="NavBarPositionRadioButton"
                        checked={navbarPosition === 'top'}
                        onChange={() => setConfig('navbarPosition', 'top')}
                        inline
                    />
                    <Form.Check
                        type="radio"
                        id="combo-radio"
                        label="Combo"
                        name="NavBarPositionRadioButton"
                        checked={navbarPosition === 'combo'}
                        onChange={() => setConfig('navbarPosition', 'combo')}
                        inline
                    />
                </div>
            </Flex>

            <hr />
            <h5 className="fs-0 d-flex align-items-center">
                Vertical Navbar Style{' '}
            </h5>
            <p className="fs--1">Switch between styles for your vertical navbar</p>
            <ButtonGroup className="btn-group-navbar-style">
                {navbars.slice(0, 2).map(item => (
                    <RadioItem
                        key={item.name}
                        name="navbar-style"
                        label={item.name}
                        active={navbarStyle === item.name}
                        onChange={() => setConfig('navbarStyle', item.name)}
                        image={item.image}
                    />
                ))}
            </ButtonGroup>
            <ButtonGroup className="btn-group-navbar-style">
                {navbars.slice(2, 4).map(item => (
                    <RadioItem
                        key={item.name}
                        name="navbar-style"
                        label={item.name}
                        active={navbarStyle === item.name}
                        onChange={() => setConfig('navbarStyle', item.name)}
                        image={item.image}
                    />
                ))}
            </ButtonGroup>
        </Offcanvas.Body>
    </Offcanvas>
};

export default SettingsPanel;
