import * as C from "./Cells";
import { ExtendedColumnProps } from "../TableTypes";

export const ColumnTypesDefault: Record<string, ExtendedColumnProps> = {
    [C.Free.type]: { cellRenderer: C.Free.Cell },
    [C.QRCode.type]: { cellEditor: C.QRCode.EditCell },
    [C.LinkedElem.type]: { cellRenderer: C.LinkedElem.Cell },
    [C.Select.type]: { cellEditorPopup: true, cellEditor: C.Select.EditCell },
    [C.Color.type]: { filter: C.Color.Filter, cellRenderer: C.Color.Cell, cellEditor: C.Color.EditCell },
    [C.Frequency.type]: { enableRowGroup: false, comparator: C.Frequency.sort, cellRenderer: C.Frequency.Cell },
    [C.File.type]: { filter: false, sortable: false, editable: false, enableRowGroup: false, cellRenderer: C.File.Cell },
    [C.Pictures.type]: { filter: false, enableRowGroup: false, comparator: C.Pictures.sort, cellRenderer: C.Pictures.Cell },
    [C.Button.type]: { filter: false, sortable: false, editable: false, enableRowGroup: false, cellRenderer: C.Button.Cell },
    [C.Date.type]: { filter: C.Date.Filter, comparator: C.Date.sort, cellRenderer: C.Date.Cell, cellEditor: C.Date.EditCell },
    [C.SelectButton.type]: { editable: false, enableRowGroup: false, comparator: C.SelectButton.sort, cellRenderer: C.SelectButton.Cell },
    [C.CheckBox.type]: { enableRowGroup: false, comparator: C.CheckBox.sort, cellRenderer: C.CheckBox.Cell, cellEditor: C.CheckBox.EditCell },
    [C.RegValidity.type]: { enableRowGroup: false, filter: C.RegValidity.Filter, comparator: C.RegValidity.sort, cellRenderer: C.RegValidity.Cell },
    [C.Number.type]: { enableValue: true, filter: C.Number.Filter, comparator: C.Number.sort, cellRenderer: C.Number.Cell, cellEditor: C.Number.EditCell },
    [C.RoleCheck.type]: { enableRowGroup: false, comparator: C.RoleCheck.sort, cellRenderer: C.RoleCheck.Cell, cellEditor: C.RoleCheck.EditCell, filter: C.RoleCheck.Filter },
    [C.RemarquesButton.type]: { editable: false, enableRowGroup: false, filter: C.RemarquesButton.Filter, comparator: C.RemarquesButton.sort, cellRenderer: C.RemarquesButton.Cell },
}

/** Shortcut to use the cells types */
export const CellsTypes = {
    TYPE_DATE: C.Date.type,
    TYPE_FILE: C.File.type,
    TYPE_COLOR: C.Color.type,
    TYPE_SELECT: C.Select.type,
    TYPE_NUMBER: C.Number.type,
    TYPE_QR_CODE: C.QRCode.type,
    TYPE_FREE_RENDER: C.Free.type,
    TYPE_PICTURES: C.Pictures.type,
    TYPE_CHECKBOX: C.CheckBox.type,
    TYPE_FREQUENCY: C.Frequency.type,
    TYPE_ROLE_CHECK: C.RoleCheck.type,
    TYPE_ACTION_BUTTON: C.Button.type,
    TYPE_LINKED_ELEM: C.LinkedElem.type,
    TYPE_SELECT_BUTTON: C.SelectButton.type,
    TYPE_REM_BUTTON: C.RemarquesButton.type,
    TYPE_REG_VALIDITY_DATE: C.RegValidity.type,
}