import { T } from "../Constants";
import React from "react";
import useRoots from "./useRoots";
import useAsyncState from "./useAsyncState";
import { contextDestructure } from "../services";

type ContextInfos = ReturnType<ContextDestructure>;
type ContextDestructure = T.API.Utils.Context.ContextDestructure;
type UseContextInfos = (context?: Parameters<ContextDestructure>[0]) => [ContextInfos, T.AsyncStates];

const useContextInfos: UseContextInfos = context => {
    const [roots] = useRoots();
    const [infos, setInfos, infosStatus] = useAsyncState({ ids: [], name: "", elemPath: {} });

    const selected_context = React.useMemo(() => context || roots, [context, roots]);

    React.useEffect(() => {
        let isSubscribed = true;
        contextDestructure(selected_context)
            .then(({ data }) => isSubscribed && setInfos(data, "done"))
            .catch(() => isSubscribed && setInfos({ ids: [], name: "", elemPath: {} }, "error"));
        return () => { isSubscribed = false };
    }, [selected_context, setInfos]);

    return [infos || { ids: [], name: "", elemPath: {} }, infosStatus];
}

export default useContextInfos;