import { AllowArray } from "../../Misc";
import { ContextParams } from "../../General";
import { BuildingData, Mission } from "../../Data";
import { ClientData, SiteData, Option } from "../../Types";

/** Max number of 'recent' items */
export const MAX_RECENT_NAV_ITEM = 30;

/** Get the portfolios and recent selections used in the navigator */
export type NavigatorPortfoliosAndRecent = () => {
    /** The user's portfolios */
    portfolios: {
        /** A link to the custom picture of the portfolio */
        picture?: string;
        /** The name of the portfolio */
        name: string;
        /** The id of the portfolio */
        id: string;
    }[];
    /** The user's recent selection */
    recent: (
        { type: "portfolio", picture?: string, id: string, name: string }
        | { type: "sub", path: string, id: string, name: string }
    )[];
    /** The user's recent missions */
    recent_missions: Option<Record<"asset" | "path" | "created", string> & Record<"final", boolean> & Record<"mission_type", Mission["type"]>>[];
};

/** Get the sub-context and the missions used in the navigator */
export type NavigatorSubContextMissions = (context: ContextParams) => {
    /** The definition of the current context */
    current?: ReturnType<NavigatorPortfoliosAndRecent>["recent"][number];
    /** The items that are just below the current context */
    sub_context: {
        /** The id of this sub_context element */
        id: string;
        /** The type of submission of this item */
        path: string;
        /** The name of the item */
        name: string;
        /** The context under this item */
        sub_context: ReturnType<NavigatorSubContextMissions>["sub_context"];
    }[];
    /** The missions that are available for the current context */
    missions: ReturnType<NavigatorPortfoliosAndRecent>["recent_missions"];
};
/** A new context has been selected, update the recent list */
export type UpdateRecentList = (item: string) => void;
/** Remove a context from the list of recent items */
export type RemoveRecentList = (item: string) => void;
/** A new mission has been selected, update the recent mission list */
export type UpdateRecentMissionList = (mission: string) => void;
/** Get the missions a user has access to, or the missions specified (if user has access to them) */
export type UsersMissions = (ids: AllowArray<string>) => ReturnType<NavigatorPortfoliosAndRecent>["recent_missions"];
/** Get the user's missions on a specified asset _id */
export type UsersAssetMissions = (asset: string) => Mission[];

/** Get the table data for a user's available clients */
export type NavTableClients = (light?: boolean) => (ClientData & {
    /** The id of the client */
    id: string;
    /** Link to the client's picture */
    picture_link?: string;
    /** The full name of the client's country */
    countries?: string[];
    /** The ids of the building's regions */
    regions_ids?: string[];
    /** The full name of the client's region */
    regions?: string[];
    /** The list of buildings affectations label code */
    affectations: string[];
    /** The list of emplacements affectations label code */
    sub_affectations: string[];
    /** The list of enseignes related to this client */
    enseignes: string[];
    /** The list of buildings names under this client */
    buildings_names: string[];
    /** The list of sites names under this client */
    sites_names: string[];
    /** The sum of the buildings surfaces BHS or the first surface found */
    surfaces: number;
})[];

/** Get the table data for a user's available sites */
export type NavTableSites = (light?: boolean) => (SiteData & {
    /** The list of tags names that can be found in the notes of elements under the site */
    tags_descendants: string[];
    /** The list of tags names given to the site */
    tags_sites: string[];
    /** The id of the site */
    id: string;
    /** Link to the site's picture */
    picture_link?: string;
    /** The full name of the site's country */
    full_country: string;
    /** The id of the site's region */
    region_id?: string;
    /** The full name of the site's region */
    full_region?: string;
    /** The list of buildings affectations label code */
    affectations: string[];
    /** The list of emplacements affectations label code */
    sub_affectations: string[];
    /** The list of buildings names under this site */
    buildings_names: string[];
    /** The list of client this site is under */
    clients_names: string[];
    /** The sum of the buildings surfaces BHS or the first surface found */
    surfaces: number;
})[];

/** Get the table data for a user's available buildings */
export type NavTableBuildings = (light?: boolean) => (BuildingData & {
    /** The list of tags names that can be found in the notes of elements under the building */
    tags_descendants: string[];
    /** The list of tags names given to the site */
    tags_buildings: string[];
    /** The id of the building */
    id: string;
    /** Link to the building's picture */
    picture_link?: string;
    /** The full name list of the building's countries */
    countries: string[];
    /** The ids of the building's regions */
    regions_ids?: string[];
    /** The full name list of the building's regions */
    regions?: string[];
    /** The list of sites this building is under */
    sites_names: string[];
    /** The list of client this building is under */
    clients_names: string[];
    /** The BHS surface or the first surface found */
    surface_gen: number;
    /** The list of parcel's name right over this building */
    parcels_names: string[];
    /** The label code for the affectation */
    affectation_label?: string;
    /** The list of emplacements affectations label code */
    sub_affectations: string[];
})[];