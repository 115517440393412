import React from 'react';
import classNames from 'classnames';
import * as BS from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { TB } from '../../../Constants';
import { topNavbarBreakpoint } from '../../../config';

type NavbarDropdownProps = {
  title: string;
  children?: React.ReactNode;
};

const breakpoints = { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200, xxl: 1540 };

const NavbarDropdown: React.FC<NavbarDropdownProps> = ({ title, children }) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const onMouseOver = React.useCallback(() => {
    let windowWidth = window.innerWidth;
    if (windowWidth >= breakpoints[topNavbarBreakpoint]) setDropdownOpen(true);
  }, []);

  const onMouseLeave = React.useCallback(() => {
    let windowWidth = window.innerWidth;
    if (windowWidth >= breakpoints[topNavbarBreakpoint]) setDropdownOpen(false);
  }, []);

  const cardClassNames = React.useMemo(() => classNames('shadow-none dark__bg-1000', {
    'navbar-card-app': title === 'app',
    'navbar-card-pages': title === 'pages',
    'navbar-card-components': title === 'modules'
  }), [title]);

  return <BS.Dropdown
    show={dropdownOpen}
    onMouseOver={onMouseOver}
    onMouseLeave={onMouseLeave}
    onToggle={() => setDropdownOpen(!dropdownOpen)}
  >
    <BS.Dropdown.Toggle as={Link} to="#!" className="nav-link fw-semi-bold">
      {TB.capitalizeFirstLetter(title)}
    </BS.Dropdown.Toggle>

    <BS.Dropdown.Menu className="dropdown-menu-card mt-0 dropdown-caret">
      <BS.Card className={cardClassNames}>
        <BS.Card.Body
          className={classNames('scrollbar max-h-dropdown', {
            'p-0 py-2': title === 'dashboard' || title === 'documentation'
          })}
        >
          {title !== 'dashboard' && title !== 'documentation' && <img src={""} alt="" className="img-dropdown" width={130} />}
          {children}
        </BS.Card.Body>
      </BS.Card>
    </BS.Dropdown.Menu>
  </BS.Dropdown>
};

export default NavbarDropdown;
