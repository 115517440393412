import React, { useMemo } from "react";
import { Spinner } from "../../../Common";
import { T, TC } from "../../../Constants";
import { useLanguage } from "../../../hooks";
import FullCalendar from "@fullcalendar/react";

import * as Cal from "@fullcalendar/react";
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import '@fullcalendar/daygrid/main.css';
import 'react-calendar-timeline/lib/Timeline.css';


type EventResizeArg = Omit<Cal.EventDropArg, "delta"> & {
    endDelta: Cal.EventDropArg["delta"];
    startDelta: Cal.EventDropArg["delta"];
}

type DateClickArg = Pick<Cal.EventDropArg, "jsEvent" | "view"> & {
    date: Date;
    allDay: boolean;
    dateStr: string;
    dayEl: HTMLElement;
}

type DropEventArg = Pick<Cal.EventDropArg, "event" | "relatedEvents" | "revert" | 'view'>;

export type PlannerViewProps = {
    loading?: boolean;
    onDropAction: (arg: DropEventArg) => void;
    onClickDate: (arg: DateClickArg) => void,
    onMoveEvent: (arg: Cal.EventDropArg) => void;
    onResizeEvent: (arg: EventResizeArg) => void;
    onClickEvent: (arg: Cal.EventClickArg) => void;
    events: ReturnType<T.API.Actions.GetPlanner>["events"];
}

const TEXT_CODES = [TC.NEXT, TC.YEAR, TC.PREVIOUS, TC.GLOBAL_TODAY, TC.DAY_DAY, TC.GLOBAL_WEEK, TC.GLOBAL_MONTH, TC.P_END_DATE]

const PlannerView: React.FC<PlannerViewProps> = props => {
    const lg = useLanguage(TEXT_CODES);

    const eventItems = useMemo(() => {
        let items = [];

        for (let event of props.events) {
            let i = 0;
            for (let cal of event.calendar) {
                items.push({ id: event.id, title: event.title, start: cal.start, end: cal.end, calIndex: i });
                i++;
            }
        }

        return items;
    }, [props.events]);

    return <div className={`w-100 p-2 ${props.loading ? "" : "bg-light"}`}>
        <Spinner loading={props.loading} size="md">
            <FullCalendar
                editable
                droppable
                height="100%"
                timeZone="local"
                events={eventItems}
                themeSystem="bootstrap"
                initialView="timeGridWeek"
                eventDrop={props.onMoveEvent}
                dateClick={props.onClickDate}
                eventClick={props.onClickEvent}
                eventReceive={props.onDropAction}
                eventResize={props.onResizeEvent}
                plugins={useMemo(() => [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin], [])}
                headerToolbar={useMemo(() => ({
                    start: 'prev,today,next',
                    center: 'title',
                    end: 'timeGridWeek,dayGridMonth,listYear'
                }), [])}
                views={useMemo(() => ({
                    dayGridMonth: { titleFormat: { month: "long", year: "numeric" } },
                    timeGridWeek: { titleFormat: { month: "numeric", year: "numeric", day: "2-digit" } },
                    timeGridDay: { titleFormat: { month: "numeric", year: "numeric", day: '2-digit' } },
                }), [])}
                buttonText={useMemo(() => ({
                    next: lg.getStaticText(TC.NEXT),
                    listYear: lg.getStaticText(TC.YEAR),
                    prev: lg.getStaticText(TC.PREVIOUS),
                    today: lg.getStaticText(TC.GLOBAL_TODAY),
                    timeGridDay: lg.getStaticText(TC.DAY_DAY),
                    timeGridWeek: lg.getStaticText(TC.GLOBAL_WEEK),
                    dayGridMonth: lg.getStaticText(TC.GLOBAL_MONTH),
                }), [lg])}
            />
        </Spinner>
    </div>;
}

export default PlannerView;