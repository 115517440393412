import React, { useMemo } from "react";
import { FP, T, TB, TC } from "../../../Constants";
import { Collapse, Toast } from "react-bootstrap";
import { Flex, IconButton } from "../../../Common";
import { useBoolean, useLanguage } from "../../../hooks";

export type ActionItemProps = {
    /** The background color */
    variant?: T.ColorTypes;
    /** A callback to delete this action */
    onRemove?: () => void;
    /** A callback to edit this action */
    onEdit?: () => void;
    /** Is the user allowed to remove this item */
    allowedEdit?: boolean;
    /** The data of the action */
    action: ReturnType<T.API.Actions.GetPlanner>["actions"][number];
}

const ActionItem: React.FC<ActionItemProps> = props => {
    const lg = useLanguage();
    const isExpanded = useBoolean(false);

    const textColor = useMemo(() => {
        let whiteText: T.ColorTypes[] = ["danger", "warning"];
        if (whiteText.includes(props.variant)) return "text-light";
        return "";
    }, [props.variant]);

    return <div className="mb-2">
        <Toast bg={props.variant} show onClose={props.onRemove}>
            <Toast.Header closeButton={props.allowedEdit && !!props.onRemove} className="justify-content-between">
                <strong>{props.action.title}</strong>
            </Toast.Header>
            <Toast.Body className={textColor}>
                <Flex justifyContent="between">
                    <div className="flex-grow-1 me-3">
                        <div>
                            {props.action.element?.name || "N/A"}
                        </div>
                        {props.action.element && <Collapse in={isExpanded.value}>
                            <div>
                                {props.action.element.local.length > 0 && <div>
                                    <span className="fw-bold me-2">{lg.getStaticText(TC.GLOBAL_LOCAL)} :</span>
                                    {props.action.element.local.map(b => b.name).join()}
                                </div>}
                                {props.action.element.emplacement.length > 0 && <div>
                                    <span className="fw-bold me-2">{lg.getStaticText(TC.GLOBAL_LABEL_EMPLACEMENT)} :</span>
                                    {props.action.element.emplacement.map(b => b.name).join()}
                                </div>}
                                {props.action.element.building.length > 0 && <div>
                                    <span className="fw-bold me-2">{lg.getStaticText(FP.BUILDING_FORM)} :</span>
                                    {props.action.element.building.map(b => b.name).join()}
                                </div>}
                                {props.action.element.site.length > 0 && <div>
                                    <span className="fw-bold me-2">{lg.getStaticText(FP.SITE_FORM)} :</span>
                                    {props.action.element.site.map(b => b.name).join()}
                                </div>}
                            </div>
                        </Collapse>}
                        {props.action.end_date && <div className="fs-75 fst-italic text-end">
                            {lg.getStaticText(TC.P_END_DATE)} :
                            <span className="ms-1">{TB.formatDate(props.action.end_date, "DD/MM/YY")}</span>
                        </div>}
                    </div>
                    <Flex direction="column" alignItems="stretch">
                        {props.allowedEdit && !!props.onEdit && <IconButton
                            className="mb-2"
                            icon="pencil-alt"
                            onClick={() => props.onEdit?.()}
                        />}

                        {props.action.element && <IconButton
                            onClick={isExpanded.toggle}
                            icon={"chevron-" + (isExpanded.value ? "up" : "down")}
                        />}
                    </Flex>
                </Flex>
            </Toast.Body>
        </Toast>
    </div>;
}

export default ActionItem;