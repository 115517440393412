import renderInContainer from "../getContainer";
import { ExcelMap, ExcelMapProps } from "../Components";
import { askExcelImport, ExcelModalImportFnProps } from "../ExcelImport";

type ExcelImportParams = Omit<ExcelModalImportFnProps, "required">;
type ExcelMapParams<A extends Record<string, any>> = Omit<ExcelMapProps<A>, "sheet" | "validate" | "popup">;
export type RenderExcelMap = <A extends Record<string, any>>(params: Partial<Record<"import", ExcelImportParams>> & Record<"map", ExcelMapParams<A>>) => Promise<Parameters<ExcelMapProps<A>["validate"]>[0]>;

const renderExcelMap: RenderExcelMap = params => new Promise(resolve => {
    // Ask the user to import the file
    askExcelImport({ ...params.import, required: true }).then(worksheet => {
        // Import cancelled
        if (!worksheet) resolve(null);
        else {
            const [render, dismount] = renderInContainer();
            const validate: ExcelMapProps<any>["validate"] = rows => dismount(() => resolve(rows));
            render(<ExcelMap {...params.map} popup sheet={worksheet} validate={validate} />);
        }
    });
});

export default renderExcelMap;