import renderInContainer from "../getContainer";
import { DataExport, DataExportProps } from "../Components";

export type AskDataExport = (params?: Omit<DataExportProps, "quit" | "validate">) => Promise<Parameters<DataExportProps["validate"]>[0]>;

const askDataExport: AskDataExport = params => new Promise(resolve => {
    const [render, dismount] = renderInContainer();
    if (render && dismount) {
        const quit: DataExportProps["quit"] = () => dismount(() => resolve(null));
        const validate: DataExportProps["validate"] = values => dismount(() => resolve(values));
        render(<DataExport {...params} quit={quit} validate={validate} />);
    }
    else resolve(null);
});

export default askDataExport;