import './CardHeader.scss';
import { useMemo } from "react";
import { TB } from "../../../Constants";

const CardHeader = ({ children, header, hasClosed, footer, hasEdit, headBtn, saveBtn, title, customClasses }) => {

    const editBtn = useMemo(() => typeof hasEdit !== "function" ? undefined : <div className="edit-icon" onClick={hasEdit}>
        <svg width="26px" height="26px" viewBox="0 0 23.5 23.5" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path d="M3,17.46 L3,20.5 C3,20.78 3.22,21 3.5,21 L6.54,21 C6.67,21 6.8,20.95 6.89,20.85 L17.81,9.94 L14.06,6.19 L3.15,17.1 C3.05,17.2 3,17.32 3,17.46 Z M20.71,7.04 C21.1,6.65 21.1,6.02 20.71,5.63 L18.37,3.29 C17.98,2.9 17.35,2.9 16.96,3.29 L15.13,5.12 L18.88,8.87 L20.71,7.04 Z"></path>
            </g>
        </svg>
    </div>, [hasEdit]);

    const wrapperClass = useMemo(() => TB.validString(customClasses) ? customClasses : "", [customClasses]);

    return <div className={"myCard " + wrapperClass} >
        <div className="card-header">
            {title && <span className="headerTitle">{title}</span>}
            {header}
            {headBtn}
            {saveBtn}
            {editBtn}
            {hasClosed && <div className="close-icon" onClick={hasClosed}>
                <svg version="1.1" viewBox="0 0 23.5 23.5" xmlns="http://www.w3.org/2000/svg">
                    <g transform="translate(2.5 2.5)" label="Layer 1">
                        <path d="m-2.5783e-4 0.0014681 17.436 18.214" strokeLinecap="round" strokeWidth="5" />
                        <path d="m-2.5783e-4 18.212 17.436 -18.214" strokeLinecap="round" strokeWidth="5" />
                        <title>Layer 1</title>
                    </g>
                </svg>
            </div>}
        </div>
        <div className="card-body flex-grow-1">
            {children}
        </div>
        {footer && <div className="card-footer">
            {footer}
        </div>}
    </div>
}

export default CardHeader;