import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as S from "../../../services";
import { Flex } from "../../../Common";
import ActionsView from "./Actions_view";
import { renderFormModal } from "../../Modal";
import { useNavigate } from "react-router-dom";
import React, { useCallback, useEffect } from "react";
import { FP, RIGHTS, T, TABS, TB } from "../../../Constants";
import { renderEquipmentModal } from "../../../PurposeModal";
import PlannerView, { PlannerViewProps } from "./Planner_view";

type Action = Planner["actions"][number];
type Planner = ReturnType<T.API.Actions.GetPlanner>;
const DEFAULT_PLANNER: Planner = { events: [], actions: [] };

const PlannerLogic: React.FC = () => {
    H.useCrumbs(TABS.PLANNING);
    const rights = H.useRights();
    const [roots] = H.useRoots();
    const navigate = useNavigate();
    const [setSize, size] = H.useElementSize();
    const [{ userId }] = H.useAuth({ tabName: TABS.PLANNING });
    const [resources, setResources, status] = H.useAsyncState<Planner>(DEFAULT_PLANNER);

    //#region Load Data
    // Load the planner data
    useEffect(() => {
        let isSubscribed = true;
        S.getPlanner(roots)
            .then(({ data }) => isSubscribed && setResources(data, "done"))
            .catch(() => setResources(DEFAULT_PLANNER, "error"));
        return () => { isSubscribed = false };
    }, [roots, setResources]);

    //#region Events Callbacks
    const onDropAction = useCallback<PlannerViewProps["onDropAction"]>(arg => {
        let action = resources.actions.filter(e => e.id === arg.event.id)[0];
        const canCreateOwn = rights.isRightAllowed(RIGHTS.ACTION.WRITE_EVENTS);

        if (action && canCreateOwn) {
            let event: Planner["events"][number] = {
                id: "",
                owner: userId,
                actions: [action],
                title: action.title,
                calendar: [{
                    start: arg.event.start.toISOString(),
                    end: new Date(arg.event.start.getTime() + 1000 * 60 * 60).toISOString(),
                }],
            }

            M.renderEventFormModal({ actions: resources.actions, event, navigate }).then(results => {
                // Only option is create a new event
                if (results && results.type === "add") {
                    let resultsActionIds = results.actions.map(a => a.id);
                    setResources(p => ({
                        events: p.events.concat(results.event),
                        actions: p.actions.filter(a => !resultsActionIds.includes(a.id)),
                    }));
                }
                arg.revert();
            });
        }
        else arg.revert();
    }, [userId, rights, resources.actions, navigate, setResources]);

    const onClickEvent = useCallback<PlannerViewProps["onClickEvent"]>(arg => {
        let event = resources.events.filter(e => e.id === arg.event.id)[0];

        const canEditEvent = event.owner === userId
            ? rights.isRightAllowed(RIGHTS.ACTION.WRITE_EVENTS)
            : rights.isRightAllowed(RIGHTS.ACTION.WRITE_OTHER_EVENTS);

        if (event && canEditEvent) M.renderEventFormModal({ event, navigate, actions: resources.actions }).then(results => {
            if (results) {
                let resultsActionIds = results.actions.map(a => a.id);
                // Remove Existing event
                if (results.type === "remove") setResources(p => ({
                    events: p.events.filter(e => e.id !== results.event.id),
                    actions: results.actions.length > 0 ? p.actions.concat(results.actions) : p.actions,
                }));
                // Only option is updated existing event
                else setResources(p => ({
                    events: p.events.map(e => e.id === event.id ? results.event : e),
                    actions: p.actions.filter(a => !resultsActionIds.includes(a.id)),
                }));
            }
        });
    }, [rights, userId, resources.events, resources.actions, setResources, navigate]);

    const onClickDate = useCallback<PlannerViewProps["onClickDate"]>(arg => {
        let event: Planner["events"][number] = {
            id: "",
            title: "",
            actions: [],
            owner: userId,
            calendar: [{
                start: arg.date.toISOString(),
                end: new Date(arg.date.getTime() + 1000 * 60 * 60).toISOString(),
            }],
        }

        const canCreateOwn = rights.isRightAllowed(RIGHTS.ACTION.WRITE_EVENTS);

        if (canCreateOwn) M.renderEventFormModal({ actions: resources.actions, event, navigate }).then(results => {
            // Only option is create a new event
            if (results && results.type === "add") {
                let resultsActionIds = results.actions.map(a => a.id);
                setResources(p => ({
                    events: p.events.concat(results.event),
                    actions: p.actions.filter(a => !resultsActionIds.includes(a.id)),
                }));
            }
        });
    }, [navigate, setResources, rights, resources.actions, userId]);

    const onMoveEvent = useCallback<PlannerViewProps["onMoveEvent"]>(arg => {
        let event = resources.events.filter(e => arg.event.id)[0];
        let calIndex = arg.event.extendedProps?.calIndex;

        const canEditEvent = event.owner === userId
            ? rights.isRightAllowed(RIGHTS.ACTION.WRITE_EVENTS)
            : rights.isRightAllowed(RIGHTS.ACTION.WRITE_OTHER_EVENTS);

        if (event && canEditEvent && typeof calIndex === "number") S.updateEventCalendar({
            event: event.id,
            calIndex,
            end: arg.event.end.toISOString(),
            start: arg.event.start.toISOString(),
        })
            .then(({ data }) => setResources(p => ({
                ...p,
                events: p.events.map(e => e.id !== event.id ? e : { ...e, calendar: data }),
            })))
            .catch(e => {
                M.Alerts.updateError(e);
                arg.revert();
            });
        else arg.revert();
    }, [setResources, rights, userId, resources.events]);

    const onResizeEvent = useCallback<PlannerViewProps["onResizeEvent"]>(arg => {
        let event = resources.events.filter(e => arg.event.id)[0];
        let calIndex = arg.event.extendedProps?.calIndex;

        const canEditEvent = event.owner === userId
            ? rights.isRightAllowed(RIGHTS.ACTION.WRITE_EVENTS)
            : rights.isRightAllowed(RIGHTS.ACTION.WRITE_OTHER_EVENTS);

        if (event && canEditEvent && typeof calIndex === "number") S.updateEventCalendar({
            event: event.id,
            calIndex,
            end: arg.event.end.toISOString(),
            start: arg.event.start.toISOString(),
        })
            .then(({ data }) => setResources(p => ({
                ...p,
                events: p.events.map(e => e.id !== event.id ? e : { ...e, calendar: data }),
            })))
            .catch(e => {
                M.Alerts.updateError(e);
                arg.revert();
            });
        else arg.revert();
    }, [rights, userId, resources.events, setResources]);
    //#endregion

    //#region Actions Callbacks
    const editAction = useCallback((action: Action) => {
        M.renderFormModal({ submissionId: action.id, path: FP.TICKET_FORM }).then(actionEdited => {
            if (actionEdited) S.getActionsPlanner(action.id)
                .then(({ data }) => setResources(p => ({
                    ...p,
                    actions: p.actions.map(a => data.filter(na => na.id === a.id)[0] || a),
                })))
                .catch(M.Alerts.loadError);
        });
    }, [setResources]);

    const removeAction = useCallback((action: Action) => {
        M.askConfirm().then(confirmed => {
            if (confirmed) S.removeTickets({ ids: action.id })
                .then(({ data }) => setResources(p => ({ ...p, actions: p.actions.filter(a => !data.tickets.includes(a.id)) })))
                .catch(M.Alerts.deleteError);
        });
    }, [setResources]);

    const addAction = useCallback(() => {
        const typePromise = new Promise<Action["type"]>(resolve => {
            const canCreateReg = rights.isRightAllowed(RIGHTS.ACTION.WRITE_REG_ACTIONS);
            const canCreateMaint = rights.isRightAllowed(RIGHTS.ACTION.WRITE_MAINTENANCE_ACTION);

            if (canCreateReg && canCreateMaint) M.askConfirm({
                yesText: "reg",
                noVariant: "primary",
                noText: "maintenance",
                text: "todo reg or maintenance action",
            }).then(response => {
                if (typeof response === "boolean") resolve(response ? "reg" : "maintenance");
                else resolve(null);
            })
            else if (canCreateReg) resolve("reg");
            else if (canCreateMaint) resolve("maintenance");
            else resolve(null);
        });

        typePromise.then(type => {
            if (type) renderEquipmentModal({ context: roots }).then(equipment => {
                let forcedSubmission = TB.submissionToArrayUpdate({ type, equipment });
                if (equipment) renderFormModal<T.TicketData>({ path: FP.TICKET_FORM, forcedSubmission }).then(action => {
                    if (action) S.getActionsPlanner(action._id)
                        .then(({ data }) => setResources(p => ({
                            ...p,
                            actions: p.actions.map(a => data.filter(na => na.id === a.id)[0] || a),
                        })))
                        .catch(M.Alerts.loadError);
                });
            });
        });
    }, [rights, roots, setResources]);
    //#endregion

    return <Flex ref={setSize} className="w-100 mb-3">
        <div style={{ width: "350px" }}>
            <ActionsView
                onAdd={addAction}
                onEdit={editAction}
                height={size.height}
                onRemove={removeAction}
                loading={status === "load"}
                actions={resources.actions}
            />
        </div>
        <PlannerView
            events={resources.events}
            onMoveEvent={onMoveEvent}
            onClickDate={onClickDate}
            onDropAction={onDropAction}
            onClickEvent={onClickEvent}
            loading={status === "load"}
            onResizeEvent={onResizeEvent}
        />
    </Flex>;
}

export default PlannerLogic;