import _ from "lodash";
import * as C from "../Common";
import * as S from "../services";
import * as PM from "../PurposeModal";
import * as M from "../Components/Modal";
import { FP, T, TB, TC } from "../Constants";
import { store as async_store } from "../Redux";

//#region Types
/** The function to import a file of new equipments. Catch sends back, argument for a Modal ALert */
type EquipImport = (params?: {
    /** The context to restraint the selection of building from */
    context?: T.ContextParams;
}) => Promise<"cancelled" | "empty" | ReturnType<T.API.Utils.Tables.BulkEquipments>>;

type ImportEquip = T.EquipmentData & Record<"floor" | "local", string>;
//#endregion

const equip_import: EquipImport = params => new Promise((resolve, reject) => {
    async_store.then(store => {
        //#region Find Root Building
        const building_root_promise = new Promise<string>(resolve => {
            if (params?.context) PM.renderBuildingSelect({ isRequired: true, context: params.context })
                .then(build => resolve(build));
            else M.renderSearchContext({ searchedType: "building" })
                .then(pick => resolve(pick?.id));
        });
        //#endregion

        //#region Languages
        const redux_state = store.getState(),
            translations = redux_state.storedLanguages,
            lang = localStorage.getItem("lang") || "fr_fr" as T.LanguageProps,
            equip_form = redux_state.formIds.filter(f => f.path === FP.EQUIPEMENT_FORM)[0]?._id,
            equip_translations = translations.object.filter(t => t._id === equip_form);

        const col_name = (property: string): string => {
            let prop_tr = equip_translations.filter(t => t.prop === property)[0];
            if (prop_tr) return prop_tr[lang] || property;
            return translations.static.filter(tr => tr.ref === property)[0]?.[lang] || property;
        }
        //#endregion

        building_root_promise.then(building => {
            if (!TB.mongoIdValidator(building)) resolve("cancelled");
            else {
                // Create the structure of what to import
                let columns_format = {
                    ip: { label: "", type: "string" },
                    eer: { label: "", type: "number" },
                    rei: { label: "", type: "number" },
                    gaz: { label: "", type: "boolean" },
                    code: { label: "", type: "string" },
                    state: { label: "", type: "string" },
                    brand: { label: "", type: "string" },
                    model: { label: "", type: "string" },
                    width: { label: "", type: "number" },
                    idCode: { label: "", type: "string" },
                    length: { label: "", type: "number" },
                    height: { label: "", type: "number" },
                    uValue: { label: "", type: "number" },
                    qrCode: { label: "", type: "string" },
                    vetusty: { label: "", type: "string" },
                    mazout: { label: "", type: "boolean" },
                    incline: { label: "", type: "number" },
                    surface: { label: "", type: "number" },
                    longName: { label: "", type: "string" },
                    quantity: { label: "", type: "number" },
                    category: { label: "", type: "string" },
                    diameter: { label: "", type: "number" },
                    capacity: { label: "", type: "number" },
                    useRange: { label: "", type: "string" },
                    criticity: { label: "", type: "string" },
                    nbrHeater: { label: "", type: "number" },
                    thickness: { label: "", type: "number" },
                    materials: { label: "", type: "string" },
                    isBuried: { label: "", type: "boolean" },
                    elecPhase: { label: "", type: "string" },
                    panelType: { label: "", type: "string" },
                    surfaceNet: { label: "", type: "number" },
                    diameterIn: { label: "", type: "number" },
                    tankVolume: { label: "", type: "number" },
                    loopNumber: { label: "", type: "number" },
                    usefulLoad: { label: "", type: "number" },
                    elecCosPhi: { label: "", type: "number" },
                    windowType: { label: "", type: "string" },
                    total_cost: { label: "", type: "number" },
                    collective: { label: "", type: "boolean" },
                    classified: { label: "", type: "boolean" },
                    modulation: { label: "", type: "boolean" },
                    diameterOut: { label: "", type: "number" },
                    elecVoltage: { label: "", type: "number" },
                    refrigerant: { label: "", type: "string" },
                    illuminance: { label: "", type: "number" },
                    openingType: { label: "", type: "string" },
                    orientation: { label: "", type: "string" },
                    serialNumber: { label: "", type: "string" },
                    isRegulated: { label: "", type: "boolean" },
                    safetyValve: { label: "", type: "boolean" },
                    levelsServed: { label: "", type: "number" },
                    nominalSpeed: { label: "", type: "number" },
                    hotWaterFlow: { label: "", type: "number" },
                    mixedAirFlow: { label: "", type: "number" },
                    minElecPower: { label: "", type: "number" },
                    maxElecPower: { label: "", type: "number" },
                    luminousFlow: { label: "", type: "number" },
                    dateFabrication: { label: "", type: "date" },
                    maxManoHeight: { label: "", type: "number" },
                    gasHeatOutput: { label: "", type: "number" },
                    cityWaterFlow: { label: "", type: "number" },
                    rainWaterFlow: { label: "", type: "number" },
                    softWaterFlow: { label: "", type: "number" },
                    greyWaterFlow: { label: "", type: "number" },
                    coldWaterFlow: { label: "", type: "number" },
                    pulsedAirFlow: { label: "", type: "number" },
                    elecIntensity: { label: "", type: "number" },
                    smokeApproval: { label: "", type: "string" },
                    underContract: { label: "", type: "boolean" },
                    dateInstallation: { label: "", type: "date" },
                    leakDetection: { label: "", type: "boolean" },
                    insulationType: { label: "", type: "string" },
                    fecalWaterFlow: { label: "", type: "number" },
                    extractAirFlow: { label: "", type: "number" },
                    maxPressureAir: { label: "", type: "number" },
                    compressorPower: { label: "", type: "number" },
                    maintenanceCost: { label: "", type: "number" },
                    nominalPressure: { label: "", type: "number" },
                    batteryCapacity: { label: "", type: "number" },
                    minWorkingPower: { label: "", type: "number" },
                    smokeEvacuation: { label: "", type: "string" },
                    refrigerantLoad: { label: "", type: "number" },
                    illuminatedArea: { label: "", type: "number" },
                    filtrationClass: { label: "", type: "string" },
                    replacement_cost: { label: "", type: "number" },
                    inGoodCondition: { label: "", type: "boolean" },
                    failureCriticity: { label: "", type: "string" },
                    expectedLifespan: { label: "", type: "number" },
                    extinguisherLoad: { label: "", type: "number" },
                    lightPerformance: { label: "", type: "number" },
                    liquidHeatOutput: { label: "", type: "number" },
                    osmoticWaterFlow: { label: "", type: "number" },
                    linkedCompressor: { label: "", type: "boolean" },
                    extinguisherAgent: { label: "", type: "string" },
                    inflationPressure: { label: "", type: "number" },
                    operatingPressure: { label: "", type: "number" },
                    nominalEfficiency: { label: "", type: "number" },
                    passengerCapacity: { label: "", type: "number" },
                    headNumLeakSystem: { label: "", type: "number" },
                    maxElecPowerCrete: { label: "", type: "number" },
                    startingElecPower: { label: "", type: "number" },
                    elecCircuitNumber: { label: "", type: "number" },
                    connectionDiameter: { label: "", type: "number" },
                    heatingWaterVolume: { label: "", type: "number" },
                    extinguisherVolume: { label: "", type: "number" },
                    recoveryEfficiency: { label: "", type: "number" },
                    latentHeatRecovery: { label: "", type: "number" },
                    ejectorNumInertGas: { label: "", type: "number" },
                    nominalPressureAir: { label: "", type: "number" },
                    thermalConductivity: { label: "", type: "number" },
                    insulationThickness: { label: "", type: "number" },
                    hotHeatingWaterFlow: { label: "", type: "number" },
                    elecAbsorbPowerWatt: { label: "", type: "number" },
                    name: { label: "", type: "string", required: true },
                    refrigerantLoadTeCO2: { label: "", type: "number" },
                    maintenanceFrequency: { label: "", type: "string" },
                    extinguisherPressure: { label: "", type: "number" },
                    operatingTemperature: { label: "", type: "number" },
                    sensibleHeatRecovery: { label: "", type: "number" },
                    controlValveSettings: { label: "", type: "string" },
                    estimated_end_of_life: { label: "", type: "number" },
                    coldWaterNominalPower: { label: "", type: "number" },
                    regfrigerantPulseFlow: { label: "", type: "number" },
                    elecAbsorbPowerAmpere: { label: "", type: "number" },
                    maxNominalHeatPowerAir: { label: "", type: "number" },
                    maxNominalColdPowerAir: { label: "", type: "number" },
                    elecDeliveredPowerWatt: { label: "", type: "number" },
                    refrigerantMaxPressure: { label: "", type: "number" },
                    coldWaterNominalPowerMin: { label: "", type: "number" },
                    coldWaterNominalPowerMax: { label: "", type: "number" },
                    elecDeliveredPowerAmpere: { label: "", type: "number" },
                    coldWaterTemperatureRegime: { label: "", type: "number" },
                    refrigerantNominalPressure: { label: "", type: "number" },
                    hotHeatingWaterNominalPower: { label: "", type: "number" },
                    hotHeatingWaterNominalPowerMin: { label: "", type: "number" },
                    hotHeatingWaterNominalPowerMax: { label: "", type: "number" },
                    hotHeatingWaterTemperatureRegime: { label: "", type: "number" },
                    floor: { label: TC.GLOBAL_FLOOR, type: "string", required: true },
                    local: { label: TC.GLOBAL_LOCAL, type: "string", required: true },
                } as T.ImportFormat<keyof ImportEquip>;
                // Set the label of each columns
                for (let [key, value] of Object.entries(columns_format)) columns_format[key].label = col_name(value.label || key);
                // Set the params for the file import and the mapping of the properties
                const mapper_params = {
                    required: true,
                    save: "import_equip",
                    columnFormat: columns_format,
                    title: TC.GLOBAL_SELECT_FILE_IMPORT,
                } as Parameters<typeof M.renderExcelMapper>[0];
                // File import + user mapping
                M.renderExcelMapper(mapper_params).then((excel_equip: ImportEquip[]) => {
                    if (!excel_equip) resolve("cancelled");
                    else if (excel_equip.length === 0) resolve("empty");
                    else {
                        let ignored_list = [] as C.Import.ValueIgnorerProps['values'];

                        const add_to_list = (prop: string, value: string) => {
                            // Pair prop/value not already in the list
                            if (!ignored_list.some(i => i.prop === prop && i.value === value)) {
                                let label = columns_format[prop]?.label || prop;
                                ignored_list.push({ prop, value, label, ignored: true });
                            }
                        }
                        // Check for potentially ignorable values
                        for (let equip of excel_equip) {

                            // If floor label was picked up as a number, set it to a string
                            if (typeof equip.floor === "number") equip.floor = (equip.floor as number).toString();
                            // If local label was picked up as a number, set it to a string
                            if (typeof equip.local === "number") equip.local = (equip.local as number).toString();

                            for (let [prop, value] of Object.entries(equip)) {
                                // Check for parasite values (ex: - / x ...)
                                if (typeof value === "string" && value.length > 0) {
                                    // 1 char long non-word character
                                    if (value.length === 1 && value.match(/\W/g)) add_to_list(prop, value);
                                }
                            }
                        }
                        
                        // Ask the user to determine which values are 'parasites'
                        M.renderValueIgnorer({ values: ignored_list }).then(ignored => {
                            if (!ignored) resolve("cancelled");
                            else {
                                // Remove the parasite values from the equipments
                                for (let equip of excel_equip) {
                                    for (let i of ignored) {
                                        if (equip[i.prop] === i.value) delete equip[i.prop];
                                    }
                                }
                                // Load the resources that will be useful later
                                const dismount = M.renderLoader();
                                S.getEquipImportResources(building).then(({ data }) => {
                                    // A list of emplacements found in the excel file
                                    let to_import_emplacements = excel_equip
                                        .map(e => ({ floor: e.floor, local: e.local })) as Parameters<typeof M.renderEmplacementMapper>[0]["to_import"];
                                    // Reduce the list of import to avoid duplicates
                                    to_import_emplacements = _.uniqBy(to_import_emplacements, e => e.floor + e.local);
                                    // Create / assign locals and floors
                                    M.renderEmplacementMapper({ links: data.links, emplacements: data.emplacements, to_import: to_import_emplacements, building }).then(emp_map => {
                                        if (!emp_map) resolve("cancelled");
                                        else {
                                            // Create a list of equipments of the same format than bulk_equipments, to use it later
                                            let bulk_equipments = [] as Parameters<typeof S.bulk_equipments>[0];
                                            // Set the floor/local in "input" property, to allow use of the bulkQuickInsert of equipments
                                            for (let map of emp_map.mapping) {
                                                // Filter to only keep the equipments that are concerned
                                                let concerned_equip = excel_equip.filter(e => e.floor === map.floor && e.local === map.local);
                                                // Update the location
                                                for (let e of concerned_equip) {
                                                    // Pick the closest parent
                                                    let input = map.local_id || map.floor_id || building;
                                                    // Add the equipment to the list to import
                                                    let new_equip: typeof bulk_equipments[number] = { ..._.omit(e, "floor", "local"), input };
                                                    bulk_equipments.push(new_equip);
                                                }
                                            }

                                            let mapping = [] as Parameters<typeof M.renderValueMapper>[0]["mapping"];
                                            // Create the mapping for the equipments's categories
                                            for (let equipment of bulk_equipments) {
                                                if (equipment.category) {
                                                    let category = data.options.category.find(c => (c as any).omniclass === equipment.category);
                                                    if (category) mapping.push({ excel_str: equipment.category, prop: "category", value: category.value });
                                                }
                                            }

                                            // Create / assign values that are "set"
                                            M.renderValueMapper({ options: data.options, mapping, equipments: bulk_equipments, brand_model: data.brand_model }).then(val_map => {
                                                if (!val_map) resolve("cancelled");
                                                else {
                                                    // Add the default color
                                                    val_map = val_map.map(e => ({ ...e, color: e.color || "#0c7e3d" }));
                                                    // Create the equipments in the database
                                                    S.bulk_equipments(val_map)
                                                        .then(results => resolve(results.data))
                                                        .catch(reject);
                                                }
                                            });
                                        }
                                    });
                                })
                                    .catch(reject)
                                    .finally(dismount);
                            }
                        });
                    }
                });
            }
        }).catch(reject);
    });
});

export default equip_import;