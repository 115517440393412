import _ from "lodash";
import { T, TB, TC } from "../../../Constants";
import { CenterMessage, Form } from "../../../Common";
import { useElementSize, useEventListener } from "../../../hooks";
import React, { HTMLAttributes, useCallback, useMemo, useRef, useState } from "react";

export type DropDownProps<A> = {
    /** A list of options to display */
    options: T.Option<A>[];
    /** A callback to render custom options */
    render_option?: (option: T.Option<A>) => React.ReactElement;
    /** Callback for a selection */
    onSelect: (option: T.Option<A>) => void;
}

const DropDown = <A,>({ onSelect, ...props }: DropDownProps<A>) => {
    const [search, setSearch] = useState("");
    const container = useRef<HTMLDivElement>(null);
    const [setWrapperSize, sizeWrapper] = useElementSize();
    const [focused, setFocused] = useState(props.options[0]?.value || "");

    //#region Options
    const filteredOptions = useMemo(() => {
        if (search.length === 0) return props.options;
        return props.options.filter(o => TB.areStringSimilar(search, o.label));
    }, [search, props.options]);
    //#endregion

    //#region Style 
    const focusedStyle = useMemo<HTMLAttributes<HTMLElement>["style"]>(() => ({
        backgroundColor: "lightblue",
    }), []);
    //#endregion

    //#region Key Press
    const onEnter = useCallback((id: string) => {
        let option = props.options.filter(o => o.value === id)[0];
        if (option) onSelect?.(option);
    }, [onSelect, props.options]);

    const onKeyPress = useCallback((e: KeyboardEvent) => {
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            e.preventDefault();
            let focusedIndex = _.findIndex(filteredOptions, o => o.value === focused);
            let focusedValue = "";

            if (e.key === "ArrowDown") {
                if (focusedIndex < 0 || focusedIndex + 1 === filteredOptions.length) focusedValue = filteredOptions[0]?.value || "";
                else focusedValue = filteredOptions[focusedIndex + 1]?.value || "";
            }
            else {
                if (focusedIndex < 0 || focusedIndex - 1 < 0) focusedValue = filteredOptions[filteredOptions.length - 1]?.value || "";
                else focusedValue = filteredOptions[focusedIndex - 1]?.value || "";
            }

            setFocused(focusedValue);
            if (focusedValue) document.getElementById(focusedValue)?.scrollIntoView?.();
        }
        else if (e.key === "Enter") {
            onEnter(focused);
            e.preventDefault();
        }
    }, [filteredOptions, focused, onEnter]);

    useEventListener("keydown", onKeyPress);
    //#endregion

    return <div ref={setWrapperSize} className="w-100">
        <div ref={container} className="position-fixed overflow-auto z-index-high border rounded bg-white" style={{ maxHeight: "250px", width: sizeWrapper.width + "px" }}>
            <div>
                <Form.TextField
                    autoFocus
                    uncontrolled
                    value={search}
                    noBottomMargin
                    onChange={setSearch}
                    placeholder={TC.GLOBAL_SEARCH_PLACEHOLDER}
                />
            </div>
            {filteredOptions.length > 0
                ? filteredOptions.map(o => <div
                    id={o.value}
                    key={o.value}
                    onClick={() => onEnter(o.value)}
                    className="border-bottom pointer p-1"
                    style={o.value === focused ? focusedStyle : {}}
                >
                    {typeof props.render_option === "function" ? props.render_option(o) : o.label}
                </div>)
                : <CenterMessage className="p-2" size="sm" italics>
                    {TC.GLOBAL_NO_OPTIONS_SELECT}
                </CenterMessage>}
        </div>
    </div>
}

export default DropDown;