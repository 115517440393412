import React from "react";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import * as S from "../../../services";
import { DS, T, TC } from "../../../Constants";

export type ConsumptionProps = {
    /** The asset of the mission */
    asset_id: string;
    /** Do not allow any edit */
    read_only?: boolean;
    /** The consumption values for the mission */
    consumption: T.Mission["consumption"];
    /** CRREM options */
    crrem: ReturnType<T.API.Utils.Missions.MissionResumeNRJ>["crrem"];
    /** A callback to update the consumption */
    set_consumption: (cons: T.Mission["consumption"], apply_db: boolean) => void;
}

export type ConsumptionRef = {
    /** Callback to reload the datasets */
    reload_datasets: () => void;
}

const MAX_YEARS = 40;
type Option = Awaited<ReturnType<typeof S.getDatasetsOptions>>["data"][number];

const Consumption = React.forwardRef<ConsumptionRef, ConsumptionProps>(({ set_consumption, ...props }, ref) => {
    const lg = H.useLanguage();
    const [datasets, set_datasets, status] = H.useAsyncState<Option[]>([]);
    const [errors, set_errors] = React.useState<T.Errors<T.Mission["consumption"]>>({});

    React.useImperativeHandle(ref, () => ({
        reload_datasets: () => {
            S.getContextDatasetsOptions({ roots: props.asset_id })
                .then(({ data }) => set_datasets(data, "done"))
                .catch(() => set_datasets([], "error"))
        }
    }), [props.asset_id, set_datasets]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getContextDatasetsOptions({ roots: props.asset_id })
            .then(({ data }) => isSubscribed && set_datasets(data, "done"))
            .catch(() => isSubscribed && set_datasets([], "error"))
        return () => {
            isSubscribed = false;
            set_datasets([], "load");
        }
    }, [props.asset_id, set_datasets]);

    const year_options = React.useMemo(() => {
        let current_year = new Date().getFullYear();
        return [...new Array(MAX_YEARS)].map((v, i) => ({ value: current_year - i, label: (current_year - i).toString() }));
    }, []);

    const datasets_named = React.useMemo(() => datasets.map(d => ({ ...d, label: `${d.label} (${d.origin})` })), [datasets])

    const options = React.useMemo(() => ({
        all: datasets_named,
        cost: datasets_named.filter(d => d.nrj === "MONEY"),
        gas: datasets_named.filter(d => d.nrj === "GAS" || d.nrj === "GAS_HEAD"),
        elec: datasets_named.filter(d => d.nrj === "ELEC" || d.nrj === "ELEC_HEAD"),
        fuel: datasets_named.filter(d => d.nrj === "FUEL" || d.nrj === "FUEL_HEAD"),
        water: datasets_named.filter(d => d.nrj === "WATER" || d.nrj === "WATER_HEAD"),
    }), [datasets_named]);

    const change_property = React.useCallback((prop: keyof T.Mission["consumption"], value: any) => {
        let new_errors = {} as typeof errors;
        if (prop === "year_to" && value <= props.consumption?.year_from) new_errors.year_to = TC.EVENT_ERROR_END_LOWER_START;
        else if (prop === "year_from" && value >= props.consumption?.year_to) new_errors.year_from = TC.EVENT_ERROR_START_HIGHER_END;
        else if (prop === "to_correct_elec" || prop === "to_correct_fuel" || prop === "to_correct_gas") {
            if (value < 0 || value > 100) new_errors[prop] = TC.NRJ_MISSION_PCT_INVALID;
        }

        // Extra update for the consumption object
        let extra_update: Partial<T.Mission["consumption"]> = {};
        if (prop === "gas" && !value) {
            new_errors.to_correct_gas = undefined;
            extra_update = { to_correct_gas: undefined };
        }
        else if (prop === "elec" && !value) {
            new_errors.to_correct_elec = undefined;
            extra_update = { to_correct_elec: undefined };
        }
        else if (prop === "fuel" && !value) {
            new_errors.to_correct_fuel = undefined;
            extra_update = { to_correct_fuel: undefined };
        }
        else if (prop === "has_ecs" && !value) {
            new_errors.to_correct_gas = undefined;
            new_errors.to_correct_elec = undefined;
            new_errors.to_correct_fuel = undefined;
            extra_update = { to_correct_elec: undefined, to_correct_gas: undefined, to_correct_fuel: undefined };
        }

        let has_errors = Object.keys(new_errors).length > 0;
        // Update the errors
        if (has_errors) set_errors(p => ({ ...p, ...new_errors }));
        else set_errors(p => ({ ...p, [prop]: undefined }));
        // Update the consumption object
        set_consumption({ ...props.consumption, ...extra_update, [prop]: value }, !has_errors);
    }, [props.consumption, set_consumption]);

    const render_dataset = React.useCallback<C.Form.SelectProps["typeahead"]["renderItem"]>((option: Option) => <>
        <i className={`fa fa-${DS.ICONS[option.src]} me-2`}></i>
        {option.label}
    </>, []);

    const toggle_ecs = React.useCallback((has_ecs: boolean) => {
        if (has_ecs) set_consumption?.({
            ...props.consumption,
            has_ecs,
            to_correct_gas: 0,
            to_correct_elec: 0,
            to_correct_fuel: 0,
        }, true)
        else {
            // Remove consumption data
            set_consumption?.({
                ...props.consumption,
                has_ecs,
                to_correct_gas: undefined,
                to_correct_elec: undefined,
                to_correct_fuel: undefined,
            }, true);
            // Remove possible errors
            set_errors(p => ({ ...p, to_correct_elec: undefined, to_correct_gas: undefined, to_correct_fuel: undefined, to_correct_water: undefined }));
        }
    }, [set_consumption, props.consumption]);

    return <div>
        {/* General */}
        <C.Title level={4} className="mb-3" text={TC.NRJ_MISSION_CONS_GEN} />
        <BS.Row className="mb-3">
            <BS.Col md={6} className="d-flex justify-content-between align-items-center">
                <div>{lg.getStaticText(TC.NRJ_MISSION_CONS_RANGE)}</div>

                <C.Flex alignItems="center" justifyContent="between">
                    <C.Form.Select
                        no_clear_btn
                        noBottomMargin
                        options={year_options}
                        error={errors.year_from}
                        disabled={props.read_only}
                        value={props.consumption?.year_from}
                        onChange={value => change_property("year_from", value)}
                    />
                    <i className="fa fa-arrow-right mx-2"></i>
                    <C.Form.Select
                        no_clear_btn
                        noBottomMargin
                        options={year_options}
                        error={errors.year_to}
                        disabled={props.read_only}
                        value={props.consumption?.year_to}
                        onChange={value => change_property("year_to", value)}
                    />
                </C.Flex>
            </BS.Col>
        </BS.Row>
        <BS.Row>
            <BS.Col md={3}>
                <C.Form.RadioBool
                    labelPosition="left"
                    onChange={toggle_ecs}
                    disabled={props.read_only}
                    value={props.consumption?.has_ecs}
                    label={TC.NRJ_MISSION_CONS_HAS_ECS}
                />
            </BS.Col>
        </BS.Row>

        {/* Elec */}
        <C.Title level={4} className="mb-3" text={TC.NRJ_MISSION_CONS_TITLE_ELEC} />
        <BS.Row>
            <BS.Col md={6}>
                <C.Form.Select
                    required
                    error={errors.elec}
                    labelPosition="left"
                    options={options.elec}
                    disabled={props.read_only}
                    loading={status === "load"}
                    value={props.consumption?.elec}
                    label={TC.NRJ_MISSION_CONS_ELEC_CONS}
                    typeahead={{ renderItem: render_dataset }}
                    onChange={value => change_property("elec", value)}
                />
            </BS.Col>
            <BS.Col md={6}>
                <C.Form.NumField
                    required
                    suffix="%"
                    labelPosition="left"
                    disabled={props.read_only}
                    error={errors.to_correct_elec}
                    label={TC.NRJ_MISSION_CONS_TO_CORRECT}
                    value={props.consumption?.to_correct_elec}
                    onChange={value => change_property("to_correct_elec", value)}
                    hidden={!props.consumption?.has_ecs || !props.consumption?.elec}
                />
            </BS.Col>
        </BS.Row>
        <BS.Row>
            <BS.Col md={6}>
                <C.Form.Select
                    labelPosition="left"
                    options={options.cost}
                    error={errors.cost_elec}
                    disabled={props.read_only}
                    loading={status === "load"}
                    value={props.consumption?.cost_elec}
                    label={TC.NRJ_MISSION_CONS_COST_ELEC}
                    typeahead={{ renderItem: render_dataset }}
                    onChange={value => change_property("cost_elec", value)}
                />
            </BS.Col>
        </BS.Row>
        <BS.Row>
            <BS.Col md={6}>
                <C.Form.Select
                    labelPosition="left"
                    options={options.elec}
                    error={errors.peak_hours}
                    disabled={props.read_only}
                    loading={status === "load"}
                    value={props.consumption?.peak_hours}
                    label={TC.NRJ_MISSION_CONS_PEAK_HOURS}
                    typeahead={{ renderItem: render_dataset }}
                    onChange={value => change_property("peak_hours", value)}
                />
            </BS.Col>
        </BS.Row>
        <BS.Row>
            <BS.Col md={6}>
                <C.Form.Select
                    labelPosition="left"
                    options={options.elec}
                    disabled={props.read_only}
                    loading={status === "load"}
                    error={errors.off_peak_hours}
                    value={props.consumption?.off_peak_hours}
                    label={TC.NRJ_MISSION_CONS_OFF_PEAK_HOURS}
                    typeahead={{ renderItem: render_dataset }}
                    onChange={value => change_property("off_peak_hours", value)}
                />
            </BS.Col>
        </BS.Row>
        <BS.Row>
            <BS.Col md={6}>
                <C.Form.Select
                    labelPosition="left"
                    options={options.all}
                    error={errors.cos_phi}
                    disabled={props.read_only}
                    loading={status === "load"}
                    value={props.consumption?.cos_phi}
                    label={TC.NRJ_MISSION_CONS_COS_PHI}
                    typeahead={{ renderItem: render_dataset }}
                    onChange={value => change_property("cos_phi", value)}
                />
            </BS.Col>
        </BS.Row>
        <BS.Row>
            <BS.Col md={6}>
                <C.Form.Select
                    disabled
                    labelPosition="left"
                    error={errors.crrem}
                    options={props.crrem}
                    value={props.consumption?.crrem}
                    label={TC.NRJ_MISSION_CONS_CRREM}
                    onChange={value => change_property("crrem", value)}
                />
            </BS.Col>
        </BS.Row>

        {/* Fuel & Gas */}
        <C.Title level={4} className="mb-3" text={TC.NRJ_MISSION_CONS_TITLE_COMB} />
        <BS.Row>
            <BS.Col md={6}>
                <C.Form.Select
                    error={errors.gas}
                    labelPosition="left"
                    options={options.gas}
                    disabled={props.read_only}
                    loading={status === "load"}
                    value={props.consumption?.gas}
                    label={TC.NRJ_MISSION_CONS_CONS_GAS}
                    typeahead={{ renderItem: render_dataset }}
                    onChange={value => change_property("gas", value)}
                />
            </BS.Col>
            <BS.Col md={6}>
                <C.Form.NumField
                    suffix="%"
                    labelPosition="left"
                    disabled={props.read_only}
                    error={errors.to_correct_gas}
                    required={!!props.consumption?.gas}
                    label={TC.NRJ_MISSION_CONS_TO_CORRECT}
                    value={props.consumption?.to_correct_gas}
                    onChange={value => change_property("to_correct_gas", value)}
                    hidden={!props.consumption?.has_ecs || !props.consumption?.gas}
                />
            </BS.Col>
        </BS.Row>
        <BS.Row>
            <BS.Col md={6}>
                <C.Form.Select
                    labelPosition="left"
                    options={options.cost}
                    error={errors.cost_gas}
                    disabled={props.read_only}
                    loading={status === "load"}
                    value={props.consumption?.cost_gas}
                    label={TC.NRJ_MISSION_CONS_COST_GAS}
                    typeahead={{ renderItem: render_dataset }}
                    onChange={value => change_property("cost_gas", value)}
                />
            </BS.Col>
        </BS.Row>
        <BS.Row>
            <BS.Col md={6}>
                <C.Form.Select
                    error={errors.fuel}
                    labelPosition="left"
                    options={options.fuel}
                    disabled={props.read_only}
                    loading={status === "load"}
                    value={props.consumption?.fuel}
                    label={TC.NRJ_MISSION_CONS_FUEL_CONS}
                    typeahead={{ renderItem: render_dataset }}
                    onChange={value => change_property("fuel", value)}
                />
            </BS.Col>
            <BS.Col md={6}>
                <C.Form.NumField
                    suffix="%"
                    labelPosition="left"
                    disabled={props.read_only}
                    error={errors.to_correct_fuel}
                    required={!!props.consumption?.fuel}
                    label={TC.NRJ_MISSION_CONS_TO_CORRECT}
                    value={props.consumption?.to_correct_fuel}
                    onChange={value => change_property("to_correct_fuel", value)}
                    hidden={!props.consumption?.has_ecs || !props.consumption?.fuel}
                />
            </BS.Col>
        </BS.Row>
        <BS.Row>
            <BS.Col md={6}>
                <C.Form.Select
                    labelPosition="left"
                    options={options.cost}
                    label={TC.NRJ_MISSION_CONS_COST_FUEL}
                    error={errors.cost_fuel}
                    disabled={props.read_only}
                    loading={status === "load"}
                    value={props.consumption?.cost_fuel}
                    typeahead={{ renderItem: render_dataset }}
                    onChange={value => change_property("cost_fuel", value)}
                />
            </BS.Col>
        </BS.Row>

        {/* Water */}
        <C.Title level={4} className="mb-3" text={TC.NRJ_MISSION_CONS_TITLE_WATER} />
        <BS.Row>
            <BS.Col md={6}>
                <C.Form.Select
                    labelPosition="left"
                    error={errors.water}
                    options={options.water}
                    disabled={props.read_only}
                    loading={status === "load"}
                    value={props.consumption?.water}
                    label={TC.NRJ_MISSION_CONS_WATER}
                    typeahead={{ renderItem: render_dataset }}
                    onChange={value => change_property("water", value)}
                />
            </BS.Col>
        </BS.Row>
        <BS.Row>
            <BS.Col md={6}>
                <C.Form.Select
                    labelPosition="left"
                    options={options.cost}
                    error={errors.cost_water}
                    disabled={props.read_only}
                    loading={status === "load"}
                    value={props.consumption?.cost_water}
                    label={TC.NRJ_MISSION_CONS_COST_WATER}
                    typeahead={{ renderItem: render_dataset }}
                    onChange={value => change_property("cost_water", value)}
                />
            </BS.Col>
        </BS.Row>
    </div>;
});

export default Consumption;