import _ from "lodash";
import React from "react";
import * as M from "../Modal";
import * as G from "../Gestion";
import * as H from "../../hooks";
import * as C from "../../Common";
import * as BS from "react-bootstrap";
import { FP, T, TB, TC } from "../../Constants";
import * as AR from "@adaptabletools/adaptable-react-aggrid";

//#region Types
type RegEquipmentsProps = {
    context: T.ContextParams;
    containerSize: number;
    elements: ReturnType<T.API.Reg.GetRegTabElements>["elements"];
    resources: ReturnType<T.API.Reg.GetRegTabResults>;
    api: React.MutableRefObject<UnMountRef | null>;
    updateElements: H.SetAsyncState<ReturnType<T.API.Reg.GetRegTabElements>["elements"], "">;
    /** Do not allow any edits */
    read_only?: boolean;
}

type FormattedCat = {
    _id: string;
    name: string;
    missing: number;
    nbEquip: number;
    is_main: boolean;
}

type FormattedMainCat = FormattedCat & { descendants: number };
type AdaptableStyleRef = { style?: AR.FormatColumn, layout?: AR.Layout };
export type UnMountRef = { hasChanged?: boolean, reset?: () => void };
//#endregion

//#region Constants
const TEXT_CODES = [TC.REG_FIX_COLUMNS, TC.REG_NO_MISSING_PROP, TC.REG_X_PROP_MISSING, TC.TAB_GAMME_EQUIP, TC.REG_FIX_COLUMNS];
//#endregion

const RegEquipments: React.FC<RegEquipmentsProps> = ({ resources, containerSize, context, api, updateElements, ...props }) => {
    const columnAlert = H.useBoolean(false);
    const tableRef = React.useRef<G.EquipmentTableRef>(null);
    const adaptableDataRef = React.useRef<AdaptableStyleRef>({});
    const [secondaryCat, setSecondCat] = React.useState<string>("");
    const { getElemObj, fetchObjectTranslations, getStaticElem } = H.useLanguage(TEXT_CODES);

    //#region Languages
    React.useEffect(() => {
        let gammes = resources.gammes.map(g => g._id);
        let mainGammes = Object.keys(resources.mainTree.categoryTree);
        let ids = gammes.concat(mainGammes).filter(TB.mongoIdValidator);
        if (ids.length > 0) fetchObjectTranslations(ids);
    }, [fetchObjectTranslations, resources.gammes, resources.mainTree.categoryTree]);
    //#endregion

    //#region Calculate Missing
    const getCleanProp = React.useCallback((prop: string) => TB.replaceStringPart(prop, "e.", ""), []);

    const getNbMissing = React.useCallback((catId: string, missingProps: string[], descendants: string[]) => {
        let missing = 0;
        for (let element of props.elements) {
            if (element.path === FP.EQUIPEMENT_FORM) {
                let category = element.submission.data.category;
                if (category === catId || descendants.includes(category)) {
                    for (let prop of missingProps.map(getCleanProp)) {
                        let value = element.submission.data[prop];
                        if (value === null || value === undefined || value === "" || (typeof value === "number" && isNaN(value))) missing++;
                    }
                }
            }
        }
        return missing;
    }, [props.elements, getCleanProp]);

    const getNbEquipments = React.useCallback((gamme: string, gammes: string[]) => {
        let nbEquip = 0;
        for (let element of props.elements) {
            if (element.path === FP.EQUIPEMENT_FORM) {
                let category = element.submission.data.category;
                if (category === gamme || gammes.includes(category)) nbEquip++;
            }
        }
        return nbEquip;
    }, [props.elements]);
    //#endregion

    //#region Adaptable
    const { forcedCat, missingProps } = React.useMemo(() => {
        let missingProps = resources.gammesProps[secondaryCat] || [];
        let forcedCat = [secondaryCat]
            .concat(resources.categoryTree[secondaryCat] || [])
            .concat(resources.mainTree.categoryTree[secondaryCat] || [])
            .filter(TB.mongoIdValidator);
        return { forcedCat, missingProps };
    }, [secondaryCat, resources.categoryTree, resources.mainTree.categoryTree, resources.gammesProps]);

    const setAdaptable = React.useCallback((props: string[]) => {
        let api = tableRef.current?.current?.adaptable;

        if (api) {
            if (adaptableDataRef.current.layout) api.layoutApi.deleteLayout(adaptableDataRef.current.layout);
            if (adaptableDataRef.current.style) api.formatColumnApi.deleteFormatColumn(adaptableDataRef.current.style);

            if (props.length > 0) {
                let fullProps = props.map(getCleanProp);

                let style = api.formatColumnApi.addFormatColumn({
                    Source: "User",
                    // IncludeGroupedRows: true,
                    Scope: { ColumnIds: fullProps },
                    Rule: { Predicates: [{ Inputs: [], PredicateId: "Blanks" }] },
                    Style: {
                        ClassName: "",
                        FontStyle: "Normal",
                        BackColor: "#FFA500",
                        FontWeight: "Normal",
                        ForeColor: "#FFFFFF",
                        BorderColor: "#FF0000",
                    }
                });

                let baseColumns = api.columnApi.getColumns()
                    .map(c => c.field)
                    .filter(f => !fullProps.includes(f));

                let endCols = baseColumns.splice(3, baseColumns.length - 3, ...fullProps);
                baseColumns = baseColumns.concat(endCols);

                let layout = api.layoutApi.createAndSetLayout({ Columns: baseColumns, Name: "reg" });

                adaptableDataRef.current.style = style;
                if (layout) adaptableDataRef.current.layout = layout;
            }
            else {
                adaptableDataRef.current.style = undefined;
                adaptableDataRef.current.layout = undefined;
            }
        }
    }, [getCleanProp]);

    React.useEffect(() => setAdaptable(missingProps), [setAdaptable, missingProps]);

    const resetColumns = React.useCallback(() => {
        let api = tableRef.current?.current?.adaptable;

        if (api) api.configApi.reloadPredefinedConfig({
            Layout: { Layouts: [adaptableDataRef.current.layout || null].filter(x => x !== null) },
            FormatColumn: { FormatColumns: [adaptableDataRef.current.style || null].filter(x => x !== null) },
        });
    }, []);
    //#endregion

    //#region Update Elements
    const onChangeValue = React.useCallback<G.EquipmentTableProps["onChange"]["value_edit"]>((_id, prop, value) => {
        if (!columnAlert.value) {
            M.renderAlert({});
            columnAlert.setFalse();
        }

        updateElements?.(p => p.map(e => {
            if (e.submission._id === _id) {
                let elem = _.cloneDeep(e);
                _.set(e.submission, prop, value);
                return elem;
            }
            return e;
        }))
    }, [updateElements, columnAlert]);

    const onRemoveElem = React.useCallback<G.EquipmentTableProps["onChange"]["delete"]>(ids => {
        if (!columnAlert.value) {
            M.renderAlert({});
            columnAlert.setFalse();
        }

        updateElements?.(p => p.filter(e => !ids.includes(e.submission._id)));
    }, [updateElements, columnAlert]);

    const onAddEquipments = React.useCallback<G.EquipmentTableProps["onChange"]["add"]>(() => {
        if (api) {
            if (TB.validObject(api.current)) api.current.hasChanged = true;
            else api.current = { hasChanged: true };
        }
    }, [api]);

    React.useEffect(() => {
        if (api) {
            const reset = () => api.current.hasChanged = false;
            if (TB.validObject(api.current)) api.current.reset = reset;
            else api.current = { reset };
        }
    }, [api]);
    //#endregion

    //#region Nb Missing props Overlay
    const renderToolTipTemp = React.useCallback((i: number, props: any) => {
        if (i === 0) return <BS.Tooltip {...props}>{getStaticElem(TC.REG_NO_MISSING_PROP)}</BS.Tooltip>;
        return <BS.Tooltip {...props}>{getStaticElem(TC.REG_X_PROP_MISSING, i.toString())}</BS.Tooltip>;
    }, [getStaticElem]);

    const getMissingOverlay = React.useCallback((missing: number) => <BS.OverlayTrigger placement="top" overlay={props => renderToolTipTemp(missing, props)}>
        <BS.Badge bg={missing > 0 ? "warning" : "success"} pill>
            {missing > 0 ? missing : <i className="fa fa-check"></i>}
        </BS.Badge>
    </BS.OverlayTrigger>, [renderToolTipTemp]);
    //#endregion

    //#region MainCat
    const mainCatList = React.useMemo<FormattedMainCat[]>(() => resources.mainTree.gammes.map(g => {
        let allMainSubGammes = resources.mainTree.categoryTree[g._id];
        let isReferencedInActions = resources.gammes.filter(g => allMainSubGammes.includes(g._id)).map(g => g._id);

        let perAction = isReferencedInActions.map(cat => {
            let missingProp = resources.gammesProps[cat];
            let descendantsGammes = resources.categoryTree[cat];
            return getNbMissing(cat, missingProp, descendantsGammes);
        });

        let missing = _.sum(perAction);
        let nbEquip = getNbEquipments(g._id, allMainSubGammes);

        return { _id: g._id, name: g.data.name, missing, descendants: isReferencedInActions.length, nbEquip, is_main: true };
    }), [resources.mainTree, resources.gammesProps, resources.gammes, resources.categoryTree, getNbMissing, getNbEquipments]);
    //#endregion

    //#region Category List
    const activeCategory = React.useMemo<FormattedCat[]>(() => {
        let categories = [] as (Record<"_id" | "name", string> & Record<"missing" | "nbEquip", number> & Record<"is_main", boolean>)[];
        mainCatList.forEach(main => {
            let descendants_ids = resources.mainTree.categoryTree[main._id];
            let descendants = resources.gammes.filter(g => descendants_ids.includes(g._id))
            if (descendants.length > 0) {
                categories.push({ _id: main._id, name: main.name, missing: main.missing, nbEquip: main.nbEquip, is_main: true });
                descendants.forEach(cat => {
                    let missingProp = resources.gammesProps[cat._id];
                    let descendantsGammes = resources.categoryTree[cat._id];
                    let nbEquip = getNbEquipments(cat._id, descendantsGammes);
                    let missing = getNbMissing(cat._id, missingProp, descendantsGammes);
                    categories.push({ _id: cat._id, name: cat.data.name, missing, nbEquip, is_main: false });
                });
            }
        });
        return categories;
    }, [resources.mainTree.categoryTree, resources.gammes, resources.gammesProps, resources.categoryTree, mainCatList, getNbMissing, getNbEquipments]);

    const renderCategoryItem = React.useCallback((cat: FormattedCat) => <BS.ListGroupItem
        as="li"
        key={cat._id}
        disabled={cat.is_main}
        active={cat._id === secondaryCat}
        onClick={() => setSecondCat(cat._id)}
        className="d-flex justify-content-between align-items-center pointer"
    >
        <div className="ms-2 me-auto">
            <div className={cat.is_main ? "fw-bold" : "ms-2"}>{getElemObj(cat._id, "name", cat.name)} ({cat.nbEquip})</div>
        </div>
        {getMissingOverlay(cat.missing)}
    </BS.ListGroupItem>, [secondaryCat, getElemObj, getMissingOverlay]);
    //#endregion

    //#region Height
    const secondRowHeight = React.useMemo(() => {
        let container = TB.getNumber(containerSize, 0);
        if (container < 0) container = 0;
        return container;
    }, [containerSize]);
    //#endregion

    return <div style={{ height: TB.getNumber(containerSize, 150) + "px" }}>
        <BS.Row style={{ height: secondRowHeight + "px" }}>
            <BS.Col md={3} className="h-100">
                <BS.Container className="position-relative bg-white border rounded p-0 mx-2 mb-3 h-100 overflow-auto">
                    <BS.ListGroup variant="flush" as="ol" children={activeCategory.map(renderCategoryItem)} />
                </BS.Container>
            </BS.Col>
            <BS.Col md={9}>
                <C.Flex className="h-100 w-100">
                    <G.Equipment
                        disable_move
                        ref={tableRef}
                        show_properties
                        hide_state_saver
                        context={context}
                        add_only_same_category
                        read_only={props.read_only}
                        default_category={secondaryCat}
                        restricted_categories={forcedCat}
                        buttons={{ icon: { element: "<i class='fa fa-table'></i>" }, onClick: resetColumns }}
                        onChange={React.useMemo(() => ({
                            delete: onRemoveElem,
                            add: onAddEquipments,
                            edit: onAddEquipments,
                            value_edit: onChangeValue,
                        }), [onChangeValue, onRemoveElem, onAddEquipments])}
                        table={{
                            adaptable: {
                                predefinedConfig: {
                                    Layout: { Layouts: [adaptableDataRef.current.layout || null].filter(x => x !== null) },
                                    FormatColumn: { FormatColumns: [adaptableDataRef.current.style || null].filter(x => x !== null) },
                                }
                            }
                        }}
                    />
                </C.Flex>
            </BS.Col>
        </BS.Row>
    </div>
}

export default RegEquipments;