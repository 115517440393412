import React from "react";
import moment from "moment";
import * as I from "../types";
import * as BS from 'react-bootstrap';
import * as H from "../../../../hooks";
import * as C from "../../../../Common";
import { T, TB, TC } from "../../../../Constants";

export type Props = {};
export type FilterModel = Status[];
type Status = Validity["status"] | "blanks";
type Validity = T.API.Reg.RegTableItem["validityDate"];

const STATUS = [
    { value: "blanks", label: null },
    { value: "expired", label: TC.REG_VALIDITY_EXPIRED },
    { value: "less3months", label: TC.REG_VALIDITY_UNDER_THREE_MONTHS },
    { value: "ok", label: TC.REG_VALIDITY_OVER_THREE_MONTH },
    { value: "none", label: TC.REG_NO_FREQUENCY },
    { value: "missing", label: TC.REG_MISSING }
] as T.Option<{}, Status>[];

export const type = "agCustomRegValidityDate";
export const sort = (a: Validity, b: Validity) => TB.sortDates(a?.valid, b?.valid);

export const Cell = React.forwardRef<{}, I.CellProps<Props>>((props, ref) => {
    const lg = H.useLanguage();
    const value = React.useMemo<Validity>(() => props.getValue?.() || null, [props]);

    const [text, color] = React.useMemo(() => {
        let date = TB.getDate(value?.valid);
        let dateStr: string = null;
        if (date !== null) dateStr = moment(date).format("DD/MM/YYYY");

        if (value?.status === "expired") return [dateStr, "#FF7043"];
        else if (value?.status === "ok") return [dateStr, "#4EAE53"];
        else if (value?.status === "less3months") return [dateStr, "#FFA726"];
        else if (value?.status === "missing") return [lg.getStaticElem(TC.REG_MISSING), "#CF1F31"];
        else if (value?.status === "none") return [lg.getStaticElem(TC.REG_NO_FREQUENCY), "#4EAE53"];
        else return [null, ""];
    }, [value, lg]);

    return <C.Flex direction="column" className="text-center text-light fw-bold" children={<div style={{ backgroundColor: color }} children={text} />} />;
});

export const Filter = React.forwardRef<I.FilterRef<FilterModel>, I.FilterProps<Props>>((props, ref) => {
    const lg = H.useLanguage();
    const [selected, set_selected] = React.useState<Status[]>(STATUS.map(o => o.value));

    React.useImperativeHandle(ref, () => ({
        getModel: () => ({ value: selected }),
        setModel: model => set_selected(model?.value || []),
        isFilterActive: () => selected.length !== STATUS.length,
        doesFilterPass: params => {
            if (selected.length === 0) return false;
            let value = props.valueGetter({ ...props, node: params?.node, data: params?.node?.data, getValue: field => params?.node?.data[field] });
            // Check is value status is empty
            if (!value?.status) return selected.includes("blanks");
            else return selected.includes(value?.status);
        }
    }), [props, selected]);

    React.useEffect(() => props.filterChangedCallback?.(), [selected, props]);
    const toggle_status = React.useCallback((status: Status) => set_selected(p => p.includes(status) ? p.filter(x => x !== status) : p.concat(status)), []);

    return <div className='p-2'>
        {STATUS.map(o => <BS.InputGroup key={o.value} className="align-items-center mb-1">
            <BS.Form.Check checked={selected.includes(o.value)} onChange={() => toggle_status(o.value)} />
            <span className='ml-3 flex-grow-1 pointer' onClick={() => toggle_status(o.value)}>{o.label ? lg.getStaticText(o.label) : null}</span>
        </BS.InputGroup>)}
    </div>;
});