import React from "react";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as S from "../../../services";
import { TableProps, Table } from "../Grid";
import { CellsTypes as CT } from "../AgGridDefs";
import * as US from "../../../services/user.service";
import { FORMATS, T, TB, TC, LANG } from "../../../Constants";

const TEXT_CODES = [
    TC.GLOBAL_ERROR_OCCURRED, TC.VAL_ALREADY_EXISTS, TC.VAL_IS_REQUIRED, TC.VAL_NOT_UNIQUE, TC.VAL_NOT_ID,
    TC.TAB_TRANSLATIONS, TC.TRAD_TAB_IMPORT,
];

type Row = T.LanguageObj;

const TextUX: React.FC = () => {
    H.useAuth({ onlyAdmin: true });
    H.useCrumbs(TC.TAB_TRANSLATIONS);
    const lg = H.useLanguage(TEXT_CODES);
    const [translations, setTranslations, status] = H.useAsyncState<Row[]>([]);

    //#region Get Translations
    React.useEffect(() => {
        let isSubscribed = true;
        US.getTranslations()
            .then(({ data }) => isSubscribed && setTranslations(data, "done"))
            .catch(() => isSubscribed && setTranslations([], "error"));
        return () => { isSubscribed = false };
    }, [setTranslations]);
    //#endregion

    //#region Quick Insert
    const allRef = React.useMemo(() => translations.map(({ ref }) => ref).filter(TB.validString), [translations]);
    const _idPropExists = React.useCallback((_id: string, prop: string) => translations.filter(t => t._id === _id && t.prop === prop).length > 0, [translations]);

    const is_idPropDisabled = React.useCallback<C.QIGetDisabledFn>((a, b, valueRow) => TB.validString(valueRow?.ref), []);
    const isRefDisabled = React.useCallback<C.QIGetDisabledFn>((a, b, valueRow) => [valueRow?._id, valueRow?._prop].some(TB.validString), []);

    const checkRef = React.useCallback<C.QICheckFn>((value, otherValueProp, valueRow) => {
        if (isRefDisabled(value, otherValueProp, valueRow)) return true;
        if (!TB.validString(value)) return lg.getStaticText(TC.VAL_IS_REQUIRED, "ref");
        if (Array.isArray(otherValueProp) && otherValueProp.filter(v => v === value).length > 1) return lg.getStaticText(TC.VAL_NOT_UNIQUE, "ref");
        if (allRef.includes(value)) return lg.getStaticText(TC.VAL_ALREADY_EXISTS, "ref");
        return true;
    }, [allRef, isRefDisabled, lg]);

    const check_id = React.useCallback<C.QICheckFn>((value, otherValueProp, valueRow) => {
        if (is_idPropDisabled(value, otherValueProp, valueRow)) return true;
        if (!TB.mongoIdValidator(value)) return lg.getStaticText(TC.VAL_NOT_ID, "_id");
        if (!TB.validString(valueRow?.prop)) return lg.getStaticText(TC.VAL_IS_REQUIRED, "prop");
        if (_idPropExists(value, valueRow.prop)) return lg.getStaticText(TC.VAL_ALREADY_EXISTS, "Combination");
        return true;
    }, [_idPropExists, is_idPropDisabled, lg]);

    const checkProp = React.useCallback<C.QICheckFn>((value, otherValueProp, valueRow) => {
        if (is_idPropDisabled(value, otherValueProp, valueRow)) return true;
        if (!TB.validString(value)) return lg.getStaticText(TC.VAL_IS_REQUIRED, "prop");
        if (!TB.mongoIdValidator(valueRow?._id)) return lg.getStaticText(TC.VAL_IS_REQUIRED, "_id");
        if (_idPropExists(value, valueRow.prop)) return lg.getStaticText(TC.VAL_ALREADY_EXISTS, "Combination");
        return true;
    }, [_idPropExists, is_idPropDisabled, lg]);

    const insertRowFormat = React.useMemo<C.QIRowFormat[]>(() => {
        let idCols: C.QIRowFormat[] = [
            { propName: "ref", colLabel: "ref", placeholder: "ref", getDisabled: isRefDisabled, check: checkRef },
            { propName: "_id", colLabel: "_id", placeholder: "_id", getDisabled: is_idPropDisabled, check: check_id },
            { propName: "prop", colLabel: "prop", placeholder: "prop", getDisabled: is_idPropDisabled, check: checkProp },
        ];

        let textCols = LANG.ALL_LANGUAGES.map(({ prop }) => ({ propName: prop, colLabel: prop, placeholder: prop }));
        return idCols.concat(textCols);
    }, [isRefDisabled, is_idPropDisabled, checkProp, checkRef, check_id]);

    const inputRenderProps = React.useMemo(() => ({ rowFormat: insertRowFormat, forceFocus: true, title: "QuickInsert", isFullScreen: true }), [insertRowFormat]);

    const quickBulkAdd = React.useCallback(() => {
        C.renderQuickInput(inputRenderProps).then(values => {
            if (Array.isArray(values)) US.createManyLanguages(values).then(({ data }) => {
                if (Array.isArray(data)) setTranslations(p => p.concat(data));
            }).catch(() => M.renderAlert({ type: "error", message: TC.GLOBAL_ERROR_OCCURRED }))
        });
    }, [inputRenderProps, setTranslations]);
    //#endregion

    //#region Events
    const edits = React.useMemo(() => ({
        remove: (row: Row) => {
            if (typeof row?.id === "number" && row.id > 0) {
                M.askConfirm().then(confirmed => {
                    if (confirmed) US.removeLanguages(row.id).then(({ data }) => {
                        if (data?.ok === 1) setTranslations(p => p.filter(t => t.id !== row.id));
                        else M.renderAlert({ type: 'error', message: TC.GLOBAL_ERROR_OCCURRED });
                    })
                })
            }
        },
        import: () => M.renderExcelMapper({ columnFormat: FORMATS.TRANSLATION.format, title: TC.TRAD_TAB_IMPORT }).then(format => {
            if (format) S.importTrad(format)
                .then(({ data }) => console.log(data))
                .catch(() => M.renderAlert({ type: "error", message: TC.GLOBAL_FILE_READ_ERROR }));
        }),
    }), [setTranslations]);
    //#endregion

    //#region Context Menu
    const getContextMenu = React.useCallback<TableProps<Row>["getContextMenuItems"]>(params => {
        let translation = params.node?.data;

        let customItems = [
            { name: "Quick Inserts", action: quickBulkAdd, icon: "<i class='fa text-warning fa-bolt'></i>" },
            { name: lg.getStaticText(TC.TRAD_TAB_IMPORT), action: edits.import, icon: "<i className='fa fa-plus'></i>" },
            { name: "Delete", action: () => edits.remove(translation), icon: "<i class='fa text-danger fa-times'></i>" },
        ] as ReturnType<typeof getContextMenu>;

        if (Array.isArray(params.defaultItems)) customItems.push("separator", ...params.defaultItems);
        return customItems;
    }, [quickBulkAdd, lg, edits]);
    //#endregion

    //#region Value Change
    const onValueChanged = React.useCallback<TableProps<Row>["onValueChange"]>(params => {
        let field = params.column.getColId(), id = params.data?.id;
        let newValue = TB.getString(params.newValue);

        if (typeof id === "number" && id > 0 && TB.validString(field)) {
            US.updateLanguage(id, { [field]: newValue }).then(({ data }) => {
                if (data?.ok === 1) setTranslations(p => p.map(t => t.id === id ? { ...t, [field]: newValue } : t));
                else M.renderAlert({ type: "error", message: TC.GLOBAL_ERROR_OCCURRED });
            }).catch(M.Alerts.updateError);
        }
    }, [setTranslations]);
    //#endregion

    //#region Columns
    const columns = React.useMemo<TableProps<Row>["columns"]>(() => [
        { field: "id", headerName: "Id", colId: "id", editable: false, type: CT.TYPE_NUMBER },
        { field: "ref", headerName: "Reference", editable: false },
        { field: "_id", headerName: "_id", editable: false, hide: true },
        { field: "prop", headerName: "Prop", editable: false, hide: true },
        { field: "fr_be", headerName: "fr_be" },
        { field: "fr_fr", headerName: "fr_fr" },
        { field: "en_us", headerName: "en_us" },
        { field: "en_uk", headerName: "en_uk" },
        { field: "nl", headerName: "nl" },
        { field: "de", headerName: "de" },
        { field: "it", headerName: "it" },
        { field: "es", headerName: "es" },
        { field: "ar", headerName: "ar" },
        { field: "last_update", headerName: "last_update", editable: false, type: CT.TYPE_DATE },
        { field: "update_method", headerName: "update_method", editable: false },
    ], []);
    //#endregion

    return <C.Flex direction="column" className="flex-grow-1">
        <div className="w-100 flex-grow-1">
            <Table<Row>
                sideBar
                status={status}
                columns={columns}
                rows={translations}
                adaptableId="lang_table"
                onValueChange={onValueChanged}
                getContextMenuItems={getContextMenu}
                columns_base={React.useMemo(() => ["editable", "sortable", "filterable"], [])}
            />
        </div>
    </C.Flex>;
}

export default TextUX;