import { arrayWrapper } from "./array";
import { validString } from "./string";
import { mongoIdValidator } from "./id";
import { T } from "../../../../Constants";

/** Put a context in a valid format for a GET request */
export const toStringContext = (context: T.ContextParams): T.StringContextParams => ({ roots: arrayWrapper(context?.roots).join(), portfolio: context?.portfolio });

/** Transform a Context from a GET request to a regular context */
export const fromStringContext = (context: T.StringContextParams): T.ContextParams => ({
    portfolio: mongoIdValidator(context?.portfolio) ? context.portfolio : null,
    roots: validString(context?.roots) ? context.roots.split(",").filter(mongoIdValidator) : null,
});