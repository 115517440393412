import axios from "axios";
import { T, TB, URL } from "../../Constants";
import * as FTB from "../../Constants/FileToolBox";

const { FILE_API } = URL;

//#region Types
type GetAppTitle = () => T.AxiosPromise<string>;
// type CreateZip = T.API.ToAxios<T.API.Utils.Files.CreateZip>;
type GetTempFile = (fileName: string) => T.AxiosPromise<Buffer>;
type DeleteTempFile = (fileName: string) => T.AxiosPromise<string>;
type TempFileExists = (fileName: string) => T.AxiosPromise<boolean>;
type PostTempFile = (fileName: string, file: string | File) => T.AxiosPromise<string>;
type MoveTempFile = (params: Record<"name" | "id", string>) => T.AxiosPromise<string>;

type CreateZip = T.API.ToPromise<(params: {
    /** The content of the zip file */
    content: Parameters<T.API.Utils.Files.CreateZip>[0];
    /** The name of the zip archive */
    name: string;
}) => void>;

type GetXML = T.API.ToPromise<(params: {
    root: string;
    fileName: string;
    exportFiles?: boolean;
    forms?: T.AllowArray<string>;
    linkTypes?: T.AllowArray<string>;
}) => void>;
//#endregion

export const getAppTitle: GetAppTitle = () => axios.get(FILE_API + "title");
export const getTempFile: GetTempFile = (name) => axios.get(FILE_API + "temp/" + name);
export const deleteTempFile: DeleteTempFile = (name) => axios.delete(FILE_API + "temp/" + name);
export const tempFileExists: TempFileExists = name => axios.get(FILE_API + "temp_exists/" + name);
/** Move a temporary file to a persistent location */
export const moveTempFile: MoveTempFile = params => axios.get(FILE_API + `temp_move/${params?.name || ""}/${params?.id || ""}`);


export const postTempFile: PostTempFile = (name, file) => new Promise((resolve, reject) => {
    FTB.fileForSend(file).then(formData => axios.post(FILE_API + "temp/" + name, formData)
        .then(resolve).catch(reject)
    ).catch(reject);
});

export const generateXML: GetXML = params => new Promise((resolve, reject) => {
    // Generate XML file
    axios.post(FILE_API + "xml", params, { responseType: "arraybuffer" }).then(({ data }) => {
        // Cast ArrayBuffer to base 64
        TB.asyncArrayBufferToBase64(data).then(base64 => {
            // Download the file
            TB.downloadFile(base64, params?.fileName || "export", "xml", true);
            resolve();
        }).catch(reject);
    }).catch(reject)
});

/** Create a zip file containing multiple other files */
export const createZip: CreateZip = params => new Promise((resolve, reject) => {
    // Generate ZIP file
    axios.post(FILE_API + "zip", { content: params?.content }, { responseType: "arraybuffer" }).then(({ data }) => {
        // Cast ArrayBuffer to base64
        TB.asyncArrayBufferToBase64(data).then(base64 => {
            // Download the file
            TB.downloadFile(base64, params?.name || "ZIP", "zip", true);
            resolve();
        }).catch(reject);
    }).catch(reject);
});