import React from "react"
import { Equipment } from "../Gestion";
import { TC, TB } from "../../Constants";
import { ErrorBanner } from "../../Common";

type EquipArchitectureProps = {
    buildingId?: string;
}

const EquipArchitecture: React.FC<EquipArchitectureProps> = ({ buildingId, ...props }) => <div className="h-100">
    {TB.mongoIdValidator(buildingId) && <Equipment context={{ roots: buildingId }} className="h-100" allow_import />}
    {!TB.mongoIdValidator(buildingId) && <ErrorBanner type="danger" textCode={TC.BUILD_EDIT_INVALID_BUILD} />}
</div>;

export default EquipArchitecture;