import "./Ticket.scss";
import { Form } from "../Form";
import { FP, TB, TC } from "../../Constants";
import { useLocation } from 'react-router-dom';
import * as US from "../../services/user.service";
import CardHeader from "../cards/card-header/CardHeader";
import { useAuth, useFormIds, useLanguage } from "../../hooks";
import { BLANK_FORMIO_FORMAT } from '../../Constants/Constants';
import { useState, useEffect, useMemo, useCallback } from "react";

const TEXT_CODES = [TC.TICKET, TC.LOADING, TC.GLOBAL_SAVE, TC.LOADING, TC.GLOBAL_ERROR, TC.NOTIF_DIA_ERR_UPDATE_MESSAGE];

const CreatTicket = ({ ...props }) => {
  const [forms] = useFormIds();
  const { state } = useLocation();
  const [{ user }, { navigate }] = useAuth();
  const [equipment, setEquipment] = useState();
  const { getStaticText } = useLanguage(TEXT_CODES);
  const [submissionDone, setSubmissionDone] = useState(false);
  const equipmentId = useMemo(() => state?.equipmentId, [state]);

  //#region User
  useEffect(() => submissionDone === true ? navigate('/', { replace: true }) : undefined, [submissionDone, navigate]);
  useEffect(() => TB.mongoIdValidator(equipmentId) ? undefined : navigate('/', { replace: true }), [equipmentId, navigate]);
  //#endregion

  //#region DB Fetches
  useEffect(() => {
    const getEquipment = async () => {
      let reply = await US.getSubmission(equipmentId);
      return (TB.mongoIdValidator(reply.data?._id) ? reply.data : null);
    }

    if (TB.mongoIdValidator(equipmentId)) getEquipment().then(setEquipment);
    else setEquipment(null);
  }, [equipmentId]);
  //#endregion

  //#region Form Handling
  const ticketFormId = useMemo(() => forms[FP.TICKET_FORM], [forms]);

  const defaultTicket = useMemo(() => ({
    technicians: [],
    equipment: equipmentId,
    calendar: [],
    internal: false,
  }), [equipmentId]);

  const handleForm = useCallback(async ({ _id, data }) => {
    const deleteNote = () => {
      // Delete the note because the ticket creation failed or the note update failed
      US.removeSubmission(_id);
      setSubmissionDone(null);
    }

    let ticketObj = BLANK_FORMIO_FORMAT(user?._id, ticketFormId);
    ticketObj.data = Object.assign(defaultTicket, { title: data?.subject });

    // Create the ticket
    let replyInsert = await US.createManySubmissions([ticketObj]);
    if (Array.isArray(replyInsert.data) && TB.mongoIdValidator(replyInsert.data?.[0]?._id)) {
      // Update the note
      let reply = await US.updateSubmission(_id, { 'data.origin': replyInsert.data[0]._id });
      if (!TB.mongoIdValidator(reply.data?._id)) deleteNote();
      else setSubmissionDone(true);
    }
    else deleteNote();
  }, [user, ticketFormId, defaultTicket]);
  //#endregion

  //#region CardHeader
  const header = useMemo(() => <div>
    <h4>{getStaticText(TC.TICKET)} : {equipment?.data?.name || getStaticText(TC.LOADING)}</h4>
  </div>, [getStaticText, equipment]);

  const footer = useMemo(() => <button className="btn btn-primary" onClick={() => document.querySelector(".ticketPage button.btn.btn-primary.btn-md")?.click?.()} >
    {getStaticText(TC.GLOBAL_SAVE)}
  </button>, [getStaticText]);
  //#endregion

  //#region Loading & Errors
  const allLoaded = useMemo(() => TB.multiMongoIdValidator([equipmentId, equipment?._id]), [equipmentId, equipment]);
  const errorLoading = useMemo(() => [equipment].includes(null), [equipment]);
  //#endregion

  return <div className="ticketPage">
    <CardHeader header={header} footer={footer}>
      {/* Form */}
      {[false, undefined].includes(submissionDone) && <div className="formBefore">
        {!allLoaded && !errorLoading && <p>{getStaticText(TC.LOADING)}</p>}

        {errorLoading && <p>{getStaticText(TC.GLOBAL_ERROR)}</p>}

        {allLoaded && !errorLoading && <Form
          path={FP.NOTES_PATH}
          onSave={handleForm}
        />}
      </div>}

      {/* After submission */}
      {[true, null].includes(submissionDone) && <div className="after">
        {submissionDone === null && <p>{getStaticText(TC.NOTIF_DIA_ERR_UPDATE_MESSAGE)}</p>}
      </div>}
    </CardHeader>
  </div>
}

export default CreatTicket;
