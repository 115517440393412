import React from 'react'

function useIsMounted() {
    const isMounted = React.useRef(false);

    React.useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false };
    }, [])

    return React.useCallback(() => isMounted.current, [])
}

export default useIsMounted
