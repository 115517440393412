import { Carousel, CarouselProps } from "../Components";
import renderInContainer from "../getContainer";

export type RenderCarousel = (params: Omit<CarouselProps, "onQuit">) => Promise<void>;

const renderCarousel: RenderCarousel = params => new Promise(resolve => {
    const [render, dismount] = renderInContainer();
    if (render && dismount) {
        let onQuit = () => dismount(() => resolve());
        render(<Carousel {...params} onQuit={onQuit} />);
    }
    else resolve();
});

export default renderCarousel;