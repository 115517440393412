import axios from "axios";
import { T, URL } from "../../Constants";

const { DASHBOARD_API } = URL;

type GetRenters = T.API.ToAxios<T.API.Report.Dashboard.GetRenters>;
type GetCellsData = T.API.ToAxios<T.API.Report.Dashboard.GetCellsData>;
type GetRenterGlobal = T.API.ToAxios<T.API.Report.Dashboard.GetRenterGlobal>;
type GetSustainabilityData = T.API.ToAxios<T.API.Report.Dashboard.GetSustainabilityData>;

/** Get a list of renters in a context */
export const getRenters: GetRenters = context => axios.post(DASHBOARD_API + "renters", context);
/** Get more global data on a renter */
export const getRenterGlobal: GetRenterGlobal = params => axios.post(DASHBOARD_API + "renter", params);
/** Get the first found area of a building */
export const getSustainabilityData: GetSustainabilityData = build => axios.get(DASHBOARD_API + "build/sustainability", { params: { build } });
/** Get the cells options for a building, and other data */
export const getCellsData: GetCellsData = build => axios.get(DASHBOARD_API + "build/cells", { params: { build } });