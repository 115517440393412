import React from "react";
import * as I from "../types";
import * as BS from "react-bootstrap";
import * as H from "../../../../hooks";
import * as C from "../../../../Common";
import { T, TB, TC } from "../../../../Constants";

//#region Types
export type Props = {
    /** The unit to display next to the number */
    unit?: string;
    /** Format the value */
    formatted?: boolean | "money";
    /** The max amount of digits to show */
    maxDigit?: number;
    /** The minimum value accepted when edited */
    min?: number;
    /** The maximum value accepted when edited */
    max?: number;
}

export type FilterModel =
    /** The conditions on the number, with the operation index in the operators array, a range if necessary or the value to test against */
    Record<"operatorOne" | "operatorTwo", Record<"index", number> & Partial<Record<"value" | "from" | "to", number>>>
    /** Are the two conditions linked with an "AND" operator. If false, the operator is "OR" */
    & Record<"isAndUnifier", boolean>;

type Operator = {
    /** The name of the operation */
    label: string;
    /** The symbol that describes what the operation does */
    symbol?: string;
    /** Does this operator needs a range to be performed */
    range: boolean;
    /** Does this operator only tests the presence or not of a value */
    blank: boolean;
    /** The function that tests the operator on the values */
    comparator?: (value: number, tested_value: number) => boolean;
    /** The function that tests if a value is within a range */
    test_range?: typeof TB.isInRange;
    /** The function that test the presence of a number */
    test_blank?: (value: number) => boolean;
}

type Unifier = "and" | "or";
//#endregion

//#region Constants
const operators: Operator[] = [
    { label: TC.GRID_FILTER_OP_EQ, symbol: "=", blank: false, range: false, comparator: (v, vt) => v === vt },
    { label: TC.GRID_FILTER_OP_NOT_EQ, symbol: "!=", blank: false, range: false, comparator: (v, vt) => v !== vt },
    { label: TC.GRID_FILTER_OP_LT, symbol: "<", blank: false, range: false, comparator: (v, vt) => vt === undefined ? false : vt < v },
    { label: TC.GRID_FILTER_OP_LTE, symbol: "<=", blank: false, range: false, comparator: (v, vt) => vt === undefined ? false : vt <= v },
    { label: TC.GRID_FILTER_OP_GT, symbol: ">", blank: false, range: false, comparator: (v, vt) => vt === undefined ? false : vt > v },
    { label: TC.GRID_FILTER_OP_GTE, symbol: ">=", blank: false, range: false, comparator: (v, vt) => vt === undefined ? false : vt >= v },
    { label: TC.GRID_FILTER_OP_RANGE, blank: false, range: true, test_range: TB.isInRange },
    { label: TC.GRID_FILTER_OP_BLANK, blank: true, range: false, test_blank: v => typeof v !== "number" || isNaN(v) },
    { label: TC.GRID_FILTER_OP_NOT_BLANK, blank: true, range: false, test_blank: v => typeof v === "number" && !isNaN(v) },
];

const check = {
    filter: (op: FilterModel["operatorOne"]) => {
        let operator = operators[op?.index];
        // No operator currently selected
        if (!operator) return false;
        let is_ok_blank = operator.blank,
            is_ok_value = !operator.range && !operator.blank && typeof op.value === "number" && !isNaN(op.value),
            is_ok_range = operator.range && typeof op.from === "number" && typeof op.to === "number" && !isNaN(op.from) && !isNaN(op.to);
        return is_ok_blank || is_ok_range || is_ok_value;
    },
    value: (operator: FilterModel["operatorOne"], value?: number) => {
        let passed = true, comparator = operators[operator.index];
        if (comparator.blank) passed = comparator.test_blank(value);
        else if (comparator.range && typeof value === "number" && !isNaN(value)) passed = comparator.test_range(operator.from, operator.to, value);
        else if (typeof value === "number" && !isNaN(value)) passed = comparator.comparator(operator.value, value);
        else passed = false;
        return passed;
    },
};
//#endregion

export const sort = TB.sortNumbers;
export const type = "agCustomNumber";

export const Cell = React.forwardRef<{}, I.CellProps<Props>>((props, ref) => {
    const content = React.useMemo(() => {
        let is_group = props?.node?.group,
            params = props.colDef?.params || {},
            number = TB.getNumber(props.getValue?.()),
            max_digits = TB.getNumber(params.maxDigit);

        if (is_group) {
            // Maybe show nothing when grouped
            if (!params?.show_grouped) {
                // There might be an aggregate active
                if (typeof props.value === "number" && !isNaN(props.value)) number = props.value;
                else return "";
            }
            else {
                // Sum up all the values found in the group
                let field = props.colDef.field;
                let values = props.node.allLeafChildren.map(c => TB.getNumber(c?.data?.[field], 0));
                number = values.reduce((prev, current) => prev + current, 0);
            }
        }
        // Fix the amount of digits after the comma
        if (number && max_digits) number = parseFloat(number.toFixed(max_digits));
        // Format the number
        if (params.formatted === "money") return TB.moneyFormat(number) || null;
        else if (params.formatted) return TB.numberFormat(number, params?.unit) || null;
        else if (isNaN(number)) return null;
        else return number;
    }, [props]);

    return <div className="text-center" children={content} />;
});

export const Filter = React.forwardRef<I.FilterRef<FilterModel>, I.FilterProps<Props>>((props, ref) => {
    const lg = H.useLanguage();
    const use_and = H.useBoolean(false);
    const [op_2, set_op_2] = React.useState<FilterModel["operatorTwo"]>(null);
    const [op_1, set_op_1] = React.useState<FilterModel["operatorOne"]>({ index: 0 });

    const active = React.useMemo(() => ({
        op_1: check.filter(op_1),
        op_2: check.filter(op_2),
    }), [op_1, op_2]);

    const options = React.useMemo(() => ({
        operators: operators.map((o, i) => ({ value: i.toString(), ...o })),
        unifiers: [
            { label: TC.GRID_FILTER_AND, value: "and" },
            { label: TC.GRID_FILTER_OR, value: "or" },
        ] as T.Option<{}, Unifier>[],
    }), []);

    const events = React.useMemo(() => ({
        operation: (is_one: boolean, str_index?: string) => {
            // Convert the new selected index into a number, or NaN if it wasn't a number
            let index = TB.getNumber(str_index);
            // No operator selected, reset the filter
            if (isNaN(index)) {
                if (is_one) set_op_1(null);
                set_op_2(null);
            }
            else {
                let operator = operators[index];
                let setter = is_one ? set_op_1 : set_op_2;
                // The new operator is a range operator, reuse previous range values if they exists
                if (operator.range) setter(p => ({ index, from: p?.from, to: p?.to }));
                // The new operator is a blank operator
                else if (operator.blank) setter({ index });
                // The new operator is a regular operator, reuse previous range values if they exists
                else setter(p => ({ index, value: p?.value }));
            }
        },
        set_unifier: (value: Unifier) => use_and.setValue(value === "and"),
        set_value: (is_one: boolean, value?: number) => (is_one ? set_op_1 : set_op_2)(p => ({ ...p, value })),
        set_range: (is_one: boolean, is_from: boolean, range?: number) => (is_one ? set_op_1 : set_op_2)(p => ({ ...p, [is_from ? "from" : "to"]: range })),
    }), [use_and]);

    const render = React.useMemo(() => ({
        select_op: (is_op_1: boolean) => <C.Form.Select
            noBottomMargin
            typeahead={{ size: "sm" }}
            options={options.operators}
            disabled={is_op_1 ? false : !active.op_1}
            onChange={index => events.operation(is_op_1, index)}
            value={(is_op_1 ? op_1 : op_2)?.index?.toString?.()}
            label={lg.getStaticText(TC.GRID_FILTER_OPERATOR_LABEL, is_op_1 ? 1 : 2)}
        />,
        value_field: (is_op_1: boolean) => {
            let op = is_op_1 ? op_1 : op_2;
            let operator = operators[op?.index];
            // The operator is not assigned, or it's a blank operator
            if (!operator || operator.blank) return null;
            // It's a range operator
            if (operator.range) return <BS.Row className="g-1">
                <BS.Col>
                    <C.Form.NumField
                        noBottomMargin
                        value={op.from}
                        label={TC.GLOBAL_FROM}
                        onChange={value => events.set_range(is_op_1, true, value)}
                    />
                </BS.Col>
                <BS.Col>
                    <C.Form.NumField
                        value={op.to}
                        noBottomMargin
                        label={TC.GLOBAL_TO}
                        onChange={value => events.set_range(is_op_1, false, value)}
                    />
                </BS.Col>
            </BS.Row>;
            // It's a regular operator
            else return <C.Form.NumField
                noBottomMargin
                value={op.value}
                label={TC.GLOBAL_VALUE}
                onChange={value => events.set_value(is_op_1, value)}
            />;
        },
    }), [events, lg, op_1, op_2, active.op_1, options.operators]);

    React.useImperativeHandle(ref, () => ({
        isFilterActive: () => active.op_1 || active.op_2,
        getModel: () => ({ value: { isAndUnifier: use_and.value, operatorOne: op_1, operatorTwo: op_2 } }),
        setModel: model => {
            set_op_1(model?.value?.operatorOne || null);
            set_op_2(model?.value?.operatorTwo || null);
            use_and.setValue(typeof model?.value?.isAndUnifier === "boolean" ? model?.value?.isAndUnifier : true);
        },
        doesFilterPass: params => {
            // Get the value for the examined row
            let value = props.valueGetter({ ...props, node: params?.node, data: params?.node?.data, getValue: field => params?.node?.data?.[field] });
            // Is the condition true
            let passed_1 = check.value(op_1, value);
            // There is no second condition, so the first one is the only one to care about
            if (!op_2) return passed_1;
            else {
                let passed_2 = check.value(op_2, value);
                return use_and.value ? passed_1 && passed_2 : passed_1 || passed_2;
            }
        },
    }), [use_and, op_1, op_2, active, props]);

    React.useEffect(() => props.filterChangedCallback?.(), [op_1, op_2, use_and.value, props]);

    return <div className="p-1">
        <BS.Stack gap={2}>
            {/* The first operator selector */}
            {render.select_op(true)}
            {/* The first operator value selector */}
            {render.value_field(true)}
            {/* The selection of 'unifier' between the 2 conditions */}
            <C.Form.Select
                no_clear_btn
                noBottomMargin
                disabled={!active.op_1}
                options={options.unifiers}
                typeahead={{ size: "sm" }}
                onChange={events.set_unifier}
                label={TC.GRID_FILTER_UNIFIER}
            />
            {/* The second operator selector */}
            {render.select_op(false)}
            {/* The second operator value selector */}
            {render.value_field(false)}
        </BS.Stack>
    </div>
});

export const EditCell = React.forwardRef<I.EditorRef<number>, I.EditorProps<Props>>((props, ref) => {
    const container = React.useRef<HTMLDivElement>(null);
    const input = React.useRef<C.Form.NumFieldRef>(null);
    H.useOnClickOutside(container, () => props.stopEditing?.());
    const [value, set_value] = React.useState<number>(props.value);
    React.useImperativeHandle(ref, () => ({ getValue: () => value }), [value]);
    React.useEffect(() => input?.current?.single?.current?.input?.current?.focus?.(), []);

    const change = React.useCallback((value?: number) => {
        let params = props.colDef?.params || {};
        if (typeof value !== "number" || isNaN(value)) set_value(NaN);
        else if (typeof params?.min === "number" && !isNaN(params.min) && value < params.min) set_value(params.min);
        else if (typeof params?.max === "number" && !isNaN(params.max) && value < params.max) set_value(params.max);
        else set_value(value);
    }, [props.colDef]);

    return <div ref={container}>
        <C.Form.NumField
            ref={input}
            noBottomMargin
            value={props.value}
            onChangeUncontrolled={change}
        />
    </div>;
});