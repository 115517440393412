import React from "react";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import * as S from "../../../services";
import * as R from "../../../reducers";
import { useDispatch } from "react-redux";
import { LANG, MISC, T, TC } from "../../../Constants";

//#region Types
export type InfosBubbleProps = {
    /** Show the form in a popup */
    popup?: boolean;
    /** Props for the modal */
    modalProps?: M.StyleModalProps;
    /** The id of an existing bubble, to be edited */
    id?: string;
    /** Callback after the bubble is created or edited */
    on_save?: (bubble?: T.InfosBubble) => void;
}

type BaseBubble = Parameters<T.API.Access.User.SaveInfoBubble>[0];
//#endregion

const Options = MISC.InfoBubblesOptions;

const InfosBubble: React.FC<InfosBubbleProps> = ({ on_save, ...props }) => {
    const lg = H.useLanguage();
    const dispatch = useDispatch();
    const is_saving = H.useBoolean(false);
    const [infos, set_infos, status] = H.useAsyncState<BaseBubble>(null);
    const [pages, set_pages, pages_status] = H.useAsyncState<T.Option[]>([]);
    const [errors, set_errors] = React.useState<T.Errors<BaseBubble["bubble"]>>({});

    React.useEffect(() => {
        let isSubscribed = true;

        let default_data: BaseBubble = {
            bubble: {
                title: "",
                size: "md",
                message: "",
                page: "any",
                duration: null,
                variant: "info",
                dismissed_by: [],
                expiration_date: null,
            },
            translations: {
                title: Object.fromEntries(LANG.ALL_PROPS.map(l => [l, ""])) as BaseBubble["translations"]["title"],
                message: Object.fromEntries(LANG.ALL_PROPS.map(l => [l, ""])) as BaseBubble["translations"]["message"],
            }
        }

        if (props.id) S.getInfoBubble(props.id)
            .then(({ data }) => isSubscribed && set_infos(data, "done"))
            .catch(() => isSubscribed && set_infos(default_data, "error"));
        else set_infos(default_data, "done");

        return () => {
            isSubscribed = false;
            set_infos(default_data, "load");
        }
    }, [set_infos, props.id]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getEquipmentResource({ type: "app_pages" })
            .then(({ data }) => isSubscribed && set_pages(data as T.Option[], "done"))
            .catch(() => isSubscribed && set_pages([], "error"));
        return () => {
            isSubscribed = false;
            set_pages([], "load");
        }
    }, [set_pages]);

    const options = React.useMemo(() => ({
        size: Options.size,
        variant: Options.variant,
        pages: [{ label: TC.BUBBLE_FORM_ANY_PAGE_OPT, value: "any" }].concat(pages),
    }), [pages]);

    const events = React.useMemo(() => ({
        set_title: (title?: string) => set_infos(p => ({
            ...p,
            bubble: { ...p.bubble, title },
            translations: { ...p.translations, title: { ...p.translations.title, [lg.prop]: title } },
        })),
        set_message: (message?: string) => set_infos(p => ({
            ...p,
            bubble: { ...p.bubble, message },
            translations: { ...p.translations, message: { ...p.translations.message, [lg.prop]: message } },
        })),
        test_render: () => {
            let html = infos?.bubble?.message || "";
            M.renderBlankModal({ title: TC.BUBBLE_FORM_TEST_RENDER, children: <C.HtmlText html={html} /> });
        },
        translate: () => {
            let rows = [infos.translations.title, infos.translations.message];
            let modal_ref: C.QuickInputProps2<typeof rows[number]>["modal_ref"] = { current: null };
            let columns: C.QuickInputProps2<typeof rows[number]>["columns"] = LANG.ALL_LANGUAGES.map(l => ({ type: "text", prop: l.prop, label: l.lg }));
            // Replace the current language with the value from the form
            rows[0][lg.prop] = infos.bubble.title;
            rows[1][lg.prop] = infos.bubble.message;

            const auto_translate = () => {
                let values = modal_ref.current?.get() || [];
                let complete_lang = LANG.ALL_PROPS.find(l => values.every(v => typeof v[l] === "string" && v[l].trim().length > 0));
                // No lang was had a translation for all rows, notify user
                if (!complete_lang) M.renderAlert({ type: "warning", message: TC.AUTO_TRANSLATE_NO_FULL_BASE });
                else {
                    const unmount = M.renderLoader();
                    let texts = values.map(v => v[complete_lang]);
                    S.translateManyText({ texts, lang: complete_lang }).then(({ data }) => {
                        let new_rows = values.map((value, index) => {
                            let result = data[index];
                            let non_empty_value = Object.fromEntries(
                                Object.entries(value).filter(([lang, text]) => typeof text === "string" && text.length > 0)
                            );
                            if (result === "failed") return value;
                            else return { ...result, ...non_empty_value, };
                        });
                        modal_ref.current?.set(new_rows as any);
                    })
                        .catch(M.Alerts.loadError)
                        .finally(unmount);
                }
            };

            let footer = <C.Button variant="info" icon="robot" text={TC.AUTO_TRANSLATE} onClick={auto_translate} />

            M.renderQuickInput<typeof rows[number]>({ columns, modal_ref, gel_rows: true, rows, footer, modal: { size: "lg", title: TC.BUBBLE_FORM_MANUAL_TRANSLATION } }).then(labels => {
                if (labels) set_infos(p => ({
                    translations: { title: labels[0], message: labels[1] },
                    bubble: { ...p.bubble, title: labels[0][lg.prop], message: labels[1][lg.prop] },
                }));
            });
        },
    }), [lg.prop, infos?.bubble, infos?.translations, set_infos]);

    const labels = React.useMemo(() => ({
        title: <C.Flex alignItems="center" justifyContent="between">
            {lg.getStaticText(TC.BUBBLE_FORM_TITLE_PROP)}
            <C.Button className="mb-2" size="sm" icon="language" onClick={events.translate} text={TC.BUBBLE_FORM_MANUAL_TRANSLATION} />
        </C.Flex>,
        message: <C.Flex alignItems="center" justifyContent="between">
            {lg.getStaticText(TC.BUBBLE_FORM_MESSAGE)}
            <div className="mb-2">
                <C.Button variant="info" size="sm" icon="eye" text={TC.BUBBLE_FORM_TEST_RENDER} onClick={events.test_render} />
                <C.Button className="ms-2" size="sm" icon="language" onClick={events.translate} text={TC.BUBBLE_FORM_MANUAL_TRANSLATION} />
            </div>
        </C.Flex>,
    }), [events, lg]);

    const save = React.useCallback(() => {
        let new_errors = {} as typeof errors;
        if (!infos.bubble.page) new_errors.page = TC.GLOBAL_REQUIRED_FIELD;
        if (!infos.bubble.size) new_errors.size = TC.GLOBAL_REQUIRED_FIELD;
        if (!infos.bubble.title) new_errors.title = TC.GLOBAL_REQUIRED_FIELD;
        if (!infos.bubble.message) new_errors.message = TC.GLOBAL_REQUIRED_FIELD;
        if (!infos.bubble.variant) new_errors.variant = TC.GLOBAL_REQUIRED_FIELD;

        if (Object.keys(new_errors).length > 0) set_errors(new_errors);
        else {
            is_saving.setTrue();
            S.saveInfoBubble(infos).then(r => {
                let languages: T.LanguageObj[] = [];
                // Set the title translation
                languages.push({ id: NaN, _id: r.data._id, prop: "title", last_update: new Date().toISOString(), update_method: "M", ...infos.translations.title });
                // Set the message translation
                languages.push({ id: NaN, _id: r.data._id, prop: "message", last_update: new Date().toISOString(), update_method: "M", ...infos.translations.message });
                // Update the redux translations
                dispatch(R.sync_update_objects_lg({ type: infos.bubble._id ? "update_object" : "add_object", languages }));
                // Fire the callback
                on_save?.(r.data)
            }).catch(M.Alerts.updateError);
        }
    }, [is_saving, infos, on_save, dispatch]);

    const footer = React.useMemo(() => <C.Flex justifyContent="end">
        <C.Button icon="save" onClick={save} text={TC.GLOBAL_SAVE} />
    </C.Flex>, [save]);

    //#region Errors
    React.useEffect(() => {
        if (errors.page && infos?.bubble?.page) set_errors(e => ({ ...e, page: undefined }));
    }, [errors.page, infos?.bubble?.page]);

    React.useEffect(() => {
        if (errors.size && infos?.bubble?.size) set_errors(e => ({ ...e, size: undefined }));
    }, [errors.size, infos?.bubble?.size]);

    React.useEffect(() => {
        if (errors.title && infos?.bubble?.title) set_errors(e => ({ ...e, title: undefined }));
    }, [errors.title, infos?.bubble?.title]);

    React.useEffect(() => {
        if (errors.message && infos?.bubble?.message) set_errors(e => ({ ...e, message: undefined }));
    }, [errors.message, infos?.bubble?.message]);

    React.useEffect(() => {
        if (errors.variant && infos?.bubble?.variant) set_errors(e => ({ ...e, variant: undefined }));
    }, [errors.variant, infos?.bubble?.variant]);
    //#endregion

    return React.createElement(
        props.popup ? M.BlankModal : React.Fragment,
        props.popup ? {
            ...props.modalProps,
            footer,
            autofocus: false,
            disableEnforceFocus: true,
            onQuit: () => on_save?.(),
            size: props.modalProps?.size || "md",
            title: props.modalProps?.title || TC.BUBBLE_FORM_POPUP_TITLE,
            maxBodyHeight: props.modalProps?.maxBodyHeight || "75vh",
        } as M.BlankModalProps : null,
        <C.Spinner status={status}>
            <BS.Row className="g-1">
                {/* Page selector */}
                <BS.Col md={6}>
                    <C.Form.Select
                        no_clear_btn
                        error={errors.page}
                        options={options.pages}
                        label={TC.BUBBLE_FORM_PAGE}
                        value={infos?.bubble?.page}
                        loading={pages_status === "load"}
                        onChange={page => set_infos({ ...infos, bubble: { ...infos.bubble, page } })}
                    />
                </BS.Col>

                {/* Expiration date */}
                <BS.Col md={6}>
                    <C.Form.DateTime
                        enableTime
                        label={TC.BUBBLE_FORM_EXP_DATE}
                        value={infos?.bubble?.expiration_date}
                        onChange={expiration_date => set_infos({ ...infos, bubble: { ...infos.bubble, expiration_date } })}
                    />
                </BS.Col>
            </BS.Row>

            <BS.Row className="g-1">
                {/* Variant */}
                <BS.Col md={4}>
                    <C.Form.Select
                        no_clear_btn
                        error={errors.variant}
                        options={options.variant}
                        label={TC.BUBBLE_FORM_VARIANT}
                        value={infos?.bubble?.variant}
                        onChange={variant => set_infos({ ...infos, bubble: { ...infos.bubble, variant } })}
                        typeahead={{ renderItem: (item: typeof options.variant[number]) => <div className={`alert-${item.bs}`} children={item.label} /> }}
                    />
                </BS.Col>

                {/* Size */}
                <BS.Col md={4}>
                    <C.Form.Select
                        no_clear_btn
                        error={errors.size}
                        options={options.size}
                        label={TC.BUBBLE_FORM_SIZE}
                        value={infos?.bubble?.size}
                        onChange={size => set_infos({ ...infos, bubble: { ...infos.bubble, size } })}
                    />
                </BS.Col>

                {/* Duration of the message */}
                <BS.Col md={4}>
                    <C.Form.NumField
                        suffix="s"
                        value={infos?.bubble?.duration}
                        label={TC.BUBBLE_FORM_DURATION}
                        tooltip={TC.BUBBLE_FORM_DURATION_TIP}
                        onChange={duration => set_infos({ ...infos, bubble: { ...infos.bubble, duration } })}
                    />
                </BS.Col>
            </BS.Row>

            <BS.Row className="g-1">
                {/* Title of the alert */}
                <BS.Col md={12}>
                    <C.Form.TextField
                        label={labels.title}
                        error={errors.title}
                        onChange={events.set_title}
                        value={infos?.bubble?.title}
                    />
                </BS.Col>
            </BS.Row>

            <BS.Row className="g-1">
                {/* HTML Editor */}
                <BS.Col md={12}>
                    <C.Form.TextField
                        textArea
                        rows={5}
                        max_height="350px"
                        error={errors.message}
                        label={labels.message}
                        onChange={events.set_message}
                        value={infos?.bubble?.message}
                    />
                </BS.Col>
            </BS.Row>
            {!props.popup && footer}
        </C.Spinner>
    );
}

export default InfosBubble;