import ReactDom from "react-dom";
import { Form, FormApi } from "../Form";
import { Dialog } from "@material-ui/core";
import { ReduxWrapper } from "../../Common";
import * as Text from "../../Constants/text";
import { TB, T, LANG } from "../../Constants";
import { renderFormModal } from "./PopUpFormModal";
import React, { FC, isValidElement, ReactNode, useCallback, useMemo, useRef, useState } from "react";

type CreateSelectModalFnProps = {
    title?: string;
    language?: number;
    allowSkip?: boolean;
    allowQuit?: boolean;
    existingOptions?: any
    formPath?: string;
    formId?: string;
    defaultSubmission?: T.AnyObject;
    extraContent?: ReactNode;
}

interface CreateSelectModalProps extends CreateSelectModalFnProps {
    onValidation?: (value?: string) => void;
    onQuit?: () => void;
}

const CreateSelectModal: FC<CreateSelectModalProps> = ({ onValidation, title, onQuit, language = LANG.FR, allowSkip = false, allowQuit = false, existingOptions, formPath, formId, defaultSubmission, extraContent, ...props }) => {
    const submitRef = useRef<FormApi>({});
    const modalRef = useRef<HTMLDivElement | null>(null);
    const [selectedValue, setSelectedValue] = useState<string>();

    //#region Language
    const validLanguage = useMemo(() => TB.getValidLanguageCode(language), [language]);
    //#endregion

    //#region Strings
    const validTitle = useMemo(() => TB.validString(title) ? title : null, [title]);
    //#endregion

    //#region Options
    const options = useMemo(() => Array.isArray(existingOptions) ? existingOptions : [], [existingOptions]);
    const errorOptions = useMemo(() => existingOptions === null ? <div className="alert alert-danger">{Text.G_ERROR_FETCH[language]}</div> : undefined, [language, existingOptions]);
    //#endregion

    //#region Extra Content
    const content = useMemo(() => TB.isDomElement(extraContent) || isValidElement(extraContent) ? extraContent : undefined, [extraContent]);
    //#endregion

    console.log(submitRef);

    //#region Body
    const showFullExistingValue = useCallback(() => {
        renderFormModal({
            title,
            _id: formId,
            readOnly: true,
            hideSubmit: true,
            noDbSaving: true,
            modalProps: { size: "md" },
            submissionId: selectedValue,
        })
    }, [selectedValue, formId, title]);

    const existingPanel = useMemo(() => options.length === 0 ? undefined : <div>
        <h4>Existing value</h4>
        <div className="input-group">
            <select className="form-select" value={selectedValue ?? ""} onChange={e => setSelectedValue(e.target.value)}>
                <option value=""></option>
                {options.map(({ label, value }) => <option key={value} value={value}>{label}</option>)}
            </select>
            <button disabled={[undefined, ""].includes(selectedValue)} className="btn btn-outline-secondary" onClick={() => onValidation?.(selectedValue)}>Choose</button>
            <button disabled={[undefined, ""].includes(selectedValue)} className="btn btn-outline-dark" onClick={showFullExistingValue}>
                <i className="fa fa-eye"></i>
            </button>
        </div>
        {errorOptions}
    </div>, [selectedValue, errorOptions, options, showFullExistingValue, onValidation]);

    const createPanel = useMemo(() => <div className="my-1">
        <h4 className="m-0">New value</h4>
        <Form
            _id={formId}
            path={formPath}
            api={submitRef}
            onSave={({ _id }) => onValidation?.(_id)}
            forcedSubmission={TB.submissionToArrayUpdate(defaultSubmission)}
        />
    </div>, [formPath, defaultSubmission, formId, onValidation]);
    //#endregion

    //#region Modal Parts
    const header = useMemo(() => validTitle === null ? undefined : <div className="modal-header">
        <h5 className="modal-title">{validTitle}</h5>
        {allowQuit && <button className="btn-close" onClick={onQuit}></button>}
    </div>, [validTitle, allowQuit, onQuit]);

    const body = useMemo(() => <div className="modal-body overflow-scroll" style={{ maxHeight: "70vh" }}>
        {content}
        {existingPanel}
        {createPanel}
    </div>, [existingPanel, content, createPanel]);

    const footer = useMemo(() => <div className="modal-footer">
        <button onClick={() => submitRef?.current?.save?.()} className="btn btn-primary">{Text.GLOBAL_CONFIRM[validLanguage]}</button>
        <button disabled={!allowSkip} onClick={() => onValidation?.()} className="btn btn-danger">
            <i className="fa fa-times mr-2"></i>Ignorer
        </button>
    </div>, [validLanguage, allowSkip, onValidation]);
    //#endregion

    return <Dialog open maxWidth="lg" fullWidth onClose={onQuit}>
        <ReduxWrapper>
            <div className="modal-content">
                {header}
                {body}
                {footer}
            </div>
            <div ref={modalRef}></div>
        </ReduxWrapper>
    </Dialog>;
}
export default CreateSelectModal;

export const askCreateSelectModal = (params?: CreateSelectModalFnProps) => new Promise<string | undefined | null>(resolve => {
    let container = document.getElementById("modalContainer")!;
    if (!TB.validObject(params)) params = {};
    if (container !== null) {
        let handleDismount = () => container !== null ? ReactDom.unmountComponentAtNode(container) : undefined;

        let onValidation = (str?: string) => {
            handleDismount();
            resolve(str);
        };

        let onQuit = () => {
            handleDismount();
            resolve(null);
        };

        ReactDom.render(<ReduxWrapper>
            <CreateSelectModal {...params} onQuit={onQuit} onValidation={onValidation} />
        </ReduxWrapper>, container);
    }
    else resolve(null);
});