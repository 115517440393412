import React from "react";
import * as H from "../../hooks";
import * as C from "../../Common";
import * as S from "../../services";
import * as BS from "react-bootstrap";
import { DndProvider } from "react-dnd";
import { useDrag, useDrop } from "react-dnd";
import { FixedSizeList } from "react-window";
import { FP, T, TB, TC } from "../../Constants";
import AutoSizer from "react-virtualized-auto-sizer";
import { HTML5Backend } from "react-dnd-html5-backend";

//#region Types
type Changes = Parameters<T.API.Reg.RegSwitch>[0][number];
type Details = ReturnType<T.API.Reg.GetRegActionDetails>;
type TagElements = { label?: string, variant?: T.ColorTypes, tipsMessages: string[], id: string };
export type UnMountRef = { save?: () => Promise<any>, askSave: boolean };

type AutoFillRegProps = {
    elements: ReturnType<T.API.Reg.GetRegTabElements>["elements"];
    resources: ReturnType<T.API.Reg.GetRegTabResults>;
    api: React.MutableRefObject<UnMountRef | null>;
    save: (changes: Changes[]) => Promise<any>;
    /** Do not allow any edits */
    read_only?: boolean;
};
//#endregion

//#region Constants
const TEXT_CODES = [
    TC.GLOBAL_SAVE, TC.GLOBAL_RESET, TC.REG_FAILED_CONDITION, TC.REG_POTENTIAL_ACTIONS, TC.REG_MISSING_TERM, TC.REG_AUTO_FILL,
    TC.REG_CLAIMED_ACTIONS, TC.REG_ACTION_APPLICABLES, TC.REG_EQUIP_NO_CAT, TC.REG_NO_FORMULA, TC.REG_INVALID_FORMULA,
    TC.REG_INVALID_REGION_COUNTRY, TC.REG_INVALID_RESOURCE, TC.REG_NOT_COMPATIBLE_GAMME_EQUIP, TC.REG_CELL_NOT_OCCUPATION,
    TC.REG_GAMME_UNPRECISE
];
//#endregion

const AutoFillReg: React.FC<AutoFillRegProps> = ({ resources, api, save, ...props }) => {
    const saving = H.useBoolean(false);
    const isMounted = H.useIsMounted();
    const hideNoGamme = H.useBoolean(true);
    const [search, set_search] = React.useState("");
    const [changes, setChanges] = React.useState<Changes[]>([]);
    const [actionDetails, setActionDetails] = H.useAsyncState<Details>([]);
    const { getStaticText, getTextObj, getElemObj, fetchObjectTranslations } = H.useLanguage(TEXT_CODES);

    //#region Load details
    React.useEffect(() => {
        let isSubscribed = true;
        let ids = resources.actions.map(a => a._id);

        if (ids.length === 0) setActionDetails([], "done");
        else S.getRegActionDetails(ids)
            .then(({ data }) => isSubscribed && setActionDetails(data, "done"))
            .catch(() => isSubscribed && setActionDetails([], "error"));

        return () => { isSubscribed = false };
    }, [setActionDetails, resources.actions]);
    //#endregion

    //#region Filter and format elements
    const appliedElemPerRegIgnoreState = React.useMemo(() => {
        let regAndElem: Record<string, string[]> = {};

        for (let elem of props.elements) {
            /* The reglementations as specified in the db */
            let dataReg = TB.getArray<T.ActionValueItem>(elem.submission.data.reglementations)
                .map(r => r?.action)
                .filter(TB.mongoIdValidator);

            for (let r of dataReg) {
                if (!Array.isArray(regAndElem[r])) regAndElem[r] = [elem.submission._id];
                else if (!regAndElem[r].includes(elem.submission._id)) regAndElem[r].push(elem.submission._id);
            }
        }

        return regAndElem;
    }, [props.elements]);

    const appliedElemPerReg = React.useMemo(() => {
        let regAndElem: Record<string, string[]> = {};

        for (let elem of props.elements) {
            let relativeChanges = changes.filter(c => c.elem === elem.submission._id);

            let toAdd = relativeChanges.filter(c => c.isSet).map(c => c.reg);
            let toRemove = relativeChanges.filter(c => !c.isSet).map(c => c.reg);

            /* The reglementations as specified in the db */
            let dataReg = TB.getArray<T.ActionValueItem>(elem.submission.data.reglementations)
                .map(r => r?.action)
                .filter(TB.mongoIdValidator);

            // Add the reg where isSet is true and remove those where isSet is false
            let reg = dataReg.concat(toAdd).filter(r => !toRemove.includes(r));

            for (let r of reg) {
                if (!Array.isArray(regAndElem[r])) regAndElem[r] = [elem.submission._id];
                else if (!regAndElem[r].includes(elem.submission._id)) regAndElem[r].push(elem.submission._id);
            }
        }

        return regAndElem;
    }, [changes, props.elements]);

    const getElementsApplied = React.useCallback((actionId: string): TagElements[] => {
        let actionConformity = resources.actionConformity[actionId];
        let elementsIds = TB.getArray(appliedElemPerReg[actionId]);

        let elements = props.elements
            .filter(e => elementsIds.includes(e.submission._id))
            .map(e => {
                let errors = [];
                let isWarning = false;
                let conformity = actionConformity[e.submission._id];

                let isBaseOk = conformity.locMatch && conformity.resourceMatch;
                if (!conformity.resourceMatch) errors.push(getStaticText(TC.REG_INVALID_RESOURCE));
                if (!conformity.locMatch) errors.push(getStaticText(TC.REG_INVALID_REGION_COUNTRY));

                if (conformity.resourceMatch && e.path === FP.EMPLACEMENT_FORM && !e.submission.data.isOccupation) {
                    isBaseOk = false;
                    errors.push(getStaticText(TC.REG_CELL_NOT_OCCUPATION));
                }

                // If is equipement
                if (isBaseOk && e.path === FP.EQUIPEMENT_FORM) {
                    // If equipment has category
                    if (TB.mongoIdValidator(e.submission.data.category)) {
                        isBaseOk = conformity.gammeMatch || conformity.unPreciseGamme;
                        if (conformity.unPreciseGamme) errors.push(getStaticText(TC.REG_GAMME_UNPRECISE));
                        else if (!conformity.gammeMatch) errors.push(getStaticText(TC.REG_NOT_COMPATIBLE_GAMME_EQUIP));
                    }
                    // No category given to the equipment
                    else {
                        isWarning = true;
                        errors.push(getStaticText(TC.REG_EQUIP_NO_CAT));
                    }
                }

                // If condition was not passed
                if (isBaseOk && typeof conformity.conditionMatch === "boolean" && !conformity.conditionMatch) {
                    if (conformity.matchError) {
                        isWarning = true;
                        if (conformity.matchError.invalidFormula) errors.push(getStaticText(TC.REG_INVALID_FORMULA));
                        if (conformity.matchError.noFormula) errors.push(getStaticText(TC.REG_NO_FORMULA));
                        if (conformity.matchError.missingTerms.length > 0) conformity.matchError.missingTerms.forEach(t => errors.push(getStaticText(TC.REG_MISSING_TERM, t)));
                    }
                    // Condition executed ok but not passed
                    else {
                        isBaseOk = false;
                        errors.push(getStaticText(TC.REG_FAILED_CONDITION));
                    }
                }

                if (errors.length > 0) isWarning = true;
                if (e.full_path) errors.push(<span className="text-muted fs-75">{e.full_path}</span>);

                let variant: T.ColorTypes = "success";
                if (!isBaseOk) variant = "danger";
                else if (isWarning) variant = "warning";

                return {
                    variant,
                    tipsMessages: errors,
                    id: e.submission._id,
                    label: getTextObj(e.submission._id, "name", e.submission.data.name),
                };
            });

        return elements;
    }, [getStaticText, getTextObj, appliedElemPerReg, resources.actionConformity, props.elements]);

    const getPotentiallyApplicableElements = React.useCallback((actionId: string, ignoreChanges = false): TagElements[] => {
        let affected = TB.getArray(appliedElemPerReg[actionId]);
        let actionConformity = resources.actionConformity[actionId];

        // Filter the emplacement (only keep if is occupation)
        let elements = props.elements.filter(e => e.path !== FP.EMPLACEMENT_FORM || e.submission.data.isOccupation);
        if (!ignoreChanges) elements = elements.filter(e => !affected.includes(e.submission._id))

        // Filter the equipments is specified so
        if (hideNoGamme.value) elements = elements.filter(e => e.path !== FP.EQUIPEMENT_FORM || TB.mongoIdValidator(e.submission.data.category));

        let potElem = elements.map(e => {
            let conformity = actionConformity[e.submission._id];
            let isBaseOk = conformity.locMatch && conformity.resourceMatch;
            let isWarning = false;
            let errors = [];

            // If is equipement
            if (isBaseOk && e.path === FP.EQUIPEMENT_FORM) {
                // If equipment has category
                if (TB.mongoIdValidator(e.submission.data.category)) {
                    isBaseOk = conformity.gammeMatch || conformity.unPreciseGamme;
                    if (conformity.unPreciseGamme) errors.push(getStaticText(TC.REG_GAMME_UNPRECISE));
                }
                // No category given to the equipment
                else errors.push(getStaticText(TC.REG_EQUIP_NO_CAT));
            }

            // If condition was not passed
            if (isBaseOk && typeof conformity.conditionMatch === "boolean" && !conformity.conditionMatch) {
                if (conformity.matchError) {
                    isWarning = true;
                    if (conformity.matchError.invalidFormula) errors.push(getStaticText(TC.REG_INVALID_FORMULA));
                    if (conformity.matchError.noFormula) errors.push(getStaticText(TC.REG_NO_FORMULA));
                    if (conformity.matchError.missingTerms.length > 0) conformity.matchError.missingTerms.forEach(t => errors.push(getStaticText(TC.REG_MISSING_TERM, t)));
                }
                else isBaseOk = false;
            }

            if (errors.length > 0) isWarning = true;
            if (e.full_path) errors.push(<span className="text-muted fs-75">{e.full_path}</span>);

            let variant: T.ColorTypes = "success";
            if (!isBaseOk) variant = "danger";
            else if (isWarning) variant = "warning";

            return {
                variant,
                tipsMessages: errors,
                id: e.submission._id,
                label: getTextObj(e.submission._id, "name", e.submission.data.name),
            };
        }).filter(e => e.variant !== "danger");

        return potElem;
    }, [getStaticText, getTextObj, props.elements, resources.actionConformity, appliedElemPerReg, hideNoGamme.value]);
    //#endregion

    //#region Update elements
    const updateItem = React.useCallback((elem: string, reg: string, isSet: boolean) => {
        setChanges(p => p.filter(c => c.elem !== elem || c.reg !== reg).concat({ elem, reg, isSet }))
    }, []);

    const setItem = React.useCallback((elem: string, reg: string) => updateItem(elem, reg, true), [updateItem]);
    const unsetItem = React.useCallback((elem: string, reg: string) => updateItem(elem, reg, false), [updateItem]);

    const autoFill = React.useCallback((actionId?: string) => {
        let newChanges: Changes[] = [];
        let toUpdateAction = TB.mongoIdValidator(actionId) ? resources.actions.filter(a => a._id === actionId) : resources.actions;

        for (let action of toUpdateAction) {
            let toAdd = getPotentiallyApplicableElements(action._id, true);
            console.log({ toAdd })
            newChanges = newChanges.concat(toAdd.map(a => ({ elem: a.id, reg: action._id, isSet: true })));
        }

        setChanges(p => {
            let filteredPrevious = p.filter(i => newChanges.filter(c => c.elem === i.elem && c.reg === i.reg).length === 0);
            return filteredPrevious.concat(newChanges);
        });
    }, [resources.actions, getPotentiallyApplicableElements]);

    const autoUnList = React.useCallback((actionId?: string) => {
        if (TB.mongoIdValidator(actionId)) {
            // Take the values that were already activated (in db) and deactivate them
            let newChanges: Changes[] = TB.getArray(appliedElemPerRegIgnoreState[actionId]).map(elem => ({ reg: actionId, elem, isSet: false }));
            setChanges(p => p.filter(c => c.reg !== actionId).concat(newChanges));
        }
        else {
            let newChanges: Changes[] = [];

            for (let [reg, elements] of Object.entries(appliedElemPerRegIgnoreState)) {
                for (let elem of elements) newChanges.push({ reg, elem, isSet: false });
            }

            setChanges(newChanges);
        }
    }, [appliedElemPerRegIgnoreState]);
    //#endregion

    //#region Save
    const onSave = React.useCallback(() => {
        let promise = save?.(changes);
        saving.setTrue();

        if (promise) promise
            .then(() => isMounted() && setChanges([]))
            .finally(() => isMounted() && saving.setFalse());
        return promise;
    }, [changes, saving, isMounted, save]);

    React.useEffect(() => {
        if (api) {
            if (api.current === null) api.current = { save: onSave, askSave: changes.length > 0 };
            else {
                api.current.save = onSave;
                api.current.askSave = changes.length > 0;
            }
        }
    }, [onSave, changes.length, api]);
    //#endregion

    //#region Language
    React.useEffect(() => {
        let actionIds = resources.actions.map(a => a._id);
        fetchObjectTranslations(actionIds);
    }, [fetchObjectTranslations, resources.actions]);

    React.useEffect(() => {
        let elemIds = props.elements.map(e => e.submission._id);
        fetchObjectTranslations(elemIds);
    }, [fetchObjectTranslations, props.elements]);
    //#endregion

    //#region Detailed View
    const view_label = React.useMemo(() => hideNoGamme.value ? TC.AUTO_FILL_REG_SHOW_NO_GAMME : TC.AUTO_FILL_REG_HIDE_NO_GAMME, [hideNoGamme.value]);

    const getActionExtra = React.useCallback((id: string) => {
        let detail = actionDetails.filter(d => d._id === id)[0];
        if (!detail) return null;
        return <C.Flex className="w-100 text-muted fs-85" alignItems="center" justifyContent="center">
            <span className="text-break text-end me-1" style={{ whiteSpace: "break-spaces" }}>
                {detail.regions.length > 0 ? detail.regions.map(r => r.name).join() : detail.countries.join()}
            </span>
            <span>({detail.noFreq ? "∞" : detail.frequency || "X"})</span>
        </C.Flex>
    }, [actionDetails]);
    //#endregion

    const paged_actions = React.useMemo(() => {
        let all_actions = resources.actions || [];

        if (search.length > 0) all_actions = all_actions.filter(a => {
            let trad_name = getTextObj(a._id, "name", a.data.name).toLowerCase();
            return TB.areStringSimilar(search, trad_name);
        });
        return all_actions;

        // /* pagination.getCurrentData( */resources.actions || []/* ) */
    }, [resources.actions, search, getTextObj]);

    const actions_jsx = React.useMemo(() => paged_actions.map(a => <BS.Row className="mb-2" key={a._id}>
        <BS.Col md={3}>
            <C.Flex direction="column" className="text-center h-100" alignItems="center" justifyContent="center">
                <div className="p-1">
                    {getElemObj(a._id, "name", a.data.name)}
                    <C.IconTip icon="question-circle ms-2" tipContent={a.data.reference} />
                </div>
                {getActionExtra(a._id)}
            </C.Flex>
        </BS.Col>
        <BS.Col md={4}>
            <TagContainer canDrop={!props.read_only} dropId={a._id} onDrop={unsetItem}>
                {getPotentiallyApplicableElements(a._id).map(e => <Tag canDrag={!props.read_only} key={e.id} {...e} isAdd outline dragId={a._id} onClickIcon={setItem} />)}
            </TagContainer>
        </BS.Col>
        <BS.Col md={1} className="text-center">
            {!props.read_only && <C.Flex className="h-100" alignItems="center" justifyContent="center">
                <BS.ButtonGroup size="sm">
                    <C.Button onClick={() => autoFill(a._id)} icon="chevron-right" />
                    <C.Button onClick={() => autoUnList(a._id)} icon="chevron-left" />
                </BS.ButtonGroup>
            </C.Flex>}
        </BS.Col>
        <BS.Col md={4}>
            <TagContainer canDrop={!props.read_only} dropId={a._id} onDrop={setItem}>
                {getElementsApplied(a._id).map(e => <Tag canDrag={!props.read_only} key={e.id} {...e} outline dragId={a._id} onClickIcon={unsetItem} />)}
            </TagContainer>
        </BS.Col>
    </BS.Row>), [autoFill, autoUnList, getActionExtra, getElemObj, getElementsApplied, getPotentiallyApplicableElements, paged_actions, props.read_only, setItem, unsetItem]);

    return <C.Flex direction="column" className="flex-grow-1 w-100">
        <BS.Row className="mb-3">
            <BS.Col>
                <C.Flex alignItems="center" justifyContent="between">
                    <C.Form.TextField
                        uncontrolled
                        value={search}
                        noBottomMargin
                        customClass="w-25"
                        onChange={set_search}
                        placeholder={TC.DIA_NAME_SEARCH}
                    />
                    <BS.ButtonGroup>
                        {!props.read_only && <>
                            <C.Button variant="danger" onClick={() => setChanges([])} text={TC.GLOBAL_RESET} />

                            <C.Button
                                onClick={onSave}
                                text={TC.GLOBAL_SAVE}
                                disabled={changes.length === 0}
                                icon={{ icon: "save", spin: saving.value, spinIcon: "spin" }}
                            />
                        </>}

                        <C.Button variant="info" onClick={hideNoGamme.toggle} children={view_label} />
                    </BS.ButtonGroup>
                </C.Flex>
            </BS.Col>
        </BS.Row>

        <BS.Row className="mb-2 text-center" style={{ width: "99.5%" }}>
            <BS.Col md={3}>
                <C.Falcon.Title titleTag={getStaticText(TC.REG_ACTION_APPLICABLES)} />
            </BS.Col>
            <BS.Col md={4}>
                <C.Falcon.Title titleTag={getStaticText(TC.REG_POTENTIAL_ACTIONS)} />
            </BS.Col>
            <BS.Col md={1} className="text-center">
                {!props.read_only && <C.Flex className="h-100" alignItems="center" justifyContent="center">
                    <BS.ButtonGroup size="sm">
                        <C.Button onClick={() => autoFill()} icon="angle-double-right" />
                        <C.Button onClick={() => autoUnList()} icon="angle-double-left" />
                    </BS.ButtonGroup>
                </C.Flex>}
            </BS.Col>
            <BS.Col md={4}>
                <C.Falcon.Title titleTag={getStaticText(TC.REG_CLAIMED_ACTIONS)} />
            </BS.Col>
        </BS.Row>

        <C.Flex className="flex-grow-1" style={{ minHeight: "70vh" }}>
            {/* @ts-ignore */}
            <DndProvider backend={HTML5Backend}>
                <AutoSizer>
                    {({ height, width }) => (
                        <FixedSizeList
                            width={width}
                            itemSize={100}
                            height={height}
                            itemCount={actions_jsx.length}
                        >
                            {({ index, style }) => <div style={{ ...style, width: "98%" }} children={actions_jsx[index]} />}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            </DndProvider>
        </C.Flex>
    </C.Flex>;
}

export default AutoFillReg;

//#region BS.Container
type TagContainerProps = {
    children?: React.ReactNode;
    onDrop: (item: string, drag: string) => void;
    dropId: string;
    /** Allow dropping */
    canDrop?: boolean;
};

const TagContainer: React.FC<TagContainerProps> = ({ children, dropId, onDrop, canDrop }) => {
    const drop = useDrop(() => ({ accept: dropId, canDrop: () => canDrop, drop: ({ id }) => onDrop(id, dropId) }))[1];

    return <BS.Container ref={drop} className="bg-white rounded border border-secondary p-2 overflow-auto" style={{ /* maxHeight: "10rem", */ height: 85, minHeight: "1.5rem" }}>
        {children}
    </BS.Container>
}
//#endregion

//#region Tag
type TagProps = {
    id: string;
    dragId: string;
    label?: string;
    isAdd?: boolean;
    outline?: boolean;
    variant?: T.ColorTypes;
    tipsMessages: string[];
    onClickIcon: (id: string, regId: string) => void;
    /** Allow to drag the item */
    canDrag?: boolean;
};

const Tag: React.FC<TagProps> = ({ label, id, dragId, outline, variant, canDrag, isAdd, tipsMessages, onClickIcon }) => {
    const drag = useDrag(() => ({ type: dragId, canDrag: () => canDrag, item: { id } }))[1];

    const vVariant = React.useMemo(() => TB.isValidColorType(variant) ? variant : "primary", [variant]);
    const innerBorderColor = React.useMemo(() => outline ? "border-" + vVariant : "", [vVariant, outline]);
    const variantName = React.useMemo(() => outline ? "outline-" + vVariant : vVariant, [vVariant, outline]);

    const mainButton = React.useMemo(() => <C.Button
        size="sm"
        ref={drag}
        variant={variantName}
        style={canDrag ? { cursor: "grab" } : null}
        onClick={() => canDrag && onClickIcon(id, dragId)}
        className={`m-1 ${canDrag ? "pointer-grab" : ""}`}
    >
        <>
            {label || "N/A"}
            <i className={`fa fa-${isAdd ? "plus" : "times"} ${canDrag ? "pointer" : ""} fs-85 ms-2 border-start ${innerBorderColor} ps-2`}></i>
        </>
    </C.Button>, [dragId, id, isAdd, label, onClickIcon, drag, variantName, innerBorderColor, canDrag]);

    const renderToolTip = React.useCallback((props) => <BS.Tooltip {...props}>
        {tipsMessages.map((t, i) => <div className="text-start" key={i}>{t}</div>)}
    </BS.Tooltip>, [tipsMessages]);

    if (tipsMessages.length === 0) return mainButton;
    return <BS.OverlayTrigger overlay={renderToolTip}>
        {mainButton}
    </BS.OverlayTrigger>
}
//#endregion