import _ from "lodash";
import ReactDom from "react-dom";
import PopUpForms from "../PopUpForms";
import { useNavigate } from 'react-router-dom';
import LightTree from "../../NewDia/LightTree";
import { ReduxWrapper } from "../../../Common";
import * as Text from "../../../Constants/text";
import { useSelector } from "react-redux";
import TreeSelect from "../../popup/Accessibles/treeSelect";
import { GAMMES, RIGHTS, TB, EC, LT, FP, TC } from "../../../Constants";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Annotations from "../../Annotation/Annotations";
import AttachMultipleObjParents from "../../../helpers/AttachMultipleObjParents";
import { Confirm, ModalSelect, BlankModal, Loader, ErrorModal, renderFormModal, Carousel } from "../../Modal";
import { bulkPlans, createManySubmissions, deleteLinks, deleteSubmissionsAndRelatives, getLinksFromFilter, getManySubmissionsFromFilter, getPlansFromFilter, removeSubmission, updateSubmission } from "../../../services/user.service";

const SitePanel = ({ site, children, childrenLinks, coproList, addEntity, syndicList, addNote, forms, linkTypes, onChangeParcelLink, onUpdateDescendants, onUpdateLinks, onUpdateTree, equipGammes, ...props }) => {
    //#region State
    const modalRef = useRef();
    const navigate = useNavigate();
    const secondModalRef = useRef();

    const auth = useSelector(({ auth }) => auth);
    const [ownership, setOwnerShip] = useState([]);
    const [activePanel, setActivePanel] = useState(0);
    const language = useSelector(({ language }) => language);

    const [plans, setPlans] = useState();
    const [{ user, rights, isAdmin }, setUser] = useState({});
    //#endregion

    //#region User
    useEffect(() => auth.then(setUser), [auth]);
    const canCreate = useMemo(() => isAdmin || (Array.isArray(rights) && rights.includes(RIGHTS.TREE_CREATE)), [rights, isAdmin]);

    const showErrorModal = useCallback(errorCode =>
        new Promise(resolve => ReactDom.render(<ErrorModal onQuit={resolve} language={language} errorCode={errorCode} />, modalRef.current))
            .then(() => ReactDom.unmountComponentAtNode(modalRef.current))
        , [language]);
    //#endregion

    //#region Plans
    const siteId = useMemo(() => site?._id, [site]);
    const childrenIdString = useMemo(() => children?.map?.(({ _id }) => _id)?.join?.() ?? "", [children]);

    useEffect(() => {
        let isSubscribed = true;
        const getPlans = async () => {
            let ids = [siteId].concat(childrenIdString.split(",")).filter(TB.mongoIdValidator);
            let reply = await getPlansFromFilter({ location: ids });
            return Array.isArray(reply.data) ? reply.data : null;
        }
        // ChildrenIds went into a string to avoid to fetch when the array reference only has changed
        if (TB.mongoIdValidator(siteId)) getPlans().then(x => isSubscribed ? setPlans(x) : undefined);
        return () => isSubscribed = false;
    }, [siteId, childrenIdString]);
    //#endregion

    //#region Forms
    const clientFormId = useMemo(() => forms?.filter?.(({ path }) => path === FP.CLIENT_FORM)?.[0]?._id, [forms]);
    const parcelsFormId = useMemo(() => forms?.filter?.(({ path }) => path === FP.PARCEL_FORM)?.[0]?._id, [forms]);
    const buildFormId = useMemo(() => forms?.filter?.(({ path }) => path === FP.BUILDING_FORM)?.[0]?._id, [forms]);
    // const parkingFormId = useMemo(() => forms?.filter?.(({ path }) => path === FP.PARKING_FORM)?.[0]?._id, [forms]);
    const equipFormId = useMemo(() => forms?.filter?.(({ path }) => path === FP.EQUIPEMENT_FORM)?.[0]?._id, [forms]);
    const ownerShipFormId = useMemo(() => forms?.filter?.(({ path }) => path === FP.OWNERSHIP_FORM)?.[0]?._id, [forms]);
    const emplacementFormId = useMemo(() => forms?.filter?.(({ path }) => path === FP.EMPLACEMENT_FORM)?.[0]?._id, [forms]);

    const locationForms = useMemo(() => [parcelsFormId, buildFormId, emplacementFormId], [parcelsFormId, buildFormId, emplacementFormId]);
    //#endregion

    //#region Gamme Equip
    const irveId = useMemo(() => equipGammes?.filter?.(({ data }) => data.omniclass === GAMMES.IRVE)?.[0]?._id, [equipGammes]);
    const parkingLightsId = useMemo(() => equipGammes?.filter?.(({ data }) => data.omniclass === GAMMES.PARKING_LIGHTS)?.[0]?._id, [equipGammes]);
    //#endregion

    //#region LinkTypes
    const ownLinkType = useMemo(() => linkTypes?.filter?.(({ data }) => data.type === LT.LINK_TYPE_OWN)?.[0]?._id, [linkTypes]);
    const coOwnLinkType = useMemo(() => linkTypes?.filter?.(({ data }) => data.type === LT.LINK_TYPE_CO_OWN)?.[0]?._id, [linkTypes]);
    const gestionLinkType = useMemo(() => linkTypes?.filter?.(({ data }) => data.type === LT.LINK_TYPE_GESTION)?.[0]?._id, [linkTypes]);
    //#endregion

    //#region Links
    const descendance = useMemo(() => Array.isArray(children) ? children : [], [children]);
    const links = useMemo(() => Array.isArray(childrenLinks) ? childrenLinks : [], [childrenLinks]);
    //#endregion

    //#region Plan
    const { planImg, src, name } = useMemo(() => plans?.filter?.(({ location }) => location === siteId)?.[0] ?? {}, [plans, siteId]);
    const planLocations = useMemo(() => children?.filter?.(({ form }) => locationForms.includes(form)) ?? [], [locationForms, children]);

    const showPlanEditor = useCallback(() => {
        let planPromise = new Promise(resolve => ReactDom.render(<ReduxWrapper>
            <Annotations
                onSave={resolve}
                offerWiderOrigins
                defaultPlans={plans}
                close={() => resolve(null)}
                locations={[site].concat(planLocations).filter(x => TB.mongoIdValidator(x?._id))}
            />
        </ReduxWrapper>, modalRef.current));

        planPromise.then(async toSavePlans => {
            ReactDom.unmountComponentAtNode(modalRef.current);
            if (Array.isArray(toSavePlans)) {
                ReactDom.render(<Loader />, modalRef.current);

                let [existingPlans, newPlans] = _.partition(toSavePlans, ({ _id }) => TB.mongoIdValidator(_id));
                let stillTherePlansIds = existingPlans.map(({ _id }) => _id);
                let [toUpdate, toDelete] = _.partition(plans, ({ _id }) => stillTherePlansIds.includes(_id));
                toUpdate = toUpdate.map(({ _id }) => toSavePlans.filter(p => p?._id === _id)?.[0]);

                let bulk = newPlans.map(p => ({ insertOne: { document: p } }))
                    .concat(toDelete.map(({ _id }) => ({ deleteOne: { filter: { _id } } })))
                    .concat(toUpdate.map(({ _id, ...r }) => ({ updateOne: { filter: { _id }, update: { ...r }, upsert: false } })));

                let reply = await bulkPlans(bulk);
                let { ok, insertedIds } = reply?.data ?? {};
                if (ok === 1) {
                    if (Array.isArray(insertedIds) && insertedIds?.length > 0) {
                        let reply = await getPlansFromFilter({ _id: insertedIds });
                        if (Array.isArray(reply?.data)) setPlans(toSavePlans.filter(({ _id }) => !TB.mongoIdValidator(_id)).concat(reply.data));
                        else setPlans(toSavePlans.filter(({ _id }) => !TB.mongoIdValidator(_id)).concat(insertedIds.map((_id, i) => ({ ...newPlans?.[i] ?? {}, _id }))));
                    }
                    else setPlans(toSavePlans);
                    ReactDom.unmountComponentAtNode(modalRef.current);
                }
                else {
                    ReactDom.unmountComponentAtNode(modalRef.current);
                    let errorPromise = new Promise(resolve => ReactDom.render(<ErrorModal language={language} errorCode={EC.CODE_DB_UPDATE_FAIL} onQuit={resolve} />, modalRef.current));
                    errorPromise.then(() => ReactDom.unmountComponentAtNode(modalRef.current));
                }
            }
        });
    }, [plans, planLocations, language, site]);

    const zoomPicture = useCallback(() => {
        let imgPromise = new Promise(resolve => ReactDom.render(<Carousel
            onQuit={resolve}
            language={language}
            images={{ src: planImg ?? src, title: name }}
        />, modalRef.current));

        imgPromise.then(() => ReactDom.unmountComponentAtNode(modalRef.current));
    }, [language, name, src, planImg]);

    const planPanel = useMemo(() => {
        let hasImg = [planImg, src].some(TB.validString);
        let errorLoad = plans === null;
        let isLoading = plans === undefined;
        let allOk = Array.isArray(plans);

        return <div className="w-25 p-1 pt-3 d-inline-flex flex-column justify-content-center">
            <div className="bg-light border position-relative" style={{ height: "15rem" }}>
                {isLoading && <Loader isPopUp={false} />}
                {errorLoad && <span className="position-absolute top-50 start-50 translate-middle text-center">
                    <i className="fa fa-exclamation-triangle text-danger fa-2x"></i>
                    <p className="mt-1 text-danger">{Text.ANNOT_ERROR_LOAD_PLANS[language]}</p>
                </span>}
                {allOk && hasImg && <img onClick={zoomPicture} className="w-100 h-100 pointer-zoom" alt="" src={planImg ?? src} onError={e => console.log(e)} />}
                {allOk && !hasImg && <span className="text-dark position-absolute top-50 start-50 translate-middle fs-5">{Text.ANNOT_NO_PLAN[language]}</span>}
            </div>
            <div className="w-100 p-1">
                <button disabled={!allOk} onClick={showPlanEditor} className="btn btn-primary stop-hiding w-100">
                    <i className="fa fa-paint-brush mr-2"></i>
                    {Text.ANNOT_DEFAULT_PLAN[language]}
                </button>
            </div>
        </div>
    }, [planImg, plans, language, src, showPlanEditor, zoomPicture]);
    //#endregion

    //#region Buildings
    const buildings = useMemo(() => children?.filter?.(({ form }) => form === buildFormId) ?? [], [children, buildFormId]);
    const buildingsOptions = useMemo(() => buildings.map(({ _id, data }) => <option key={_id} value={_id}>{data?.name}</option>), [buildings]);
    //#endregion

    //#region Parcels
    const onChangeParcelBuild = useCallback((parcel, newBuild, oldBuild) => onChangeParcelLink?.(parcel, newBuild, oldBuild, siteId), [onChangeParcelLink, siteId]);
    const parcels = useMemo(() => children?.filter?.(({ form }) => form === parcelsFormId) ?? [], [children, parcelsFormId]);

    const onChangeMainParcel = useCallback(async (parcelId, isMain) => {
        let reply = await updateSubmission(parcelId, { "data.isMain": isMain });

        if (TB.mongoIdValidator(reply.data?._id)) onUpdateDescendants?.(prev => prev.map(p => {
            if (p?._id === parcelId) return { ...p, data: { ...p.data, isMain } };
            return p;
        }));
        else {
            let errorPromise = new Promise(resolve => ReactDom.render(<ErrorModal onQuit={resolve} language={language} errorCode={EC.CODE_DB_UPDATE_FAIL} />, modalRef.current));
            errorPromise.then(() => ReactDom.unmountComponentAtNode(modalRef.current));
        }
    }, [language, onUpdateDescendants]);

    const addParcel = useCallback(() => {
        let formPromise = new Promise(resolve => ReactDom.render(<ReduxWrapper>
            <PopUpForms
                sendBackFullObj
                readOnly={false}
                handleForm={resolve}
                pathForm={FP.PARCEL_FORM}
                title={Text.GLOBAL_LABEL_PARCEL[language]}
            />
        </ReduxWrapper>, modalRef.current));

        formPromise.then(async parcel => {
            ReactDom.unmountComponentAtNode(modalRef.current);
            if (TB.mongoIdValidator(parcel?._id)) {
                let newLink = { input: siteId, output: parcel._id, type: ownLinkType };

                let reply = await AttachMultipleObjParents([newLink], user);
                if (reply === true) onUpdateTree(({ descendance, links }) => ({
                    descendance: descendance.concat(parcel),
                    links: links.concat(newLink)
                }));
                else {
                    let errorPromise = new Promise(resolve => ReactDom.render(<ErrorModal language={language} errorCode={EC.CODE_DB_UPDATE_FAIL} onQuit={resolve} />, modalRef.current));
                    removeSubmission(parcel?._id);
                    errorPromise.then(() => ReactDom.unmountComponentAtNode(modalRef.current));
                }
            }
        });
    }, [language, ownLinkType, siteId, user, onUpdateTree]);

    const editParcel = useCallback(id => {
        let formPromise = new Promise(resolve => ReactDom.render(<ReduxWrapper>
            <PopUpForms
                sendBackFullObj
                readOnly={false}
                idSubmission={id}
                handleForm={resolve}
                pathForm={FP.PARCEL_FORM}
                title={Text.GLOBAL_LABEL_PARCEL[language]}
            />
        </ReduxWrapper>, modalRef.current));

        formPromise.then(async parcel => {
            ReactDom.unmountComponentAtNode(modalRef.current);
            if (TB.mongoIdValidator(parcel?._id)) onUpdateDescendants(prev => prev.map(node => node._id === parcel._id ? parcel : node));
        });
    }, [language, onUpdateDescendants]);

    const parcelsWithBuilds = useMemo(() => {
        let checkedIds = [];

        const getChildren = ids => {
            if (!Array.isArray(ids)) ids = [ids];
            let children = links.filter(({ input, output }) => ids.includes(input) && !checkedIds.includes(output)).map(({ output }) => output);
            checkedIds = checkedIds.concat(ids);
            if (children.length === 0) return [];
            return descendance.filter(({ _id }) => children.includes(_id)).concat(getChildren(children));
        }

        return parcels.map(({ _id, ...r }) => ({ _id, ...r, building: getChildren(_id).filter(({ form }) => form === buildFormId)?.[0]?._id }));
    }, [parcels, links, buildFormId, descendance]);

    const parcelTableHeader = useMemo(() => <thead>
        <tr>
            <th scope="col">#</th>
            <th className="text-start" scope="col">{Text.GLOBAL_NAME[language]}</th>
            <th scope="col">isMain</th>
            <th scope="col">{Text.GLOBAL_LABEL_BUILD[language]}</th>
            <th>Note</th>
            <th></th>
        </tr>
    </thead>, [language]);

    const parcelPanel = useMemo(() => <div className="py-0 px-2 flex-grow-1 overflow-auto border-start d-flex flex-column">
        <div className="position-sticky top-0 bg-white"><h5 className="h-100 mb-0 p-2">{Text.GLOBAL_LABEL_PARCEL[language]}</h5></div>
        <div className="flex-grow-1">
            <table className="table text-center align-middle">
                {parcelTableHeader}
                <tbody>
                    {parcelsWithBuilds.map(({ _id, form, data, building }, i) => <tr key={_id}>
                        <th className="align-middle" scope="row">{i + 1}</th>
                        <td className="text-start align-middle">{data?.name}</td>
                        <td className="align-middle">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" checked={data?.isMain ?? false} onChange={e => onChangeMainParcel(_id, e.target.checked)} />
                            </div>
                        </td>
                        <td className="align-middle">
                            <select className="form-select" value={building ?? ""} onChange={e => onChangeParcelBuild(_id, e.target.value, building)}>
                                <option></option>
                                {buildingsOptions}
                            </select>
                        </td>
                        <td>
                            <button className="btn btn-primary" onClick={() => addNote(_id)}>
                                <i className="fa fa-comment-alt"></i>
                            </button>
                        </td>
                        <td>
                            <button className="btn btn-primary" onClick={() => editParcel(_id)}>
                                <i className="fa fa-pencil-alt"></i>
                            </button>
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </div>
        <div className="position-sticky bottom-0 bg-white p-2">
            <button onClick={addParcel} className="btn btn-primary">
                <i className="fa fa-plus mr-2"></i> {Text.GLOBAL_LABEL_PARCEL[language]}
            </button>
        </div>
    </div>, [parcelsWithBuilds, buildingsOptions, parcelTableHeader, language, onChangeParcelBuild, onChangeMainParcel, addParcel, editParcel, addNote]);

    const parcelPlanPanel = useMemo(() => <div className="d-flex border-bottom h-50" style={{ maxHeight: "50vh" }}>
        {planPanel}
        {parcelPanel}
    </div>, [planPanel, parcelPanel]);
    //#endregion

    //#region Parkings
    const parkings = useMemo(() => children?.filter?.(c => c.form === emplacementFormId && c.data.type === "parking") ?? [], [emplacementFormId, children]);

    //#region Parking Lights
    const parkingWithLightsAndIRVE = useMemo(() => parkings.map(({ _id, ...r }) => {
        let children = links.filter(({ input }) => input === _id).map(({ output }) => output);
        let equipments = descendance.filter(({ _id, form }) => children.includes(_id) && form === equipFormId);

        let irves = equipments.filter(({ data }) => data.category === irveId).map(({ _id }) => _id);
        let lights = equipments.filter(({ data }) => data.category === parkingLightsId).map(({ _id }) => _id);

        return { _id, ...r, lights, irves };
    }), [parkings, descendance, links, equipFormId, parkingLightsId, irveId]);

    const parkingLightsAddEdit = useCallback((parkingId, category, forceCreate = false, submissionId) => {
        let parking = parkingWithLightsAndIRVE.filter(({ _id }) => _id === parkingId)?.[0];
        let existingLights = TB.mongoIdValidator(category) ? (category === parkingLightsId ? parking?.lights : parking?.irves) : undefined;

        // Add Lights
        if (forceCreate || !Array.isArray(existingLights) || existingLights.length === 0) {
            renderFormModal({
                submissionId,
                path: FP.EQUIPEMENT_FORM,
                modalProps: { size: "md" },
                forcedSubmission: [{ prop: "category", value: category }],
            }).then(async equip => {
                if (TB.mongoIdValidator(equip?._id)) {
                    ReactDom.render(<Loader />, modalRef.current);
                    let newLink = { input: parkingId, output: equip._id, type: ownLinkType };
                    let reply = await AttachMultipleObjParents([newLink], user);
                    ReactDom.unmountComponentAtNode(modalRef.current);
                    if (reply === true) onUpdateTree?.(({ descendance, links }) => ({
                        descendance: descendance.concat(equip),
                        links: links.concat(newLink),
                    }));
                    else {
                        let errorPromise = new Promise(resolve => ReactDom.render(<ErrorModal language={language} onQuit={resolve} errorCode={EC.CODE_DB_UPDATE_FAIL} />, modalRef.current));
                        removeSubmission(equip._id);
                        errorPromise.then(() => ReactDom.unmountComponentAtNode(modalRef.current));
                    }
                }
            });
        }
        // Edit lights
        else {
            let lightsObj = descendance.filter(({ _id }) => existingLights.includes(_id)).map(({ _id, data }) => ({ value: _id, label: data?.name }));

            let selectionPromise = new Promise(resolve => ReactDom.render(<ReduxWrapper>
                <ModalSelect
                    options={lightsObj}
                    language={language}
                    onValidate={resolve}
                    onQuit={() => resolve(null)}
                    defaultVal={existingLights?.[0]}
                    noSelectionText={Text.ADD[language]}
                />
            </ReduxWrapper>, modalRef.current));

            selectionPromise.then(val => {
                ReactDom.unmountComponentAtNode(modalRef.current);
                if (val === undefined) parkingLightsAddEdit(parkingId, category, true);
                else if (TB.mongoIdValidator(val)) parkingLightsAddEdit(parkingId, category, true, val);
            })

        }
    }, [parkingWithLightsAndIRVE, parkingLightsId, language, ownLinkType, descendance, user, onUpdateTree]);
    //#endregion

    const addParking = useCallback(() => {
        let formPromise = new Promise(resolve => ReactDom.render(<ReduxWrapper>
            <PopUpForms
                isEdit
                sendBackFullObj
                readOnly={false}
                handleForm={resolve}
                pathForm={FP.EMPLACEMENT_FORM}
                title={TC.GLOBAL_LABEL_PARKING}
                submission={{ type: "parking" }}
            />
        </ReduxWrapper>, modalRef.current));

        formPromise.then(async parking => {
            ReactDom.unmountComponentAtNode(modalRef.current);
            if (TB.mongoIdValidator(parking?._id)) {
                let newLink = { input: siteId, output: parking._id, type: ownLinkType };

                let reply = await AttachMultipleObjParents([newLink], user);
                if (reply === true) onUpdateTree(({ descendance, links }) => ({
                    descendance: descendance.concat(parking),
                    links: links.concat(newLink)
                }));
                else {
                    let errorPromise = new Promise(resolve => ReactDom.render(<ErrorModal language={language} errorCode={EC.CODE_DB_UPDATE_FAIL} onQuit={resolve} />, modalRef.current));
                    removeSubmission(parking?._id);
                    errorPromise.then(() => ReactDom.unmountComponentAtNode(modalRef.current));
                }
            }
        });
    }, [language, user, siteId, ownLinkType, onUpdateTree]);

    const removeParking = useCallback(id => {
        let confirmPromise = new Promise(resolve => ReactDom.render(<Confirm
            language={language}
            onValidate={resolve}
            onQuit={() => resolve(null)}
        />, modalRef.current));

        confirmPromise.then(async confirm => {
            ReactDom.unmountComponentAtNode(modalRef.current);
            if (confirm) {
                ReactDom.render(<Loader />, modalRef.current);
                let replyDelete = await deleteSubmissionsAndRelatives([id]);
                ReactDom.unmountComponentAtNode(modalRef.current);
                if (replyDelete.data?.ok === 1) onUpdateTree(({ descendance, links }) => ({
                    descendance: descendance.filter(({ _id }) => _id !== id),
                    links: links.filter(({ input, output }) => ![input, output].includes(id))
                }));
                else {
                    let errorPromise = new Promise(resolve => ReactDom.render(<ErrorModal language={language} errorCode={EC.CODE_DB_DELETE_FAIL} onQuit={resolve} />, modalRef.current));
                    errorPromise.then(() => ReactDom.unmountComponentAtNode(modalRef.current));
                }
            }
        });
    }, [language, onUpdateTree]);

    const onChangeParking = useCallback(async (id, key, value) => {
        let update = {};
        update['data.' + key] = value;
        let replyUpdate = await updateSubmission(id, update);
        if (TB.mongoIdValidator(replyUpdate?.data?._id)) onUpdateDescendants?.(prev => prev.map(node => {
            if (node._id !== id) return node;
            node.data[key] = value;
            return node;
        }));
        else {
            let errorPromise = new Promise(resolve => ReactDom.render(<ErrorModal errorCode={EC.CODE_DB_UPDATE_FAIL} onQuit={resolve} language={language} />, modalRef.current));
            errorPromise.then(() => ReactDom.unmountComponentAtNode(modalRef.current));
        }
    }, [language, onUpdateDescendants]);

    const noParking = useMemo(() => <div className="position-absolute top-50 start-50 translate-middle d-flex flex-column">
        <button onClick={addParking} className="btn btn-outline-secondary px-4 py-2">
            <i className="fa fa-plus"></i>
        </button>
        <span className="text-muted mt-2">{Text.SF_NO_PARKINGS[language]}</span>
    </div>, [language, addParking]);

    const parkingTableHeader = useMemo(() => <thead>
        <tr>
            <th scope="col">#</th>
            <th scope="col">name</th>
            <th scope="col">nbPlaces</th>
            <th scope="col">Area</th>
            <th>Compteur</th>
            <th>Eclairage</th>
            <th>GAMMES.IRVE</th>
            <th>Autre</th>
            <th>Note</th>
            <th scope="col"></th>
        </tr>
    </thead>, []);

    const parkingNotesOptions = useMemo(() => [
        "Présence de compteur commun"
    ], []);

    const parkingPanel = useMemo(() => {
        if (parkingWithLightsAndIRVE.length === 0) return noParking;

        return <div className="flex-grow-1 d-flex flex-column overflow-auto">
            <table className="table text-center align-middle">
                {parkingTableHeader}
                <tbody>
                    {parkingWithLightsAndIRVE.map(({ _id, data, lights, irves }, i) => <tr key={_id}>
                        <th scope="row" className="align-middle">{i + 1}</th>
                        <td className="w-50">
                            <input className="form-control" value={data?.name ?? ""} onChange={e => onChangeParking(_id, "name", e.target.value)} />
                        </td>
                        <td>
                            <input className="form-control" type="number" value={data?.capacity ?? ""} min={0} onChange={e => onChangeParking(_id, "capacity", parseInt(e.target.value) || "")} />
                        </td>
                        <td>
                            <input className="form-control" type="number" value={data?.area ?? ""} min={0} onChange={e => onChangeParking(_id, "area", parseInt(e.target.value) || "")} />
                        </td>
                        <td>
                            <button onClick={() => addEntity?.(siteId, _id)} className="btn btn-primary">
                                <i className="fa fa-plus"></i>
                            </button>
                        </td>
                        <td>
                            <button onClick={() => parkingLightsAddEdit(_id, parkingLightsId)} className="btn btn-primary">
                                <i className={"fa fa-" + (Array.isArray(lights) && lights.length > 0 ? "pencil-alt" : "plus")}></i>
                            </button>
                        </td>
                        <td>
                            <button onClick={() => parkingLightsAddEdit(_id, irveId)} className="btn btn-primary">
                                <i className={"fa fa-" + (Array.isArray(irves) && irves.length > 0 ? "pencil-alt" : "plus")}></i>
                            </button>
                        </td>
                        <td>
                            <button onClick={() => parkingLightsAddEdit(_id)} className="btn btn-primary">
                                <i className="fa fa-plus"></i>
                            </button>
                        </td>
                        <td>
                            <button className="btn btn-primary" onClick={() => addNote?.(_id, parkingNotesOptions)}><i className="fa fa-comment-alt"></i></button>
                        </td>
                        <td>
                            <button className="btn btn-danger" onClick={() => removeParking(_id)}>
                                <i className="fa fa-times"></i>
                            </button>
                        </td>
                    </tr>)}
                </tbody>
            </table>
            <div className="position-sticky bottom-0 bg-white p-2 flex-shrink-1">
                <button className="btn btn-primary" onClick={addParking}>
                    <i className="fa fa-plus mr-2"></i> {Text.GLOBAL_LABEL_PARKING[language]}
                </button>
            </div>
        </div>
    }, [language, parkingWithLightsAndIRVE, noParking, parkingTableHeader, siteId, irveId, parkingLightsId, parkingNotesOptions, addParking, removeParking, onChangeParking, parkingLightsAddEdit, addNote, addEntity]);
    //#endregion

    //#region Copro & Syndic
    const [copro, syndic] = useMemo(() => [coproList, syndicList].map(a => Array.isArray(a) ? a : []), [coproList, syndicList]);
    const reverseCoproList = useMemo(() => _.flatten(copro.map(({ coOwned, ...coOwner }) => coOwned.map(item => ({ ...item, coOwner })))), [copro]);
    const reverseSyndicList = useMemo(() => _.flatten(syndic.map(({ managed, ...manager }) => managed.map(item => ({ ...item, manager })))), [syndic]);

    const linkedSyndic = useMemo(() => reverseSyndicList.map(({ _id, manager, ...r }) => {
        let link = links.filter(({ input, output, type }) => type === gestionLinkType && input === manager?._id && output === _id)?.[0]?._id;
        return { _id, manager, link, ...r };
    }), [gestionLinkType, links, reverseSyndicList]);

    const coproPercentage = useMemo(() => reverseCoproList.map(({ _id, coOwner, ...r }) => {
        let link = links.filter(({ input, output, type }) => type === coOwnLinkType && input === coOwner?._id && output === _id)?.[0]?._id;
        let ownerShipObj = ownership.filter(({ data }) => data.link === link)?.[0]
        let pct = ownerShipObj?.data?.pctOwnership;
        return { _id, coOwner, pct, ownerShipId: ownerShipObj?._id, link, ...r };
    }), [coOwnLinkType, links, ownership, reverseCoproList]);

    const linkFetched = useMemo(() => ownership.map(({ data }) => data?.link).filter(TB.mongoIdValidator), [ownership]);
    const linkToFetch = useMemo(() => reverseCoproList.map(({ _id, coOwner }) => ({ input: coOwner?._id, output: _id })).filter(({ input, output }) => TB.multiMongoIdValidator([input, output])), [reverseCoproList]);

    useEffect(() => {
        let isSubscribed = true;

        const getOwnerShips = async () => {
            let filter = { _id: { $nin: linkFetched }, type: coOwnLinkType, $or: linkToFetch };
            let replyLinks = await getLinksFromFilter(filter);
            if (!Array.isArray(replyLinks.data) || replyLinks.data.length === 0) return;
            let replyOwnership = await getManySubmissionsFromFilter({ form: ownerShipFormId, "data.link": replyLinks.data.map(({ _id }) => _id) });
            if (!Array.isArray(replyOwnership.data) || replyOwnership.data.length === 0) return;
            if (isSubscribed) setOwnerShip(prev => prev.concat(replyOwnership.data));
        }

        if (TB.multiMongoIdValidator([coOwnLinkType, ownerShipFormId]) && linkToFetch.length > 0) getOwnerShips();
        return () => isSubscribed = false;
    }, [coOwnLinkType, linkFetched, linkToFetch, ownerShipFormId]);

    const onAddRelation = useCallback(linkTypeId => {
        let title = linkTypeId === gestionLinkType ? "Syndicat" : "Co-propriétaire";
        let outTitle = linkTypeId === gestionLinkType ? "Gère" : "Possède";

        const getInput = () => new Promise(resolve => {
            const createNewEnterprise = () => {
                ReactDom.unmountComponentAtNode(modalRef.current);
                new Promise(res => ReactDom.render(<ReduxWrapper>
                    <div style={{ zIndex: "800000" }}>
                        <PopUpForms
                            sendBackFullObj
                            pathForm={FP.CLIENT_FORM}
                            title={Text.GLOBAL_LABEL_ENTERPRISE[language]}
                            handleForm={obj => TB.mongoIdValidator(obj?._id) ? res(obj) : res(null)}
                        />
                    </div>
                </ReduxWrapper>, secondModalRef.current))
                    .then(obj => {
                        ReactDom.unmountComponentAtNode(secondModalRef.current);
                        resolve({ input: obj?._id, newClient: obj });
                    });
            }

            new Promise(r => ReactDom.render(<BlankModal title={title} size="xl" onQuit={() => r(null)}>
                <TreeSelect
                    onValidate={r}
                    isPopUp={false}
                    close={() => r(null)}
                    fixedResource={FP.CLIENT_FORM}
                    createNew={canCreate ? createNewEnterprise : null}
                />
            </BlankModal>, modalRef.current))
                .then(id => {
                    ReactDom.unmountComponentAtNode(modalRef.current);
                    if (!TB.mongoIdValidator(id)) resolve(null);
                    else resolve(id);
                })
        });

        const getOutput = () => new Promise(resolve => {
            new Promise(r => ReactDom.render(<LightTree
                popUpSize
                root={siteId}
                title={outTitle}
                onValidate={r}
                restrictOwnLinks
                onClose={() => r(null)}
                linkRestriction={{ objForm: clientFormId, linkType: linkTypeId === gestionLinkType ? LT.LINK_TYPE_GESTION : LT.LINK_TYPE_CO_OWN }}
            />, modalRef.current))
                .then(id => {
                    ReactDom.unmountComponentAtNode(modalRef.current);
                    if (!TB.mongoIdValidator(id)) resolve(null);
                    else resolve(id);
                });
        });

        // Pick input (Tree Select only enterprises)
        getInput().then(results => {
            let { input, newClient } = TB.validObject(results) ? results : { input: results };

            if (input === null) return;
            // Pick output (Light Tree root = site)
            else getOutput().then(async output => {
                if (output === null) return;
                else {
                    let replyData = await getManySubmissionsFromFilter({ _id: [input, output] });
                    if (!Array.isArray(replyData.data)) showErrorModal(EC.CODE_DB_UPDATE_FAIL);
                    else {
                        let newNodes = [...replyData.data, newClient].filter(TB.validObject).filter(({ _id }) => TB.mongoIdValidator(_id));

                        let link = { input, output, type: linkTypeId };
                        let reply = await AttachMultipleObjParents([link], user);
                        if (reply === true) onUpdateTree(({ links, descendance }) => ({
                            links: links.concat(link),
                            descendance: _.uniqBy(descendance.concat(newNodes), "_id")
                        }))
                        else showErrorModal(EC.CODE_DB_UPDATE_FAIL);
                    }
                }
            });
        });
    }, [siteId, clientFormId, gestionLinkType, canCreate, user, language, showErrorModal, onUpdateTree]);

    const onRemoveRelation = useCallback((linkId, objId) => {
        let promises = [];
        if (TB.mongoIdValidator(linkId)) promises.push(new Promise(res => deleteLinks(linkId).then(({ data }) => res(data?.ok === 1))));
        if (TB.mongoIdValidator(objId)) promises.push(new Promise(res => removeSubmission(objId).then(({ data }) => res(TB.mongoIdValidator(data?._id)))));

        Promise.all(promises).then(async results => {
            if (!results.every(Boolean)) showErrorModal(EC.CODE_DB_DELETE_FAIL).then(() => navigate("/", { replace: true }));
            else onUpdateLinks(prev => prev.filter(({ _id }) => _id !== linkId));
        });
    }, [navigate, showErrorModal, onUpdateLinks]);

    //#region Syndic
    const syndicTableHeader = useMemo(() => <thead>
        <tr>
            <th>{Text.GLOBAL_LABEL_ENTERPRISE[language]}</th>
            <th>Gere</th>
            <th></th>
        </tr>
    </thead>, [language]);

    const syndicTableFooter = useMemo(() => <tfoot className="text-start">
        <tr>
            <td colSpan={3}>
                <button onClick={() => onAddRelation(gestionLinkType)} className="btn btn-primary">
                    <i className="fa fa-plus mr-2"></i> Syndic
                </button>
            </td>
        </tr>
    </tfoot>, [gestionLinkType, onAddRelation]);

    const syndicTable = useMemo(() => <table className="table table-sm table-bordered">
        {syndicTableHeader}
        <tbody>
            {linkedSyndic.map(({ _id, data, manager, link }) => <tr key={_id}>
                <td>{manager?.data?.name}</td>
                <td>{data?.name}</td>
                <td>
                    <button onClick={() => onRemoveRelation(link)} className="btn btn-danger">
                        <i className="fa fa-times"></i>
                    </button>
                </td>
            </tr>)}
        </tbody>
        {syndicTableFooter}
    </table>, [linkedSyndic, syndicTableFooter, syndicTableHeader, onRemoveRelation]);
    //#endregion

    //#region Copro
    const onChangePct = useCallback(async (objId, linkId, value) => {
        let newValue = isNaN(parseFloat(value)) ? "" : parseFloat(value);
        if (newValue > 100 || newValue < 0) return;

        if (TB.mongoIdValidator(objId)) {
            let update = { "data.pctOwnership": newValue };
            let reply = await updateSubmission(objId, update);
            if (TB.mongoIdValidator(reply?.data?._id)) setOwnerShip(prev => prev.map(o => {
                if (o?._id === objId) o.data.pctOwnership = newValue;
                return o;
            }));
            else showErrorModal(EC.CODE_DB_UPDATE_FAIL);
        }
        else if (TB.mongoIdValidator(linkId)) {
            let obj = TB.blankSub(user?._id, ownerShipFormId);
            obj.data = { pctOwnership: newValue, link: linkId };
            let reply = await createManySubmissions([obj]);
            if (Array.isArray(reply?.data) && TB.mongoIdValidator(reply.data[0]?._id)) setOwnerShip(prev => prev.concat(reply.data));
            else showErrorModal(EC.CODE_DB_UPDATE_FAIL);
        }
    }, [user, ownerShipFormId, showErrorModal]);

    const coproTableHeader = useMemo(() => <thead>
        <tr>
            <th>{Text.GLOBAL_LABEL_ENTERPRISE[language]}</th>
            <th>Bien</th>
            <th>% possession</th>
            <th></th>
        </tr>
    </thead>, [language]);

    const coproTableFooter = useMemo(() => <tfoot className="text-start">
        <tr>
            <td colSpan={4}>
                <button onClick={() => onAddRelation(coOwnLinkType)} className="btn btn-primary">
                    <i className="fa fa-plus mr-2"></i> Copropriété
                </button>
            </td>
        </tr>
    </tfoot>, [coOwnLinkType, onAddRelation]);

    const coproTable = useMemo(() => <table className="table table-sm table-bordered">
        {coproTableHeader}
        <tbody>
            {coproPercentage.map(({ _id, data, coOwner, pct, ownerShipId, link }) => <tr key={_id}>
                <td>{coOwner?.data?.name}</td>
                <td>{data?.name}</td>
                <td>
                    <input type="number" value={pct ?? ""} min={0} max={100} onChange={e => onChangePct(ownerShipId, link, e.target.value)} className="form-control" />
                </td>
                <td>
                    <button onClick={() => onRemoveRelation(link, ownerShipId)} className="btn btn-danger">
                        <i className="fa fa-times"></i>
                    </button>
                </td>
            </tr>)}
        </tbody>
        {coproTableFooter}
    </table>, [coproTableFooter, coproTableHeader, coproPercentage, onChangePct, onRemoveRelation]);
    //#endregion

    const coproSyndicPanel = useMemo(() => <div className="row g-1 h-50 text-center">
        <div className="col-md-6 border overflow-auto p-1" style={{ maxHeight: "22.5rem" }}>
            {syndicTable}
        </div>
        <div className="col-md-6 border overflow-scroll p-1" style={{ maxHeight: "22.5rem" }}>
            {coproTable}
        </div>
    </div>, [coproTable, syndicTable]);
    //#endregion

    //#region Panels
    const panelsList = useMemo(() => [
        { label: "Parcel & Plan", content: parcelPlanPanel },
        { label: "Syndic & Copro", content: coproSyndicPanel },
    ], [parcelPlanPanel, coproSyndicPanel]);

    const panelNav = useMemo(() => <div className="w-100 px-2">
        <ul className="nav nav-pills nav-fill">
            {panelsList.map(({ label }, i) => <li key={i} className="nav-item">
                <button onClick={() => setActivePanel(i)} className={`nav-link ${i === activePanel ? "active" : ""}`}>{label}</button>
            </li>)}
        </ul>
    </div>, [panelsList, activePanel])

    const currentContent = useMemo(() => panelsList[activePanel].content, [panelsList, activePanel]);
    //#endregion

    return <div className="h-100">
        <div style={{ height: "3rem" }}>
            {panelNav}
        </div>
        <div className="h-100">
            <div className="p-2 h-100">
                {currentContent}

                <div>
                </div>

                <div className="h-50 w-100 p-2 d-flex flex-column shadow-sm position-relative" style={{ maxHeight: "40vh" }}>
                    <div className="flex-shrink-1"><h5>{Text.GLOBAL_LABEL_PARKING[language]}</h5></div>
                    {parkingPanel}
                </div>
                <div ref={modalRef}></div>
                <div ref={secondModalRef}></div>
            </div>
        </div>
    </div>
}

export default SitePanel;