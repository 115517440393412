import * as D from "../../NewDia";
import { TB } from "../../../Constants";
import renderInContainer from "../getContainer";

export type RenderLightTreeProps = Omit<D.LightTreeProps, "onClose" | "onValidate" | "modal">;

const renderLightTree = (params?: RenderLightTreeProps) => new Promise<Parameters<D.LightTreeProps["onValidate"]>[0]>(resolve => {
    let [render, dismount] = renderInContainer();
    if (!TB.validObject(params)) params = { root: "" };

    if (render && dismount) {
        let onClose = () => dismount?.(() => resolve(null));
        let onConfirm: D.LightTreeProps["onValidate"] = format => dismount?.(() => resolve(format));
        render(<D.LightTree {...params} modal onClose={onClose} onValidate={onConfirm} />);
    }
    else resolve(null);
});

export default renderLightTree;