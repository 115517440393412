import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Avatar } from '../../../Common';
import SimpleBarReact from 'simplebar-react';
import { useState, FC, useEffect, useMemo } from 'react';
import { Card, Col, Dropdown, Row } from 'react-bootstrap';

type QuickLinkItemProps = {
  avatar?: string;
  avatarText?: string;
  img?: string;
  title?: string;
  link?: string;
  hr?: boolean;
};

const quickLinks = [
  {
    // avatar: account,
    title: 'Account',
    link: `/user/profile`
  },
  {
    // img: themewagon,
    title: 'Themewagon',
    link: 'https://themewagon.com/'
  },
  {
    // img: mailbluster,
    title: 'Mailbluster',
    link: 'https://mailbluster.com/'
  },
  // { img: google, title: 'Google' },
  // { img: spotify, title: 'Spotify' },
  // { img: steam, title: 'Steam' },
  // { img: githubLight, title: 'Github' },
  // { img: discord, title: 'Discord' },
  // { img: xbox, title: 'xbox' },
  // { img: trello, title: 'Kanban' },
  // { img: hp, title: 'Hp' },
  { hr: true },
  // { img: linkedin, title: 'Linkedin' },
  // { img: twitter, title: 'Twitter' },
  // { img: facebook, title: 'Facebook' },
  // { img: instagram, title: 'Instagram' },
  // { img: pinterest, title: 'Pinterest' },
  // { img: slack, title: 'Slack' },
  // { img: deviantart, title: 'Deviantart' },
  {
    avatarText: 'E',
    title: 'Events',
    link: `/events/event-detail`,
    contentClass: 'bg-soft-primary text-primary'
  }
];

const NineDotMenu: FC = ({ ...props }) => {
  const [show, setShow] = useState(null);

  useEffect(() => window.addEventListener('scroll', () => window.innerWidth < 1200 && setShow(false)), []);

  return <Dropdown navbar={true} as="li" show={show} onToggle={() => setShow(!show)}>
    <Dropdown.Toggle bsPrefix="toggle" as={Link} to="#!" className="nav-link px-2 nine-dots" >
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="37" viewBox="0 0 16 16" fill="none" >
        <circle cx="2" cy="2" r="2" fill="#6C6E71"></circle>
        <circle cx="2" cy="8" r="2" fill="#6C6E71"></circle>
        <circle cx="2" cy="14" r="2" fill="#6C6E71"></circle>
        <circle cx="8" cy="8" r="2" fill="#6C6E71"></circle>
        <circle cx="8" cy="14" r="2" fill="#6C6E71"></circle>
        <circle cx="14" cy="8" r="2" fill="#6C6E71"></circle>
        <circle cx="14" cy="14" r="2" fill="#6C6E71"></circle>
        <circle cx="8" cy="2" r="2" fill="#6C6E71"></circle>
        <circle cx="14" cy="2" r="2" fill="#6C6E71"></circle>
      </svg>
    </Dropdown.Toggle>

    <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg" show={show} >
      <Card className="dropdown-menu-end shadow-none">
        {/* @ts-ignore */}
        <SimpleBarReact className="nav-quick-links">
          <Card.Body>
            <Row className="text-center g-0">
              {quickLinks.map((item, index) => <QuickLinkItem key={index} {...item} />)}
              <Col xs={12}>
                <Link to="#!" className="btn btn-outline-primary btn-sm mt-4">
                  Show more
                </Link>
              </Col>
            </Row>
          </Card.Body>
        </SimpleBarReact>
      </Card>
    </Dropdown.Menu>
  </Dropdown>
};

const QuickLinkItem: FC<QuickLinkItemProps> = ({ avatar, avatarText, img, title, link, hr }) => {

  const hrLine = useMemo(() => hr ? <Col xs={12}><hr className="my-3 mx-n3 bg-200" /></Col> : undefined, [hr]);

  const quickItem = useMemo(() => hr ? undefined : <Col xs={4}>
    <a className="d-block hover-bg-200 px-2 py-3 rounded-3 text-decoration-none" target={'_blank'} rel="noopener noreferrer" href={link || '#!'} >
      {avatar && <Avatar src={avatar} size="2xl" />}
      {avatarText && <Avatar isExact name={avatarText} size="2xl" mediaClass="fs-2 bg-soft-primary text-primary" />}
      {img && <img src={img} width={40} height={40} alt="" />}
      <p className={classNames('mb-0 fw-medium text-800 text-truncate fs--2', { 'pt-1': img })}>
        {title}
      </p>
    </a>
  </Col>, [avatar, avatarText, hr, img, link, title]);

  return <>
    {hrLine}
    {quickItem}
  </>
};

export default NineDotMenu;
