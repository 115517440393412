import * as T from "../Types";
import * as FP from "./FormPaths";

type TagInfo = {
    /**  */
    form: string;
    /**  */
    prop: string;
    /**  */
    prop_id: string;
    /**  */
    type: T.NoteTag["type"];
}

/** The list of tags, where to find them, what they use as reference, etc... */
export const tags_infos: TagInfo[] = [
    { form: FP.SITE_FORM, prop: "tags" as keyof T.SiteData, prop_id: "_id", type: "site" },
    { form: FP.EQUIPEMENT_FORM, prop: "tags" as keyof T.EquipmentData, prop_id: "_id", type: "equip" },
    { form: FP.EQUIPMENT_GAMME, prop: "tags" as keyof T.EquipmentData, prop_id: "_id", type: "equip" },
    { form: FP.BUILDING_FORM, prop: "tags" as keyof T.BuildingData, prop_id: "_id", type: "build_tag" },
    { form: FP.BUILDING_FORM, prop: "affect_detail" as keyof T.BuildingData, prop_id: "_id", type: "building" },
    { form: FP.EMPLACEMENT_FORM, prop: "tags" as keyof T.EmplacementData, prop_id: "_id", type: "emplacement" },
    { form: FP.NOTES_PATH, prop: "tags" as keyof T.NoteData, prop_id: "origin" as keyof T.NoteData, type: "note" },
    { form: FP.EMPLACEMENT_FORM, prop: "actif_detail" as keyof T.EmplacementData, prop_id: "_id", type: "building" },
    { form: FP.TICKET_FORM, prop: "tags" as keyof T.TicketData, prop_id: "equipment" as keyof T.TicketData, type: "ticket" },
    { form: FP.ACTION_REG_FORM, prop: "doneBy" as keyof T.RegAction, prop_id: "site" as keyof T.RegAction, type: "reg_done_by" },
    { form: FP.REMARQUES_DEFAULTS, prop: "tags" as keyof T.RemarqueDefault, prop_id: "element" as keyof T.RemarqueDefault, type: "rems" },
];