import React from "react";
import * as echarts from 'echarts/core';
import ReactEcharts, { EChartsReactProps } from "echarts-for-react";
import * as CP from 'echarts/components';
echarts.use([CP.TitleComponent, CP.TooltipComponent, CP.GridComponent, CP.LegendComponent]);

export type EchartsRef = ReactEcharts;
export type EchartsProps = Omit<EChartsReactProps, "echarts">;

const Echarts = React.forwardRef<ReactEcharts, EchartsProps>((props, ref) => {

    return <ReactEcharts {...props} ref={ref} echarts={echarts} />;
});

export default Echarts;