import React from "react";
import * as I from "../types";
import * as BS from "react-bootstrap";
import * as H from "../../../../hooks";
import * as C from "../../../../Common";
import { T, TB, TC } from "../../../../Constants";

//#region Types
export type Props = {};
export type FilterModel = ("blank" | "clear" | "somber")[];
//#endregion

//#region Constants
const Filters = [
    { label: "(blanks)", value: "blank" },
    { label: TC.GLOBAL_COLOR_CLEAR, value: "clear" },
    { label: TC.GLOBAL_COLOR_DARK, value: "somber" },
] as T.Option<{}, FilterModel[number]>[];
//#endregion

export const type = "agCustomColor";

export const Cell = React.forwardRef<{}, I.CellProps<Props>>((props, ref) => <div className="p-1 h-100 w-100 border">
    <div style={{ backgroundColor: TB.getColor(props.getValue?.()) || "" }} className="h-100 w-100" />
</div>);

export const Filter = React.forwardRef<I.FilterRef<FilterModel>, I.FilterProps<Props>>((props, ref) => {
    const [filters, setFilters] = React.useState<FilterModel>(Filters.map(o => o.value));

    const toggleFilter = React.useCallback((filter: FilterModel[number]) => setFilters(p => {
        if (p.includes(filter)) return p.filter(s => s !== filter);
        else return p.concat(filter);
    }), []);

    React.useImperativeHandle(ref, () => ({
        setModel: model => setFilters(model?.value || []),
        getModel: () => ({ value: filters }),
        isFilterActive: () => true,
        doesFilterPass: params => {
            // All filters are active, so all values pass the conditions
            if (filters.length === Filters.length) return true;
            let value = TB.getColor(
                props.valueGetter({ ...props, node: params?.node, data: params?.node?.data, getValue: field => params?.node?.data?.[field] })
            );
            let isBlank = value === null;
            let isClear = !isBlank && TB.isColorWhite(value);
            let passBlank = filters.includes("blank") && isBlank;
            let passClear = filters.includes("clear") && isClear;
            let passSomber = filters.includes("somber") && !isClear;

            return passBlank || passClear || passSomber;
        },
    }), [filters, props]);

    React.useEffect(() => props.filterChangedCallback?.(), [filters, props]);

    return <div className='p-2'>
        {Filters.map(o => <C.Form.CheckBox
            key={o.value}
            noBottomMargin
            label={o.label}
            customClass="mb-1"
            labelPosition="right"
            value={filters.includes(o.value)}
            onChange={() => toggleFilter(o.value)}
        />)}
    </div>
});

export const EditCell = React.forwardRef<I.EditorRef<string>, I.EditorProps<Props>>((props, ref) => {
    const input = React.useRef<HTMLInputElement>(null);
    const container = React.useRef<HTMLDivElement>(null);
    H.useOnClickOutside(container, () => props.stopEditing?.());
    const [selectedValue, setValue] = React.useState(props.value);
    const color = React.useMemo(() => TB.getColor(selectedValue) || "", [selectedValue]);

    React.useImperativeHandle(ref, () => ({ getValue: () => color }));

    React.useEffect(() => {
        // Auto open the picker pop-up
        let to = setTimeout(() => input.current?.click?.(), 1);
        return () => clearTimeout(to);
    }, []);

    return <div ref={container} tabIndex={-1} className="h-100">
        <BS.FormControl ref={input} type="color" className="mx-auto w-100" value={color} onChange={e => setValue(e.target.value)} />
    </div>
});