import { settings } from '../config';

export const configReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET_CONFIG':
      if (payload.setInStore) localStorage.setItem(payload.key, payload.value);
      return { ...state, [payload.key]: payload.value };

    case 'REFRESH': return { ...state };

    case 'RESET':
      localStorage.clear();
      return { ...state, ...settings };

    default: return state;
  }
};
