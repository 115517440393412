import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as H from '../../hooks';
import * as TC from "../../Constants/textCode";

const TEXT_CODES = [TC.ERR_404_TITLE, TC.ERR_404_DESC, TC.ERR_404_CONTACT_US, TC.ERR_404_RETURN_HOME];

const Error404: React.FC = () => {
    const lg = H.useLanguage(TEXT_CODES);

    return <Card className="text-center">
        <Card.Body className="p-5">
            <div className="display-1 text-300 fs-error">404</div>
            <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
                {lg.getStaticText(TC.ERR_404_TITLE)}
            </p>
            <hr />
            <p>
                {lg.getStaticText(TC.ERR_404_DESC)}
                <a href="mailto:info@automatronic.be" className="ms-1">{lg.getStaticText(TC.ERR_404_CONTACT_US)}</a>.
            </p>
            <Link className="btn btn-primary btn-sm mt-3" to="/">
                <i className='fa fa-home me-2'></i>
                {lg.getStaticText(TC.ERR_404_RETURN_HOME)}
            </Link>
        </Card.Body>
    </Card>
};

export default Error404;
