import React from "react";
import { TB } from "../../../Constants";
import renderInContainer from "../getContainer";
import { TimeFormatter, TimeFormatterProps } from "../Components";

export type AskTimeFormatModalProps = Omit<TimeFormatterProps, "onClose" | "onConfirm">;

const askTimeFormat = (params?: AskTimeFormatModalProps) => new Promise<Parameters<TimeFormatterProps["onConfirm"]>[0]>(resolve => {
    let [render, dismount] = renderInContainer();
    if (!TB.validObject(params)) params = {};

    if (render && dismount) {
        let onClose = () => dismount?.(() => resolve(null));
        let onConfirm: TimeFormatterProps["onConfirm"] = format => dismount?.(() => resolve(format));
        render(<TimeFormatter {...params} onClose={onClose} onConfirm={onConfirm} />);
    }
    else resolve(null);
});

export default askTimeFormat;