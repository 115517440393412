import moment from "moment";
import { Dialog } from "@material-ui/core";
import { LANG, TB } from "../../Constants";
import { ReduxWrapper, TimeSelector } from "../../Common";
import renderInContainer from "./getContainer";
import React, { FC, isValidElement, ReactNode, useMemo, useState } from "react";

type TimeObj = { dateFrom?: string, dateTo?: string, interval?: string };

type GrafanaModalFnProps = {
    title?: string;
    language?: number;
    footer?: ReactNode;
    defaultTimeObj?: TimeObj;
    getUrl?: (params: { from: number, to: number }) => string;
}

interface GrafanaModalProps extends GrafanaModalFnProps {
    onQuit?: () => void;
}

const GrafanaModal: FC<GrafanaModalProps> = ({ onQuit, footer, title, getUrl, language = LANG.FR, defaultTimeObj, ...props }) => {
    const [{ dateFrom, dateTo, interval }, setTime] = useState<TimeObj>(TB.validObject(defaultTimeObj) ? defaultTimeObj : { interval: '15 DAY' });

    //#region Labels
    const validTitle = useMemo(() => TB.validString(title) ? title : "", [title]);
    //#endregion

    //#region Time Selector
    const timeSelector = useMemo(() => <div>
        <TimeSelector
            from={dateFrom}
            to={dateTo}
            interval={interval}
            onChangeInterval={interval => setTime({ interval })}
            onChangeDatePicker={({ from, to }) => setTime({ dateFrom: from, dateTo: to })}
        />
    </div>, [dateFrom, dateTo, interval]);

    const translatedInterval = useMemo(() => {
        if (typeof interval !== "string") return null;
        let [number, time] = interval.split(/\s/g);
        return { number: parseInt(number), time: time.toLowerCase() + 's' };
    }, [interval]);

    const { from, to } = useMemo(() => {
        /* @ts-ignore */
        if ([dateFrom, dateTo].every(date => !isNaN(Date.parse(date)))) return { from: new Date(dateFrom).getTime(), to: new Date(dateTo).getTime() };
        /* @ts-ignore */
        if (translatedInterval !== null) return { from: moment().subtract(translatedInterval.number, translatedInterval.time).unix() * 1000, to: new Date().getTime() };
        return { from: moment().subtract(2, 'w').unix() * 1000, to: new Date().getTime() };
    }, [dateFrom, dateTo, translatedInterval]);
    //#endregion

    //#region Graph
    const graphSource = useMemo(() => getUrl?.({ from, to }), [from, to, getUrl]);

    const graph = useMemo(() => <div style={{ height: "525px" }}>
        <div className="mx-auto" style={{ width: "90%" }}>
            <iframe title="g1" width="100%" height="500" frameBorder="0" src={graphSource} />
        </div>
    </div>, [graphSource]);
    //#endregion

    //#region Footer
    const isFooterValid = useMemo(() => [isValidElement, TB.isDomElement, Array.isArray].some(fn => fn(footer)), [footer]);
    //#endregion

    //#region Modal Parts
    const modalHeader = useMemo(() => <div className="modal-header">
        <h5 className="modal-title">{validTitle}</h5>
        <button className="btn-close" onClick={onQuit}></button>
    </div>, [validTitle, onQuit]);

    const modalBody = useMemo(() => <div className="modal-body">
        {timeSelector}
        {graph}
    </div>, [timeSelector, graph]);

    const modalFooter = useMemo(() => !isFooterValid ? undefined : <div className="modal-footer">
        {footer}
    </div>, [isFooterValid, footer]);
    //#endregion

    return <ReduxWrapper>
        <Dialog open fullWidth maxWidth="lg" onClose={onQuit} PaperProps={{ className: "animated_scale_in" }}>
            <div className="modal-content">
                {modalHeader}
                {modalBody}
                {modalFooter}
            </div>
        </Dialog>
    </ReduxWrapper>
}

export default GrafanaModal;

export const renderGrafanaModal = (params?: GrafanaModalFnProps) => new Promise<null>(resolve => {
    let [render, dismount] = renderInContainer();
    if (!TB.validObject(params)) params = {};
    if (render && dismount) {
        let onQuit = () => dismount(() => resolve(null));

        render(<ReduxWrapper>
            <GrafanaModal {...params} onQuit={onQuit} />
        </ReduxWrapper>);
    }
    else resolve(null);
});