import _ from "lodash";
import moment from "moment";
import * as S from "../../services";
import { T, TB, TC, FREQ, RIGHTS } from "../../Constants";
import { ErrorBanner, Flex, TypeAhead } from "../../Common";
import { askConfirm, Loader, renderAlert, renderRegDoc } from "../Modal";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useAsyncState, useAuth, useBoolean, useLanguage, useRights } from "../../hooks";
import { Button, Col, FormCheck, FormControl, InputGroup, Row, FormLabel, OverlayTrigger, Tooltip } from "react-bootstrap";

//#region Types
type ActionRegUpdaterProps = {
    label?: string;
    category?: string;
    disabled?: boolean;
    elementId?: string;
    roots?: string | string[];
    itemActions?: T.ActionValueItem[];
    onChangeActions?: (actions: T.ActionValueItem[]) => void;
}

type Resources = { regDocs: T.RegDoc[], actions: T.API.Reg.RegActionResume[] }
//#endregion

//#region Constants
const COL_SIZES = [4, 4, 1, 1, 1, 1];
const DISABLED_COL_SIZES = [4, 3, 2, 2, 1, 0];
const DF_INIT: Resources = { actions: [], regDocs: [] };

const TEXT_CODES = [
    TC.REG_FORM_REGLEMENTATION, TC.GLOBAL_SERVICE_WORKER, TC.REG_LAST_DOC, TC.GLOBAL_STATUS, TC.DIA_NO_DATA,
    TC.REG_FAILED_CONDITION, TC.REG_INVALID_REGION_COUNTRY, TC.REG_NOT_COMPATIBLE_GAMME_EQUIP, TC.REG_GAMME_UNPRECISE,
    TC.REG_FORM_NOTE, TC.REG_FORM_CONFORM, TC.REG_FORM_INFRACTION, TC.GLOBAL_NO_DOC, TC.REG_MAKE_ACTIVE, TC.REG_MAKE_INACTIVE,
    TC.REG_DELETE_ACTIVE_NOW, TC.REG_NEXT_CONTROL, TC.REG_NO_FORMULA, TC.REG_INVALID_FORMULA, TC.REG_INVALID_RESOURCE, TC.REG_MISSING_TERM,
    TC.GLOBAL_CHOICE_WILL_AFFECT_IMMEDIATELY, TC.ACTION_SHOW_ALL_ACTIONS, TC.GLOBAL_VALID_UNTIL, TC.GLOBAL_DELETE, TC.REG_AUTO_SAVE_ELEMENTS,
];
//#endregion

const ActionRegUpdater: FC<ActionRegUpdaterProps> = ({ elementId, roots, label, itemActions, disabled, onChangeActions }) => {
    const rights = useRights();
    const [{ userId }] = useAuth();
    const lg = useLanguage(TEXT_CODES);
    const showOnlyConform = useBoolean(true);
    const [items, setItems] = useState<typeof itemActions>([]);
    const [{ actions, regDocs }, setResource, status] = useAsyncState<Resources>(DF_INIT, "load");

    useEffect(() => {
        if (Array.isArray(itemActions) && itemActions.length > 0) setItems(itemActions)
    }, [itemActions]);

    //#region Rights
    const canAddDelete = useMemo(() => rights.isRightAllowed(RIGHTS.REG.EDIT_APPLICABLE_REG, elementId), [rights, elementId]);
    const canEditOwnDoc = useMemo(() => rights.isRightAllowed(RIGHTS.REG.WRITE_OWN_REG_DOCS, elementId), [rights, elementId]);
    const canEditOthersDoc = useMemo(() => rights.isRightAllowed(RIGHTS.REG.WRITE_OTHER_REG_DOCS, elementId), [rights, elementId]);
    //#endregion

    const colSizes = useMemo(() => disabled && canAddDelete ? DISABLED_COL_SIZES : COL_SIZES, [disabled, canAddDelete]);

    //#region Load Data
    useEffect(() => {
        let isSubscribed = true;

        S.getActionsItem(elementId)
            .then(({ data }) => isSubscribed && setResource(r => ({ ...r, ...data }), "done"))
            .catch(() => isSubscribed && setResource(DF_INIT, "error"));

        return () => { isSubscribed = false };
    }, [elementId, setResource]);
    //#endregion

    //#region Error & Load
    const errorBanner = useMemo(() => {
        switch (status) {
            case "load": return <Loader isPopUp={false} />;
            case "error": return <ErrorBanner type="danger" textCode={TC.GLOBAL_FAILED_LOAD} />;
            default: return null;
        }
    }, [status]);
    //#endregion

    //#region Change Callbacks
    const change = useCallback<typeof onChangeActions>(items => {
        if (typeof onChangeActions === "function") onChangeActions(items);
        else setItems(items);
    }, [onChangeActions]);

    const addItem = useCallback(() => change(items.concat({})), [items, change]);

    const changeAction = useCallback((index: number, action?: string) => {
        let previousAction = _.find(items, (it, i) => i === index)?.action;

        console.log({ index, action });

        const onChange = () => S.switchActivation({ element: elementId, deactivateAction: previousAction, activateAction: action }).then(({ data }) => {
            let ids = data.map(d => d._id);
            if (ids.length > 0) setResource(p => ({
                ...p,
                regDocs: p.regDocs
                    .filter(d => !ids.includes(d._id))
                    .concat(data),
            }));
            change(items.map((it, i) => i === index ? { ...it, action } : it))
        }).catch(() => renderAlert({ type: "error", message: TC.GLOBAL_ERROR }));

        if (previousAction) askConfirm({
            yesText: TC.GLOBAL_SAVE,
            text: TC.SCH_SAVE_BEFORE_QUIT,
            title: TC.CFQ_TITLE_AUTO_SAVE_NOTIF,
        }).then(confirmed => confirmed && onChange());
        else onChange();
    }, [items, change, setResource, elementId]);

    const removeAction = useCallback((index: number) => {
        askConfirm({ text: TC.REG_DELETE_ACTIVE_NOW }).then(confirmed => {
            if (confirmed) {
                let toRemoveAction = _.find(items, (it, i) => i === index)?.action;
                if (toRemoveAction) S.makeRegInactive(toRemoveAction, elementId).then(({ data }) => {
                    setResource(p => ({ ...p, regDocs: data }));
                    change(items.filter((it, i) => i !== index))
                }).catch(() => renderAlert({ type: "error", message: TC.GLOBAL_ERROR_UPDATE }));
            }
        });
    }, [elementId, items, change, setResource]);

    const onCallRegDoc = useCallback((action: string, forceNewDoc?: boolean) => {
        renderRegDoc({ action, elemId: elementId, roots, forceNewDoc }).then(doc => {
            if (doc !== null) setResource(p => {
                let regDocs = p.regDocs;
                // Deleted document
                if (doc.action === "deleted") regDocs = regDocs.filter(r => r._id !== doc.doc._id);
                // Updated Document
                else if (_.find(p.regDocs, r => r._id === doc.doc._id)) regDocs = regDocs.map(r => r._id === doc.doc._id ? doc.doc : r);
                // New Document
                else regDocs = regDocs.concat(doc.doc);
                return { ...p, regDocs };
            });
        })
    }, [elementId, roots, setResource]);

    const removeElemFromDoc = useCallback((docId: string) => {
        askConfirm({ text: TC.REG_DELETE_ACTIVE_NOW }).then(confirmed => {
            if (confirmed) S.removeRegDocElem(docId, elementId, true).then(({ data }) => {
                setResource(p => {
                    let newDocs = p.regDocs.filter(r => r._id !== docId);
                    if (data !== null) newDocs = newDocs
                        .filter(d => d._id !== data._id)
                        .concat(data);

                    return { ...p, regDocs: newDocs }
                });
            }).catch(() => renderAlert({ type: "error", message: TC.GLOBAL_ERROR_UPDATE }));
        });
    }, [elementId, setResource]);

    const activateElement = useCallback((docId: string) => {
        S.activateRegDocElem(docId, elementId).then(({ data }) => {
            if (data !== null) setResource(p => ({
                ...p,
                regDocs: p.regDocs.filter(r => r._id !== data._id).concat(data),
            }))
        }).catch(() => renderAlert({ type: "error", message: TC.GLOBAL_ERROR }));
    }, [elementId, setResource]);
    //#endregion

    //#region Options
    const selectedActions = useMemo(() => items.map(a => a.action).filter(TB.mongoIdValidator), [items]);

    const actionsOptions = useMemo(() => {
        return actions.filter(a => showOnlyConform.value ? (a.conform || selectedActions.includes(a._id)) : true)
            .map(a => ({ ...a, value: a._id, label: lg.getTextObj(a._id, 'name') }));
    }, [actions, selectedActions, showOnlyConform.value, lg]);

    const renderConformityCheck = useCallback((option: Resources["actions"][0] & { label: string }, isItem = false) => {
        if (!option) return null;
        if (option.conform && !option.conformity.unPreciseGamme && !option.conformity.matchError) {
            if (!isItem) return null;
            return <Flex alignItems="center" justifyContent="start">
                <i className="me-2 fa fa-check-circle text-success"></i>
                <span>{option.label}</span>
            </Flex>
        }

        let errors: string[] = [];
        let c = option.conformity;
        let isRedAlert = !option.conform;

        if (isRedAlert) {
            if (!c.locMatch) errors.push(lg.getStaticText(TC.REG_INVALID_REGION_COUNTRY));
            else if (!c.resourceMatch) errors.push(lg.getStaticText(TC.REG_INVALID_RESOURCE));
            else if (!c.gammeMatch) errors.push(lg.getStaticText(TC.REG_NOT_COMPATIBLE_GAMME_EQUIP));
            else if (!c.conditionMatch) errors.push(lg.getStaticText(TC.REG_FAILED_CONDITION));
        }
        else {
            if (c.matchError?.noFormula) errors.push(lg.getStaticText(TC.REG_NO_FORMULA));
            else if (c.unPreciseGamme) errors.push(lg.getStaticText(TC.REG_GAMME_UNPRECISE));
            else if (c.matchError?.invalidFormula) errors.push(lg.getStaticText(TC.REG_INVALID_FORMULA));
            else if (c.matchError?.missingTerms?.length > 0) for (let t of c.matchError.missingTerms) errors.push(lg.getStaticText(TC.REG_MISSING_TERM, t));
        }

        const renderToolTip = (props) => <Tooltip {...props} className={TB.getString(props.className) + " z-index-higher-modal"} >
            {errors.map((t, i) => <div className="text-start mb-1" key={i}>{t}</div>)}
        </Tooltip>;

        let variant = "text-" + (isRedAlert ? "danger" : "warning");
        let icon = (isRedAlert ? "exclamation" : "question") + "-circle";

        let content = <OverlayTrigger overlay={renderToolTip}>
            <i className={`fa fa-${icon} ${variant}`}></i>
        </OverlayTrigger>;

        if (isItem) return <Flex alignItems="center" justifyContent="start">
            {content}
            <span className="ms-2">{option.label}</span>
        </Flex>;
        return content;
    }, [lg]);

    const getActions = useCallback((action?: string) => {
        return actionsOptions
            .filter(a => a.value === action || (!selectedActions.includes(a.value) && (a.conform || !showOnlyConform.value)))
    }, [actionsOptions, selectedActions, showOnlyConform.value]);
    //#endregion

    //#region RegDoc
    const sortByStatus = useCallback((a: T.RegFormElem, b: T.RegFormElem) => {
        if (a.status === b.status) return 0;
        if (a.status === "infraction") return -1;
        if (b.status === "infraction") return 1;
        if (a.status === "notes") return -1;
        if (b.status === "notes") return 1;
        return 0;
    }, []);

    const findRegDocs = useCallback((action?: string) => {
        if (!TB.mongoIdValidator(action)) return null;
        let actionName = _.find(actions, a => a._id === action)?.name || "N/A";

        let docs = _.reverse(regDocs
            .filter(rd => rd.regAction === action)
            .map(rd => ({ ...rd, actionName }))
            .sort(TB.sortByLastControl));

        let doc = docs[0];
        if (!doc) return null;
        return doc;
    }, [regDocs, actions]);

    const renderDoc = useCallback((action?: string) => {
        let doc = findRegDocs(action);
        let nameValue = doc ? `${doc.actionName} (${moment(doc.last_control).format("DD/MM/YY")})` : "";
        if (doc?.formerElement?.some?.(e => e.elem === elementId)) activateElement(doc._id);

        const canEdit = doc?.user === userId ? canEditOwnDoc : canEditOthersDoc;

        return <InputGroup>
            <FormControl value={nameValue} readOnly style={disabled ? {} : { backgroundColor: '#FFFFFF' }} />
            {!disabled && TB.mongoIdValidator(action) && <>
                {doc && canEdit && <>
                    <Button size="sm" variant="info" onClick={() => onCallRegDoc(action)}>
                        <i className="fa fa-pencil-alt"></i>
                    </Button>
                    <Button title={lg.getStaticText(TC.GLOBAL_DELETE)} variant="danger" size="sm" onClick={() => removeElemFromDoc(doc._id)}>
                        <i className="fa fa-times"></i>
                    </Button>
                </>}
                {canEditOwnDoc && <Button size="sm" onClick={() => onCallRegDoc(action, true)}>
                    <i className="fa fa-plus"></i>
                </Button>}
            </>}
        </InputGroup>
    }, [findRegDocs, onCallRegDoc, removeElemFromDoc, activateElement, lg, disabled, elementId, canEditOwnDoc, canEditOthersDoc, userId]);

    const renderDate = useCallback((action?: string) => {
        let doc = findRegDocs(action);
        if (!doc || !doc.frequency) return null;
        let freq = TB.splitFrequency(doc.frequency);
        return moment(doc.last_control).add(freq.num, freq.unit).format("DD/MM/YY");
    }, [findRegDocs]);

    const renderNextDate = useCallback((action?: string) => {
        let doc = findRegDocs(action);
        if (!doc || !doc.frequency) return null;
        let range = FREQ.DATE_RANGE_PER_FREQUENCY(doc.last_control, doc.frequency, 0.2);
        if (!range) return null;
        return moment(range.start).format("DD/MM/YY");
    }, [findRegDocs]);

    const renderStatus = useCallback((action?: string) => {
        let doc = findRegDocs(action);
        if (!doc) return null;
        let status = doc.currentElement.filter(e => e.elem === elementId).sort(sortByStatus)[0]?.status;
        if (status === "notes") return <span style={{ color: "#FFA726" }}>{lg.getStaticElem(TC.REG_FORM_NOTE)}</span>;
        if (status === "conform") return <span style={{ color: "#4EAE53" }}>{lg.getStaticElem(TC.REG_FORM_CONFORM)}</span>;
        if (status === "infraction") return <span style={{ color: "#FF7043" }}>{lg.getStaticElem(TC.REG_FORM_INFRACTION)}</span>;
        return null;
    }, [findRegDocs, sortByStatus, lg, elementId]);
    //#endregion

    return <>
        {errorBanner}
        {status === "done" && <>
            <Row className="mt-3 mb-1">
                <Col>
                    <Flex alignItems='center'>
                        <span className="me-2 fs-150 fw-bold">{lg.getStaticElem(label)}</span>
                        {canAddDelete && <Button disabled={disabled} onClick={addItem} size="sm">
                            <i className="fa fa-plus"></i>
                        </Button>}
                    </Flex>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <span className="text-muted">{lg.getStaticText(TC.REG_AUTO_SAVE_ELEMENTS)}</span>
                </Col>
            </Row>

            <Row className="g-3 text-center fs-110 fw-bold mb-2">
                <Col md={colSizes[0]}>{lg.getStaticElem(TC.REG_FORM_REGLEMENTATION)}</Col>
                <Col md={colSizes[1]}>{lg.getStaticElem(TC.REG_LAST_DOC)}</Col>
                <Col md={colSizes[2]}>{lg.getStaticElem(TC.GLOBAL_STATUS)}</Col>
                <Col md={colSizes[3]}>{lg.getStaticElem(TC.GLOBAL_VALID_UNTIL)}</Col>
                <Col md={colSizes[4]}>{lg.getStaticElem(TC.REG_NEXT_CONTROL)}</Col>
                {!disabled && <Col md={colSizes[6]}></Col>}
            </Row>

            {items.length === 0 && <Row>
                <Col className="text-center fst-italic" md={12}>{lg.getStaticElem(TC.DIA_NO_DATA)}</Col>
            </Row>}

            {items.length > 0 && <>
                {items.map((it, i) => <Row key={i} className="mb-3 g-3">
                    <Col md={colSizes[0]}>
                        <TypeAhead
                            disabled={disabled}
                            selectedItems={it.action}
                            options={getActions(it.action)}
                            onChange={opt => changeAction(i, opt[0]?.value)}
                            renderItem={option => renderConformityCheck(option as any, true)}
                            renderInputExtra={options => renderConformityCheck(options[0] as any)}
                        />
                    </Col>
                    <Col md={colSizes[1]}>
                        {renderDoc(it.action)}
                    </Col>
                    <Col md={colSizes[2]}>
                        {renderStatus(it.action)}
                    </Col>
                    <Col md={colSizes[3]}>
                        {renderDate(it.action)}
                    </Col>
                    <Col md={colSizes[4]}>
                        {renderNextDate(it.action)}
                    </Col>

                    {!disabled && canAddDelete && <Col md={colSizes[5]}>
                        <Button size="sm" variant="danger" onClick={() => removeAction(i)}>
                            <i className="fa fa-times"></i>
                        </Button>
                    </Col>}
                </Row>)}

                <Row className="mt-2">
                    <Col className="d-flex">
                        <FormLabel className="me-2">{lg.getStaticText(TC.ACTION_SHOW_ALL_ACTIONS)}</FormLabel>
                        <FormCheck disabled={disabled} type="switch" checked={!showOnlyConform.value} onChange={() => showOnlyConform.toggle()} />
                    </Col>
                </Row>
            </>}
        </>}
    </>;
}

export default ActionRegUpdater;