import { TB } from "../../../../Constants";
import renderInContainer from "../../getContainer";
import { ReportCells, ReportCellsProps } from "../../Components";

export type AskReportCellsProps = Omit<ReportCellsProps, "onQuit" | "onValidate">;

const askReportCells = (params?: AskReportCellsProps, containerId?: string) => new Promise<Parameters<ReportCellsProps["onValidate"]>[0] | null>(resolve => {
    let [render, dismount] = renderInContainer(containerId);
    if (!TB.validObject(params)) params = { building: "" };

    if (render && dismount) {
        let onClose = () => dismount?.(() => resolve(null));
        let onConfirm: ReportCellsProps["onValidate"] = format => dismount?.(() => resolve(format));
        render(<ReportCells {...params} onQuit={onClose} onValidate={onConfirm} />);
    }
    else resolve(null);
});

export default askReportCells;