import _ from "lodash";
import { LT } from "../Data";
import * as T from "../Types";
import * as TC from "../textCode";
import { validString } from "./string";
import { RIGHTS, FP, LANG } from "../Data";

const TEXT_CODES_ARRAY: string[] = _.concat(
    // Text codes
    Object.values(TC),
    // Rights
    RIGHTS.ALL_RIGHTS,
    // Submission types
    FP.ALL_OBJECT_TYPES,
    // Link Types
    LT.ALL_LINK_TYPES
);

const ALERTS_TYPES = ["success", "error", "info", "warning"];

/** Evaluate if a value is an existing text code */
export const isTextCode = (code?: any): code is string => {
    // Value is not a string
    if (!validString(code)) return false;
    // Is a translation references
    return TEXT_CODES_ARRAY.includes(code) || ALERTS_TYPES.includes(code);
};

/** Returns the parameter if it is a valid language code, French if not */
export const getValidLanguageCode = (code: any) => validLanguageCode(code) ? code : LANG.LANGUAGES_INDEXES[0];

/** Assess if the code is an existing language code */
export const validLanguageCode = (code: any): code is number => LANG.LANGUAGES_INDEXES.includes(code);

/** Assess if the prop is a known language prop */
export const isValidLanguageProp = (prop: unknown): prop is T.LanguageProps => LANG.ALL_PROPS.includes(prop as T.LanguageProps);