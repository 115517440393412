import React from "react";
import * as I from "../types";

export type Props<A = Record<string, any>> = {
    /** The callback to render the form */
    render_form?: (row: A) => void;
};

export const type = "agLinkedElem";

export const Cell = React.forwardRef<{}, I.CellProps<Props>>((props, ref) => {

    return <div
        children={props.value}
        className="pointer false_link"
        onClick={() => props.colDef?.params?.render_form?.(props.data)}
    />;
});