import React from "react";
import Quality from "./Quality";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as S from "../../../services";
import { T } from "../../../Constants";

export type ReportProps = {
    /** The prediction id */
    prediction: string;
    /** Use the provided training results instead of fetching it */
    training?: ReturnType<T.API.Utils.Predictions.ValidateModel>;
}

const Report: React.FC<ReportProps> = props => {
    const [training, set_training, status] = H.useAsyncState<ReportProps["training"]>(null);

    React.useEffect(() => {
        let isSubscribed = true;
        if (props.training) set_training(props.training, "done");
        else S.generateModelData(props.prediction)
            .then(({ data }) => isSubscribed && set_training(data, "done"))
            .catch(() => isSubscribed && set_training(null, "error"));
        return () => {
            isSubscribed = false;
            set_training(null, "load");
        }
    }, [props.prediction, props.training, set_training]);

    return <C.Spinner status={status}>
        <Quality is_report training={training} />
    </C.Spinner>;
}

export default Report;