// import { CellsTypes } from "../../AgGridDefs";
import { ColumnType } from "../../TableTypes";

const optionsMap = col => ({ ...col, sortable: true, filter: true, enableRowGroup: true });

const ContractsColumns: ColumnType[] = [
    { field: "data.name", headerName: "name", params: { required: true } },
    { field: "data.site", headerName: "site" },
    {
        headerName: "gammes",
        children: [...new Array(6)].map((x, i) => ({ field: "gammes." + i, headerName: i })).map(optionsMap)
    },
    { field: "data.duration", headerName: "duration" },
    { field: "data.resources", headerName: "resources" },
    { field: "data.description", headerName: "description" },
    { field: "data.frequency", headerName: "frequency" },
].map(optionsMap);

export default ContractsColumns;