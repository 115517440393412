import { ImportFormat } from "../Types/ImportStruct";

export const format: ImportFormat<Props> = {
    rem1: { key: 'O', label: "Remarques 1" },
    rem2: { key: 'N', label: "remarques 2" },
    doneBy: { key: 'G', label: "todo doneBy" },
    gammes: { key: 'B', label: "todo gammes" },
    regions: { key: 'E', label: "todo regions" },
    countries: { label: "todo countries" },
    extLink: { key: 'P', label: "todo link", type: "string" },
    paidBy: { key: 'H', label: "todo paidBy", type: "string" },
    reference: { key: 'F', label: "todo reference", type: "string" },
    condition: { key: 'K', label: "todo condition", type: "string" },
    resources: { key: 'A', label: "todo resources", required: true },
    frequency: { key: 'C', label: "todo frequency", required: true },
    docDescription: { key: 'D', label: "todo docDescription", type: "string" },
    europeanDirective: { key: 'L', label: "todo europeanDirective", type: "string" },
};

type Props = "rem1" | "rem2" | "doneBy" | "gammes" | "regions" | "countries" |
    "extLink" | "paidBy" | "reference" | "condition" | "resources" | "frequency" |
    "docDescription" | "europeanDirective";