import React from "react";
import moment from "moment";
import * as M from "../../Modal";
import * as G from "../../Gestion";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as S from "../../../services";
import { T, TC } from "../../../Constants";
import { CellsTypes as CT } from "../../Gestion/AgGridDefs";

export type HistoryProps = {
    /** The _id of the element under inspection */
    item: string;
    /** The prop under review */
    prop: string;
    /** Display the component in a popup ? */
    popup?: boolean;
    /** Extra style for the popup */
    modal?: M.StyleModalProps;
    /** Callback on the closure of the modal */
    on_quit?: () => void;
}

type Row = Data["history"][number];
type Data = ReturnType<T.API.Utils.Edits.ItemHistory>;

const History: React.FC<HistoryProps> = props => {
    const lg = H.useLanguage();
    const [data, set_data, status] = H.useAsyncState<Data>({ location: null, form: "", history: [] });

    React.useEffect(() => {
        let isSubscribed = true;
        S.itemHistory({ prop: props.prop, item: props.item })
            .then(({ data }) => isSubscribed && set_data(data, "done"))
            .catch(() => isSubscribed && set_data({ location: null, form: "", history: [] }, "error"));
        return () => {
            isSubscribed = false;
            set_data({ location: null, form: "", history: [] }, "load");
        }
    }, [props.prop, props.item, set_data]);

    const rows = React.useMemo(() => data.history.map(row => {
        let action_name = "";
        if (row.action_id) action_name = lg.getTextObj(row.action_id, "name", row.action_def_name) + ` ${moment(row.doc_date).format("DD/MM/YYYY")}`;
        return { ...row, action_name };
    }), [data.history, lg]);

    const columns = React.useMemo<G.TableProps<Row>["columns"]>(() => [
        { field: "action_date", headerName: TC.CERT_HISTORY_DATE, type: CT.TYPE_DATE, params: { isDateTime: true } },
        { field: "user_name", headerName: TC.CERT_HISTORY_USER },
        { field: "mission_name", headerName: TC.CERT_HISTORY_MISSION },
        { field: "value_str", headerName: TC.CERT_HISTORY_VALUE },
        {
            headerName: TC.CERT_HISTORY_CERTIFICATION,
            children: [
                { field: "action_name", headerName: TC.CERT_PROOF_DOCUMENT },
                { field: "doc_author", headerName: TC.REG_FORM_CONTROLLER },
                { field: "date_data", headerName: TC.CERT_HISTORY_DATA_DATE, type: CT.TYPE_DATE },
                { field: "file_proof", headerName: TC.CERT_HISTORY_FILE_PROOFS, type: CT.TYPE_FILE },
                { field: "description", headerName: TC.CERT_HISTORY_DESCRIPTION },
            ],
        }
    ], []);

    const bread_crumbs = React.useMemo(() => {
        let elements = [] as React.ReactNode[];
        let get_chevron = (index: number) => <i key={index} className="fa fs-85 text-dark fa-chevron-right mx-2" />;

        if (data.location) {
            if (data.location.site) elements.push(data.location.site, get_chevron(elements.length / 2));
            if (data.location.building) elements.push(data.location.building, get_chevron(elements.length / 2));
            if (data.location.floor) elements.push(data.location.floor, get_chevron(elements.length / 2));
            if (data.location.local) elements.push(data.location.local, get_chevron(elements.length / 2));
            if (data.location.self) elements.push(data.location.self, get_chevron(elements.length / 2));
        }
        elements.push(lg.getTextObj(data.form, props.prop, props.prop));
        return elements;
    }, [data.form, data.location, lg, props.prop]);

    return React.createElement(
        props.popup ? M.BlankModal : React.Fragment,
        props.popup ? {
            ...props.modal,
            onQuit: props.on_quit,
            title: TC.CERT_HISTORY_EDIT_HISTORY,
            isFullScreen: typeof props.modal?.isFullScreen === "boolean" ? props.modal.isFullScreen : true,
        } as M.StyleModalProps : null,
        <C.Flex direction="column" className="h-100">
            <C.Flex className="mb-1" alignItems="center">
                <i className="fa fa-clock me-2" />
                <C.Title className="mb-0" level={4} text={TC.CERT_HISTORY_EDIT_HISTORY} />
            </C.Flex>

            <C.Title className="text-info mb-3" level={5} children={bread_crumbs} />

            <G.Table<Row>
                rows={rows}
                status={status}
                columns={columns}
                sideBar="filters_columns"
                adaptableId="history_table"
                columns_base="all_but_edit"
            />

        </C.Flex>
    );
}

export default History;