import React, { FC } from "react";

export type AccessUserProfileProps = {
    user: string;
}

const AccessUserProfile: FC<AccessUserProfileProps> = ({ user }) => {


    return <>
        {user}
    </>;
}

export default AccessUserProfile;