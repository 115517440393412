import * as R from "../../../reducers";
import { renderLoader } from "../Loader";
import { TB, FP, TC } from "../../../Constants";
import * as US from "../../../services/user.service";
import { RenderModalSelectProps, askSelect } from "../ModalSelect";

interface askEquipSelectProps extends Omit<RenderModalSelectProps, "options"> {
    roots: string | string[];
}

const askEquipSelect = (params: askEquipSelectProps) => new Promise<string | null | undefined>(resolve => {
    if (!TB.validObject(params)) resolve(null);
    else {
        let unmount = renderLoader();
        let { roots, ...props } = params;

        const onError = () => {
            unmount();
            resolve(null);
            R.renderAlert({ type: "error", message: TC.GLOBAL_FAILED_LOAD });
        };

        US.getLocationList(roots, FP.EQUIPEMENT_FORM, true, FP.SITE_FORM).then(({ data }) => {
            if (Array.isArray(data)) {
                unmount();
                let options: { label: string, value: string }[] = data;
                askSelect({ ...props, options }).then((selection) => {
                    if (TB.mongoIdValidator(selection)) resolve(selection);
                    else resolve(selection)
                });
            }
            else onError();
        }).catch(onError);
    }
});

export default askEquipSelect;