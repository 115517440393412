import React from "react";
import * as I from "../types";
import * as H from "../../../../hooks";
import * as C from "../../../../Common";
import { T, TC } from "../../../../Constants";

//#region Types
export type Props<A = Record<string, any>> = {
    /** Make the dropdown menu the size of the largest option */
    auto_fit?: boolean;
    /** A static list of options */
    values?: T.Option[];
    /** Allow for multiple input select */
    multiple?: boolean;
    /** Add an empty option */
    empty_option?: boolean;
    /** Choose which property to use as value, instead of using it's base property */
    field_value?: string;
    /** A callback to get the options */
    getValues?: (row: A, colDef: I.EditorProps<Props>["colDef"]) => T.Option[] | Promise<T.Option[]>;
    /** Other values to pass on to the basic 'select' component */
    typeahead?: Omit<C.Form.SelectProps["typeahead"], "onAddOption"> & {
        /** Override of the 'onAddOption' so that it returns the row as well */
        onAddOption?: (text: string, row: A) => T.Option | Promise<T.Option>;
    };
}
//#endregion

export const type = "agCustomSelect";

const EMPTY_OPTION_VALUE = "empty_option_value";

export const EditCell = React.forwardRef<{}, I.EditorProps<Props>>((props, ref) => {
    const select = React.useRef<C.Form.SelectRef>(null);
    const container = React.useRef<HTMLDivElement>(null);
    H.useOnClickOutside(container, e => props.stopEditing());
    const [values, setValues, status] = H.useAsyncState<T.Option[]>([]);
    const params = React.useMemo(() => props.colDef.params, [props.colDef.params]);
    const [selected, setSelected] = React.useState(params.field_value ? props.data[params.field_value] : props.value);

    React.useEffect(() => {
        container.current.onkeydown = (e) => {
            if (e.code === "Enter") {
                select.current?.select_active_item?.();
                e.stopPropagation();
            }
        }
    }, []);

    // Focus on the cell on opening
    React.useEffect(() => select.current?.focus?.(), []);

    // Get options & values
    React.useEffect(() => {
        let isSubscribed = true;
        if (Array.isArray(params?.values)) setValues(params.values, "done");
        else if (typeof params?.getValues === "function") {
            let results = params?.getValues(props.data, props.colDef);

            if (Array.isArray(results)) setValues(results, "done");
            else if (results instanceof Promise) results
                .then(opt => isSubscribed && setValues(opt, "done"))
                .catch(() => isSubscribed && setValues([], "error"));
            else setValues([], "error");
        }
        else setValues([], "error");
        return () => { isSubscribed = false };
    }, [params, props.data, props.colDef, setValues]);

    // Update Ref
    React.useImperativeHandle(ref, () => ({
        getValue: () => selected,
    }), [selected]);

    const onChange = React.useCallback((value: typeof selected) => {
        if (value === EMPTY_OPTION_VALUE && params?.empty_option) value = undefined;
        setSelected(value);
        if (!params.multiple) setTimeout(() => props.stopEditing(), 50);
    }, [props, params?.empty_option, params.multiple]);

    const options = React.useMemo(() => {
        if (!params?.empty_option) return values;
        return [{ label: TC.GLOBAL_NONE, value: EMPTY_OPTION_VALUE }].concat(values);
    }, [values, params?.empty_option]);

    const add_option = React.useCallback<C.Form.SelectProps["typeahead"]["onAddOption"]>(text => {
        let new_option = params.typeahead?.onAddOption?.(text, props.data);
        if (new_option) {
            if (new_option instanceof Promise) new_option.then(option => {
                if (option) {
                    // Add the option with the others
                    setValues(p => p.concat(option));
                    // Add the value as selected
                    setSelected(p => {
                        if (params.multiple) return (p || []).concat(option.value);
                        else return option.value;
                    });
                }
            }).catch(console.error);
            else {
                // Force case because typescript doesn't do it after 'instanceof'
                let option = new_option as T.Option;
                // Add the option with the others
                setValues(p => p.concat(option));
                // Add the value as selected
                setSelected(p => {
                    if (params.multiple) return (p || []).concat(option.value);
                    else return option.value;
                });
            }
        }
    }, [params.typeahead, props.data, params.multiple, setValues]);

    return <div ref={container}>
        <C.Form.Select
            ref={select}
            noBottomMargin
            value={selected}
            options={options}
            onChange={onChange}
            multiple={params.multiple}
            loading={status === "load"}
            bold_values={EMPTY_OPTION_VALUE}
            typeahead={React.useMemo(() => ({
                ...params?.typeahead,
                hideClearButton: true,
                onAddOption: add_option,
                dropdownFit: params?.auto_fit,
            }), [params?.auto_fit, params?.typeahead, add_option])}
        />
    </div>
});