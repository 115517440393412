import React from "react";
import { Form } from "..";
import moment from "moment";
import Button from "./Button";
import * as H from "../../hooks";
import * as Falcon from "../Falcon";
import * as BS from "react-bootstrap";
import { T, TB, TC } from "../../Constants";

//#region Types
type RelativeRanges =
    "2 DAY"
    | "7 DAY"
    | "1 HOUR"
    | "3 HOUR"
    | "6 HOUR"
    | "15 DAY"
    | "30 DAY"
    | "90 DAY"
    | "1 YEAR"
    | "2 YEAR"
    | "5 YEAR"
    | "12 HOUR"
    | "24 HOUR"
    | "6 MONTH"
    | "5 MINUTE"
    | "15 MINUTE"
    | "30 MINUTE"

type TimeSelectorProps = {
    /** Current from date */
    to?: string;
    /** Current to date */
    from?: string;
    /** If true, the to day will not be taken full, it will stop at midnight, else it will got until 23:59:59 */
    toExclusive?: boolean;
    /** Force the from/to selection to be years only */
    only_years?: boolean;
    /** Allow datetime options */
    show_datetime?: boolean;
    /** Set a minimum relative time range, other time ranges under the selected one will be dismissed */
    min_relative_range?: RelativeRanges;
    /** ClassName to be given to the button */
    button_class?: string;
    interval?: string;
    stayOpenOnChange?: boolean;
    onChangeInterval?: (interval: string) => void;
    onChangeDatePicker?: (dates: T.MakeRequired<DateRange, "from" | "to">) => void;
}

type DateRange = { from?: string, to?: string };
type Errors = Partial<Record<"from" | "to", string>>;
//#endregion

//#region Constants
const TEXT_CODES = [TC.TS_MENU_BUTTON, TC.TS_RELATIVE_TIME, TC.TS_ABSOLUTE_TIME, TC.GLOBAL_FROM, TC.GLOBAL_TO, TC.TS_CONFIRM_ABSOLUTE];

const INTERVALS = [
    { value: "5 MINUTE", label: TC.TS_LAST_5_MIN },
    { value: "15 MINUTE", label: TC.TS_LAST_15_MIN },
    { value: "30 MINUTE", label: TC.TS_LAST_30_MIN },
    { value: "1 HOUR", label: TC.TS_LAST_1_HOUR },
    { value: "3 HOUR", label: TC.TS_LAST_3_HOUR },
    { value: "6 HOUR", label: TC.TS_LAST_6_HOUR },
    { value: "12 HOUR", label: TC.TS_LAST_12_HOUR },
    { value: "24 HOUR", label: TC.TS_LAST_24_HOUR },
    { value: "2 DAY", label: TC.TS_LAST_2_DAY },
    { value: "7 DAY", label: TC.TS_LAST_7_DAY },
    { value: "15 DAY", label: TC.TS_LAST_15_DAYS },
    { value: "30 DAY", label: TC.TS_LAST_30_DAY },
    { value: "90 DAY", label: TC.TS_LAST_90_DAY },
    { value: "6 MONTH", label: TC.TS_LAST_6_MONTH },
    { value: "1 YEAR", label: TC.TS_LAST_1_YEAR },
    { value: "2 YEAR", label: TC.TS_LAST_2_YEAR },
    { value: "5 YEAR", label: TC.TS_LAST_5_YEAR },
];

const getDefaultDates = (dates: DateRange): T.MakeRequired<DateRange, "from" | "to"> => {
    let from = TB.getDate(dates?.from)?.toISOString?.();
    let to = TB.getDate(dates?.to)?.toISOString?.();
    return { from, to };
}
//#endregion

const TimeSelector: React.FC<TimeSelectorProps> = ({ stayOpenOnChange = false, from, to, interval, onChangeDatePicker, onChangeInterval, ...props }) => {
    const showMenu = H.useBoolean(false);
    const lg = H.useLanguage(TEXT_CODES);
    const container = React.useRef<HTMLDivElement>(null);
    const [errors, setErrors] = React.useState<Errors>({});
    const [dates, setDates] = React.useState<DateRange>(getDefaultDates({ from, to }));

    H.useOnClickOutside(container, showMenu.setFalse);

    React.useEffect(() => setDates(getDefaultDates({ from, to })), [from, to]);

    //#region Intervals
    const intervals = React.useMemo(() => {
        if (!props.min_relative_range) return INTERVALS;
        let keep_intervals = [] as typeof INTERVALS;
        for (let i = INTERVALS.length - 1; i >= 0; i--) {
            let interval = INTERVALS[i];

            keep_intervals.unshift(interval);
            if (interval.value === props.min_relative_range) i = -1;
        }
        return keep_intervals;
    }, [props.min_relative_range]);

    const onClickInterval = React.useCallback((interval: string) => {
        onChangeInterval?.(interval);
        if (!stayOpenOnChange) showMenu.setFalse();
    }, [showMenu, onChangeInterval, stayOpenOnChange]);

    const intervalList = React.useMemo(() => <BS.ListGroup variant="flush" className="overflow-auto" style={{ maxHeight: "300px" }}>
        {intervals.map(i => <BS.ListGroupItem key={i.value} action active={interval === i.value} onClick={() => onClickInterval(i.value)}>
            <span>{lg.getStaticElem(i.label)}</span>
        </BS.ListGroupItem>)}
    </BS.ListGroup>, [lg, onClickInterval, intervals, interval]);

    React.useEffect(() => lg.fetchStaticTranslations(intervals.map(i => i.label)), [lg, intervals]);
    //#endregion

    //#region Time Range
    React.useEffect(() => setErrors({}), [dates]);

    const onSetDate = React.useCallback((prop: "from" | "to", value?: string) => {
        if (TB.validString(value)) setDates(p => ({
            ...p,
            [prop]: props.show_datetime
                ? TB.getDate(value)?.toISOString?.() || ""
                : prop === "to" && !props.toExclusive
                    ? moment(value, "YYYY-MM-DD" + (props.show_datetime ? " HH:mm" : "")).add(1, "day").subtract(1, "second").toISOString()
                    : moment(value, "YYYY-MM-DD" + (props.show_datetime ? " HH:mm" : "")).toISOString()
        }));
        else setDates(p => ({ ...p, [prop]: undefined }));
    }, [props.toExclusive, props.show_datetime]);

    const onConfirmTimeRange = React.useCallback(() => {
        let errors: Errors = {};
        let [from, to] = [dates.from, dates.to].map(d => TB.getDate(d));

        if (from && to) {
            let [mFrom, mTo] = [from, to].map(d => moment(d));

            if (mFrom.isAfter(mTo)) errors.from = TC.ERR_DATE_FROM_HIGHER_DATE_TO;
            if (mTo.isBefore(mFrom)) errors.to = TC.ERR_DATE_TO_LOWER_DATE_FROM;

            if (Object.keys(errors).length > 0) setErrors(p => ({ ...p, ...errors }));
            else {
                onChangeDatePicker?.({ from: mFrom.toISOString(), to: mTo.toISOString() });
                if (!stayOpenOnChange) showMenu.setFalse();
            }
        }
        else {
            if (!from) errors.from = TC.GLOBAL_REQUIRED_FIELD;
            if (!to) errors.from = TC.GLOBAL_REQUIRED_FIELD;
            setErrors(p => ({ ...p, ...errors }));
        }
    }, [dates, onChangeDatePicker, showMenu, stayOpenOnChange]);

    const datesInputs = React.useMemo(() => props.only_years ? <>
        <Form.NumField
            label={TC.GLOBAL_FROM}
            error={{ code: errors.from }}
            value={new Date(dates.from).getFullYear()}
            onChange={year => onSetDate("from", year ? new Date("01/01/" + year + " 02:00").toISOString() : undefined)}
        />
        <Form.NumField
            label={TC.GLOBAL_TO}
            error={{ code: errors.to }}
            value={new Date(dates.to).getFullYear()}
            onChange={year => onSetDate("to", year ? new Date("12/31/" + year + " 23:59").toISOString() : undefined)}
        />
    </>
        : <>
            <Form.DateTime
                value={dates.from}
                label={TC.GLOBAL_FROM}
                error={{ code: errors.from }}
                enableTime={props.show_datetime}
                onChange={d => onSetDate("from", d)}
            />

            <Form.DateTime
                value={dates.to}
                label={TC.GLOBAL_TO}
                error={{ code: errors.to }}
                enableTime={props.show_datetime}
                onChange={d => onSetDate("to", d)}
            />
        </>, [dates, errors, onSetDate, props.only_years, props.show_datetime]);
    //#endregion

    //#region Main Button
    const buttonTitle = React.useMemo(() => {
        let intervalLabel = intervals.find(i => i.value === interval)?.label;
        if (intervalLabel) return lg.getStaticText(intervalLabel);
        if (from && to) {
            let [d_from, d_to] = [from, to].map(d => moment(d).format("DD/MM/YY"));
            return `${d_from} - ${d_to}`;
        }
        return lg.getStaticText(TC.TS_MENU_BUTTON);
    }, [intervals, lg, interval, from, to]);
    //#endregion

    return <div ref={container} className="position-relative ">

        <Button className={props.button_class} onClick={showMenu.toggle} icon="clock" children={buttonTitle} />

        {showMenu.value && <BS.Card className="position-absolute z-index-low" style={{ width: "500px" }}>
            <BS.Card.Body>
                <BS.Row>
                    <BS.Col>
                        <Falcon.Title titleTag={lg.getStaticElem(TC.TS_ABSOLUTE_TIME)} className="mb-3" />
                        {datesInputs}
                        <Button onClick={onConfirmTimeRange} text={TC.TS_CONFIRM_ABSOLUTE} />
                    </BS.Col>
                    <BS.Col>
                        <Falcon.Title titleTag={lg.getStaticElem(TC.TS_RELATIVE_TIME)} className="mb-3" />
                        {intervalList}
                    </BS.Col>
                </BS.Row>
            </BS.Card.Body>
        </BS.Card>}
    </div>
}

export default TimeSelector;