import React from "react";
import { TB, TC } from "../../../Constants";
import { renderBlankModal } from "../../Modal";
import FloorModal, { TypeValue as FloorTypeValue, RenderFloorModalFn } from "./FloorModal";

type ContentTypes = FloorTypeValue;
type renderParamsFn = RenderFloorModalFn;

const getModalContent = (type: ContentTypes) => {
    switch (type) {
        case "floor": return { Element: FloorModal, title: TC.MAGIC_CREATE_FLOOR };
        default: return { Element: null, title: null };
    }
}

export const renderMagicModal: renderParamsFn = params => new Promise(resolve => {
    if (!TB.validObject(params)) resolve(null);
    else {
        let { type, ...props } = params;
        let content = getModalContent(type);
        if (content.Element === null) resolve(null);
        else renderBlankModal({
            autofocus: false,
            size: "md",
            maxBodyHeight: "50vh",
            title: content.title,
            renderContent: resolve => <content.Element {...props} onResolve={resolve} />,
        })
            .then(r => resolve(r));
    }
});