import React from "react";
import useRoots from "./useRoots";
import { T, TB } from "../Constants";
import { useSelector } from "react-redux";
import { getUserAccess } from "../services";

const useRights = (context?: T.ContextParams) => {
    const [roots] = useRoots();
    const access_redux = useSelector((r: T.ReduxSelector) => r.rights);
    const [access_context, set_access_context] = React.useState<T.RightsContext>({ admin: false, access: [], loading: true, context_ids: [], search_context: null });

    const is_same_context = React.useMemo(() => {
        let is_same = true;
        if (context) {
            // Same portfolios
            if (context.portfolio && context.portfolio !== roots.portfolio) is_same = false;
            // Same roots
            else is_same = TB.arraySimilar(TB.arrayWrapper(context.roots), TB.arrayWrapper(roots.roots));
        }
        return is_same;
    }, [context, roots]);

    React.useEffect(() => {
        let isSubscribed = true;

        // Context is not the global one, load new access
        if (!is_same_context) getUserAccess(context)
            .then(({ data }) => isSubscribed && set_access_context({ ...data, loading: false }))
            .catch(e => set_access_context({ admin: false, access: [], loading: false, context_ids: [], search_context: context }));
        else set_access_context({ admin: false, access: [], loading: false, context_ids: [], search_context: context });

        return () => { isSubscribed = false };
    }, [is_same_context, context]);

    const access = React.useMemo(() => is_same_context ? access_redux : access_context, [access_context, access_redux, is_same_context]);

    /**
     * Check if this page is allowed to view
     * @param pageCode The code of the page
     * @param id If the page does not affect the whole context, provide a more precise id
     */
    const isPageAllowed = React.useCallback((pageCode: string, id?: string) => {
        if (access.admin) return true;
        let relevantAccess = access.access;
        if (TB.mongoIdValidator(id)) relevantAccess = access.access.filter(a => a.ids.includes(id));
        return relevantAccess.some(a => a.pages.includes(pageCode));
    }, [access.access, access.admin]);

    /**
     * Check if this panel is allowed to view
     * @param tabCode The code of the panel
     * @param id If the panel does not affect the whole context, provide a more precise id
     */
    const isPanelAllowed = React.useCallback((tabCode: string, id?: string) => {
        if (access.admin) return true;
        let relevantAccess = access.access;
        if (TB.mongoIdValidator(id)) relevantAccess = access.access.filter(a => a.ids.includes(id));
        return relevantAccess.some(a => a.panels.includes(tabCode));
    }, [access.access, access.admin]);

    /**
     * Check if this right is allowed to view
     * @param rightCode The code of the right
     * @param id If the right does not affect the whole context, provide a more precise id
     */
    const isRightAllowed = React.useCallback((rightCode: string, id?: string) => {
        if (access.admin) return true;
        let relevantAccess = access.access;
        if (TB.mongoIdValidator(id)) relevantAccess = access.access.filter(a => a.ids.includes(id));
        return relevantAccess.some(a => a.rights.includes(rightCode));
    }, [access.access, access.admin]);

    return React.useMemo(() => ({
        isPageAllowed,
        isPanelAllowed,
        isRightAllowed,
        loaded: !access.loading,
        context_ids: access.context_ids,
    }), [isPageAllowed, isPanelAllowed, isRightAllowed, access.loading, access.context_ids]);
}

export default useRights;