import { TB } from "../Constants";
import { ReduxWrapper } from "../Common";
import { renderInContainer } from "../Components/Modal";
import { SortMenuProps, SortMenu, SortItem } from "../Components/SortMenu";

type SortMenuParams<A> = Omit<SortMenuProps<A>, "onSave">;

const renderSortModal = <A extends {}>(params: SortMenuParams<A>, containerId?: string) => new Promise<null | SortItem[]>(resolve => {
    if (!TB.validObject(params)) resolve(null);
    else {
        let [render, dismount] = renderInContainer();
        if (render && dismount) {
            let onSave = (x: null | SortItem[]) => dismount(() => resolve(x));

            render(<ReduxWrapper>
                <SortMenu<A> {...params} onSave={onSave} />
            </ReduxWrapper>);
        }
        else resolve(null);
    }
});

export default renderSortModal;