import React from 'react';

interface UseBooleanOutput {
    value: boolean;
    toggle: () => void;
    setTrue: () => void;
    setFalse: () => void;
    ref: React.MutableRefObject<boolean>;
    setValue: React.Dispatch<React.SetStateAction<boolean>>;
}

function useBoolean(defaultValue?: boolean): UseBooleanOutput {
    const [value, setValue] = React.useState(!!defaultValue);
    const ref = React.useRef(value);

    React.useImperativeHandle(ref, () => value, [value]);
    const setTrue = React.useCallback(() => setValue(true), []);
    const setFalse = React.useCallback(() => setValue(false), []);
    const toggle = React.useCallback(() => setValue(x => !x), []);
    return React.useMemo(() => ({ value, setValue, setTrue, setFalse, toggle, ref }), [value, setTrue, setFalse, toggle]);
}

export default useBoolean