import React from "react";
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { T } from "../../Constants";

class ErrorBoundary extends React.Component<Record<"children", T.AllowArray<React.ReactElement>>, { has_error: boolean }> {
    constructor(props) {
        super(props);
        this.state = { has_error: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error(error, errorInfo);
    }

    render() {
        if (this.state.has_error) {
            // You can render any custom fallback UI
            return <Card className="text-center">
                <Card.Body className="p-5">
                    <div className="display-1 text-300 fs-error">ERROR</div>
                    <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
                        It seems an error has occured.
                    </p>
                    <hr />
                    <p>
                        Please reload the page or contact us at
                        <a href="mailto:info@automatronic.be" className="ms-1">info@automatronic.be</a>.
                    </p>
                    <Link className="btn btn-primary btn-sm mt-3" to="/">
                        <i className='fa fa-home me-2'></i>
                        Return to the main page
                    </Link>
                </Card.Body>
            </Card>
        }

        return this.props.children;
    }
}

export default ErrorBoundary;