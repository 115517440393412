import _ from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";
import { Portal } from "@material-ui/core";
import { FlatLabelInput } from "../BuildEditor";
import { ErrorBanner, Flex } from "../../Common";
import ActionPlanResults from "./ActionPlanResults";
import { TC, FP, TABS, TB, T } from "../../Constants";
import { ActionPlan } from "./ActionPlan/ActionPlanTypes";
import { Loader, renderAlert, renderLoader } from "../Modal";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { createActionPlan, getFullResources } from "../../services/user.service";
import { Col, Form, FormCheck, FormControl, Button, InputGroup, Row } from "react-bootstrap";
import { useAuth, useCrumbs, useFavorite, useLanguage, useNumber, usePortal } from "../../hooks";

//#region Types
interface BuildFullLoc extends T.FullOptionsLocations { submission: T.BuildingType };

type Resource = {
    buildings: BuildFullLoc[];
    actions: T.RenovationActionType[];
    status: "load" | "ready" | "error" | "noBuilds";
}

type FavoriteObj = {
    budget?: number;
    nbYears?: number;
    yearStart?: number;
    priorities: Priorities;
    selectedActions: string[];
    priceRefacturation?: number;
    priceRefacturationProd?: number;
    discriminations: Discrimination;
}

type Priorities = { [key in PrioritiesProps]?: number };
type Discrimination = { [key in DiscrimProp]?: number };
type PrioritiesProps = "roi" | "ratio" | "replacement" | "bailEnd";
type DiscrimProp = "typeA" | "typeB" | "typeC" | "typeD" | "typeE" | "isFood" | "isNonFood" | "isRetail" | "isNonRetail";
//#endregion

//#region Constants
const currentYear = moment().year();

const TEXT_CODES = [
    TC.APS_PERIMETER, TC.APS_BUDGET, TC.APS_EURO_PER_YEAR, TC.APS_YEAR_PLURAL, TC.GLOBAL_NO_BUILD_CONTEXT, TC.APS_CRUMB_TITLE, TC.APS_PRIORITIES, TC.APS_PCT_ATTRIBUTES,
    TC.APS_CRITERIAS, TC.APS_DISCRIMINATION, TC.APS_ROI, TC.APS_REPLACE_EMERGENCY, TC.APS_CO2_RATION, TC.APS_BAIL_END, TC.GLOBAL_CALCULATE, TC.APS_BUILD_LOC_POT,
    TC.APS_IS_FOOD, TC.APS_IS_NOT_FOOD, TC.APS_IS_RETAIL, TC.APS_IS_NOT_RETAIL, TC.APS_ERR_PCT_OVER, TC.GLOBAL_REQUIRED_FIELD, TC.APS_REFACTURATION, TC.APS_REFACTURATION_PROD,
    TC.GLOBAL_VAL_LOWER_THAN, TC.GLOBAL_LABEL_ARRAY_REQUIRED, TC.GLOBAL_ACTIONS
]

const DF_ROW_1 = {
    nbYears: 4,
    budget: 1000000,
    priceRefacturation: 10,
    yearStart: currentYear,
    priceRefacturationProd: 300,
}

const DF_DISCRIMINATIONS = {
    typeA: 100,
    typeB: 100,
    typeC: 100,
    typeD: 100,
    typeE: 100,
    isFood: 100,
    isNonFood: 100,
    isRetail: 100,
    isNonRetail: 100,
}

const DF_PRIORITIES = {
    roi: 25,
    ratio: 25,
    replacement: 25,
    bailEnd: 25,
}

const DF_INIT = {
    ...DF_ROW_1,
    selectedActions: [],
    priorities: DF_PRIORITIES,
    priceRefacturation: undefined,
    priceRefacturationProd: undefined,
    discriminations: DF_DISCRIMINATIONS,
}
//#endregion

const ActionPlanSimulator: FC<{}> = ({ ...props }) => {
    useAuth({ tabName: TABS.ACTION_SIMULATOR });
    const topNavPortal = usePortal("topNavBar");
    const { resetCrumbs } = useCrumbs(TC.APS_CRUMB_TITLE);
    const [errors, setErrors] = useState<T.StringObject>({});
    const [actionPlan, setActionPlan] = useState<ActionPlan>();
    const { getStaticText, getStaticElem } = useLanguage(TEXT_CODES);
    const [selectedActions, setSelectedActions] = useState<string[]>([]);
    const [priorities, setPriorities] = useState<Priorities>(DF_PRIORITIES);
    const [selectedBuildings, setSelectedBuildings] = useState<string[]>([]);
    const { dataContext } = useSelector((reducer: T.ReduxSelector) => reducer);
    const [discriminations, setDiscriminations] = useState<Discrimination>(DF_DISCRIMINATIONS);
    const [budget, setBudget] = useNumber({ default: DF_ROW_1.budget, min: 0, allowFloat: false });
    const [nbYears, setYears] = useNumber({ default: DF_ROW_1.nbYears, min: 1, allowFloat: false });
    const [priceRefacturation, setRefacturationPrice] = useNumber({ default: DF_ROW_1.priceRefacturation, min: 0 });
    const [{ status, buildings, actions }, setResources] = useState<Resource>({ status: "load", buildings: [], actions: [] });
    const [priceRefacturationProd, setRefacturationPriceProd] = useNumber({ default: DF_ROW_1.priceRefacturationProd, min: 0 });
    const [yearStart, setYearStart] = useNumber({ default: DF_ROW_1.yearStart, min: DF_ROW_1.yearStart, allowFloat: false, allowNaN: false });
    const [{ currentFilters, dropdown }, { setFilters, updateInitial }] = useFavorite({ origin: TABS.ACTION_SIMULATOR, initialState: DF_INIT });

    //#region Fav Filters
    const favFilters = useMemo<FavoriteObj>(() => ({
        budget, nbYears, priorities, priceRefacturation, priceRefacturationProd, discriminations, yearStart, selectedActions
    }), [budget, nbYears, priorities, priceRefacturation, discriminations, priceRefacturationProd, yearStart, selectedActions]);

    useEffect(() => {
        if (currentFilters !== null) {
            /* @ts-ignore */
            let filters: FavoriteObj = currentFilters;
            setBudget(filters.budget);
            setYears(filters.nbYears);
            setYearStart(filters.yearStart);
            setPriorities(filters.priorities);
            setSelectedActions(filters.selectedActions);
            setDiscriminations(filters.discriminations);
            setRefacturationPrice(filters.priceRefacturation);
            setRefacturationPriceProd(filters.priceRefacturationProd);
        }
    }, [currentFilters, setBudget, setYearStart, setRefacturationPrice, setRefacturationPriceProd, setYears]);

    /* @ts-ignore */
    useEffect(() => setFilters(favFilters), [setFilters, favFilters]);

    useEffect(() => {
        /* @ts-ignore */
        if (actions.length > 0) updateInitial({ selectedActions: actions.map(a => a._id) });
    }, [actions, updateInitial]);
    //#endregion

    //#region Load Buildings & actions
    useEffect(() => {
        let isSubscribed = true;
        let { selectedItems, selectedPortfolio } = dataContext;

        if (TB.mongoIdValidator(selectedPortfolio) || selectedItems.length > 0) getFullResources(FP.BUILDING_FORM, { actionPlan: true, roots: selectedItems, portfolio: selectedPortfolio })
            .then(({ data }) => {
                if (isSubscribed) {
                    if (!data?.hasFailed) {
                        if (!Array.isArray(data.buildings) || data.buildings.length === 0) setResources(p => ({ ...p, status: "noBuilds" }));
                        else {
                            setSelectedActions(p => p.length > 0 ? p : data.actions.map(a => a._id));
                            setSelectedBuildings(data.buildings.map(b => b.submission._id));
                            setResources(p => ({ ...p, ...data, status: "ready" }));
                        }
                    }
                    else setResources(p => ({ ...p, status: "error" }));
                }
            })
            .catch(() => {
                if (isSubscribed) setResources(p => ({ ...p, status: "error" }));
            });
        else setResources(p => ({ ...p, status: "noBuilds" }));

        return () => {
            isSubscribed = false;
            resetCrumbs();
        }
    }, [dataContext, resetCrumbs]);
    //#endregion

    //#region Styles & Props
    const subTitleRowsProp = useMemo(() => ({ className: "mb-2", gutter: 2 }), []);
    const titleRowsProp = useMemo(() => ({ className: "align-items-center mb-3", gutter: 2 }), []);
    //#endregion

    //#region Actions Select
    const switchAllActions = useCallback(() => setSelectedActions(p => p.length === actions.length ? [] : actions.map(a => a._id)), [actions]);
    const switchAction = useCallback((_id: string) => setSelectedActions(p => p.includes(_id) ? p.filter(id => id !== _id) : p.concat(_id)), []);

    const actionsRow = useMemo(() => <Row {...titleRowsProp}>
        <Col md={3}>
            <span className="h4">{getStaticElem(TC.GLOBAL_ACTIONS)}</span>
        </Col>
        <Col md={9}>
            <div className="bg-white border rounded p-3 overflow-auto position-relative" style={{ maxHeight: "200px" }}>
                {actions.map(a => <Flex key={a._id}>
                    <FormCheck type="switch" checked={selectedActions.includes(a._id)} onChange={e => switchAction(a._id)} />
                    <span className="mx-2">{a.data.name}</span>
                </Flex>)}
                <FormCheck className="position-absolute top-0 end-0 m-2" checked={selectedActions.length === actions.length} onChange={switchAllActions} />
            </div>
            {errors.selectedActions && <Form.Control.Feedback type="invalid" className="shown">{getStaticElem(errors.selectedActions)}</Form.Control.Feedback>}
        </Col>
    </Row>, [actions, errors.selectedActions, getStaticElem, selectedActions, switchAction, switchAllActions, titleRowsProp]);
    //#endregion

    //#region Budget
    const budgetProps = useMemo(() => ({
        budget: { step: 100000, value: budget ?? "", onChange: e => setBudget(e?.target?.value), isInvalid: Boolean(errors.budget) },
        nbYears: { value: nbYears ?? "", onChange: e => setYears(e?.target?.value), isInvalid: Boolean(errors.nbYears) },
        yearStart: { value: yearStart ?? "", onChange: e => setYearStart(e?.target?.value), isInvalid: Boolean(errors.yearStart) },
    }), [budget, nbYears, yearStart, errors, setBudget, setYearStart, setYears]);

    const budgetRow = useMemo(() => <Row {...titleRowsProp}>
        <Col md={3}>
            <span className="h4">{getStaticElem(TC.APS_BUDGET)}</span>
        </Col>
        <Col md={9}>
            <Flex alignItems="center" justifyContent="around" wrap="wrap">
                <Form.Control style={{ maxWidth: "150px" }} type="number" {...budgetProps.budget} />
                <span className="mx-2">{getStaticElem(TC.APS_EURO_PER_YEAR)}</span>
                <Form.Control style={{ maxWidth: "100px" }} type="number" {...budgetProps.nbYears} />
                <span className="mx-2">{getStaticElem(TC.APS_YEAR_PLURAL)}</span>
                <Form.Control style={{ maxWidth: "100px" }} type="number" {...budgetProps.yearStart} />
            </Flex>
        </Col>
        <Col md={3}></Col>
        <Col md={9}>
            {Object.keys(budgetProps).map(key => <React.Fragment key={key}>
                {errors[key] && <Form.Control.Feedback type="invalid" className="shown">{getStaticElem(errors[key])}</Form.Control.Feedback>}
            </React.Fragment>)}
        </Col>
    </Row>, [budgetProps, titleRowsProp, errors, getStaticElem]);
    //#endregion

    //#region Perimeter
    const switchBuilding = useCallback((_id: string) => setSelectedBuildings(p => p.includes(_id) ? p.filter(id => id !== _id) : p.concat(_id)), []);
    const switchAllBuilds = useCallback(() => setSelectedBuildings(p => p.length === buildings.length ? [] : buildings.map(b => b.submission._id)), [buildings]);

    const getSiteName = useCallback((location: T.LocationType) => {
        let site = location.site?.[0];
        if (TB.isSite(site)) return <span>({site.data.name})</span>;
        return null;
    }, []);

    const perimeterRow = useMemo(() => <Row {...titleRowsProp}>
        <Col md={3}>
            <span className="h4">{getStaticElem(TC.APS_PERIMETER)}</span>
        </Col>
        <Col md={9}>
            <div className="bg-white border rounded p-3 overflow-auto position-relative" style={{ maxHeight: "200px" }}>
                {buildings.map(({ submission, location }) => <Flex key={submission._id}>
                    <FormCheck type="switch" checked={selectedBuildings.includes(submission._id)} onChange={e => switchBuilding(submission._id)} />
                    <span className="mx-2">{submission.data.name}</span>
                    {getSiteName(location)}
                </Flex>)}
                <FormCheck className="position-absolute top-0 end-0 m-2" checked={selectedBuildings.length === buildings.length} onChange={switchAllBuilds} />
            </div>
            {errors.selectedBuildings && <Form.Control.Feedback type="invalid" className="shown">{getStaticElem(errors.selectedBuildings)}</Form.Control.Feedback>}
        </Col>
    </Row>, [titleRowsProp, errors, buildings, selectedBuildings, getStaticElem, getSiteName, switchBuilding, switchAllBuilds]);
    //#endregion

    //#region Refacturation Price
    const refacturationValues = useMemo(() => [
        { label: TC.APS_REFACTURATION, value: priceRefacturation, setter: setRefacturationPrice, prop: "priceRefacturation" },
        { label: TC.APS_REFACTURATION_PROD, value: priceRefacturationProd, setter: setRefacturationPriceProd, prop: "priceRefacturationProd" },
    ], [priceRefacturation, priceRefacturationProd, setRefacturationPrice, setRefacturationPriceProd]);

    const refacturationPricePanel = useMemo(() => refacturationValues.map(v => <>
        <Row key={v.prop} {...titleRowsProp}>
            <Col md={3}>
                <span className="h4">{getStaticElem(v.label)}</span>
            </Col>
            <Col md={9}>
                <InputGroup>
                    <FormControl
                        min={0}
                        type="number"
                        value={v.value ?? ""}
                        isInvalid={Boolean(errors[v.prop])}
                        onChange={e => v.setter(e.target.value)}
                    />
                    <InputGroup.Text>€/MWh</InputGroup.Text>
                </InputGroup>
                {errors[v.prop] && <Form.Control.Feedback type="invalid" className="shown">{getStaticElem(errors[v.prop])}</Form.Control.Feedback>}
            </Col>
        </Row>
    </>), [getStaticElem, refacturationValues, errors, titleRowsProp]);
    //#endregion

    //#region Priority & Discrimination
    const onPriorityBlur = useCallback((prop: PrioritiesProps) => setPriorities(p => typeof p[prop] !== "number" ? { ...p, [prop]: 0 } : p), []);
    const onDiscrimBlur = useCallback((prop: DiscrimProp) => setDiscriminations(p => typeof p[prop] !== "number" ? { ...p, [prop]: 0 } : p), []);

    const [prioritySum, priorityType] = useMemo(() => {
        let sum = _.sum(Object.values(priorities).filter(num => typeof num === "number"));
        let type: "danger" | "warning" | "success" = sum > 100 ? "danger" : (sum < 100 ? "warning" : "success");
        return [sum, type];
    }, [priorities]);

    const listCriteria = useMemo<{ label: string, prop: PrioritiesProps }[]>(() => [
        { label: TC.APS_ROI, prop: "roi" },
        { label: TC.APS_CO2_RATION, prop: "ratio" },
        { label: TC.APS_REPLACE_EMERGENCY, prop: "replacement" },
        { label: TC.APS_BAIL_END, prop: "bailEnd" }
    ], []);

    const listDiscriminations = useMemo<{ label: string, template?: string, prop: DiscrimProp }[]>(() => [
        { label: TC.APS_BUILD_LOC_POT, template: "A", prop: "typeA" },
        { label: TC.APS_BUILD_LOC_POT, template: "B", prop: "typeB" },
        { label: TC.APS_BUILD_LOC_POT, template: "C", prop: "typeC" },
        { label: TC.APS_BUILD_LOC_POT, template: "D", prop: "typeD" },
        { label: TC.APS_BUILD_LOC_POT, template: "E", prop: "typeE" },
        { label: TC.APS_IS_FOOD, prop: "isFood" },
        { label: TC.APS_IS_NOT_FOOD, prop: "isNonFood" },
        { label: TC.APS_IS_RETAIL, prop: "isRetail" },
        { label: TC.APS_IS_NOT_RETAIL, prop: "isNonRetail" },
    ], []);

    const onChangePriority = useCallback((prop: PrioritiesProps, value: string) => {
        let pct = TB.getPct(value);
        if (!isNaN(pct)) setPriorities(p => ({ ...p, [prop]: pct }));
        else setPriorities(p => ({ ...p, [prop]: undefined }));
    }, []);

    const onChangeDiscrim = useCallback((prop: DiscrimProp, value: string) => {
        let pct = TB.getPct(value);
        if (!isNaN(pct)) setDiscriminations(p => ({ ...p, [prop]: pct }));
        else setDiscriminations(p => ({ ...p, [prop]: undefined }));
    }, []);

    const priorityPanel = useMemo(() => <>
        <Row {...titleRowsProp}>
            <Col><span className="h4">{getStaticElem(TC.APS_PRIORITIES)}</span></Col>
            <Col>
                <div className={`text-end fs-1 fw-bold  text-${priorityType}`}>
                    {getStaticElem(TC.APS_PCT_ATTRIBUTES, prioritySum.toString())}
                </div>
            </Col>
        </Row>
        <Row {...subTitleRowsProp}>
            <Col md={12}><span className="h5">{getStaticElem(TC.APS_CRITERIAS)}</span></Col>
            {listCriteria.map(({ label, prop }) => <Col md={6} key={prop}>
                <FlatLabelInput className="text-center" label={getStaticText(label)} labelWidth={4}>
                    <InputGroup>
                        <FormControl
                            min={0}
                            step={5}
                            max={100}
                            type="number"
                            value={priorities[prop] ?? ""}
                            isInvalid={Boolean(errors[prop])}
                            onBlur={() => onPriorityBlur(prop)}
                            onChange={e => onChangePriority(prop, e.target.value)}
                        />
                        <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup>
                </FlatLabelInput>
                {errors[prop] && <Form.Control.Feedback type="invalid" className="shown">{getStaticText(errors[prop])}</Form.Control.Feedback>}
            </Col>)}
        </Row>
        <Row {...subTitleRowsProp}>
            <Col md={12}><span className="h5">{getStaticElem(TC.APS_DISCRIMINATION)}</span></Col>
            {listDiscriminations.map(({ label, prop, template }) => <Col md={6} key={prop}>
                <FlatLabelInput className="text-end" label={getStaticText(label, template)} labelWidth={6}>
                    <InputGroup>
                        <FormControl
                            min={0}
                            step={5}
                            max={100}
                            type="number"
                            value={discriminations[prop] ?? ""}
                            onBlur={() => onDiscrimBlur(prop)}
                            onChange={e => onChangeDiscrim(prop, e.target.value)}
                        />
                        <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup>
                </FlatLabelInput>
            </Col>)}
        </Row>
    </>, [listCriteria, subTitleRowsProp, titleRowsProp, errors, discriminations, priorityType, priorities, prioritySum, listDiscriminations, getStaticText, onPriorityBlur, onDiscrimBlur, onChangePriority, onChangeDiscrim, getStaticElem]);
    //#endregion

    //#region Errors
    const errorBanner = useMemo(() => {
        if (status === "load") return <Loader />;
        if (status === "error") return <ErrorBanner type="danger" textCode={TC.GLOBAL_FAILED_LOAD} />;
        if (status === "noBuilds") return <ErrorBanner type="warning" textCode={TC.GLOBAL_NO_BUILD_CONTEXT} />;
        return null;
    }, [status]);
    //#endregion

    //#region Simulation && value errors
    useEffect(() => {
        let criteriaProps = listCriteria.map(({ prop }) => prop), errorProps = Object.keys(errors);
        if (prioritySum <= 100 && criteriaProps.some(prop => errorProps.includes(prop))) setErrors(p => _.omit(p, criteriaProps));
    }, [errors, listCriteria, prioritySum]);

    useEffect(() => {
        if (typeof priceRefacturation === "number" && errors.priceRefacturation) setErrors(p => _.omit(p, "priceRefacturation"));
    }, [priceRefacturation, errors]);

    useEffect(() => {
        if (typeof priceRefacturationProd === "number" && errors.priceRefacturationProd) setErrors(p => _.omit(p, "priceRefacturationProd"));
    }, [priceRefacturationProd, errors]);

    useEffect(() => {
        if (typeof budget === "number" && budget >= 0 && errors.budget) setErrors(p => _.omit(p, "budget"));
    }, [budget, errors]);

    useEffect(() => {
        if (typeof nbYears === "number" && nbYears >= 1 && errors.nbYears) setErrors(p => _.omit(p, "nbYears"));
    }, [nbYears, errors]);

    useEffect(() => {
        if (typeof yearStart === "number" && yearStart >= currentYear && errors.yearStart) setErrors(p => _.omit(p, "yearStart"));
    }, [yearStart, errors]);

    const yearLaps = useMemo(() => TB.getNumber(yearStart, currentYear) - moment().year(), [yearStart]);

    const validateApiParams = useMemo(() => ({
        nbYears, budget, discriminations, priceRefacturation, priceRefacturationProd, roots: selectedBuildings, keepActions: selectedActions, priorities, yearLaps
    }), [nbYears, budget, discriminations, priceRefacturation, selectedBuildings, priceRefacturationProd, priorities, yearLaps, selectedActions]);

    const simulate = useCallback(() => {
        let errors: T.StringObject = {};
        if (prioritySum > 100) listCriteria.forEach(({ prop }) => errors[prop] = TC.APS_ERR_PCT_OVER);
        if (typeof priceRefacturation !== "number") errors.priceRefacturation = TC.GLOBAL_REQUIRED_FIELD;
        if (typeof priceRefacturationProd !== "number") errors.priceRefacturationProd = TC.GLOBAL_REQUIRED_FIELD;
        if (selectedBuildings.length === 0) errors.selectedBuildings = TC.GLOBAL_LABEL_ARRAY_REQUIRED;
        if (selectedActions.length === 0) errors.selectedActions = TC.GLOBAL_LABEL_ARRAY_REQUIRED;

        if (typeof yearStart !== "number") errors.yearStart = TC.GLOBAL_REQUIRED_FIELD;
        else if (yearStart < currentYear) errors.yearStart = getStaticText(TC.GLOBAL_VAL_LOWER_THAN, currentYear.toString());

        if (typeof budget !== "number") errors.budget = TC.GLOBAL_REQUIRED_FIELD;
        else if (budget < 0) errors.budget = getStaticText(TC.GLOBAL_VAL_LOWER_THAN, "0€");

        if (typeof nbYears !== "number") errors.nbYears = TC.GLOBAL_REQUIRED_FIELD;
        else if (nbYears < 1) errors.nbYears = getStaticText(TC.GLOBAL_VAL_LOWER_THAN, "1");

        if (Object.keys(errors).length > 0) setErrors(errors);
        else {
            let unmount = renderLoader();
            let onError = () => renderAlert({ type: "error", message: TC.GLOBAL_ERROR_OCCURRED });

            createActionPlan(validateApiParams)
                .then(({ data }) => {
                    if (data?.hasFailed) onError();
                    else setActionPlan(data);
                })
                .catch(onError)
                .finally(unmount);
        }
    }, [getStaticText, prioritySum, priceRefacturation, priceRefacturationProd, selectedActions.length, selectedBuildings.length, listCriteria, validateApiParams, budget, nbYears, yearStart]);

    const simulationButton = useMemo(() => <div className="my-3 text-center">
        <Button onClick={simulate} className="px-5">{getStaticElem(TC.GLOBAL_CALCULATE)}</Button>
    </div>, [getStaticElem, simulate]);
    //#endregion

    //#region
    const updaters = useMemo(() => ({
        updateLocs: (loc: T.LocationType) => setActionPlan(p => p ? ({ ...p, locations: p.locations.map(l => l.id === loc.id ? loc : l) }) : undefined),
        updateEquips: (equip: T.EquipmentType) => setActionPlan(p => p ? ({ ...p, equipments: p.equipments.map(e => e._id === equip._id ? equip : e) }) : undefined),
        updateActions: (action: T.RenovationActionType) => setActionPlan(p => p ? ({ ...p, actions: p.actions.map(a => a._id === action._id ? action : a) }) : undefined),
    }), []);
    //#endregion

    return <div className="w-100 flex-grow-1">
        {errorBanner}
        {status === "ready" && <div className="mt-2">
            {budgetRow}
            {perimeterRow}
            {actionsRow}
            {refacturationPricePanel}
            {priorityPanel}
            {simulationButton}
            {actionPlan && <div className="mt-3">
                <ActionPlanResults plan={actionPlan} updaters={updaters} yearLaps={yearLaps} />
            </div>}
        </div>}

        <Portal container={topNavPortal.current}>{dropdown}</Portal>
    </div>
}

export default ActionPlanSimulator;