import _ from "lodash";
import React from "react";
import * as M from "../../Modal";
import { Form } from "../../Form";
import SitePanel from "./SitePanel";
import CellPanel from "./CellPanel";
import * as H from "../../../hooks";
import EntityPanel from "./EntityPanel";
import { Popper } from "@material-ui/core";
import BuildingPanel from "./BuildingPanel";
import socketIoClient from 'socket.io-client';
import { ErrorBanner } from "../../../Common";
import { useNavigate } from "react-router-dom";
import * as US from "../../../services/user.service";
import SearchModule from "../../SearchModule/SearchModule";
import { SOCKET_PATH } from "../../../Constants/Constants";
import { TC, LT, FP, T, GAMMES, EC, TB, TABS } from "../../../Constants";
import AttachMultipleObjParents from "../../../helpers/AttachMultipleObjParents";

//#region Types
type Resource = {
    links: T.Link[];
    forms: T.FormType[];
    bails: T.Submission[]; //TODO
    linkTypes: T.LinkType[];
    descendance: T.Submission[];
    defaultGammes: T.EquipGammeType[];
    status: "load" | "ready" | "error" | "noContext";
}

type SetBailAlias = (fn: (params: T.Submission[]) => T.Submission[]) => void;
type SetDescAlias = (fn: (params: { descendance: T.Submission[], links: T.Link[] }) => { descendance: T.Submission[], links: T.Link[] }) => void;
//#endregion

//#region Constants
const DF_RESOURCE: Resource = { forms: [], bails: [], descendance: [], links: [], linkTypes: [], defaultGammes: [], status: "load" };
const TEXT_CODES = [TC.GLOBAL_LABEL_ENTITY, TC.GLOBAL_LABEL_SITE, TC.GLOBAL_LABEL_BUILD, TC.GLOBAL_LABEL_CELLS, TC.GLOBAL_LABEL_RENTER, TC.GLOBAL_LABEL_ENTITY];
//#endregion

const SiteForm = ({ ...props }) => {
    //#region State
    const [roots] = H.useRoots();
    H.useCrumbs(TC.TAB_SITE_FORM);
    const navigate = useNavigate();
    const [{ user }] = H.useAuth({ tabName: TABS.SITE_WIZARD });
    const { getStaticText, language } = H.useLanguage(TEXT_CODES);
    // const { dataContext } = useSelector((redux: T.ReduxSelector) => redux);

    // Local State
    const [activePanel, setActivePanel] = React.useState(0);
    const [openPopOver, setOpenPopOver] = React.useState(false);
    const [activeTabSite, setActiveTabSite] = React.useState<string>();
    const [selectedClient, setSelectedClient] = React.useState<string>();
    const [groupingSelection, setGroupingSelection] = React.useState<string[]>([]);
    const [{ grouping, filtered }, setSearch] = React.useState<{ grouping: string[], filtered: string[] }>({ grouping: [], filtered: [] });

    // Db Data
    const [socket, setSocket] = React.useState<T.Socket | null>(null);
    const [{ forms, linkTypes, defaultGammes, descendance, links, bails, status }, setResource] = React.useState(DF_RESOURCE);

    // Refs
    const searchBarRef = React.useRef();
    const popRef = React.useRef<HTMLLIElement | null>(null);
    //#endregion

    //#region Fetch Data
    React.useEffect(() => {
        if (TB.validObject(roots) && !TB.mongoIdValidator(selectedClient) && socket !== null) {
            US.clientsContextWithSites(roots?.roots, roots?.portfolio).then(({ data }) => {
                let reqFailed = data.hasFailed || !Array.isArray(data);
                let noContext = Array.isArray(data) && data.length === 0;
                if (reqFailed) setResource({ ...DF_RESOURCE, status: "error" });
                else if (noContext) setResource({ ...DF_RESOURCE, status: "noContext" });
                else if (data.length === 1 && TB.mongoIdValidator(data[0]?._id)) setSelectedClient(data[0]?._id);
                else {
                    let options = data.sort(TB.sortByNameSubmission).map(({ _id, data }) => ({ label: data?.name, value: _id }));
                    M.askSelect({ isRequired: true, title: TC.GLOBAL_LABEL_ENTERPRISE, label: TC.GLOBAL_LABEL_ENTERPRISE, options }).then(id => {
                        if (TB.mongoIdValidator(id)) setSelectedClient(id);
                        else navigate("/");
                    });
                }
            });
        }
    }, [roots, language, selectedClient, socket, navigate]);

    React.useEffect(() => {
        if (TB.mongoIdValidator(selectedClient)) US.getFullResources(FP.SITE_FORM, { roots: selectedClient })
            .then(({ data }) => {
                if (data?.hasFailed) setResource({ ...DF_RESOURCE, status: "error" });
                else setResource({ ...data, status: "ready" });
            })
            .catch(() => setResource({ ...DF_RESOURCE, status: "error" }));
    }, [selectedClient]);
    //#endregion

    //#region Forms & Link Types & Default Gammes
    const bailFormId = React.useMemo(() => _.find(forms, f => f.path === FP.BAIL_FORM)?._id, [forms]);
    const siteFormId = React.useMemo(() => _.find(forms, f => f.path === FP.SITE_FORM)?._id, [forms]);
    const entityFormId = React.useMemo(() => _.find(forms, f => f.path === FP.ENTITY_FORM)?._id, [forms]);
    const parcelFormId = React.useMemo(() => _.find(forms, f => f.path === FP.PARCEL_FORM)?._id, [forms]);
    const clientFormId = React.useMemo(() => _.find(forms, f => f.path === FP.CLIENT_FORM)?._id, [forms]);
    const buildFormId = React.useMemo(() => _.find(forms, f => f.path === FP.BUILDING_FORM)?._id, [forms]);
    const equipFormId = React.useMemo(() => _.find(forms, f => f.path === FP.EQUIPEMENT_FORM)?._id, [forms]);
    const enseigneFormId = React.useMemo(() => _.find(forms, f => f.path === FP.ENSEIGNE_FORM)?._id, [forms]);
    const emplacementFormId = React.useMemo(() => _.find(forms, f => f.path === FP.EMPLACEMENT_FORM)?._id, [forms]);

    const ownLinkType = React.useMemo(() => _.find(linkTypes, lt => lt.data.type === LT.LINK_TYPE_OWN)?._id, [linkTypes]);
    const coOwnLinkType = React.useMemo(() => _.find(linkTypes, lt => lt.data.type === LT.LINK_TYPE_CO_OWN)?._id, [linkTypes]);
    const rentalLinkType = React.useMemo(() => _.find(linkTypes, lt => lt.data.type === LT.LINK_TYPE_RENTAL)?._id, [linkTypes]);
    const gestionLinkType = React.useMemo(() => _.find(linkTypes, lt => lt.data.type === LT.LINK_TYPE_GESTION)?._id, [linkTypes]);

    const roofId = React.useMemo(() => _.find(defaultGammes, dg => dg.data.omniclass === GAMMES.ROOF)?._id, [defaultGammes]);
    const wallId = React.useMemo(() => _.find(defaultGammes, dg => dg.data.omniclass === GAMMES.WALLS)?._id, [defaultGammes]);
    const windowId = React.useMemo(() => _.find(defaultGammes, dg => dg.data.omniclass === GAMMES.WINDOWS)?._id, [defaultGammes]);
    //#endregion

    //#region Socket
    const disconnectSocket = React.useCallback(() => {
        if (TB.mongoIdValidator(selectedClient) && socket !== null) socket.emit('unlockClient', { client: selectedClient });
    }, [selectedClient, socket]);

    React.useEffect(() => disconnectSocket, [disconnectSocket]);

    React.useEffect(() => {
        /* @ts-ignore */
        if (socket === null) setSocket(socketIoClient(window.location.origin, { path: SOCKET_PATH }));
        return () => { if (socket !== null) socket?.disconnect?.() };
    }, [socket]);
    //#endregion

    //#region Setter Alias
    const setDescendance = React.useCallback<SetDescAlias>(fn => {
        if (typeof fn === "function") setResource(p => {
            let newVal = fn({ descendance: p.descendance, links: p.links });
            return { ...p, ...newVal };
        });
    }, []);

    const setBails = React.useCallback<SetBailAlias>(fn => {
        if (typeof fn === "function") setResource(p => {
            let newVal = fn(p.bails);
            return { ...p, bails: newVal };
        });
    }, []);
    //#endregion

    //#region Sorted / Grouped / Categorized Data
    const sites = React.useMemo<T.SiteType[]>(() => descendance.filter(s => s.form === siteFormId).filter(TB.isSite), [descendance, siteFormId]);

    const allSyndic = React.useMemo(() => {
        if (![descendance, links].every(Array.isArray)) return [];

        // Separate the enterprises to keep only those that are syndic as well
        let syndicLinksInputs = links.filter(({ type }) => type === gestionLinkType).map(({ input }) => input);

        return descendance
            .filter(({ _id, form }) => syndicLinksInputs.includes(_id) && form === clientFormId)
            .map(({ _id, ...r }) => {
                // Return the items that are managed by the syndic-enterprise
                let outputs = links.filter(({ input, type }) => input === _id && type === gestionLinkType).map(({ output }) => output);
                return { _id, ...r, managed: descendance.filter(({ _id }) => outputs.includes(_id)) };
            });
    }, [descendance, links, gestionLinkType, clientFormId]);

    const allCopro = React.useMemo(() => {
        if (![descendance, links].every(Array.isArray)) return [];

        // Separate the enterprises that are co-owner
        let coproLinksInputs = (links?.filter?.(({ type }) => type === coOwnLinkType) ?? []).map(({ input }) => input);

        return descendance
            .filter(({ _id, form }) => coproLinksInputs.includes(_id) && form === clientFormId)
            .map(({ _id, ...r }) => {
                // Return the items that are co-owned by the enterprise
                let outputs = links.filter(({ input, type }) => input === _id && type === coOwnLinkType).map(({ output }) => output);
                return { _id, ...r, coOwned: descendance.filter(({ _id }) => outputs.includes(_id)) }
            });
    }, [descendance, links, coOwnLinkType, clientFormId]);
    //#endregion

    //#region Data Reload
    const bailsIdsStr = React.useMemo(() => bails.map(b => b._id).join(), [bails]);

    React.useEffect(() => {
        let isSubscribed = true;

        const getBails = async () => {
            let filter = { "data.link": links.map(l => l._id), _id: { $nin: bailsIdsStr.split(',').filter(TB.mongoIdValidator) }, form: bailFormId };
            let reply = await US.getManySubmissionsFromFilter(filter);
            return Array.isArray(reply.data) ? reply.data : null;
        }

        if (links.every(l => TB.mongoIdValidator(l._id))) getBails().then(bails => {
            if (isSubscribed) {
                if (Array.isArray(bails) && bails.length > 0) setResource(p => ({ ...p, bails: p.bails.concat(bails) }));
                else if (!Array.isArray(bails)) M.renderAlert({ type: "warning", }); //TODO
            }
        });
        return () => { isSubscribed = false };
    }, [links, bailFormId, bailsIdsStr]);

    React.useEffect(() => {
        let isSubscribed = true;
        let noIdLinks = links.filter(l => !TB.mongoIdValidator(l._id));

        const getMissingLinks = async () => {
            let reply = await US.getLinksFromFilter({ $or: noIdLinks });
            return Array.isArray(reply?.data) ? reply.data : null;
        }

        if (noIdLinks.length > 0) getMissingLinks().then(newLinks => {
            if (isSubscribed) {
                if (Array.isArray(newLinks) && newLinks.length > 0) setDescendance(p => ({ ...p, links: links.filter(l => TB.mongoIdValidator(l._id)).concat(newLinks) }));
                else if (!Array.isArray(newLinks)) M.renderAlert({ type: "error" }); //TODO
            }
        })

        return () => { isSubscribed = false };
    }, [links, setDescendance]);
    //#endregion

    //#region Notes
    const addNote = React.useCallback((origin: string, titleOptions?: string[]) => {
        if (!Array.isArray(titleOptions) || titleOptions.length === 0) M.renderNoteModal({ origin });
        else M.askSelect({ /* TODO */ noSelectionText: "Afficher les notes existantes todo", title: "Suggestions de titres", options: titleOptions.map(str => ({ value: str, label: str })) }).then(title => {
            if (TB.validString(title)) M.renderNoteModal({ origin, defaultSubject: title });
            else if (title === undefined) M.renderNoteModal({ origin });
        });
    }, []);
    //#endregion

    //#region Callbacks
    const onChangeParcelLink = React.useCallback((parcelInput, newOutput, oldOutput, siteId) => {
        // TODO still some details to do about the link between build and site
        let newBuildParentsIds = links.filter(({ output, type }) => output === newOutput && type === ownLinkType).map(({ input }) => input).filter(TB.mongoIdValidator);
        let oldBuildParentsIds = links.filter(({ output, type }) => output === oldOutput && type === ownLinkType).map(({ input }) => input).filter(TB.mongoIdValidator);

        let dbActionPromise = new Promise(async (resolve, reject) => {
            // Must create / update a link
            if (TB.mongoIdValidator(newOutput)) {
                // Must update a link
                if (TB.mongoIdValidator(oldOutput)) {
                    let bulk: T.BulkMongoose[] = [{
                        updateOne: {
                            filter: { input: parcelInput, output: oldOutput, type: ownLinkType },
                            update: { output: newOutput },
                            upsert: true,
                        }
                    }];

                    let siteBuildLink = { input: siteId, output: oldOutput, type: ownLinkType };
                    let newOutputSiteParent = descendance.filter(({ _id, form }) => newBuildParentsIds.includes(_id) && form === siteFormId).map(({ _id }) => _id).filter(TB.mongoIdValidator);
                    let oldOutputHasParent = descendance.filter(({ _id, form }) => oldBuildParentsIds.includes(_id) && [siteFormId, parcelFormId].includes(form) && _id !== parcelInput).length > 0;

                    // If building has no other parent than the one about to leave, link it to the site
                    if (!oldOutputHasParent) bulk.push({ insertOne: { document: siteBuildLink } });
                    if (newOutputSiteParent.length > 0) bulk.push({ deleteMany: { filter: { input: newOutputSiteParent, output: newOutput, type: ownLinkType } } });

                    let reply = await US.bulkLinkAction(bulk);

                    if (reply?.data?.ok === 1) resolve(() => setDescendance(({ descendance, links }) => ({
                        descendance,
                        links: links
                            .filter(link => {
                                if (newOutputSiteParent.length === 0) return true;
                                return !newOutputSiteParent.some(id => link.input === id && link.output === newOutput && link.type === ownLinkType);
                            })
                            .map(link => {
                                if (link.input === parcelInput && link.output === oldOutput && link.type === ownLinkType) return { ...link, output: newOutput };
                                return link;
                                /* @ts-ignore */
                            }).concat(oldOutputHasParent ? [] : siteBuildLink)
                    })));
                    else reject();
                }
                // Must create a link
                else {
                    let siteParents = descendance.filter(({ _id, form }) => newBuildParentsIds.includes(_id) && form === siteFormId).map(({ _id }) => _id).filter(TB.mongoIdValidator);

                    let newLink = { input: parcelInput, output: newOutput, type: ownLinkType };
                    let reply = await US.createLink(newLink);

                    if (TB.mongoIdValidator(reply.data?._id)) {
                        if (siteParents.length > 0) await US.deleteManyLinksFromFilter({ type: ownLinkType, output: newOutput, input: siteParents });
                        resolve(() => setDescendance(({ descendance, links }) => ({
                            descendance,
                            links: links.filter(link => {
                                if (siteParents.length === 0) return true;
                                return !siteParents.some(id => link.input === id && link.output === newOutput && link.type === ownLinkType);
                                /* @ts-ignore */
                            }).concat(newLink),
                        })));
                    }
                    else reject();
                }
            }
            // Must delete a link
            else {
                let isBuildLinked = descendance.filter(({ _id }) => oldBuildParentsIds.includes(_id))
                    .filter(({ _id, form }) => [siteFormId, parcelFormId].includes(form) && _id !== parcelInput).length > 0;

                let recoveryLink = { input: siteId, output: oldOutput, type: ownLinkType };
                let bulk: T.BulkMongoose[] = [{ deleteMany: { filter: { input: parcelInput, output: oldOutput, type: ownLinkType } } }];
                if (!isBuildLinked) bulk.push({ insertOne: { document: recoveryLink } });

                let reply = await US.bulkLinkAction(bulk);

                if (reply.data.ok === 1) resolve(() => setDescendance(({ descendance, links }) => ({
                    descendance,
                    links: links.filter(({ input, output, type }) => !(input === parcelInput && output === oldOutput && type === ownLinkType))
                        /* @ts-ignore */
                        .concat(isBuildLinked ? [] : recoveryLink)
                })));
                else reject();
            }
        });

        // All ok, update local state
        dbActionPromise.then(localSetter => typeof localSetter === "function" ? localSetter() : undefined)
            // An error occurred
            .catch(() => M.renderErrorModal({ errorCode: EC.CODE_DB_UPDATE_FAIL }));
    }, [setDescendance, ownLinkType, links, descendance, parcelFormId, siteFormId]);

    const onUpdateBails = React.useCallback(func => typeof func === "function" ? setBails(prev => func(prev)) : undefined, [setBails]);
    const onUpdateTree = React.useCallback(func => typeof func === "function" ? setDescendance(prev => func(prev)) : undefined, [setDescendance]);
    const onUpdateLinks = React.useCallback(func => typeof func === "function" ? setDescendance(({ descendance, links }) => ({ links: func(links), descendance })) : undefined, [setDescendance]);
    const onUpdateDescendants = React.useCallback(func => typeof func === "function" ? setDescendance(({ descendance, links }) => ({ links, descendance: func(descendance) })) : undefined, [setDescendance]);
    //#endregion

    //#region Utilities
    const getDescendance = React.useCallback((list, noClientLinks = false) => {
        if (![descendance, links].every(Array.isArray)) return [];
        let checked: string[] = [];

        const getChildren = (ids: string | string[]) => {
            if (!Array.isArray(ids)) ids = [ids];
            checked = checked.concat(ids);

            let children = links
                .filter(({ input, output, type }) => ids.includes(input) && !checked.includes(output) && (!noClientLinks || ![rentalLinkType].includes(type)))
                .map(({ output }) => output);
            if (children.length === 0) return [];
            return _.uniqBy(descendance.filter(({ _id }) => children.includes(_id)).concat(getChildren(children)), "_id");
        }

        return list.sort(TB.sortByNameSubmission).map(({ _id, ...r }) => {
            checked = [];
            return { ...r, _id, children: getChildren(_id) }
        });
    }, [descendance, links, rentalLinkType]);

    const getAscendance = React.useCallback(list => {
        if (![descendance, links].every(Array.isArray)) return [];
        let checked: string[] = [];

        const getParents = ids => {
            if (!Array.isArray(ids)) ids = [ids];
            checked = checked.concat(ids);

            let parents = links.filter(({ output, input }) => ids.includes(output) && !checked.includes(input)).map(({ input }) => input);

            if (parents.length === 0) return [];
            return _.uniqBy(descendance.filter(({ _id }) => parents.includes(_id)).concat(getParents(parents)), "_id");
        }

        return list.sort(TB.sortByNameSubmission).map(({ _id, ...r }) => {
            checked = [];
            return { ...r, _id, parents: getParents(_id) };
        });
    }, [descendance, links]);
    //#endregion

    //#region SearchModule
    const searchList = React.useMemo(() => [
        { label: "Pays", property: "countries" },
        { label: "Succursales", property: "enterprise" },
        { label: "Nom", property: "name", noGroup: true },
        { label: "N° de site", property: "numSite", noGroup: true }
    ], []);

    const groupingTranslator = React.useMemo(() => grouping.map(prop => {
        let { property } = _.find(searchList, s => s.property === prop) || {};
        return TB.validString(property) ? property : null;
    }).filter(TB.validString), [grouping, searchList]);

    const searchModuleProps = React.useMemo(() => ({
        searchList,
        testArray: true,
        allowGrouping: true,
        refObj: searchBarRef,
        onSelect: filtered => setSearch(prev => ({ ...prev, filtered })),
        onGrouping: grouping => setSearch(prev => ({ ...prev, grouping })),
    }), [searchList]);

    React.useEffect(() => setGroupingSelection([]), [grouping]);
    //#endregion

    //#region SiteList
    const noteBuilding = React.useCallback(({ data, children, ...r }) => {
        let equipChildren = children.filter(({ form }) => form === equipFormId);
        let roofData = equipChildren.filter(({ data }) => data.category === roofId)?.[0]?.data || {};
        let wallData = equipChildren.filter(({ data }) => data.category === wallId)?.[0]?.data || {};
        let windowData = equipChildren.filter(({ data }) => data.category === windowId)?.[0]?.data || {};

        let wallScore = ["materials", "surface"].map(str => _.get(wallData, str));
        let windowScore = ["materials", "surface", "uValue"].map(str => _.get(windowData, str));
        let roofScore = ["materials", "surface", "uValue", "incline"].map(str => _.get(roofData, str));
        let buildScore = ["number", "street", "zipcode", "town", "ENT_TECH_BUILD_YEAR", "ENT_REF_CONSUMPTION_ACTUAL"].map(str => _.get(data, str));

        let allScoreVal = _.flatten([wallScore, windowScore, roofScore, buildScore]);
        let nbValidVal = allScoreVal.filter(x => ![null, undefined, "", NaN].includes(x)).length;

        let note = Math.round((nbValidVal / allScoreVal.length) * 100) ?? 0;
        return { data, ...r, note };
    }, [equipFormId, roofId, wallId, windowId]);

    const noteCell = React.useCallback(({ _id, data, children, parents, ...r }) => {
        let renters = children.filter(({ form }) => form === clientFormId);
        let enseigne = parents.filter(({ form }) => form === enseigneFormId)?.[0]?.data;

        let rentersIds = renters.map(({ _id }) => _id)
        let rentersLinks = links.filter(({ input, output }) => input === _id && rentersIds.includes(output)).map(({ _id }) => _id);
        let bailsObj = bails.filter(({ data }) => rentersLinks.includes(data.link))?.[0]?.data ?? {};
        let renterObj = renters?.[0]?.data ?? {};

        let cellData = ["name"].map(str => _.get(data, str));
        let enseigneData = ["name"].map(str => _.get(enseigne, str));
        let renterData = ["name", "numSiren"].map(str => _.get(renterObj, str));
        let bailData = ["start", "end", "break", "affectation", "loadDistribution"].map(str => _.get(bailsObj, str));

        let note = _.flatten([cellData, enseigneData, renterData, bailData]).every(x => ![null, undefined, ""].includes(x)) ? 100 : 0;

        return { _id, note, data, children, parents, ...r };
    }, [clientFormId, enseigneFormId, bails, links]);

    const sitesDescendance = React.useMemo(() => getAscendance(getDescendance(sites, true)).map(({ data, parents, children, ...r }) => {
        let hasParcel = false;

        let notedChildren = getAscendance(getDescendance(children))
            .map(c => {
                if (c.form === buildFormId) return noteBuilding(c);
                else if (c.form === emplacementFormId && c?.data?.isRentable) return noteCell(c);
                else if (c.form === parcelFormId) hasParcel = true;
                return c;
            });

        let enterprise = _.uniq(parents.filter(({ form, data }) => form === clientFormId && TB.validString(data?.name)).map(({ data }) => data.name));
        let countries = [data.country].filter(TB.validString);

        let buildNote = notedChildren.filter(({ note, form }) => typeof note === "number" && form === buildFormId).map(({ note }) => note);
        let cellNote = notedChildren.filter(({ note, form }) => typeof note === "number" && form === emplacementFormId).map(({ note }) => note);

        let noteWeight = 100 / cellNote.length;
        let buildWeight = 100 / buildNote.length;

        let noteSiteCell = _.reduce(cellNote, (total, note) => total + (note / 100) * noteWeight, 0);
        let noteSiteBuild = _.reduce(buildNote, (total, note) => total + (note / 100) * buildWeight, 0);

        let allNotes = [noteSiteBuild, hasParcel ? 100 : 0, noteSiteCell];
        let noteSite = Math.round(_.reduce(allNotes, (total, note) => total + (note / 100) * (100 / allNotes.length), 0));

        let noteOj = {
            note: noteSite,
            parcelNote: hasParcel ? 100 : 0,
            cellNote: Math.round(noteSiteCell),
            buildNote: Math.round(noteSiteBuild),
        };

        return { data, ...noteOj, children: notedChildren, parents, ...r, countries, enterprise };
    }), [sites, buildFormId, parcelFormId, clientFormId, emplacementFormId, getDescendance, noteBuilding, noteCell, getAscendance]);
    //#endregion

    //#region Grouping
    const maxSiteInNav = React.useMemo(() => 5, []);

    const getNavLayout = React.useCallback((selectedIndex = -1, list) => {
        if (selectedIndex === -1 || list.length <= maxSiteInNav) return { siteToShowInNav: list };

        if (selectedIndex < (maxSiteInNav / 2)) return {
            siteToShowInNav: list.filter((s, i) => i < maxSiteInNav),
            isRestLeft: false
        };

        if (selectedIndex > ((list.length - 1) - (maxSiteInNav / 2))) return {
            siteToShowInNav: list.filter((s, i) => i > (list.length - maxSiteInNav - 1)),
            isRestLeft: true
        };

        return {
            siteToShowInNav: list.filter((n, i) => i > (selectedIndex - (maxSiteInNav / 2)) && i < (selectedIndex + (maxSiteInNav / 2))),
            isRestLeft: null
        };
    }, [maxSiteInNav]);

    const groupNavBarsData = React.useMemo(() => {
        if (groupingTranslator.length === 0) return {};

        const groupByCountry = list => Object.fromEntries(
            Object.entries(_.groupBy(list, ({ data }) => data.country))
                .map(([country, sites]) => [country, sites.map(({ _id }) => _id)])
        );

        const groupByOwner = list => {
            let ownerObj = { none: [] };

            list.forEach(({ parents, ...site }) => {
                let clientParents = parents.filter(({ form }) => form === clientFormId);
                clientParents.forEach(({ data }) => {
                    let name = data?.name;
                    if (TB.validString(name)) {
                        if (!Array.isArray(ownerObj[name])) ownerObj[name] = [{ parents, ...site }];
                        else ownerObj[name].push({ parents, ...site });
                    }
                    /* @ts-ignore */
                    else ownerObj.none.push({ parents, ...site });
                });
            });

            return Object.fromEntries(Object.entries(ownerObj)
                .filter(([key, array]) => Array.isArray(array) && array.length > 0)
                .map(([key, array]) => [key, _.unionBy(array, "_id")])
            );
        }

        const groupSwitcher = prop => {
            switch (prop) {
                case "countries": return groupByCountry;
                case "enterprise": return groupByOwner;
                default: return _.groupBy;
            }
        }

        let tempSites = sitesDescendance.filter(({ _id }) => filtered.includes(_id)).map(c => _.clone(c));

        const recursive = (obj, prop) => {
            let entries = Object.entries(obj);
            /* @ts-ignore */
            if (entries.every(([key, val]) => Array.isArray(val))) return Object.fromEntries(entries.map(([key, array]) => [key, groupSwitcher(prop)(array, prop)]));
            if (entries.every(([key, val]) => TB.validObject(val))) return Object.fromEntries(entries.map(([key, obj]) => [key, recursive(obj, prop)]));
            return obj;
        }

        for (let prop of groupingTranslator) {
            if (Array.isArray(tempSites)) tempSites = groupSwitcher(prop)(tempSites, prop);
            else if (TB.validObject(tempSites)) tempSites = recursive(tempSites, prop);
        }

        return tempSites;
    }, [groupingTranslator, sitesDescendance, clientFormId, filtered]);

    React.useEffect(() => {
        const recursive = (values, object, i, pos = 0) => TB.validObject(object) && i < grouping.length ? recursive(values.concat(Object.keys(object)[pos]), Object.values(object)[pos], i + 1) : values;
        if (groupingSelection.length === 0 && grouping.length > 0) setGroupingSelection(recursive([], groupNavBarsData, 0));
        else {
            for (let i = 0; i < groupingSelection.length; i++) {
                let keyArray = groupingSelection.filter((k, j) => j <= i);
                let key = keyArray.join('.');
                let valueObj = i === 0 ? groupNavBarsData : _.get(groupNavBarsData, key);


                if (!Array.isArray(valueObj) && (!TB.validObject(valueObj) || !Object.keys(valueObj).includes(groupingSelection[i]))) {
                    let previousKeyArray = _.dropRight(keyArray, 1);
                    let previousKey = previousKeyArray.join(".");
                    let previousObj = _.get(groupNavBarsData, previousKey);

                    let newGrouping = recursive(previousKeyArray, previousObj, i);
                    if (!_.isEqual(newGrouping, groupingSelection)) setGroupingSelection(newGrouping);
                    break;
                }
            }
        }
    }, [groupNavBarsData, grouping, groupingSelection]);

    const onChangeGroupTab = React.useCallback((val, index) => setGroupingSelection(prev => prev.map((x, i) => i === index ? val : x)), []);

    const groupNavBars = React.useMemo(() => groupingSelection.map((selectedKey, i) => {
        let accessKey = groupingSelection.filter((str, index) => index < i).join(".");
        let subObject = _.get(groupNavBarsData, accessKey) ?? groupNavBarsData;

        const recursive = value => {
            if (Array.isArray(value)) return value.map(({ note }) => note);
            if (TB.validObject(value)) return Object.values(value).map(recursive);
        }

        let arrayNotePerKey = recursive(subObject);
        let notePerKey = arrayNotePerKey.map(array => Math.round(_.sum(_.flatten(array)) / array.length));

        let valList = Object.keys(subObject).map((key, i) => ({ key, note: notePerKey[i] }));
        let selectedIndex = _.findIndex(valList, ["key", selectedKey]);
        let { siteToShowInNav, isRestLeft } = getNavLayout(selectedIndex, valList);

        let onClickArrows = (isRestLeft?: boolean) => {
            let key;
            if (isRestLeft) key = valList?.[Math.floor(selectedIndex - (maxSiteInNav / 2))]?.key;
            else key = valList?.[Math.floor(selectedIndex + (maxSiteInNav / 2))]?.key;
            onChangeGroupTab(key, i);
        }

        return <div key={selectedKey}>
            <ul className="nav nav-tabs align-items-stretch flex-nowrap">
                {(isRestLeft || isRestLeft === null) && <li className="nav-item">
                    <button onClick={() => onClickArrows(true)} className="nav-link"><i className="fa fa-angle-double-left text-primary"></i></button>
                </li>}
                {siteToShowInNav.map(({ key, note }) => <li key={key} className="nav-item flex-grow-1">
                    <div onClick={() => onChangeGroupTab(key, i)} className={`pointer h-100 text-center w-100 align-items-center d-flex nav-link ${key === selectedKey ? "active" : ""}`}>
                        <span className="flex-grow-1 fs-85">{key} - <b>{note}%</b></span>
                    </div>
                </li>)}
                {!isRestLeft && <li className="nav-item">
                    <button onClick={() => onClickArrows()} className="nav-link"><i className="fa fa-angle-double-right text-primary"></i></button>
                </li>}
            </ul>
        </div>
    }), [groupingSelection, groupNavBarsData, maxSiteInNav, onChangeGroupTab, getNavLayout]);
    //#endregion

    //#region Navigation
    const filteredSites = React.useMemo(() => sitesDescendance?.filter?.(({ _id }) => filtered.includes(_id)) ?? [], [sitesDescendance, filtered]);

    const showSites = React.useMemo<T.SiteType[]>(() => {
        if (groupingSelection.length === 0) return Array.isArray(filteredSites) ? filteredSites : [];
        let fullAccess = groupingSelection.join(".");
        let sites = _.get(groupNavBarsData, fullAccess);
        return Array.isArray(sites) ? sites.filter(({ _id }) => filtered.includes(_id)) : [];
    }, [groupingSelection, filteredSites, groupNavBarsData, filtered]);

    React.useEffect(() => {
        if (showSites.length > 0) {
            let newSiteList = showSites.map(({ _id }) => _id);
            // let defaultSite = newSiteList.filter(id => dataContext.selectedSites.includes(id))[0];
            if (!newSiteList.includes(activeTabSite || "")) setActiveTabSite(/* defaultSite || */ newSiteList[0]);
        }
        else setActiveTabSite(undefined);
    }, [activeTabSite, showSites]);

    const addSite = React.useCallback(() => {
        M.renderFormModal({ title: "New Site todo", path: FP.SITE_FORM }).then(site => {
            if (TB.mongoIdValidator(site?._id) && TB.validSubmission(site)) {
                // Create link with selected client
                let unmount = M.renderLoader();
                US.createLink({ input: selectedClient, output: site._id, type: ownLinkType }).then(({ data }) => {
                    // Update descendance & links + Change activeSiteTab
                    if (TB.validLink(data)) {
                        // Update descendance & links + Change activeSiteTab
                        setDescendance(prev => ({
                            descendance: TB.getArray(prev.descendance).concat(site),
                            links: TB.getArray(prev.links).concat(data),
                        }));
                        setActiveTabSite(site._id);
                    }
                    else {
                        M.renderAlert({ type: "error", message: "Failed to create a new site todo" });
                        US.removeSubmission(site._id);
                    }
                    unmount();
                });
            }

        });
    }, [setDescendance, selectedClient, ownLinkType]);

    const siteSearchItems = React.useMemo(() => sitesDescendance.map(({ _id, data, ...r }) => ({ id: _id, ...data, ...r, })), [sitesDescendance]);

    const filterButton = React.useMemo(() => <li ref={popRef} className="nav-item">
        <button onClick={() => setOpenPopOver(p => !p)} className="nav-link"><i className={`fa fa-${openPopOver ? "times" : "search"} text-primary`}></i></button>
    </li>, [openPopOver]);

    const popOver = React.useMemo(() => <Popper open hidden={!openPopOver} anchorEl={popRef.current} placement="left">
        <div className="p-3 bg-white border border-dark shadow rounded" style={{ width: "35rem" }}>
            {/* @ts-ignore */}
            <SearchModule itemList={siteSearchItems} {...searchModuleProps} />
        </div>
    </Popper>, [openPopOver, searchModuleProps, siteSearchItems]);

    const selectedSiteIndex = React.useMemo(() => showSites.map(({ _id }) => _id).indexOf(activeTabSite || ""), [showSites, activeTabSite]);
    const { siteToShowInNav, isRestLeft } = React.useMemo(() => getNavLayout(selectedSiteIndex, showSites), [selectedSiteIndex, showSites, getNavLayout]);

    const onClickRest = React.useCallback((isRestLeft?: boolean) => {
        if (isRestLeft) setActiveTabSite(showSites[Math.floor(selectedSiteIndex - (maxSiteInNav / 2))]?._id);
        else setActiveTabSite(showSites?.[Math.floor(selectedSiteIndex + (maxSiteInNav / 2))]?._id);
    }, [showSites, selectedSiteIndex, maxSiteInNav]);

    const titlesSiteNotes = React.useMemo(() => ["Propriété", "Syndic"], []);

    const siteTabulation = React.useMemo(() => <div>
        {groupNavBars}
        <ul className="nav nav-tabs align-items-stretch flex-nowrap">
            <li className="nav-item">
                <button onClick={addSite} className={"nav-link " + (activeTabSite === null ? "active" : "")}>
                    <i className="fa fa-plus text-primary"></i>
                </button>
            </li>
            {(isRestLeft || isRestLeft === null) && <li className="nav-item">
                <button onClick={() => onClickRest(true)} className="nav-link"><i className="fa fa-angle-double-left text-primary"></i></button>
            </li>}
            {siteToShowInNav.map(({ data, _id, note }) => <li key={_id} className="nav-item flex-grow-1">
                <div onClick={() => setActiveTabSite(_id)} className={"pointer h-100 text-center w-100 align-items-center d-flex nav-link " + (activeTabSite === _id ? "active" : "")}>
                    <span className="flex-grow-1 fs-85">
                        {data?.name} {TB.validString(data?.numSite) || typeof data?.numSite === "number" ? `(${data?.numSite})` : ""}
                        {" "} - <b>{note}%</b>
                    </span>
                    {activeTabSite === _id && <button onClick={() => addNote(_id, titlesSiteNotes)} className="btn btn-primary btn-sm"><i className="fa fa-comment-alt mr-0"></i></button>}
                </div>
            </li>)}
            {!isRestLeft && <li className="nav-item">
                <button onClick={() => onClickRest()} className="nav-link"><i className="fa fa-angle-double-right text-primary"></i></button>
            </li>}
            {filterButton}
        </ul>
        {popOver}
    </div>, [activeTabSite, groupNavBars, popOver, filterButton, siteToShowInNav, isRestLeft, titlesSiteNotes, onClickRest, addSite, addNote]);
    //#endregion

    //#region Add Entity
    const { children, parcelNote, cellNote, buildNote, ...activeSite } = React.useMemo(() => sitesDescendance.filter(({ _id }) => _id === activeTabSite)?.[0] ?? {}, [sitesDescendance, activeTabSite]);

    /* @ts-ignore */
    const addEntity = React.useCallback((root, inputId, { linkType, linkId, energyForced } = {}) => {
        let promise = TB.mongoIdValidator(inputId) ? new Promise(r => r(inputId))
            : M.renderLightTree({
                root: TB.mongoIdValidator(root) ? root : selectedClient,
                linkRestriction: {
                    isInput: true,
                    objForm: entityFormId,
                    linkType: TB.validString(linkType) ? linkType : LT.LINK_TYPE_OWN,
                },
                style: {
                    size: "lg",
                    title: "Emplacement du compteur",
                },
            })

        promise.then(input => {
            if (TB.mongoIdValidator(input)) M.renderFormModal({
                path: FP.ENTITY_FORM,
                forcedSubmission: [
                    { prop: "siteId", value: activeSite?._id },
                    { prop: "typedecompteur", value: "MANUAL" },
                    { prop: "energyForced", value: energyForced },
                    { prop: "stationsList", value: activeSite?.data?.stations },
                ],
            }).then(async entity => {
                if (TB.mongoIdValidator(entity?._id)) {
                    let link = { input, output: entity?._id, type: TB.mongoIdValidator(linkId) ? linkId : ownLinkType };
                    /* @ts-ignore */
                    let reply = await AttachMultipleObjParents([link], user);
                    if (reply === true) {
                        /* @ts-ignore */
                        setDescendance(({ links, descendance }) => ({ links: links.concat(link), descendance: descendance.concat(entity) }));
                        setActivePanel(3);
                    }
                    else {
                        US.removeSubmission(entity?._id);
                        M.renderErrorModal({ errorCode: EC.CODE_DB_UPDATE_FAIL });
                    }
                }
            });
        });
    }, [entityFormId, ownLinkType, user, selectedClient, activeSite, setDescendance]);
    //#endregion

    //#region Sites
    const siteChildrenIds = React.useMemo(() => children?.map(({ _id }) => _id)?.concat?.(activeSite?._id)?.filter?.(TB.mongoIdValidator) ?? [], [children, activeSite]);

    const siteCopro = React.useMemo(() => {
        return allCopro
            .map(({ coOwned, ...r }) => ({ ...r, coOwned: coOwned.filter(({ _id }) => siteChildrenIds.includes(_id)) }))
            .filter(({ coOwned }) => coOwned.length > 0)
    }, [allCopro, siteChildrenIds]);

    const siteSyndic = React.useMemo(() => {
        return allSyndic.map(({ managed, ...r }) => ({ ...r, managed: managed.filter(({ _id }) => siteChildrenIds.includes(_id)) }))
            .filter(({ managed }) => managed.length > 0);
    }, [allSyndic, siteChildrenIds]);

    const childrenLinks = React.useMemo(() => {
        let childrenArray = Array.isArray(children) ? children : [];
        let otherIds = _.flatten([activeSite?._id, siteSyndic.map(({ _id }) => _id), siteCopro.map(({ _id }) => _id)]).filter(TB.mongoIdValidator);
        let ids = childrenArray.map(({ _id }) => _id).concat(otherIds).filter(TB.mongoIdValidator);
        return Array.isArray(links) ? links.filter(({ input, output }) => [input, output].every(id => ids.includes(id))) : [];
    }, [children, activeSite, links, siteCopro, siteSyndic]);

    const onSubmitDoneSite = React.useCallback(async (site: T.Submission) => {
        if (!TB.mongoIdValidator(selectedClient) || !TB.mongoIdValidator(site?._id) || !TB.mongoIdValidator(ownLinkType)) return;
        let unmount = M.renderLoader();

        let link = { input: selectedClient, output: site._id, type: ownLinkType };
        let replyLink = await AttachMultipleObjParents([link], user);

        unmount();

        if (replyLink === true) {
            setDescendance(({ descendance, links }) => ({
                descendance: descendance.concat(site),
                /* @ts-ignore */
                links: links.concat(link),
            }));
            setActiveTabSite(site._id);
        }
        else {
            US.removeSubmission(site._id);
            M.renderErrorModal({ errorCode: EC.CODE_DB_UPDATE_FAIL });
        }
    }, [setDescendance, ownLinkType, selectedClient, user]);

    const addSitePanel = React.useMemo(() => <div className="w-75 mx-auto my-2">
        <Form path={FP.SITE_FORM} onSave={onSubmitDoneSite} />
    </div>, [onSubmitDoneSite]);

    const sitePanelProps = React.useMemo(() => ({
        forms,
        addNote,
        children,
        addEntity,
        linkTypes,
        onUpdateTree,
        onUpdateLinks,
        childrenLinks,
        site: activeSite,
        onChangeParcelLink,
        onUpdateDescendants,
        coproList: siteCopro,
        syndicList: siteSyndic,
        equipGammes: defaultGammes,
    }), [children, activeSite, childrenLinks, siteCopro, siteSyndic, defaultGammes, linkTypes, forms, onChangeParcelLink, addEntity, addNote, onUpdateDescendants, onUpdateLinks, onUpdateTree]);

    const sitePanels = React.useMemo(() => !TB.mongoIdValidator(activeSite?._id) ? (activeTabSite === null ? addSitePanel : undefined) : <div className="h-100">
        <SitePanel key={activeSite?._id} {...sitePanelProps} />
    </div>, [addSitePanel, activeSite, activeTabSite, sitePanelProps]);
    //#endregion

    //#region Buildings
    const siteBuildings = React.useMemo(() => children?.filter?.(({ form }) => form === buildFormId) ?? [], [children, buildFormId]);
    const buildingDescendance = React.useMemo(() => getDescendance(siteBuildings, true), [siteBuildings, getDescendance]);

    const buildingChildrenLinks = React.useMemo(() => {
        /* @ts-ignore */
        let ids = _.uniq(_.flatten(buildingDescendance.map(({ children }) => children)).map(({ _id }) => _id)).filter(TB.mongoIdValidator);
        if (ids.length === 0) return [];
        return links.filter(({ input, output }) => [input, output].every(id => ids.includes(id)));
    }, [links, buildingDescendance]);

    const buildingPanelProps = React.useMemo(() => ({
        forms,
        addNote,
        addEntity,
        linkTypes,
        onUpdateTree,
        onUpdateDescendants,
        siteId: activeTabSite,
        clientId: selectedClient,
        equipGammes: defaultGammes,
        buildings: buildingDescendance,
        childrenLinks: buildingChildrenLinks,
    }), [buildingChildrenLinks, buildingDescendance, defaultGammes, forms, linkTypes, activeTabSite, selectedClient, onUpdateDescendants, addNote, onUpdateTree, addEntity]);

    const buildingPanel = React.useMemo(() => <div className="h-100">
        <BuildingPanel {...buildingPanelProps} />
    </div>, [buildingPanelProps])
    //#endregion

    //#region Cells
    const siteCells = React.useMemo(() => children?.filter?.(({ form, data }) => form === emplacementFormId) ?? [], [children, emplacementFormId]);
    const cellsDescendance = React.useMemo(() => getDescendance(siteCells), [siteCells, getDescendance]);

    const cellsAscendance = React.useMemo(() => getAscendance(cellsDescendance).map(({ _id, parents, ...r }) => ({
        _id,
        ...r,
        parents,
        building: parents?.filter?.(({ form }) => form === buildFormId)?.[0],
        enseigne: parents?.filter?.(({ form }) => form === enseigneFormId)?.[0],
    })), [cellsDescendance, buildFormId, enseigneFormId, getAscendance]);

    const cellsRelatedLinks = React.useMemo(() => {
        /* @ts-ignore */
        let ids = _.uniq(_.flattenDeep(cellsAscendance.map(({ children, parents, _id }) => [children, parents, { _id }])).map(({ _id }) => _id)).filter(TB.mongoIdValidator);
        if (ids.length === 0) return [];
        return links.filter(({ input, output }) => [input, output].every(id => ids.includes(id)));
    }, [cellsAscendance, links]);

    const cellsBails = React.useMemo(() => {
        if (!Array.isArray(bails) || bails.length === 0) return [];
        let linksIds = cellsRelatedLinks.map(({ _id }) => _id).filter(TB.mongoIdValidator);
        if (linksIds.length === 0) return [];
        return bails.filter(({ data }) => linksIds.includes(data?.link));
    }, [bails, cellsRelatedLinks]);

    const cellPanelProps = React.useMemo(() => ({
        forms,
        addNote,
        linkTypes,
        addEntity,
        onUpdateTree,
        onUpdateBails,
        site: activeSite,
        bails: cellsBails,
        onUpdateDescendants,
        cells: cellsAscendance,
        client: selectedClient,
        relatedLinks: cellsRelatedLinks
    }), [cellsRelatedLinks, activeSite, cellsBails, selectedClient, linkTypes, cellsAscendance, forms, addNote, onUpdateDescendants, onUpdateTree, onUpdateBails, addEntity]);

    const cellPanel = React.useMemo(() => <div className="h-100">
        <CellPanel {...cellPanelProps} />
    </div>, [cellPanelProps]);
    //#endregion

    //#region Entities
    const entities = React.useMemo(() => children?.filter?.(({ form }) => form === entityFormId) ?? [], [children, entityFormId]);

    const entityPanelProps = React.useMemo(() => ({
        addNote,
        entities,
        addEntity,
        linkTypes,
        onUpdateTree,
        site: activeSite,
    }), [entities, linkTypes, activeSite, addEntity, addNote, onUpdateTree]);

    const entityPanel = React.useMemo(() => <div className="h-100">
        <EntityPanel {...entityPanelProps} />
    </div>, [entityPanelProps]);
    //#endregion

    //#region PanelSelection
    const panels = React.useMemo(() => [
        { label: getStaticText(TC.GLOBAL_LABEL_SITE), content: sitePanels, note: parcelNote },
        { label: getStaticText(TC.GLOBAL_LABEL_BUILD), content: buildingPanel, note: buildNote },
        { label: `${getStaticText(TC.GLOBAL_LABEL_CELLS)} / ${getStaticText(TC.GLOBAL_LABEL_RENTER)}`, content: cellPanel, note: cellNote },
        { label: getStaticText(TC.GLOBAL_LABEL_ENTITY), content: entityPanel }
    ], [sitePanels, buildingPanel, cellPanel, parcelNote, cellNote, entityPanel, buildNote, getStaticText]);

    const panelSelect = React.useMemo(() => <div className="p-2">
        <ul className="nav nav-pills">
            {panels.map(({ label, note }, i) => <li key={i} className="nav-item flex-grow-1 border">
                <button onClick={() => setActivePanel(i)} className={"nav-link rounded-0 w-100 " + (activePanel === i ? "active" : "")}>
                    {label}{note !== undefined ? ` (${note}%)` : ""}
                </button>
            </li>)}
        </ul>
    </div>, [activePanel, panels]);

    const { content } = React.useMemo(() => panels?.[activePanel] ?? { content: <div></div> }, [panels, activePanel]);
    //#endregion

    //#region Error  & Loading ...
    const errorBanner = React.useMemo(() => {
        if (status === "load") return <M.Loader lowerZIndex />;
        if (status === "noContext") return <ErrorBanner type="warning" textCode={TC.GLOBAL_NO_CONTEXT} />;
        if (status === "error") return <ErrorBanner type="danger" textCode={TC.GLOBAL_FAILED_LOAD} />;
        return null;
    }, [status]);
    //#endregion

    return <div className="flex-grow-1">
        {errorBanner}
        {status === "ready" && <div className="h-100 d-flex flex-column">
            {siteTabulation}
            <div className="bg-white h-100 flex-grow-1">
                <div className="h-100 d-flex flex-column position-relative">
                    <div className="flex-shrink-1">{panelSelect}</div>
                    {TB.mongoIdValidator(activeTabSite) && content}
                    {!TB.mongoIdValidator(activeTabSite) && <div className="position-absolute top-50 start-50 translate-middle">
                        <div className="jumbotron h2 text-muted">
                            Pas de site sélectionné
                        </div>
                    </div>}
                </div>
            </div>
        </div>}
    </div>
}

export default SiteForm;