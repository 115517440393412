import _ from "lodash";
import React from "react";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import { Table, TableProps } from "../Grid";
import { ContextMenuItem } from "../TableTypes";
import { CellsTypes as CT } from "../AgGridDefs";
import * as US from "../../../services/user.service";
import { TC, FP, T, TB, TABS, RIGHTS } from "../../../Constants";

//#region Types
type Resource = {
    places: LocMix;
    forms: T.FormType[];
    gammes: T.EquipGammeType[];
    contracts: T.ContractType[];
    locations: T.LocationType[];
}

type Row = Resource["contracts"][number];
type ContractsTableProps = { rootId?: string };
type LocMix = (T.EmplacementType | T.SiteType | T.BuildingType | T.ParkingType)[];
//#endregion

const TEXT_CODES = [
    TC.GLOBAL_FAILED_LOAD, TC.GLOBAL_NO_CONTEXT, TC.GLOBAL_SHOW, TC.GLOBAL_EDIT, TC.GLOBAL_DELET_N_ITEMS, TC.GLOBAL_DELETE, TC.CONTRACT_ADD_NEW,
    TC.CONTRACT_ADD_SAME_REFS
];

const DF_RESOURCE: Resource = { places: [], contracts: [], locations: [], forms: [], gammes: [] };

const ContractsTable: React.FC<ContractsTableProps> = props => {
    const rights = H.useRights();
    const [roots] = H.useRoots();
    H.useCrumbs(TC.TAB_CONTRACTS);
    const lg = H.useLanguage(TEXT_CODES);
    const [{ userId }] = H.useAuth({ tabName: TABS.CONTRACTS_TABLE });
    const [resource, setResources, status] = H.useAsyncState<Resource>(DF_RESOURCE);
    const { contracts, forms, gammes, places, locations } = React.useMemo(() => resource, [resource]);

    React.useEffect(() => {
        let context: T.ContextParams = props.rootId ? { roots: props.rootId } : roots;

        let isSubscribed = true;
        US.getFullResources(FP.CONTRACT_FORM, context).then(({ data }) => {
            if (isSubscribed) {
                if (data?.hasFailed) setResources(DF_RESOURCE, "error");
                else setResources(data, "done");
            }
        }).catch(() => isSubscribed && setResources(DF_RESOURCE, "error"));
        return () => {
            isSubscribed = false;
            setResources(DF_RESOURCE, "load");
        }
    }, [roots, props.rootId, setResources]);

    const rows = React.useMemo(() => contracts.map(c => ({
        ...c,
        perimeter_loc: places.filter(p => (c.data.perimeter?.loc || []).includes(p._id)).map(p => p.data.name).join(),
        perimeter_gammes: gammes.filter(p => (c.data.perimeter?.gammes || []).includes(p._id)).map(p => p.data.name).join(),
    })), [places, gammes, contracts]);

    const columns = React.useMemo(() => [
        { field: "perimeter_loc", headerName: TC.GLOBAL_LOCATION },
        { field: "perimeter_gammes", headerName: TC.TAB_GAMME_EQUIP },
        { field: "data.title", headerName: TC.GLOBAL_TITLE },
        { field: "data.receiver", headerName: TC.CONTRACT_RECEIVER },
        { field: "data.start", headerName: TC.P_START_DATE, type: CT.TYPE_DATE },
        { field: "data.end", headerName: TC.P_END_DATE, type: CT.TYPE_DATE },
        { field: "data.category", headerName: TC.CONTRACT_CATEGORY },
        { field: "data.noticePeriod", headerName: TC.CONTRACT_NOTICE, type: CT.TYPE_NUMBER, params: { maxDigit: 0 } },
        { field: "data.yearlyPrice", headerName: TC.CONTRACT_PRICE, type: CT.TYPE_NUMBER, params: { maxDigit: 2 } },
        { field: "data.file", headerName: TC.G_FILE_LABEL, type: CT.TYPE_FILE },
        { field: "data.internRef", headerName: TC.CONTRACT_INT_REF },
        { field: "data.externRef", headerName: TC.CONTRACT_EXT_REF },
        { field: "data.obligations", headerName: TC.CONTRACT_OBLIGATIONS },
        { field: "data.facturation", headerName: TC.CONTRACT_FACTURATION },
        { field: "data.reconduction", headerName: TC.CONTRACT_RECONDUCTION, type: CT.TYPE_CHECKBOX },
        { field: "data.indexation", headerName: TC.CONTRACT_INDEXATION, type: CT.TYPE_CHECKBOX },
        { field: "data.indexationDate", headerName: TC.CONTRACT_INDEXATION_DATE, type: CT.TYPE_DATE },
    ], []);

    const changes = React.useMemo(() => ({
        edit: (contract: T.ContractType, readOnly = false) => {
            M.renderFormModal({ submissionId: contract._id, path: FP.CONTRACT_FORM, readOnly }).then(newContract => {
                if (TB.isContract(newContract)) setResources(p => ({ ...p, contracts: p.contracts.map(c => c._id === newContract._id ? newContract : c) }));
            });
        },
        delete: (contracts: T.ContractType | T.ContractType[]) => {
            let number = Array.isArray(contracts) && contracts.length.toString();
            let title = Array.isArray(contracts) ? TC.GLOBAL_DELET_N_ITEMS : TC.GLOBAL_DELETE;

            M.askConfirm({ title: lg.getStaticText(title, number || undefined) }).then(confirmed => {
                if (confirmed) {
                    let ids = Array.isArray(contracts) ? contracts.map(t => t._id) : [contracts._id];
                    US.removeManySubmissionsFromId(ids).then(({ data }) => {
                        if (data?.ok) setResources(p => ({ ...p, contracts: p.contracts.filter(t => !ids.includes(t._id)) }));
                        else M.renderAlert({ type: "error", message: TC.GLOBAL_ERROR_DELETE });
                    });
                }
            });
        },
        create: (contract?: T.ContractType, params?: { sameRef?: boolean }) => {
            let renderContent: Parameters<typeof M.renderBlankModal>[0]["renderContent"] = (resolve) => <ContractPerimeter
                forms={forms}
                gammes={gammes}
                locations={places}
                itemLoc={locations}
                onConfirm={(loc, gammes) => resolve({ loc, gammes })}
            />;

            let submission: (T.ContractData | null) = null;
            if (TB.isContract(contract)) {
                submission = {};
                if (params?.sameRef) submission = _.omit({ ...contract.data }, "perimeter");
                if (Object.keys(submission).length === 0) submission = null;
            }

            M.renderBlankModal({ renderContent, size: "md", title: TC.GLOBAL_PERIMETER })
                .then(perimeter => {
                    let forcedSubmission = TB.submissionToArrayUpdate(submission);
                    if (perimeter !== null) M.renderFormModal({
                        path: FP.CONTRACT_FORM,
                        forcedSubmission: forcedSubmission.concat({ prop: "perimeter", value: perimeter })
                    })
                        .then(contract => TB.isContract(contract) && setResources(p => ({ ...p, contracts: p.contracts.concat(contract) })))
                });
        },
        edit_perimeter: (contract: T.ContractType) => {
            const renderContent: Parameters<typeof M.renderBlankModal>[0]["renderContent"] = (resolve) => <ContractPerimeter
                forms={forms}
                gammes={gammes}
                locations={places}
                itemLoc={locations}
                current={contract.data.perimeter}
                onConfirm={(loc, gammes) => resolve({ loc, gammes })}
            />;

            M.renderBlankModal({ renderContent, size: "md", title: TC.GLOBAL_PERIMETER }).then(perimeter => {
                if (perimeter) US.updateSubmission(contract._id, { "data.perimeter": perimeter }).then(() => {
                    setResources(p => ({
                        ...p,
                        contracts: p.contracts.map(c => {
                            if (c._id !== contract._id) return c;
                            return { ...c, data: { ...c.data, perimeter } };
                        }),
                    }));
                }).catch(M.Alerts.updateError);
            });
        }
    }), [forms, gammes, lg, locations, places, setResources]);

    const getContextMenu = React.useCallback<TableProps<Row>["getContextMenuItems"]>(params => {
        let contract = params.node?.data;
        let selectedContracts = params.api.getSelectedNodes().map(({ data }) => data).filter(TB.isContract);
        let extraItems: (string | ContextMenuItem)[] = [], defaultItems = TB.getArray(params.defaultItems).filter(TB.validString);

        let isOwn = contract?.owner === userId;
        let editRight = isOwn ? RIGHTS.MISC.WRITE_OWN_CONTRACTS : RIGHTS.MISC.WRITE_OTHER_CONTRACTS;
        let canEdit = rights.isRightAllowed(editRight);

        let canCreate = rights.isRightAllowed(RIGHTS.MISC.WRITE_OWN_CONTRACTS);

        if (contract) {
            extraItems.push({
                name: lg.getStaticText(TC.GLOBAL_SHOW),
                icon: "<i class='fa fa-search'></i>",
                action: () => changes.edit(contract, true)
            });

            if (canEdit) {
                extraItems.push(
                    {
                        name: lg.getStaticText(TC.GLOBAL_EDIT),
                        icon: "<i class='fa fa-pencil-alt'></i>",
                        action: () => changes.edit(contract),
                    },
                    {
                        name: lg.getStaticText(TC.CONTRACT_EDIT_PERIMETER),
                        icon: "<i class='fa fa-map-marked-alt'></i>",
                        action: () => changes.edit_perimeter(contract),
                    }
                );

                if (selectedContracts.length > 1) extraItems.push({
                    name: lg.getStaticText(TC.GLOBAL_DELET_N_ITEMS, selectedContracts.length.toString()),
                    icon: "<i class='fa fa-times text-danger'></i>",
                    action: () => changes.delete(selectedContracts)
                });
                else extraItems.push({
                    name: lg.getStaticText(TC.GLOBAL_DELETE),
                    icon: "<i class='fa fa-times text-danger'></i>",
                    action: () => changes.delete(contract)
                });
            }
        }

        if (canCreate) extraItems.push({
            action: changes.create,
            icon: "<i class='fa fa-plus'></i>",
            name: lg.getStaticText(TC.CONTRACT_ADD_NEW),
            subMenu: [{
                disabled: !TB.isContract(contract),
                icon: "<i class='fa fa-plus'></i>",
                name: lg.getStaticText(TC.CONTRACT_ADD_SAME_REFS),
                action: () => changes.create(contract, { sameRef: true }),
            }],
        })

        if (extraItems.length > 0 && defaultItems.length > 0) extraItems.push("separator");
        return extraItems.concat(defaultItems);
    }, [lg, changes, rights, userId]);

    return <div className="w-100">
        <C.Spinner error={status === "error"}>
            <Table<Row>
                sideBar
                rows={rows}
                status={status}
                columns={columns}
                rowSelection="multiple"
                groupDisplayType="singleColumn"
                adaptableId={TABS.CONTRACTS_TABLE}
                getContextMenuItems={getContextMenu}
                columns_base={["filterable", "grouped", "sortable"]}
                statusBar={{ statusPanels: [{ statusPanel: "agTotalRowCountComponent", align: "center" }] }}
            />
        </C.Spinner>
    </div>
}

export default ContractsTable;

//#region Perimeter editor
type ContractPerimeterProps = {
    /** The current perimeter */
    current?: T.ContractData["perimeter"];
    locations: LocMix;
    forms: T.FormType[];
    itemLoc: T.LocationType[];
    gammes: T.EquipGammeType[];
    onConfirm?: (locations: string[], gammes: string[]) => void;
}

const TEXT_CODE_PERIMETER = [TC.GLOBAL_LABEL_BUILD, TC.GLOBAL_PERIMETER, TC.TAB_GAMME_EQUIP, TC.GLOBAL_LABEL_SITE, TC.GLOBAL_LABEL_EMPLACEMENT, TC.GLOBAL_SAVE, TC.GLOBAL_LABEL_PARKING];

const ContractPerimeter: React.FC<ContractPerimeterProps> = ({ gammes, itemLoc, locations, forms, onConfirm, ...props }) => {
    const lg = H.useLanguage(TEXT_CODE_PERIMETER);
    const [locs, setLocs] = React.useState<string[]>(props.current?.loc || []);
    const [gammesEquip, setGammesEquip] = React.useState<string[]>(props.current?.gammes || []);

    //#region Forms & Array validation
    const formsArray = React.useMemo(() => TB.getArray(forms).filter(TB.isValidForm), [forms]);
    const gammesArray = React.useMemo(() => TB.getArray(gammes).filter(TB.isValidEquipGamme), [gammes]);
    const itemLocArray = React.useMemo(() => TB.getArray(itemLoc).filter(TB.isValidLocationObj), [itemLoc]);
    const locArray = React.useMemo<LocMix>(() => TB.getArray(locations).filter(s => TB.isBuilding(s) || TB.isSite(s) || TB.isEmplacement(s)), [locations]);

    const siteForm = React.useMemo(() => _.find(formsArray, f => f.path === FP.SITE_FORM)?._id, [formsArray]);
    const buildForm = React.useMemo(() => _.find(formsArray, f => f.path === FP.BUILDING_FORM)?._id, [formsArray]);
    const empForm = React.useMemo(() => _.find(formsArray, f => f.path === FP.EMPLACEMENT_FORM)?._id, [formsArray]);
    //#endregion

    //#region Options
    const gammesOptions = React.useMemo(() => gammesArray.map(g => ({ label: g.data.name, value: g._id, extra: g.data.omniclass, icon: g.data.isEquipment && "wrench" })), [gammesArray]);

    const [buildings, sites, emplacements, parkings] = React.useMemo(() => {
        let buildings = locArray.filter(l => l.form === buildForm) as T.Submission<T.BuildingData>[];
        let sites = locArray.filter(l => l.form === siteForm) as T.Submission<T.SiteData>[];
        let all_emplacements = locArray.filter(l => l.form === empForm) as T.Submission<T.EmplacementData>[];
        let [emplacements, parkings] = _.partition(all_emplacements, e => e.data.type !== "parking");
        return [buildings, sites, emplacements, parkings];
    }, [buildForm, siteForm, empForm, locArray]);

    const getOption = React.useCallback((array: LocMix) => array.map(s => {
        let loc = _.find(itemLocArray, l => l.id === s._id);
        let extra: (string | null) = null;
        if (loc !== undefined) extra = _.uniq(["local", "emplacement", "building", "site"].map(prop => loc?.[prop]?.[0]?.data?.name).filter(TB.validString)).join(' / ');
        return { label: s.data.name, value: s._id, extra };
    }), [itemLocArray]);

    const renderOption = React.useCallback((option?: { label: string, value: string, extra?: string | null, icon?: string }) => <div>
        {TB.validString(option?.icon) && <i className={`fa fa-${option?.icon} me-2`}></i>}
        <span>{option?.label}</span>
        {TB.validString(option?.extra) && <span className="ms-2 fs-75 fst-italic">({option?.extra})</span>}
    </div>, []);
    //#endregion

    //#region Selected Options
    const [selectedLoc, selectedLocForm] = React.useMemo(() => {
        let selected = locArray.filter(l => locs.includes(l._id));
        let form = _.uniq(selected.map(s => s.form))[0];
        return [getOption(selected).map(o => o.value), form];
    }, [getOption, locArray, locs]);
    //#endregion

    const locationCol = React.useMemo(() => [
        { label: TC.GLOBAL_LABEL_SITE, form: siteForm, id: "site", array: sites },
        { label: TC.GLOBAL_LABEL_BUILD, form: buildForm, id: "build", array: buildings },
        { label: TC.GLOBAL_LABEL_EMPLACEMENT, form: empForm, id: "emp", array: emplacements },
        { label: TC.GLOBAL_LABEL_PARKING, form: empForm, id: "parking", array: parkings },
    ].filter(o => o.array.length > 0), [buildForm, buildings, empForm, emplacements, siteForm, sites, parkings]);

    return <div className="position-relative">
        <BS.Row>
            <BS.Col>
                <BS.Form.Label>{lg.getStaticElem(TC.GLOBAL_PERIMETER)}</BS.Form.Label>
            </BS.Col>
        </BS.Row>
        <BS.Row className="mt-3">
            {locationCol.map(({ label, form, id, array }) => <BS.Col key={id}>
                <BS.Form.Label>{lg.getStaticElem(label)}</BS.Form.Label>
                <C.TypeAhead
                    id={id}
                    multiple
                    renderItem={renderOption}
                    options={getOption(array)}
                    onChange={opt => setLocs(opt.map(o => o.value))}
                    selectedItems={selectedLocForm === form ? selectedLoc : []}
                    disabled={selectedLocForm !== form && TB.mongoIdValidator(selectedLocForm)}
                />
            </BS.Col>)}
        </BS.Row>
        <BS.Row className="mt-3">
            <BS.Col>
                <BS.Form.Label>{lg.getStaticElem(TC.TAB_GAMME_EQUIP)}</BS.Form.Label>
                <C.TypeAhead
                    multiple
                    options={gammesOptions}
                    renderItem={renderOption}
                    selectedItems={gammesEquip}
                    onChange={opt => setGammesEquip(opt.map(o => o.value))}
                />
            </BS.Col>
        </BS.Row>

        <C.Flex className="mt-3" justifyContent="end">
            <BS.Button onClick={() => onConfirm?.(locs, gammesEquip)} disabled={locs.length === 0}>
                <i className="fa fa-save me-2"></i>
                {lg.getStaticElem(TC.GLOBAL_SAVE)}
            </BS.Button>
        </C.Flex>
    </div>
}
//#endregion