import React from "react";
import * as G from "../Grid";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as S from "../../../services";
import { CellsTypes } from "../AgGridDefs";
import { T, TABS, TC } from "../../../Constants";
import _ from "lodash";

type Row = Data["options"][number];
type Data = ReturnType<T.API.EDL.EQUIP_DATA.GetAllOptions>;

const CODES = {
    rei: TC.OPTION_CODE_REI,
    smoke: TC.OPTION_CODE_SMOKE,
    windowType: TC.OPTION_CODE_WINDOWS,
    materials: TC.OPTION_CODE_MATERIALS,
    app_pages: TC.OPTION_CODE_APP_PAGES,
    panelType: TC.OPTION_CODE_PANEL_TYPE,
    rem_status: TC.OPTION_CODE_REM_STATUS,
    elecPhases: TC.OPTION_CODE_ELEC_PHASES,
    refrigerant: TC.OPTION_CODE_REFRIGERANT,
    orientation: TC.OPTION_CODE_ORIENTATION,
    ticket_types: TC.OPTION_CODE_TICKET_TYPES,
    emplacement_type: TC.OPTION_CODE_EMP_TYPE,
    openingTypes: TC.OPTION_CODE_OPENING_TYPES,
    filtrationClass: TC.OPTION_CODE_FILTRATION,
    communication: TC.OPTION_CODE_COMMUNICATION,
    reg_doc_types: TC.OPTION_CODE_REG_DOC_TYPES,
    rem_cost_types: TC.OPTION_CODE_REM_COST_TYPE,
    rem_categories: TC.OPTION_CODE_REM_CATEGORIES,
    emplacement_affectation: TC.OPTION_CODE_AFFECTATIONS,
    intervenants_roles: TC.OPTION_CODE_INTERVENANTS_ROLES,
    reg_action_dashboards: TC.OPTION_CODE_REG_ACTION_DASH,
    connectionDiameter: TC.OPTION_CODE_CONNECTION_DIAMETER,
} as Record<Row["option_code"], string>;

const Options: React.FC = () => {
    const lg = H.useLanguage();
    H.useAuth({ onlyAdmin: true });
    const loading = H.useBoolean(false);
    const [data, set_data, status] = H.useAsyncState<Data>({ options: [], extra_columns: [] });

    React.useEffect(() => {
        let isSubscribed = true;
        S.getAllOptions()
            .then(({ data }) => isSubscribed && set_data(data, "done"))
            .catch(() => isSubscribed && set_data({ options: [], extra_columns: [] }, "error"));
        return () => {
            isSubscribed = false;
            set_data({ options: [], extra_columns: [] }, "error");
        }
    }, [set_data]);

    const rows = React.useMemo(() => (data.options || []).map(opt => {
        // Translate the label
        let tr_label = lg.getStaticText(opt.label);
        // Translater the option code
        let tr_option_code = lg.getStaticText(CODES[opt.option_code]);
        // Create the row
        let row = { ...opt, tr_label, tr_option_code };
        // Translate the extra columns
        data.extra_columns.forEach(col => {
            if (col.type === "translation" && col.code === opt.option_code) {
                row["tr_" + col.prop] = lg.getStaticText(opt[col.prop]);
            }
        });
        return row;
    }), [data, lg]);

    const columns = React.useMemo(() => {
        let columns: G.TableProps<Row>["columns"] = [
            { field: "tr_option_code", headerName: TC.OPTIONS_TABLE_CODE, editable: false },
            { field: "value", headerName: TC.OPTIONS_TABLE_VALUE },
            { field: "tr_label", headerName: TC.OPTIONS_TABLE_LABEL, editable: false },
            { field: "label", headerName: TC.OPTIONS_TABLE_LABEL_DB },
        ];

        for (let col of (data.extra_columns || [])) {
            if (col.type === "translation") columns.push(
                { field: "tr_" + col.prop, headerName: col.label, editable: false },
                { field: col.prop, headerName: TC.OPTIONS_TABLE_VAR_REF, params: { header_template: col.label } },
            );
            else {
                let type: string;
                if (col.type === "bool") type = CellsTypes.TYPE_CHECKBOX;
                else if (col.type === "date") type = CellsTypes.TYPE_DATE
                else if (col.type === "number") type = CellsTypes.TYPE_NUMBER;
                columns.push({ field: col.prop, type, headerName: col.label });
            }
        }
        return columns;
    }, [data.extra_columns]);

    const allow_edit = React.useCallback<G.TableProps<Row>["onCellEditingStarted"]>(event => {
        let allowed_no_check = ["label", "value"] as (keyof Row)[];
        // The cell edited isn't in a basic column
        if (!allowed_no_check.includes(event.colDef.field as any)) {
            let is_accepted = data.extra_columns.some(col => col.code === event.data.option_code && col.prop === event.colDef.field);
            if (!is_accepted) {
                event.api.stopEditing();
                M.renderAlert({ message: TC.OPTIONS_TABLE_PROP_NO_EDIT, type: "warning" });
            }
        }
    }, [data.extra_columns]);

    const onValueChange = React.useCallback<G.TableProps<Row>["onValueChange"]>(event => {
        let new_value: string;
        let prop: keyof Row = event.colDef.field as any;
        let sql_prop: keyof Omit<T.pSQL.AppOptions, "option_code">;

        if (prop === "value") {
            sql_prop = "value";
            new_value = event.newValue;
        }
        else if (prop === "label") {
            sql_prop = "label";
            new_value = event.newValue;
        }
        else {
            // Check if field is an extra, found in the extra columns
            let accepted_extra = data.extra_columns.filter(col => col.code === event.data.option_code).map(col => col.prop);
            if (accepted_extra.includes(prop as string)) {
                let new_json = {};
                // Parse the previous JSON value
                try { new_json = JSON.parse(event.data.label) }
                catch { new_json = {} }
                // Update the value
                new_json[prop] = event.newValue;
                // Stringify the new JSON
                new_value = JSON.stringify(new_json);
                sql_prop = "extra";
            }
            else M.renderAlert({ message: TC.OPTIONS_TABLE_PROP_NO_EDIT, type: "warning" });
        }

        // Check that we do have a prop and a value to update
        if (sql_prop && new_value) S.updateOption({ option_code: event.data.option_code, value: event.data.value, prop: sql_prop, new_value }).then(({ data }) => {
            set_data(p => ({
                ...p,
                options: p.options.map(opt => {
                    if (opt.option_code === event.data.option_code && opt.value === event.data.value) {
                        if (sql_prop === "extra") return { ...opt, ...JSON.parse(new_value) };
                        else return { ...opt, [sql_prop]: new_value };
                    }
                    else return opt;
                }),
            }));
        }).catch(M.Alerts.updateError);
    }, [set_data, data.extra_columns]);

    const events = React.useMemo(() => ({
        add_option: () => {
            // Make a list of existing option codes
            let option_codes = _.uniqBy(data.options, "option_code")
                .map(opt => ({ label: CODES[opt.option_code], value: opt.option_code }));
            // Ask user what option code he wants to add
            M.askSelect({ options: option_codes, title: TC.ADD_OPTION_TITLE }).then(option_code => {
                if (option_code) {
                    let extras = data.extra_columns.filter(col => col.code === option_code);

                    let params: any = {
                        modal: { isFullScreen: true, title: TC.ADD_OPTION_TITLE },
                        onCheck: rows => {
                            let errors: T.Errors<typeof rows[number]>[] = [];
                            for (let row of rows) {
                                let row_errors: T.Errors<typeof row> = {};
                                // Value is required
                                if (!row.value) row_errors.value = TC.GLOBAL_REQUIRED_FIELD;
                                // Check that the value is unique
                                else if (data.options.some(opt => opt.option_code === option_code && opt.value === row.value)) row_errors.value = TC.OPTIONS_TABLE_VALUE_UNIQUE;
                                // Label is required
                                if (!row.label) row_errors.label = TC.GLOBAL_REQUIRED_FIELD;
                                // Check that the label is unique
                                else if (data.options.some(opt => opt.option_code === option_code && opt.label === row.label)) row_errors.label = TC.OPTIONS_TABLE_VALUE_UNIQUE;
                                // Add the errors to the list
                                errors.push(row_errors);
                            }
                            return errors;
                        },
                        columns: [
                            { prop: "value", label: TC.OPTIONS_TABLE_VALUE, type: "text" },
                            { prop: "label", label: TC.OPTIONS_TABLE_LABEL_DB, type: "text" },
                        ],
                    }

                    for (let extra of extras) {
                        let type: typeof params.columns[0]["type"] = "text";
                        if (extra.type === "bool") type = "boolean";
                        else if (extra.type === "number") type = "number";
                        else if (extra.type === "date") type = null;
                        // Add the extra column
                        if (type) params.columns.push({ prop: extra.prop, label: extra.label, type });
                    }

                    M.renderQuickInput(params).then(new_rows => {
                        if (Array.isArray(new_rows) && new_rows.length > 0) {
                            let new_options = new_rows
                                .map(({ label, value, ...extra }) => ({ label, value, option_code: option_code as any, extra: JSON.stringify(extra) }));

                            S.createOption(new_options)
                                .then(() => set_data(p => ({ ...p, options: p.options.concat(new_options) })))
                                .catch(M.Alerts.updateError);
                        }
                    });
                }
            });
        },
        remove_option: (row: Row) => {
            if (!row) return;
            else M.askConfirm().then(confirmed => {
                if (confirmed) S.removeOption({ option_code: row.option_code, value: row.value })
                    .then(() => set_data(p => ({ ...p, options: p.options.filter(opt => opt.option_code !== row.option_code || opt.value !== row.value) })))
                    .catch(M.Alerts.deleteError);
            });
        },
    }), [set_data, data]);

    const context_menu = React.useCallback<G.TableProps<Row>["getContextMenuItems"]>(params => {
        let items = [
            {
                action: events.add_option,
                name: lg.getStaticText(TC.ADD),
                icon: "<i class='fas fa-plus'></i>",
            },
            {
                name: lg.getStaticText(TC.GLOBAL_DELETE),
                icon: "<i class='fas fa-times text-danger'></i>",
                action: () => events.remove_option(params.node.data),
            }
        ] as ReturnType<G.TableProps<Row>["getContextMenuItems"]>;

        if (items.length > 0 && params.defaultItems) items.push("separator");
        if (params.defaultItems) items.push(...params.defaultItems);
        return items;
    }, [events, lg]);

    return <div className="w-100">
        <C.Spinner error={status === "error"}>
            <G.Table<Row>
                sideBar
                rows={rows}
                status={status}
                columns={columns}
                columns_base="all"
                onValueChange={onValueChange}
                onCellEditingStarted={allow_edit}
                getContextMenuItems={context_menu}
                adaptableId={TABS.ADMIN_OPTIONS_TABLE}
                loading={loading.value || status === "load"}
            />
        </C.Spinner>
    </div>;
}

export default Options;