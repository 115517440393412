import React from "react";
import * as H from "../../../../hooks";
import { Form } from "../../../../Common";
import { Cell as Button } from "./Button";
import { T, TC } from "../../../../Constants";
import { ExtendedFilterParams } from "../../TableTypes";
import { IDoesFilterPassParams, IRowNode } from "ag-grid-community";

//#region Types
type Value = ReturnType<T.API.Utils.Tables.GetEmplacementRows>[number]["remarques"];
//#endregion

//#region Constants
const Options: T.Option<{}, Value["status"]>[] = [
    { value: "memo", label: TC.REM_BUTTON_FILTER_MEMO },
    { value: "open", label: TC.REM_BUTTON_FILTER_OPEN },
    { value: "none", label: TC.REM_BUTTON_FILTER_NONE },
];

export const Texts = Options.map(o => o.label).concat(TC.GLOBAL_ALL);
//#endregion

export const Cell = Button;
export const type = "agCustomRemButton";

export const sort = (a: Value, b: Value) => {
    let valueA = 0, valueB = 0;
    if (a?.remarque_color === "danger") valueA = 3;
    else if (a?.remarque_color === "warning") valueA = 2;
    else if (a?.remarque_color === "secondary") valueA = 1;
    if (b?.remarque_color === "danger") valueB = 3;
    else if (b?.remarque_color === "warning") valueB = 2;
    else if (b?.remarque_color === "secondary") valueB = 1;

    // Values have the same importance, sort on their number
    if (valueA === valueB) return (a?.nb_remarques || 0) - (b?.nb_remarques || 0);
    else return valueA - valueB;
};

export const Filter = React.forwardRef<{}, ExtendedFilterParams>((props, ref) => {
    H.useLanguage(Texts);
    const [selected, setSelected] = React.useState(Options.map(o => o.value));

    //#region Filter logic
    const setFilter = React.useCallback((filter: typeof selected) => {
        if (Array.isArray(filter) && filter.every(f => typeof f === "string")) setSelected(filter);
    }, []);

    const getValue = React.useCallback((node: IRowNode): Value => props.valueGetter({
        node,
        api: props.api,
        data: node.data,
        colDef: props.colDef,
        column: props.column,
        context: props.context,
        columnApi: props.columnApi,
        getValue: field => node.data[field],
    }), [props]);

    const doesFilterPass = React.useCallback((params: IDoesFilterPassParams) => {
        // No filter selected
        if (selected.length === 0) return false;
        let value = getValue(params.node);
        if (!value || !value?.status) return selected.includes("none");

        if (value.status === "both") return selected.includes("memo") || selected.includes("open");
        else return selected.includes(value.status);
    }, [selected, getValue]);

    const toggleFilter = React.useCallback((status: Value["status"]) => setSelected(p => {
        if (p.includes(status)) return p.filter(s => s !== status);
        else return p.concat(status);
    }), []);

    const toggleAll = React.useCallback(() => setSelected(p => {
        // All are selected
        if (p.length === Options.length) return [];
        else return Options.map(o => o.value);
    }), []);
    //#endregion

    //#region Ref
    const setModel = React.useCallback(model => setFilter(model?.value), [setFilter]);
    /* @ts-ignore */
    const getModel = React.useCallback(() => !this.isFilterActive?.() ? null : { value: selected }, [selected]);

    React.useEffect(() => props.filterChangedCallback?.(), [selected, props]);

    React.useImperativeHandle(ref, () => ({
        setModel,
        getModel,
        doesFilterPass,
        isFilterActive: () => true,
    }), [doesFilterPass, getModel, setModel]);
    //#endregion

    return <div className="p-2">

        <Form.CheckBox
            noBottomMargin
            customClass="mb-1"
            onChange={toggleAll}
            label={TC.GLOBAL_ALL}
            labelPosition="right"
            value={selected.length === Options.length}
        />

        {Options.map(o => <Form.CheckBox
            key={o.value}
            noBottomMargin
            label={o.label}
            customClass="mb-1"
            labelPosition="right"
            value={selected.includes(o.value)}
            onChange={() => toggleFilter(o.value)}
        />)}
    </div>;
});