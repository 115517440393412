import React from "react";
import * as I from "../types";
import * as M from "../../../Modal";
import * as BS from "react-bootstrap";
import { TB } from "../../../../Constants";

export type Props = {};

export const type = "agCustomQrCode";

export const EditCell = React.forwardRef<I.EditorRef<string>, I.EditorProps<Props>>((props, ref) => {
    const [code, setCode] = React.useState<string>(props.value);
    React.useImperativeHandle(ref, () => ({ getValue: () => code }), [code]);

    const scanCode = React.useCallback(() => {
        M.askScan({ size: "xs", title: "QR Scan" }).then(value => {
            if (TB.validString(value)) {
                setCode(value);
                props.api.stopEditing();
            }
        })
    }, [props.api]);

    return <BS.InputGroup className="h-100">
        <BS.FormControl autoFocus value={code} onChange={e => setCode(e.target.value)} />
        <BS.Button onClick={scanCode} children={<i className="fa fa-qrcode" />} />
    </BS.InputGroup>
});