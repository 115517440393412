import React from "react";
import * as M from "../../Modal";
import DropDown from "./DropDown";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import * as S from "../../../services";
import { DS, T, TB } from "../../../Constants";

//#region Types
export type FormulaProps = {
    /** Disable data entry */
    disabled?: boolean;
    /** The point of origin */
    origin: string;
    /** Change callback */
    onChange?: (formula: string) => void;
    /** The default value */
    value?: string;
    /** If the formula is written in a dataset, do not offer this dataset as an option */
    currentDataset?: string;
};

export type FormulaShellProps = FormulaProps & {
    /** The list of datasets, nodes, weather stations, ... */
    options: ReturnType<T.API.Utils.Datasets.FormulaOptions>;
    /** A list of props suggested for the nodes */
    props: ReturnType<T.API.Utils.Datasets.GetFormulaFormProps>;
    /** A option to hide the option to show a formula in a modal */
    no_expand?: boolean;
}
//#endregion

export const FormulaShell: React.FC<FormulaShellProps> = ({ onChange, ...props }) => {
    const lg = H.useLanguage();
    const [forms] = H.useFormIds();
    const areaRef = React.useRef<HTMLTextAreaElement>(null);
    const formula = React.useMemo(() => props.value || "", [props.value]);

    const cursor = H.useTextAreaPosition(areaRef);
    H.useAutoSizeTextArea(areaRef.current, formula);

    //#region Props
    const propList = React.useMemo(() => {
        let entries = props.props.map(fp => {
            let options = fp.properties.map(p => ({
                label: `${lg.getTextObj(forms[fp.path], p.prop, p.prop)} (${p.type})`,
                value: p.prop,
            }));
            return [fp.path, options]
        });
        return Object.fromEntries(entries);
    }, [lg, forms, props.props]);
    //#endregion

    //#region Translation
    const dissected = React.useMemo(() => TB.dissectFormula(formula), [formula]);

    const translation = React.useMemo(() => {
        let elements: React.ReactElement[] = [];

        for (let i = 0; i < dissected.length; i++) {
            let item = dissected[i];

            if (item.type === "text") {
                let j = 0;
                let splits = item.text.split("\n");

                for (let split of splits) {
                    if (j !== 0) elements.push(<br key={`${i}_${j}_br`} />);
                    elements.push(<span key={`${i}_${j}`} className="me-1">{split}</span>);
                    j++;
                }
            }
            else if (item.type === "station_tag") {
                let tag_id = item.tag, station_id = item.station;
                let station = props.options.filter(o => o.value === station_id)[0];
                let tag = station?.datasets?.filter?.(d => d.value === tag_id)[0];

                // Add the station
                if (station) elements.push(<span key={i + "_station"} className="me-1" style={{ backgroundColor: "lightskyblue" }}>
                    {station?.label || "N/A"}
                </span>);
                // Add the tag
                if (tag) elements.push(<span key={i + "_tag"} className="me-1" style={{ backgroundColor: "lightgreen" }}>
                    {tag?.label || "N/A"}
                </span>)

            }
            else {
                let itemId = item.item, datasetId = item.dataset;
                let option = props.options.filter(o => o.value === itemId)[0];
                let dataset = (option?.datasets || []).filter(d => d.value === datasetId)[0];

                // Add the item
                elements.push(<span key={i + "_item"} className="me-1" style={{ backgroundColor: "lightskyblue" }}>
                    {option?.label || "N/A"}
                </span>);
                // Add the dataset
                if (dataset) elements.push(<span key={i + "_data"} className="me-1" style={{ backgroundColor: "lightgreen" }}>
                    [{dataset?.label || "N/A"}]
                </span>);
                // Add the prop
                else if (item.prop) elements.push(<span key={i + "_prop"} className="me-1" style={{ backgroundColor: "lightyellow" }}>
                    [{item.prop || "N/A"}]
                </span>);
                else if (item.history_prop) elements.push(<span key={i + "_history"} className="me-1" style={{ backgroundColor: "lightcoral" }}>
                    [{item.history_prop || "N/A"}]
                </span>);
            }
        }
        return elements;
    }, [dissected, props.options]);
    //#endregion

    //#region Selection
    const setFormula = React.useCallback<React.Dispatch<React.SetStateAction<string>>>(changed_formula => {
        let new_formula = "", previous_formula = formula;
        // Set an new defined string as the formula
        if (typeof changed_formula === "string") new_formula = changed_formula;
        // Execute the function to obtain the new formula
        else new_formula = changed_formula(previous_formula);
        // If both formulas are different, then update
        if (new_formula !== previous_formula) onChange?.(new_formula);
    }, [onChange, formula]);

    const [show, optionsDropDown] = React.useMemo(() => {
        let opt = [];
        let item = dissected.filter(d => d.start <= cursor.start && d.end >= cursor.start)[0];

        if (item && item.type === "item") {
            let itemId = item.item;
            let showDropDown = {} as Partial<Record<"dataset" | "item" | "prop" | "meteo" | "history_prop", boolean>>;
            let option = props.options.filter(o => o.value === itemId)[0];

            // Show the item selection selection dropdown
            if (cursor.selection === "@" && !TB.mongoIdValidator(item.item)) showDropDown.item = true;
            // Show the prop selection dropdown
            else if (cursor.selection === "#" && !TB.validString(item.prop)) {
                opt = propList[option.path] || [];
                showDropDown.prop = true;
            }
            // Show the prop history selection
            else if (cursor.selection === "£" && !TB.validString(item.history_prop)) {
                opt = propList[option.path] || [];
                showDropDown.history_prop = true;
            }
            // Show the dataset selection dropdown
            else if (cursor.selection === "$" && !TB.mongoIdValidator(item.dataset)) {
                opt = (option?.datasets || []).filter(o => o.value !== props.currentDataset);
                showDropDown.dataset = true;
            }
            return [showDropDown, opt];
        }
        // A meteo station was provided, need to ask for a meteo tag
        else if (item && item.type === "station_tag") {
            // User wants to set the tag
            if (cursor.selection === "$" && !item.tag) {
                let station = item.station;
                return [{ meteo: true }, props.options.filter(o => o.value === station)[0]?.datasets || []];
            }
            else return [{}, []];
        }
        else return [{}, []];
    }, [cursor.start, cursor.selection, dissected, props.options, propList, props.currentDataset]);

    const onSelectItem = React.useCallback((option: T.Option, token: string) => {
        let newCursor = 0;
        setFormula(p => {
            let middle = option.value + token;
            let end = p.substring(cursor.end);
            let start = p.substring(0, cursor.start);
            newCursor = (start + middle).length;
            return start + middle + end;
        });

        areaRef.current?.focus?.();

        setTimeout(() => {
            if (areaRef.current) {
                areaRef.current.selectionEnd = newCursor;
                areaRef.current.scrollBy({ top: 10 });
            }
        }, 100);
    }, [cursor.end, cursor.start, setFormula]);
    //#endregion

    //#region Expansion
    const expansion = React.useMemo(() => {
        if (props.no_expand) return { style: { height: "50vh", maxHeight: "50vh", minHeight: "50vh" } };
        else return {
            style: { maxHeight: "150px" },
            button: <BS.Col md={1}>
                <C.Button
                    size="sm"
                    className="w-100 h-100"
                    icon="expand-arrows-alt"
                    onClick={() => M.renderModalFormula(props).then(f => typeof f === "string" && setFormula(f))}
                />
            </BS.Col>,
        }
    }, [setFormula, props]);
    //#endregion

    React.useEffect(() => {
        if (areaRef.current) areaRef.current.value = formula;
    }, [formula]);

    return <C.Flex direction="row" justifyContent="between">
        <BS.Row className="g-1 me-2 flex-grow-1">
            <BS.Col>
                <div className="position-relative">
                    <div className="h-100">
                        <textarea
                            ref={areaRef}
                            style={expansion.style}
                            className="form-control"
                            disabled={props.disabled}
                            onChange={e => setFormula(e.target.value)}
                        />
                    </div>

                    {show.item && <DropDown options={props.options} onSelect={opt => onSelectItem(opt, "@")} />}
                    {show.prop && <DropDown options={optionsDropDown} onSelect={opt => onSelectItem(opt, "#")} />}
                    {show.meteo && <DropDown options={optionsDropDown} onSelect={opt => onSelectItem(opt, "$")} />}
                    {show.history_prop && <DropDown options={optionsDropDown} onSelect={opt => onSelectItem(opt, "£")} />}
                    {show.dataset && <DropDown<typeof props.options[number]["datasets"][number]>
                        options={optionsDropDown}
                        onSelect={opt => onSelectItem(opt, "$")}
                        render_option={o => <C.Flex justifyContent="between">
                            <span>{o.label}</span>
                            {o.src && <span>
                                <i className={`fa fa-${DS.ICONS[o.src] || "question"}`}></i>
                            </span>}
                        </C.Flex>}
                    />}
                </div>
            </BS.Col>
            <BS.Col>
                <div className="text-start bg-light border border-2 rounded h-100 p-1 overflow-auto" style={expansion.style}>
                    {translation}
                </div>
            </BS.Col>
            {expansion.button}
        </BS.Row>
    </C.Flex>;
}

export const Formula: React.FC<FormulaProps> = props => {
    const [formProps, setFormProps, fpStatus] = H.useAsyncState<FormulaShellProps["props"]>([]);
    const [options, setOptions, optionStatus] = H.useAsyncState<FormulaShellProps["options"]>([]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getFormulaFormProps()
            .then(({ data }) => isSubscribed && setFormProps(data, 'done'))
            .catch(() => isSubscribed && setFormProps([], "error"));
        return () => {
            isSubscribed = false;
            setFormProps([], "load");
        };
    }, [setFormProps]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getFormulaItems(props.origin)
            .then(({ data }) => isSubscribed && setOptions(data, "done"))
            .catch(() => isSubscribed && setOptions([], "error"));
        return () => {
            isSubscribed = false;
            setOptions([], "load");
        };
    }, [setOptions, props.origin]);

    const loading = React.useMemo(() => fpStatus === "load" || optionStatus === "load", [fpStatus, optionStatus]);
    const error = React.useMemo(() => fpStatus === "error" || optionStatus === "error", [fpStatus, optionStatus]);

    return <C.Spinner loading={loading} error={error}>
        <FormulaShell {...props} props={formProps} options={options} />
    </C.Spinner>;
}

export default Formula;