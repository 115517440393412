import _ from "lodash";
import XLSX from "xlsx";
import moment from 'moment';
import { TB } from "../../Constants";

const SWAP_SHEET = sheet => {
    if (!TB.validObject(sheet)) return null;
    let aoa = XLSX.utils.sheet_to_json(sheet, { header: 1 });

    var nbCols = aoa.length;
    var nbRows = aoa[0].length;
    var newArray = [];
    for (let col = 0; col < nbRows; col++) {
        newArray[col] = [];
        for (let row = 0; row < nbCols; row++) newArray[col][row] = aoa[row][col];
    }

    return XLSX.utils.aoa_to_sheet(newArray, { cellDates: true })
};

export const ENTITY_FORMAT_A_TO_B = file => {
    let aoa = XLSX.utils.sheet_to_json(file, { header: 1 });
    let groupedByName = _.groupBy(aoa, ([name]) => name);
    let names = _.uniq(Object.keys(groupedByName))
    let newArray = [['time'], ...names.map(name => [name])];

    // Fill the time column
    Object.entries(groupedByName).forEach(([name, values]) => {
        values.forEach(val => {
            // Search for a time
            let time = val.filter(x => TB.validObject(x) && !isNaN(Date.parse(x)))?.[0] ?? null;
            if (time === null) return;

            // Add the time to the list
            let timeStamp = moment(time).unix() * 1000;
            newArray[0] = _.uniq(newArray[0].concat(timeStamp));
        });
    });

    // No times present in the csv
    if (newArray[0].length <= 1) return null;

    Object.entries(groupedByName).forEach(([name, array]) => {
        array.forEach(val => {
            // Search for number values, and take the first one
            let meterIndex = val.filter(x => typeof x === "number")?.[0] ?? null;
            if (meterIndex === null) return;

            // Search for the time value
            let time = val.filter(x => TB.validObject(x) && !isNaN(Date.parse(x)))?.[0] ?? null;
            if (time === null) return;
            let ts = moment(time).unix() * 1000;

            // Search for the position of the value in the new sheet
            let indexName = newArray.map((array, i) => array[0] === name ? i : -1).filter(x => x > 0)?.[0];
            let timeIndex = newArray[0].map((timeStamp, i) => timeStamp === ts ? i : -1).filter(x => x > 0)?.[0];

            if (![indexName, timeIndex].every(x => typeof x === "number")) return;

            if (!Array.isArray(newArray[indexName])) newArray[indexName] = [];
            newArray[indexName][timeIndex] = meterIndex;
        });
    });

    // Replace the timestamps with time objects
    newArray[0] = newArray[0].map(x => TB.validString(x) ? x : new Date(x));

    let formattedSheet = XLSX.utils.aoa_to_sheet(newArray, { cellDates: true });
    return SWAP_SHEET(formattedSheet);
};