import React from "react";
import * as I from "../Input";
import * as H from "../../../hooks";
import * as S from "../../../services";
import { T, TB, TC } from "../../../Constants";
import * as C from "../../../Components";
import { Flex, Spinner } from "../../Layout";
import { CellsTypes } from "../../../Components/Gestion";

export type TicketGroupProps = I.InputProps;
type TicketExtra = Record<"selected", boolean>;
type Building = ReturnType<T.API.Utils.Context.GetContextBuildings>[number];

const TicketGroup: React.FC<TicketGroupProps> = ({ onChange, ...props }) => {
    const [buildings, set_buildings, status] = H.useAsyncState<Building[]>([]);
    const [state, set_state] = React.useState<"unmounted" | "hidden" | "displayed">("unmounted");

    const value = React.useMemo<string[]>(() => props.value || [], [props.value]);
    const context = React.useMemo<T.ContextParams>(() => ({ roots: buildings.map(b => b.value) }), [buildings]);
    const isDisabled = React.useMemo(() => props.disabled || props.noOverride, [props.disabled, props.noOverride]);
    const ticket_equip = React.useMemo(() => (props.fullSubmission as T.TicketData)?.equipment, [props.fullSubmission]);

    React.useEffect(() => {
        let isSubscribed = true;
        if (TB.mongoIdValidator(ticket_equip)) S.getContextBuildings({ roots: ticket_equip })
            .then(({ data }) => isSubscribed && set_buildings(data, "done"))
            .catch(() => isSubscribed && set_buildings([], "error"));
        return () => {
            isSubscribed = false;
            set_buildings([], "load");
        }
    }, [ticket_equip, set_buildings]);

    React.useEffect(() => {
        if (!props.hidden) set_state("displayed");
        else if (state !== "unmounted") set_state("hidden");
    }, [props.hidden, state]);

    const table_props = React.useMemo<C.Table.TicketProps<TicketExtra>>(() => ({
        context,
        read_only: true,
        show_no_group: true,
        origin: "ticket_grouper_form",
        process_rows: (rows) => rows.map(row => ({ ...row, selected: props.value?.includes?.(row._id) })),
        extra_columns: [{
            pinned: "left",
            headerName: " ",
            field: "selected",
            type: CellsTypes.TYPE_ACTION_BUTTON,
            params: {
                action: row => row && onChange?.(row.selected ? value.filter(v => v !== row._id) : value.concat(row._id)),
                buttonProps: row => row.selected
                    ? { variant: "danger", size: "sm", icon: "times", text: TC.GLOBAL_DELETE, hidden: row._id === props.submissionId }
                    : { variant: "primary", size: "sm", icon: "check", text: TC.GLOBAL_SELECT, hidden: row._id === props.submissionId },
            }
        }],
    }), [props.value, props.submissionId, context, value, onChange]);

    return <I.ComponentWrapper {...props} disabled={isDisabled}>
        {state !== "unmounted" && <Spinner status={status}>
            <Flex style={{ height: state === "displayed" ? "600px" : "0px" }} children={<C.Table.Tickets<TicketExtra> {...table_props} />} />
        </Spinner>}
    </I.ComponentWrapper>;
};

export default TicketGroup;