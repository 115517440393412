// Confirmed in new System

/* ------- General ------- */
/** A dashboard for a building */
export const TAB_ID_CARD = "buildIdCard";
/** A dashboard for a portfolio */
export const TAB_PORTFOLIO = "portfolio";
/** A building's edition wizard */
export const BUILDING_EDITOR = "building_editor";
/** The Tree View page */
export const DIA_TREE = "dia_tree";

/* ------- Tech ------- */
/** A table of equipments */
export const EQUIPMENTS_TABLE = "table_equipments";
/** A table of emplacements */
export const EMPLACEMENTS_TABLE = "table_emplacements";
/** The wizard for sites editions */
export const SITE_WIZARD = "site_wizard";
/** A table for the equipments type */
export const EQUIPMENTS_TYPE_TABLE = "table_equipments_type";
/** A Table for the equipments gammes */
export const EQUIPMENTS_GAMMES_TABLE = "table_equipments_gammes";
/** A Mission's CDM's wizard */
export const MISSION_CDM_WIZARD = "mission_cdm_wizard";
/** A Mission's NRJ report wizard */
export const MISSION_NRJ_REPORT = "mission_nrj_report";
/** A Mission's Red Flag wizard */
export const MISSION_RED_FLAG_REPORT = "mission_red_flag_report";
/** A mission's EU Taxonomy Wizard */
export const MISSION_EU_TAXONOMY = "mission_eu_taxonomy";
/** A mission's RSC01 Wizard */
export const MISSION_RSC01 = "mission_RSC01";
/** A mission's EPRA Wizard */
export const MISSION_EPRA = "mission_epra";

/* ------- Reg ------- */
/** A table of reg Docs */
export const REG_DOCS_TABLE = "table_reg_docs";
/** A table of reglementations */
export const REG_ACTIONS_TABLE = "table_reg_actions";
/** Reg Wizard */
export const REG_WIZARD = "reg_wizard";

/* ------- Actions ------- */
/** The calendar page */
export const PLANNING = "planning";
/** The CAPEX Page */
export const CAPEX_TABLE = "table_capex";
/** A table of actions event */
export const ACTION_EVENT_TABLE = "table_action_event";
/** A dashboard of the action statistics */
export const ACTION_DASHBOARD = "dashboard_action";

/* ------- Energy ------- */
/** A table of energy tags */
export const NRJ_TAG_TABLE = "table_nrj_tags";
/** A table of dataset alarms */
export const NRJ_ALARM_TABLE = "table_alarm_tags";
/** A table of alerts */
export const NRJ_ALERT_TABLE = "table_alerts";
/** A environment energy dashboard */
export const ENV_DASHBOARD = "env_dashboard";

/* ------- Miscellaneous ------- */
/** The action plan simulator */
export const ACTION_SIMULATOR = "action_simulator";
/** A table of remarques */
export const REMARQUES_TABLE = "table_remarques";
/** A table of contracts */
export const CONTRACTS_TABLE = "table_contracts";
/** A table of users */
export const USERS_TABLE = "table_users";
/** A table of notes */
export const NOTES_TABLE = "table_notes";
/** An admin's table of all created missions */
export const MISSION_LOG_ADMIN = "mission_log_admin";
/** An admin's table of tags */
export const ADMIN_DATA_CORRECTION = "admin_data_correction";
/** An admin's table of system's alerts */
export const ADMIN_SYS_ALERTS = "info_bubble_table";
/** An admin's table of available misc. options */
export const ADMIN_OPTIONS_TABLE = "options_table";


// Not yet in new system or won't be at all
export const TREE = "tree";
export const FORMIO = "formio";
export const FORMS = "newFormio";
export const GESTION = "gestion";
export const REF_JOOL = "refJOOL";
export const ADMIN_TAB = "adminTab";
export const ADMIN_BOARD = "adminBoard";
export const ENTITY_EDIT = "entityEdit";
export const TAB_SITE_FORM = "siteForm";
export const EVENT_READER = "eventReader";
export const GRAPH_ENTITY = "graphEntity";
export const HOME_CONTEXT = "homeContext";
export const MANUAL_CHECK = "manualCheck";
export const GRAPH_BUILDING = "graphBuild";
export const ENTITY_TABLE = "entitiesTable";
export const GESTION_USERS = "gestionUsers";
export const TAB_TRANSLATE = "translations";
export const TICKET_CLIENT = "ticketClients";
export const TAB_REMARQUES = "TAB_REMARQUES";
export const TABLE_REGLEMENTATION = "tableReg";
export const GESTION_TICKETS = "gestionTickets";
export const GESTION_CONTRACTS = "contractsTable";
export const GESTION_EQUIPMENT = "gestionEquipment";
export const TABLE_GAMMES_EQUIP = "equipGammesTable";
export const GESTION_TECHNICIAN = "gestionTechnician";
export const GESTION_STORE_EQUIP = "gestionStoreEquip";
export const GESTION_REG_ACTIONS = "gestionRegActions";
export const GESTION_EMPLACEMENTS = "gestionEmplacements";
export const GESTION_TICKETS_DASHBOARD = "dashboardTickets";
export const GESTION_MAINTENANCE = "gestionGammeMaintenance";