import { TB, TC } from "../../../Constants";
import renderInContainer from "../getContainer";
import { Confirm, ConfirmProps } from "../Components";

export type AskConfirmModalProps = Omit<ConfirmProps, "onClose" | "onConfirm">;
type PresetConfirm = (params?: Partial<AskConfirmModalProps>) => ReturnType<typeof askConfirm>;

const askConfirm = (params?: AskConfirmModalProps, containerId?: string) => new Promise<Parameters<ConfirmProps["onValidate"]>[0] | null>(resolve => {
    let [render, dismount] = renderInContainer(containerId);
    if (!TB.validObject(params)) params = {};

    if (render && dismount) {
        let onClose = () => dismount?.(() => resolve(null));
        let onConfirm: ConfirmProps["onValidate"] = format => dismount?.(() => resolve(format));
        render(<Confirm {...params} onQuit={onClose} onValidate={onConfirm} />);
    }
    else resolve(null);
});

export const Confirms = {
    remove_file: (params => askConfirm({ ...params, text: params?.text || TC.REMOVE_FILE_MSG, title: params?.title || TC.REMOVE_FILE_TITLE })) as PresetConfirm,
    quit_no_save: (params => askConfirm({ ...params, text: params?.text || TC.QUIT_NO_SAVE_MSG, title: params?.title || TC.QUIT_NO_SAVE_TITLE })) as PresetConfirm,
}

export default askConfirm;