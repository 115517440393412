import React from "react";
import * as I from "../Input";
import * as IT from "../../Item";
import * as L from "../../Layout";
import { T, TC } from "../../../Constants";

export type MaterialProps = I.InputProps;
type Row = T.EquipmentData["materials"][number];

const UNITS: T.Option[] = [
    { label: "m²", value: "m²" },
    { label: "m³", value: "m³" },
    { label: "L", value: "L" },
    { label: "kg", value: "kg" },
]

const Materials: React.FC<MaterialProps> = props => {
    const is_disabled = React.useMemo(() => props.disabled || props.noOverride, [props.disabled, props.noOverride]);

    const columns = React.useMemo<IT.QuickInputProps2<Row>["columns"]>(() => [
        {
            type: "select",
            prop: "component",
            options: props.options,
            label: TC.MATERIAL_COMPONENTS_LABEL,
            typeahead: {
                maxResults: 20,
                renderItem: (o: T.Option<Record<"description", string>>) => {
                    return <L.Flex alignItems="center" justifyContent="between">
                        <span>
                            {o.label}
                            <IT.IconTip icon="question-circle ms-2" tipContent={o.description} />
                        </span>
                        <span className="fs-85 text-muted">{o.value}</span>
                    </L.Flex>
                },
            }
        },
        { size: 2, type: "number", prop: "quantity", label: TC.GLOBAL_QUANTITY },
        { size: 2, type: "select", options: UNITS, prop: "unit", label: TC.DATASET_UNIT },
        { size: 3, type: "text", prop: "detail", label: TC.BR_TAB_LABEL_DETAILS },
    ], [props.options]);

    return <I.ComponentWrapper {...props} disabled={is_disabled}>
        <IT.QuickInput2
            controlled
            no_init_focus
            columns={columns}
            rows={props.value}
            onSubmit={v => props.onChange?.(v)}
        />
    </I.ComponentWrapper>;
}

export default Materials;