import React from 'react'
import useEventListener from './useEventListener';
import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect';

type Positions = Omit<DOMRect, "toJSON">;
const DEFAULT: Positions = { x: 0, y: 0, top: 0, left: 0, right: 0, width: 0, bottom: 0, height: 0 };

function useElementSize<T extends HTMLElement = HTMLDivElement>(ref: T): Positions {
    // Mutable values like 'ref.current' aren't valid dependencies
    // because mutating them doesn't re-render the component.
    // Instead, we use a state as a ref to be reactive.
    const [positions, setPositions] = React.useState<Positions>(DEFAULT);

    // Prevent too many rendering using React.useCallback
    const handleSize = React.useCallback(() => {
        setPositions(ref?.getBoundingClientRect() || DEFAULT);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref?.getBoundingClientRect]);

    useEventListener("blur", handleSize);
    useEventListener("drag", handleSize);
    useEventListener("click", handleSize);
    useEventListener("close", handleSize);
    useEventListener('resize', handleSize);
    useEventListener("change", handleSize);

    useIsomorphicLayoutEffect(() => {
        handleSize()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref?.offsetHeight, ref?.offsetWidth])

    return positions;
}

export default useElementSize
