import useAuth from "./useAuth";
import useLocation from "./useLocation";
import { T, TB, LANG } from "../Constants";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { CONNECT_SOCKET, DISCONNECT_SOCKET } from "../reducers/socket";

type callbackFn = (...params: any[]) => void;
type callbacksItem = { event: string, callback: callbackFn };

type AdminCallbacks = "show_users" | "disconnect_users";
type AdminCallbacksParams = {};

const isCbItem = (cb?: any): cb is callbacksItem => TB.validObject(cb) && TB.validString(cb.event) && typeof cb.callback === "function";

const useSocket = () => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const [{ user }] = useAuth({ forcedLog: false });
    const callbackRefs = useRef<{ [key: string]: callbackFn | null }>({});
    const { socket, dataContext, language } = useSelector((redux: T.ReduxSelector) => redux);

    //#region Connect socket
    useEffect(() => {
        if (socket === null) dispatch(CONNECT_SOCKET());
    }, [socket, dispatch]);
    //#endregion

    //#region Callbacks
    const isConnected = useMemo(() => socket !== null, [socket]);
    const disconnect = useCallback(() => dispatch(DISCONNECT_SOCKET()), [dispatch]);

    const emit = useCallback((event: string, params?: any) => {
        if (socket === null || !TB.validString(event)) return null;
        return socket.emit(event, params);
    }, [socket]);

    const setCallbacks = useCallback((callbacks: callbacksItem[]) => {
        if (Array.isArray(callbacks) && socket !== null) {
            callbacks.filter(isCbItem).forEach(({ event, callback }) => {
                if (typeof callbackRefs.current[event] !== "function") {
                    socket.on(event, (...params) => callbackRefs.current[event]?.(...params));
                }
                callbackRefs.current[event] = callback;
            });
        }
    }, [socket]);

    const unsetCallbacks = useCallback((events: string[]) => {
        if (Array.isArray(events) && socket !== null) {
            events.filter(TB.validString).forEach(event => {
                socket.off(event);
                callbackRefs.current[event] = null;
            });
        }
    }, [socket]);

    const adminCallback = useCallback((event: AdminCallbacks, params?: AdminCallbacksParams) => socket !== null ? emit(event, params) : null, [socket, emit]);
    //#endregion

    //#region User info
    const validLanguage = useMemo(() => TB.getValidLanguageCode(language), [language]);
    const languageProp = useMemo(() => LANG.ALL_LANGUAGES[validLanguage].prop, [validLanguage]);

    const contextItems = useMemo(() => {
        if (TB.mongoIdValidator(dataContext.selectedPortfolio)) return { portfolio: dataContext.selectedPortfolio };
        return { items: dataContext.selectedItems };
    }, [dataContext.selectedItems, dataContext.selectedPortfolio]);

    useEffect(() => {
        let _id = user?._id;
        if (socket !== null && TB.mongoIdValidator(_id)) emit("log_user", { _id, path: pathname, language: languageProp, ...contextItems });
    }, [socket, user?._id, pathname, contextItems, languageProp, emit]);
    //#endregion

    return { isConnected, disconnect, emit, setCallbacks, adminCallback, unsetCallbacks };
}

export default useSocket;