import _ from "lodash";
import React from "react";
import Data from "./Data";
import * as F from "../../Form";
import * as M from "../../Modal";
import * as G from "../../Gestion";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as D from "../../Dashboard";
import * as BS from "react-bootstrap";
import * as S from "../../../services";
import ControlList from "./ControlList";
import { RegWizard } from "../../RegDoc";
import { T, TB, TC } from "../../../Constants";
import { useNavigate } from "react-router-dom";

//#region Types
type RemExtra = Record<"ignore", boolean>;
type MissionResumeCDM = ReturnType<T.API.Utils.Missions.MissionResumeCDM>;
//#endregion

//#region Constants
type Tabs = {
    /** The label displayed in the tab */
    label: string;
    /** The label displayed in the crumb */
    crumb: string;
    /** The key to know which tab is active */
    key: "mission" | "equip" | "rem" | "data" | "reg" | "summary";
    /** Requires an extra task to be displayed */
    task?: "reg";
}

const TABS = [
    { label: TC.CERT_HISTORY_MISSION, key: "mission", crumb: TC.CERT_HISTORY_MISSION },
    { label: TC.CDM_MISSION_EQUIP_PANEL, key: "equip", crumb: TC.CDM_MISSION_EQUIP_CRUMB },
    { label: TC.CDM_MISSION_REM_PANEL, key: "rem", crumb: TC.CDM_MISSION_REM_PANEL },
    { label: TC.CDM_MISSION_DATA_PANEL, key: "data", crumb: TC.CDM_MISSION_DATA_CRUMB },
    { label: TC.CDM_MISSION_REG_PANEL, key: "reg", crumb: TC.CDM_MISSION_REG_PANEL, task: "reg" },
    { label: TC.CDM_MISSION_SUMMARY_PANEL, key: "summary", crumb: TC.CDM_MISSION_SUMMARY_PANEL },
] as Tabs[];
//#endregion

const CDM: React.FC = () => {
    const lg = H.useLanguage();
    const navigate = useNavigate();
    const [roots, mission_id] = H.useRoots();
    const reload_datasets = H.useBoolean(true);
    const updated_mission = H.useBoolean(false);
    const ignored_rems_ids_ref = React.useRef<string[]>([]);
    const rem_ref = React.useRef<G.RemarquesTableRef<RemExtra>>(null);
    const mission_ref = React.useRef<F.Missions.MissionFormRef>(null);
    const [current_tab, set_current_tab] = React.useState<Tabs["key"]>("mission");
    const { updateCrumbs, resetCrumbs } = H.useCrumbs(TC.GLOBAL_CDM_MISSION_TITLE);
    const [loaded_tabs, set_loaded_tabs] = React.useState<Tabs["key"][]>(["mission"]);
    const [mission_state, set_mission_state, status] = H.useAsyncState<MissionResumeCDM>({ asset_id: "", lang: "en_uk", mission_type: "cdm", mission_id: "", has_reg: false, read_only: true, executive_summary: null });

    //#region Tabs & Crumbs
    React.useEffect(() => {
        let tab = TABS.filter(t => t.key === current_tab)[0];
        if (tab) updateCrumbs({ label: tab.label }, 1);
    }, [resetCrumbs, updateCrumbs, current_tab]);

    const change_tab = React.useCallback((tab: typeof current_tab) => {
        const tab_setter = (keep_mission_loaded = true) => {
            // Update the current tab
            set_current_tab(tab);
            // Update the list of loaded tab
            set_loaded_tabs(p => {
                let new_loaded = p.includes(tab) ? p : p.concat(tab);
                if (!keep_mission_loaded) return new_loaded.filter(t => t !== "mission");
                else return new_loaded;
            });
        }

        // Ask to confirm the changes made in the mission
        if (current_tab === "mission" && updated_mission.value) M.askConfirm({ title: TC.MISSION_WIZARD_AUTO_SAVE_MISSION, text: TC.MISSION_WIZARD_AUTO_SAVE_MISSION_TEXT }).then(confirmed => {
            if (confirmed) {
                let mission = mission_ref.current.mission;
                let result = mission_ref.current?.save?.();
                if (result && result !== "errors") result.then(success => {
                    if (success) {
                        tab_setter();
                        updated_mission.setFalse();
                        set_mission_state(p => ({ ...p, lang: mission?.lang || "en_uk" }));
                    }
                });
            }
            else tab_setter(false);
        });
        // Apply the 'ignore' to the remarques
        else if (tab === "rem") {
            tab_setter();
            let ignore_ids = mission_state.extra?.cdm?.ignore_rems || [];
            // Update the ref
            ignored_rems_ids_ref.current = ignore_ids;
        }
        // Update the local state when leaving the remarques tab
        else if (current_tab === "rem") {
            set_mission_state(p => ({ ...p, extra: { ...p.extra, cdm: { ...p.extra?.cdm, ignore_rems: [...ignored_rems_ids_ref.current] } } }));
            tab_setter();
        }
        else tab_setter();
    }, [set_mission_state, current_tab, updated_mission, mission_state.extra]);

    const available_tabs = React.useMemo<Tabs[]>(() => {
        if (status !== "done") return [];
        return TABS.filter(t => {
            if (t.task) {
                if (t.task === "reg") return mission_state.has_reg;
                else return false;
            }
            else return true;
        });
    }, [mission_state.has_reg, status]);

    const onSaveMission = React.useCallback<F.Missions.MissionFormProps["onSave"]>(mission => {
        if (mission !== "deleted" && mission !== "error") {
            updated_mission.setFalse();
            set_mission_state(p => ({ ...p, has_reg: mission.tasks.some(t => t.type === "reg") }));
        }
    }, [set_mission_state, updated_mission]);

    React.useEffect(() => {
        if (current_tab === "rem") {
            rem_ref.current.table.current.grid.api.forEachNode(node => {
                if (node.data) {
                    node.updateData({ ...node.data, ignore: ignored_rems_ids_ref.current.includes(node.data._id) })
                }
            });
        }
    }, [current_tab]);

    // eslint-disable-next-line react-hooks/exhaustive-deps -- Dependency is not necessary, and I don't want the warning to show up
    React.useEffect(() => updated_mission.setFalse(), [mission_id]);
    //#endregion

    //#region Load data
    React.useEffect(() => {
        let isSubscribed = true;
        S.missionResumeCDM()
            .then(({ data }) => {
                if (isSubscribed) {
                    let crumb_title = data.mission_type === "edl_tech" ? TC.MISSION_TYPES_EDL : TC.GLOBAL_CDM_MISSION_TITLE;
                    updateCrumbs({ label: crumb_title }, 0, true);
                    set_mission_state(data, "done");
                }
            })
            .catch(() => isSubscribed && set_mission_state({ asset_id: "", lang: "en_uk", mission_id: "", mission_type: "cdm", has_reg: false, read_only: true, executive_summary: null }, "error"));
        return () => {
            resetCrumbs();
            isSubscribed = false;
            set_current_tab("mission");
            set_loaded_tabs(["mission"]);
            set_mission_state({ asset_id: "", mission_id: "", lang: "en_uk", mission_type: "cdm", has_reg: false, read_only: true, executive_summary: null }, "load");
        };
    }, [set_mission_state, resetCrumbs, updateCrumbs, mission_id]);
    //#endregion

    //#region Update Mission
    const update = React.useMemo(() => ({
        structure: (structure: T.Mission["structure"]) => {
            S.saveMissionDataStructure(structure)
                .then(() => set_mission_state(p => ({ ...p, structure })))
                .catch(M.Alerts.updateError);
        },
        extra: (params: Parameters<typeof S.saveMissionExtra>[0]) => {
            let previous_extra;
            set_mission_state(p => {
                previous_extra = p.extra;
                let new_extra = { ...p.extra };
                let changes = TB.arrayWrapper(params);

                for (let change of changes)
                    new_extra = { ...new_extra, [change.type]: { ...new_extra[change.type], [change.prop]: change.value } };

                return { ...p, extra: new_extra };
            });

            S.saveMissionExtra(params)
                // .then(({ data }) => set_mission_state(p => ({ ...p, extra: data })))
                .catch(e => {
                    set_mission_state(p => ({ ...p, extra: previous_extra }));
                    M.Alerts.updateError(e);
                });
        },
    }), [set_mission_state]);
    //#endregion

    //#region Remarque Table
    const rem_table_props = React.useMemo(() => ({
        status: ["open", "memo"] as G.RemarquesTableProps<RemExtra>["status"],
        columns: [{
            pinned: "right",
            field: "ignore",
            headerName: " ",
            editable: false,
            type: G.CellsTypes.TYPE_SELECT_BUTTON,
            params: {
                invert: true,
                use_is_selected_filters_label: true,
                action: (row, node) => {
                    let is_ignored_after_change = !row.ignore;
                    // Deselect the row
                    if (ignored_rems_ids_ref.current.includes(row._id)) ignored_rems_ids_ref.current.splice(ignored_rems_ids_ref.current.indexOf(row._id), 1)
                    // Select the row
                    else ignored_rems_ids_ref.current.push(row._id);
                    // Update the row in the grid
                    node.updateData({ ...row, ignore: is_ignored_after_change });
                    // Update the change in the database
                    S.saveMissionExtra({ type: "cdm", prop: "ignore_rems", value: ignored_rems_ids_ref.current })
                        .catch(e => {
                            M.Alerts.updateError(e);
                            // Reset the state of the row in the grid
                            node.updateData({ ...row, ignore: !is_ignored_after_change });
                            // Reset the list of ignored remarques
                            if (is_ignored_after_change) ignored_rems_ids_ref.current.splice(ignored_rems_ids_ref.current.indexOf(row._id), 1);
                            else ignored_rems_ids_ref.current.push(row._id);
                        });
                },
                buttonProps: row => row && !row.ignore
                    ? { variant: "success", icon: "check-circle", size: "sm", text: TC.EPRA_DATASET_SELECTED }
                    : { variant: "primary", icon: "times-circle", size: "sm", text: TC.EPRA_DATASET_TO_SELECT },
            },
        }] as G.RemarquesTableProps<RemExtra>["extra_columns"],
        buttons: [
            {
                icon: { element: "<i class='fa fa-check me-2'></i>" },
                label: lg.getStaticText(TC.EPRA_TABLE_SELECT_DISPLAYED_ROWS),
                onClick: () => {
                    let og_list = ignored_rems_ids_ref.current;
                    // Select the filtered rows of the grid
                    rem_ref.current.table.current.grid.api.selectAllFiltered();
                    let all_current_rows = rem_ref.current.table.current.grid.api.getSelectedRows();
                    rem_ref.current.table.current.grid.api.deselectAll();
                    // Remove all those rows from the list of ignored remarques
                    let selected_ids = all_current_rows.map(r => r._id);
                    ignored_rems_ids_ref.current = ignored_rems_ids_ref.current.filter(id => !selected_ids.includes(id));
                    // Update the state of the now selected rows
                    let updated_state = all_current_rows.map(r => ({ ...r, ignore: false }));
                    // Apply the changes to the grid
                    rem_ref.current.table.current.grid.api.applyTransaction({ update: updated_state });
                    // Save the changes in the database
                    S.saveMissionExtra({ type: "cdm", prop: "ignore_rems", value: ignored_rems_ids_ref.current })
                        .catch(e => {
                            M.Alerts.updateError(e);
                            // Reset the state of the row in the grid
                            rem_ref.current.table.current.grid.api.applyTransaction({ update: all_current_rows });
                            // Reset the list of ignored remarques
                            ignored_rems_ids_ref.current = og_list;
                        });
                },
            },
            {
                icon: { element: "<i class='fa fa-times me-2'></i>" },
                label: lg.getStaticText(TC.EPRA_TABLE_UNSELECT_DISPLAYED_ROWS),
                onClick: () => {
                    let og_list = ignored_rems_ids_ref.current;
                    // Select the filtered rows of the grid
                    rem_ref.current.table.current.grid.api.selectAllFiltered();
                    let all_current_rows = rem_ref.current.table.current.grid.api.getSelectedRows();
                    rem_ref.current.table.current.grid.api.deselectAll();
                    // Add all those rows to the list of ignored remarques
                    let selected_ids = all_current_rows.map(r => r._id);
                    ignored_rems_ids_ref.current = _.uniq(ignored_rems_ids_ref.current.concat(selected_ids));
                    // Update the state of the now selected rows
                    let updated_state = all_current_rows.map(r => ({ ...r, ignore: true }));
                    // Apply the changes to the grid
                    rem_ref.current.table.current.grid.api.applyTransaction({ update: updated_state });
                    // Save the changes in the database
                    S.saveMissionExtra({ type: "cdm", prop: "ignore_rems", value: ignored_rems_ids_ref.current })
                        .catch(e => {
                            M.Alerts.updateError(e);
                            // Reset the state of the row in the grid
                            rem_ref.current.table.current.grid.api.applyTransaction({ update: all_current_rows });
                            // Reset the list of ignored remarques
                            ignored_rems_ids_ref.current = og_list;
                        });
                },
            }
        ] as G.RemarquesTableProps<RemExtra>["buttons"],
        value_change: (params => {
            if (params.colDef.field === "ignore") return "processed";
            else return "to_process";
        }) as G.RemarquesTableProps<RemExtra>["onValueChange"],
    }), [lg]);
    //#endregion

    const rich_text_summary = React.useMemo(() => {
        let db_value = mission_state.extra?.cdm?.comment;
        if (!db_value || db_value === "<p><br></p>") db_value = mission_state.executive_summary?.[mission_state.lang || "en_uk"];
        return db_value || "";
    }, [mission_state.extra, mission_state.executive_summary, mission_state.lang]);

    return <C.Flex direction="column" className="flex-grow-1">
        <C.Spinner status={status}>

            <BS.Row className="g-1">
                {available_tabs.map(tab => <BS.Col key={tab.key}>
                    <C.Button
                        size="sm"
                        text={tab.label}
                        className="w-100"
                        onClick={() => change_tab(tab.key)}
                        variant={tab.key === current_tab ? "primary" : "link"}
                    />
                </BS.Col>)}
            </BS.Row>

            {loaded_tabs.includes("mission") && <div className="flex-grow-1 my-3" hidden={current_tab !== "mission"}>
                <F.Missions.MissionForm
                    no_delete
                    ref={mission_ref}
                    navigate={navigate}
                    onSave={onSaveMission}
                    asset={mission_state.asset_id}
                    onChange={updated_mission.setTrue}
                    mission_id={mission_state.mission_id}
                />
            </div>}

            {loaded_tabs.includes("equip") && <div className="flex-grow-1 my-3" hidden={current_tab !== "equip"}>
                <ControlList read_only={mission_state.read_only} asset={mission_state.asset_id} new_datasets={reload_datasets.setTrue} />
            </div>}

            <G.Remarques<RemExtra>
                ref={rem_ref}
                context={roots}
                className="flex-grow-1 my-3"
                hidden={current_tab !== "rem"}
                status={rem_table_props.status}
                buttons={rem_table_props.buttons}
                read_only={mission_state.read_only}
                extra_columns={rem_table_props.columns}
                onValueChange={rem_table_props.value_change}
            />

            {loaded_tabs.includes("reg") && <div className="flex-grow-1 my-3" hidden={current_tab !== "reg"}>
                <RegWizard read_only={mission_state.read_only} context={roots} />
            </div>}

            {loaded_tabs.includes("data") && <div hidden={current_tab !== "data"} className="flex-grow-1 my-3">
                <Data
                    onChange={update.structure}
                    read_only={mission_state.read_only}
                    structure={mission_state.structure}
                    reload_data={reload_datasets.value}
                    on_data_reloaded={reload_datasets.setFalse}
                />
            </div>}

            {loaded_tabs.includes("summary") && <div className="flex-grow-1 my-3" hidden={current_tab !== "summary"}>
                <div className="mb-3">
                    <C.Form.RichText
                        tools="underline"
                        value={rich_text_summary}
                        disabled={mission_state.read_only}
                        label={TC.MISSION_CDM_COMMENT_GENERAL_LABEL}
                        editor_style={{ maxHeight: "500px", height: "250px" }}
                        onChange={value => update.extra({ type: "cdm", prop: "comment", value })}
                    />
                </div>
                <D.EquipmentsIndicators
                    show_extra_graph
                    root={mission_state.asset_id}
                    mission={mission_state.mission_id}
                    ignored_rems={mission_state.extra?.cdm?.ignore_rems}
                />
            </div>}

        </C.Spinner>
    </C.Flex>;
}

export default CDM;