import React from 'react';
import * as H from '../../hooks';
import * as BS from 'react-bootstrap';

type FalconCloseButtonProps = {
    variant?: string;
    size?: "sm" | "lg";
    className?: string;
    noOutline?: boolean;
    onClick?: (event: any) => void;
}

const FalconCloseButton: React.FC<FalconCloseButtonProps> = ({ size, onClick, noOutline, variant, className, ...rest }) => {
    const is_dark = H.useDark();

    const classes = React.useMemo(() => {
        let classes = [] as string[];
        if (size) classes.push("btn-" + size);
        if (className) classes.push(className);
        if (noOutline) classes.push("no-outline");
        return classes.join(" ");
    }, [size, className, noOutline]);

    return <BS.CloseButton
        {...rest}
        onClick={onClick}
        className={classes}
        variant={variant || (is_dark ? 'white' : undefined as any)}
    />
};

export default FalconCloseButton;
