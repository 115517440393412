import classNames from 'classnames';
import { Nav } from 'react-bootstrap';
import { T } from "../../../Constants";
import { Link } from 'react-router-dom';
import { AppContext } from '../../../Context';
import { useHideUnavailable } from '../../../hooks';
import { useContext, FC, useCallback, useMemo } from 'react';

type NavbarNavLinkProps = {
  title?: string;
  route?: T.RoutesType;
};

const NavbarNavLink: FC<NavbarNavLinkProps> = ({ title, route }) => {
  const hide = useHideUnavailable();
  const { config: { navbarCollapsed, showBurgerMenu }, setConfig } = useContext(AppContext);

  const handleClick = useCallback(() => {
    if (navbarCollapsed) setConfig('navbarCollapsed', !navbarCollapsed);
    if (showBurgerMenu) setConfig('showBurgerMenu', !showBurgerMenu);
  }, [navbarCollapsed, showBurgerMenu, setConfig]);

  const linkClassName = useMemo(() => classNames('fw-medium', {
    'py-1': !title,
    'text-700 mb-0 fw-bold': title,
    'link-600': !title && route?.active,
    'text-muted fs-75 not-allowed': !title && !route?.active,
  }), [route, title]);

  const to = useMemo(() => route?.active ? route.to : '#', [route?.active, route?.to]);

  if (hide && !route?.active) return null;

  /* @ts-ignore */
  return <Nav.Link as={title ? 'p' : Link} className={linkClassName} to={to} onClick={handleClick} >
    {title ? title : route.name}
  </Nav.Link>
};

export default NavbarNavLink;
