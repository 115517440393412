import { useCallback, useRef, useState } from "react";
import './Accordion.scss';

function Accordion({ header, children, color, textColor, onDoubleClick, editFunc }) {
    const [isOpen, setIsOpen] = useState(false);
    const editButtonRef = useRef();
    const timer = useRef();

    const onClickHandler = useCallback((event) => {
        clearTimeout(timer.current);
        if (event.detail === 1) timer.current = setTimeout(() => setIsOpen(!isOpen), 200)
        else if (event.detail === 2) onDoubleClick?.();
    }, [isOpen, onDoubleClick]);

    const functionSwitcher = useCallback(event => {
        if (typeof editFunc !== "function") setIsOpen(!isOpen);
        else if (editButtonRef.current?.contains?.(event?.target)) editFunc?.();
        else if (typeof onDoubleClick !== "function") setIsOpen(!isOpen);
        else onClickHandler(event);
    }, [isOpen, editFunc, onDoubleClick, onClickHandler]);

    return <div id='accordion'>
        <div onClick={typeof onDoubleClick === "function" ? e => onClickHandler(e) : functionSwitcher} className={'accordion-header_2'} style={{ 'background': color, 'color': textColor }}>
            <div className="headerTitle" title={header}>
                {header}
            </div>
            {/* accordion-options */}
            <div className={'d-flex flex-column align-items-center justify-content-evenly flex-shrink-1 p-2'}>
                {typeof editFunc === "function" && <div className="buttonDiv">
                    <i ref={editButtonRef} onClick={functionSwitcher} style={{ color: textColor }} className="fa fa-edit"></i>
                </div>}
                <div><i className={"fa fa-" + (isOpen ? "minus" : "plus")}></i></div>
            </div>
        </div>
        {isOpen &&
            <div className='accordion-body' style={{ 'borderLeftColor': color }}>
                {children}
            </div>
        }
    </div>
}

export default Accordion;