import { T, TB } from "../../Constants";
import { useLanguage } from "../../hooks";
import IconTip, { IconTipProps } from "./IconTip";
import React, { useMemo } from "react";
import { Button as B, ButtonProps as BProps, OverlayTrigger, OverlayTriggerProps, Tooltip } from "react-bootstrap";

export type ButtonProps = BProps & {
    /** Button's Icon props or just an icon class */
    icon?: string | IconTipProps;
    /** The content to display inside the button */
    children?: string | number | T.AllowArray<React.ReactElement>;
    /** Content of the tooltip */
    tip?: string | T.AllowArray<React.ReactElement>;
    /** Text content of the button */
    text?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ icon, children, tip, text, ...props }, ref) => {
    const lg = useLanguage();

    const iconTipProps = useMemo<IconTipProps>(() => typeof icon === "string" ? { icon } : icon, [icon]);

    const tipProps = useMemo(() => {
        if (!tip) return { active: false };
        return {
            active: true,
            render: (p => <Tooltip {...p} className="z-index-higher-modal">
                {typeof tip === "string" ? lg.getStaticText(tip) : tip}
            </Tooltip>) as OverlayTriggerProps["overlay"],
        }
    }, [tip, lg]);

    const button = useMemo(() => <B ref={ref} {...props} className={(props.className || "") + (props.variant === "link" ? " false-link" : "")}>
        {iconTipProps && <IconTip {...iconTipProps} className={children || text ? "me-2" : ""} />}
        {TB.validString(text)
            ? lg.getStaticText(text)
            : typeof children === "string"
                ? lg.getStaticText(children)
                : children}
    </B>, [iconTipProps, lg, children, props, ref, text]);

    if (tipProps.active) return <OverlayTrigger trigger={["hover", "focus"]} overlay={tipProps.render} children={button} />;
    return button;
});

export default Button;