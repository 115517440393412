import ReactDOM from 'react-dom';
import DefaultComp from './NoRenderComp';
import { ReactComponent } from 'react-formio';

export default class TicketGroup extends ReactComponent {
    static get builderInfo() {
        return {
            title: "TicketGroup",
            icon: "calendar",
            group: "Basic",
            documentation: "",
            weight: -10,
            schema: TicketGroup.schema()
        };
    }

    static schema = () => ReactComponent.schema({ type: "TicketGroup", label: "TicketGroup" });

    attachReact = element => ReactDOM.render(<DefaultComp />, element);
    detachReact = element => element ? ReactDOM.unmountComponentAtNode(element) : undefined;
}