import axios from "axios";
import { URL, T } from "../../Constants";

const { DATA_API, NRJ_API } = URL;

//#region Types
type GetPackages = () => T.AxiosPromise<any/* TODO */>;
type GetAllForms = () => T.AxiosPromise<T.FormReduxState>;
type GetCountries = (lang?: T.LanguageProps) => T.AxiosPromise<T.Option[]>;
type GetRegions = (country: string) => T.AxiosPromise<T.API.Geo.RegionOptions[]>;
//#endregion

type GetBrands = T.API.ToAxios<T.API.EDL.EQUIP_DATA.GetBrands>
type BrandModels = T.API.ToAxios<T.API.EDL.EQUIP_DATA.BrandModels>;
type GetResource = T.API.ToAxios<T.API.EDL.EQUIP_DATA.GetResource>;
type UpdateOption = T.API.ToAxios<T.API.EDL.EQUIP_DATA.UpdateOption>;
type CreateOption = T.API.ToAxios<T.API.EDL.EQUIP_DATA.CreateOption>;
type RemoveOption = T.API.ToAxios<T.API.EDL.EQUIP_DATA.RemoveOption>;
type GetAllOptions = T.API.ToAxios<T.API.EDL.EQUIP_DATA.GetAllOptions>;
type GetWeatherStations = T.API.ToAxios<T.API.Utils.Energy.GetWeatherStations>;

export const getAllForms: GetAllForms = () => axios.get(DATA_API + "forms");
export const getPackages: GetPackages = () => axios.get(DATA_API + "packages");
export const getCountries: GetCountries = lang => axios.get(DATA_API + "country", { params: { lang } });
export const getRegions: GetRegions = (country) => axios.get(DATA_API + "regions", { params: { country } });

/** Get all the weather stations from the database */
export const getWeatherStations: GetWeatherStations = () => axios.get(NRJ_API + "weather_stations");
/** Load translated emplacement resources */
export const getEquipmentResource: GetResource = params => axios.get(DATA_API + "equipment/" + (params?.type || "") + "?=lang" + (params?.lang || ""));
/** Load translated emplacement resources */
export const getEmplacementResource: GetResource = params => axios.get(DATA_API + "emplacement/" + (params?.type || "") + "?=lang" + (params?.lang || ""));
/** Get a list of brands */
export const modelsForBrand: BrandModels = brand => axios.get(DATA_API + "models", { params: { brand } });
/** Get a list of model available for a given brand */
export const getBrands: GetBrands = () => axios.get(DATA_API + "brands");
/** Get the complete list of options stored in db */
export const getAllOptions: GetAllOptions = () => axios.get(DATA_API + "all_options");
/** Update an option in the database */
export const updateOption: UpdateOption = params => axios.post(DATA_API + "options/edit", params);
/** Create a new option in the database */
export const createOption: CreateOption = options => axios.post(DATA_API + "options/create", { options });
/** Delete an existing option from the database */
export const removeOption: RemoveOption = params => axios.post(DATA_API + "options/delete", params);