import bCrypt from "bcryptjs";
import { validString } from "./string";

export const formioEncrypt = (text: string) => new Promise<string | null>((resolve) => {
    bCrypt.genSalt(10, (err, salt) => {
        if (err) resolve(null);
        else bCrypt.hash(text, salt, (error, hash) => {
            if (error) resolve(null);
            else resolve(hash);
        });
    })
});

export const isBCryptHash = (hash: string) => validString(hash) && Array.isArray(hash.match(/^\$2[ayb]\$.{56}$/g));