import * as T from "../Types";
import { getNumber } from "./number";

type SurfaceProps = "area" | keyof Pick<T.BuildingData,
    "surfaceHEAT"
    | "surfaceLA"
    | "surfaceTA"
    | "surfaceCA"
    | "surfaceAA"
    | "surfacePA"
    | "surfaceGFA"
    | "surfaceIFA"
    | "surfaceNFA"
    | "surfaceNRA"
    | "surfaceUTA"
    | "surfaceUCA"
    | "surfaceUAA"
    | "surfaceUPA"
    | "surfaceCold"
>;

const ordered_props: SurfaceProps[] = [
    "surfaceHEAT",
    "surfaceCold",
    "surfaceLA",
    "surfaceGFA",
    "area",
    "surfaceIFA",
    "surfaceNFA",
    "surfaceNRA",
    "surfaceTA",
    "surfaceCA",
    "surfaceAA",
    "surfacePA",
    "surfaceUTA",
    "surfaceUCA",
    "surfaceUAA",
    "surfaceUPA",
];

/**
 * Find the first valid surface, based on the EAN norm
 */
export const getHigherSurface = (obj: T.AnyObject) => find_surface(obj).value;

/** 
 * Finds the highest surface available, and the prop associated
 */
export const find_surface = (obj: Partial<Record<SurfaceProps, number>>) => {
    let values = ordered_props
        .map(p => ({ value: getNumber(obj?.[p]), prop: p }))
        .filter(v => !isNaN(v.value));

    return values[0] || { value: NaN, prop: "" };
}

/**
 * Create an address's string from a building's data
 */
export const join_address = (building: T.BuildingData) => {
    let address = "";
    if (building?.street) address = building.street;
    if (building?.number) address += (address.length === 0 ? "" : " ") + building.number;
    if (building?.town) address += (address.length === 0 ? "" : ", ") + building.town;
    if (building?.zipcode) address += (address.length === 0 ? "" : " - ") + building.zipcode;
    return address;
}

/**
 * Strip the trailing char from an omniclass to only keep the non-0 numbers
 */
export const strip_omniclass = (omniclass: string) => {
    if (!omniclass) return "";
    let stripped = omniclass;
    if (stripped.endsWith(" D")) stripped = stripped.slice(0, -2);
    if (stripped.endsWith(" 00")) stripped = stripped.slice(0, -3);
    // Repeat the previous step in case gamme is a top level one
    if (stripped.endsWith(" 00")) stripped = stripped.slice(0, -3);
    return stripped;
}