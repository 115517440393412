import "./QRCode.scss";
import { useAuth } from "../../hooks";
import { useEffect, useRef } from "react";
import { RIGHTS, TABS, TB } from "../../Constants";
import { useNavigate, Link, useParams } from 'react-router-dom';

const ScannedQRCode = ({ ...props }) => {
    //#region State
    const navigate = useNavigate();
    const { buildingId, equipmentId } = useParams();
    const [{ user, isAdmin, rights, userTabsRef }] = useAuth();

    // Link References
    const createTicketRef = useRef();
    const equipmentsGestion = useRef();
    const registerRef = useRef();
    //#endregion

    useEffect(() => user === null ? registerRef.current?.click?.() : undefined, [user]);
    useEffect(() => !TB.multiMongoIdValidator([buildingId, equipmentId]) ? navigate("/", { replace: true }) : undefined, [buildingId, equipmentId, navigate]);

    useEffect(() => {
        // Check if all data are loaded and correct
        if (TB.multiMongoIdValidator([user?._id, buildingId, equipmentId]) && [userTabsRef, rights].every(Array.isArray)) {
            // User is authorized in Gestion
            if (userTabsRef.includes(TABS.GESTION_EQUIPMENT) && rights.includes(RIGHTS.GE_CREATE_TICKET)) equipmentsGestion.current?.click?.();
            else createTicketRef.current?.click?.();
        }
    }, [user, isAdmin, buildingId, equipmentId, userTabsRef, rights]);

    return <div className="loadingQR">
        <i className="fa fa-spinner fa-pulse fa-3x"></i>

        <Link to={{ pathname: "/ticket", state: { buildingId, equipmentId } }}>
            <button ref={createTicketRef} hidden={true}></button>
        </Link>

        <Link to={{ pathname: "/equipement", state: { buildingId, equipmentId } }}>
            <button ref={equipmentsGestion} hidden={true}></button>
        </Link>

        <Link to={{ pathname: "/login" }}>
            <button ref={registerRef} hidden={true}></button>
        </Link>
    </div>
};

export default ScannedQRCode;
