import React from "react";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import { Alerts } from "../MiscModal";
import * as S from "../../../services";
import { renderLoader } from "../Loader";
import { T, TB, TC } from "../../../Constants";
import { default as Modal, BlankModalProps, StyleModalProps } from "../BlankModal";

type SaveType = Parameters<typeof S.assessCorrections>[0]["state"];
type Errors = T.Errors<Record<"upper_bound" | "first_value", string>> & Record<"to_keep" | "to_exclude", Record<"start" | "end", string>[]>;

export type DataCorrectProps = {
    /** Display in a modal ? */
    popup?: boolean;
    /** Extra params to change the modal */
    modal?: StyleModalProps;
    /** The pair station id / Tag id to correct the data from */
    pairs: T.DataSet["tag"];
    /** The id of the dataset */
    dataset_id: string;
    /** The name of the dataset */
    dataset_name: T.DataSet["name"];
    /** Callback to cancel the correction */
    on_quit?: () => void;
    /** Callback after validation or rejection of the changes */
    on_done?: (type: SaveType) => void;
};

const DataCorrect: React.FC<DataCorrectProps> = ({ on_done, on_quit, ...props }) => {
    const lg = H.useLanguage();
    const edit_parameters = H.useBoolean(false);
    const [data, set_data, status] = H.useAsyncState([], "load");
    const [to_keep, set_to_keep] = React.useState<Record<"start" | "end", string>[]>([]);
    const [to_exclude, set_to_exclude] = React.useState<Record<"start" | "end", string>[]>([]);
    const [numbers, set_numbers] = React.useState<Partial<Record<"upper_bound" | "first_value", number>>>({});
    const [errors, set_errors] = React.useState<Errors>({ first_value: "", upper_bound: "", to_keep: [], to_exclude: [] });

    const pairs = React.useMemo(() => [{ station: props.pairs.station, tag: props.pairs?.id }], [props.pairs]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.checkCorrection(pairs)
            .then(({ data }) => isSubscribed && set_data(data, "done"))
            .catch(() => isSubscribed && set_data([], "error"));
        return () => {
            isSubscribed = false;
            set_data([], "load");
        }
    }, [set_data, pairs]);

    const chart_options = React.useMemo(() => {
        let chart_options = {
            series: [],
            legend: { data: [] },
            xAxis: { type: 'time' },
            tooltip: { trigger: 'axis', axisPointer: { type: "cross" } },
            yAxis: { type: 'value', axisLabel: { formatter: '{value}' } },
            toolbox: {
                feature: {
                    restore: { show: true },
                    dataZoom: {
                        yAxisIndex: false,
                        icon: {
                            zoom: 'path://', // hack to remove zoom button
                            back: 'path://', // hack to remove restore button
                        }
                    }
                }
            }
        };

        for (let i = 0; i < data.length; i++) {
            let item = data[i];
            let name = props.dataset_name;

            let og_name = lg.getStaticText(TC.DATA_CORR_OG) + " " + name;
            let corrected_name = lg.getStaticText(TC.DATA_CORR_CORRECTION) + " " + name;
            chart_options.legend.data.push({ name: og_name }, { name: corrected_name });

            chart_options.series.push(
                {
                    smooth: true,
                    type: 'line',
                    name: og_name,
                    showSymbol: false,
                    data: item.orginal_data.map(d => [d[1], d[0]]),
                },
                {
                    smooth: true,
                    type: 'line',
                    showSymbol: false,
                    name: corrected_name,
                    lineStyle: { type: "dashed" },
                    data: item.datapoints.map(d => [d[1], d[0]]),
                },
            );
        }
        return chart_options;
    }, [props.dataset_name, data, lg]);

    const save_parameters = React.useCallback(() => {
        let newErrors = { to_keep: [], to_exclude: [] } as typeof errors;
        // Check the dates
        for (let prop of ["to_keep", "to_exclude"] as const) {
            let dates = prop === "to_keep" ? to_keep : to_exclude;
            for (let d of dates) {
                let to = TB.getDate(d.end);
                let from = TB.getDate(d.start);
                let error = { start: "", end: "" };

                if (to === null) error.end = TC.GLOBAL_REQUIRED_FIELD;
                if (from === null) error.start = TC.GLOBAL_REQUIRED_FIELD;
                if (from && to && from.getTime() >= to.getTime()) {
                    error.end = TC.ERR_DATE_TO_LOWER_DATE_FROM;
                    error.start = TC.ERR_DATE_FROM_HIGHER_DATE_TO;
                }
                newErrors[prop].push(error);
            }
        }

        if (typeof numbers.upper_bound === "number") {
            if (isNaN(numbers.upper_bound)) newErrors.upper_bound = TC.GLOBAL_REQUIRED_FIELD;
            else if (numbers.upper_bound < 0) newErrors.upper_bound = TC.GLOBAL_NUMBER_MUST_BE_POSITIVE;
        }

        let has_date_errors = newErrors.to_keep.some(e => e.start || e.end) || newErrors.to_exclude.some(e => e.start || e.end);

        if (has_date_errors || newErrors.first_value || newErrors.upper_bound) set_errors(newErrors);
        else {
            let unmount = renderLoader();
            S.assessCorrections({ state: "accept", dataset: props.dataset_id, pairs, to_exclude, to_keep, numbers }).then(({ data }) => {
                set_data(data || [], "done");
                edit_parameters.setFalse();
            })
                .catch(Alerts.loadError)
                .finally(unmount);
        }
    }, [to_exclude, to_keep, numbers, pairs, edit_parameters, set_data, props.dataset_id]);

    const render_date_picker = React.useCallback((is_to_keep = false) => {
        let dates = is_to_keep ? to_keep : to_exclude;
        let set_dates = is_to_keep ? set_to_keep : set_to_exclude;
        let errors_code = is_to_keep ? errors.to_keep : errors.to_exclude;
        let title = is_to_keep ? TC.DATA_CORRECT_DATE_TO_KEEP : TC.DATA_CORRECT_DATE_TO_EXCLUDE;
        let add_text = is_to_keep ? TC.DATA_CORRECT_DATES_TO_KEEP : TC.DATA_CORRECT_DATES_EXCLUDE;
        let tip = is_to_keep ? TC.DATA_CORRECT_DATE_TO_KEEP_TIP : TC.DATA_CORRECT_DATE_TO_EXCLUDE_TIP;

        return <>
            <span>
                <C.Title text={title} />
                <C.IconTip tipContent={tip} icon="question-circle" className="ms-2" />
            </span>
            {dates.length === 0
                ? <C.Button
                    icon="plus"
                    className="w-100 my-2"
                    text={add_text}
                    onClick={() => set_dates([{ start: "", end: "" }])}
                />
                : dates.map((date, i) => <BS.Row className="g-1 mb-1" key={i}>
                    <BS.Col md={5}>
                        <C.Form.DateTime
                            enableTime
                            noBottomMargin
                            value={date.start}
                            error={errors_code[i]?.start}
                            onChange={v => set_dates(p => p.map((d, j) => j === i ? { ...d, start: v } : d))}
                        />
                    </BS.Col>
                    <BS.Col md={5}>
                        <C.Form.DateTime
                            enableTime
                            noBottomMargin
                            value={date.end}
                            error={errors_code[i]?.end}
                            onChange={v => set_dates(p => p.map((d, j) => j === i ? { ...d, end: v } : d))}
                        />
                    </BS.Col>
                    <BS.Col md={2}>
                        <C.Flex className="w-100" alignItems="center">
                            <C.Button
                                size="sm"
                                icon="plus"
                                className="w-100 me-1"
                                onClick={() => set_dates(p => p.concat({ start: "", end: "" }))}
                            />
                            <C.Button
                                size="sm"
                                variant="danger"
                                icon="trash-alt"
                                className="w-100"
                                onClick={() => set_dates(p => p.filter((_, j) => j !== i))}
                            />
                        </C.Flex>
                    </BS.Col>
                </BS.Row>)}
        </>;
    }, [to_keep, to_exclude, errors]);

    const footer = React.useMemo(() => <C.Flex alignItems="end">
        <C.Button
            icon="pencil-alt"
            variant="secondary"
            onClick={edit_parameters.setTrue}
            text={TC.DATA_CORRECT_EDIT_PARAMS}
        />
        <C.Button
            icon="check"
            className="ms-2"
            variant="primary"
            onClick={() => on_done?.("accept")}
            text={TC.DATA_CORR_ACCEPT_CHANGES}
        />
        <C.Button
            icon="times"
            className="ms-2"
            variant="danger"
            onClick={() => on_done?.("reject")}
            text={TC.DATA_CORR_REJECT_CHANGES}
        />
    </C.Flex>, [on_done, edit_parameters]);

    return React.createElement(
        props.popup ? Modal : React.Fragment,
        props.popup ? {
            ...props.modal,
            footer,
            onQuit: on_quit,
            title: props.modal?.title || TC.DATA_CORR_TITLE,
            isFullScreen: typeof props.modal?.isFullScreen === "boolean" ? props.modal.isFullScreen : true,
        } as BlankModalProps : null,
        <C.Spinner status={status}>

            {edit_parameters.value && <Modal
                size="sm"
                onQuit={edit_parameters.setFalse}
                title={TC.DATA_CORRECT_EDIT_PARAMS}
                footer={<C.Button text={TC.GLOBAL_CONFIRM} onClick={save_parameters} />}
            >

                <BS.Row className="g-1">
                    <BS.Col>
                        <C.Form.NumField
                            placeholder="500"
                            error={errors.upper_bound}
                            value={numbers.upper_bound}
                            label={TC.DATA_CORRECT_UPPER_BOUND}
                            onChange={v => set_numbers(p => ({ ...p, upper_bound: v }))}
                        />
                    </BS.Col>
                    <BS.Col>
                        <C.Form.NumField
                            error={errors.first_value}
                            value={numbers.first_value}
                            label={TC.DATA_CORRECT_FIRST_VALUE}
                            onChange={v => set_numbers(p => ({ ...p, first_value: v }))}
                        />
                    </BS.Col>
                </BS.Row>

                {render_date_picker(true)}
                {render_date_picker(false)}
            </Modal>}

            <C.Echarts
                className="h-100"
                option={chart_options}
                ref={ref => {
                    let chart = ref?.getEchartsInstance?.();
                    chart?.dispatchAction?.({
                        type: 'takeGlobalCursor',
                        key: 'dataZoomSelect',
                        dataZoomSelectActive: true,
                    });
                    chart?.on?.("restore", () => {
                        chart?.dispatchAction?.({
                            type: 'takeGlobalCursor',
                            key: 'dataZoomSelect',
                            dataZoomSelectActive: true,
                        });
                        chart?.setOption?.(chart_options);
                    });
                    chart?.on?.('click', (params: any) => {
                        if (params.componentType === 'markArea') chart?.dispatchAction?.({
                            type: 'dataZoom',
                            dataZoomIndex: 0,
                            endValue: params.data.coord[1][0],
                            startValue: params.data.coord[0][0],
                        })
                    });
                }}
            />

            {!props.popup && footer}
        </C.Spinner>
    );
}

export default DataCorrect;