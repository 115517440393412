import * as M from "../BlankModal";
import * as D from "../../Dashboard";
import { TC } from "../../../Constants";
import renderInContainer from "../getContainer";

export type RenderAlertDashboards = (params: D.AlertDashboardsProps & M.StyleModalProps) => Promise<void>;

/** 
 * Render a modal containing the alert Dashboard
 */
export const renderAlertDashboards: RenderAlertDashboards = params => new Promise(resolve => {
    const [render, dismount] = renderInContainer();
    if (render && dismount) {
        const { datasets, ...props } = params || { datasets: [] };

        render(<M.default
            {...props}
            onQuit={() => dismount(() => resolve(null))}
            title={props?.title || TC.DASHBOARD_MODAL_DATASETS}
            isFullScreen={typeof props?.isFullScreen === "boolean" ? props.isFullScreen : true}
        >
            <D.AlertDashboards datasets={datasets} />
        </M.default>);
    }
    else resolve(null);
});