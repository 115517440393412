import _ from "lodash";
import React from "react";
import * as H from "../../hooks";
import * as C from "../../Common";
import * as S from "../../services";
import * as BS from "react-bootstrap";
import { FP, T, TC } from "../../Constants";
import ClientTable, { ClientTableRef } from "./ClientTable";
import SiteTable, { SiteTableRef, SiteTableProps } from "./SiteTable";
import BuildTable, { BuildingTableRef, BuildTableProps } from "./BuildTable";

type Panel = "client" | "site" | "building";
type Site_Row = ReturnType<T.API.Utils.Navigator.NavTableSites>[number];
type Client_Row = ReturnType<T.API.Utils.Navigator.NavTableClients>[number];
type Building_Row = ReturnType<T.API.Utils.Navigator.NavTableBuildings>[number];

export type SearchContextProps = {
    /** Will propose a column for portfolio selection */
    portfolio?: boolean;
    /** Currently Selected ids  */
    selected?: Record<Panel, string[]>;
    /** Only offer one type of item */
    searchedType?: Panel;
    /** Callback for an item's selection */
    onSelect?: (id: string, name: string, type?: Panel, site?: string) => void;
    /** Callback for multiple item's selection */
    select_many?: (items: Record<"id" | "name", string>[], type: Panel) => void;
}

const SearchContext: React.FC<SearchContextProps> = ({ select_many, ...props }) => {
    const site_ref = React.useRef<SiteTableRef>(null);
    const client_ref = React.useRef<ClientTableRef>(null);
    const building_ref = React.useRef<BuildingTableRef>(null);

    const [heavy_loaded, set_heavy_loaded] = React.useState<Panel[]>([]);
    const [sites, setSites, site_status] = H.useAsyncState<Site_Row[], "heavy">([]);
    const [panel, setPanel] = React.useState<Panel>(props?.searchedType || "site");
    const [clients, setClients, client_status] = H.useAsyncState<Client_Row[], "heavy">([]);
    const [buildings, setBuildings, building_status] = H.useAsyncState<Building_Row[], "heavy">([]);

    //#region Load data
    const loaded = React.useMemo<Record<Panel, boolean>>(() => ({
        site: site_status === "done" || site_status === "heavy",
        client: client_status === "done" || client_status === "heavy",
        building: building_status === "done" || building_status === "heavy",
    }), [site_status, client_status, building_status]);

    const load_heavy = React.useCallback((load_panel = panel) => {
        if (load_panel === "building") setBuildings([], "heavy");
        else if (load_panel === "client") setClients([], "heavy");
        else if (load_panel === "site") setSites([], "heavy");

        let promise: Promise<any>;
        if (load_panel === "client") promise = S.navTableClients(false)
            .then(({ data }) => setClients(data, "done"))
            .catch(() => setClients([], "error"));
        else if (load_panel === "site") promise = S.navTableSites(false)
            .then(({ data }) => setSites(data, "done"))
            .catch(() => setSites([], "error"));
        else if (load_panel === "building") promise = S.navTableBuildings(false)
            .then(({ data }) => setBuildings(data, "done"))
            .catch(() => setBuildings([], "error"));

        promise.then(() => set_heavy_loaded(p => p.concat(load_panel)));
    }, [setBuildings, setClients, setSites, panel]);

    React.useEffect(() => {
        let isSubscribed = true;
        if (panel === "client" && !loaded.client) S.navTableClients(true)
            .then(({ data }) => isSubscribed && setClients(data, "done"))
            .catch(() => isSubscribed && setClients([], "error"));
        return () => { isSubscribed = false };
    }, [panel, loaded.client, setClients]);

    React.useEffect(() => {
        let isSubscribed = true;
        if (panel === "site" && !loaded.site) S.navTableSites(true)
            .then(({ data }) => isSubscribed && setSites(data, "done"))
            .catch(() => isSubscribed && setSites([], "error"));
        return () => { isSubscribed = false };
    }, [panel, loaded.site, setSites]);

    React.useEffect(() => {
        let isSubscribed = true;
        if (panel === "building" && !loaded.building) S.navTableBuildings(true)
            .then(({ data }) => isSubscribed && setBuildings(data, "done"))
            .catch(() => isSubscribed && setBuildings([], "error"));
        return () => { isSubscribed = false };
    }, [panel, loaded.building, setBuildings]);
    //#endregion

    //#region Disabled Type Switching
    const disabled = React.useMemo<Record<Panel, boolean>>(() => {
        if (!props.portfolio || !props.selected) return { client: false, building: false, site: false };
        else if (props.selected?.site?.length > 0 || props.searchedType === "site") return { client: true, building: true, site: false };
        else if (props.selected?.client?.length > 0 || props.searchedType === "client") return { client: false, building: true, site: true };
        else if (props.selected?.building?.length > 0 || props.searchedType === "building") return { client: true, building: false, site: true };
        else return { client: false, building: false, site: false };
    }, [props.portfolio, props.selected, props.searchedType]);
    //#endregion

    //#region Selection & Updates
    const select_all = React.useCallback((is_unselect = false) => {
        let ids = [] as string[];
        let selection = [] as typeof props.selected[Panel];
        let list = [] as (Site_Row | Client_Row | Building_Row)[];

        if (panel === "building") {
            list = buildings;
            ids = building_ref.current?.select_all?.() || [];
        }
        else if (panel === "client") {
            list = clients;
            ids = client_ref.current?.select_all?.() || [];
        }
        else if (panel === "site") {
            list = sites;
            ids = site_ref.current?.select_all?.() || [];
        }

        if (is_unselect) selection = props.selected[panel].filter(id => !ids.includes(id));
        else selection = _.uniq(props.selected[panel].concat(ids.filter(id => !props.selected[panel].includes(id))));

        let items = list.filter(r => selection.includes(r.id)).map(r => ({ id: r.id, name: r.name }));
        select_many?.(items, panel);
    }, [panel, buildings, sites, clients, props.selected, select_many]);

    const updates = React.useMemo(() => ({
        building: ((id, changes) => {
            setBuildings(p => p.map(b => b.id === id ? { ...b, ...changes } : b));
        }) as BuildTableProps["row_update"],
        site: ((id, changes) => {
            setSites(p => p.map(b => b.id === id ? { ...b, ...changes } : b));
        }) as SiteTableProps["row_update"],
    }), [setBuildings, setSites]);
    //#endregion

    return <C.Flex direction="column" className="w-100 h-100">
        <C.Flex justifyContent={props.portfolio ? "between" : "end"} alignItems="center">
            {props.portfolio && <BS.ButtonGroup>
                <C.Button
                    size="sm"
                    icon="check-double"
                    onClick={() => select_all()}
                    text={TC.NAV_TABLE_SELECT_ALL}
                />
                <C.Button
                    size="sm"
                    icon="times"
                    variant="danger"
                    onClick={() => select_all(true)}
                    text={TC.NAV_TABLE_UNSELECT_ALL}
                />
            </BS.ButtonGroup>}
            <BS.ToggleButtonGroup size="sm" type="radio" name="cat_type" value={panel} onChange={setPanel}>
                <BS.ToggleButton disabled={disabled.client} variant="outline-secondary" id="client" value="client">
                    <C.IconTip icon="user" tipContent={FP.CLIENT_FORM} />
                </BS.ToggleButton>
                <BS.ToggleButton disabled={disabled.site} variant="outline-secondary" id="site" value="site">
                    <C.IconTip icon="city" tipContent={FP.SITE_FORM} />
                </BS.ToggleButton>
                <BS.ToggleButton disabled={disabled.building} variant="outline-secondary" id="building" value="building">
                    <C.IconTip icon="building" tipContent={FP.BUILDING_FORM} />
                </BS.ToggleButton>
            </BS.ToggleButtonGroup>
        </C.Flex >

        {panel === "site" && <SiteTable
            rows={sites}
            ref={site_ref}
            row_update={updates.site}
            selected={props.selected?.site}
            load_heavy={() => load_heavy("site")}
            is_heavy={heavy_loaded.includes("site")}
            selection={props.portfolio ? "checkbox" : "button"}
            status={site_status === "heavy" ? "load" : site_status}
            onSelect={id => props.onSelect?.(id, sites.filter(c => c.id === id)[0]?.name || "", "site")}
        />}

        {panel === "client" && <ClientTable
            rows={clients}
            ref={client_ref}
            selected={props.selected?.client}
            load_heavy={() => load_heavy("client")}
            is_heavy={heavy_loaded.includes("client")}
            selection={props.portfolio ? "checkbox" : "button"}
            status={client_status === "heavy" ? "load" : client_status}
            onSelect={id => props.onSelect?.(id, clients.filter(c => c.id === id)[0]?.name || "", "client")}
        />}

        {panel === "building" && <BuildTable
            rows={buildings}
            ref={building_ref}
            row_update={updates.building}
            selected={props.selected?.building}
            load_heavy={() => load_heavy("building")}
            is_heavy={heavy_loaded.includes("building")}
            selection={props.portfolio ? "checkbox" : "button"}
            status={building_status === "heavy" ? "load" : building_status}
            onSelect={id => {
                let row = buildings.filter(c => c.id === id)[0];
                props.onSelect?.(id, row?.name || "", "building", row?.sites_names.join());
            }}
        />}

    </C.Flex >;
}

export const SearchContextManyBuffer: React.FC<SearchContextProps> = props => {
    const [selection, set_selection] = React.useState<Parameters<SearchContextProps["select_many"]>[0]>([]);
    const [panel, set_panel] = React.useState<Parameters<SearchContextProps["select_many"]>[1]>(props.searchedType || "site");

    const selected = React.useMemo<SearchContextProps["selected"]>(() => ({
        site: [],
        client: [],
        building: [],
        [panel]: selection.map(s => s.id)
    }), [panel, selection]);

    const select = React.useMemo(() => ({
        single: ((id, name, panel) => {
            set_panel(panel);
            set_selection(p => {
                if (p.some(v => v.id === id)) return p.filter(v => v.id !== id);
                else return p.concat({ id, name });
            });
        }) as SearchContextProps["onSelect"],
        multiple: ((items, panel) => {
            set_panel(panel);
            set_selection(items);
        }) as SearchContextProps["select_many"],
    }), []);

    return <C.Flex direction="column" className="h-100">
        <div className="flex-grow-1">
            <SearchContext
                {...props}
                portfolio
                selected={selected}
                onSelect={select.single}
                select_many={select.multiple}
            />
        </div>
        <C.Flex justifyContent="end">
            <C.Button text={TC.GLOBAL_CONFIRM} onClick={() => props.select_many?.(selection, panel)} />
        </C.Flex>
    </C.Flex>
}

export default SearchContext;