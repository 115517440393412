import { TB, TC } from "../../Constants";
import * as M from "../Modal";
import { useLanguage } from "../../hooks";
import { useState, useMemo, useCallback } from 'react';

const ImgUploader = ({ multiple = false, setBase64, classContainer = "", existingValues = "", sendFormioInfos = false, id = "", maxSize = "", ...props }) => {
    const [hoveredIndex, setHoveredIndex] = useState();
    const [filesTooLarge, setIsFileTooLarge] = useState(false);
    const { getStaticText } = useLanguage([TC.SCH_FILE_TOO_LARGE]);

    //#region Max Size
    const sizeMaxAdjusted = useMemo(() => typeof maxSize === "number" ? maxSize * 1000 : null, [maxSize]);
    //#endregion

    //#region On Change
    const onChange = useCallback(e => {
        const getBase64 = (file) => new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (!sendFormioInfos) resolve(reader.result);
                else resolve({
                    storage: "base64",
                    name: file.name,
                    url: reader.result,
                    size: file.size,
                    type: file.type,
                    originalName: file.name,
                });
            }
            reader.onerror = (err) => reject(err);
        });

        const getFiles = async files => {
            let arrayBase64 = [];
            for (let i = 0; i < files.length; i++) arrayBase64.push(getBase64(files[i]));
            let temp = await Promise.all(arrayBase64);
            return temp;
        }

        let files = e.target.files;
        if (TB.validObject(files)) {
            if (sizeMaxAdjusted === null || Object.values(files).every(({ size }) => size <= sizeMaxAdjusted)) getFiles(files).then(base64s => setBase64?.(base64s)).finally(() => setIsFileTooLarge(false));
            else setIsFileTooLarge(true);
        }
    }, [sendFormioInfos, setBase64, sizeMaxAdjusted]);
    //#endregion

    //#region Existing Values
    const values = useMemo(() => (Array.isArray(existingValues) ? existingValues : [existingValues]).filter((sendFormioInfos ? TB.validObject : TB.validString)), [existingValues, sendFormioInfos]);
    const formattedValues = useMemo(() => values.map(img => ({ src: img?.url ?? img, title: img?.name ?? undefined })), [values]);
    const remove = useCallback(index => setBase64?.(values.filter((x, i) => i !== index)), [values, setBase64]);
    const onShowBigger = useCallback(src => M.renderCarousel({ defaultSrc: src, images: formattedValues }), [formattedValues]);

    const pictureShow = useCallback((img, i) => <div className='position-relative' key={i} style={{ maxWidth: "10rem" }} onMouseEnter={() => setHoveredIndex(i)} onMouseLeave={() => setHoveredIndex()}>
        <img className='w-100 h-100' src={img?.url ?? img} alt={img?.name ?? i} title={img?.name ?? i} style={{ maxHeight: "15rem" }} />
        {hoveredIndex === i && <div className='fade-in-50 pointer d-flex align-items-center justify-content-center position-absolute top-0 start-0 w-100 h-100 opacity-50 bg-dark text-light' onClick={() => onShowBigger(img?.src ?? img)}>
            <i className='fa fa-search-plus fa-2x'></i>
        </div>}
        <button onClick={() => remove(i)} className='btn btn-danger rounded-circle position-absolute top-0 start-100 translate-middle'>
            <i className='fa fa-times'></i>
        </button>
    </div>, [hoveredIndex, remove, onShowBigger]);

    const valuesShow = useMemo(() => <div className='my-1'>
        {values.map(pictureShow)}
    </div>, [values, pictureShow]);
    //#endregion

    const inputId = useMemo(() => TB.validString(id) ? id : undefined, [id]);
    const wrapperClassName = useMemo(() => "d-flex align-items-center" + (TB.validString(classContainer) ? classContainer : ""), [classContainer]);

    return <div className={wrapperClassName}>
        <div className='w-25'>
            <input id={inputId} type="file" accept='image/*' multiple={multiple} onChange={onChange} />
            {filesTooLarge && <p className='text-danger'>{getStaticText(TC.SCH_FILE_TOO_LARGE)}</p>}
        </div>
        <div className='flex-grow-1 mx-2'>
            {valuesShow}
        </div>
    </div>
}

export default ImgUploader;