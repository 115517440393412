import React from "react";
import * as H from "../../hooks";

export type TitleProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> & {
    /** Which level of title to display */
    level?: number;
    /** The content to display in the title tag */
    children?: string | React.ReactNode | React.ReactNode[];
    /** A shorthand prop to specify the text to display in the title tag */
    text?: string;
    /** The name of an icon to display before the title */
    icon?: string;
}

const Title: React.FC<TitleProps> = ({ level, text, children, ...props }) => {
    const lg = H.useLanguage();

    const content = React.useMemo(() => {
        let content_span = null;
        if (typeof text === "string") content_span = lg.getStaticText(text);
        else if (typeof children === "string") content_span = lg.getStaticText(children);
        else content_span = children || null;

        if (props.icon) return <span><i className={`me-2 fa fa-${props.icon}`} />{content_span}</span>;
        else return content_span;
    }, [text, children, props.icon, lg]);

    const html_tag = React.useMemo(() => {
        if (level === 1) return "h1";
        else if (level === 2) return "h2";
        else if (level === 3) return "h3";
        else if (level === 4) return "h4";
        else if (level === 5) return "h5";
        else if (level === 6) return "h6";
        else return "h3";
    }, [level]);

    return React.createElement(html_tag, props, content);
}

export default Title;