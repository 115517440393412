import React from "react";
import { Table } from "react-bootstrap";
import { T, TB } from "../../../Constants";
import { InputProps, Input, ComponentWrapper } from "../Input";

export type TableProps = InputProps & {
    hover?: boolean;
    striped?: boolean;
    bordered?: boolean;
    rows?: T.FormRows[];
    condensed?: boolean;
    cellAlignment?: "left" | "center" | "right";
}

const TableForm: React.FC<TableProps> = ({ rows, hover, striped, onChange, bordered, condensed, cellAlignment, ...props }) => {

    const size = React.useMemo(() => condensed ? "sm" : "md", [condensed]);
    const vRows = React.useMemo<T.FormRows[]>(() => TB.getArray(rows).filter(r => Array.isArray(r)).map(r => r.filter(c => Array.isArray(c.components))), [rows]);

    const alignment = React.useMemo(() => {
        if (cellAlignment === "right") return "text-end";
        if (cellAlignment === "center") return 'text-center';
        return "text-start";
    }, [cellAlignment]);

    return <ComponentWrapper {...props} label={null}>
        <Table className={alignment} striped={striped} bordered={bordered} hover={hover} size={size} >
            <tbody>
                {vRows.map((r, i) => <tr key={i}>
                    {r.map((cell, i) => <td key={i}>
                        {cell.components.map(c => <Input
                            {...c}
                            prop={c.key}
                            formId={props.formId}
                            lock_form={props.lock_form}
                            contextKey={props.contextKey}
                            unlock_form={props.unlock_form}
                            submissionId={props.submissionId}
                            onAddCertification={props.onAddCertification}
                            onChange={(v, p) => onChange?.(v, p || c.key)}
                        />)}
                    </td>)}
                </tr>)}
            </tbody>
        </Table>
    </ComponentWrapper>
}

export default TableForm;