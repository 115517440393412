import { T, LANG, TB } from '../Constants';

//#region Utils
const isLangProp = (prop: any): prop is T.LanguageProps => LANG.ALL_PROPS.includes(prop);

const langNumToProp = (lg: number): T.LanguageProps => {
    let lang = LANG.ALL_LANGUAGES.filter(l => l.index === lg)[0];
    return isLangProp(lang?.prop) ? lang.prop : LANG.EN_PROP;
}

const langPropToNum = (lg: T.LanguageProps) => {
    let lang = LANG.ALL_LANGUAGES.filter(l => l.prop === lg)[0];
    return typeof lang?.index === "number" ? lang.index : LANG.EN;
}
//#endregion

//#region Callbacks
const onChangeLanguage = (oldLg: number, newLg?: number) => {
    if (TB.getValidLanguageCode(newLg) !== newLg) return oldLg;
    localStorage.setItem("lang", langNumToProp(newLg));
    document.documentElement.setAttribute("lang", LANG.getHtmlLanguageCode(newLg));
    return newLg;
}

const getInitialLang = () => {
    let prop = localStorage.getItem("lang");
    if (isLangProp(prop)) return langPropToNum(prop);
    else {
        localStorage.setItem("lang", LANG.FR_PROP);
        return LANG.FR;
    }
}
//#endregion

const languageRedux: T.ReduxFn<number, T.LanguageActions, number> = (state = getInitialLang(), payload) => {
    const { type, action } = TB.getObject(payload);

    if (type === "CHANGE_LANG") {
        // Notify "languageLoad" to load other language's translations
        // payload?.asyncDispatch({ type: "LOAD_OTHER_LANG", action: langNumToProp(action) })
        return onChangeLanguage(state, action);
    }
    else return state;
}

export default languageRedux;

export const setLanguage: T.ReduxDispatch<T.LanguageActions, number> = lang => ({ type: "CHANGE_LANG", action: lang });