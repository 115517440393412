import React from "react";
import { ErrorBanner } from "../../Item";
import { Col, Row } from "react-bootstrap";
import { T, TB, TC } from "../../../Constants";
import { ComponentWrapper, InputProps, Input } from "../Input";

export type ColumnsProps = InputProps & {
    columns?: T.FormColumns[];
}

const Columns: React.FC<ColumnsProps> = ({ columns, onChange, ...props }) => {
    const emptyComponents = React.useMemo(() => <ErrorBanner canHide type="info" textCode={TC.FR_TAB_NO_COMPONENTS} />, []);

    const cleanColumns = React.useMemo(() => TB.getArray(columns).map(col => ({
        components: TB.getArray(col.components),
        size: {
            [col.size || "md"]: TB.getNumberRange(col.width, { max: 12, min: 1, default: 6 })
        },
    })), [columns]);

    return <ComponentWrapper {...props} label={null}>
        <Row className="g-3">
            {cleanColumns.map((col, i) => <Col key={i} {...col.size}>
                {col.components.map((c, i, a) => <Input
                    {...c}
                    prop={c.key}
                    formId={props.formId}
                    lock_form={props.lock_form}
                    contextKey={props.contextKey}
                    unlock_form={props.unlock_form}
                    submissionId={props.submissionId}
                    noBottomMargin={i === a.length - 1}
                    onAddCertification={props.onAddCertification}
                    onChange={(v, p) => onChange?.(v, p || c.key)}
                />)}
            </Col>)}

            {cleanColumns.length === 0 && emptyComponents}
        </Row>
    </ComponentWrapper>
}

export default Columns;