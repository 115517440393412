import { TB } from "../../../Constants";
import { MultiSelect, MultiSelectProps } from "../Components";
import renderInContainer from "../getContainer";

export type AskMultiSelectModalProps = Omit<MultiSelectProps, "onClose" | "onConfirm">

const askMultiSelect = (params?: AskMultiSelectModalProps) => new Promise<Parameters<MultiSelectProps["onConfirm"]>[0]>(resolve => {
    let [render, dismount] = renderInContainer();
    if (!TB.validObject(params)) params = { options: [] };

    if (render && dismount) {
        let onClose = () => dismount?.(() => resolve(null));
        let onConfirm: MultiSelectProps["onConfirm"] = value => dismount?.(() => resolve(value));
        render(<MultiSelect {...params} onClose={onClose} onConfirm={onConfirm} />);
    }
});

export default askMultiSelect;