export const USER_ROLE_C_LEVEL = "cLevel";
export const USER_ROLE_TECH = "technician";
export const USER_ROLE_ANONYMOUS = "anonymous";
export const USER_ROLE_ADMIN = "administrator";
export const USER_ROLE_CLIENT_ADMIN = "clientAdmin";
export const USER_ROLE_AUTHENTICATED = "authenticated";
export const USER_ROLE_TEAM_LEADER = "technicalDirector";

export const ALL_ROLES = [
    USER_ROLE_TECH,
    USER_ROLE_ADMIN,
    USER_ROLE_C_LEVEL,
    USER_ROLE_ANONYMOUS,
    USER_ROLE_TEAM_LEADER,
    USER_ROLE_CLIENT_ADMIN,
    USER_ROLE_AUTHENTICATED,
]