import jwt from "jsonwebtoken";
import { validString } from "./string";
import { validObject } from "./objects";

/** Decodes an AG-Grid API key token */
export const decode_grid_token = (token: string) => new Promise<string>((resolve, reject) => {
    jwt.verify(token, "atm", (error, decoded) => {
        if (error) reject(error);
        else if (!validObject(decoded)) reject("Empty token");
        else if (validString(decoded?.key)) resolve(decoded.key);
        else reject("Invalid Token");
    });
});