import { Flex } from "../../Common";
import { useLanguage } from "../../hooks";
import {TC, FP, TB} from "../../Constants";
import * as US from "../../services/user.service";
import React, { FC, useCallback, useMemo } from "react";
import { askPrompt, askSelect, renderAlert } from "../Modal";
import { Accordion, Button, Col, Row } from "react-bootstrap";

export type MatrixOption = {
    _id: string;
    form: string;
    props: string[];
}

type MatrixSelectorProps = {
    options: MatrixOption[];
    onSelectProp?: (form: string, prop: string) => void;
    onChangeOptions?: (updateFn: (options: MatrixOption[]) => MatrixOption[]) => void;
}

const TEXT_CODES = [TC.MATRIX_ADD_PROP, TC.MATRIX_ADD_RESOURCE, TC.GLOBAL_ERROR_UPDATE, TC.MATRIX_NO_PROP_DEFINED, TC.MATRIX_PROP_EXISTS];

const MatrixSelector: FC<MatrixSelectorProps> = ({ options, onSelectProp, onChangeOptions, ...props }) => {
    const { getStaticText } = useLanguage(TEXT_CODES.concat(FP.RESOURCE_FORMS));

    const arrayOptions = useMemo(() => Array.isArray(options) ? options : [], [options]);
    const formPaths = useMemo(() => arrayOptions.map(opt => opt.form), [arrayOptions]);
    const resourcesOptions = useMemo(() => FP.RESOURCE_FORMS.filter(path => !formPaths.includes(path)).map(value => ({ value, label: getStaticText(value) })), [getStaticText, formPaths]);

    const addResource = useCallback(() => {
        askSelect({ isRequired: true, options: resourcesOptions })
            .then((path?: string | null) => {
                if (TB.validString(path)) US.addMatrix(path).then(({ data }) => {
                    if (TB.isMatrixObject(data)) {
                        let newOption: MatrixOption = { _id: data._id, form: data.form, props: [] };
                        onChangeOptions?.(p => p.concat(newOption));
                    }
                    else renderAlert({ type: "error", message: TC.GLOBAL_ERROR_UPDATE });
                });
            });
    }, [resourcesOptions, onChangeOptions]);

    const valChecker = useCallback((form: string, value: string) => {
        let option = arrayOptions.filter(opt => opt.form === form)[0];
        if (Array.isArray(option?.props)) {
            let exists = option.props.includes(value);
            if (exists) return { message: getStaticText(TC.MATRIX_PROP_EXISTS), isValid: false };
        }
        return true;
    }, [arrayOptions, getStaticText]);

    const addProperty = useCallback((form: string) => {
        askPrompt({ title: getStaticText(TC.MATRIX_ADD_PROP), valChecker: str => valChecker(form, str), isRequired: true })
            .then(prop => {
                if (TB.validString(prop)) US.addPropToFormMatrix(form, prop)
                    .then(({ data }) => {
                        if (data?.ok === 1) onChangeOptions?.(p => p.map(opt => opt.form === form ? { ...opt, props: opt.props.concat(prop) } : opt));
                        else renderAlert({ type: "error", message: TC.GLOBAL_ERROR_UPDATE });
                    });

            });
    }, [valChecker, getStaticText, onChangeOptions]);

    return <div>
        <Flex justifyContent="end">
            <Button className="my-2" onClick={addResource}>
                <i className="fa fa-plus me-2"></i>
                {getStaticText(TC.MATRIX_ADD_RESOURCE)}
            </Button>
        </Flex>
        <Accordion>
            {arrayOptions.map(({ _id, form, props }, i) => <Accordion.Item key={_id} eventKey={i.toString()}>
                <Accordion.Header className="d-flex">
                    <Flex className="w-100 me-3" direction="row" alignItems="center" justifyContent="between">
                        {getStaticText(form)}
                        <span onClick={() => addProperty(form)} className="btn btn-info">
                            <i className="fa fa-plus me-2"></i>
                            {getStaticText(TC.MATRIX_ADD_PROP)}
                        </span>
                    </Flex>
                </Accordion.Header>
                <Accordion.Body style={{ maxHeight: "45vh", overflowY: "scroll" }}>
                    <Row>
                        {props.length > 0 && props.sort(TB.sortString).map((str, i) => <Col sm={6} md={4} xxl={3} key={i} onClick={() => onSelectProp?.(form, str)} >
                            <span className="pointer nav-link d-inline-block text-decoration-underline p-0">{str}</span>
                        </Col>)}
                        {props.length === 0 && <span className="text-muted text-center">{getStaticText(TC.MATRIX_NO_PROP_DEFINED)}</span>}
                    </Row>
                </Accordion.Body>
            </Accordion.Item>)}
        </Accordion>
    </div>
}

export default MatrixSelector