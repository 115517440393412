import _ from "lodash";
import React from "react";
import * as C from "../..";
import * as H from "../../../hooks";
import * as BS from "react-bootstrap";
import * as S from "../../../services";
import * as M from "../../../Components/Modal";
import { FP, T, TB, TC } from "../../../Constants";

//#region Types
export type ValueMapperProps = {
    /** Should it be displayed in a modal ? */
    popup?: boolean;
    /** Extra modal style params */
    modal?: M.StyleModalProps;
    /** Callback for the closure of the modal */
    onQuit?: () => void;
    /** Callback for the submission of the entries */
    onSave?: (mapped_equipments: ValueMapperProps["equipments"]) => void;
    /** The list of set options */
    options: ReturnType<T.API.Utils.Import.GetEquipImportResources>["options"];
    /** A list of pre-defined mapping */
    mapping?: Mapping[];
    /** The bulk import equipments */
    equipments: Parameters<T.API.Utils.Tables.BulkEquipments>[0];
    /** The Data about Brands & Models */
    brand_model: ReturnType<T.API.Utils.Import.GetEquipImportResources>["brand_model"];
};

type Mapping = {
    /** The prop changed */
    prop: keyof ValueMapperProps["options"];
    /** The string value found in the excel */
    excel_str: string;
    /** The real value in a good format */
    value?: any;
};
//#endregion

const AMOUNT_PER_PAGE = 7;

const ValueMapper: React.FC<ValueMapperProps> = props => {
    const lg = H.useLanguage();
    const [forms] = H.useFormIds();
    const [page_brand, set_page_brand] = React.useState(0);
    const equip_form = React.useMemo(() => forms[FP.EQUIPEMENT_FORM], [forms]);
    const [mapping, set_mapping] = React.useState<Mapping[]>(props.mapping || []);
    const [added, set_added] = React.useState<ValueMapperProps["brand_model"]>({ brands: [], models: [] });

    //#region Validation & updates
    const update_prop = React.useCallback((map: Mapping) => set_mapping(p => {
        let exists = p.some(m => m.prop === map.prop && m.excel_str === map.excel_str);
        if (exists) return p.map(m => m.prop === map.prop && m.excel_str === map.excel_str ? map : m);
        else return p.concat(map);
    }), []);

    const validate = React.useCallback(() => {
        let equipments = [] as Parameters<ValueMapperProps["onSave"]>[0];

        // Loop through each equipments
        for (let equip of props.equipments) {
            // Create a copy of the equipment, to change it's properties
            let new_equip = { ...equip };
            // Search through every props
            for (let prop of Object.keys(props.options)) {
                // Find a mapping that match this pair equipment/prop
                let map = mapping.filter(m => m.prop === prop && m.excel_str === equip[prop])[0];
                // Value found, set the value
                if (map) new_equip[prop] = map.value;
                // No value found, remove the property
                else delete new_equip[prop];
            }
            // Handle the brand & Models separately
            let model_str = new_equip.brand + new_equip.model;
            let model_map = mapping.filter(m => m.prop === "model" && m.excel_str === model_str)[0];
            let brand_map = mapping.filter(m => m.prop === "brand" && m.excel_str === equip.brand)[0];
            if (brand_map) {
                new_equip.brand = brand_map.value;
                if (model_map) new_equip.model = model_map.value;
                else delete new_equip.model;
            }
            else {
                delete new_equip.brand;
                delete new_equip.model;
            }
            equipments.push(new_equip);
        }
        props.onSave?.(equipments);
    }, [props, mapping]);
    //#endregion

    //#region Brands & Models
    const SORT_LIKENESS = React.useCallback((options: T.Option[], label: string) => [...options].sort((o1, o2) => {
        let precision1 = TB.checkStringSimilarity(o1.label, label),
            precision2 = TB.checkStringSimilarity(o2.label, label);
        // Both are quite similar to the string given, order by their likeness
        if (precision1 > 0.85 && precision2 > 0.85) return precision2 - precision1;
        // Only one is quite similar
        if (precision1 > 0.85) return -1;
        if (precision2 > 0.85) return 1;
        // Order alphabetically
        if (o1.label === o2.label) return 0;
        return o1.label > o2.label ? 1 : -1;
    }), []);

    const brands_models_pairs = React.useMemo(() => {
        // A list of pairs brand/models found in the excel file
        let found_refs = props.equipments.map(e => ({ brand: e.brand, model: e.model }));
        // Reduce the list of import to avoid duplicates
        let uniq_refs = _.uniqBy(found_refs, e => e.brand + e.model);
        // Match the refs with their ids
        return uniq_refs.map(r => {
            let map_brand = mapping.filter(m => m.prop === "brand" && m.excel_str === r.brand)[0];
            let map_model = mapping.filter(m => m.prop === "model" && m.excel_str === (r.brand + r.model))[0];
            return { ...r, brand_id: map_brand?.value, model_id: map_model?.value };
        }).sort((r1, r2) => {
            if (r1.brand === r2.brand) return r1.model > r2.model ? 1 : -1;
            else return r1.brand > r2.brand ? 1 : -1;
        });
    }, [props.equipments, mapping]);

    const max_page = React.useMemo(() => Math.floor(brands_models_pairs.length / AMOUNT_PER_PAGE), [brands_models_pairs.length]);

    const pagination = React.useMemo(() => ({
        can_prev: page_brand > 0,
        can_next: (page_brand + 1) < max_page,
        info: `${page_brand + 1} / ${max_page}`,
        to_next: () => set_page_brand(p => p + 1),
        to_prev: () => set_page_brand(p => p - 1),
        current: _.slice(brands_models_pairs, page_brand * AMOUNT_PER_PAGE, (page_brand + 1) * AMOUNT_PER_PAGE),
    }), [page_brand, max_page, brands_models_pairs]);

    const bm_options = React.useMemo(() => {
        let all_brands = props.brand_model.brands.concat(added.brands),
            all_models = props.brand_model.models.concat(added.models);
        return {
            brands: all_brands,
            model: (brand: string) => all_models.filter(m => m.brand === brand),
        }
    }, [props.brand_model, added]);

    const do_create_promise = React.useCallback((options: T.Option[]) => new Promise<"create" | "cancel" | Record<"id", string>>(resolve => {
        // No options that could be deemed similar
        if (options.length === 0) resolve("create");
        // Only one similar option, ask user to confirm
        else if (options.length === 1) M.askConfirm({
            noText: TC.EQUIP_IMPORT_CREATE_NEW_ITEM,
            yesText: TC.EQUIP_IMPORT_CREATE_OLD_ITEM,
            title: TC.EQUIP_IMPORT_CREATE_OR_AUTO_TITLE,
            text: lg.getStaticText(TC.EQUIP_IMPORT_CREATE_OLD_ITEM_SINGLE, options[0].label),
        })
            .then(confirmed => {
                // Canceled the select
                if (typeof confirmed !== "boolean") resolve("cancel");
                // Chose the existing item
                else if (confirmed) resolve({ id: options[0].value })
                // Chose to create a new item
                else resolve("create");
            })
        // More than one option found, ask user to pick
        else M.askSelect({
            options: options,
            defaultVal: options[0].value,
            selectProps: { hideClearButton: true },
            title: TC.EQUIP_IMPORT_CREATE_OR_AUTO_TITLE,
            confirmText: TC.EQUIP_IMPORT_CREATE_OLD_ITEM,
            label: TC.EQUIP_IMPORT_CREATE_OLD_ITEM_MULTI,
            noSelectionText: TC.EQUIP_IMPORT_CREATE_NEW_ITEM,
        })
            .then(selection => {
                // Canceled the select
                if (selection === null) resolve("cancel");
                // Chose an existing item
                else if (TB.mongoIdValidator(selection)) resolve({ id: selection });
                // Chose to create a new item
                else resolve("create");
            });
    }), [lg]);

    const brand_events = React.useMemo(() => ({
        set_brand: (brand_label: string, brand_id?: string) => set_mapping(p => {
            let new_map = [] as typeof p;
            let exists = p.some(m => m.prop === "brand" && m.excel_str === brand_label);
            if (exists) new_map = p.map(m => m.prop === "brand" && m.excel_str === brand_label ? { ...m, value: brand_id } : m);
            else new_map = p.concat({ excel_str: brand_label || "", value: brand_id, prop: "brand" });
            // Reset the models for this brand
            if (!brand_id) new_map = new_map.filter(m => m.excel_str.substring(0, brand_label.length) !== brand_label);
            return new_map;
        }),
        set_model: (brand_label: string, model_label: string, model_id?: string) => {
            let excel_v = (brand_label || "") + (model_label || "");
            set_mapping(p => {
                let exists = p.some(m => m.prop === "model" && m.excel_str === excel_v);
                if (exists) return p.map(m => m.prop === "model" && m.excel_str === excel_v ? { ...m, value: model_id } : m);
                else return p.concat({ excel_str: excel_v, value: model_id, prop: "model" });
            })
        },
        add_brand: (text: string, label: string) => {
            let sub = TB.submissionToArrayUpdate({ name: (text || "").toLocaleUpperCase() } as Partial<T.BrandData>);
            M.renderFormModal<T.BrandData>({ path: FP.BRAND_FORM, forcedSubmission: sub })
                .then(brand => {
                    if (brand) {
                        set_added(p => ({ ...p, brands: p.brands.concat({ label: brand.data.name, value: brand._id }) }));
                        brand_events.set_brand(label, brand._id);
                    }
                });
        },
        add_model: (text: string, label: string, brand_id: string, brand_label: string) => {
            let sub = TB.submissionToArrayUpdate({ type: text, brand: brand_id } as Partial<T.ModelData>);
            M.renderFormModal<T.ModelData>({ path: FP.TYPE_FORM, forcedSubmission: sub })
                .then(model => {
                    if (model) {
                        set_added(p => ({ ...p, models: p.models.concat({ label: model.data.type, brand: brand_id, value: model._id }) }));
                        brand_events.set_model(brand_label, label, model._id);
                    }
                });
        },
        auto_brand: (text: string, label: string) => {
            let sub = { name: (text || "").toLocaleUpperCase() } as T.BrandData;
            let similar_options = bm_options.brands.filter(option => TB.checkStringSimilarity(option.label, text) > 0.9);

            do_create_promise(similar_options).then(action => {
                // Automatically create a new submission
                if (action === "create") S.createSubmission({ submission: sub, path: FP.BRAND_FORM }).then(({ data }) => {
                    let brand = data.submissions[0];
                    if (brand) {
                        set_added(p => ({ ...p, brands: p.brands.concat({ label: brand.data.name, value: brand._id }) }));
                        brand_events.set_brand(label, brand._id);
                    }
                }).catch(M.Alerts.updateError);
                // Automatically select an existing option
                else if (action !== "cancel") brand_events.set_brand(label, action.id);
            });
        },
        auto_model: (text: string, label: string, brand_id: string, brand_label: string) => {
            let options = bm_options.model(brand_id);
            let sub = { type: text, brand: brand_id } as T.ModelData;
            let similar_options = options.filter(option => TB.checkStringSimilarity(option.label, text) > 0.9);

            do_create_promise(similar_options).then(action => {
                // Automatically create a new submission
                if (action === "create") S.createSubmission({ submission: sub, path: FP.TYPE_FORM }).then(({ data }) => {
                    let model = data.submissions[0];
                    if (model) {
                        set_added(p => ({ ...p, models: p.models.concat({ label: model.data.type, brand: brand_id, value: model._id }) }));
                        brand_events.set_model(brand_label, label, model._id);
                    }
                }).catch(M.Alerts.updateError);
                else if (action !== "cancel") brand_events.set_model(brand_label, label, action.id);
            });
        }
    }), [bm_options, do_create_promise]);
    //#endregion   

    //#region Content
    const values_per_prop = React.useMemo(() => {
        let temp_mapping: Partial<Record<Mapping["prop"], Mapping[]>> = {};

        for (let prop of Object.keys(props.options)) {
            let real_mapping = mapping.filter(m => m.prop === prop);
            let temp_mapping_prop = [] as typeof temp_mapping[Mapping["prop"]];
            let excel_values = _.uniq(props.equipments.map(e => e[prop])).filter(e => e !== undefined);

            for (let v of excel_values) {
                let real_value = real_mapping.filter(r => r.excel_str === v)[0]?.value;
                temp_mapping_prop.push({ prop: prop as any, excel_str: v, value: real_value });
            }
            if (temp_mapping_prop.length > 0) temp_mapping[prop] = temp_mapping_prop;
        }
        return temp_mapping;
    }, [props.equipments, props.options, mapping]);

    const entries = React.useMemo(() => {
        return Object.entries(props.options)
            .filter(([prop]) => Array.isArray(values_per_prop[prop]));
    }, [props.options, values_per_prop]);

    const content = React.useMemo(() => <BS.Tabs
        mountOnEnter
        unmountOnExit
        variant="tabs"
        className="mb-2"
        defaultActiveKey={entries?.[0]?.[0]}
    >
        {entries.map(([prop, options]) => <BS.Tab eventKey={prop} key={prop} title={lg.getTextObj(equip_form, prop, prop)}>
            {values_per_prop[prop].map((map: Mapping) => <BS.Row key={map.excel_str} className="mb-3">
                <BS.Col>
                    <C.Flex direction="column">
                        <span className="fw-bold">{lg.getStaticText(TC.IMPORT_EQUIP_EXCEL_VALUE)}:</span>
                        <span>{map.excel_str}</span>
                    </C.Flex>
                </BS.Col>
                <BS.Col>
                    <C.Form.Select
                        noBottomMargin
                        value={map.value}
                        options={options}
                        label={{ prop, _id: equip_form }}
                        onChange={v => update_prop({ excel_str: map.excel_str || "", prop: map.prop, value: v })}
                        typeahead={prop === "category" ? {
                            extra_search_props: "omniclass",
                            renderItem: opt => <div>{opt.label} <span className="text-muted">{(opt as any).omniclass}</span></div>,
                        } : null}
                    />
                </BS.Col>
            </BS.Row>)}
        </BS.Tab>)}


        {brands_models_pairs.length > 0 && <BS.Tab eventKey="brand" title={lg.getTextObj(equip_form, "brand", "brand")}>
            <C.Flex direction="column" className="h-100 w-100">
                <div className="flex-grow-1">
                    {pagination.current.map((v, i) => <BS.Row key={i}>
                        <BS.Col>
                            <C.Flex justifyContent="between" alignItems="center">
                                <div className="mb-0">{v.brand || lg.getStaticText(TC.IMPORT_EQUIP_EMP_NOT_ATTRIBUTES)}</div>
                                <div>
                                    {v.brand !== undefined && <C.Button
                                        size="sm"
                                        variant="link"
                                        text={TC.IMPORT_AUTO_CREATE}
                                        onClick={() => brand_events.auto_brand(v.brand, v.brand)}
                                    />}
                                </div>
                            </C.Flex>
                            <C.Form.Select
                                value={v.brand_id}
                                disabled={v.brand === undefined}
                                options={SORT_LIKENESS(bm_options.brands, v.brand)}
                                onChange={id => brand_events.set_brand(v.brand, id)}
                                typeahead={{
                                    allowNewOption: true,
                                    onAddOption: text => brand_events.add_brand(text, v.brand),
                                }}
                            />
                        </BS.Col>
                        <BS.Col>
                            <C.Flex justifyContent="between" alignItems="center">
                                <div className="mb-0">{v.model || lg.getStaticText(TC.IMPORT_EQUIP_EMP_NOT_ATTRIBUTES)}</div>
                                <div>
                                    {v.model !== undefined && v.brand_id && <C.Button
                                        size="sm"
                                        variant="link"
                                        text={TC.IMPORT_AUTO_CREATE}
                                        onClick={() => brand_events.auto_model(v.model, v.model, v.brand_id, v.brand)}
                                    />}
                                </div>
                            </C.Flex>
                            <C.Form.Select
                                value={v.model_id}
                                disabled={v.model === undefined || !v.brand_id}
                                onChange={id => brand_events.set_model(v.brand, v.model, id)}
                                options={SORT_LIKENESS(bm_options.model(v.brand_id), v.model)}
                                typeahead={{
                                    allowNewOption: true,
                                    onAddOption: text => brand_events.add_model(text, v.model, v.brand_id, v.brand),
                                }}
                            />
                        </BS.Col>
                    </BS.Row>)}
                </div>
                <C.Flex justifyContent="center" alignItems="center">
                    <C.IconButton className="me-3" icon="arrow-left" disabled={!pagination.can_prev} onClick={pagination.to_prev} />
                    <div className="me-3">{pagination.info}</div>
                    <C.IconButton icon="arrow-right" disabled={!pagination.can_next} onClick={pagination.to_next} />
                </C.Flex>
            </C.Flex>
        </BS.Tab>}
    </BS.Tabs>, [brands_models_pairs, bm_options, pagination, lg, equip_form, entries, values_per_prop, brand_events, update_prop, SORT_LIKENESS]);

    const footer = React.useMemo(() => <C.Flex justifyContent="end" children={<C.Button icon="save" text={TC.GLOBAL_CONFIRM} onClick={validate} />} />, [validate]);
    //#endregion


    return React.createElement(
        props.popup ? M.BlankModal : "div",
        props.popup ? {
            ...props.modal,
            footer,
            height: "75vh",
            onQuit: props.onQuit,
            size: props.modal?.size || "lg",
            title: props.modal?.title || TC.IMPORT_EQUIP_EQUIP_TITLE,
        } : null,
        <>
            {content}
            {!props.popup ? <div className="mt-2" children={footer} /> : null}
        </>
    );
}

export default ValueMapper;