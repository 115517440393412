import jwt from "jsonwebtoken";
import * as S from "../services";
import { T, TB } from "../Constants";

//#region Types
type ReloadFn = (state: Promise<T.UserRedux>) => Promise<T.UserRedux>;
type LocalUser = { user: T.UserType, preferences: T.AnyObject, isLoggedIn: boolean };
type LoginFn = (state: Promise<T.UserRedux>, user: T.UserType, admin_log?: boolean) => Promise<T.UserRedux>;
//#endregion

//#region Reloads
const getLocalUser = (): (LocalUser | null) => {
    try {
        let userStr = localStorage.getItem("formioUser");
        let tokenStr = localStorage.getItem("formioToken");

        if (userStr === null || tokenStr === null) return null;
        let user = JSON.parse(userStr);
        if (TB.isUser(user)) {
            let token = jwt.decode(tokenStr);
            if (typeof token === "string") return null;
            let endTime = token?.exp;
            if (typeof endTime !== "number") return null;
            // Token Expired
            if (new Date().getTime() > (endTime * 1000)) return null;

            let preferences = user.data.preferences;
            return { user, preferences, isLoggedIn: true };
        }
        else return null;
    }
    catch (error) {
        return null;
    }
}

const setLocalUser: LoginFn = (state, user) => new Promise(resolve => {
    state.then(redux => {
        try {
            localStorage.setItem('formioUser', JSON.stringify(user));
            resolve({ ...redux, user, preferences: TB.getObject(user.data.preferences) });
        }
        catch (error) {
            console.error(error);
            resolve(redux);
        }
    })
});

const loadAll: LoginFn = (state, user, admin_log = false) => new Promise(resolve => {
    state.then(redux => {
        let id = user?._id;
        let local = getLocalUser();

        if (!TB.mongoIdValidator(id) || local === null) resolve(initialState);
        else S.getAllUserInfos(id)
            .then(({ data }) => {
                let new_state = new Promise(r => r({ ...redux, ...data, ...local, isLoggedIn: true, admin_log }));
                new_state = setLocalUser(new_state, data.user);
                new_state.then(state => resolve(state));
            })
            .catch(error => resolve(redux))
    });
});

const loadPortfolio: ReloadFn = state => new Promise(resolve => {
    state.then(redux => {
        let id = redux.user?._id;
        if (TB.mongoIdValidator(id)) S.getPortfolioUser(id)
            .then(({ data }) => resolve({ ...redux, portfolios: data }))
            .catch(() => resolve(redux));
        else resolve(initialState);
    });
});

const reloadLocalUser: ReloadFn = state => new Promise(resolve => {
    state.then(redux => {
        let localData = getLocalUser();
        if (localData === null) resolve(initialState);
        else resolve({ ...redux, ...localData });
    })
});

const de_log: ReloadFn = (state) => new Promise(resolve => {
    // Call an API that will reset the cache
    S.cleanCache().finally(() => defaultPromise.then(resolve));
});
//#endregion

//#region Initial State
const initialState: T.UserRedux = {
    isAdmin: false,
    portfolios: [],
    isMobile: false,
    user: undefined,
    preferences: {},
    isLoggedIn: false,
}

const defaultPromise = new Promise<T.UserRedux>(r => r(initialState));

const initialPromise = new Promise<T.UserRedux>((resolve) => {
    let localData = getLocalUser();
    if (localData === null) defaultPromise.then(resolve);
    else loadAll(defaultPromise, localData.user)
        .then(state => resolve({ ...localData, ...state }));
});
//#endregion

const authRedux: T.ReduxFn<Promise<T.UserRedux>, T.AuthActions> = (state = initialPromise, payload) => {
    const { type, action } = TB.getObject(payload);

    switch (type) {
        case "LOGOUT": return de_log(state);
        case "RELOAD_USER": return reloadLocalUser(state);
        case "SET_USER": return setLocalUser(state, action);
        case "RELOAD_PORTFOLIOS": return loadPortfolio(state);
        case "LOGIN_SUCCESS": return loadAll(state, action?.user, action?.admin_log);
        default: return state;
    }
}

export default authRedux;

export const logout: T.ReduxDispatch<T.AuthActions> = () => ({ type: "LOGOUT" });
export const reloadUser: T.ReduxDispatch<T.AuthActions> = () => ({ type: "RELOAD_USER" });
export const reloadPortfolios: T.ReduxDispatch<T.AuthActions> = () => ({ type: "RELOAD_PORTFOLIOS" });
export const setUser: T.ReduxDispatch<T.AuthActions, T.UserType> = user => ({ type: "SET_USER", action: user });
export const login: T.ReduxDispatch<T.AuthActions, Record<"user", T.UserType> & Partial<Record<"admin_log", boolean>>> = params => ({ type: "LOGIN_SUCCESS", action: params });