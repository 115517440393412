import * as T from "../Types";
import { arrayWrapper } from "./array";
import { validObject } from "./objects";
import { validString } from "./string";

export const submissionToArrayUpdate = (submission?: Record<string, any>, disable?: T.AllowArray<string>): T.Sockets.Form.ForcedUpdate[] => {
    disable = arrayWrapper(disable).filter(validString);
    if (!validObject(submission)) return [];
    return Object.entries(submission).map(([prop, value]) => ({ prop, value, disable: disable.includes(prop) }));
}

export const isFormConditional = (cond: any): cond is T.FormConditional => {
    if (!validObject(cond)) return false;
    if (!validString(cond.eq) || !validString(cond.when)) return false;
    return typeof cond.show === "boolean";
}