import renderInContainer from "../getContainer";
import { NameMail, NameMailProps } from "../Components";

export type AskNameMail = (params: Omit<NameMailProps, "quit" | "validate">) => Promise<Parameters<NameMailProps["validate"]>[0]>;

const askNameMail: AskNameMail = params => new Promise(resolve => {
    const [render, dismount] = renderInContainer();
    if (render && dismount) {
        const quit: NameMailProps["quit"] = () => dismount(() => resolve(null));
        const validate: NameMailProps["validate"] = values => dismount(() => resolve(values));
        render(<NameMail {...params} quit={quit} validate={validate} />);
    }
    else resolve(null);
});

export default askNameMail;