import React from "react";
import Button from "../Button";
import * as H from "../../../hooks";
import Flex from "../../Layout/Flex";
import * as C from "./QuickInputCells";
import { T } from "../../../../../Constants";
import IconTip, { IconTipProps } from "../IconTip";
import { Row as BSRow, Col as BSCol } from "react-bootstrap";

//#region Types
export type Column<A extends RowData> = C.Cell & {
    /** Name of the column */
    label: string;
    /** Size of the columns, sum of all should be equal to twelve */
    size?: number;
    /** Name of the property it represents */
    prop: string;
    /** A default value */
    init_value?: any;
    /** Should a cell be disabled */
    is_disabled?: boolean | ((value: A) => boolean);
    /** Reset the value if another key has changed */
    reset_on?: T.AllowArray<keyof A>;
    /** Properties for a tooltip next to the label */
    tip_props?: IconTipProps;
    /** Should the current value be changed ? */
    replace_value?: (row: A) => Record<"change", boolean> & Partial<Record<"value", any>>;
};

type RowData = Record<string, any>;

type HeaderRowProps<A extends RowData> = {
    /** The listing of the columns */
    columns: Column<A>[];
    /** Should a space for the 'remove' column be included */
    add_button_col?: boolean;
    /** Disable the functionality to add or delete rows */
    gel_rows?: boolean;
}

export interface RowProps<A extends RowData> extends HeaderRowProps<A> {
    /** The value of the row */
    value?: A;
    /** The index of the current row */
    row_index?: number;
    /** The errors of the object */
    errors?: T.Errors<A>;
    /** Disable the functionality to add or delete rows */
    gel_rows?: boolean;
    /** Callback for value change */
    onChange: (value: A) => void;
    /** Callback to remove the value */
    remove?: () => void;
    /** Callback to add a new row */
    add_row?: () => void;
    /** Ask to switch the focus to another row */
    focus_row?: (row: number, cell: number) => void;
    /** Auto-focus on the first cell of the first row */
    no_init_focus?: boolean;
};

export type RowRef<A extends RowData> = {
    /** Callback to get the value */
    get: () => A;
    /** Focus on a specific cell in the row */
    focus: (cell: number) => void;
};
//#endregion

const RenderHeaderRow = <A extends RowData>(props: HeaderRowProps<A>, ref: React.ForwardedRef<{}>) => {
    const lg = H.useLanguage();

    //#region Language
    React.useEffect(() => {
        let codes = [] as string[];
        for (let col of props.columns) codes.push(col?.label);
        lg.fetchStaticTranslations(codes);
    }, [lg, props.columns]);
    //#endregion

    return <div>
        <BSRow className="align-items-center g-1">
            <BSCol md={props.gel_rows ? 12 : 11}>
                <BSRow className="g-1">
                    {props.columns.map(c => <BSCol key={c.prop} md={c.size}>
                        <h6>
                            {lg.getStaticText(c.label)}
                            {c.tip_props && <IconTip className="ms-2" {...c.tip_props} />}
                        </h6>
                    </BSCol>)}
                </BSRow>
            </BSCol>
            {!props.gel_rows && <BSCol md={1}></BSCol>}
        </BSRow>
    </div>
};

const RenderRow = <A extends RowData>({ onChange, ...props }: RowProps<A>, ref: React.ForwardedRef<RowRef<A>>) => {
    const row = React.useRef<HTMLDivElement>(null);
    const cellsRef = React.useRef<React.MutableRefObject<C.CellRef>[]>([]);

    H.useEffectOnce(() => {
        if (props.row_index === 0 && !props.no_init_focus) cellsRef.current?.[0]?.current?.focus?.()
    });

    H.useEventListener("keydown", event => {
        if (event.key === "Tab" || event.key === "Enter") {
            let source = event.target as HTMLElement;
            let closest_row = source.closest(".qi-row-indexed");

            if (closest_row && "dataset" in closest_row) {
                /* @ts-ignore the index of the cell */
                let index = parseInt((closest_row.dataset as any)?.index);
                /* @ts-ignore The type of input */
                let type = (closest_row.dataset as any)?.type as C.Cell["type"];

                // User pressed enter
                if (event.key === "Enter") {
                    // Disable for select, because 'Enter' has it's own purpose in select
                    if (type !== "select") {
                        event.preventDefault();
                        // Clean-up the current component
                        cellsRef.current?.[index]?.current?.clean?.();
                        // Focus on the next row, same col
                        props.focus_row?.(props.row_index + 1, index);
                    }
                    // Type === select, so 'Enter' acts like 'Tab'
                    else setTimeout(() => {
                        // Pressed key is 'Tab', at the end of the line
                        if (index + 1 === props.columns.length) props.focus_row(props.row_index + 1, 0);
                        // Move the the next col
                        else cellsRef.current?.[index + 1]?.current?.focus?.();
                        // Clean-up the current component
                        cellsRef.current?.[index]?.current?.clean?.()
                    }, 5);
                }
                else if (event.key === "Tab") {
                    event.preventDefault();
                    // Clean-up the current component
                    cellsRef.current?.[index]?.current?.clean?.();
                    // Pressed key is 'Tab', at the end of the line
                    if (index + 1 === props.columns.length) props.focus_row(props.row_index + 1, 0);
                    // Move the the next col
                    else cellsRef.current?.[index + 1]?.current?.focus?.();
                }
            }
        }
    }, row);

    React.useEffect(() => {
        for (let column of (props.columns || [])) {
            if (typeof column.replace_value === "function" && typeof props.value === "object" && props.value !== null) {
                let updates = column.replace_value(props.value);
                if (updates.change && props.value?.[column.prop] !== updates.value) {
                    onChange?.({ ...props.value, [column.prop]: updates.value });
                }
            }
        }
    }, [onChange, props.columns, props.value]);

    React.useImperativeHandle(ref, () => ({
        get: () => props.value,
        focus: cell => cellsRef.current?.[cell]?.current?.focus?.(),
    }), [props.value]);

    const default_value = React.useMemo(() => {
        let value = {} as RowData;
        for (let col of props.columns) if (col.init_value) value[col.prop] = col.init_value;
        if (Object.keys(value).length > 0) return value;
        return null;
    }, [props.columns]);

    React.useEffect(() => {
        if ((!props.value || Object.keys(props.value).length === 0) && default_value) {
            onChange(default_value as A);
        }
    }, [default_value, onChange, props.value]);

    const update_value = React.useCallback((value: any, prop: string) => {
        // Some values must be 'cleaned'
        let to_reset = props.columns
            .filter(c => Array.isArray(c.reset_on) ? c.reset_on.includes(prop) : c.reset_on === prop)
            .map(c => c.prop);

        let updated_value = { ...props.value, [prop]: value };
        to_reset.forEach(r => (updated_value[r] as any) = undefined);
        onChange?.(updated_value);
    }, [onChange, props.columns, props.value]);

    const render_cell = React.useCallback((column: Column<A>, index: number) => {
        let content = null as React.ReactElement;

        let value = props.value?.[column.prop];
        let onChange = value => update_value(value, column.prop);
        let error = column.error || { code: props.errors?.[column.prop] };

        let disabled = false;
        if (typeof column.is_disabled === "function") disabled = column.is_disabled(props.value);
        else disabled = column.is_disabled;

        // Initialize a ref if none exists
        if (!cellsRef.current[index]) cellsRef.current[index] = React.createRef();

        if (column.type === "number") content = <C.NumberCell
            {...column}
            value={value}
            error={error}
            disabled={disabled}
            onChange={onChange}
            ref={cellsRef.current[index]}
        />;
        else if (column.type === "text") content = <C.TextCell
            {...column}
            value={value}
            error={error}
            disabled={disabled}
            onChange={onChange}
            ref={cellsRef.current[index]}
        />;
        else if (column.type === "select") content = <C.SelectCell
            {...column}
            value={value}
            error={error}
            disabled={disabled}
            onChange={onChange}
            rowObject={props.value}
            ref={cellsRef.current[index]}
        />;
        else if (column.type === "boolean") content = <C.BooleanCell
            {...column}
            value={value}
            error={error}
            disabled={disabled}
            onChange={onChange}
            ref={cellsRef.current[index]}
        />;
        else if (column.type === "color") content = <C.ColorCell
            {...column}
            value={value}
            error={error}
            disabled={disabled}
            onChange={onChange}
            ref={cellsRef.current[index]}
        />;

        return <BSCol
            md={column.size}
            key={column.prop}
            data-index={index}
            children={content}
            data-type={column.type}
            className="qi-row-indexed"
        />;
    }, [props.value, props.errors, update_value]);

    return <div ref={row}>
        <BSRow className="align-items-center g-1" style={{ overflowX: "scroll" }}>
            <BSCol md={props.gel_rows ? 12 : 11}>
                <BSRow className="g-1" children={props.columns.map(render_cell)} />
            </BSCol>
            {!props.gel_rows && <BSCol md={1}>
                <Flex alignItems="center" justifyContent="center">
                    <Button disabled={!props.remove} className="w-100 h-100" icon="times" variant="danger" onClick={props.remove} />
                </Flex>
            </BSCol>}
        </BSRow>
    </div>;
}

export const HeaderRow = React.forwardRef(RenderHeaderRow) as <A extends RowData>(props: HeaderRowProps<A> & { ref?: any }) => React.ReactElement;
export const Row = React.forwardRef(RenderRow) as <A extends RowData>(props: RowProps<A> & { ref?: React.ForwardedRef<RowRef<A>> }) => React.ReactElement;