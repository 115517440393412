import _ from "lodash";
import React from "react";
import * as I from "../types";
import * as BS from "react-bootstrap";
import { IRowNode } from "ag-grid-community";
import { FP, T, TC } from "../../../../Constants";
import { useBoolean, useLanguage } from "../../../../hooks";

//#region Types
export type Props = {};
export type FilterModel = Role["status"][];
type Role = ReturnType<T.API.Access.GetRoleDistribution>["rows"][number]["roles"][string];
type Row = Pick<ReturnType<T.API.Access.GetRoleDistribution>["rows"][number], "name" | "path">;
//#endregion

export const type = "agCustomRoleCheck";

const ROLES = [
    { value: "blocked", label: TC.ACCESS_USER_LABEL_BLOCKED },
    { value: "unavailable", label: TC.ACCESS_USER_LABEL_UNAVAILABLE },
    { value: "inherited", label: TC.ACCESS_USER_LABEL_INHERITED },
    { value: "granted", label: TC.ACCESS_USER_LABEL_GRANTED },
    { value: "in_descend", label: TC.ACCESS_USER_LABEL_IN_DESCEND },
] as T.Option<{}, Role["status"]>[];

const STATUS_PRIORITY = [
    ["in_descend", 1],
    ["granted", 2],
    ["inherited", 3],
    ["unavailable", 4],
    ["blocked", 5],
] as [Role["status"], number][];

export const sort = (a?: Role, b?: Role) => {
    let priority_a = STATUS_PRIORITY.filter(s => s[0] === a?.status)[0]?.[1] || STATUS_PRIORITY.length;
    let priority_b = STATUS_PRIORITY.filter(s => s[0] === b?.status)[0]?.[1] || STATUS_PRIORITY.length;
    let result = priority_a - priority_b;
    if (result === 0) {
        if (a?.can_edit === b?.can_edit) return 0;
        else return a?.can_edit ? -1 : 1;
    }
    else return result;
};

export const Cell = React.forwardRef<{}, I.CellProps<Props>>((props, ref) => {

    const status = React.useMemo<Role>(() => {
        const default_role: Role = { can_edit: false, status: "blocked" };
        if (!props.node.group) return props?.getValue?.() || default_role;
        else {
            let field = props.colDef.field;
            let searched_path = "", search_name = props.node.key;
            if (props.node.field === "client") searched_path = FP.CLIENT_FORM;
            else if (props.node.field === "site") searched_path = FP.SITE_FORM;
            else if (props.node.field === "building") searched_path = FP.BUILDING_FORM;

            let group_rows = props.node.allLeafChildren as IRowNode<Row>[];
            let match_row = group_rows.filter(r => r.data.name === search_name && r.data.path === searched_path)[0];

            if (!match_row) return default_role;
            else return _.get(match_row.data, field) || default_role;
        }
    }, [props]);

    const icon = React.useMemo(() => {
        let icon = { i: "ban", color: "danger" as T.ColorTypes }, opacity = "0.5";

        if (status.status === "granted") icon = { i: "check", color: "success" };
        else if (status.status === "inherited") icon = { i: "check", color: "info" };
        else if (status.status === "in_descend") icon = { i: "circle", color: "warning" };
        else if (status.status === "unavailable") icon = { i: "times", color: "danger" };
        // Update opacity if user is allowed to update the role
        if (status.can_edit && !props.node.group) opacity = "1";
        else if (props.node.group) opacity = "0.25";
        return <i className={`fa fa-${icon.i} text-${icon.color}`} style={{ opacity }}></i>
    }, [status, props.node.group]);

    return <div className="text-center fs-120">
        {icon}
    </div>;
});

export const EditCell = React.forwardRef<I.EditorRef<Role>, I.EditorProps<Props>>((props, ref) => {
    const editing = useBoolean(true);
    const [value, set_value] = React.useState<Role>(props.value || { can_edit: false, status: "blocked" });

    React.useImperativeHandle(ref, () => ({ getValue: () => value }));
    React.useEffect(() => !editing.value && props.api?.stopEditing?.(), [editing.value, props.api]);

    React.useEffect(() => {
        if (value.can_edit && value.status !== "blocked") set_value(p => ({ can_edit: p.can_edit, status: p.status === "granted" ? "unavailable" : "granted" }));
        editing.setFalse();
    }, [value, editing]);

    return <></>;
});

export const Filter = React.forwardRef<I.FilterRef<FilterModel>, I.FilterProps<Props>>((props, ref) => {
    const lg = useLanguage();
    const [selected, set_selected] = React.useState<FilterModel>(ROLES.map(o => o.value));

    React.useImperativeHandle(ref, () => ({
        getModel: () => ({ value: selected }),
        setModel: model => set_selected(model?.value || []),
        isFilterActive: () => selected.length !== ROLES.length,
        doesFilterPass: params => {
            if (selected.length === 0) return false;
            let value = props.valueGetter({ ...props, node: params?.node, data: params?.node?.data, getValue: field => params?.node?.data[field] });
            // Check is value status is empty
            return selected.includes(value?.status);
        }
    }), [props, selected]);

    React.useEffect(() => props.filterChangedCallback?.(), [selected, props]);
    const toggle_status = React.useCallback((status: Role["status"]) => set_selected(p => p.includes(status) ? p.filter(x => x !== status) : p.concat(status)), []);

    return <div className='p-2'>
        {ROLES.map(o => <BS.InputGroup key={o.value} className="align-items-center mb-1">
            <BS.Form.Check checked={selected.includes(o.value)} onChange={() => toggle_status(o.value)} />
            <span className='ml-3 flex-grow-1 pointer' onClick={() => toggle_status(o.value)}>{o.label ? lg.getStaticText(o.label) : null}</span>
        </BS.InputGroup>)}
    </div>;
});