import _ from "lodash";
import Socket from 'socket.io-client';
import { T, TB, URL } from "../Constants";
import useAsyncState from "./useAsyncState";
import { useCallback, useEffect, useMemo, useState } from "react";

type Callback = {
    /** The callback to execute */
    cb: Function;
    /** The name of the event to listen to */
    event: string;
};

type SingleSocket = [
    {
        /** Emit an event */
        emit: (event: Callback["event"], params?: any) => void;
        /** Add multiple callbacks to the list */
        addCallbacks: (callbackList: Callback[]) => void;
        /** Add a callback to the list */
        addOneCallback: (event: Callback["event"], cb: Callback["cb"]) => void;
        /** Remove some or all listeners*/
        removeCallbacks: (events: "all" | Callback["event"][]) => void;
    },
    T.Socket,
];

const useSingleSocket = (path: string): SingleSocket => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [callbacks, setCallbacks] = useState<Callback[]>([]);
    const [socket, setSocket] = useAsyncState<T.Socket | null>(null);

    /**
     * Connect / Disconnect the socket
     */
    useEffect(() => {
        let newSocket: typeof socket = null;

        // Connect
        if (path) {
            /* @ts-ignore */
            newSocket = Socket(URL.APP_DOMAIN, { path });
            if (newSocket) setSocket(newSocket, "done");
            else setSocket(null, "error");
        }
        else setSocket(null, "error");

        // Disconnect
        return () => {
            if (newSocket) newSocket.disconnect?.();
        }
    }, [setSocket, path]);

    /** 
     * Add a callback to the list 
     */
    const addOneCallback = useCallback<SingleSocket[0]["addOneCallback"]>((event, cb) => {
        if (socket && TB.validString(event) && typeof cb === "function") setCallbacks(p => {
            if (p.some(l => l.event === event)) return p.map(l => {
                if (l.event === event) {
                    // Remove old callback
                    socket.off(event);
                    // Add new Callback
                    socket.on(event, cb);
                    return { event, cb };
                }
                else return l;
            });
            else {
                socket.on(event, cb);
                return p.concat({ event, cb });
            }
        });
    }, [socket]);

    /**
     * Add multiple callbacks to the list
     */
    const addCallbacks = useCallback<SingleSocket[0]["addCallbacks"]>(callbackList => {
        if (Array.isArray(callbackList) && callbackList.length > 0) setCallbacks(p => {
            let newEvents = callbackList.map(c => c.event);
            let [toUpdate, toAdd] = _.partition(p, l => newEvents.includes(l.event));

            for (let listeners of toUpdate) {
                socket?.off(listeners.event);
                socket?.on(listeners.event, listeners.cb);
            }
            for (let listeners of toAdd) socket?.on(listeners.event, listeners.cb);

            return p
                .map(listener => toUpdate.filter(l => l.event === listener.event)[0] || listener)
                .concat(toAdd);
        });
    }, [socket]);

    /**
     * Remove some or all listeners
     */
    const removeCallbacks = useCallback<SingleSocket[0]["removeCallbacks"]>(events => {
        if (socket) setCallbacks(p => {
            let toKeep: typeof p = [], toRemove: typeof p = [];

            // Remove all callbacks
            if (events === "all") toRemove = p;
            else for (let listener of p) {
                if (events.includes(listener.event)) toRemove.push(listener);
                else toKeep.push(listener);
            }

            for (let listener of toRemove) socket.off(listener.event);
            return toKeep;
        });
    }, [socket]);

    /**
     * Emit an event
     */
    const emit = useCallback<SingleSocket[0]["emit"]>((event, params) => {
        if (socket && TB.validString(event)) socket.emit(event, params);
    }, [socket]);

    return useMemo(() => {
        return [{ emit, addCallbacks, addOneCallback, removeCallbacks }, socket]
    }, [emit, addCallbacks, addOneCallback, removeCallbacks, socket]);
}

export default useSingleSocket;