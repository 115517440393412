import React from "react";
import * as C from "../../Common";
import * as R from "../../reducers";
import { TB } from "../../Constants";
import * as DOM from "react-router-dom"
import { useDispatch } from "react-redux";
import { AppContext } from "../../Context";

const FrameLayout: React.FC = () => {
    const dispatch = useDispatch();
    const [searchParams] = DOM.useSearchParams();
    const { config: { isDark }, setConfig } = React.useContext(AppContext);

    React.useEffect(() => {
        // Force to load translations
        dispatch(R.forceLoadLg());
    }, [dispatch]);

    React.useEffect(() => {
        let darkModeParam = searchParams.get("isDark");
        if (TB.validString(darkModeParam) && ["true", "false"].includes(darkModeParam)) {
            let forceDarkMode = searchParams.get("isDark") === "true";
            if (forceDarkMode !== isDark) setConfig('isDark', forceDarkMode)
        }
    }, [setConfig, searchParams, isDark]);

    return <C.ReduxWrapper>
        <C.AlertsContainer />
        <div className="container-fluid p-0">
            <div className="content d-flex flex-column p-0">
                <div className="d-flex flex-grow-1 h-100">
                    <DOM.Outlet />
                </div>
            </div>
        </div>
    </C.ReduxWrapper>;
}

export default FrameLayout;