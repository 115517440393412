import { REGEX } from "../Data";
import { ObjectIdStringify, mongoIdValidator } from "./id";
import { validObject } from "./objects";

type StringSimilarityFnOptions = {
    /** How to search the inclusion, which string should be used as 'container' */
    inclusion?: "both" | "tested" | "reference";
    /** How similar does the string have to be to pass ? If > 0.85 %, won't return true is part of the search appear in the string of reference */
    precision?: number;
    caseSensitive?: boolean;
}

export const containsUuid = (str?: string) => getString(str).match(REGEX.UUID_REGEX_LOOSE);
export const validString = (str: any): str is string => typeof str === "string" && str.length > 0;
export const validateMail = (mail: string) => validString(mail) && mail.match(REGEX.MAIL_REGEX) !== null;
export const stringSimplifier = (str: string) => getString(str).normalize('NFD').replace(/[\u0300-\u036f]/g, "");
export const capitalizeFirstLetter = (str: string) => validString(str) ? str.charAt(0).toUpperCase() + str.slice(1) : "";
export const isExcelColumnName = (col?: string): col is string => validString(col) && !!col.match(REGEX.EXCEL_COL_REGEX_STRICT);
export const unCapitalizeString = (str: string) => validString(str) ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : "";
export const shortenString = (str: string, maxSize = 75): string => validString(str) ? (str.length > maxSize ? str.substring(0, maxSize) + "..." : str) : "";
export const replaceStringPart = (string: string, search: string | RegExp, replace: string) => getString(string).split(getString(search)).join(getString(replace));

/**
 * Get a string value from unknown type
 */
export const getString = (str: any, defaultStr?: string) => {
    if (validString(str)) return str;
    if (Array.isArray(str)) return str.join();
    if (typeof str === "number") return str.toString();
    if (validString(defaultStr)) return defaultStr;
    return "";
}

/**
 * Check the degree of similarity between two strings, between 0 and 1
 */
export const checkStringSimilarity = (s1: string, s2: string): number => {
    const editDistance = (s1: string, s2: string) => {
        s1 = s1.toLowerCase();
        s2 = s2.toLowerCase();

        let costs: number[] = [];
        for (let i = 0; i <= s1.length; i++) {
            let lastValue = i;
            for (let j = 0; j <= s2.length; j++) {
                if (i === 0) costs[j] = j;
                else {
                    if (j > 0) {
                        let newValue = costs[j - 1];
                        if (s1.charAt(i - 1) !== s2.charAt(j - 1)) newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
                        costs[j - 1] = lastValue;
                        lastValue = newValue;
                    }
                }
            }
            if (i > 0) costs[s2.length] = lastValue;
        }
        return costs[s2.length];
    }

    if (typeof s1 !== "string" || typeof s2 !== "string") return 0;
    let longer = s1, shorter = s2;
    if (s1.length < s2.length) {
        longer = s2;
        shorter = s1;
    }
    let longerLength = longer.length;
    if (longerLength === 0) return 1.0;
    return (longerLength - editDistance(longer, shorter)) / longerLength;
}

export const extraNormalizeString = (string: string, toUpperCase = true) => {
    let vString = getString(string);
    if (toUpperCase) vString = vString.toUpperCase();
    let regex = toUpperCase ? /[^A-Z0-9_\s]/g : /[^a-zA-Z0-9_\s]/g;
    return vString.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(regex, "")
        .replace(/\s+/g, "_");
}

/**
 * Check if two strings are similar, given a degree of precision
 */
export const areStringSimilar = (tested: string, reference: string, options?: StringSimilarityFnOptions) => {
    if (![tested, reference].every(validString)) return false;
    let { caseSensitive = false, precision = 0.75 } = validObject(options) ? options : {};
    if (typeof precision !== "number") precision = 0.75;
    let [s1, s2]: string[] = [tested, reference].map(str => extraNormalizeString(caseSensitive ? str : str.toLowerCase(), false) ?? "");

    let includes = false;
    let inclusion = options?.inclusion || "both";
    if (inclusion === "both") includes = s2.includes(s1) || s1.includes(s2);
    else if (inclusion === "tested") includes = s1.includes(s2);
    else if (inclusion === "reference") includes = s2.includes(s1);
    let is_similar = checkStringSimilarity(s1, s2) >= precision;

    // If precision > 0.85, includes does not cut it
    if (precision > 0.85) return is_similar;
    else return includes || is_similar;
}

/** 
 * Transform a string into a variable-friendly string 
 */
export const toSnakeCase = (str: string): string => {
    let snakeCase = extraNormalizeString(str, false);
    snakeCase = snakeCase.replace(/\s+/g, "_");
    return snakeCase;
};

/**
 * Transforms a value to a string for db storage
 */
export const valueToString = (value: any) => {
    let str_value = "";
    if (Array.isArray(value) || (typeof value === "object" && value !== null)) {
        let to_mongo_id = ObjectIdStringify(value);
        if (mongoIdValidator(to_mongo_id)) str_value = to_mongo_id;
        else str_value = JSON.stringify(value);
    }
    else str_value = String(value);
    // Replace ' with escaped character
    // eslint-disable-next-line no-useless-escape
    return replaceStringPart(str_value, "'", "''");
}

/**
 * URL-encode an object
 */
export const url_encode_object = (obj: Record<string, any>) => {
    if (typeof obj !== "object" || obj === null) return "";
    // Convert the object to a JSON string
    const jsonString = JSON.stringify(obj);
    // Encode the JSON string for use in a URL
    const urlEncodedString = encodeURIComponent(jsonString);
    return urlEncodedString;
}