import React from 'react';
import { TB } from '../../Constants';

type SoftBadgeProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  & {
    /** Round the edges of the badge */
    pill?: boolean;
    /** The color of the badge */
    bg?: 'info'
    | 'dark'
    | 'light'
    | 'danger'
    | 'primary'
    | 'success'
    | 'warning'
    | 'secondary'
    /* Update _master.css to add new outline styles */
    | 'outline-success';
  }

const SoftBadge: React.FC<SoftBadgeProps> = props => {

  const classes = React.useMemo(() => {
    let bg = props.bg || "primary";
    let classes = [props.className];

    if (props.pill) classes.push("rounded-pill");
    if (bg.includes("outline")) classes.push("badge", "badge-outline", "badge-" + bg);
    else classes.push("badge", "badge-soft-" + bg);
    return classes.filter(TB.validString).join(" ");
  }, [props.bg, props.className, props.pill]);

  return <div className={classes} style={props.style} children={props.children} />;
};

export default SoftBadge;