import { TB } from "../../../../Constants";
import renderInContainer from "../../getContainer";
import { ReportSurface, ReportSurfaceProps } from "../../Components";

export type AskReportSurfaceProps = Omit<ReportSurfaceProps, "onQuit" | "onValidate">;

const AskReportSurface = (params?: AskReportSurfaceProps, containerId?: string) => new Promise<Parameters<ReportSurfaceProps["onValidate"]>[0] | null>(resolve => {
    let [render, dismount] = renderInContainer(containerId);
    if (!TB.validObject(params)) params = { build_surface: { surface: NaN, prop: "", name: "" } };

    if (render && dismount) {
        let onClose = () => dismount?.(() => resolve(null));
        let onConfirm: ReportSurfaceProps["onValidate"] = format => dismount?.(() => resolve(format));
        render(<ReportSurface {...params} onQuit={onClose} onValidate={onConfirm} />);
    }
    else resolve(null);
});

export default AskReportSurface;