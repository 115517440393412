import React from "react";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import * as S from "../../../services";
import { T, TC } from "../../../Constants";

export type AssignProps = {
    /** Pre-select the gamme to be reassigned */
    to_be_reassigned?: string;
    /** Should the component be rendered in a popup ? */
    popup?: boolean;
    /** If the options were already loaded */
    options?: Option[];
    /** Extra Styling for the modal */
    modal?: M.StyleModalProps;
    /** Callback after validation */
    on_validate?: (params?: Record<"reassigned_from" | "reassigned_to", string>) => void;
}

type Option = ReturnType<T.API.Utils.Gammes.GetGammesOptions>[number];

const Assign: React.FC<AssignProps> = ({ on_validate, ...props }) => {
    const lg = H.useLanguage();
    const [to_be_assign_to, set_to_be_assign_to] = React.useState("");
    const [gammes, set_gammes, status] = H.useAsyncState<Option[]>([]);
    const [to_be_reassigned, set_to_be_reassigned] = React.useState("");
    const [errors, set_errors] = React.useState<T.Errors<Record<"from" | "to", any>>>({});

    React.useEffect(() => {
        if (props.to_be_reassigned) set_to_be_reassigned(props.to_be_reassigned);
    }, [props.to_be_reassigned]);

    React.useEffect(() => {
        set_errors({});
    }, [to_be_reassigned, to_be_assign_to]);

    React.useEffect(() => {
        let isSubscribed = true;
        if (props.options) set_gammes(props.options, "done");
        else S.getGammesOptions({ lang: lg.prop, load_inactive: true })
            .then(({ data }) => isSubscribed && set_gammes(data, "done"))
            .catch(() => isSubscribed && set_gammes([], "error"));
        return () => {
            isSubscribed = false;
            set_gammes([], "load");
        }
    }, [lg.prop, props.options, set_gammes]);

    const validate = React.useCallback(() => {
        let new_errors = {} as typeof errors;
        let has_children = to_be_reassigned && gammes.some(g => g.parent === to_be_reassigned);
        // Verify that values have been selected
        if (!to_be_assign_to) new_errors.to = TC.GLOBAL_REQUIRED_FIELD;
        if (!to_be_reassigned) new_errors.from = TC.GLOBAL_REQUIRED_FIELD;
        // Check that the gamme to be reassigned is not the same as the one to be assigned to
        if (to_be_reassigned === to_be_assign_to) new_errors.from = TC.GAMME_ASSIGNMENT_ERROR_NOT_TO_SELF;
        // Check if the gamme to be reassigned is different from the one to be assigned to
        if (has_children) new_errors.from = TC.GAMME_ASSIGNMENT_ERROR_HAS_CHILDREN;
        // No errors raised, we can now validate the assignment
        let to_be_reassigned_label = gammes.find(g => g.value === to_be_reassigned)?.label;
        let delete_message = lg.getStaticText(TC.GAMME_ASSIGNMENT_WILL_BE_DELETED, to_be_reassigned_label);
        if (Object.keys(new_errors).length > 0) set_errors(new_errors);
        else M.askConfirm({ text: delete_message }).then(confirmed => {
            if (confirmed) on_validate?.({ reassigned_from: to_be_reassigned, reassigned_to: to_be_assign_to });
        });
    }, [on_validate, lg, to_be_assign_to, to_be_reassigned, gammes]);

    const footer = React.useMemo(() => <C.Flex justifyContent="end">
        <C.Button icon="check" text={TC.GLOBAL_CONFIRM} onClick={validate} />
    </C.Flex>, [validate]);

    const render_option = React.useCallback((option: Option) => <C.Flex justifyContent="between" alignItems="end">
        <span children={option.label} />
        <span className="ms -2 text-muted fs-85" children={option.omniclass} />
    </C.Flex>, []);

    return React.createElement(
        props.popup ? M.BlankModal : React.Fragment,
        props.popup ? {
            ...props.modal,
            footer: footer,
            size: props.modal?.size || "md",
            onQuit: () => on_validate?.(null),
            title: props.modal?.title || TC.TABLE_GAMME_REASSIGN,
        } as M.BlankModalProps : null,
        <div style={{ height: "7.5rem" }}>
            <C.Spinner status={status} min_load_size="7.5rem">
                <BS.Row className="g-2">
                    <BS.Col>
                        <C.Form.Select
                            no_clear_btn
                            options={gammes}
                            error={errors.from}
                            value={to_be_reassigned}
                            show_error_when_disabled
                            onChange={set_to_be_reassigned}
                            disabled={!!props.to_be_reassigned}
                            label={TC.GAMME_ASSIGNMENT_GAMME_ASSIGNMENT_TO_REASSIGN}
                            typeahead={{ renderItem: render_option, extra_search_props: "omniclass", dropdownFit: true }}
                        />
                    </BS.Col>

                    <BS.Col>
                        <C.Form.Select
                            no_clear_btn
                            options={gammes}
                            error={errors.to}
                            value={to_be_assign_to}
                            onChange={set_to_be_assign_to}
                            label={TC.GAMME_ASSIGNMENT_WILL_REPLACE}
                            typeahead={{ renderItem: render_option, extra_search_props: "omniclass", dropdownFit: true }}
                        />
                    </BS.Col>
                </BS.Row>

                {!props.popup && footer}
            </C.Spinner>
        </div>
    );
}

export default Assign;