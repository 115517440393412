import _ from "lodash";

const DrawingSelector = require("./DrawingType");
const PolygonSelector = require("./PolygonType");
const { PointSelector, RectangleSelector } = require("react-image-annotation/lib/selectors");

const getRectStyle = ({ x, y, width, height }, color) => ({
    top: y + "%",
    left: x + "%",
    width: width + "%",
    height: height + "%",
    backgroundColor: color + "99"
});

const getPointStyle = ({ x, y }, color) => ({
    top: `calc(${y}% - 10px)`,
    left: `calc(${x}% - 10px)`,
    backgroundColor: color,
});

const getDrawingPath = ({ coordinates, points }, closePath = false) => {
    let { clientWidth, clientHeight } = document.querySelector('.annotationEditor') ?? {};
    if ([clientHeight, clientWidth].some(x => x === undefined)) return;

    const getWidth = pct => clientWidth * (pct / 100);
    const getHeight = pct => clientHeight * (pct / 100);

    let newCoord = Array.isArray(coordinates) ? [...coordinates] : [...points];
    let firstElem = newCoord.shift();

    let path = `M ${getWidth(firstElem?.x || 0)} ${getHeight(firstElem?.y || 0)} `;
    newCoord.forEach(({ x, y }) => path += `L ${getWidth(x)} ${getHeight(y)} `);

    return path + (closePath ? "z" : "");
};

const getMiddlePointText = ({ coordinates, points }, text) => {
    let { clientWidth, clientHeight } = document.querySelector('.annotationEditor') ?? {};
    if ([clientHeight, clientWidth].some(x => x === undefined)) return;

    const getWidth = pct => clientWidth * (pct / 100);
    const getHeight = pct => clientHeight * (pct / 100);
    const getMiddle = (min, max) => min + ((max - min) / 2);

    let coord = Array.isArray(coordinates) ? coordinates : points;

    let maxY = _.maxBy(coord, ({ y }) => y).y;
    let maxX = _.maxBy(coord, ({ x }) => x).x;
    let minY = _.minBy(coord, ({ y }) => y).y;
    let minX = _.minBy(coord, ({ x }) => x).x;

    let middle = { x: getMiddle(minX, maxX), y: getMiddle(minY, maxY) };

    return <text x={getWidth(middle.x)} y={getHeight(middle.y)} textAnchor="middle">{text}</text>
}

const generatePolygonPoints = ({ points }) => {
    let { clientWidth, clientHeight } = document.querySelector('.annotationEditor') ?? {};
    if ([clientHeight, clientWidth].some(x => x === undefined)) return;

    const getWidth = pct => clientWidth * (pct / 100);
    const getHeight = pct => clientHeight * (pct / 100);
    return points.map(({ x, y }, i) => <circle key={i} cx={getWidth(x)} cy={getHeight(y)} r="3" />)
}

export const selectorRender = ({ annotation }) => {
    let { geometry } = annotation;

    switch (geometry.type) {
        case DrawingSelector.TYPE: return <svg className="selectorTrace draw">
            <path d={getDrawingPath(geometry)} />
        </svg>;

        case PolygonSelector.TYPE: return <svg className="selectorTrace polygon">
            <path d={getDrawingPath(geometry)} />
            {generatePolygonPoints(geometry)}
        </svg>;

        case RectangleSelector.TYPE: return <div className="rectangle selectorTrace" style={getRectStyle(geometry)}></div>;
        case PointSelector.TYPE: return <div className="selectorTrace point" style={getPointStyle(geometry)}></div>;
        default: break;
    }
}

export const renderHighlight = ({ active, annotation, key }) => {
    let { geometry, data } = annotation;
    let { text, color } = data || {};

    if (typeof color !== "string" || color.length === 0) color = "#FFFFFF";

    switch (geometry.type) {
        case DrawingSelector.TYPE: return <svg className="selectorTrace highlight draw" >
            <path d={getDrawingPath(geometry, true)} style={{ fill: color }} />
            {getMiddlePointText(geometry, text)}
        </svg>;

        case PolygonSelector.TYPE: return <svg className="selectorTrace highlight polygon">
            <path d={getDrawingPath(geometry, true)} style={{ fill: color }} />
            {getMiddlePointText(geometry, text)}
        </svg>;

        case RectangleSelector.TYPE: return <div className="selectorTrace highlight rectangle" style={getRectStyle(geometry, color)}>{text}</div>;
        case PointSelector.TYPE: return <div className="selectorTrace highlight point" style={getPointStyle(geometry, color)}>
            <div className="pointText">{text}</div>
        </div>;
        default: break;
    }
}

export const editorStyle = ({ x, y, height, width, type, boxX, boxY, boxHeight }) => {
    switch (type) {
        case PolygonSelector.TYPE: return { left: `${x}%`, top: `${y}%` };
        case DrawingSelector.TYPE: return { left: `${boxX}%`, top: `${boxY + boxHeight}%` };
        default: return { left: `${x}%`, top: `${y + height}%` };
    }
}