import axios from "axios";
import { getHeader } from "../Headers";
import { T, TB, URL } from "../../Constants";

const { REG_API } = URL;

type SwitchReg = T.API.ToAxios<T.API.Reg.RegSwitch>;
type ClearRegDocs = T.API.ToAxios<T.API.Reg.ClearRegForms>;
type RemoveRegForm = T.API.ToAxios<T.API.Reg.RemoveRegForm>;
type SetRegDocTags = T.API.ToAxios<T.API.Reg.SetRegDocTags>;
type UpdateRegForm = T.API.ToAxios<T.API.Reg.UpdateRegForm>;
type CreateRegForm = T.API.ToAxios<T.API.Reg.CreateRegForm>;
type FindDocuments = T.API.ToAxios<T.API.Reg.FindDocuments>;
type ToggleKPISaving = T.API.ToAxios<T.API.Reg.ToggleKPISaving>;
type GetKpiSavingData = T.API.ToAxios<T.API.Reg.GetKpiSavingData>;
type GetRegTabResults = T.API.ToAxios<T.API.Reg.GetRegTabResults>;
type GetRegTabElements = T.API.ToAxios<T.API.Reg.GetRegTabElements>;
type GetRegActionDetails = T.API.ToAxios<T.API.Reg.GetRegActionDetails>;
type GetElemRegUpdaterData = T.API.ToAxios<T.API.Reg.GetElemRegUpdaterData>;
type UpdateKPINextExecDate = T.API.ToAxios<T.API.Reg.UpdateKPINextExecDate>;
type UpdateKPISavingFrequency = T.API.ToAxios<T.API.Reg.UpdateKPISavingFrequency>;
type GetRegActionTableResource = T.API.ToAxios<T.API.Reg.GetRegActionTableResource>;

//#region Types Results
type GetActionsItemResults = { regDocs: T.RegDoc[], actions: T.API.Reg.RegActionResume[] };
//#endregion

//#region Types API Call
type GetActionItem = (elementId: string) => T.AxiosPromise<GetActionsItemResults>;
type MakeRegInactive = (action: string, element: string) => T.AxiosPromise<T.RegDoc[]>;
type ActivateRegDocElem = (doc: string, element: string) => T.AxiosPromise<null | T.RegDoc>;
type GetRegActionItems = (ids: string[]) => T.AxiosPromise<T.API.Reg.ActionsRegTableItems[]>;
type DeleteRegAction = (actions: string | string[]) => T.AxiosPromise<T.API.Reg.DeleteActionResults>;
type GetRegActionsTableData = (context: T.ContextParams) => T.AxiosPromise<T.API.Reg.ActionsRegTableData>;
type ContextActions = (params: T.API.Reg.GetActionsContextParams) => T.AxiosPromise<T.API.Reg.RegActionResume[]>;
type RemoveRegDocElem = (doc: string, element: string, activateNext?: boolean) => T.AxiosPromise<null | T.RegDoc>;
type SwitchActivation = (params: { element: string, activateAction?: string, deactivateAction?: string }) => T.AxiosPromise<T.RegDoc[]>;
//#endregion

/** Removes a reg doc from the database */
export const removeRegForm: RemoveRegForm = id => axios.post(REG_API + "doc/remove", { id }, getHeader("DELETE"));
/** Get the data to provide to the ActionRegUpdater component */
export const getElemRegUpdaterData: GetElemRegUpdaterData = id => axios.get(REG_API + "elem/updater", { params: { id } });
/** Get Frequency & Region info for reg actions */
export const getRegActionDetails: GetRegActionDetails = ids => axios.post(REG_API + "actions/details", { ids });
/** Get reg actions table items and resources */
export const getRegActionTableResource: GetRegActionTableResource = params => axios.post(REG_API + "table/resources", params);
/** Get infos about the building's kpi saving policy */
export const getKpiSavingData: GetKpiSavingData = building => axios.post(REG_API + "kpi/info", { building }, getHeader("EDIT"));
/** Change the frequency of a building's KPI saving */
export const updateKPISavingFrequency: UpdateKPISavingFrequency = params => axios.post(REG_API + "kpi/update", params, getHeader("EDIT"));
/** Toggle the saving of a building's KPI */
export const toggleKPISaving: ToggleKPISaving = building => axios.post(REG_API + "kpi/toggle", { building }, getHeader("EDIT"));
/** Update the date of next execution */
export const updateKPINextExecDate: UpdateKPINextExecDate = params => axios.post(REG_API + "kpi/update_date", params, getHeader("EDIT"));
/** Update a RegDoc's tags */
export const setRegDocTags: SetRegDocTags = params => axios.post(REG_API + "tags", params, getHeader("EDIT"));
/** Find a list of available document for a user in a certain context */
export const findDocuments: FindDocuments = context => axios.post(REG_API + "documents", context);
/** Delete multiple reg docs */
export const clearRegDocs: ClearRegDocs = ids => axios.post(REG_API + "clear", { ids }, getHeader("DELETE"));
/** Create a new document */
export const createRegDoc: CreateRegForm = doc => axios.post(REG_API + "createRegDoc", doc, getHeader("CREATE"));
/** Update an existing document */
export const updateRegForm: UpdateRegForm = doc => axios.post(REG_API + "updateRegForm", doc, getHeader("CREATE"));

export const getRegTabResources: GetRegTabResults = c => axios.post(REG_API + "context", c);
export const contextActions: ContextActions = params => axios.post(REG_API + "actionsContext", params);
export const getRegTabElements: GetRegTabElements = context => axios.post(REG_API + "elements", context);
export const getRegActionItems: GetRegActionItems = ids => axios.post(REG_API + "actions/items", { ids });
export const getRegActionsTableData: GetRegActionsTableData = c => axios.post(REG_API + "actions/table", c);
export const getActionsItem: GetActionItem = e => axios.post(REG_API + "actions/getActions", { elementId: e });
export const switchReg: SwitchReg = changes => axios.post(REG_API + "switchReg", { changes }, getHeader("EDIT"));
export const switchActivation: SwitchActivation = params => axios.post(REG_API + "switchActivation", params, getHeader("EDIT"));
export const activateRegDocElem: ActivateRegDocElem = (doc, element) => axios.post(REG_API + "activate", { doc, element }, getHeader("EDIT"));
export const makeRegInactive: MakeRegInactive = (action, element) => axios.post(REG_API + "inactive", { action, element }, getHeader("EDIT"));
export const deleteRegAction: DeleteRegAction = a => axios.delete(REG_API + "actions", { headers: getHeader("DELETE"), params: { actions: TB.arrayWrapper(a).join() } });
export const removeRegDocElem: RemoveRegDocElem = (doc, element, activateNext) => axios.post(REG_API + "removeElem", { doc, element, activateNext }, getHeader("DELETE"));