import * as T from "../Types";
import { REGEX } from "../Data";
import isColorLib from "is-color";
import tinyColor from "tinycolor2";
import { getNumber } from "./number";
import { getObject } from "./objects";
import { validString, getString } from "./string";

type RGBArray = [number, number, number];
type PickHexParams = { hexA?: string, hexB?: string, rgbA?: RGBArray, rgbB?: RGBArray, monoChromeDefault?: boolean };

export const COLOR_TYPES = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark'];
export const isValidColorType = (str: any): str is T.ColorTypes => COLOR_TYPES.includes(str);
export const getColor = (color: any): string | null => isColor(color) ? color : null;
export const isColor = (color: any): color is string => validString(color) && isColorLib(color);

const padZero = (str: string, len = 2) => {
    if (typeof len !== "number" || len < 0) len = 0;
    let zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
}

export const invertColor = (hex: string) => {
    if (!validString(hex)) hex = "";
    if (hex.indexOf('#') === 0) hex = hex.slice(1);
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    if (hex.length !== 6) return "#000000";
    // invert color components
    let rgb = [0, 2, 4].map(index => (255 - parseInt(hex.slice(index, index + 2), 16)).toString(16));
    // pad each with zeros and return
    return '#' + rgb.map(col => padZero(col)).join("");
}

export const getLumaContrast = (color = "#ffffff") => {
    let contrastColor = '#ffffff';
    if (!validString(color)) color = "#000000";
    let c = color.substring(1);      // strip #
    let rgb = parseInt(c, 16);   // convert rrggbb to decimal
    let r = (rgb >> 16) & 0xff;  // extract red
    let g = (rgb >> 8) & 0xff;  // extract green
    let b = (rgb >> 0) & 0xff;  // extract blue

    let luma = (0.2126 * r) + (0.7152 * g) + (0.0722 * b); // per ITU-R BT.709
    return (luma > 180 ? '#404040' : contrastColor);
}

/**
 * Is a color bright
 */
export const isColorWhite = (bgColor: string) => {
    if (!validString(bgColor)) bgColor = "#FFFFFF";
    var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    let [r, g, b] = [0, 2, 4].map(index => parseInt(color.substring(index, index + 2), 16));
    return ((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186;
}

export const isHexColor = (color?: string) => validString(color) && color.match(REGEX.COLOR_REGEX);

const componentToHex = (c: number) => {
    let hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}

export const rgbToHex = (r: number, g: number, b: number) => "#" + [r, g, b].map(num => componentToHex(getNumber(num) || 0)).join("");

export const hexToRgb = (hex: string): RGBArray | null => {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = getString(hex).replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
}

export const darkenColor = (color: string, amount?: number) => tinyColor(color).darken(amount);
export const brightenColor = (color: string, amount?: number) => tinyColor(color).brighten(amount);
const isRgbArray = (array?: any): array is RGBArray => Array.isArray(array) && array.length === 3 && array.every(x => typeof x === "number" && !isNaN(x) && x >= 0 && x <= 255);

export const pickHex = (weight: number, params?: PickHexParams) => {
    let { hexA, hexB, rgbA, rgbB, monoChromeDefault } = getObject(params);
    let w = getNumber(weight);
    if (typeof w !== "number" || w < 0) w = 0;
    else if (w > 1) w = 1;

    let default1: RGBArray = monoChromeDefault ? [0, 0, 0] : [255, 76, 22]; //#000000 && #FF4E16
    let default2: RGBArray = monoChromeDefault ? [255, 255, 255] : [78, 174, 83]; //#FFFFFF && #4EAE53

    let hex1 = getColor(hexA), hex2 = getColor(hexB);
    let hex1Rgb = hexToRgb(hex1), hex2Rgb = hexToRgb(hex2);

    let color1 = isRgbArray(rgbA) ? rgbA : (hex1Rgb ? hex1Rgb : default1);
    let color2 = isRgbArray(rgbB) ? rgbB : (hex2Rgb ? hex2Rgb : default2);

    let w1 = weight, w2 = 1 - w1;
    let rgb: RGBArray = [Math.round(color2[0] * w1 + color1[0] * w2), Math.round(color2[1] * w1 + color1[1] * w2), Math.round(color2[2] * w1 + color1[2] * w2)];

    return rgbToHex(rgb[0], rgb[1], rgb[2]);
}

/**
 * Generate random HEX color codes
 */
export const generateHexColorCodes = (n: number): string[] => {
    const generatedCodes: string[] = [];

    const generateRandomHexCode = (): string => {
        const characters = "0123456789ABCDEF";
        let code = "#";
        for (let i = 0; i < 6; i++) code += characters[Math.floor(Math.random() * characters.length)];
        return code;
    }

    for (let i = 0; i < n; i++) {
        let gen_try = 0;
        let code = generateRandomHexCode();
        // Check if the generated code already exists
        while (generatedCodes.includes(code) && gen_try < 5) {
            gen_try++;
            code = generateRandomHexCode();
        }
        generatedCodes.push(code);
    }
    return generatedCodes;
}