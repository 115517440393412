export const LINK_TYPE_OWN = "LT_OWN";
export const LINK_TYPE_GAS = "LT_GAS";
export const LINK_TYPE_GSM = "LT_GSM";
export const LINK_TYPE_ELEC = "LT_ELEC";
export const LINK_TYPE_FORM = "LT_FORM";
export const LINK_TYPE_MBUS = "LT_MBUS";
export const LINK_TYPE_WIFI = "LT_WIFI";
export const LINK_TYPE_WATER = "LT_WATER";
export const LINK_TYPE_TH_HOT = "LT_THCH";
export const LINK_TYPE_FIBER = "LT_FIBER";
export const LINK_TYPE_RS485 = "LT_RS485";
export const LINK_TYPE_TH_COLD = "LT_THFR";
export const LINK_TYPE_SUN = "LT_ELEC_PPV";
export const LINK_TYPE_PULSION = "LT_PULSE";
export const LINK_TYPE_RENTAL = "LT_RENTAL";
export const LINK_TYPE_CO_OWN = "LT_CO_OWN";
export const LINK_TYPE_ENERGY = "LT_PRIMARY";
export const LINK_TYPE_TH_FUEL = "LT_TH_FUEL";
export const LINK_TYPE_GESTION = "LT_GESTION";
export const LINK_TYPE_ETHERNET = "LT_ETHERNET";
export const LINK_TYPE_ENSEIGNE = "LT_ENSEIGNE";
export const LINK_TYPE_SITE_STRUCT = "LT_WEB_APP";
export const LINK_TYPE_HIERARCHY = "LT_HIERARCHY";
export const LINK_TYPE_EXTRACTION = "LT_EXTRACTION";
export const LINK_TYPE_PREDICTION = "LT_PREDICTION";
export const LINK_TYPE_MAINTENANCE = "LT_MAINTENANCE";
export const LINK_TYPE_EXPLOITATION = "LT_EXPLOITATION";
export const LINK_TYPE_EQUIPMENT_CATEGORY = "LT_OMNICLASS";

//#region Grouping
export const GROUP_STRUCTURE = [LINK_TYPE_OWN];
export const GROUP_EVENT = [LINK_TYPE_MAINTENANCE];
export const GROUP_ORGANIZATION = [LINK_TYPE_HIERARCHY];
export const GROUP_SITE_STRUCT = [LINK_TYPE_SITE_STRUCT];
export const GROUP_VENTILATION = [LINK_TYPE_PULSION, LINK_TYPE_EXTRACTION];

export const GROUP_ENERGY_NON_PRIMARY = [
    LINK_TYPE_GAS,
    LINK_TYPE_SUN,
    LINK_TYPE_ELEC,
    LINK_TYPE_WATER,
    LINK_TYPE_TH_HOT,
    LINK_TYPE_TH_FUEL,
    LINK_TYPE_TH_COLD,
];

export const GROUP_ENERGY = GROUP_ENERGY_NON_PRIMARY.concat(LINK_TYPE_ENERGY);

export const GROUP_COMMUNICATION = [
    LINK_TYPE_GSM,
    LINK_TYPE_WIFI,
    LINK_TYPE_MBUS,
    LINK_TYPE_FIBER,
    LINK_TYPE_RS485,
    LINK_TYPE_ETHERNET,
];
//#endregion

//#region ALL Link Types
export const ALL_LINK_TYPES = [
    LINK_TYPE_GSM,
    LINK_TYPE_GAS,
    LINK_TYPE_OWN,
    LINK_TYPE_SUN,
    LINK_TYPE_WIFI,
    LINK_TYPE_MBUS,
    LINK_TYPE_FORM,
    LINK_TYPE_ELEC,
    LINK_TYPE_FIBER,
    LINK_TYPE_RS485,
    LINK_TYPE_WATER,
    LINK_TYPE_RENTAL,
    LINK_TYPE_ENERGY,
    LINK_TYPE_CO_OWN,
    LINK_TYPE_TH_HOT,
    LINK_TYPE_GESTION,
    LINK_TYPE_PULSION,
    LINK_TYPE_TH_FUEL,
    LINK_TYPE_TH_COLD,
    LINK_TYPE_ETHERNET,
    LINK_TYPE_ENSEIGNE,
    LINK_TYPE_HIERARCHY,
    LINK_TYPE_PREDICTION,
    LINK_TYPE_EXTRACTION,
    LINK_TYPE_MAINTENANCE,
    LINK_TYPE_SITE_STRUCT,
    LINK_TYPE_EXPLOITATION,
    LINK_TYPE_EQUIPMENT_CATEGORY,
];
//#endregion

export const validLinkType = linkType => ALL_LINK_TYPES.includes(linkType);