import React from "react";
import * as M from "../Modal";
import * as H from "../../hooks";
import * as C from "../../Common";
import * as S from "../../services";
import * as BS from "react-bootstrap";
import { T, TABS, TC, URL } from "../../Constants";
import { embedDashboard } from '@superset-ui/embedded-sdk';

type Panels = "elec" | "co2" | "water" | "waste";
type Config = ReturnType<T.API.Access.Auth.GetSupersetToken>;
type SSOptions = ReturnType<T.API.Access.Auth.GetSupersetOptions>;
type PanelsOptions = T.Option<Record<"container", React.RefObject<HTMLDivElement>>, Panels>;

type ConfiguratorProps = {
    /** For which panel is it active ? */
    panel: T.Superset.Category;
    /** The configuration object */
    setting?: T.Superset.Config[T.Superset.Category];
    /** The label of the configuration panel */
    title: string;
    /** Callback to update the configuration */
    update_config: (value: T.Superset.Config[T.Superset.Category]) => void;
    /** Callback to apply a default vue */
    set_default: (sub_settings: ConfiguratorProps["setting"]) => void;
    /** A list of options available */
    options: SSOptions[T.Superset.Category];
    /** Should the default options of the favorite be used */
    use_default?: boolean;
}

//#region Constants & Tools
const superset_id = "aiset_schema.energy_dashboard";

const Dashboard_Ids: Record<Panels, string> = {
    co2: "8ccffa7e-b119-4a4f-87fa-7dd0c1770e10",
    elec: "d5a69b9f-d187-4e6c-8e45-dc5ec72a3724",
    waste: "8333b4fc-728a-408a-ad83-4bddb0353e9f",
    water: "bc832402-3ae4-4f77-a62d-31beed9aa62b",
}

const agg_options = [
    { value: "emplacement", label: TC.DASH_ENV_AGG_LOC_CELLS },
    { value: "building", label: TC.DASH_ENV_AGG_BUILD },
    { value: "site", label: TC.DASH_ENV_AGG_SITES },
] as T.Option<{}, T.Superset.Aggregation>[];

const options_filters = [
    { value: "tags", label: TC.DASH_ENV_FILTER_TAGS, only_energy: false, ask_segment_select: true },
    { value: "energy", label: TC.DASH_ENV_FILTER_ENERGY, only_energy: true, ask_segment_select: false },
    { value: "country", label: TC.DASH_ENV_FILTER_COUNTRY, only_energy: false, ask_segment_select: false },
    { value: "actif_type", label: TC.DASH_ENV_FILTER_AFFECT, only_energy: false, ask_segment_select: false },
] as T.Option<Record<"only_energy" | "ask_segment_select", boolean>, keyof T.Superset.Config[T.Superset.Category]["filters"]>[];

const set_panel = (panel: PanelsOptions, token: string) => {
    setTimeout(() => {
        embedDashboard({
            id: Dashboard_Ids[panel.value],
            mountPoint: panel.container.current,
            supersetDomain: URL.SUPERSET_RE_ROUTED,
            fetchGuestToken: () => new Promise(r => r(token)),
            dashboardUiConfig: {
                hideTitle: true,
                hideChartControls: false,
                filters: { visible: false, expanded: false },
                urlParams: { standalone: 3, show_filters: 0 },
            },
        });

        let iframe = panel.container.current.querySelector('iframe');
        if (iframe) {
            iframe.style.width = '100%';
            iframe.style.height = "955px";
        }
    }, 1000);
}
//#region Constants & Tools

const Environment: React.FC = () => {
    const lg = H.useLanguage();
    const [roots] = H.useRoots();
    H.useCrumbs(TC.TAB_ENV_DASHBOARD);
    const show_config = H.useBoolean(false);
    H.useAuth({ tabName: TABS.ENV_DASHBOARD });
    const co2_container = React.useRef<HTMLDivElement>(null);
    const elec_container = React.useRef<HTMLDivElement>(null);
    const water_container = React.useRef<HTMLDivElement>(null);
    const waste_container = React.useRef<HTMLDivElement>(null);
    const [settings, set_settings] = React.useState<T.Superset.Config>(null);
    const [config, set_config, status] = H.useAsyncState<Config>({ token: "", session: null, state: "load" });
    const [options, set_options, status_options] = H.useAsyncState<SSOptions>({ energy: null, wastes: null, water: null });

    const panels = React.useMemo<PanelsOptions[]>(() => [
        { container: elec_container, value: "elec", label: TC.SUPERSET_DASH_ELEC_TITLE },
        { container: co2_container, value: "co2", label: TC.SUPERSET_DASH_CO2_TITLE },
        { container: water_container, value: "water", label: TC.SUPERSET_DASH_WATER_TITLE },
        { container: waste_container, value: "waste", label: TC.SUPERSET_DASH_WASTE_TITLE },
    ], []);

    const configurator = React.useMemo<Pick<ConfiguratorProps, "panel" | "title">[]>(() => [
        { panel: "energy", title: TC.DASH_ENV_CONFIG_PANEL_ENERGY },
        { panel: "water", title: TC.DASH_ENV_CONFIG_PANEL_WATER },
        { panel: "wastes", title: TC.DASH_ENV_CONFIG_PANEL_WASTE },
    ], []);

    React.useEffect(() => {
        if (config.session?.dashboard_params) {
            let session_settings = config.session?.dashboard_params;
            let new_settings: Omit<typeof settings, "aggregation"> = {
                energy: { filters: session_settings.energy.filters, segmentation: [session_settings.energy.segmentation] },
                wastes: { filters: session_settings.wastes.filters, segmentation: [session_settings.wastes.segmentation] },
                water: { filters: session_settings.water.filters, segmentation: [session_settings.water.segmentation] },
            };
            set_settings(p => ({ ...p, ...new_settings }));
        }
    }, [config.session?.dashboard_params]);

    const update_config = React.useMemo(() => ({
        local: (prop: ConfiguratorProps["panel"], settings: ConfiguratorProps["setting"]) => set_settings(prev => ({ ...prev, [prop]: settings })),
        confirm: (new_settings = settings) => {
            if (config.session?.session_id) {
                S.setConfigSuperset({ session_id: config.session.session_id, config: new_settings, context: roots, superset_id }).then(() => {
                    // Reload the superset panels
                    panels.forEach(panel => set_panel(panel, config.token));
                    // Transform the settings to the session format
                    let as_session_settings: T.Superset.Session["dashboard_params"] = {
                        aggregation: new_settings.aggregation,
                        energy: { filters: new_settings.energy.filters, segmentation: new_settings.energy.segmentation[0] },
                        wastes: { filters: new_settings.wastes.filters, segmentation: new_settings.wastes.segmentation[0] },
                        water: { filters: new_settings.water.filters, segmentation: new_settings.water.segmentation[0] },
                    };
                    // Update the session
                    set_config(p => ({ ...p, session: { ...p.session, dashboard_params: as_session_settings } }));
                }).catch(M.Alerts.updateError);
            }
        },
        set_aggregation: (agg: T.Superset.Aggregation) => {
            let new_settings = { ...settings, aggregation: agg };
            update_config.confirm(new_settings);
            set_settings(new_settings);
        },
        set_default: (prop: ConfiguratorProps["panel"], sub_settings: ConfiguratorProps["setting"]) => {
            let new_settings = { ...settings, [prop]: sub_settings };
            update_config.confirm(new_settings);
            set_settings(new_settings);
        },
    }), [config.session, config.token, roots, settings, panels, set_config]);

    //#region Data Load & Setup
    React.useEffect(() => {
        let isSubscribed = true;
        let dashboard_ids = Object.values(Dashboard_Ids);
        S.getSupersetToken({ context: roots, dashboard_ids, superset_id })
            .then(({ data }) => isSubscribed && set_config(data, "done"))
            .catch(() => isSubscribed && set_config({ token: "", session: null, state: "error" }, "error"));
        return () => {
            isSubscribed = false;
            set_config({ token: "", session: null, state: "load" }, "error");
        }
    }, [set_config, roots]);

    React.useEffect(() => {
        let session_id = config.session?.session_id;
        if (session_id && config.state === "load") {
            let isSubscribed = true;
            let timeout: ReturnType<typeof setTimeout>;

            const test_state = () => {
                S.getSupersetState({ session_id, dashboard_id: superset_id }).then(({ data }) => {
                    if (isSubscribed) {
                        if (data === "load") timeout = setTimeout(test_state, 5000);
                        else set_config(p => ({ ...p, state: data }));
                    }
                }).catch(() => isSubscribed && set_config(p => ({ ...p, state: "error" })));
            }

            // Delay the first check to let the server process the request
            timeout = setTimeout(test_state, 2000);
            return () => {
                isSubscribed = false;
                if (timeout) clearTimeout(timeout);
            }
        }
    }, [config.session, config.state, set_config]);

    React.useEffect(() => {
        if (config.token && config.state === "done") panels.forEach(panel => set_panel(panel, config.token));
    }, [config.token, config.state, panels]);

    React.useEffect(() => {
        let isSubscribed = true;
        let session_id = config.session?.session_id;
        if (session_id && config.state === "done") {
            S.getSupersetOptions(session_id)
                .then(r => isSubscribed && set_options(r.data, "done"))
                .catch(() => isSubscribed && set_options({ energy: null, wastes: null, water: null }, "error"));
            return () => {
                isSubscribed = false;
                set_options({ energy: null, wastes: null, water: null }, "load");
            }
        }
    }, [config.session, config.state, set_options]);
    //#endregion

    const render_filters_seg = React.useCallback((panel: Panels) => {
        let dash_settings: T.Superset.Session["dashboard_params"][T.Superset.Category];
        if (panel === "co2" || panel === "elec") dash_settings = config?.session?.dashboard_params?.energy;
        else if (panel === "water") dash_settings = config?.session?.dashboard_params?.water;
        else if (panel === "waste") dash_settings = config?.session?.dashboard_params?.wastes;

        let components = [] as React.ReactElement[];
        // Add the filters
        for (let [temp_key, values] of Object.entries(dash_settings?.filters || {})) {
            let key: keyof T.Superset.Config[T.Superset.Category]["filters"] = temp_key as any;
            if (panel !== "co2" && panel !== "elec" && key === "energy") continue;

            let filter_label = options_filters.find(f => f.value === key)?.label;
            let classname = "", style: React.CSSProperties = {};
            if (values.length > 0) {
                style.cursor = "help";
                classname = "btn-link";
            }

            // There was an element before, so we need to add a separator
            if (components.length > 0) components.push(<div key={"sep_" + key} className="me-2" children="," />);
            // Add the filter
            components.push(<C.TipContainer
                key={key}
                tipContent={values.join(", ")}
                children={<div
                    className={classname}
                    style={style}
                    children={lg.getStaticText(filter_label)}
                />}
            />);
        }
        if (components.length > 0) components.unshift(<div
            key="filter_icon"
            children={<C.IconTip
                icon="filter"
                className="me-2"
                tipContent={TC.DASH_ENV_CONFIG_FILTERS}
            />}
        />);
        // Add the segmentation
        if (dash_settings?.segmentation) {
            let seg_label = options_filters.find(f => f.value === dash_settings.segmentation)?.label;
            if (!seg_label) seg_label = options_filters.find(f => f.value === "tags")?.label;
            components.push(
                <div key="seg_icon" children={<C.IconTip icon="chart-pie" className="mx-2" tipContent={TC.DASH_ENV_CONFIG_GROUPING} />} />,
                typeof dash_settings.segmentation === "string" || dash_settings.segmentation.tags.length === 0
                    ? <div key="seg" children={lg.getStaticText(seg_label)} />
                    : <C.TipContainer
                        key="seg"
                        tipContent={dash_settings.segmentation.tags.join(", ")}
                        children={<div className="btn-link" style={{ cursor: "help" }} children={lg.getStaticText(seg_label)} />}
                    />
            );
        }

        return <C.Flex direction="row" className="fs-85" children={components} />;
    }, [config.session, lg]);

    const spinner_texts = React.useMemo<C.SpinnerProps>(() => {
        let spin_text: string, error_text: string;

        if (status === "load") spin_text = TC.SUPERSET_LOAD_CONNECTING;
        else if (status === "error") error_text = TC.SUPERSET_ERROR_CONNECTING;
        else if (config.state === "load") spin_text = TC.SUPERSET_LOAD_DATA;
        else if (config.state === "error") error_text = TC.SUPERSET_ERROR_DATA;

        return { error: error_text, spin_text: spin_text, height: "flex-grow-1", status: [status, config.state], is_main_page: true };
    }, [status, config.state]);

    const config_button_props = React.useMemo<C.ButtonProps>(() => {
        let icon: C.ButtonProps["icon"];
        if (status_options === "load") icon = { spin: true, icon: "cog" };
        else icon = "cog";
        return { icon, size: "sm", disabled: !config.token, text: TC.SUPERSET_DASH_CONFIG_BUTTON };
    }, [status_options, config.token]);

    return <C.Spinner {...spinner_texts}>
        <C.Flex className="flex-grow-1" direction="column">
            {/* A button to open the configuration panel */}
            <C.Flex justifyContent="between" className="mb-2">
                <div>
                    {lg.getStaticText(TC.SUPERSET_DASH_JUMP_TO)} :
                    {panels.map(panel => <C.Button
                        size="sm"
                        variant="link"
                        className="mx-1"
                        key={panel.value}
                        text={panel.label}
                        onClick={() => panel.container.current?.scrollIntoView?.({ behavior: "smooth" })}
                    />)}
                </div>

                <C.Button {...config_button_props} onClick={show_config.setTrue} />
            </C.Flex>

            {panels.map(panel => <div key={panel.value}>
                <C.Flex justifyContent="between" className="me-3 mb-1">
                    <C.Title className="flex-grow-1 mb-0" text={panel.label} level={4} />
                    {render_filters_seg(panel.value)}
                </C.Flex>
                <div ref={panel.container} />
            </div>)}
        </C.Flex>

        <BS.Offcanvas enforceFocus={false} className="w-50" show={show_config.value} onHide={show_config.setFalse}>
            <BS.Offcanvas.Header closeButton>
                <BS.Offcanvas.Title>{lg.getStaticText(TC.SUPERSET_DASH_CONFIG_TITLE)}</BS.Offcanvas.Title>
            </BS.Offcanvas.Header>
            <BS.Offcanvas.Body>

                <C.Flex className="h-100" direction="column" justifyContent="between">

                    <C.Form.Select
                        no_clear_btn
                        options={agg_options}
                        label={TC.DASH_ENV_AGG_LABEL}
                        value={settings?.aggregation || "emplacement"}
                        onChange={(agg: T.Superset.Aggregation) => update_config.set_aggregation(agg)}
                    />

                    <BS.Accordion className="flex-grow-1">
                        {configurator.map(c => <BS.Accordion.Item key={c.panel} eventKey={c.panel}>
                            <BS.Accordion.Header children={lg.getStaticText(c.title)} />
                            <BS.Accordion.Body>
                                <Configurator

                                    panel={c.panel}
                                    title={c.title}
                                    options={options[c.panel]}
                                    setting={settings?.[c.panel]}
                                    use_default={!config.session?.dashboard_params}
                                    update_config={settings => update_config.local(c.panel, settings)}
                                    set_default={settings => update_config.set_default(c.panel, settings)}
                                />
                            </BS.Accordion.Body>
                        </BS.Accordion.Item>)}
                    </BS.Accordion>

                    <C.Flex className="my-3" justifyContent="end">
                        <C.Button
                            icon="check"
                            text={TC.SUPERSET_DASH_CONFIG_CONFIRM}
                            onClick={() => update_config.confirm()}
                        />
                    </C.Flex>

                </C.Flex>

            </BS.Offcanvas.Body>
        </BS.Offcanvas>

    </C.Spinner>;
};

export default Environment;

const Configurator: React.FC<ConfiguratorProps> = ({ update_config, set_default, ...props }) => {
    const [fav_data, { setFilters }] = H.useFavorite({
        size: "sm",
        variant: "sector",
        applyFav: set_default,
        initialState: props.setting,
        no_default: props.use_default,
        origin: props.panel + "_configurator",
    });

    const update = React.useCallback<typeof update_config>(config => {
        setFilters(config);
        update_config(config);
    }, [update_config, setFilters]);

    const type_filters = React.useMemo(() => {
        let filters_with_options = options_filters.map(f => {
            return { ...f, options: (props.options?.[f.value] || []).map(o => ({ value: o, label: o })) }
        });

        if (props.panel === "energy") return filters_with_options;
        else return filters_with_options.filter(f => !f.only_energy);
    }, [props.panel, props.options]);

    //#region Filters
    const filters = React.useMemo(() => {
        let active_filters = Object.entries(props.setting?.filters || {});
        if (props.panel !== "energy") active_filters = active_filters.filter(([key]) => key !== "energy");
        // Not all type of filters already have a value set, so we need to add an empty one
        if (active_filters.length < type_filters.length) active_filters.push(["", []]);
        let new_active_filters = active_filters
            .map(([key, value]) => ({ key, value, options: type_filters.find(f => f.value === key)?.options || [] }));
        return new_active_filters;
    }, [props.setting, props.panel, type_filters]);

    const get_filter_options = React.useCallback((current: string) => {
        // The list of filters already set
        let set_filters = Object.keys(props.setting?.filters || {});
        // The list of filters that can be set
        let available_filters = type_filters.filter(f => !set_filters.includes(f.value) || f.value === current);
        return available_filters;
    }, [props.setting, type_filters]);

    const change_filter = React.useMemo(() => ({
        remove: (key: keyof T.Superset.Config[T.Superset.Category]["filters"]) => {
            let new_settings: ConfiguratorProps["setting"] = {
                // Create a copy of the settings as to not mutate the original
                filters: { ...(props.setting?.filters || {}) },
                segmentation: props.setting?.segmentation || [],
            };
            delete new_settings.filters[key];
            update(new_settings);
        },
        update_value: (key: keyof T.Superset.Config[T.Superset.Category]["filters"], value: string[]) => {
            let new_settings: ConfiguratorProps["setting"] = {
                // Create a copy of the settings as to not mutate the original
                filters: { ...(props.setting?.filters || {}) },
                segmentation: props.setting?.segmentation || [],
            };
            new_settings.filters[key] = value;
            update(new_settings);
        },
        update_key: (old_key?: keyof T.Superset.Config[T.Superset.Category]["filters"], new_key?: keyof T.Superset.Config[T.Superset.Category]["filters"]) => {
            let new_settings: ConfiguratorProps["setting"] = {
                // Create a copy of the settings as to not mutate the original
                filters: { ...(props.setting?.filters || {}) },
                segmentation: props.setting?.segmentation || [],
            };
            // There was a key, so we need to remove it
            if (old_key) delete new_settings.filters[old_key];
            // There is a new key, so we need to add it
            if (new_key) new_settings.filters[new_key] = [];
            // Update the settings
            update(new_settings);
        }
    }), [props.setting, update]);
    //#endregion

    //#region Segmentations
    const segmentations = React.useMemo(() => {
        let active = [...(props.setting?.segmentation || [])];
        // Not all type of segmentations already have a value set, so we need to add an empty one
        if (active.length < type_filters.length) active.push(null);
        // Reformat the active segmentations
        let active_objects = active.map(s => {
            if (!s) return { value: [], key: "", show_select: false };
            let show_select = type_filters.find(f => typeof s === "string" ? f.value === s : f.value === "tags")?.ask_segment_select;
            return typeof s === "string" ? { value: [], key: s, show_select } : { value: s.tags, key: "tags", show_select }
        });

        let new_active_objects = active_objects
            .map(s => ({ ...s, options: type_filters.find(f => f.value === s.key)?.options || [] }));
        // Only use one segmentation (maybe temporary, that's why we use this 'hack' here)
        return [new_active_objects[0]];
    }, [props.setting, type_filters]);

    const get_segmentation_options = React.useCallback((current: string) => {
        // The list of segmentations that can be set
        let available_segmentations = type_filters.filter(f => {
            // Check if there is already a value for this filter
            let segmentation_value = segmentations.some(s => s.key === f.value);
            // Show the segmentation if not already used, or is used but is also the current one
            return !segmentation_value || f.value === current;
        });
        return available_segmentations;
    }, [segmentations, type_filters]);

    const change_segmentation = React.useMemo(() => ({
        remove: (key: keyof T.Superset.Config[T.Superset.Category]["filters"]) => {
            let new_settings: ConfiguratorProps["setting"] = {
                filters: props.setting?.filters || {},
                segmentation: props.setting?.segmentation || [],
            };
            // Remove the segmentation
            if (key === "tags") new_settings.segmentation = new_settings.segmentation.filter(s => typeof s === "string");
            else new_settings.segmentation = new_settings.segmentation.filter(s => s !== key);
            update(new_settings);
        },
        update_value: (key: keyof T.Superset.Config[T.Superset.Category]["filters"], value: string[]) => {
            let new_settings: ConfiguratorProps["setting"] = {
                filters: props.setting?.filters || {},
                segmentation: props.setting?.segmentation || [],
            };
            // Only update the tags
            if (key === "tags") {
                new_settings.segmentation = new_settings.segmentation.map(s => typeof s !== "string" ? { tags: value } : s);
                update(new_settings);
            }
        },
        update_key: (old_key?: keyof T.Superset.Config[T.Superset.Category]["filters"], new_key?: keyof T.Superset.Config[T.Superset.Category]["filters"]) => {
            let new_settings: ConfiguratorProps["setting"] = {
                filters: props.setting?.filters || {},
                segmentation: props.setting?.segmentation || [],
            };

            // There was a key, so we need to remove it
            if (old_key) {
                if (old_key === "tags") new_settings.segmentation = new_settings.segmentation.filter(s => typeof s === "string");
                else new_settings.segmentation = new_settings.segmentation.filter(s => s !== old_key);
            }
            // There is a new key, so we need to add it
            if (new_key) {
                if (new_key === "tags") new_settings.segmentation.push({ tags: [] });
                else new_settings.segmentation.push(new_key);
            }
            // Update the settings
            update(new_settings);
        }
    }), [props.setting, update]);
    //#endregion

    return <div>
        <div className="mb-2">
            <C.Flex justifyContent="between" alignItems="center">
                <C.Title text={TC.DASH_ENV_CONFIG_FILTERS} level={4} />
                {fav_data.dropdown}
            </C.Flex>

            {filters.map((filter, i) => <C.Flex key={filter.key || i}>
                <C.Flex className="flex-grow-1" alignItems="start" justifyContent="between">
                    <C.Form.Select
                        no_clear_btn
                        noBottomMargin
                        value={filter.key}
                        customClass="w-100 mb-1"
                        options={get_filter_options(filter.key)}
                        label={i === 0 ? TC.DASH_ENV_PROPERTY_LABEL : undefined}
                        onChange={value => change_filter.update_key(filter.key as any, value)}
                    />
                    <C.Form.Select
                        multiple
                        noBottomMargin
                        value={filter.value}
                        disabled={!filter.key}
                        options={filter.options}
                        customClass="w-100 mb-1 mx-2"
                        label={i === 0 ? TC.DASH_ENV_OPTION_LABEL : undefined}
                        onChange={value => change_filter.update_value(filter.key as any, value)}
                    />
                </C.Flex>
                <C.Flex alignItems="center" justifyContent="center">
                    <C.Button
                        icon="times"
                        variant="danger"
                        disabled={!filter.key}
                        className={i === 0 ? "mt-3" : undefined}
                        onClick={() => change_filter.remove(filter.key as any)}
                    />
                </C.Flex>
            </C.Flex>)}
        </div>

        <div>
            <C.Title text={TC.DASH_ENV_CONFIG_GROUPING} level={4} />

            {segmentations.map((seg, i) => <C.Flex key={seg.key || i}>
                {/* <C.Flex className="flex-grow-1" alignItems="start" justifyContent="between"> */}
                <C.Form.Select
                    no_clear_btn
                    noBottomMargin
                    value={seg.key}
                    customClass="w-100 mb-1"
                    options={get_segmentation_options(seg.key)}
                    label={i === 0 ? TC.DASH_ENV_PROPERTY_LABEL : undefined}
                    onChange={value => change_segmentation.update_key(seg.key as any, value)}
                />
                <C.Form.Select
                    multiple
                    noBottomMargin
                    value={seg.value}
                    options={seg.options}
                    customClass="w-100 mb-1 mx-2"
                    disabled={!seg.key || !seg.show_select}
                    label={i === 0 ? TC.DASH_ENV_OPTION_LABEL : undefined}
                    onChange={value => change_segmentation.update_value(seg.key as any, value)}
                />
                {/* </C.Flex> */}
                {/* Not necessary as we (temporarily ?) only allow one segmentation */}
                {/* <C.Flex alignItems="center" justifyContent="center">
                    <C.Button
                        icon="times"
                        variant="danger"
                        disabled={!seg.key}
                        className={i === 0 ? "mt-3" : undefined}
                        onClick={() => change_segmentation.remove(seg.key as any)}
                    />
                </C.Flex> */}
            </C.Flex>)}
        </div>
    </div >;
}