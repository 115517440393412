import React from "react";
import * as I from "../Input";
import * as H from "../../../hooks";
import * as BS from "react-bootstrap";
import { T, TB, TC } from "../../../Constants";

//#region Types
export type RadioBoolProps = I.InputProps & {
    /** The name of the input, if there are more thant one RadioBool on the page */
    name?: string;
    /** Color of the buttons */
    variant?: T.ColorTypes;
    /** Do not allow no selection at all */
    always_selected?: boolean;
};
//#endregion

const RadioBool: React.FC<RadioBoolProps> = ({ disabled, noOverride, onChange, ...props }) => {
    const lg = H.useLanguage([TC.GLOBAL_YES, TC.GLOBAL_NO]);

    const isDisabled = React.useMemo(() => disabled || noOverride, [disabled, noOverride]);
    const vName = React.useMemo(() => TB.getString(props.name || props.prop, "radioBool"), [props.name, props.prop]);
    const variant = React.useMemo(() => "outline-" + (TB.isValidColorType(props.variant) ? props.variant : "secondary"), [props.variant]);

    const boolToStringValue = React.useMemo(() => {
        if (typeof props.value !== "boolean") return "";
        return props.value ? "yes" : "no";
    }, [props.value]);

    const onValueChange = React.useCallback((radioValue?: "yes" | "no") => {
        if (isDisabled) return;
        let radioValueBool = radioValue === "yes";
        if (props.value === radioValueBool) {
            // Don't send back if a value should always be selected
            if (!props.always_selected) onChange?.(undefined);
        }
        else onChange?.(radioValueBool);
    }, [props.value, props.always_selected, onChange, isDisabled]);

    return <I.ComponentWrapper {...props} type="radioBool" labelMargin labelPosition={props.labelPosition || "left"} disabled={isDisabled}>
        <BS.ToggleButtonGroup type="radio" size="sm" name={vName} value={boolToStringValue}>
            <BS.ToggleButton
                value="yes"
                variant={variant}
                id={vName + "_yes"}
                disabled={isDisabled}
                onClick={() => onValueChange("yes")}
            >
                {lg.getStaticElem(TC.GLOBAL_YES)}
            </BS.ToggleButton>
            <BS.ToggleButton
                value="no"
                variant={variant}
                id={vName + "_no"}
                disabled={isDisabled}
                onClick={() => onValueChange("no")}
            >
                {lg.getStaticElem(TC.GLOBAL_NO)}
            </BS.ToggleButton>
        </BS.ToggleButtonGroup>
    </I.ComponentWrapper>
}

export default RadioBool;