import React from 'react';
import { TB } from '../../Constants';
import * as echarts from 'echarts/core';
import { PieChart } from 'echarts/charts';
import { useLanguage } from '../../hooks';
import { CanvasRenderer } from 'echarts/renderers';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { GridComponent, TooltipComponent, TitleComponent, LegendComponent } from 'echarts/components';
echarts.use([TitleComponent, TooltipComponent, GridComponent, PieChart, CanvasRenderer, LegendComponent]);

//#region Types
type DataItem = {
    name?: string;
    value: number;
    color?: string;
}

type ToolTipParams = {
    bgColor?: string;
    textColor?: string;
    borderWidth?: number;
    borderColor?: string;
    transitionDuration?: number;
    valueFormatter?: ((value: number) => string);
}

type LegendParams = {
    color?: string;
    orientation?: "horizontal" | "vertical",
    formatter?: string | ((name: string) => string);
}

type DoughnutChartProps = {
    title?: string;
    height?: string;
    data: DataItem[];
    centerText?: string;
    valuedLegend?: boolean;
    legend?: boolean | LegendParams;
    tooltip?: boolean | ToolTipParams;
}
//#endregion

//#region Constants
const defaultLegend: LegendParams = {
    color: "#000000",
    orientation: "vertical",
}

const defaultTooltip: ToolTipParams = {
    borderWidth: 1,
    bgColor: "#FFFFFF",
    transitionDuration: 0,
}
//#endregion

const DoughnutChart: React.FC<DoughnutChartProps> = ({ legend, title, data, centerText, valuedLegend, tooltip, height, ...props }) => {
    const { getStaticText } = useLanguage();

    //#region Legend
    const legendObj = React.useMemo(() => {
        if (!legend) return;
        let params = typeof legend === "boolean" ? defaultLegend : { ...defaultLegend, ...TB.getObject(legend) };
        return {
            left: "left",
            orient: params.orientation,
            formatter: params.formatter,
            textStyle: { color: TB.getColor(params.color) || "#000000" }
        }
    }, [legend]);
    //#endregion

    //#region Tooltip
    const toolTipObj = React.useMemo(() => {
        if (!tooltip) return { show: false };
        let params = typeof tooltip === "boolean" ? defaultTooltip : { ...defaultTooltip, ...TB.getObject(tooltip) };
        return {
            trigger: 'item',
            padding: [7, 10],
            axisPointer: { type: 'none' },
            borderColor: params.borderColor,
            backgroundColor: params.bgColor,
            borderWidth: params.borderWidth,
            valueFormatter: params.valueFormatter,
            textStyle: { color: params.textColor },
            transitionDuration: params.transitionDuration,
        }
    }, [tooltip]);
    //#endregion

    //#region Data
    const dataObj = React.useMemo(() => TB.getArray(data).map(d => ({
        value: TB.getNumber(d.value) || 0,
        name: TB.getString(d.name, "N/A") + `${valuedLegend ? ` (${d.value || 0})` : ""}`,
        itemStyle: {
            color: TB.getColor(d.color),
        }
    })), [data, valuedLegend]);
    //#endregion

    const getOption = React.useCallback(() => ({
        legend: legendObj,
        series: [{
            name: TB.getString(centerText),
            type: 'pie',
            radius: ['40%', '70%'],
            center: ['50%', '55%'],
            avoidLabelOverlap: false,
            labelLine: { show: false },
            data: dataObj,
            label: {
                show: true,
                position: 'center',
                formatter: '{a}',
                fontSize: 23,
                color: "#000000"
            },
        }],
        tooltip: toolTipObj,
    }), [legendObj, toolTipObj, dataObj, centerText]);

    return <>
        {TB.validString(title) && <div className='w-100 text-center'><span className='h5'>{getStaticText(title)}</span></div>}
        <ReactEChartsCore echarts={echarts} option={getOption()} style={{ height: TB.getString(height, '20rem') }} />
    </>
}

export default DoughnutChart;