import React from "react";
import * as H from "../../../../hooks";
import * as C from "../../../../Common";
import * as BS from "react-bootstrap";
import { FP, T, TB, TC } from "../../../../Constants";
import { StyleModalProps, default as Modal } from "../../BlankModal";

export type ReportSurfaceProps = {
    /** Data about the surface of the building */
    build_surface: ReturnType<T.API.Report.Dashboard.GetSustainabilityData>;
    /** Extra Modal params */
    modal?: StyleModalProps;
    /** Callback for cancelation */
    onQuit?: () => void;
    /** Callback for confirmation */
    onValidate?: (data: Data) => void;
}

type Data = {
    /** The value of the surface */
    surface: number;
    /** The start of the period of time */
    from: string;
    /** The end of the period of time */
    to: string;
}

const get_default_date = (): Pick<Data, "from" | "to"> => {
    var currentDate = new Date();
    // Calculate the first day of the last year at midnight
    var from = new Date(currentDate);
    // Set the year to the previous year, month to January, and day to 1
    from.setFullYear(currentDate.getFullYear() - 1, 0, 1);
    // Set hours, minutes, seconds, and milliseconds to zero
    from.setHours(0, 0, 0, 0);
    // Calculate the last day of the previous year at 23:59:59
    var to = new Date(from);
    // Set the year to the current year, month to January, and day to 0 (last day of the previous year)
    to.setFullYear(from.getFullYear() + 1, 0, 0);
    // Set hours to 23, minutes to 59, seconds to 59, and milliseconds to 999
    to.setHours(23, 59, 59, 999);
    return { from: from.toISOString(), to: to.toISOString() };
}

const ReportSurface: React.FC<ReportSurfaceProps> = props => {
    const [forms] = H.useFormIds();
    const [errors, set_errors] = React.useState<T.Errors<Data>>({});
    const build_form = React.useMemo(() => forms[FP.BUILDING_FORM], [forms]);
    const [data, set_data] = React.useState<Data>({ surface: props.build_surface.surface, ...get_default_date() });

    const surface_label = React.useMemo<C.Form.NumFieldProps["label"]>(() => {
        if (props.build_surface.prop) return { _id: build_form, prop: props.build_surface.prop };
        return TC.GLOBAL_SURFACE;
    }, [build_form, props.build_surface.prop]);

    //#region Save
    const save = React.useCallback(() => {
        let new_errors = {} as typeof errors;

        let from = TB.getDate(data.from), to = TB.getDate(data.to);
        if (isNaN(data.surface)) new_errors.surface = TC.GLOBAL_REQUIRED_FIELD;
        else if (data.surface < 0) new_errors.surface = TC.GLOBAL_NUMBER_MUST_BE_POSITIVE;
        if (from === null) new_errors.from = TC.GLOBAL_REQUIRED_FIELD;
        if (to === null) new_errors.to = TC.GLOBAL_REQUIRED_FIELD;
        if (from && to && from.getTime() >= to.getTime()) {
            new_errors.to = TC.ERR_DATE_TO_LOWER_DATE_FROM;
            new_errors.from = TC.ERR_DATE_FROM_HIGHER_DATE_TO;
        }

        if (Object.keys(new_errors).length > 0) set_errors(new_errors);
        else props.onValidate?.(data);
    }, [data, props]);

    const save_button = React.useMemo(() => <C.Flex justifyContent="end">
        <C.Button onClick={save} icon="save" text={TC.GLOBAL_CONFIRM} />
    </C.Flex>, [save]);
    //#endregion

    //#region Errors
    React.useEffect(() => set_errors(p => ({ ...p, to: undefined })), [data.to]);
    React.useEffect(() => set_errors(p => ({ ...p, from: undefined })), [data.from]);
    React.useEffect(() => set_errors(p => ({ ...p, surface: undefined })), [data.surface]);
    //#endregion

    return <Modal
        {...props.modal}
        footer={save_button}
        onQuit={props.onQuit}
        size={props.modal?.size || "sm"}
        title={props.modal?.title || TC.SUSTAINABILITY_REPORT_MODAL}
    >
        <C.Form.NumField
            required
            value={data.surface}
            label={surface_label}
            error={{ code: errors.surface }}
            onChange={surface => set_data(p => ({ ...p, surface }))}
        />
        <BS.Row>
            <BS.Col>
                <C.Form.DateTime
                    required
                    value={data.from}
                    label={TC.GLOBAL_FROM}
                    error={{ code: errors.from }}
                    onChange={from => set_data(p => ({ ...p, from }))}
                />
            </BS.Col>
            <BS.Col>
                <C.Form.DateTime
                    required
                    value={data.to}
                    label={TC.GLOBAL_TO}
                    error={{ code: errors.to }}
                    onChange={to => set_data(p => ({ ...p, to }))}
                />
            </BS.Col>
        </BS.Row>
    </Modal>;
}

export default ReportSurface;