import _ from "lodash";
import React from "react";
import ReactDom from "react-dom";
import * as S from "../services";
import { v4 as uuid } from "uuid";
import { CRAFT_FILE_URL } from "./Constants.URL";
import { AnyObject, File as CustomFile } from "./Types"
import { uploadLocalFile } from "../services/user.service";
import { GLOBAL_GENERATING_FILE, GLOBAL_FAIL_GEN_FILE } from "./textCode";
import { BlankModal, renderAlert, renderLoader } from "../Components/Modal";
import FileUploadComp from "../Components/Formio/Components/Premium/FileUploadComp";
import { getObject, getNumber, getString, isFile, validString, mongoIdValidator, asyncArrayBufferToBase64, downloadFile, validObject } from "./ToolBox";

type ImportImgFnParams = {
    title?: string;
    label?: string;
    imageSize?: number;
    fileMaxSize?: string;
}

type ReportDownloadExtraParams = {
    name: string;
    extension: string;
    language?: number;
}

export const importNewImg = (params: ImportImgFnParams) => new Promise<CustomFile[] | null>((resolve, reject) => {
    let newParams: ImportImgFnParams = getObject(params, { imageSize: 250, fileMaxSize: "15MB" });
    let container = document.getElementById("modalContainer");
    if (container === null) reject(null);
    else new Promise(resolve => ReactDom.render(<BlankModal title={newParams.title} onQuit={() => resolve(null)} size="md">
        {/* @ts-ignore */}
        <FileUploadComp
            image
            setter={resolve}
            newStorage="local"
            label={getString(newParams.label)}
            imageSize={getNumber(newParams.imageSize, 250)}
            fileMaxSize={getString(newParams.fileMaxSize, "15MB")}
        />
    </BlankModal>, container))
        .then(images => {
            if (container !== null) ReactDom.unmountComponentAtNode(container);

            if (Array.isArray(images) && images.every(isFile) && images.length > 0) {
                let purgedImages = images
                    .map(file => _.omit(file, ["isAdded", "isB64"]))
                    .filter(isFile);

                resolve(purgedImages);
            }
            else resolve(null);
        });
});

export const fileForSend = (file: File | string) => new Promise<FormData>((resolve, reject) => {
    let formData = new FormData();
    if (typeof file === "string") {
        formData.append("file", file);
        resolve(formData);
    }
    else getB64FromFileObject(file).then(b64 => {
        formData.append("file", b64);
        resolve(formData);
    }).catch(reject);
});

export const uploadNewFile = (name: string, b64: string, _id: string) => new Promise<{ name: string, url: string }>((resolve, reject) => {
    if (!validString(name) || !validString(b64) || !mongoIdValidator(_id)) reject(null);
    else {
        let formData = new FormData();
        formData.append("file", b64);
        uploadLocalFile(_id, name, formData)
            .then(() => resolve({ name, url: CRAFT_FILE_URL(_id, name) }))
            .catch(reject);
    }
});

export const destructureFileListSingleFile = (fileList: FileList) => new Promise<CustomFile>((resolve, reject) => {
    if (fileList instanceof FileList) {
        let file = fileList.item(0);
        if (!file) reject(null);
        else {
            let { name, size, type } = file;
            getB64FromFileObject(file)
                .then(b64 => resolve({
                    size,
                    type,
                    url: b64,
                    storage: "local",
                    originalName: name,
                    name: `${uuid()}-${name}`,
                }))
                .catch(reject);
        }
    }
    else reject(null);
});

export const downloadJsReportFile = (templateName: string, options: ReportDownloadExtraParams, params: AnyObject) => new Promise((resolve, reject) => {
    let unmountLoader = renderLoader(GLOBAL_GENERATING_FILE);
    if (!validObject(params)) params = {};

    const handleError = (error?: any) => {
        unmountLoader();
        renderAlert({ delay: 10, type: "error", message: GLOBAL_FAIL_GEN_FILE });
        reject(error);
    }

    if (!validString(templateName) || !validObject(options)) handleError();
    else if (!validString(options.extension) || !validString(options.name)) handleError();
    else S.file_report({ template: templateName, params }).then(({ data }) => {
        if (data instanceof ArrayBuffer) asyncArrayBufferToBase64(data).then(base64 => {
            downloadFile(base64, options.name, options.extension);
            unmountLoader();
        }).catch(handleError);
        else handleError();
    }).catch(error => handleError(error));
});

export const getB64FromFileObject = (file: File) => new Promise<string>((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onerror = reject;
    reader.onload = () => {
        if (typeof reader.result === "string") resolve(reader.result);
        else resolve(arrayBufferToBase64(reader.result));
    }
});

export const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}