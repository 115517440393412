import * as T from "../Types";
import { LANG } from "../Data";
import { ImportFormat } from "../Types/ImportStruct";

/* @ts-ignore */
const LG_COLS: Record<T.LanguageProps, T.ElemFormat> = Object.fromEntries(LANG.ALL_PROPS.map(p => [p, { label: p }]));

export const format: ImportFormat<Props> = {
    ref: { label: "ref" },
    _id: { label: "_id" },
    prop: { label: "prop" },
    ...LG_COLS,
};

type Props = T.LanguageProps | "ref" | "_id" | "prop";