import React from "react";
import * as H from "../../hooks";
import * as C from "../../Common";
import { Alerts } from "./MiscModal";
import { TB, TC } from "../../Constants";
import { ReduxWrapper } from "../../Common";
import renderInContainer from "./getContainer";
import XLSX, { WorkBook, WorkSheet } from "xlsx";
import BlankModalReduxWrapper from "./BlankModal";
// import { CSV_TYPE_FILE, EXCEL_TYPE_FILE } from "../Gestion/Gestion.Constants";

export type ExcelModalImportFnProps = {
    label?: string;
    title?: string;
    onlyCsv?: boolean;
    required?: boolean;
    confirmText?: string;
    sheetSelect?: boolean;
    /** Force to use the 'raw' parameters when reading an excel/csv file */
    raw?: boolean;
}

export interface ExcelModalImportProps extends ExcelModalImportFnProps {
    onQuit?: () => void;
    onValidate?: (x?: WorkSheet | WorkBook) => void;
}

const ExcelModalImport: React.FC<ExcelModalImportProps> = ({ onQuit, onValidate, label, title, confirmText, required = true, sheetSelect = true, onlyCsv = false, ...props }) => {
    const lg = H.useLanguage();
    const [file, setFile] = React.useState<WorkBook>();
    const [selectedSheet, setSheet] = React.useState<string>();

    //#region Labels
    const validLabel = React.useMemo(() => label ? lg.getStaticText(label) : undefined, [label, lg]);
    //#endregion

    //#region Required
    const requiredStar = React.useMemo(() => required ? <span className="text-danger">*</span> : undefined, [required]);
    const allowConfirm = React.useMemo(() => required ? TB.validObject(file) && (sheetSelect ? TB.validString(selectedSheet) : true) : true, [file, selectedSheet, required, sheetSelect]);
    //#endregion

    //#region Input Handler
    const fileType = React.useMemo(() => onlyCsv ? ".csv" : ".xlsx,.csv", [onlyCsv]);
    // const advancedType = React.useMemo(() => onlyCsv ? [CSV_TYPE_FILE, "text/csv"] : [EXCEL_TYPE_FILE], [onlyCsv]);

    const onChangeFile = React.useCallback(e => {
        let file = e.target.files?.[0] ?? null;
        if (file === null) return;
        let reader = new FileReader();
        reader.onload = e => {
            if (e.target !== null && e.target?.result !== null && !TB.validString(e.target?.result)) {
                let data = new Uint8Array(e.target.result);
                let workbook = XLSX.read(data, { type: "array", raw: props.raw || onlyCsv, cellDates: true });
                if (Array.isArray(workbook?.SheetNames)) setFile(workbook);
            }
            else Alerts.failedFileUpload();
        }
        reader.readAsArrayBuffer(file);
    }, [onlyCsv, props.raw]);
    //#endregion

    //#region Sheet Selection
    React.useEffect(() => TB.validObject(file) ? setSheet(undefined) : undefined, [file]);
    React.useEffect(() => sheetSelect && file && Array.isArray(file.SheetNames) && file.SheetNames.length > 0 ? setSheet(file.SheetNames[0]) : undefined, [file, sheetSelect]);

    const SheetNames = React.useMemo(() => file?.SheetNames?.map?.(str => <option key={str} value={str}>{str}</option>), [file]);

    const sheetBody = React.useMemo(() => !sheetSelect || !TB.validObject(file) ? undefined : <div className="form-floating mt-3">
        <select value={selectedSheet ?? ""} onChange={e => setSheet(e.target.value)} className="form-select">
            {SheetNames}
        </select>
        <label className="mt-0">Sheet</label>
    </div>, [SheetNames, selectedSheet, file, sheetSelect]);
    //#endregion

    //#region Validation
    const validateImport = React.useCallback(() => onValidate?.(sheetSelect ? file?.Sheets?.[selectedSheet ?? ""] : file), [file, selectedSheet, sheetSelect, onValidate]);
    //#endregion

    //#region Modal Parts
    const body = React.useMemo(() => <div>
        <div className={validLabel ? "form-floating" : ""}>
            <input type="file" className="form-control" accept={fileType} onChange={onChangeFile} />
            {validLabel && <label className="mt-0">{validLabel}{requiredStar}</label>}
        </div>
        {SheetNames && SheetNames.length > 1 && sheetBody}
    </div>, [validLabel, requiredStar, SheetNames, sheetBody, fileType, onChangeFile]);

    const footer = React.useMemo(() => <C.Flex justifyContent="end">
        <C.Button
            disabled={!allowConfirm}
            onClick={validateImport}
            text={confirmText || TC.GLOBAL_CONFIRM}
        />
    </C.Flex>, [confirmText, allowConfirm, validateImport]);
    //#endregion

    return <BlankModalReduxWrapper
        size="sm"
        title={title || "Import"}
        onQuit={onQuit}
        footer={footer}
    >
        {body}
    </BlankModalReduxWrapper>
}

export default ExcelModalImport;

export const askExcelImport = (params?: ExcelModalImportFnProps) => new Promise<WorkBook | WorkSheet | undefined | null>(resolve => {
    let [render, dismount] = renderInContainer();
    if (!TB.validObject(params)) params = {};
    if (render && dismount) {
        let onQuit = () => dismount(() => resolve(null));
        let onValidate = (str?: WorkBook | WorkSheet) => dismount(() => resolve(str));
        render(<ReduxWrapper>
            <ExcelModalImport {...params} onQuit={onQuit} onValidate={onValidate} />
        </ReduxWrapper>);
    }
    else resolve(null);
});