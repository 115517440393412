import _ from 'lodash';
import "./FormulaEditor.scss";
import * as S from "../../../services";
import { Box } from "@material-ui/core";
import { FP, TB } from '../../../Constants';
import { useFormIds } from '../../../hooks';
import * as US from "../../../services/user.service";
import { InputProps, ComponentWrapper } from "../Input";
import { Alerts, Loader } from '../../../Components/Modal';
import LightTree from "../../../Components/NewDia/LightTree";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ArrowDropDown, AccountTree } from "@material-ui/icons";
import React, { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

const TAG_KEY = ".";
const ENTITY_KEY = "@";
const ALL_KEYS = [ENTITY_KEY, TAG_KEY];

const TREE_DROPDOWN = "tree";
const REGULAR_DROPDOWN = "dropdown";

export type FormulaEditorProps = InputProps & {
    objPaths?: string[];
    isTreePopUp?: boolean;
    isSwitchLock?: boolean;
}

const FormulaEditor: FC<FormulaEditorProps> = ({ onChange, isSwitchLock, isTreePopUp, objPaths, ...props }) => {
    //#region State
    const [forms] = useFormIds();
    const [icons, setIcons] = useState([]);
    const [nodes, setNodes] = useState([]);
    const [formula, setFormula] = useState("");
    const [cursorPos, setCursorPos] = useState(0);
    const [focusedTag, setFocusedTag] = useState();
    const [focusedNode, setFocusedNode] = useState();
    const [nodesLocation, setNodesLocation] = useState([]);
    const [dropDownMethod, setDropDownMethod] = useState(isTreePopUp ? TREE_DROPDOWN : REGULAR_DROPDOWN);

    const siteIdRef = useRef<string[]>(null);
    const inputRef = useRef<HTMLTextAreaElement>(null);
    //#endregion

    //#region Component props
    const isDisabled = useMemo(() => props.disabled || props.noOverride || !TB.mongoIdValidator(props.submissionId), [props.disabled, props.noOverride, props.submissionId]);
    //#endregion

    //#region Forms
    const validObjPaths = useMemo(() => {
        if (!Array.isArray(objPaths)) return [FP.ENTITY_FORM];
        let paths = objPaths.filter(FP.IS_VALID_RESOURCE);
        return paths.length === 0 ? [FP.ENTITY_FORM] : paths;
    }, [objPaths]);

    const iconFormId = useMemo(() => forms[FP.ICON_PATH], [forms]);
    const siteFormId = useMemo(() => forms[FP.SITE_FORM], [forms]);
    const entityFormId = useMemo(() => forms[FP.ENTITY_FORM], [forms]);
    //#endregion

    //#region Site
    const siteId = useMemo(() => TB.arrayWrapper(props.extraData?.site).filter(TB.mongoIdValidator), [props.extraData?.site]);

    const formatData = useCallback((formId: string, data: Record<string, any>) => {
        if (!TB.validObject(data)) data = {};
        /* TODO more general formatting + add a name to eventual data without name property*/
        switch (formId) {
            case forms[FP.ENTITY_FORM]:
                let { th_tag, th_tin_tag, th_tout_tag, th_power_tag, th_cons_tag, th_volume_tag, tag, tag_ppv, gas_tag, water_tag } = data || {};
                let allTags = _.concat(th_tag, th_tin_tag, th_tout_tag, th_power_tag, th_cons_tag, th_volume_tag, tag, tag_ppv, gas_tag, water_tag)
                    .filter(TB.validObject)
                    .filter(({ station_id, tag_id }) => [station_id, tag_id].every(x => typeof x === "number"));
                return allTags;

            default: return [];
        }
    }, [forms]);

    useEffect(() => {
        if (siteId.length > 0 || TB.mongoIdValidator(props.submissionId)) S.getContextSites({ roots: props.submissionId || siteId }).then(({ data }) => {
            let sitesIds = data.map(s => s.value);
            if (sitesIds.length === 0) setNodes([]);
            else {
                siteIdRef.current = sitesIds;
                let formsIds = validObjPaths.map(path => forms[path]).filter(TB.mongoIdValidator);

                US.advancedSearch(sitesIds, undefined, false, undefined, formsIds, undefined).then(({ data }) => {
                    if (Array.isArray(data)) setNodes(data.map(({ data, ...r }) => {
                        let allTags = formatData(r.form, data);
                        return { ...r, data: { allTags, ...data } };
                    }))
                    else setNodes([]);
                }).catch(Alerts.loadError);
            }

        }).catch(Alerts.loadError);
    }, [siteId, entityFormId, siteFormId, forms, validObjPaths, formatData, props.extraData, props.submissionId]);
    //#endregion

    //#region Entities
    const nodesName = useMemo(() => Object.fromEntries(
        (Array.isArray(nodes) ? nodes : []).map(({ _id, data }) => [_id, data?.name])
    ), [nodes]);

    const getTagsName = useCallback((entityId, tagId) => {
        if (!TB.validString(tagId)) return "";
        if (!Array.isArray(nodes) || !TB.mongoIdValidator(entityId)) return tagId;
        let entity = nodes.filter(({ _id }) => _id === entityId)[0];
        if (!TB.validObject(entity) || !Array.isArray(entity?.data?.allTags)) return tagId;
        let name = entity.data.allTags.filter(tag => tag?.tag_id === parseInt(tagId))[0]?.name;
        return TB.validString(name) ? `.[${name}]` : tagId;
    }, [nodes]);

    useEffect(() => {
        const getLocation = async () => {
            if (nodes.length === 0) return [];
            let reply = await US.itemLocater(nodes.map(({ _id }) => _id), false);
            return Array.isArray(reply?.data) ? reply.data : null;
        }
        if (Array.isArray(nodes)) getLocation().then(setNodesLocation);
    }, [nodes]);

    const locations = useMemo(() => Object.fromEntries(
        /* TODO do better */
        (Array.isArray(nodesLocation) ? nodesLocation : [])
            .map(({ id, building }) => [id, building?.map(({ data }) => data?.name)?.join(', ')])
    ), [nodesLocation]);
    //#endregion

    //#region Icons
    const iconsList = useMemo(() => Object.fromEntries(
        (Array.isArray(icons) ? icons : []).map(({ _id, data }) => [_id, data?.icon?.[0]?.url])
    ), [icons]);

    useEffect(() => {
        const getIcons = async () => {
            let ids = nodes.map(({ data }) => data?.selectIcon?._id).filter(TB.mongoIdValidator);
            if (ids.length === 0) return [];
            let reply = await US.getManySubmissionsFromFilter({ form: iconFormId, _id: ids });
            return Array.isArray(reply.data) ? reply.data : null;
        }
        if (Array.isArray(nodes) && TB.mongoIdValidator(iconFormId)) getIcons().then(setIcons);
    }, [nodes, iconFormId]);
    //#endregion

    //#region Formula
    useEffect(() => TB.validString(props.value) ? setFormula(props.value) : undefined, [props.value]);

    useEffect(() => {
        if (TB.validString(props.value) && inputRef.current) inputRef.current.value = props.value;
    }, [props.value]);

    const parseFormula = useCallback(formula => {
        var form = [], i = 0, temp = '', oid = '', tid = '';

        while (i < formula.length) {
            if (formula[i] === '@') {
                if (temp.length > 0) {
                    form.push(temp);
                    temp = '';
                }
                oid = formula.substring(i + 2, i + 26);
                i = i + 27;
                if (formula[i] === ".") {
                    tid = formula.substring(i + 2, i + 2 + formula.substring(i + 2, formula.length).indexOf('}'));
                    i = i + 2 + formula.substring(i + 2, formula.length).indexOf('}') + 1;
                    form.push([oid, tid])
                }
                else form.push([oid, null]);
            }
            else temp += formula[i];
            i++
        }
        if (temp.length > 0) form.push(temp);
        return form;
    }, []);

    /* @ts-ignore */
    const { translatedFormula, hasUnknown } = useMemo<{ translatedFormula: string | ReactNode, hasUnknown: boolean }>(() => {
        let hasUnknown = false;

        const replaceId = string => {
            if (TB.mongoIdValidator(string)) {
                let name = nodesName?.[string];
                if (!TB.validString(name)) hasUnknown = true;
                return `[${nodesName?.[string]}]`;
            }
            return string;
        }

        const replaceTag = (id, tagNum) => {
            if (TB.validString(tagNum)) {
                let name = getTagsName(id, tagNum);
                if (!TB.validString(name)) hasUnknown = true;
                return name;
            }
            return tagNum ?? "";
        }

        if (!Array.isArray(nodes) && nodes !== null) return { hasUnknown: false, translatedFormula: <div className="h-100 w-100"><Loader isPopUp={false} /></div> };
        if (typeof formula !== "string") return '';

        let parsedFormula = parseFormula(formula);
        let translatedFormula = parsedFormula.map(elem => Array.isArray(elem) ? replaceId(elem?.[0] || "") + replaceTag(elem?.[0], elem?.[1]) : elem)
            .map(str => str?.trim?.() || "")
            .join(' ');

        return { translatedFormula, hasUnknown };
    }, [formula, nodesName, nodes, getTagsName, parseFormula]);
    //#endregion

    //#region Cursor && String Comparison
    const checkSimilarity = useCallback((str1, str2) => TB.checkStringSimilarity(str1, str2) >= 0.5, []);
    const getCursorPosition = useCallback(() => setCursorPos(inputRef.current?.selectionStart || 0), []);
    const checkIncludes = useCallback((str1, str2) => ([str1, str2].every(str => typeof str === "string") ? str1.toLowerCase().includes(str2.toLowerCase()) : false), []);

    const lastKeyCaract = useMemo(() => _.maxBy(ALL_KEYS.map(key => ({ key, index: formula?.slice?.(0, cursorPos)?.lastIndexOf?.(key) })), "index"), [formula, cursorPos]);
    //#endregion

    //#region DropDown Switch
    const toggleOptions = useMemo(() => [
        { icon: <ArrowDropDown />, value: REGULAR_DROPDOWN },
        { icon: <AccountTree />, value: TREE_DROPDOWN },
    ], []);

    /* @ts-ignore */
    const dropDownMenuSwitch = useMemo(() => isSwitchLock ? undefined : <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", '& > :not(style) + :not(style)': { mt: 2 } }}>
        <ToggleButtonGroup value={dropDownMethod} onChange={(e, val) => setDropDownMethod(val)} exclusive>
            {toggleOptions.map(({ icon, value }) => <ToggleButton value={value} key={value} aria-label={value}>
                {icon}
            </ToggleButton>)}
        </ToggleButtonGroup>
    </Box>, [dropDownMethod, toggleOptions, isSwitchLock]);

    const removeKeyCursorPos = useCallback(() => {
        let newFormula = formula.substring(0, cursorPos - 1) + formula.substring(cursorPos);
        setFormula(newFormula);
    }, [formula, cursorPos]);
    //#endregion

    //#region Entity
    const searchStringNode = useMemo(() => {
        if (typeof formula !== "string") return '';
        // Try to find the closest key before the position of the cursor
        let lastKeyBeforeCursor = formula.slice(0, cursorPos).lastIndexOf(ENTITY_KEY);
        // None Found
        if (lastKeyBeforeCursor === -1) return "";
        let endOfString = formula.slice(lastKeyBeforeCursor + 1);
        // Check there already is an id after that Key, if so, then no search filter
        let possibleId = _.flatten(endOfString.split("{").map(x => x.split('}'))).filter(str => str.trim().length > 0)[0];
        if (TB.mongoIdValidator(possibleId)) return { id: possibleId };
        // Return the string between the last key and the cursor
        return formula.slice(lastKeyBeforeCursor + 1, cursorPos);
    }, [formula, cursorPos]);

    const onPickEntity = useCallback(id => {
        // Delete the searchStringNode, and insert the id
        let tags = nodes.filter(({ _id }) => _id === id)?.[0]?.data?.allTags;
        /* @ts-ignore */
        let newFormula = formula.substring(0, cursorPos - searchStringNode.length) + `{${id}}` + formula.substring(cursorPos) + (tags?.length > 0 ? '.' : ' ');
        setFormula(newFormula);
        setCursorPos(newFormula.length - 1);
    }, [formula, cursorPos, searchStringNode, nodes]);

    /* @ts-ignore */
    const showOptionsEntity = useMemo(() => ((lastKeyCaract?.key === ENTITY_KEY && lastKeyCaract?.index >= 0) || searchStringNode.length > 0) && !TB.mongoIdValidator(searchStringNode?.id), [lastKeyCaract, searchStringNode]);

    const showTreeEntity = useMemo(() => showOptionsEntity && dropDownMethod === TREE_DROPDOWN, [showOptionsEntity, dropDownMethod]);
    const showDropDownEntity = useMemo(() => showOptionsEntity && dropDownMethod === REGULAR_DROPDOWN, [showOptionsEntity, dropDownMethod]);

    const nodesFiltered = useMemo(() => {
        const sortByCloseness = (a, b) => {
            /* @ts-ignore */
            let aResemblance = TB.checkStringSimilarity(a?.data?.name, searchStringNode);
            /* @ts-ignore */
            let bResemblance = TB.checkStringSimilarity(b?.data?.name, searchStringNode);
            if (aResemblance > bResemblance) return -1;
            if (aResemblance < bResemblance) return 1;
            return 0;
        }

        if (!Array.isArray(nodes)) return [];
        /* @ts-ignore */
        if (searchStringNode.length === 0) return nodes;
        return nodes
            .filter(({ _id, data }) => [checkIncludes, checkSimilarity].some(funct => funct(data?.name, searchStringNode) || funct(locations?.[_id], searchStringNode)))
            .sort(sortByCloseness);
    }, [nodes, searchStringNode, locations, checkSimilarity, checkIncludes]);

    const nodesList = useMemo(() => {
        if (nodesFiltered.length === 0) return <div>NO DATA ENTITY TODO</div>
        return nodesFiltered.map(({ _id, data }) => <div id={`nodeOption${_id}`} className={"option entity " + (focusedNode === _id ? "focused" : "")} key={_id}>
            <div className="imgContainer" style={{ backgroundColor: data?.color || "transparent" }}>
                <img src={iconsList?.[data?.selectIcon?._id]} alt={data?.typeEnergie} />
            </div>
            <p onClick={() => onPickEntity(_id)}>{data?.name} {typeof locations?.[_id] === "string" ? `(${locations?.[_id]})` : ""}</p>
        </div>);
    }, [nodesFiltered, iconsList, focusedNode, locations, onPickEntity]);

    const dropDownEntities = useMemo(() => {
        if (!showDropDownEntity) return;
        return <div className="dropdownList node">{nodesList}</div>
    }, [showDropDownEntity, nodesList]);
    //#endregion

    //#region Tag KEEP CHECKING FROM HERE
    const searchStringTag = useMemo(() => {
        if (typeof formula !== "string") return '';
        // No entityTag was given before
        /* @ts-ignore */
        if (!TB.mongoIdValidator(searchStringNode?.id)) return '';
        // There is no '.' in the formula
        let lastKeyBeforeCursor = formula.slice(0, cursorPos).lastIndexOf(TAG_KEY);

        if (lastKeyBeforeCursor === -1) return "";
        // If there was another tag key before the current tag key but no entity key in between
        if (formula.slice(0, lastKeyBeforeCursor).lastIndexOf(TAG_KEY) > formula.slice(0, lastKeyBeforeCursor).lastIndexOf(ENTITY_KEY)) return {};

        let endOfString = formula.slice(lastKeyBeforeCursor + 1);
        let possibleTag = _.flatten(endOfString.split("{").map(x => x.split('}'))).filter(str => str.trim().length > 0)[0] || "";
        if (possibleTag.match(/^\d+$/g)) return { tag: possibleTag };
        return formula.slice(lastKeyBeforeCursor + 1, cursorPos);
    }, [formula, cursorPos, searchStringNode]);

    /* @ts-ignore */
    const showDropDownTag = useMemo(() => ((lastKeyCaract?.key === TAG_KEY && lastKeyCaract?.index >= 0) || searchStringTag?.length > 0) && typeof searchStringTag === "string", [lastKeyCaract, searchStringTag]);

    const onPickTag = useCallback(tag_id => {
        /*  @ts-ignore */
        let newFormula = formula.substring(0, cursorPos - searchStringTag.length) + `{${tag_id}}` + formula.substring(cursorPos) + " ";
        setFormula(newFormula);
        setCursorPos(newFormula.length - 1);
    }, [formula, cursorPos, searchStringTag]);

    const tagOptions = useMemo(() => {
        const sortByCloseness = (a, b) => {
            /* @ts-ignore */
            let aResemblance = TB.checkStringSimilarity(a?.name, searchStringTag);
            /* @ts-ignore */
            let bResemblance = TB.checkStringSimilarity(b?.name, searchStringTag);
            if (aResemblance > bResemblance) return -1;
            if (aResemblance < bResemblance) return 1;
            return 0;
        }

        /* @ts-ignore */
        if (!TB.mongoIdValidator(searchStringNode?.id)) return [];
        /* @ts-ignore */
        let allTags = nodes?.filter?.(({ _id }) => _id === searchStringNode.id)?.[0]?.data?.allTags || [];
        if (!Array.isArray(allTags)) return [];
        if (typeof searchStringTag !== "string" || searchStringTag.length === 0) return allTags;
        return allTags.filter(({ name }) => [checkIncludes, checkSimilarity].some(funct => funct(name, searchStringTag))).sort(sortByCloseness);
    }, [searchStringNode, nodes, searchStringTag, checkSimilarity, checkIncludes]);

    const tagList = useMemo(() => {
        if (tagOptions.length === 0) return <div>NO DATA TAG TODO</div>;
        return tagOptions.map(({ comment_, name, tag_id }) => <div className={'option tag ' + (focusedTag === tag_id ? "focused" : "")} key={tag_id}>
            <p onClick={() => onPickTag(tag_id)}>{`${name} (${comment_})`}</p>
        </div>);
    }, [tagOptions, focusedTag, onPickTag]);

    const dropDownTags = useMemo(() => {
        if (!showDropDownTag) return;
        return <div className='dropdownList'>{tagList}</div>
    }, [showDropDownTag, tagList]);
    //#endregion

    //#region DropDown Selection
    useEffect(() => showDropDownEntity ? undefined : setFocusedNode(null), [showDropDownEntity]);
    useEffect(() => nodesFiltered.filter(({ _id }) => _id === focusedNode).length === 0 ? setFocusedNode(null) : undefined, [nodesFiltered, focusedNode]);

    useEffect(() => showDropDownTag ? undefined : setFocusedTag(null), [showDropDownTag]);
    useEffect(() => tagOptions.filter(({ tag_id }) => tag_id === focusedTag).length === 0 ? setFocusedTag(null) : undefined, [tagOptions, focusedTag]);

    const handleKeyboardDropDown = useCallback(e => {
        const handleKeyBoard = (array, itemKey, focusItem, setFocus, onPick, validateKey, code) => {
            if (arrowKeys.includes(code)) {
                e.preventDefault();
                let items = array.map(item => item?.[itemKey]);
                let id;
                switch (code) {
                    case "ArrowUp":
                        if (validateKey(focusItem)) {
                            let currentIndex = items.indexOf(focusItem);
                            let newIndex = currentIndex > 0 ? currentIndex - 1 : array.length - 1;
                            id = array?.[newIndex]?.[itemKey];
                            setFocus(id);
                        }
                        else {
                            id = array?.[array.length - 1]?.[itemKey];
                            setFocus(id);
                        }
                        break;
                    case "ArrowDown":
                        if (validateKey(focusItem)) {
                            let currentIndex = items.indexOf(focusItem);
                            let newIndex = currentIndex < array.length - 1 ? currentIndex + 1 : 0;
                            id = array?.[newIndex]?.[itemKey];
                            setFocus(id);
                        }
                        else {
                            id = array?.[0]?.[itemKey];
                            setFocus(id);
                        }
                        break;
                    case "Enter":
                        if (validateKey(focusItem)) onPick(focusItem);
                        break;
                    default:
                        break;
                }

                if (TB.mongoIdValidator(id)) {
                    let dropdownDOM = document.querySelector(".dropdownList.node");
                    if (dropdownDOM !== null) {
                        let optionDOM = dropdownDOM.querySelector(`#nodeOption${id}`);
                        /* @ts-ignore */
                        if (optionDOM !== null) optionDOM.scrollIntoViewIfNeeded?.();
                    }
                }
            }
        }

        let arrowKeys = ["ArrowUp", "ArrowDown", "Enter"];

        if (showDropDownEntity) handleKeyBoard(nodesFiltered, '_id', focusedNode, setFocusedNode, onPickEntity, TB.mongoIdValidator, e.code);
        else if (showDropDownTag) handleKeyBoard(tagOptions, "tag_id", focusedTag, setFocusedTag, onPickTag, str => str?.toString?.()?.match?.(/^\d+$/g), e.code);
    }, [showDropDownEntity, nodesFiltered, focusedNode, focusedTag, showDropDownTag, tagOptions, onPickEntity, onPickTag]);
    //#endregion

    //#region Translator Formula
    const translatedPanel = useMemo(() => {
        if (typeof translatedFormula === "string") return <textarea value={translatedFormula} disabled={true} />;
        return translatedFormula;
    }, [translatedFormula]);
    //#endregion

    //#region Text Area
    const textAreaProps = useMemo(() => ({
        onClick: getCursorPosition,
        onKeyUp: getCursorPosition,
        onKeyDown: handleKeyboardDropDown,
        disabled: isDisabled || formula === undefined,
    }), [formula, isDisabled, getCursorPosition, handleKeyboardDropDown]);
    //#endregion

    return <ComponentWrapper {...props} disabled={isDisabled}>
        {dropDownMenuSwitch}
        <div className='formulaJool'>
            <div className="inputFormula mainPanel">
                <textarea onBlur={() => onChange?.(formula)} ref={inputRef} value={formula} onChange={e => setFormula(e.target.value)} {...textAreaProps} />
                {dropDownEntities}
                {dropDownTags}
            </div>
            <div className="translatedFormula mainPanel">
                {translatedPanel}
            </div>
        </div>
        {hasUnknown && <div className='d-flex align-items-center alert alert-danger fs-85'>
            <i className='fa fa-exclamation-triangle mr-2'></i>
            <span className='flex-grow-1'>Certains Id/tag ne sont plus existant</span>
        </div>}
        <div>
            {/* @ts-ignore */}
            {showTreeEntity && <LightTree
                modal
                root={siteIdRef.current?.[0]}
                onClose={removeKeyCursorPos}
                limitedForms={[FP.ENTITY_FORM]}
                onValidate={id => onPickEntity(id)}
                style={{ size: "xl", title: "Selection" }}
            />}
        </div>
    </ComponentWrapper>;
}

export default FormulaEditor;