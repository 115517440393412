import React from "react";
import Report from "./Report";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import * as S from "../../../services";
import { Training } from "./Configuration";
import { DS, T, TB, TC } from "../../../Constants";

//#region Types
type PredValidationInfo = ReturnType<T.API.Utils.Predictions.PredValidationInfo>;

export type ValidationProps = {
    /** The _id of the prediction */
    prediction: string;
    /** The _id of the original prediction, if this one is an updated version */
    og_prediction?: string;
    /** The _id of the dataset source */
    dataset: string;
    /** The training results after the validation */
    training: Training;
    /** Callback after the validation & creation of the datasets */
    validated: (datasets: T.DataSet[]) => void;
    /** Update the footer */
    set_footer: (footer: React.ReactElement) => void;
}

type Preset = {
    /** The label reference for the default name of the prediction preset */
    name: string;
    /** The description reference for the preset */
    description: string;
    /** Does is depends on a thermal analysis ? */
    is_thermal: boolean;
    /** The unique code id of the preset */
    code: "delta" | T.DataSet["pred_type"],
    /** Does this preset requires another preset to be activated */
    requires?: T.DataSet["pred_type"][];
}

const NRJ_TYPES = DS.DATASETS.type;
//#endregion

const PRESETS: Preset[] = [
    { name: TC.PRED_PRESET_NAME_MID, code: "median", description: TC.PRED_PRESET_DESC_MID, is_thermal: false },
    { name: TC.PRED_PRESET_NAME_HIGH, code: "upper", description: TC.PRED_PRESET_DESC_HIGH, is_thermal: false },
    { name: TC.PRED_PRESET_NAME_LOW, code: "lower", description: TC.PRED_PRESET_DESC_LOW, is_thermal: false },
    { name: TC.PRED_PRESET_NAME_DELTA, code: "delta", description: TC.PRED_PRESET_DESC_DELTA, is_thermal: false, requires: ["upper"] },
    { name: TC.PRED_PRESET_NAME_THERMAL, code: "thermal", description: TC.PRED_PRESET_DESC_THERMAL, is_thermal: true },
    { name: TC.PRED_PRESET_NAME_COLD, code: "cold", description: TC.PRED_PRESET_DESC_COLD, is_thermal: true },
    { name: TC.PRED_PRESET_NAME_BASELINE, code: "baseline", description: TC.PRED_PRESET_DESC_BASELINE, is_thermal: true },
    { name: TC.PRED_PRESET_NAME_ACTIVITY, code: "activity", description: TC.PRED_PRESET_DESC_ACTIVITY, is_thermal: true },
    { name: TC.PRED_PRESET_NAME_OPENINGS, code: "opening", description: TC.PRED_PRESET_DESC_OPENINGS, is_thermal: true },
];

const Validation: React.FC<ValidationProps> = props => {
    const lg = H.useLanguage();
    const [errors, set_errors] = React.useState<T.Errors<Record<Preset["code"], string>>>({});
    const [presets, set_presets] = React.useState<(Preset & Record<"active", boolean>)[]>([]);
    const [info, set_info, status] = H.useAsyncState<PredValidationInfo>({ auto_update: false, dataset_name: "", energy: "OTHER", existing: [], is_thermal: false });

    const update_disabled = React.useMemo(() => info.existing.map(e => e.code), [info.existing]);

    React.useEffect(() => {
        if (status === "done") {
            let type_traduction = NRJ_TYPES.filter(t => t.value === info.energy)[0]?.label;
            let new_presets: typeof presets = PRESETS.map(p => {
                let existing_preset = info.existing.filter(e => e.code === p.code)[0];
                if (p.code === "delta") return {
                    ...p,
                    active: !!existing_preset,
                    name: existing_preset?.name || `[${lg.getStaticText(TC.ALARM_TITLE).toUpperCase()}] ${info.dataset_name} - ${lg.getStaticText(p.name, type_traduction)}`,
                }
                else return {
                    ...p,
                    active: !!existing_preset,
                    name: existing_preset?.name || `${info.dataset_name} - ${lg.getStaticText(p.name, type_traduction)}`,
                };
            });
            set_presets(info.is_thermal ? new_presets : new_presets.filter(p => !p.is_thermal));
        }
    }, [info.is_thermal, lg, status, info.energy, info.existing, info.dataset_name]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.predValidationInfo({ prediction: props.prediction, former_prediction: props.og_prediction })
            .then(({ data }) => isSubscribed && set_info(data, "done"))
            .catch(() => isSubscribed && set_info({ auto_update: false, existing: [], dataset_name: "", energy: "OTHER", is_thermal: false }, "error"));
        return () => {
            isSubscribed = false;
            set_info({ auto_update: false, existing: [], dataset_name: "", energy: "OTHER", is_thermal: false }, "load");
        }
    }, [props.prediction, props.og_prediction, set_info]);

    const change = React.useMemo(() => ({
        set_frequency: (frequency?: string) => {
            let freq = TB.splitFrequency(frequency);
            if (!freq) set_info(p => ({ ...p, frequency: undefined }));
            else S.updatePredUpdateFrequency({ prediction: props.prediction, frequency })
                .then(() => set_info(p => ({ ...p, frequency })))
                .catch(M.Alerts.updateError);
            // S.togglePredUpdate
        },
        toggle_frequency: (save: boolean) => {
            S.togglePredUpdate(props.prediction).then(() => {
                // Save the KPI, frequency default is one month
                if (save) set_info(p => ({ ...p, auto_update: true, frequency: "6M" }));
                // Do not save the KPI
                else set_info(p => ({ ...p, auto_update: false, frequency: undefined }));
            }).catch(M.Alerts.updateError);
        },
        name: (code: Preset["code"], name: string) => {
            set_errors(p => ({ ...p, [code]: undefined }));
            set_presets(prev => prev.map(p => p.code === code ? { ...p, name } : p))
        },
        active: (code: Preset["code"], active: boolean) => {
            // The preset this code needs to be activated
            let required_codes = PRESETS.filter(p => p.code === code)[0]?.requires || [];
            // The presets who needs this code to stay activated
            let dependant_codes = PRESETS.filter(p => (p.requires || []).includes(code as any)).map(p => p.code);
            // Manage errors
            set_errors(p => ({ ...p, [code]: undefined }));
            // Manage the new state
            set_presets(prev => prev.map(p => {
                // The preset switched by the user
                if (p.code === code) return { ...p, active };
                // This preset is needed if the current toggled preset is set as 'active'
                else if (active && required_codes.includes(p.code as any) && !p.active) return { ...p, active: true };
                // This preset can't be active anymore if the current preset isn't active anymore
                else if (!active && dependant_codes.includes(p.code as any) && p.active) return { ...p, active: false };
                else return p;
            }));
        },
    }), [props.prediction, set_info]);

    const save_datasets = React.useCallback(() => {
        let new_errors = {} as typeof errors;
        for (let preset of presets) {
            if (preset.active && !TB.validString(preset.name)) new_errors[preset.code] = TC.GLOBAL_REQUIRED_FIELD;
        }
        if (Object.keys(new_errors).length > 0) set_errors(new_errors);
        else {
            let params = {
                dataset: props.dataset,
                prediction: props.prediction,
                og_prediction: props.og_prediction,
                create_delta: presets.filter(p => p.active && p.code === "delta")?.[0]?.name,
                to_create: presets.filter(p => p.active && p.code !== "delta").map(p => ({ code: p.code, name: p.name })),
            } as Parameters<typeof S.predictionDatasets>[0];

            S.predictionDatasets(params)
                .then(({ data }) => props.validated?.(data))
                .catch(M.Alerts.updateError);
        }
    }, [presets, props]);

    React.useEffect(() => props.set_footer(
        <C.Flex className="mt-3" justifyContent="end">
            <C.Button icon="save" onClick={save_datasets} text={TC.GLOBAL_SAVE} />
        </C.Flex>
    ), [save_datasets, props]);

    return <C.Spinner status={status}>

        <Report training={props.training} prediction={props.prediction} />

        <div>
            <C.Title level={3} className="my-3" text={props.og_prediction ? TC.PRED_VALIDATE_DATASET_TO_UPDATE : TC.PRED_VALIDATE_DATASET_TO_CREATE} />

            <BS.Row className="g-2 mb-2 fw-bold">
                <BS.Col md={7}>
                    {lg.getStaticText(TC.GLOBAL_NAME)}
                </BS.Col>
                <BS.Col md={4}>
                    {lg.getStaticText(TC.DESCRIPTION)}
                </BS.Col>
                <BS.Col md={1}></BS.Col>
            </BS.Row>

            {presets.map(p => <BS.Row className="g-2 mb-1 align-items-center" key={p.code}>
                <BS.Col md={7}>
                    <C.Form.TextField
                        value={p.name}
                        noBottomMargin
                        disabled={update_disabled.includes(p.code)}
                        onChange={name => change.name(p.code, name)}
                    />
                </BS.Col>
                <BS.Col md={4}>
                    {lg.getStaticText(p.description)}
                </BS.Col>
                <BS.Col md={1}>
                    <C.Form.CheckBox
                        noBottomMargin
                        value={p.active}
                        check_type="switch"
                        onChange={active => change.active(p.code, active)}
                        disabled={update_disabled.includes(p.code)}
                    />
                </BS.Col>
            </BS.Row>)}

            <div className="mt-3">
                <C.Title level={3} className="mb-3" text={TC.PRED_VALIDATE_UPDATE} />

                <C.Form.CheckBox
                    check_type="switch"
                    labelPosition="left"
                    value={info.auto_update}
                    onChange={change.toggle_frequency}
                    label={TC.PRED_VALIDATE_AUTO_UPDATE}
                />

                {info.auto_update && <C.Form.Frequency
                    noReset
                    labelPosition="left"
                    value={info.frequency}
                    onChange={change.set_frequency}
                    label={TC.PRED_VALIDATE_UPDATE_FREQ}
                />}
            </div>
        </div>
    </C.Spinner>
};

export default Validation;