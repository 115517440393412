import "./Slide.scss";
import React from "react";
import * as M from "../../../Modal";
import * as BS from "react-bootstrap";
import * as H from "../../../../hooks";
import * as C from "../../../../Common";
import { T, TB } from "../../../../Constants";

type SlideMenuProps = T.DiaMenuProps & { open: boolean, onClose: (closed: boolean) => void };

const Menu: React.FC<SlideMenuProps> = ({ open, node, onClose, onPick, ...props }) => {
    const lg = H.useLanguage();

    //#region Colors
    const { color, isWhite } = React.useMemo(() => ({
        color: TB.getColor(node.color) || "",
        isWhite: TB.isColorWhite(node.color),
    }), [node.color]);
    //#endregion

    //#region Image
    const icon = React.useMemo(() => TB.iconIdToUrl(node.icon), [node.icon]);

    const popUpImg = React.useCallback(() => {
        if (node.picture) M.renderCarousel({ images: [{ title: node.label, src: node.picture }] });
    }, [node.picture, node.label]);
    //#endregion

    //#region Render Button
    const renderButton = React.useCallback((right: T.DiaRight) => <C.Button
        key={right.code}
        className="singleButton"
        title={lg.getStaticText(right.code)}
        children={<i className={"fa fa-" + right.icon} />}
        variant={"outline-" + (isWhite ? "dark" : "light")}
        onClick={() => {
            onPick?.(right.code, node);
            onClose?.(false)
        }}
    />, [lg, onPick, onClose, isWhite, node]);
    //#endregion

    return <BS.Offcanvas show={open} onHide={() => onClose(false)} placement="end" backdropClassName=''>
        <BS.Offcanvas.Header closeButton closeVariant={isWhite ? undefined : "white"} onClick={e => e.stopPropagation()} style={{ background: color }}>
            <C.Flex direction="column">
                <BS.Offcanvas.Title className={"fs-180 fw-bold " + (isWhite ? "light" : "dark")}>{node.label}</BS.Offcanvas.Title>
                {node.path && <BS.Offcanvas.Title className={isWhite ? "light" : "dark"}>({lg.getStaticText(node.path)})</BS.Offcanvas.Title>}
            </C.Flex>
        </BS.Offcanvas.Header>
        <BS.Offcanvas.Body onClick={e => e.stopPropagation()} style={{ background: color }}>
            <div className="menuBody">
                {icon && <img className="background-img" src={icon} alt="" />}
                <div className="position-relative" style={{ zIndex: 4 }}>
                    <div className="buttonGroups my-2">
                        {node.rights.map(renderButton)}
                    </div>
                    {node.picture && <div className="buildImgWrapper">
                        <img className={`buildingImg ${isWhite ? "dark" : ""}`} src={node.picture} alt="" title={node.label} onClick={popUpImg} />
                    </div>}
                </div>
            </div>
            <div className="h-100"></div>
        </BS.Offcanvas.Body>
    </BS.Offcanvas>
}

export default Menu;