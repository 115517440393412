import React from "react";
import * as H from "../../hooks";
import * as C from "../../Common";
import * as BS from "react-bootstrap";
import * as CP from "../../Components";
import * as DOM from "react-router-dom";
import { RESOURCE as R } from "../../Constants";

type AuthCardLayoutProps = {
    /** Show the footer */
    footer?: boolean;
    /** Content to show on the left side of the Card */
    leftSideContent?: React.ReactNode;
};

const AuthCardLayout: React.FC<AuthCardLayoutProps> = props => {
    H.useAuth({ forcedLog: false });

    const footerContent = React.useMemo(() => <p className="mb-0 mt-4 mt-md-5 fs--1 fw-semi-bold text-white opacity-75">
        Read our{" "}
        <DOM.Link className="text-decoration-underline text-white" to="#!">
            terms
        </DOM.Link>{" "}
        and{" "}
        <DOM.Link className="text-decoration-underline text-white" to="#!">
            conditions{" "}
        </DOM.Link>
    </p>, []);

    const [bgShape, shape1] = React.useMemo(() => [R.BG_SHAPE, R.SHAPE_1, R.AISET_LOGO].map((r) => R.RESOURCE_URL(r)), []);

    return <C.Section fluid className="py-0">
        <C.AlertsContainer />
        <BS.Row className="g-0 min-vh-100 flex-center">
            <BS.Col lg={8} xl={5} className="py-3 position-relative">
                <img className="bg-auth-circle-shape" src={bgShape} alt="" width="250" />
                <img className="bg-auth-circle-shape-2" src={shape1} alt="" width="150" />

                <BS.Card className="overflow-hidden z-index-1">
                    <BS.Card.Body className="p-0">
                        <BS.Row className="h-100 g-0">
                            <BS.Col md={5} className="text-white text-center /*bg-card-gradient*/">
                                <div className="position-relative p-4 pt-md-5 pb-md-7 h-100">
                                    <div className="z-index-1 position-relative light h-100 d-flex align-items-center justify-content-center">
                                        <img src="/api/file/main" alt="" width="100%" height="auto" />
                                    </div>
                                </div>
                                <div className="mt-3 mb-4 mt-md-4 mb-md-5 light">
                                    {props.leftSideContent}
                                    {props.footer && footerContent}
                                </div>
                            </BS.Col>
                            <BS.Col md={7} className="d-flex align-items-center justify-content-center" >
                                <div className="p-4 p-md-5 flex-grow-1">
                                    <div className="position-absolute top-0 end-0 p-2">
                                        <CP.NavBar.TOP.LanguageDropdown />
                                    </div>
                                    <DOM.Outlet />
                                </div>
                            </BS.Col>
                        </BS.Row>
                    </BS.Card.Body>
                </BS.Card>
            </BS.Col>
        </BS.Row>
    </C.Section>
};

export default AuthCardLayout;
