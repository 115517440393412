import * as S from "../services";
import { T, TB, TC } from "../Constants";
import * as M from "../Components/Modal";

//#region Types
type MissionOption = T.Awaited<ReturnType<typeof S.usersMissions>>["data"];

type DataModal<A = {}> = RenderSelectModal & A;
type PromiseResults = null | undefined | string;
type GetContextOrigins = T.API.Utils.Datasets.GetContextOrigins;
type RenderSelectModal = Omit<M.RenderModalSelectProps, "options">;

type UserModalParams = DataModal<{
    /** Should the current user appear in the options ? */
    includeSelf?: boolean;
    /** Should Admins appear in the options ? */
    includeAdmin?: boolean;
}>;

type SiteModalParams = DataModal<{
    /** The context to search for sites */
    context: T.ContextParams
}>;

type BuildingModalParams = DataModal<{
    /** The context to search for sites */
    context: T.ContextParams
}>;

type EquipModalParams = DataModal<{
    /** The context to search for equipments */
    context: T.ContextParams;
}>;

type OriginContextParams = DataModal<{
    /** The context to search for Origins */
    context: Parameters<GetContextOrigins>[0];
}>;

type DatasetFromOriginParams = DataModal<{
    /** The id(s) of origins to get datasets from */
    origin: T.AllowArray<string>;
}>;

type CriticityParams = DataModal<{
    /** The type of criticity to fetch */
    type: T.EquipIndicatorData["type"];
    /** Elements to update after the selection's been made */
    toUpdate?: string;
}>;

type ContextElementsParams = DataModal<Parameters<T.API.Utils.Context.GetContextOptions>[0]>;
//#endregion

/**
 * Render a select modal
 */
const renderSelectModal = <A extends {} = {}>(params: RenderSelectModal, dataPromise: Promise<M.ModalSelectOption<A>[]>) => new Promise<PromiseResults>(resolve => {
    let dismount = M.renderLoader();
    let vParams = TB.getObject(params);

    dataPromise
        .then(options => {
            if (options.length === 1 && params.isRequired && TB.validString(options[0].value)) resolve(options[0].value);
            else M.askSelect({ ...vParams, options }).then(resolve);
        })
        .catch((error: T.AxiosError) => {
            M.Alerts.loadError(error);
            resolve(null);
        })
        .finally(dismount);
});

/**
 * Render a modal of the users below the current user
 */
export const renderUserSelect = (params: UserModalParams) => {
    const optionPromise = new Promise<M.ModalSelectOption[]>((resolve, reject) => {
        S.getUsers({ includeAdmin: params?.includeAdmin, includeSelf: params?.includeSelf })
            .then(({ data }) => resolve(data.map(u => ({ label: u.data.name, value: u._id }))))
            .catch(reject);
    });
    return renderSelectModal(params, optionPromise);
}

/**
 * Render a modal of the sites in a given context
 */
export const renderSiteSelect = (params: SiteModalParams) => {
    const optionPromise = new Promise<M.ModalSelectOption[]>((resolve, reject) => {
        S.getContextSites(params?.context || {})
            .then(({ data }) => resolve(data))
            .catch(reject);
    });
    return renderSelectModal(params, optionPromise);
}

/**
 * Render a modal of the buildings in a given context
 */
export const renderBuildingSelect = (params: BuildingModalParams) => {
    const optionPromise = new Promise<ReturnType<T.API.Utils.Context.GetContextBuildings>>((resolve, reject) => {
        S.getContextBuildings(params?.context || {})
            .then(({ data }) => resolve(data))
            .catch(reject);
    });
    return renderSelectModal(params, optionPromise);
}

/**
 * Render a modal of the equipments in a given context
 */
export const renderEquipmentModal = (params: EquipModalParams) => {
    const optionPromise = new Promise<M.ModalSelectOption[]>((resolve, reject) => {
        S.getContextEquipments(params?.context)
            .then(({ data }) => resolve(data))
            .catch(reject);
    });
    return renderSelectModal(params, optionPromise);
}

/**
 * Create a custom list of element found in a context
 */
export const renderContextElements = (params: ContextElementsParams) => {
    type C_Option = Awaited<ReturnType<typeof S.getContextOptions>>["data"][number];
    const optionPromise = new Promise<C_Option[]>((resolve, reject) => {
        let api_params = { context: params?.context, add_all_parents: params?.add_all_parents, add_parent: params?.add_parent, forms: params?.forms };
        S.getContextOptions(api_params).then(({ data }) => resolve(data)).catch(reject);
    });

    params.selectProps = {
        ...params.selectProps, renderItem: (o: C_Option) => {
            let text = o.label;
            if (o.full_path) text += ` (${o.full_path.join(" - ")})`;
            else if (o.parent) text += ` (${o.parent})`;
            return <div children={text} />;
        }
    };

    return renderSelectModal(params, optionPromise);
}

/**
 * Render a modal to let the user pick an origin out of a context
 */
export const renderOriginContextPickModal = (params: OriginContextParams) => {
    const optionPromise = new Promise<ReturnType<GetContextOrigins>>((resolve, reject) => {
        S.getContextOrigins(params?.context)
            .then(({ data }) => resolve(data))
            .catch(reject);
    });
    return renderSelectModal({
        ...params,
        selectProps: {
            renderItem: (o: Awaited<typeof optionPromise>[number]) => <span><i className={"me-2 fa fa-" + o.icon}></i>{o.label}</span>
        }
    }, optionPromise);
}

/**
 * Render a modal to pick a dataset from an origin
 */
export const renderDatasetPickModal = (params: DatasetFromOriginParams) => {
    const optionPromise = new Promise<T.Option[]>((resolve, reject) => {
        S.getDatasetsOptions(params?.origin)
            .then(({ data }) => resolve(data))
            .catch(reject);
    });

    return renderSelectModal({ ...params, title: params?.title || TC.ALARM_PICK_DATASET }, optionPromise);
}

/**
 * Render a modal for equipment's indicator selection
 */
export const renderEquipIndicatorModal = (params: CriticityParams) => {
    const optionPromise = new Promise<T.Option[]>((resolve, reject) => {
        S.getPreciseEquipIndicator(params?.type).then(({ data }) => {
            let options = data.map(i => ({ value: i._id, prop: "level", label: i.data.level }));
            resolve(options);
        }).catch(reject);
    });

    let title = "";
    if (params?.type === "criticityEquipment") title = TC.ELEM_BASE_CRITICITY;
    if (typeof params?.title === "string") title = params.title;

    let select = renderSelectModal({ ...params, title, isRequired: true }, optionPromise);

    if (!TB.mongoIdValidator(params?.toUpdate)) return select;
    else return new Promise(resolve => {
        select.then(indicator => {
            let property = "criticity" as Parameters<typeof S.setElemIndicator>[0]["property"];
            if (params.type === "criticityFailure") property = "failureCriticity";
            else if (params.type === "vetusty") property = "vetusty";

            if (TB.mongoIdValidator(indicator)) S.setElemIndicator({ element: params.toUpdate, value: indicator, property })
                .then(({ data }) => {
                    if (data === "failure") {
                        M.Alerts.updateError();
                        resolve(null)
                    }
                    else resolve(indicator);
                })
                .catch(() => {
                    M.Alerts.updateError();
                    resolve(null);
                })
            else resolve(null);
        });
    }) as ReturnType<typeof renderSelectModal>;
}

/**
 * Render a modal for a mission selection
 */
export const searchMission = (params: DataModal) => new Promise<MissionOption[number]>(resolve => {
    const optionPromise = new Promise<MissionOption>((resolve, reject) => {
        S.usersMissions(null)
            .then(r => resolve(r.data))
            .catch(reject);
    });

    const renderItem = (m: MissionOption[number]) => <>{m.label} - {m.created}</>;

    renderSelectModal({ ...params, selectProps: { renderItem }, title: params?.title || TC.NAV_SEARCH_MISSION }, optionPromise).then(mission_id => {
        if (!mission_id) resolve(null);
        else optionPromise.then(missions => {
            let mission = missions.filter(m => m.value === mission_id)[0];
            if (mission) resolve(mission);
            else resolve(null);
        }).catch(() => resolve(null));
    });
});

/**
 * Choose a user's mission from the current context
 */
export const searchAssetMission = (params: DataModal<Record<"asset", string>>) => new Promise<"cancel" | T.Mission>(resolve => {
    const optionPromise = new Promise<T.Option<Record<"mission", T.Mission>>[]>((resolve, reject) => {
        S.usersAssetMissions(params.asset).then(r => {
            let options = r.data.map(m => ({
                mission: m,
                value: m._id,
                label: `${m.type.toUpperCase()} - ${m.ref_provider} - ${TB.formatDate(m.createdAt, "DD/MM/YYYY")}`,
            }))
                .concat({ mission: null, value: "none", label: TC.GLOBAL_NONE });
            resolve(options);
        }).catch(reject);
    });

    renderSelectModal({
        ...params,
        isRequired: true,
        defaultVal: "none",
        title: params?.title || TC.MISSION_COPY_FROM,
        description: params?.description || TC.MISSION_COPY_FROM_DESC,
    }, optionPromise).then(mission_id => {
        if (!mission_id) resolve("cancel");
        else optionPromise.then(missions => {
            let mission = missions.filter(m => m.value === mission_id)[0]?.mission;
            if (mission) resolve(mission);
            else resolve(null);
        }).catch(() => resolve(null));
    });
})