import React from "react";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import * as S from "../../../services";
import { T, TB, TC } from "../../../Constants";

export type KPIPanelProps = {
    /** Id of the building */
    building: string;
}

type Saving = ReturnType<T.API.Reg.GetKpiSavingData>;

const KPIPanel: React.FC<KPIPanelProps> = props => {
    const [save, set_save, status] = H.useAsyncState<Saving>({ save: false });

    React.useEffect(() => {
        let isSubscribed = true;
        S.getKpiSavingData(props.building)
            .then(({ data }) => isSubscribed && set_save(data, "done"))
            .catch(() => isSubscribed && set_save({ save: false }, "error"));
        return () => {
            isSubscribed = false;
            set_save({ save: false }, "load");
        }
    }, [props.building, set_save]);

    const set_save_kpi = React.useCallback((save: boolean) => {
        S.toggleKPISaving(props.building).then(({ data }) => {
            // Save the KPI, frequency default is one month
            if (save) set_save({ save: true, frequency: "1M", next_exec: data });
            // Do not save the KPI
            else set_save({ save: false });
        }).catch(M.Alerts.updateError);
    }, [props.building, set_save]);

    const set_frequency = React.useCallback((frequency?: string) => {
        let freq = TB.splitFrequency(frequency);
        if (!freq) set_save(p => ({ ...p, frequency: undefined }));
        else S.updateKPISavingFrequency({ building: props.building, frequency })
            .then(() => set_save(p => ({ ...p, frequency })))
            .catch(M.Alerts.updateError);
    }, [props.building, set_save]);

    const set_next_exec = React.useCallback((date_str?: string) => {
        let date = TB.getDate(date_str);
        if (!date) set_save(p => ({ ...p, next_exec: undefined }));
        else S.updateKPINextExecDate({ building: props.building, next_exec: date.toISOString() })
            .then(() => set_save(p => ({ ...p, next_exec: date.toISOString() })))
            .catch(M.Alerts.updateError);
    }, [props.building, set_save]);

    const frequency_error = React.useMemo(() => {
        if (typeof save.frequency !== "string" && save.save) return TC.GLOBAL_REQUIRED_FIELD;
    }, [save]);

    const date_error = React.useMemo(() => {
        if (typeof save.next_exec !== "string" && save.save) return TC.GLOBAL_REQUIRED_FIELD;
    }, [save]);

    return <div style={{ minHeight: "25vh" }}>
        <C.Spinner min_load_size="25vh" status={status}>
            <BS.Row className="align-items-center">
                <BS.Col>
                    <C.Form.RadioBool
                        required
                        labelFullWidth
                        always_selected
                        value={save.save}
                        labelPosition="left"
                        onChange={set_save_kpi}
                        label={TC.KPI_SAVE_LABEL}
                    />
                </BS.Col>
                <BS.Col>
                    <C.Form.Frequency
                        noReset
                        required
                        labelPosition="left"
                        disabled={!save.save}
                        value={save.frequency}
                        onChange={set_frequency}
                        label={TC.KPI_SAVE_FREQUENCY}
                        error={{ code: frequency_error }}
                    />
                    <C.Form.DateTime
                        required
                        enableTime
                        labelPosition="left"
                        disabled={!save.save}
                        value={save.next_exec}
                        onChange={set_next_exec}
                        error={{ code: date_error }}
                        label={TC.KPI_NEXT_EXEC_DATE}
                    />
                </BS.Col>
            </BS.Row>
        </C.Spinner>
    </div>;
}

export default KPIPanel;