import _ from "lodash";
import React from "react";
import * as H from "../../../hooks";
import { Flex } from "../../Layout";
import * as BS from "react-bootstrap";
import * as C from "../../../Components";
import { ErrorBanner, TypeAhead } from "../../Item";
import * as US from "../../../services/user.service";
import { ComponentWrapper, InputProps } from "../Input";
import { TC, FP, T, RIGHTS as R, TB, REGEX } from "../../../Constants";

export type ActionsProps = InputProps;

const Actions: React.FC<ActionsProps> = ({ onChange, ...props }) => {
    const isDisabled = React.useMemo(() => props.disabled || props.noOverride, [props.disabled, props.noOverride]);

    return <ComponentWrapper {...props} disabled={isDisabled}>
        <Action
            value={props.value}
            disabled={isDisabled}
            actionType="maintenance"
            extraData={props.extraData}
            submissionId={props.submissionId}
            setter={val => onChange?.(val)}
            submission={props.fullSubmission}
            category={props.fullSubmission?.category}
        />
    </ComponentWrapper>
}

export default Actions;

//#region Action Comp
type ActionValue = ActionValueItem[];
type ActionType = "regulation" | "maintenance";
type ExtraDataType = { site?: string | string[], category?: string };
type Anchor = { _id: string, path: string, name?: string };
type DbActionItems = T.ActionMaintenanceType | T.ActionRegulationType;
type ActionValueItem = { action?: string, duration?: string, worker?: string[], dateLastMaintenance?: string };

type ActionsComponentProps = {
    site?: string;
    label?: string;
    category?: string;
    disabled?: boolean;
    value?: ActionValue;
    submissionId?: string;
    submission?: T.AnyObject;
    actionType?: ActionType;
    extraData?: ExtraDataType;
    validationSetter?: (isValid: boolean, otherKey?: string) => void;
    setter?: (val?: ActionValue, otherKey?: string) => void;
}

type Resource = {
    gammes: string[];
    forms: T.FormType[];
    anchors: Anchor[] | null;
    actions: DbActionItems[];
    users: { label: string, value: string }[];
    status: "load" | "ready" | "noClass" | "error";
}

type AllDbActionsObj = {
    allDbActions: DbActionItems[];
    statusDbActions: "untouched" | "ready" | "error";
}

type UnSyncFetchObj = {
    nonExistantIds: string[];
    failedFetchIds: string[];
}

const isRegActionGuard = (action?: any, formId?: string): action is T.ActionRegulationType => TB.validSubmission(action) && action.form === formId;
const isMaintenanceActionGuard = (action?: any, formId?: string): action is T.ActionMaintenanceType => TB.validSubmission(action) && action.form === formId;

const TEXT_CODES = [
    FP.BUILDING_FORM, FP.SITE_FORM, FP.CLIENT_FORM, TC.DURATION, TC.ACTION_MAINTENANCE, TC.ACTION_MAINTENANCE_LAST_DATE, TC.ACTION_REG_LAST_DATE, TC.ACTION_REG,
    TC.ACTION_SHOW_ALL_ACTIONS, TC.ACTION_SHOW_SPECIFIC, TC.GLOBAL_REQUIRED_FIELD, TC.ACTION_GHOST_VALUE, TC.ACTION_FAILED_LOAD_VALUE, TC.ACTION_VALUE_NOT_SPECIFIC,
    TC.GLOBAL_SERVICE_WORKER,
];

const Action: React.FC<ActionsComponentProps> = ({ disabled, setter, validationSetter, ...props }) => {
    const [{ user, isAdmin }] = H.useAuth();
    const rights = H.useRights();
    const [showAll, setShowAll] = React.useState(false);
    const { getStaticText } = H.useLanguage(TEXT_CODES);
    const [errors, setErrors] = React.useState<{ [key: string]: string[] }>({});
    const [{ nonExistantIds, failedFetchIds }, setNonExistantIds] = React.useState<UnSyncFetchObj>({ nonExistantIds: [], failedFetchIds: [] });
    const [{ allDbActions, statusDbActions }, setAllActions] = React.useState<AllDbActionsObj>({ allDbActions: [], statusDbActions: "untouched" });
    const [{ actions, forms, anchors, users, status }, setResources] = React.useState<Resource>({ forms: [], gammes: [], actions: [], users: [], anchors: null, status: "load" });

    //#region Anchor & Category
    const { submissionId, site, extraData, category, submission } = React.useMemo(() => props, [props]);
    const anchorId = React.useMemo(() => [submissionId, site, extraData?.site].filter(TB.mongoIdValidator)[0] ?? null, [submissionId, site, extraData]);
    const omniclass = React.useMemo(() => [category, extraData?.category, submission?.data?.category, submission?.category].filter(TB.mongoIdValidator)[0] ?? null, [category, extraData, submission]);
    //#endregion

    //#region Banner
    const errorBanner = React.useMemo(() => {
        if (status === "error") return <ErrorBanner type="danger" textCode={TC.GLOBAL_FAILED_LOAD} />;
        if (status === "noClass") return <ErrorBanner type="warning" textCode={TC.GLOBAL_NO_EQUIP_GAMME} />;
        if (status === "load") return <C.Modal.Loader isPopUp={false} />;
        return null;
    }, [status]);
    //#endregion

    //#region Value
    const validActionValue: ActionValue = React.useMemo(() => TB.getArray(props.value).filter(TB.validObject).concat([{}]), [props.value]);
    //#endregion

    //#region Forms & Type Guard & Action type
    const regulationForm = React.useMemo(() => _.find(forms, ({ path }) => path === FP.ACTION_REG_FORM)?._id, [forms]);
    const maintenanceForm = React.useMemo(() => _.find(forms, ({ path }) => path === FP.MAINTENANCE_ACTION)?._id, [forms]);

    const isRegAction = React.useCallback((action: any): action is T.ActionRegulationType => isRegActionGuard(action, regulationForm), [regulationForm]);
    const isMaintenanceAction = React.useCallback((action: any): action is T.ActionMaintenanceType => isMaintenanceActionGuard(action, maintenanceForm), [maintenanceForm]);

    const [actionType, actionTypeForm, actionTypePath, colLabels]: [ActionType, string | undefined, string, { date: string, action: string }] = React.useMemo(() => {
        switch (props.actionType) {
            case "regulation": return [props.actionType, regulationForm, FP.ACTION_REG_FORM, { date: TC.ACTION_REG_LAST_DATE, action: TC.ACTION_REG }];
            default: return ["maintenance", maintenanceForm, FP.MAINTENANCE_ACTION, { date: TC.ACTION_MAINTENANCE_LAST_DATE, action: TC.ACTION_MAINTENANCE }];
        }
    }, [regulationForm, maintenanceForm, props.actionType]);
    //#endregion

    //#region Rights
    const canEditActions = React.useMemo(() => {
        if (disabled) return false;
        let okReg = rights.isRightAllowed(R.CAN_EDIT_ACTIONS_REGULATION) && actionType === "regulation";
        let okMaintenance = rights.isRightAllowed(R.CAN_EDIT_ACTIONS_MAINTENANCE) && actionType === "maintenance";
        return okReg || okMaintenance;
    }, [actionType, rights, disabled]);

    const canCreateActionClient = React.useMemo(() => {
        if (!canEditActions) return false;
        let okReg = rights.isRightAllowed(R.ACTION_REG_CLIENT_CREATE) && actionType === "regulation";
        let okMaintenance = rights.isRightAllowed(R.ACTION_MAINTENANCE_CLIENT_CREATE) && actionType === "maintenance";
        return okReg || okMaintenance;
    }, [actionType, rights, canEditActions]);

    const canNotCreateAction = React.useMemo(() => {
        if (canCreateActionClient) return false;
        let okReg = rights.isRightAllowed(R.NO_ACTION_REG_CREATE) && actionType === "regulation";
        let okMaintenance = rights.isRightAllowed(R.NO_ACTION_MAINTENANCE_CREATE) && actionType === "maintenance";
        return okReg || okMaintenance;
    }, [actionType, rights, canCreateActionClient]);
    //#endregion

    //#region Anchors
    const anchorsOption = React.useMemo(() => {
        if (anchors === null || anchors.length === 0) return null;
        if (anchors.length === 1) return anchors[0]._id;
        let anchorToChoose = canCreateActionClient ? anchors : anchors.filter(({ path }) => path !== FP.CLIENT_FORM);

        return anchorToChoose.map(a => ({ label: `${a.name} (${getStaticText(a.path)})`, value: a._id }));
    }, [anchors, canCreateActionClient, getStaticText]);

    const chooseAnchor = React.useCallback(() => new Promise(resolve => {
        if (!Array.isArray(anchorsOption)) resolve(anchorsOption);
        else C.Modal.askSelect({ options: anchorsOption, isRequired: true }).then(resolve);
    }), [anchorsOption]);
    //#endregion

    //#region Action Options
    const specificActionsIds = React.useMemo(() => actions.map(act => act._id), [actions]);
    const mergedActions = React.useMemo(() => _.uniqBy(allDbActions.concat(actions).sort(TB.sortByNameSubmission), "_id"), [allDbActions, actions]);
    const dynamicActions = React.useMemo(() => _.uniqBy((showAll ? mergedActions : actions).sort(TB.sortByNameSubmission), "_id"), [showAll, mergedActions, actions]);

    const actionMap = React.useCallback(({ _id, data }) => ({ value: _id, label: data.name || "NO_NAME", frequency: data.frequency }), []);

    const allActions = React.useMemo(() => dynamicActions.map(actionMap), [dynamicActions, actionMap]);
    const selectedActions = React.useMemo(() => validActionValue.map(({ action }) => action).filter(TB.mongoIdValidator), [validActionValue]);

    const getDbAction = React.useCallback((id?: string) => _.find(mergedActions, ({ _id }) => _id === id), [mergedActions]);
    const getActions = React.useCallback((id?: string) => allActions.filter(({ value }) => !selectedActions.includes(value) || value === id), [allActions, selectedActions]);
    //#endregion

    //#region onSelect
    const setArray = React.useCallback((array: ActionValue) => {
        let validValues = array.filter(val => {
            if (!TB.validObject(val)) return false;
            if (Object.entries(val).length === 0) return false;
            return TB.mongoIdValidator(val.action) || Array.isArray(val.worker) || [val.dateLastMaintenance, val.duration].some(TB.validString);
        });
        setter?.(validValues);
    }, [setter]);

    const onSelectAction = React.useCallback((index: number, actionId?: string) => {
        let action = getDbAction(actionId);
        let newValueArray = validActionValue.map((act, i) => {
            if (i !== index) return act;
            let duration = isMaintenanceAction(action) ? action.data.duration : null;
            if (!TB.validString(duration) || !duration.match(REGEX.DURATION_REGEX)) duration = null;
            let newVal = { ...act, action: actionId };
            if (duration !== null) newVal.duration = duration;
            return newVal;
        });

        setArray(newValueArray);
    }, [validActionValue, isMaintenanceAction, getDbAction, setArray]);

    const onSelectUser = React.useCallback((index: number, userIds?: string[]) => {
        setArray(validActionValue.map((u, i) => i !== index ? u : { ...u, worker: userIds }));
    }, [validActionValue, setArray]);

    const onSelectLastDate = React.useCallback((index: number, lastDate?: string) => {
        let date = TB.getDate(lastDate);
        let dateStr = date === null ? "" : date.toISOString();
        let newValueArray = validActionValue.map((act, i) => i !== index ? act : { ...act, dateLastMaintenance: dateStr });
        setArray(newValueArray);
    }, [validActionValue, setArray]);

    const onChangeDuration = React.useCallback((index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        /* @ts-ignore */
        let newChar = event.nativeEvent.data;
        let newValue = event.target.value;
        //Bad input 
        if (!TB.validString(newChar) || !newChar.match(/^\d$/g)) return;
        if (!TB.validString(newValue) || !newValue.match(REGEX.DURATION_REGEX)) return;
        let newValueArray = validActionValue.map((act, i) => i !== index ? act : { ...act, duration: newValue });
        setArray(newValueArray);
    }, [setArray, validActionValue]);
    //#endregion

    //#region Remove
    const onRemove = React.useCallback((index: number) => setArray(validActionValue.filter((x, i) => i !== index)), [validActionValue, setArray]);
    //#endregion

    //#region Create new Action
    const allowCreateNewOption = React.useMemo(() => Array.isArray(anchors) && anchors.length > 0 && !canNotCreateAction, [anchors, canNotCreateAction]);

    const createNewOption = React.useCallback((text: string, index: number) => {
        chooseAnchor().then((anchor) => {
            if (TB.mongoIdValidator(anchor)) {
                C.Modal.renderFormModal({
                    path: actionTypePath, forcedSubmission: [
                        { prop: "name", value: text },
                        { prop: "site", value: anchor },
                        { prop: "gammes", value: [omniclass] },
                    ]
                }).then(submission => {
                    if (isMaintenanceAction(submission) || isRegAction(submission)) {
                        setResources(p => ({ ...p, actions: p.actions.concat(submission) }));
                        onSelectAction(index, submission._id);
                    }
                });
            }
        });
    }, [actionTypePath, omniclass, chooseAnchor, isMaintenanceAction, isRegAction, onSelectAction]);
    //#endregion

    //#region Action Error
    const getActionError = React.useCallback((actionId?: string) => {
        if (!TB.mongoIdValidator(actionId)) return { isCrit: true, code: TC.GLOBAL_REQUIRED_FIELD };
        if (failedFetchIds.includes(actionId)) return { isCrit: false, code: TC.ACTION_FAILED_LOAD_VALUE };
        if (nonExistantIds.includes(actionId)) return { isCrit: true, code: TC.ACTION_GHOST_VALUE };
        if (!specificActionsIds.includes(actionId)) return { isCrit: false, code: TC.ACTION_VALUE_NOT_SPECIFIC };
        return null;
    }, [failedFetchIds, nonExistantIds, specificActionsIds]);
    //#endregion

    //#region Render
    const renderSelectItem = React.useCallback((params: { label: string, frequency?: string | string[] }) => <div>
        <span>{params.label}</span>
        {params.frequency && <span className="ms-2">({Array.isArray(params.frequency) ? params.frequency.join() : params.frequency})</span>}
    </div>, []);

    const renderActionSelect = React.useCallback((index: number, actionId?: string) => {
        let stringAction = TB.getString(actionId);
        let error = index === validActionValue.length - 1 ? null : getActionError(actionId);
        let condError = Array.isArray(errors[stringAction]);

        return <>
            <TypeAhead
                disabled={!canEditActions}
                renderItem={renderSelectItem}
                options={getActions(actionId)}
                allowNewOption={allowCreateNewOption}
                invalid={error !== null && error.isCrit}
                selectedItems={actionId}
                onAddOption={text => createNewOption(text, index)}
                onChange={values => onSelectAction(index, values?.[0]?.value)}
            />
            {error && !condError && <BS.Form.Control.Feedback className={`shown ${!error?.isCrit ? "warning" : ""}`} type="invalid">{getStaticText(error?.code)}</BS.Form.Control.Feedback>}
            {condError && <BS.Form.Control.Feedback className="shown" type="invalid">
                {errors[stringAction].join(" / ")}
            </BS.Form.Control.Feedback>}
        </>
    }, [getActions, getActionError, renderSelectItem, onSelectAction, createNewOption, getStaticText, errors, allowCreateNewOption, validActionValue, canEditActions]);

    const renderLastDatePicker = React.useCallback((index: number, date?: string) => <BS.Form.Control
        type="date"
        disabled={!canEditActions}
        value={TB.formatDate(date, "YYYY-MM-DD")}
        onChange={e => onSelectLastDate(index, e.target.value)}
    />, [onSelectLastDate, canEditActions]);

    const renderDurationPicker = React.useCallback((index: number, duration?: string) => <BS.Form.Control
        type="duration"
        placeholder="hh:mm"
        disabled={!canEditActions}
        value={duration || "00:00"}
        /* @ts-ignore */
        onChange={event => onChangeDuration(index, event)}
    />, [onChangeDuration, canEditActions]);

    const renderUserSelect = React.useCallback((index: number, userIds?: string[]) => <TypeAhead
        multiple
        options={users}
        disabled={!canEditActions}
        selectedItems={TB.getArray(userIds)}
        onChange={values => onSelectUser(index, values.map(v => v.value).filter(TB.mongoIdValidator))}
    />, [onSelectUser, canEditActions, users]);

    const renderActionRow = React.useCallback((val: ActionValueItem, index: number) => <BS.Row className="mb-2" key={index}>
        <BS.Col>{renderActionSelect(index, val.action)}</BS.Col>
        {actionType === "maintenance" && <BS.Col>{renderDurationPicker(index, val.duration)}</BS.Col>}
        <BS.Col>{renderLastDatePicker(index, val.dateLastMaintenance)}</BS.Col>
        <BS.Col>{renderUserSelect(index, val.worker)}</BS.Col>
        <BS.Col sm={1}>
            <BS.Button disabled={!canEditActions} onClick={() => onRemove(index)} variant="danger"><i className="fa fa-times"></i></BS.Button>
        </BS.Col>
    </BS.Row>, [renderActionSelect, actionType, canEditActions, renderLastDatePicker, renderUserSelect, renderDurationPicker, onRemove]);

    const colHeaders = React.useMemo(() => <BS.Row className="mb-2 text-center h5">
        <BS.Col>{getStaticText(colLabels.action)}</BS.Col>
        {actionType === "maintenance" && <BS.Col>{getStaticText(TC.DURATION)}</BS.Col>}
        <BS.Col>{getStaticText(colLabels.date)}</BS.Col>
        <BS.Col>{getStaticText(TC.GLOBAL_SERVICE_WORKER)}</BS.Col>
        <BS.Col sm={1}></BS.Col>
    </BS.Row>, [actionType, colLabels, getStaticText]);
    //#endregion

    //#region Validation
    React.useEffect(() => {
        let isValid = validActionValue.every((value, index) => {
            if (index === validActionValue.length - 1) return true;
            let error = getActionError(value.action);
            return error === null || !error.isCrit;
        });

        validationSetter?.(isValid);
    }, [getActionError, validationSetter, validActionValue]);

    const testCondition = React.useCallback(() => {
        US.checkActionsConditions(selectedActions, anchorId, submissionId, submission?.data || {}, FP.EQUIPEMENT_FORM).then(({ data }) => {
            if (!data?.hasFailed) {
                if (TB.validString(data)) C.Modal.renderAlert({ message: data, type: "error" });
                else if (TB.validObject(data)) setErrors(data);
            }
            else C.Modal.renderAlert({ message: TC.GLOBAL_ERROR_OCCURRED, type: "error" });
        });
    }, [anchorId, selectedActions, submission?.data, submissionId]);
    //#endregion

    //#region Show All Switch
    const showAllLabel = React.useMemo(() => showAll ? TC.ACTION_SHOW_ALL_ACTIONS : TC.ACTION_SHOW_SPECIFIC, [showAll]);

    const showAllSwitch = React.useMemo(() => <Flex justifyContent="between" className="px-3 py-2">
        <Flex>
            <BS.Form.Check disabled={!canEditActions} type="switch" checked={showAll} onChange={() => setShowAll(p => !p)} />
            <BS.Form.Label className="ms-1">{getStaticText(showAllLabel)}</BS.Form.Label>
        </Flex>
        {isAdmin && <BS.Button size="sm" onClick={testCondition}>test condition</BS.Button>}
    </Flex>, [showAll, isAdmin, showAllLabel, canEditActions, getStaticText, testCondition]);
    //#endregion

    //#region Load Resource
    const fetchedActionIds = React.useMemo(() => mergedActions.map(({ _id }) => _id), [mergedActions]);
    const fetchParams = React.useMemo(() => ({ formComp: true, anchorId, actionType, omniclass, userId: user?._id }), [anchorId, actionType, omniclass, user?._id]);

    React.useEffect(() => {
        let isSubscribed = true;

        if (TB.mongoIdValidator(fetchParams.userId)) US.getFullResources(FP.MAINTENANCE_ACTION, fetchParams)
            .then(({ data }) => {
                if (isSubscribed) {
                    if (data?.hasFailed) setResources(p => ({ ...p, status: "error" }));
                    else if (data?.noCategory) setResources(p => ({ ...p, actions: [], status: "noClass" }));
                    else setResources({ ...data, status: "ready" });
                }
            })
            .catch(() => setResources(p => ({ ...p, status: "error" })));

        return () => { isSubscribed = false; }
    }, [fetchParams]);

    React.useEffect(() => {
        let isSubscribed = true;
        if (showAll && TB.mongoIdValidator(regulationForm) && TB.mongoIdValidator(maintenanceForm)) {
            if (statusDbActions === "untouched" || statusDbActions === "error") {
                let unmount = C.Modal.renderLoader();
                let filter = {
                    form: actionTypeForm,
                    _id: { $nin: fetchedActionIds },
                    $or: [{ "data.site": { $exists: false } }, { "data.site": "" }],
                }

                US.getManySubmissionsFromFilter(filter)
                    .then(({ data }) => {
                        if (isSubscribed) {
                            if (!Array.isArray(data)) setAllActions(p => ({ ...p, statusDbActions: "error" }));
                            else setAllActions(p => ({ ...p, allDbActions: data, statusDbActions: "ready" }));
                        }
                    })
                    .catch(() => isSubscribed && setAllActions(p => ({ ...p, statusDbActions: "error" })))
                    .finally(unmount);
            }
        }

        return () => { isSubscribed = false };
    }, [actionTypeForm, maintenanceForm, fetchedActionIds, regulationForm, showAll, statusDbActions]);

    React.useEffect(() => {
        let isSubscribed = true;
        if (status === "ready") {
            let notFetchedIds = selectedActions.filter(id => !fetchedActionIds.includes(id) && !nonExistantIds.includes(id));
            if (TB.mongoIdValidator(actionTypeForm) && notFetchedIds.length > 0) US.getManySubmissionsFromFilter({ _id: notFetchedIds, form: actionTypeForm })
                .then(({ data }) => {
                    if (isSubscribed) {
                        if (Array.isArray(data)) {
                            let fetchedIds = data.map(d => d._id);
                            let notFound = notFetchedIds.filter(id => !fetchedIds.includes(id));

                            setAllActions(p => ({ ...p, allDbActions: p.allDbActions.concat(data) }));

                            setNonExistantIds(p => {
                                let nonExistantIds = p.nonExistantIds, failedFetchIds = p.failedFetchIds;

                                // Add those that were found
                                if (notFound.length > 0) nonExistantIds = nonExistantIds.concat(notFound);
                                // If error last time we tried to get those, but not this time, remove them from failed fetch.
                                if (failedFetchIds.some(id => notFetchedIds.includes(id))) failedFetchIds = failedFetchIds.filter(id => !notFetchedIds.includes(id));
                                return { nonExistantIds, failedFetchIds };
                            })
                        }
                        else setNonExistantIds(p => ({ ...p, failedFetchIds: notFetchedIds }));
                    }
                })
                .catch(() => setNonExistantIds(p => ({ ...p, failedFetchIds: notFetchedIds })));
        }

        return () => { isSubscribed = false };
    }, [actionTypeForm, fetchedActionIds, nonExistantIds, selectedActions, status]);
    //#endregion

    return <>
        <div className="mb-2">{props.label}</div>
        {errorBanner}
        {status === "ready" && <>
            {colHeaders}
            {validActionValue.map(renderActionRow)}
            {showAllSwitch}
        </>}
    </>
}
//#endregion