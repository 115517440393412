import { REGEX } from "../Data";
import { getNumber } from "./number";
import { getString } from "./string";

/**
 * Transform a duration (like 10:30) into seconds
 */
export const durationToSeconds = (duration: string) => {
    let strDuration = getString(duration);
    if (!strDuration.match(REGEX.DURATION_REGEX)) return null;
    let [hour, min] = strDuration.split(":").map(n => parseInt(n));

    if (isNaN(hour) || isNaN(min)) return null;
    return (hour * 60 * 60) + (min * 60);
}

/**
 * Transform a number of seconds into a duration
 */
export const secondsToDuration = (duration: number) => {
    let num = getNumber(duration);
    if (isNaN(num) || num === null) return null;
    let hour = Math.floor(num / (60 * 60));
    let minutes = (num - (hour * 60 * 60)) / 60;
    return `${hour < 10 ? "0" : ""}${hour}:${minutes < 10 ? "0" : ""}${minutes}`;
}