import React from "react";
import { T, TC } from "../../../Constants";
import { StyleModalProps, default as Modal } from "../BlankModal";
import { Button, Flex, Form, ReduxWrapper } from "../../../Common";

export type MultiSelectProps = {
    /** Callback for closing the modal */
    onClose?: () => void;
    /** Callback after confirming the values */
    onConfirm?: (prompt?: (string | number)[]) => void;
    /** Extra params to change the modal */
    modal?: StyleModalProps;
    /** The label of the input */
    label?: string;
    /** The title of the modal */
    title?: string;
    /** An additional element displayed under the input */
    description?: string | React.ReactElement;
    /** The text to be displayed in the confirm button */
    confirm_text?: string;
    /** The text to be displayed in the 'No selection' button */
    no_select_text?: string;
    /** Is a value required for validation */
    required?: boolean;
    /** The default selected value */
    value?: (string | number)[];
    /** The options to populate the select */
    options: T.Option[];
    /** Extra typeahead props to pass on */
    typeahead?: Form.SelectProps["typeahead"];
}

const MultiSelect: React.FC<MultiSelectProps> = ({ onConfirm, ...props }) => {
    const [value, setValue] = React.useState(props.value || []);

    //#region Confirmation
    const confirm = React.useCallback(() => onConfirm?.(value), [onConfirm, value]);

    const select_nothing = React.useCallback(() => {
        if (!props.required) onConfirm?.();
    }, [props.required, onConfirm]);
    //#endregion

    //#region Footer
    const footer = React.useMemo(() => <Flex justifyContent="end">
        {!props.required && <Button onClick={select_nothing} icon="times" variant="secondary" className="me-2">
            {props.no_select_text || TC.GLOBAL_NO_SELECTION}
        </Button>}
        <Button icon="save" onClick={confirm} disabled={props.required && value.length === 0}>
            {props.confirm_text || TC.GLOBAL_CONFIRM}
        </Button>
    </Flex>, [confirm, select_nothing, value.length, props.no_select_text, props.confirm_text, props.required]);
    //#endregion

    return <Modal
        {...props.modal}
        footer={footer}
        onQuit={props.onClose}
        size={props.modal?.size || "sm"}
        title={props.title || props.modal?.title || " "}
    >
        <Form.Select
            multiple
            value={value}
            noBottomMargin
            onChange={setValue}
            label={props.label}
            options={props.options}
            required={props.required}
            typeahead={props.typeahead}
            description={props.description}
        />
    </Modal>;
}

const WrapperMultiSelect: React.FC<MultiSelectProps> = props => <ReduxWrapper>
    <MultiSelect {...props} />
</ReduxWrapper>;

export default WrapperMultiSelect;