import ReactDOM from 'react-dom';
import DefaultComp from './NoRenderComp';
import { ReactComponent } from 'react-formio';

export default class Frequency extends ReactComponent {
    static get builderInfo() {
        return {
            title: "Frequency",
            icon: "calendar",
            group: "Basic",
            documentation: "",
            weight: -10,
            schema: Frequency.schema()
        };
    }

    static schema = () => ReactComponent.schema({ type: "Frequency", label: "Frequency" });

    attachReact = element => ReactDOM.render(<DefaultComp />, element);
    detachReact = element => element ? ReactDOM.unmountComponentAtNode(element) : undefined;
}