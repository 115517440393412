import React from "react";
import moment from "moment";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import * as S from "../../../services";
import { T, TB, TC } from "../../../Constants";

//#region Types
type Resources = ReturnType<T.API.Utils.Energy.GetAlarmMailing>;

export type AlarmMailingProps = {
    /** Show the form in a popup */
    popup?: boolean;
    /** Id of the site, to create alarm mailing per site */
    site: string;
    /** Props for the modal */
    modalProps?: M.StyleModalProps;
    /** Callback for quitting */
    onQuit?: () => void;
}
//#endregion

const AlarmMailing: React.FC<AlarmMailingProps> = props => {
    const lg = H.useLanguage();
    const [resources, set_resources, resources_status] = H.useAsyncState<Resources>({ created: [], subscribed: [], users: [], alarms: [] });

    React.useEffect(() => {
        let isSubscribed = true;
        S.getAlarmMailing(props.site)
            .then(({ data }) => isSubscribed && set_resources(data, "done"))
            .catch(() => isSubscribed && set_resources({ users: [], created: [], subscribed: [], alarms: [] }, "error"));
        return () => {
            isSubscribed = false;
            set_resources({ users: [], created: [], subscribed: [], alarms: [] }, "load");
        }
    }, [props.site, set_resources]);

    const format = React.useMemo(() => ({
        date: (date: string) => moment(date).format("DD/MM/YY HH:mm"),
        alarms: (alarms: any[]) => {
            let options = resources.alarms.filter(a => alarms.includes(a.value)).map(a => a.label).join();
            return options;
        },
        freq: (frequency: string) => {
            let tr = TB.translate_frequency(frequency);
            if (!tr) return "N/A";
            return tr.amount + " " + lg.getStaticText(tr.ref);
        },
    }), [lg, resources.alarms]);

    const events = React.useMemo(() => ({
        create: () => {
            S.createMailingTask(props.site)
                .then(({ data }) => set_resources(r => ({ ...r, created: r.created.concat(data) })))
                .catch(M.Alerts.updateError);
        },
        remove: (task: string) => {
            M.askConfirm().then(confirmed => {
                if (confirmed) S.removeMailingTask(task)
                    .then(() => set_resources(p => ({ ...p, created: p.created.filter(t => t._id !== task) })))
                    .catch(M.Alerts.deleteError);
            });
        },
        change: (task: string, prop: Parameters<typeof S.updateMailingTask>[0]["prop"], value: any) => {
            S.updateMailingTask({ prop, value, task }).then(() => {
                set_resources(p => ({
                    ...p,
                    created: p.created.map(t => {
                        if (t._id !== task) return t;
                        else if (prop === "parameters.users") return { ...t, parameters: { ...t.parameters, users: value } };
                        else if (prop === "parameters.alarms") return { ...t, parameters: { ...t.parameters, alarms: value } };
                        return { ...t, [prop]: value };
                    })
                }));
            }).catch(M.Alerts.updateError);
        },
        unsubscribe: (task: string) => {
            M.askConfirm({ title: TC.ALARMS_MAILING_UNSUBSCRIBE, text: TC.ALARMS_MAILING_UNSUB_TEXT }).then(confirmed => {
                if (confirmed) S.unsubscribeMailingTask(task)
                    .then(() => set_resources(p => ({ ...p, subscribed: p.subscribed.filter(t => t._id !== task) })))
                    .catch(M.Alerts.updateError);
            });
        }
    }), [set_resources, props.site]);

    const add_user = React.useCallback((text: string, task_id: string) => {
        let task = resources.created.filter(c => c._id === task_id)[0];
        // Make sure the email is correct
        if (task) M.askPrompt({ mail: true, label: TC.GENERAL_USERNAME, title: TC.MISSION_USERS_INPUT_MAIL, defaultVal: text, isRequired: true }).then((mail: string) => {
            // Check the availability of this email address
            if (mail) {
                // Check if the email is not already loaded
                let loaded_user = resources.users.filter(u => u.mail === mail)[0];
                // User was available
                if (loaded_user) {
                    let is_in_mailing = task.parameters.users.includes(loaded_user.value);
                    // The user already has access to the mission
                    if (is_in_mailing) M.renderAlert({ type: "info", message: TC.MAILING_ALARM_USER_ALREADY_SELECTED });
                    // The user did not have access to the mission, so we give him
                    else events.change(task_id, "parameters.users", task.parameters.users.concat(loaded_user.value));
                }
                // User was not available
                else S.mailExists({ mail }).then(mail_info => {
                    let user = mail_info.data as T.Submission<T.UserData>;
                    // Can't use this email address
                    if (mail_info.data === "duplicate" || mail_info.data === "forbidden") M.renderAlert({ type: "warning", message: TC.AUTH_NEW_USER_MAIL_FORBIDDEN });
                    // Must create a new user to use this email and give him access to missions for this context
                    else if (mail_info.data === "free") M.askNameMail({ value: { name: "", email: mail }, disable_mail: true, modal: { title: TC.MISSION_USER_CREATE_NEW } }).then(infos => {
                        if (infos) S.createShellUser(infos).then(({ data }) => {
                            // Add the user to the mission property
                            events.change(task_id, "parameters.users", task.parameters.users.concat(data._id));
                            // Add the user to the list of available users
                            set_resources(p => ({
                                ...p,
                                users: p.users.concat({
                                    value: data._id,
                                    label: data.data.name,
                                    mail: data.data.email,
                                })
                            }));
                        }).catch(M.Alerts.updateError);
                    });
                    // User already exists, add him to the list, he will receive his access on save
                    else {
                        // Add the user to the mission property
                        events.change(task_id, "parameters.users", task.parameters.users.concat(user._id));
                        // Add the user to the list of available users
                        set_resources(p => ({
                            ...p,
                            users: p.users.concat({
                                value: user._id,
                                label: user.data.name,
                                mail: user.data.email,
                            })
                        }));
                    }
                }).catch(M.Alerts.loadError);
            }
        });
    }, [resources.users, resources.created, events, set_resources]);

    return React.createElement(
        props.popup ? M.BlankModal : React.Fragment,
        props.popup ? {
            ...props.modalProps,
            autofocus: false,
            onQuit: props.onQuit,
            disableEnforceFocus: true,
            size: props.modalProps?.size || "lg",
            title: props.modalProps?.title || TC.MAILING_ALARM_TITLE,
            maxBodyHeight: props.modalProps?.maxBodyHeight || "60vh",
        } as M.BlankModalProps : null,

        <C.Spinner status={resources_status} min_load_size="350px">

            <div className="mb-3">
                <C.Title level={3} text={TC.ALARMS_MAILING_MY_ALARMS} />

                {resources.created.length === 0
                    ? <C.CenterMessage className="my-3" children={TC.MAILING_ALARM_NONE_CREATED} />
                    : resources.created.map((task, i) => <div className="p-2">

                        <C.Flex alignItems="center" justifyContent="between" className="mb-2">
                            <C.Title level={5}>{lg.getStaticText(TC.MAILING_ALARM_LISTING_NUM_N, i + 1)}</C.Title>
                            <C.Button
                                size="sm"
                                icon="times"
                                variant="link"
                                text={TC.GLOBAL_DELETE}
                                style={{ color: "red" }}
                                onClick={() => events.remove(task._id)}
                            />
                        </C.Flex>

                        <BS.Row key={task._id} className="mb-2 g-1 border-bottom align-item-center">
                            <BS.Col md={6}>
                                <C.Form.Select
                                    multiple
                                    options={resources.alarms}
                                    typeahead={{ height: "md" }}
                                    value={task.parameters.alarms}
                                    label={TC.MAILING_ALARMS_ALARM}
                                    onChange={u => events.change(task._id, "parameters.alarms", u)}
                                />
                            </BS.Col>
                            <BS.Col md={6}>
                                <C.Form.Select
                                    multiple
                                    options={resources.users}
                                    value={task.parameters.users}
                                    label={TC.MAILING_ALARMS_USERS}
                                    addResourceLabel={TC.MISSION_USER_ADD_LABEL}
                                    onChange={u => events.change(task._id, "parameters.users", u)}
                                    typeahead={{
                                        height: "md",
                                        allowNewOption: true,
                                        extra_search_props: "mail",
                                        onAddOption: text => add_user(text, task._id),
                                    }}
                                />
                            </BS.Col>
                            <BS.Col md={6}>
                                <C.Form.Frequency
                                    noReset
                                    value={task.frequency}
                                    label={TC.MAILING_ALARMS_FREQ}
                                    onChange={f => events.change(task._id, "frequency", f)}
                                />
                            </BS.Col>
                            <BS.Col md={6}>
                                <C.Form.DateTime
                                    enableTime
                                    value={task.next_scheduled}
                                    label={TC.MAILING_ALARMS_NEXT_EXEC}
                                    onChange={d => events.change(task._id, "next_scheduled", d)}
                                />
                            </BS.Col>
                        </BS.Row>
                    </div>)}

                <C.Flex justifyContent="end">
                    <C.Button size="sm" icon="plus" text={TC.ALARMS_MAILING_CREATE} onClick={events.create} />
                </C.Flex>
            </div>

            <div className="mb-3">
                <C.Title level={3} text={TC.ALARMS_MAILING_SUBSCRIBED} />

                {resources.subscribed.length === 0
                    ? <C.CenterMessage className="my-3" children={TC.ALARMS_MAILING_NO_SUBSCRIPTION} />
                    : resources.subscribed.map((task, i) => <div className="p-2">

                        <C.Flex alignItems="center" justifyContent="between" className="mb-1">
                            <C.Title level={6}>{lg.getStaticText(TC.MAILING_ALARM_LISTING_NUM_N, i + 1)}</C.Title>
                            <C.Button
                                size="sm"
                                icon="times"
                                variant="link"
                                style={{ color: "red" }}
                                text={TC.ALARMS_MAILING_UNSUBSCRIBE}
                                onClick={() => events.unsubscribe(task._id)}
                            />
                        </C.Flex>

                        <BS.Row key={task._id} className="mb-2 g-1">
                            <BS.Col className="text-start">
                                {format.alarms(task.parameters?.alarms || [])}
                            </BS.Col>
                            <BS.Col className="text-center">
                                {format.freq(task.frequency)}
                            </BS.Col>
                            <BS.Col className="text-end">
                                {format.date(task.next_scheduled)}
                            </BS.Col>
                        </BS.Row>
                    </div>)}
            </div>
        </C.Spinner >
    );
}

export default AlarmMailing;