import * as Formio from 'react-formio';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { FormBuilder, Components } from 'react-formio';
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useRef, useMemo, useCallback } from 'react';

import './FormViewer.scss';
import { Form } from "../Form";
import { URL } from '../../Constants';
import * as components from "./Components";
import BASE_EDIT_FORM from '../Formio/Settings';
import * as US from '../../services/user.service';
import * as Constants from '../../Constants/Constants';

Components.setComponents(components);
Formio.Formio.baseUrl = URL.APP_DOMAIN;
Formio.Formio.projectUrl = URL.APP_DOMAIN;
Formio.Formio.projectUrlSet = true;

//Can't be in state, because it triggers into an infinity loop
var lastFormChange = undefined;

const FormViewer = ({ ...props }) => {
    const navigate = useNavigate();
    const params = useParams();

    const auth = useSelector(({ auth }) => auth);
    const [currentUser, setUser] = useState();

    //The form to be displayed, empty if new one
    const [form, setForm] = useState(params?.action === Constants.CREATE_FORM ? {} : undefined);
    const [formTitle, setFormTitle] = useState('');
    const [error, setError] = useState();

    //The type of new form if we are creating a new one
    const [type, setType] = useState(params?.type);
    const typeRef = useRef(type);

    //Are we creating a new form or editing an existing one ?
    const [isNewForm, setIsNewForm] = useState(params?.action === Constants.CREATE_FORM);
    const isNewFormRef = useRef(isNewForm);

    //Are we building or editing a form, if false we are just seeing a submission
    const [action, setAction] = useState(params?.action);

    //First time we pass through the onChangeListener
    const [onChangeFirstTime, setOnChangeFirstTime] = useState(true);
    const onChangeFirstTimeRef = useRef(onChangeFirstTime);

    const homeButtonRef = useRef();

    useEffect(() => auth.then(({ user }) => setUser(user)), [auth]);
    useEffect(() => currentUser === null ? navigate("/login", { replace: true }) : undefined, [currentUser, navigate]);
    useEffect(() => error && !error.isNotUnique && formTitle.length > 0 ? setError() : undefined, [error, formTitle]);

    useEffect(() => {
        if (form !== undefined && form._id !== params?.formId) setForm(undefined);
        if (action !== params?.action) setAction(params?.action);
        if (type !== params?.type) setType(params?.type);
        setIsNewForm(params?.action === Constants.CREATE_FORM);
    }, [params, form, action, type]);

    useEffect(() => {
        try {
            if (form === undefined && !isNewForm) {
                US.getFormsFromId([params?.formId]).then(reply => {
                    if (Array.isArray(reply.data) && reply.data.length === 1) {
                        setFormTitle(reply.data[0].title);
                        setForm(reply.data[0]);
                    }
                });
            }
        }
        catch (err) {
            console.log(err);
        }
    }, [form, isNewForm, params]);

    useEffect(() => {
        onChangeFirstTimeRef.current = onChangeFirstTime;
        isNewFormRef.current = isNewForm;
        typeRef.current = type;
    }, [onChangeFirstTime, type, isNewForm]);

    const formPath = useMemo(() => {
        //Remove all non letter/numbers from the string
        return '/' + formTitle.toLowerCase().replace(/[^0-9a-z]/gi, '');
    }, [formTitle]);

    const onChangeListener = useCallback(schema => {
        //First time we don't set the submission or it reset it
        if (onChangeFirstTimeRef.current) setOnChangeFirstTime(false);
        lastFormChange = schema;
    }, []);

    const sendHome = useCallback(() => homeButtonRef.current?.click?.(), []);
    const updateFormInDB = useCallback(form => US.updateForm(form).then(sendHome), [sendHome]);

    const createNewFormDB = useCallback(async form => {
        //Add the new owner to the form
        form.owner = currentUser._id;
        //Create the form in db and recuperate it (for the id)
        let reply = await US.createForm(form);
        //Create the default action in db
        await US.createAction(Constants.GET_DEFAULT_ACTION(reply.data._id, form.title));
        sendHome();
    }, [currentUser, sendHome]);

    const pathAndTitleAreUnique = useCallback(async (formInfos) => {
        try {
            let isUnique = true;
            let reply = await US.formInfosAlreadyExists(formInfos.title, formInfos.path);

            //If we are creating a new form, neither must exists
            if (isNewForm && reply.data === null) return true;
            if (Array.isArray(reply.data)) {
                //If we are editing a new one, all the results from the query must have the same id as the form here
                for (let formDB of reply.data) {
                    if (formDB._id !== form._id) isUnique = false;
                }
            }
            return isUnique;
        }
        catch (err) {
            console.log(err);
        }
    }, [form, isNewForm]);

    const getFormInfosValues = useCallback(() => ({
        title: formTitle,
        path: form?.path || formPath.slice(1),
    }), [formTitle, form, formPath]);

    const saveForm = useCallback(async () => {
        //Recuperate the forms infos
        let formInfos = getFormInfosValues();

        let infosAreUnique = await pathAndTitleAreUnique(formInfos);

        if (formInfos.title !== '' && infosAreUnique) {
            let formName = formInfos.title.toLowerCase().replace(/[^a-z]/g, '');

            //Create a new form in the db
            if (isNewFormRef.current) {
                let submissionAccess, access;

                if (typeRef.current === Constants.FORMIO_FORM_TYPE_FORM) {
                    submissionAccess = Constants.FORMIO_SUBMISSION_ACCESS_FORM_DEFAULT;
                    access = Constants.FORMIO_ACCESS_FORM_DEFAULT;
                }
                else {
                    submissionAccess = Constants.FORMIO_SUBMISSION_ACCESS_RESOURCE_DEFAULT;
                    access = Constants.FORMIO_ACCESS_RESOURCE_DEFAULT;
                }

                let form = {
                    components: lastFormChange.components,
                    title: formInfos.title,
                    name: formName,
                    path: formInfos.path,
                    machineName: formName,
                    type: typeRef.current,
                    submissionAccess: submissionAccess,
                    access: access
                };

                createNewFormDB(form);

            }
            //Update a form that already exists
            else {
                //Last form change is the form updated (for the components only)
                let formUpdated = lastFormChange;

                formUpdated.title = formInfos.title;
                formUpdated.name = formName;
                formUpdated.path = formInfos.path;
                formUpdated.modified = new Date();

                updateFormInDB(formUpdated);
            }
        }
        else setError((formInfos.title ?
            { msg: "Path and title aren't unique!", isNotUnique: true }
            : { msg: "You failed to enter a title" }
        ));
    }, [createNewFormDB, getFormInfosValues, pathAndTitleAreUnique, updateFormInDB]);

    const customComponents = useMemo(() => Object.fromEntries(Object.entries(components).map(([key, val]) => [key, true])), []);

    const builderOptions = useMemo(() => ({
        ...BASE_EDIT_FORM,
        noDefaultSubmitButton: true,
        builder: {
            ...BASE_EDIT_FORM.builder,
            custom: { components: customComponents, title: "Custom" },
        }
    }), [customComponents]);

    return <div>
        {/* Display a formBuilder that can be used to create a new form or edit a existing one */}
        {((action === Constants.CREATE_FORM || action === Constants.EDIT_FORM) && form !== undefined) && <div className={Constants.FORM_VIEWER_FORM_BUILDER}>

            <div className='mt-2 mb-3'>
                <div>
                    <label>Title</label>
                    <input type="text" className='form-control' value={formTitle} onChange={event => setFormTitle(event.target.value)} />
                </div>
                <div>
                    <label>Path</label>
                    <input type="text" className='form-control' value={(form?.path ? '/' + form.path : formPath)} disabled={true} />
                </div>
                {error && <div id="errorMessage">
                    <span>{error.msg}</span>
                </div>}
            </div>

            <FormBuilder form={form} onChange={onChangeListener} options={builderOptions} />
            <div className={Constants.FORM_VIEWER_BUTTONS}>
                <Link to={"/formBuilding"}>
                    <button className={`${Constants.FORM_VIEWER_BUTTONS} ${Constants.FORM_VIEWER_CANCEL}`}>Cancel</button>
                </Link>
                <button className={`${Constants.FORM_VIEWER_BUTTONS} ${Constants.FORM_VIEWER_SAVE}`} onClick={saveForm} >Save</button>
            </div>
        </div>}

        {/* Display a form that can be used to add a new Submission */}
        {(action !== Constants.CREATE_FORM && action !== Constants.EDIT_FORM && form !== undefined) && <div className={Constants.FORM_VIEWER_FORM_DIV}>
            <Form path={form.path} onSave={sendHome} />
            <div className={Constants.FORM_VIEWER_BUTTONS_DIV}>
                <Link to={"/formBuilding"}>
                    <button className={`${Constants.FORM_VIEWER_BUTTONS} ${Constants.FORM_VIEWER_CANCEL}`}>Cancel</button>
                </Link>
            </div>
        </div>}

        <Link to='/'>
            <button hidden={true} ref={homeButtonRef} id="goHomeButton"></button>
        </Link>
    </div>
}

export default FormViewer;