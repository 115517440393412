import _ from "lodash";
import { Button } from "react-bootstrap";
import { ActionItemProps } from "./ActionItem";
import ActionContainer from "./ActionContainer";
import DraggableActionItem from "./DraggableActionItem";
import { FP, RIGHTS, T, TB, TC } from "../../../Constants";
import React, { useEffect, useMemo, useState } from "react";
import { Flex, IconButton, Spinner, TypeAhead } from "../../../Common";
import { useAuth, useElementSize, useLanguage, useRights } from "../../../hooks";

type Actions = ReturnType<T.API.Actions.GetPlanner>["actions"];
type Sorters = "date_asc" | "date_desc" | "urgency_asc" | "urgency_desc";
type Sort = Record<Sorters, (a: Actions[number], b: Actions[number]) => number>;

export type ActionsViewProps = {
    /** Are the data loading ? */
    loading?: boolean;
    /** The height of the ticket container, in pixel */
    height: number;
    /** A list of non-scheduled actions */
    actions: Actions;
    /** Callback to delete an action */
    onRemove?: (action: Actions[number]) => void;
    /** Callback to edit an action */
    onEdit?: (action: Actions[number]) => void;
    /** Remove the sorting logic for the component */
    hideSort?: boolean;
    /** Are the items draggable in a calendar or in a container */
    drag?: "calendar" | "container"
    /** Callback for when an action has been drop in this container */
    onDrop?: (action: ActionItemProps["action"]) => void;
    /** Callback to add an option */
    onAdd?: () => void;
}

const PAGE_SIZE = 20;
const TEXT_CODES = [FP.BUILDING_FORM, FP.SITE_FORM, TC.GLOBAL_LABEL_EMPLACEMENT, TC.GLOBAL_LOCAL];

const ActionsView: React.FC<ActionsViewProps> = props => {
    const rights = useRights();
    const [{ userId }] = useAuth();
    const lg = useLanguage(TEXT_CODES);
    const [page, setPage] = useState(0);
    const [setHeaderSize, headerSize] = useElementSize();
    const [setFooterSize, footerSize] = useElementSize();
    const [sort, setSort] = useState<Sorters>("date_desc");

    //#region Processed Actions
    const preProcessedActions = useMemo(() => props.actions.map(a => {
        let isReg = a.type === "reg";
        let isOwn = a.owner === userId;

        let right = isOwn
            ? (isReg ? RIGHTS.ACTION.WRITE_REG_ACTIONS : RIGHTS.ACTION.WRITE_MAINTENANCE_ACTION)
            : (isReg ? RIGHTS.ACTION.WRITE_OTHER_REG_ACTION : RIGHTS.ACTION.WRITE_OTHER_MAINTENANCE_ACTION);

        return { ...a, canEdit: rights.isRightAllowed(right) };
    }), [rights, userId, props.actions]);
    //#endregion

    //#region Sort
    const sortOptions = useMemo<{ value: Sorters, label: string }[]>(() => [
        { value: "date_asc", label: "date_asc" },
        { value: "date_desc", label: "date_desc" },
        { value: "urgency_asc", label: "urgency_asc" },
        { value: "urgency_desc", label: "urgency_desc" },
    ], []);

    const sorts = useMemo<Sort>(() => {
        const sortByDateAsc: Sort[Sorters] = (a, b) => {
            const [aDate, bDate] = [a.end_date, b.end_date].map(date => TB.getDate(date)?.getTime?.() || NaN);

            if (isNaN(aDate) && isNaN(bDate)) return 0;
            else if (isNaN(aDate)) return 1;
            else if (isNaN(bDate)) return -1;
            else return aDate - bDate;
        }

        const sortByUrgencyAsc: Sort[Sorters] = (a, b) => {
            const getNumericalUrgency = (urgency: typeof a["urgency"]) => {
                if (urgency === "high") return 3;
                if (urgency === "medium") return 2;
                if (urgency === "low") return 1;
                return 0;
            }
            return getNumericalUrgency(a.urgency) - getNumericalUrgency(b.urgency);
        }

        return {
            "date_asc": sortByDateAsc,
            "urgency_asc": sortByUrgencyAsc,
            "date_desc": (a, b) => -1 * sortByDateAsc(a, b),
            "urgency_desc": (a, b) => -1 * sortByUrgencyAsc(a, b),
        }
    }, []);

    useEffect(() => lg.fetchStaticTranslations(sortOptions.map(o => o.label)), [sortOptions, lg]);
    // Need to concat because .sort doesn't change the array ref
    const sortedActions = useMemo(() => {
        if (props.hideSort) return preProcessedActions;
        return [].concat(preProcessedActions.sort(sorts[sort]))
    }, [sort, preProcessedActions, sorts, props.hideSort]);
    //#endregion

    //#region Pagination
    // Reset current page if number of actions change
    useEffect(() => setPage(0), [props.actions.length]);
    const maxPages = useMemo(() => Math.floor(props.actions.length / PAGE_SIZE), [props.actions.length]);

    const pagination = useMemo(() => ({
        canBack: page > 0,
        canNext: page < maxPages,
        toStart: () => setPage(0),
        toEnd: () => setPage(maxPages),
        toPrev: () => setPage(p => p - 1 < 0 ? 0 : p - 1),
        toNext: () => setPage(p => p + 1 > maxPages ? maxPages : p + 1),
    }), [page, maxPages]);

    const currentActions = useMemo(() => _.slice(sortedActions, page * PAGE_SIZE, (page + 1) * PAGE_SIZE), [page, sortedActions]);
    //#endregion

    //#region Action Container
    const proppedActions = useMemo<ActionItemProps[]>(() => currentActions.map(a => ({
        action: a,
        allowedEdit: a.canEdit,
        onEdit: props.onEdit && (() => props.onEdit(a)),
        onRemove: props.onRemove && (() => props.onRemove(a)),
        variant: a.urgency === "high" ? "danger" : (a.urgency === "medium" ? "warning" : (a.urgency === "low" ? "success" : undefined)),
    })), [currentActions, props]);

    const actionContainer = useMemo(() => {
        if (props.drag === "container") return <ActionContainer actions={proppedActions} onDrop={props.onDrop} />;
        if (proppedActions.length === 0) return <Flex className="h-100" alignItems="center" justifyContent="center">
            <span className="text-muted fst-italic">
                todo no actions
            </span>
        </Flex>;
        return proppedActions.map(a => <DraggableActionItem key={a.action.id} {...a} />);
    }, [proppedActions, props.drag, props.onDrop]);
    //#endregion

    const canAddAction = useMemo(() => {
        return rights.isRightAllowed(RIGHTS.ACTION.WRITE_REG_ACTIONS) || rights.isRightAllowed(RIGHTS.ACTION.WRITE_MAINTENANCE_ACTION);
    }, [rights]);

    const containerSize = useMemo(() => props.height - headerSize.height - footerSize.height, [props.height, headerSize.height, footerSize.height]);

    return <div className="mx-2 rounded" style={{ height: props.height }}>
        <Spinner loading={props.loading} size="md">
            <>
                <div ref={setHeaderSize}>
                    {!props.hideSort && <Flex className="mb-2" alignItems="center">
                        <i className="me-3 fa fa-sort"></i>
                        <TypeAhead
                            selectedItems={sort}
                            options={sortOptions}
                            className="flex-grow-1"
                            onChange={o => setSort(p => o?.[0]?.value as Sorters || p)}
                        />
                    </Flex>}

                    <Flex justifyContent="between">
                        <div>
                            <IconButton
                                className="me-2"
                                icon="angle-double-left"
                                onClick={pagination.toStart}
                                disabled={!pagination.canBack}
                            />
                            <IconButton
                                icon="angle-left"
                                onClick={pagination.toPrev}
                                disabled={!pagination.canBack}
                            />
                        </div>
                        <div>
                            <strong>{page + 1} / {maxPages + 1}</strong>
                        </div>
                        <div>
                            <IconButton
                                icon="angle-right"
                                onClick={pagination.toNext}
                                disabled={!pagination.canNext}
                            />
                            <IconButton
                                className="ms-2"
                                icon="angle-double-right"
                                onClick={pagination.toEnd}
                                disabled={!pagination.canNext}
                            />
                        </div>
                    </Flex>
                </div>
                <div className="m-2 overflow-auto" style={{ height: containerSize }}>
                    {actionContainer}
                </div>
                {props.onAdd && canAddAction && <div className="p-2" ref={setFooterSize}>
                    <Flex alignItems="center" justifyContent="end">
                        <Button onClick={props.onAdd}>
                            <i className="fa fa-plus me-2"></i>
                            todo add
                        </Button>
                    </Flex>
                </div>}
            </>
        </Spinner>
    </div>;
}

export default ActionsView;