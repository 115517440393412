import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import Avatar, { AvatarProps } from './Avatar';

type NotificationProps = {
    time: string;
    emoji?: string;
    flush?: boolean;
    unread?: boolean;
    className?: string;
    avatar?: AvatarProps;
    children?: ReactNode | ReactNode[];
};

const Notification: FC<NotificationProps> = ({ avatar, time, className, unread = false, flush = false, emoji, children }) => (
    <Link className={classNames('notification', { 'notification-unread': unread, 'notification-flush': flush }, className)} to="#!" >
        {avatar && <div className="notification-avatar">
            <Avatar {...avatar} className="me-3" />
        </div>}
        <div className="notification-body">
            {typeof children === "string"
                ? <p className="mb-1" dangerouslySetInnerHTML={{ __html: children }} />
                : <div className='mb-1'>{children}</div>}

            <span className="notification-time">
                {emoji && <span className="me-2" role="img" aria-label="Emoji">
                    {emoji}
                </span>}
                {time}
            </span>
        </div>
    </Link>
);

export default Notification;
