import React from "react";
import * as M from "../../Modal";
import * as S from "../../../services";
import { T, TB, TC } from "../../../Constants";
import { Button, Flex, Form } from "../../../Common";
import * as H from "../../../hooks";

//#region Types
type Entry = T.API.Utils.Energy.ManualEntry<string | number>;
type Dataset = ReturnType<T.API.Utils.Datasets.GetDatasetItemTable>;

export type EntryFormProps = {
    /** A pre-completed value */
    value?: Partial<Entry>;
    /** The id of the dataset */
    dataset: string;
    /** Callback for submission */
    onSubmit: (entry: Entry) => void;
    /** Ask or not for the date */
    askDate?: boolean;
    /** Render the form as a popup */
    popup?: boolean;
    /** Modal parameters */
    modalStyle?: M.StyleModalProps;
}
//#endregion

const create_entry = (props: EntryFormProps): Entry => ({
    note: props.value?.note,
    dataset: props.dataset || "",
    time: props.value?.time || "",
    value: props.value?.value || undefined,
});

const EntryForm: React.FC<EntryFormProps> = props => {
    const lg = H.useLanguage();
    const [file, setFile] = React.useState<T.File[]>([]);
    const [entry, setEntry] = React.useState(create_entry(props));
    const [errors, setErrors] = React.useState<T.Errors<Entry>>({});
    const [dataset, set_dataset, status] = H.useAsyncState<Dataset>([]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getDatasetItemTable(props.dataset)
            .then(({ data }) => isSubscribed && set_dataset(data, "done"))
            .catch(() => isSubscribed && set_dataset([], "error"));
        return () => {
            isSubscribed = false;
            set_dataset([], "load");
        }
    }, [props.dataset, set_dataset]);

    const modal_name = React.useMemo(() => {
        if (props.modalStyle?.title) return props.modalStyle?.title;
        else {
            let name = lg.getStaticText(TC.DATA_ENTRY_ADD_TITLE);
            let row = dataset.find(d => d._id === props.dataset);
            if (row) name += ` : ${row.element} - ${row.name}`;
            return name;
        }
    }, [props.modalStyle?.title, props.dataset, lg, dataset]);

    //#region Form
    const form = React.useMemo(() => <div>
        <Form.NumField
            required
            value={entry.value}
            label={TC.DATA_ENTRY_VALUE}
            error={{ code: errors.value }}
            onChange={value => setEntry(p => ({ ...p, value }))}
        />
        {props.askDate && <Form.DateTime
            required
            enableTime
            value={entry.time}
            label={TC.DATA_ENTRY_TIME}
            error={{ code: errors.time }}
            onChange={time => setEntry(p => ({ ...p, time }))}
        />}
        <Form.TextField
            textArea
            rows={3}
            autoExpand
            value={entry.note}
            label={TC.DATA_ENTRY_NOTE}
            onChange={note => setEntry(p => ({ ...p, note }))}
        />
        <Form.FileUploader
            image
            value={file}
            onChange={setFile}
            label={TC.DATA_ENTRY_PICTURE}
            error={{ code: errors.picture }}
        />
    </div>, [entry, errors, file, props.askDate]);
    //#endregion

    //#region Save
    const onSave = React.useCallback(async () => {
        let new_errors: typeof errors = {};
        let non_removed_files = file.filter(f => f.storage !== "removed");
        if (props.askDate && !TB.getDate(entry.time)) new_errors.time = TC.GLOBAL_REQUIRED_FIELD;
        if (typeof entry.value !== "number" || isNaN(entry.value)) new_errors.value = TC.GLOBAL_REQUIRED_FIELD;

        if (non_removed_files.length > 0) {
            let reply = await S.tempFileExists(non_removed_files[0].name);
            if (!reply.data) new_errors.picture = TC.FILE_FAILED_UPLOAD;
        }

        if (Object.keys(new_errors).length > 0) setErrors(new_errors);
        else props.onSubmit({ ...entry, picture: non_removed_files?.[0]?.name });
    }, [entry, file, props]);

    const submit_button = React.useMemo(() => <Flex justifyContent="end">
        <Button text={TC.CONFIRM} icon="save" onClick={onSave} />
    </Flex>, [onSave]);
    //#endregion

    if (props.popup && status === "done") return <M.BlankModal
        {...props.modalStyle}
        title={modal_name}
        footer={submit_button}
        size={props.modalStyle?.size || "sm"}
        children={form}
    />;
    else return <M.Loader />;
}

export default EntryForm;