import axios from "axios";
import { getHeader } from "../Headers";
import { URL, T } from "../../Constants";

const { LINK_API } = URL;

type CreateLink = T.API.ToAxios<T.API.Utils.Link.CreateLink>;
type RemoveLinks = T.API.ToAxios<T.API.Utils.Link.RemoveLinks>;
type LinkTypesPossible = T.API.ToAxios<T.API.Utils.Link.LinkTypesPossible>;

export const createLink: CreateLink = link => axios.post(LINK_API + "create", link, getHeader("CREATE"));
export const getTypesAvailable: LinkTypesPossible = params => axios.get(LINK_API + "available", { params });
export const removeLinks: RemoveLinks = ids => axios.post(LINK_API + "remove", { ids }, getHeader("DELETE"));