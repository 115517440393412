import _ from "lodash";
import React from "react";
import * as G from "../Grid";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as S from "../../../services";
import * as PM from "../../../PurposeModal";
import { CellsTypes as CT } from "../AgGridDefs";
import { RIGHTS as R, TC, FP, TABS, T, TB, RIGHTS } from "../../../Constants";

//#region Types & Constants
type Row<A = {}> = (ReturnType<T.API.Utils.Tables.GetTicketRows>[number]) & A;

export type TicketProps<A = {}> = {
    /** The context to load the actions from */
    context?: T.ContextParams;
    /** The origin, to differentiate the saved configuration */
    origin?: string;
    /** Force to not show the "group" tickets */
    show_no_group?: boolean;
    /** Do not allow edition of the table */
    read_only?: boolean;
    /** Extra columns */
    extra_columns?: G.TableProps<Row<A>>["columns"];
    /** Add extra data to the rows */
    process_rows?: (rows: Row[]) => Row<A>[];
    /** Callback for when the grid is ready */
    onReadyGrid?: G.TableProps<Row<A>>["onReadyGrid"];
    /** Buttons to show in the toolbar */
    buttons?: G.TableProps<Row<A>>["extra_buttons"];
    /** Callback for inline edits */
    onValueChange?: (params: Parameters<G.TableProps<Row<A>>["onValueChange"]>[0]) => "processed" | "to_process";
}

export type TicketRef<A = {}> = {
    /** The table grid ref */
    table: React.MutableRefObject<G.TableRef<Row<A>>>;
    /** The loaded rows */
    rows: Row<A>[];
}
//#endregion

const RenderTickets = <A extends {},>({ process_rows, onValueChange, onReadyGrid, ...props }: TicketProps<A>, ref: React.ForwardedRef<TicketRef<A>>) => {
    const lg = H.useLanguage();
    const rights = H.useRights();
    const [forms] = H.useFormIds();
    const loading = H.useBoolean(false);
    const [{ isMobile, userId }] = H.useAuth();
    const table = React.useRef<G.TableRef<Row<A>>>(null);
    const [rows, set_rows, status] = H.useAsyncState<Row[]>([]);
    const [context, set_context, context_status] = H.useAsyncState<ReturnType<T.API.Utils.Context.ContextDestructure>>(null);

    //#region Rights
    const allowed = React.useMemo(() => ({
        event_own: rights.isRightAllowed(R.ACTION.WRITE_EVENTS),
        event_other: rights.isRightAllowed(R.ACTION.WRITE_OTHER_EVENTS),
        reg_own_edit: rights.isRightAllowed(R.ACTION.WRITE_REG_ACTIONS),
        reg_other_edit: rights.isRightAllowed(R.ACTION.WRITE_OTHER_REG_ACTION),
        ticket_own_edit: rights.isRightAllowed(R.ACTION.WRITE_MAINTENANCE_ACTION),
        ticket_other_edit: rights.isRightAllowed(R.ACTION.WRITE_OTHER_MAINTENANCE_ACTION),
    }), [rights]);
    //#endregion

    //#region Fetch Data
    React.useEffect(() => {
        let isSubscribed = true;
        S.getTicketRows(props.context)
            .then(({ data }) => isSubscribed && set_rows(data, "done"))
            .catch(() => isSubscribed && set_rows([], "error"));
        return () => {
            isSubscribed = false;
            set_rows([], "load");
        }
    }, [props.context, set_rows]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.contextDestructure(props.context)
            .then(({ data }) => isSubscribed && set_context(data, "done"))
            .catch(() => isSubscribed && set_context(null, "error"));
        return () => {
            isSubscribed = false;
            set_context(null, "load");
        }
    }, [props.context, set_context]);
    //#endregion

    //#region Rows, Formatters & Callbacks
    const tr_rows = React.useMemo<Row<A>[]>(() => {
        let action_rows = rows.map(ticket => ({
            ...ticket,
            tr_type: lg.getStaticText(ticket.type_label),
            tr_status: lg.getStaticText(ticket.status_label),
        }));
        if (props.show_no_group) action_rows = action_rows.filter(r => r.type !== "group");
        if (typeof process_rows === "function") return process_rows(action_rows);
        else return action_rows as any;
    }, [lg, rows, process_rows, props.show_no_group]);

    const event = React.useMemo(() => ({
        note: (row: Row) => M.renderNoteModal({ origin: row._id }),
        edit: (row: Row, readOnly = false) => {
            let footer: Parameters<typeof M.renderPopUpFormModal>[0]["footer"] = null;
            if (!TB.mongoIdValidator(row.signedBy)) footer = quit => <C.Button
                variant="info"
                icon="signature"
                text={TC.TICKET_TABLE_CLOSE_TICKET}
                onClick={() => {
                    quit();
                    event.close(row);
                }}
            />;

            M.renderPopUpFormModal<T.TicketData>({ path: FP.TICKET_FORM, hide_panels: ["note", "rem", "reg", "kpi", "data"], submissionId: row._id, readOnly, footer }).then(edited_ticket => {
                if (edited_ticket) {
                    let to_reload = [edited_ticket._id];
                    if (edited_ticket.data.type === "group") to_reload = to_reload.concat(edited_ticket.data.sub_tickets);

                    S.getTicketRowsIds(to_reload)
                        .then(({ data }) => set_rows(p => p.map(t => data.find(d => d._id === t._id) || t)))
                        .catch(M.Alerts.loadError);
                }
            });
        },
        delete: (selected_rows: T.AllowArray<Row>) => {
            let rows = TB.arrayWrapper(selected_rows);
            let title = rows.length > 1 ? TC.GLOBAL_DELET_N_ITEMS : TC.GLOBAL_DELETE;

            M.askConfirm({ title: lg.getStaticText(title, rows.length) }).then(confirmed => {
                if (confirmed) {
                    let ids = rows.map(r => r._id);
                    S.removeTickets({ ids })
                        .then(() => set_rows(prev_tickets => {
                            for (let row of rows) {
                                // The deleted row was a group, update it's children to not have a parent
                                if (row.type === "group") prev_tickets = prev_tickets.map(t => {
                                    if (t._id === row._id) return null;
                                    else if (t.type === "group") return t;
                                    else {
                                        let index_group = t.parents.indexOf(row._id);
                                        if (index_group === -1) return t;
                                        else return {
                                            ...t,
                                            parents: t.parents.filter((_, index) => index !== index_group),
                                            parent_group: t.parent_group.filter((_, index) => index !== index_group),
                                        };
                                    }
                                }).filter(t => t !== null);
                                // The deleted row was not a group,remove the ids from the list of children if they appear in a group
                                else prev_tickets = prev_tickets.map(t => {
                                    if (t._id === row._id) return null;
                                    else if (t.type !== "group") return t;
                                    else return { ...t, sub_tickets: t.sub_tickets.filter(s => s !== row._id) };
                                }).filter(t => t !== null);
                            }
                            return prev_tickets;
                        }))
                        .catch(M.Alerts.deleteError);
                }
            });
        },
        create: () => PM.renderContextElements({
            isRequired: true,
            add_all_parents: true,
            context: props.context,
            title: TC.P_NEW_TICKET_EQUIP_SELECT,
            forms: [FP.BUILDING_FORM, FP.EQUIPEMENT_FORM, FP.EMPLACEMENT_FORM],
        }).then(equipId => {
            if (TB.mongoIdValidator(equipId)) M.renderFormModal<T.TicketData>({ path: FP.TICKET_FORM, forcedSubmission: [{ prop: "equipment", value: equipId }] }).then(ticket => {
                if (ticket) {
                    let to_get_tickets = [ticket._id];
                    if (ticket.data.type === "group") to_get_tickets = to_get_tickets.concat(ticket.data.sub_tickets);
                    S.getTicketRowsIds(to_get_tickets)
                        .then(({ data }) => set_rows(prev_tickets => {
                            let new_ticket = data.find(d => d._id === ticket._id);
                            return prev_tickets.map(t => data.find(d => d._id === t._id) || t).concat(new_ticket);
                        }))
                        .catch(M.Alerts.loadError);
                }
            });
        }),
        create_group: (sub_tickets: Row[]) => {
            let start_date = sub_tickets.map(t => t.start_date).filter(TB.getDate).sort(TB.sortDates)[0];
            let end_date = sub_tickets.map(t => t.end).filter(TB.getDate).sort(TB.sortDates).reverse()[0];

            let submissions = [
                { prop: "end", value: end_date },
                { prop: "start", value: start_date },
                { prop: "type", value: "group", disable: true },
                { prop: "equipment", value: context?.ids?.[0] },
                { prop: "sub_tickets", value: sub_tickets.map(t => t._id) },
                { prop: "cost", value: sub_tickets.reduce((acc, t) => acc + (t.cost || 0), 0) },
            ] as Parameters<typeof M.renderFormModal>[0]["forcedSubmission"];

            M.renderFormModal<T.TicketData>({ path: FP.TICKET_FORM, forcedSubmission: submissions }).then(ticket => {
                if (ticket) {
                    let to_get_tickets = [ticket._id];
                    if (ticket.data.type === "group") to_get_tickets = to_get_tickets.concat(ticket.data.sub_tickets);
                    S.getTicketRowsIds(to_get_tickets)
                        .then(({ data }) => set_rows(prev_tickets => {
                            let new_ticket = data.find(d => d._id === ticket._id);
                            return prev_tickets.map(t => data.find(d => d._id === t._id) || t).concat(new_ticket);
                        }))
                        .catch(M.Alerts.loadError);
                }
            });
        },
        edit_element: (row: Row) => {
            // Find the right to edit the element
            let write_right = null;
            if (row.form_id === forms[FP.SITE_FORM]) write_right = RIGHTS.TECH.EDIT_SITE;
            else if (row.form_id === forms[FP.BUILDING_FORM]) write_right = RIGHTS.TECH.EDIT_BUILDING;
            else if (row.form_id === forms[FP.EQUIPEMENT_FORM]) write_right = RIGHTS.TECH.EDIT_EQUIPMENT;
            else if (row.form_id === forms[FP.EMPLACEMENT_FORM]) write_right = RIGHTS.TECH.EDIT_EMPLACEMENT;
            else write_right = RIGHTS.TECH.WRITE_OTHERS;
            // Check if the user has the right to edit the element
            let readOnly = !rights.isRightAllowed(write_right);
            M.renderPopUpFormModal({ _id: row.form_id, submissionId: row.equipment, hide_panels: ["note"], readOnly }).then(submission => {
                if (submission) {
                    loading.setTrue();
                    S.getTicketRowsIds(row._id)
                        .then(({ data }) => set_rows(p => p.map(t => data.find(d => d._id === t._id) || t)))
                        .catch(M.Alerts.loadError)
                        .finally(loading.setFalse);
                }
            });
        },
        close: (row: Row) => {
            M.renderStandALoneTicketSigner({ ticket: row._id }).then(ticket => {
                if (ticket) {
                    loading.setTrue();
                    S.getTicketRowsIds(row._id)
                        .then(({ data }) => set_rows(p => p.map(t => data.find(d => d._id === t._id) || t)))
                        .catch(M.Alerts.loadError)
                        .finally(loading.setFalse);
                }
            });
        }
    }), [lg, context, loading, rights, forms, props.context, set_rows]);
    //#endregion

    //#region Columns
    const form_id = React.useMemo(() => forms[FP.TICKET_FORM], [forms]);

    const get_options = React.useMemo(() => ({
        can_edit_tag: (row: Row) => {
            if (props.read_only) return false;
            let is_own_ticket = row.owner === userId;
            if (row.type === "reg") return is_own_ticket ? allowed.reg_own_edit : allowed.reg_other_edit;
            return is_own_ticket ? allowed.ticket_own_edit : allowed.ticket_other_edit;
        },
        tags: (row: Row) => new Promise<T.Option[]>((resolve, reject) => {
            let roots: T.AllowArray<string> = row.equipment;
            if (row.type === "group") roots = row.sub_tickets.map(t => tr_rows.find(r => r._id === t)?.equipment).filter(TB.mongoIdValidator);

            S.getNoteTags({ context: { roots }, type: "ticket" })
                .then(r => resolve(r.data))
                .catch(reject);
        }),
        create_tag: (text: string, row: Row) => new Promise<T.Option>((resolve, reject) => {
            let submission = { name: text, users: [userId], sites: [], type: "ticket" } as T.NoteTag;
            S.createSubmission({ submission, path: FP.NOTE_TAG_FORM }).then(({ data }) => {
                let new_tag = data.submissions[0] as T.Submission<T.NoteTag>;
                if (new_tag) {
                    let new_option = { label: new_tag.data.name, value: new_tag._id } as T.Option;
                    resolve(new_option);
                }
                else resolve(null);
            }).catch(reject);
        }),
        note_button: {
            action: row => row && event.note(row),
            buttonProps: { variant: "primary", size: "sm", icon: "sticky-note", text: isMobile ? undefined : TC.GLOBAL_NOTES },
        } as G.ColDefParams<Row>,
    }), [userId, event, isMobile, tr_rows, allowed, props.read_only]);

    const columns = React.useMemo(() => {
        let columns = [
            { field: "title", headerName: TC.GLOBAL_TITLE },
            { field: "owner", headerName: TC.GLOBAL_SUB_OWNER },
            { field: "created", headerName: TC.GLOBAL_CREATED_DATE, type: CT.TYPE_DATE },
            { field: "element", headerName: TC.GLOBAL_ELEMENT, type: CT.TYPE_LINKED_ELEM, params: { render_form: event.edit_element } },
            { field: "tr_status", headerName: TC.GLOBAL_STATUS },
            { field: "tr_type", headerName: TC.GLOBAL_TYPE },
            { field: "description", headerName: TC.DESCRIPTION },
            { field: "estimated_cons", headerName: "estimated_cons", type: CT.TYPE_NUMBER, params: { header_id: form_id } },
            { field: "parent_group", headerName: TC.TICKET_PARENT_ACTION },
            {
                headerName: "tags",
                field: "tags_names",
                type: CT.TYPE_SELECT,
                editable: p => get_options.can_edit_tag(p.data),
                params: {
                    multiple: true,
                    header_id: form_id,
                    field_value: "tags",
                    getValues: get_options.tags,
                    typeahead: { allowNewOption: true, onAddOption: get_options.create_tag },
                }
            },
            { field: "assigned_users", headerName: TC.GLOBAL_SERVICE_WORKER },
            {
                headerName: TC.DURATION,
                children: [
                    { field: "start_date", headerName: TC.P_START_DATE, type: CT.TYPE_DATE, params: { isDateTime: true } },
                    { field: "end", headerName: TC.P_END_DATE, type: CT.TYPE_DATE, params: { isDateTime: true } },
                    { field: "duration", headerName: TC.DURATION },
                ]
            },
            {
                headerName: TC.ER_SIGNATURE,
                children: [
                    { field: "signed_by_user", headerName: TC.GT_SIGNED_BY },
                    { field: "dateSignature", headerName: TC.GT_SIGNED_DATE, type: CT.TYPE_DATE, params: { isDateTime: true } },
                ]
            },
            {
                headerName: TC.ER_PICTURES,
                children: [
                    { field: "before", headerName: TC.GLOBAL_BEFORE, type: CT.TYPE_FILE },
                    { field: "after", headerName: TC.GLOBAL_AFTER, type: CT.TYPE_FILE },
                ]
            },
            {
                headerName: TC.GLOBAL_LOCATION,
                children: [
                    { field: "site", headerName: TC.GLOBAL_LABEL_SITE, hide: true },
                    { field: "building", headerName: TC.GLOBAL_LABEL_BUILD, hide: true },
                    { field: "emplacement", headerName: TC.FLOOR, hide: true },
                    { field: "local", headerName: TC.GLOBAL_LOCAL, hide: true },
                    { field: "parking", headerName: TC.GLOBAL_LABEL_PARKING, hide: true },
                    { field: "location", headerName: TC.GLOBAL_FULL_LOC },
                ]
            }
        ] as G.TableProps<Row<A>>["columns"];

        if (!props.read_only) columns.unshift({ field: "note", headerName: TC.GLOBAL_NOTES, type: CT.TYPE_ACTION_BUTTON, params: get_options.note_button });
        if (props.extra_columns) columns.push(...props.extra_columns);
        return columns;
    }, [form_id, get_options, event, props.extra_columns, props.read_only]);
    //#endregion

    //#region Context Menu & Inline Change
    const on_inline_change = React.useCallback<G.TableProps<Row<A>>["onValueChange"]>(params => {
        let change = onValueChange?.(params) || "to_process";
        if (change === "to_process") {
            let row = params.data,
                old_value = params.oldValue,
                is_own = row.owner === userId,
                field = params.colDef.field as keyof typeof rows[number],
                can_edit = is_own
                    ? row.type === "reg" ? allowed.reg_own_edit : allowed.ticket_own_edit
                    : row.type === "reg" ? allowed.reg_other_edit : allowed.ticket_other_edit;

            // This field can't be edited
            if (field !== "tags_names") M.Alerts.updateError();
            // The user doesn't have the right to edit this note
            else if (!can_edit) M.Alerts.haveNotRight();
            // All seems ok
            else {
                let prop: keyof T.TicketData;
                if (field === "tags_names") {
                    prop = "tags";
                    old_value = row.tags;
                }
                else prop = field as typeof prop;

                const updatePromise = new Promise<"cancel" | void>((resolve, reject) => {
                    let api_params = {
                        field: prop,
                        _id: row._id,
                        old_value: old_value,
                        new_value: params.newValue,
                    } as Parameters<typeof S.update_ticket_field>[0];

                    loading.setTrue();

                    S.update_ticket_field(api_params).then(({ data }) => {
                        if (data === "changed") M.askConfirm({ title: TC.UPDATE_FORCE_CHANGE, text: TC.UPDATE_VALUE_UNFRESH }).then(confirmed => {
                            if (confirmed) S.update_ticket_field({ ...api_params, force_update: true }).then(({ data }) => {
                                if (data === "changed") reject("Error");
                                else resolve();
                            })
                            else resolve("cancel");
                        });
                        else resolve();
                    }).catch(reject);
                });

                updatePromise.then(rows => {
                    if (rows !== "cancel") get_options.tags(row).then(tags => {
                        let selected_tags = tags.filter(t => (params.newValue || []).includes(t.value));
                        let tags_names = selected_tags.map(t => t.label);
                        let updated_row = { ...row, tags: params.newValue, tags_names } as Row;
                        set_rows(p => p.map(t => t._id === row._id ? updated_row : t));
                    })
                })
                    .catch(M.Alerts.updateError)
                    .finally(loading.setFalse);
            }
        }
    }, [allowed, get_options, loading, userId, set_rows, onValueChange]);

    const getContextMenu = React.useCallback<G.TableProps<Row<A>>["getContextMenuItems"]>(params => {
        let row = params.node?.data;
        let has_context = TB.mongoIdValidator(context?.ids?.[0]);
        let selected_rows = params.api.getSelectedNodes().map(n => n.data);
        let extraItems = [] as ReturnType<G.TableProps<Row<A>>["getContextMenuItems"]>;
        let [selected_reg, selected_other] = _.partition(selected_rows, r => r.type === "reg");
        let can_create_group = has_context && selected_rows.length > 1 && selected_rows.every(r => r.type !== "group");

        let can_edit = false,
            is_own_ticket = row?._id === userId,
            is_reg_ticket = row?.type === "reg",
            can_delete_all_rows = selected_rows.length > 0
                && selected_reg.every(r => r.owner === userId ? allowed.reg_own_edit : allowed.reg_other_edit)
                && selected_other.every(r => r.owner === userId ? allowed.ticket_own_edit : allowed.ticket_other_edit);

        // User is the creator of the ticket
        if (is_own_ticket) {
            if (is_reg_ticket) can_edit = allowed.reg_own_edit;
            else can_edit = allowed.ticket_own_edit;
        }
        // Ticket is from someone else
        else if (is_reg_ticket) can_edit = allowed.reg_other_edit;
        else can_edit = allowed.ticket_other_edit;

        if (row) {
            let isClosed = TB.mongoIdValidator(row.signedBy);
            extraItems.push({ name: lg.getStaticText(TC.GLOBAL_SHOW), icon: "<i class='fa fa-search'></i>", action: () => event.edit(row, true) });
            if (!props.read_only) {
                extraItems.push({ name: lg.getStaticText(TC.GLOBAL_ADD_NOTE), icon: "<i class='fa fa-sticky-note'></i>", action: () => event.note(row) });
                if (can_create_group) extraItems.push({ name: lg.getStaticText(TC.TICKET_CREATE_GROUP, selected_rows.length), action: () => event.create_group(selected_rows) });
                if (can_edit) {
                    if (!isClosed) extraItems.push(
                        {
                            action: () => event.edit(row),
                            name: lg.getStaticText(TC.GLOBAL_EDIT),
                            icon: "<i class='fa fa-pencil-alt'></i>",
                        },
                        {
                            action: () => event.close(row),
                            icon: "<i class='fa fa-signature'></i>",
                            name: lg.getStaticText(TC.TICKET_TABLE_CLOSE_TICKET),
                        }
                    );
                    if (can_delete_all_rows && selected_rows.length > 0) extraItems.push({
                        action: () => event.delete(selected_rows),
                        icon: "<i class='fa fa-times text-danger'></i>",
                        name: lg.getStaticText(TC.GLOBAL_DELET_N_ITEMS, selected_rows.length),
                    });
                    else extraItems.push({
                        action: () => event.delete(row),
                        name: lg.getStaticText(TC.GLOBAL_DELETE),
                        icon: "<i class='fa fa-times text-danger'></i>",
                    });
                }
            }
        }
        // User can create maintenance tickets
        if (allowed.ticket_own_edit && !props.read_only) extraItems.push({
            action: event.create,
            icon: "<i class='fa fa-plus'></i>",
            name: lg.getStaticText(TC.P_ADD_TICKET),
        });

        if (extraItems.length > 0 && params.defaultItems?.length > 0) extraItems.push("separator", ...params.defaultItems);
        return extraItems;
    }, [allowed, event, lg, userId, context?.ids, props.read_only]);
    //#endregion

    React.useImperativeHandle(ref, () => ({ table, rows: tr_rows }), [tr_rows]);

    return <div className="w-100">
        <C.Spinner error={status === "error" || context_status === "error"}>
            <G.Table<Row<A>>
                count
                sideBar
                ref={table}
                rows={tr_rows}
                autoFit="note"
                status={status}
                columns={columns}
                rowSelection="multiple"
                onReadyGrid={onReadyGrid}
                columns_base="all_but_edit"
                extra_buttons={props.buttons}
                groupDisplayType="singleColumn"
                onValueChange={on_inline_change}
                getContextMenuItems={getContextMenu}
                loading={loading.value || context_status === "load"}
                adaptableId={props.origin || TABS.ACTION_EVENT_TABLE}
            />
        </C.Spinner>
    </div>
};

export const Tickets = React.forwardRef(RenderTickets) as <A = {}>(props: TicketProps<A> & Partial<Record<"ref", React.ForwardedRef<TicketRef<A>>>>) => React.ReactElement;

export const TicketsContext: React.FC = () => {
    H.useCrumbs(TC.TICKETS);
    const [roots] = H.useRoots();
    H.useAuth({ tabName: TABS.ACTION_EVENT_TABLE });
    return <Tickets context={roots} origin={TABS.ACTION_EVENT_TABLE} />;
}