import React from "react";
import * as H from "../../hooks";
import * as S from "../../services";
import * as MUI from "@mui/material";
import * as BS from "react-bootstrap";
import { T, TC } from "../../Constants";

export type AlertProps = {
    /** The amount of time the alert should stay up */
    delay?: number;
    /** The width of the alert */
    size?: "xs" | "sm" | "md" | "lg" | "xl";
    /** The variant of the alert */
    type?: "success" | "error" | "info" | "warning";
    /** The content of the body of the alert, priority over the 'children' property */
    message?: AlertProps["title"];
    /** The content of the footer of the alert */
    footer?: AlertProps["title"];
    /** The content of the body of the alert, lower priority than the 'message' property */
    children?: T.AllowArray<React.ReactElement>;
    /** The text or content to show in the header */
    title?: string | number | (Record<"ref", string> & Record<"template", string | number>) | Record<"_id" | "prop" | "default", string> | T.AllowArray<React.ReactElement>;
    /** If the alert is an 'InfoBubble', the id of the bubble in case the user decided to choose the option to ignore it */
    bubble_id?: string;
    /** Callback if the alert is manually dismissed */
    on_dismiss?: (type: "manual" | "auto" | "ignore") => void;
    /** The maximum non-collapsed body height */
    expanded_height?: number;
    /** The maximum collapsed body height */
    collapsed_height?: number;
};

type AlertSimpleProps = Omit<AlertProps, "delay"> & {
    /** The time left before the alert will disappear, in seconds */
    time?: Record<"left" | "start", number>;
    /** Callback to pause the timer */
    on_pause?: () => void;
    /** Callback to restart the timer */
    on_restart?: () => void;
}

const TIME_STEP = 250;
const COLLAPSED_HEIGHT = 150, EXPANDED_HEIGHT = 500;
type GetContent = (content?: AlertProps["message" | "footer" | "title"]) => React.ReactElement;

const AlertSimple: React.FC<AlertSimpleProps> = ({ on_dismiss, on_pause, on_restart, ...props }) => {
    const lg = H.useLanguage();
    const body_ref = React.useRef<HTMLDivElement>(null);
    const [expand, set_expand] = React.useState({ is: true, should: false });

    const get_content = React.useCallback<GetContent>(content => {
        // A value was passed to be used as content
        if (content) {
            // Content is a string or a reference by itself
            if (typeof content === "string") return lg.getStaticText(content);
            // Content is a number
            else if (typeof content === "number") return content.toString();
            // Content is a combination ref + template
            else if ("ref" in content && typeof content.ref === "string") return lg.getStaticText(content.ref, content.template || "");
            // Content is a dynamic translation
            else if ("_id" in content && "prop" in content) return lg.getTextObj(content._id, content.prop, content.default || "");
            // Content should be a React element
            else return content;
        }
        else return null;
    }, [lg]);

    const on_ignore = React.useCallback(() => {
        // If the alert is an 'InfoBubble' type, mark it as read
        S.markAlertAsRead(props.bubble_id).catch(console.error);
        // Dismiss the alert
        on_dismiss?.("ignore");
    }, [props.bubble_id, on_dismiss]);

    const pieces = React.useMemo(() => {
        let body = get_content(props.message),
            header = get_content(props.title),
            footer = get_content(props.footer),
            variant: Exclude<AlertProps["type"], "error"> | "danger" = "info";

        // No message provided, check the 'children' property
        if (!body) body = get_content(props.children);
        // Turn the variant into a bootstrap value
        if (props.type === "error") variant = "danger";
        else variant = props.type || "info";
        // Put a default title depending on the variant of the alert
        if (!header) header = lg.getStaticText(props.type || "info");
        // If the alert is an 'InfoBubble' type, add an 'ignore' button
        if (props.bubble_id) footer = <div className="text-end toast-body p-1">
            <button onClick={on_ignore} className="btn-none btn-link" children={lg.getStaticText(TC.INFO_BUBBLE_DONT_SHOW_AGAIN)} />
        </div>;

        return { header, body, variant, footer };
    }, [props.title, props.children, props.message, props.footer, props.type, props.bubble_id, lg, get_content, on_ignore]);

    const time_progress = React.useMemo(() => {
        if (props.time && typeof props.time.start === "number" && props.time.left >= 0) {
            let container = `bg-${pieces.variant}`;
            let pct_left = ((props.time.left || 0) / (props.time.start || 1)) * 100;
            return <div className={container} children={<MUI.LinearProgress color={props.type} className="" variant="determinate" value={100 - pct_left} />} />;
        }
        else return null;
    }, [pieces.variant, props.time, props.type]);

    //#region Body Collapsing & Styling
    React.useEffect(() => {
        if (body_ref.current.scrollHeight > COLLAPSED_HEIGHT) set_expand({ is: false, should: true });
    }, []);

    const body_style = React.useMemo<React.CSSProperties>(() => ({
        overflow: "hidden",
        transition: "max-height 0.2s ease",
        overflowY: expand.is ? "auto" : "hidden",
        maxHeight: expand.is ? EXPANDED_HEIGHT : COLLAPSED_HEIGHT,
    }), [expand.is]);

    const content_width = React.useMemo<React.CSSProperties>(() => {
        if (props.size === "xs") return { width: "15vw" };
        else if (props.size === "sm") return { width: "25vw" };
        else if (props.size === "lg") return { width: "45vw" };
        else if (props.size === "xl") return { width: "55vw" };
        else return { width: "35vw" };
    }, [props.size]);
    //#endregion

    return <BS.Toast onMouseEnter={on_pause} onMouseLeave={on_restart} animation style={content_width} bg={pieces.variant} onClose={() => on_dismiss?.("manual")}>
        <BS.Toast.Header>
            <div className="flex-grow-1" children={pieces.header} />
        </BS.Toast.Header>
        <BS.Toast.Body ref={body_ref} style={body_style}>
            <div children={pieces.body} />
        </BS.Toast.Body>

        {expand.should && <div onClick={() => set_expand(p => ({ ...p, is: !p.is }))} className="p-0 toast-body fs-120 pointer text-center">
            <i className={`fa fa-chevron-${expand.is ? "up" : "down"}`} />
        </div>}

        {pieces.footer}

        {time_progress}
    </BS.Toast>;
};

const Alert: React.FC<AlertProps> = ({ on_dismiss, ...props }) => {
    const [time_left, start_timer, pause_timer] = H.useTimer(props.delay, TIME_STEP);

    React.useEffect(start_timer, [start_timer]);

    React.useEffect(() => {
        if (time_left === 0) setTimeout(() => on_dismiss?.("auto"), TIME_STEP * 2);
    }, [time_left, on_dismiss]);

    return <AlertSimple
        {...props}
        on_pause={pause_timer}
        on_dismiss={on_dismiss}
        on_restart={start_timer}
        time={{ left: time_left, start: props.delay }}
    />;
};

export default Alert;