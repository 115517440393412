import React from "react";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import * as S from "../../../services";
import { DS, T, TB, TC } from "../../../Constants";

//#region Type
type Data = ReturnType<T.API.Utils.Predictions.GetPredSettingsData>;

export type Training = {
    /** The prediction after it's been trained */
    prediction: T.Prediction;
    /** The data of the graphs */
    graphs: Record<string, {
        /** The columns */
        columns: string[];
        /** The list of datapoints */
        data: [number, number][][];
    }>
}

export type ConfigurationProps = {
    /** An existing prediction (for model update) */
    prediction?: string;
    /** The dataset selected by default */
    dataset?: string;
    /** The current context */
    context: T.ContextParams;
    /** Callback after initialization of the model */
    init_success: (training: Training) => void;
    /** Update the footer in the parent component */
    set_footer: (footer: React.ReactElement) => void;
}
//#endregion

//#region Constants
export const THERMAL_USAGE = [
    { value: "none", label: TC.PRED_CONFIG_TH_USAGE_NONE },
    { value: "hot", label: TC.PRED_CONFIG_TH_USAGE_HOT },
    { value: "cold", label: TC.PRED_CONFIG_TH_USAGE_COLD },
    { value: "cold_hot", label: TC.PRED_CONFIG_TH_USAGE_HOT_COLD },
] as T.Option<null, Data["prediction"]["thermal_usage"]>[];

export const TIME_GROUPS = [
    { value: "hour", label: TC.PRED_CONFIG_TIME_GROUP_HOUR },
    { value: "quarter_hour", label: TC.PRED_CONFIG_TIME_GROUP_QUARTER_HOUR },
    { value: "3hour", label: TC.PRED_CONFIG_TIME_GROUP_3_HOUR },
] as T.Option<null, Data["prediction"]["time_group"]>[];

const WITH_DATA_COUNTRY = ["FR", "BE"];
//#endregion

const Configuration: React.FC<ConfigurationProps> = ({ init_success, ...props }) => {
    const lg = H.useLanguage();
    const [data, set_data, status] = H.useAsyncState<Data>(null);
    const [errors, set_errors] = React.useState<T.Errors<Data["prediction"]>>({});

    //#region Load Data
    React.useEffect(() => {
        let isSubscribed = true;
        S.getPredSettingsData({ context: props.context, prediction: props.prediction })
            .then(({ data }) => isSubscribed && set_data({ ...data, prediction: { ...data.prediction, dataset: data.prediction.dataset || props.dataset } }, "done"))
            .catch(() => isSubscribed && set_data(null, "error"));
        return () => {
            isSubscribed = false;
            set_data(null, "load");
        }
    }, [set_data, props.context, props.prediction, props.dataset]);
    //#endregion

    //#region Helpful data & events handler
    const tag_values = React.useMemo(() => ({
        sol: (data?.info?.solar_options || []).filter(o => o.station === data?.prediction?.sol?.station && o.tag === data?.prediction?.sol?.tag)[0]?.value,
        ext_temp: (data?.info?.temp_options || []).filter(o => o.station === data?.prediction?.ext_temp?.station && o.tag === data?.prediction?.ext_temp?.tag)[0]?.value,
    }), [data?.info?.solar_options, data?.info?.temp_options, data?.prediction?.ext_temp, data?.prediction?.sol]);

    const current_dataset = React.useMemo(() => {
        let datasets = data?.info?.datasets || [];
        return datasets.filter(d => d.value === data?.prediction?.dataset)[0];
    }, [data?.info?.datasets, data?.prediction?.dataset]);

    const nb_features = React.useMemo(() => {
        let features = 0;
        if (data?.prediction?.sol) features++;
        if (data?.prediction?.country) features++;
        if (data?.prediction?.weekday) features++;
        if (data?.prediction?.ext_temp) features++;
        if (data?.prediction?.hour_minute) features++;
        if (Array.isArray(data?.prediction?.misc_var) && data.prediction.misc_var.length > 0) features++;
        return features;
    }, [data?.prediction]);

    const short_period = React.useMemo(() => {
        let to = TB.getDate(data?.prediction?.to_date),
            start = TB.getDate(data?.prediction?.from_date);
        // Raise a flag if there is less than 30 days selected
        if (start && to) return to.getTime() - start.getTime() < (1000 * 60 * 60 * 24 * 30);
        else return false;
    }, [data?.prediction?.from_date, data?.prediction?.to_date]);

    const dataset_options = React.useMemo(() => (data?.info?.datasets || []).filter(d => d.src !== "prediction"), [data?.info?.datasets]);
    const is_elec = React.useMemo(() => current_dataset?.type === "ELEC" || current_dataset?.type === "ELEC_HEAD", [current_dataset?.type]);
    const has_country_holidays = React.useMemo(() => !data?.prediction?.country || WITH_DATA_COUNTRY.includes(data.prediction.country), [data?.prediction?.country]);

    const is_thermal = React.useMemo(() => {
        // Dataset need to be for an "ELEC" kind of energy
        if (!is_elec) return false;
        // There needs to be a temperature variable
        if (!data?.prediction?.ext_temp) return false;
        // Weekdays must also be activated
        if (!data.prediction.weekday) return false;
        // The holidays must be available
        if (!WITH_DATA_COUNTRY.includes(data?.prediction?.country)) return false;
        // All conditions are met
        return true;
    }, [is_elec, data?.prediction]);

    const change = React.useMemo(() => ({
        value: (value: any, prop: keyof Data["prediction"]) => {
            if (prop === "from_date" || prop === "to_date") {
                let date = TB.getDate(value);
                if (date) {
                    if (prop === "from_date") date.setHours(0, 0, 0, 0);
                    else date.setHours(23, 59, 59, 0);
                    value = date.toISOString();
                }
            }

            set_errors(p => ({ ...p, [prop]: undefined }));
            set_data(p => ({ ...p, prediction: { ...p.prediction, [prop]: value } }));
        },
        dataset: (id: string) => {
            let new_dataset_type = data?.info?.datasets.filter(d => d.value === id)[0]?.type;
            let is_elec = new_dataset_type === "ELEC" || new_dataset_type === "ELEC_HEAD";

            set_data(p => ({
                info: p.info,
                prediction: {
                    ...p.prediction,
                    dataset: id, thermal_usage: is_elec ? p.prediction?.thermal_usage || "none" : undefined,
                }
            }));
        },
        set_tag: (value: string, is_solar = false) => {
            let options = (is_solar ? data?.info?.solar_options : data?.info?.temp_options) || [];
            let option = options.filter(o => o.value === value)[0];
            if (!option) change.value(null, is_solar ? "sol" : "ext_temp");
            else change.value({ station: option.station, tag: option.tag }, is_solar ? "sol" : "ext_temp");
        },
    }), [set_data, data?.info]);

    const render_dataset = React.useCallback((o: typeof dataset_options[number]) => <div><i className={`fa fa-${DS.ICONS[o.src]} me-2`}></i>{o.label}</div>, []);
    //#endregion

    //#region Validation
    const validate_data = React.useCallback(() => {
        let pred = data?.prediction,
            new_errors: typeof errors = {},
            end_date = TB.getDate(pred?.to_date),
            start_date = TB.getDate(pred?.from_date);

        // No dataset selected
        if (!TB.mongoIdValidator(pred?.dataset)) new_errors.dataset = TC.GLOBAL_REQUIRED_FIELD;
        // No start date
        if (!end_date) new_errors.to_date = TC.GLOBAL_REQUIRED_FIELD;
        // No end date
        if (!start_date) new_errors.from_date = TC.GLOBAL_REQUIRED_FIELD;
        // Check that from date is before end date
        if (end_date && start_date && start_date.getTime() >= end_date.getTime()) {
            new_errors.to_date = TC.ERR_DATE_TO_LOWER_DATE_FROM;
            new_errors.from_date = TC.ERR_DATE_FROM_HIGHER_DATE_TO;
        }

        if (Object.keys(new_errors).length > 0) set_errors(new_errors);
        else {
            const confirm_promise = new Promise<boolean>(resolve => {
                // If the user potentially tried to create a thermal analysis, but some conditions are not met
                if (is_elec) {
                    let unmet_conditions = [] as (Record<"prop", keyof typeof pred> & Record<"label", string>)[];
                    if (!data?.prediction?.country) unmet_conditions.push({ prop: "country", label: TC.PRED_CONFIG_HOLIDAYS });
                    if (!data?.prediction?.weekday) unmet_conditions.push({ prop: "weekday", label: TC.PRED_CONFIG_WEEKDAY });
                    if (!data?.prediction?.ext_temp) unmet_conditions.push({ prop: "ext_temp", label: TC.PRED_CONFIG_TEMP_EXT });
                    // All conditions are ok
                    if (unmet_conditions.length === 0) resolve(true);
                    // Some conditions are not ok
                    else {
                        let modal_content = <ul children={unmet_conditions.map(c => <li key={c.prop}>{lg.getStaticText(c.label)}</li>)} />;
                        M.askConfirm({
                            text: modal_content,
                            title: TC.PRED_CONFIG_THERMAL_PROPS_MISSING,
                            noText: TC.PRED_CONFIG_THERMAL_PROPS_MISSING_EDIT,
                            yesText: TC.PRED_CONFIG_THERMAL_PROPS_MISSING_CONTINUE,
                        })
                            .then(confirmed => resolve(!!confirmed));
                    }
                }
                else resolve(true);
            });

            confirm_promise.then(confirmed => {
                if (confirmed) {
                    const unmount = M.renderLoader(TC.PRED_TRAINING_MODEL);
                    S.trainModel(data.prediction as T.Prediction)
                        .then(({ data }) => {
                            if (data.training === "failure") {
                                M.Alerts.updateError(data.reason);
                                set_data(p => ({ ...p, prediction: data.prediction }));
                            }
                            else init_success?.({ prediction: data.prediction, graphs: data.graphs });
                        })
                        .catch(M.Alerts.updateError)
                        .finally(unmount);
                }
            });
        }
    }, [lg, is_elec, data?.prediction, init_success, set_data]);
    //#endregion

    //#region Footer
    React.useEffect(() => props.set_footer(
        <C.Flex justifyContent="end">
            <C.Button text={TC.PRED_CONFIG_QUALITY_CONTROL} onClick={validate_data} disabled={nb_features === 0} />
        </C.Flex>,
    ), [nb_features, validate_data, props]);
    //#endregion

    return <C.Spinner status={status} min_load_size="300px">

        {is_thermal && <C.ErrorBanner type="info" textCode={TC.PRED_THERMAL_ANALYSIS} />}
        {nb_features === 0 && <C.ErrorBanner type="danger" textCode={TC.PRED_CONFIG_NO_FEATURES} />}

        <div className="mb-3">
            <h4 className="mb-2">{lg.getStaticText(TC.PRED_CONFIG_GEN)}</h4>
            <C.Form.Select
                required
                labelPosition="left"
                error={errors.dataset}
                options={dataset_options}
                label={TC.PRED_CONFIG_DATASET}
                value={data?.prediction?.dataset}
                onChange={id => change.dataset(id)}
                typeahead={{ renderItem: render_dataset }}
                disabled={props.dataset && current_dataset?.value === props.dataset}
            />
            {is_elec && <C.Form.Select
                labelPosition="left"
                options={THERMAL_USAGE}
                error={errors.thermal_usage}
                label={TC.PRED_CONFIG_TH_USAGE}
                typeahead={{ hideClearButton: true }}
                value={data?.prediction?.thermal_usage}
                onChange={t => change.value(t, "thermal_usage")}
            />}
            <C.Form.Select
                no_clear_btn
                labelPosition="left"
                options={TIME_GROUPS}
                label={TC.PRED_CONFIG_TIME_GROUP}
                value={data?.prediction?.time_group || "hour"}
                onChange={tg => change.value(tg, "time_group")}
            />
            <BS.Row className="g-1">
                <BS.Col md={3}>
                    <C.Flex className="h-100" alignItems="center">
                        {lg.getStaticText(TC.PRED_CONFIG_TIME_CONSIDERATION)}
                    </C.Flex>
                </BS.Col>
                <BS.Col>
                    <C.Form.DateTime
                        noBottomMargin
                        error={errors.from_date}
                        value={data?.prediction?.from_date}
                        onChange={d => change.value(d, "from_date")}
                    />
                </BS.Col>
                <BS.Col>
                    <C.Form.DateTime
                        noBottomMargin
                        error={errors.to_date}
                        value={data?.prediction?.to_date}
                        onChange={d => change.value(d, "to_date")}
                    />
                </BS.Col>
            </BS.Row>
            {short_period && <BS.Row className="g-1">
                <BS.Col md={3} />
                <BS.Col children={<C.ErrorBanner size="sm" type="warning" textCode={TC.PRED_CONFIG_FULL_MONTH_OF_DATA} />} />
            </BS.Row>}
        </div>
        <div className="mb-3">
            <h4 className="mb-2">{lg.getStaticText(TC.PRED_CONFIG_VAR_WEATHER)}</h4>
            <C.Form.Select
                labelPosition="left"
                value={tag_values.sol}
                label={TC.PRED_CONFIG_SUN}
                options={data?.info?.solar_options}
                onChange={tag => change.set_tag(tag, true)}
            />
            <C.Form.Select
                labelPosition="left"
                value={tag_values.ext_temp}
                label={TC.PRED_CONFIG_TEMP_EXT}
                options={data?.info?.temp_options}
                onChange={tag => change.set_tag(tag, false)}
            />
        </div>
        <div className="mb-3">
            <h4 className="mb-2">{lg.getStaticText(TC.PRED_CONFIG_VAR_TIME)}</h4>
            <div style={{ width: "fit-content" }}>
                <C.Form.RadioBool
                    name="weekday"
                    labelFullWidth
                    always_selected
                    error={errors.weekday}
                    label={TC.PRED_CONFIG_WEEKDAY}
                    value={data?.prediction?.weekday}
                    onChange={wd => change.value(wd, "weekday")}
                />
            </div>
            <div style={{ width: "fit-content" }}>
                <C.Form.RadioBool
                    labelFullWidth
                    always_selected
                    name="hour_minute"
                    error={errors.hour_minute}
                    label={TC.PRED_CONFIG_HOUR_MIN}
                    value={data?.prediction?.hour_minute}
                    onChange={hm => change.value(hm, "hour_minute")}
                />
            </div>
            <C.Form.Select
                labelPosition="left"
                label={TC.PRED_CONFIG_HOLIDAYS}
                options={data?.info?.countries}
                value={data?.prediction?.country}
                onChange={c => change.value(c, "country")}
                error={!has_country_holidays && TC.PRED_NO_HOLIDAY_COUNTRY}
            />
        </div>
        <div className="mb-3">
            <h4 className="mb-2">{lg.getStaticText(TC.PRED_CONFIG_VAR_MISC)}</h4>
            <C.Form.Select
                multiple
                value={data?.prediction?.misc_var}
                onChange={d => change.value(d, "misc_var")}
                typeahead={{ height: "md", renderItem: render_dataset }}
                options={data?.info?.datasets?.filter?.(d => d.value !== data?.prediction?.dataset)}
            />
        </div>
    </C.Spinner>
};

export default Configuration;