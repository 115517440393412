import React from "react";
import * as M from "../Modal";
import * as H from "../../hooks";
import * as C from "../../Common";
import * as S from "../../services";
import { T, FP, TB, TC } from "../../Constants";
import { CellsTypes } from "../Gestion/AgGridDefs";
import { Table, TableProps, TableRef } from "../Gestion";

//#region Types
export type AccessManageProps = {
    /** The current user under review */
    target: string;
}

type Row = Distribution["rows"][number];
type Distribution = ReturnType<T.API.Access.GetRoleDistribution>;
//#endregion

const AccessManage: React.FC<AccessManageProps> = props => {
    const lg = H.useLanguage();
    const loading = H.useBoolean(false);
    const table = React.useRef<TableRef<Row>>(null);
    const [distribution, set_distribution, distribution_status] = H.useAsyncState<Distribution>({ roles: [], rows: [] });

    const update_distribution = React.useCallback((new_distribution: Distribution) => {
        let new_rows_ids = new_distribution.rows.map(r => r._id);
        let new_roles_ids = new_distribution.roles.map(r => r.id);

        set_distribution(p => ({
            rows: p.rows.filter(r => !new_rows_ids.includes(r._id)).concat(new_distribution.rows),
            roles: p.roles.filter(r => !new_roles_ids.includes(r.id)).concat(new_distribution.roles),
        }));
    }, [set_distribution]);

    //#region Target - Store the target in a ref, because it does not update it every time when going from one user to the other
    const target_ref = React.useRef(props.target);
    React.useImperativeHandle(target_ref, () => props.target, [props.target]);
    //#endregion

    //#region Load user's distribution
    React.useEffect(() => {
        let isSubscribed = true;
        S.getRoleDistribution({ user: props.target })
            .then(({ data }) => isSubscribed && set_distribution(data, "done"))
            .catch(() => isSubscribed && set_distribution({ roles: [], rows: [] }, "error"));
        return () => {
            isSubscribed = false;
            set_distribution({ roles: [], rows: [] }, "load");
        }
    }, [set_distribution, props.target]);
    //#endregion

    //#region Columns & rows
    const tr_rows = React.useMemo(() => distribution.rows.map(r => ({ ...r, tr_path: lg.getStaticText(r.path) })), [lg, distribution.rows]);

    const columns = React.useMemo(() => {
        let columns_list = [
            { field: "name", headerName: TC.GLOBAL_NAME, editable: false },
            { field: "tr_path", headerName: TC.TREE_CONFIG_HIDE_SUBS, editable: false },
            { field: "client", headerName: FP.CLIENT_FORM, hide: true, rowGroupIndex: 0, editable: false },
            { field: "site", headerName: FP.SITE_FORM, hide: true, rowGroupIndex: 1, editable: false },
            { field: "building", headerName: FP.BUILDING_FORM, hide: true, editable: false },
        ] as TableProps<Row>["columns"];
        // Add the roles
        for (let role of distribution.roles)
            columns_list.push({ field: "roles." + role.id, headerName: role.name, type: CellsTypes.TYPE_ROLE_CHECK });
        return columns_list;
    }, [distribution.roles]);
    //#endregion

    //#region Grid Ready Open Groups
    const grid_loaded = React.useCallback<TableProps<Row>["onReadyGrid"]>(grid => {
        if (distribution_status === "done") {
            grid?.api?.expandAll?.();
            grid?.columnApi?.autoSizeAllColumns?.();
        }
    }, [distribution_status]);
    //#endregion

    //#region On value change
    const change_value = React.useCallback<TableProps<Row>["onValueChange"]>(event => {
        let zone = event.data._id,
            role_id = (event.colDef.field || "").split(".")[1],
            new_value: Row["roles"][string] = event.newValue,
            old_value: Row["roles"][string] = event.oldValue,
            is_remove = new_value.status === "unavailable";

        // All data is clear for an update
        if (TB.mongoIdValidator(role_id) && old_value.status !== "blocked" && old_value.can_edit) {
            table.current.grid.api.showLoadingOverlay();
            // Update the user
            S.updateAccess({ target: props.target, roleId: role_id, isRemove: is_remove, zone }).then(() => {
                // Reload the distribution
                S.getRoleDistribution({ user: props.target })
                    .then(({ data }) => {
                        if (is_remove) set_distribution(data);
                        else update_distribution(data);
                    })
                    .catch(error => {
                        M.Alerts.updateError(error);
                        set_distribution({ roles: [], rows: [] }, "error");
                    })
                    .finally(() => table.current.grid.api.hideOverlay());
            }).catch(e => {
                M.Alerts.updateError(e);
                table.current.grid.api.hideOverlay();
            });
        }
        else M.Alerts.updateError({ message: TC.ACCESS_TABLE_ERROR_EDIT_NOT_ALLOWED });
    }, [set_distribution, update_distribution, props.target]);
    //#endregion

    //#region Add new root
    const add_root = React.useCallback(() => {
        M.renderSelectManyContext().then(items => {
            if (items) {
                loading.setTrue();
                S.getRoleDistribution({ user: props.target, context: { roots: items.items.map(i => i.id) } })
                    .then(({ data }) => update_distribution(data))
                    .catch(M.Alerts.loadError)
                    .finally(loading.setFalse);
            }
        });
    }, [props.target, update_distribution, loading]);

    const add_root_button = React.useMemo<TableProps<Row>["extra_buttons"]>(() => ({
        onClick: add_root,
        label: lg.getStaticText(TC.ACCESS_TABLE_ERROR_EDIT_ADD_ROOT),
    }), [lg, add_root]);
    //#endregion

    React.useEffect(() => {
        if (distribution_status === "done") table.current?.grid?.columnApi?.autoSizeAllColumns?.();
    }, [columns, distribution_status]);

    return <div className="w-100 h-100 bg-white p-3 border rounded">
        <C.Spinner status={distribution_status}>
            <Table<Row>
                ref={table}
                rows={tr_rows}
                noBottomPadding
                columns={columns}
                columns_base="all"
                groupAllowUnbalanced
                loading={loading.value}
                sideBar="filters_columns"
                onReadyGrid={grid_loaded}
                adaptableId="role_accesses"
                status={distribution_status}
                onValueChange={change_value}
                groupDisplayType="singleColumn"
                extra_buttons={add_root_button}
            />
        </C.Spinner>
    </div>;
}

export default AccessManage;