import _ from "lodash";
import { TC, T, TB } from "../../../Constants";
import FlatLabelInput from "../FlatLabelInput";
import { useLanguage, useNumber } from "../../../hooks";
import { Col, Form, Row, Button } from "react-bootstrap";
import { ColorSelect, ImgSelect } from "../../../Common";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

//#region Types
type FloorModalProps = {
    defaultIcon?: string;
    onResolve?: (emplacements: T.EmplacementData[]) => void;
    icons?: { value: string; title?: string; src: string; }[];
}

export type TypeValue = "floor";
interface FloorModalTypeProps extends FloorModalProps { type: TypeValue };
export type RenderFloorModalFn = (params: FloorModalTypeProps) => Promise<T.EmplacementData[] | null>;
//#endregion

const TEXT_CODES = [
    TC.BR_FLOOR_NB, TC.BR_FLOOR_START_AT, TC.BR_FLOOR_NAME_FORMAT, TC.BR_FLOOR_DEFAULT_AREA, TC.BUILD_EDIT_ICON, TC.BUILD_EDIT_COLOR, TC.GLOBAL_PROPERTIES_LABEL,
    TC.BR_B_AUTO_CREATE, TC.GLOBAL_REQUIRED_FIELD, TC.VAL_IS_REQUIRED
];

const TEMPLATE_FLOOR = "%num%";

const FloorModal: FC<FloorModalProps> = ({ icons, defaultIcon, onResolve, ...props }) => {
    const { getStaticText } = useLanguage(TEXT_CODES);
    const [errors, setErrors] = useState<T.StringObject>({});
    const [area, setArea] = useNumber({ allowFloat: false, min: 0 });
    const [floorStart, setFloorStart] = useNumber({ allowFloat: false, default: 0 });
    const [nbFloor, setNbFloor] = useNumber({ allowFloat: false, default: 1, min: 1, max: 50 });
    const [{ color, selectIcon, name }, setVisual] = useState({ color: "#FFFFFF", selectIcon: defaultIcon, name: TEMPLATE_FLOOR });

    const optionArray = useMemo(() => TB.getArray(icons), [icons]);

    useEffect(() => setErrors(p => TB.validString(p.name) ? _.omit({ ...p }, "name") : p), [name]);
    useEffect(() => setErrors(p => TB.validString(p.nbFloor) ? _.omit({ ...p }, "nbFloor") : p), [nbFloor]);
    useEffect(() => setErrors(p => TB.validString(p.floorStart) ? _.omit({ ...p }, "floorStart") : p), [floorStart]);

    const createEmplacementsData = useCallback(() => {
        if (typeof nbFloor === "number" && typeof floorStart === "number") {
            let emplacementsData: T.EmplacementData[] = [...new Array(nbFloor)]
                .map((x, i) => ({
                    area,
                    color,
                    selectIcon,
                    type: "floor",
                    floorNumber: i + floorStart,
                    name: TB.replaceStringPart(name, TEMPLATE_FLOOR, (i + floorStart).toString()),
                }));
            return emplacementsData;
        }
        return [];
    }, [area, color, floorStart, name, nbFloor, selectIcon]);

    const onValidate = useCallback(() => {
        let newErrors: T.StringObject = {};
        if (!TB.validString(name)) newErrors.name = TC.GLOBAL_REQUIRED_FIELD;
        else if (!name.includes(TEMPLATE_FLOOR)) newErrors.name = TC.VAL_IS_REQUIRED;
        if (typeof nbFloor !== "number") newErrors.nbFloor = TC.GLOBAL_REQUIRED_FIELD;
        if (typeof floorStart !== "number") newErrors.floorStart = TC.GLOBAL_REQUIRED_FIELD;

        if (Object.keys(newErrors).length > 0) setErrors(newErrors);
        else onResolve?.(createEmplacementsData());
    }, [name, nbFloor, floorStart, onResolve, createEmplacementsData]);

    return <div>
        <Row className="mt-2 mb-3">
            <Col md={6}>
                <FlatLabelInput required labelWidth={5} label={getStaticText(TC.BR_FLOOR_NB)}>
                    <Form.Control type="number" value={nbFloor ?? ""} onChange={e => setNbFloor(e.target.value)} isInvalid={TB.validString(errors.nbFloor)} />
                    {TB.validString(errors.nbFloor) && <span className="invalid-feedback shown">{getStaticText(errors.nbFloor)}</span>}
                </FlatLabelInput>
            </Col>
            <Col md={6}>
                <FlatLabelInput required labelWidth={5} label={getStaticText(TC.BR_FLOOR_START_AT)}>
                    <Form.Control type="number" value={floorStart ?? ""} onChange={e => setFloorStart(e.target.value)} isInvalid={TB.validString(errors.floorStart)} />
                    {TB.validString(errors.floorStart) && <span className="invalid-feedback shown">{getStaticText(errors.floorStart)}</span>}
                </FlatLabelInput>
            </Col>
        </Row>
        <Row className="mt-2 mb-3">
            <Col md={12}>
                <span className="h4">{getStaticText(TC.GLOBAL_PROPERTIES_LABEL)}</span>
            </Col>
        </Row>
        <Row className="mt-2 mb-3">
            <Col md={6}>
                <FlatLabelInput required labelWidth={5} label={getStaticText(TC.BR_FLOOR_NAME_FORMAT)}>
                    <Form.Control value={name} onChange={e => setVisual(p => ({ ...p, name: e.target.value }))} isInvalid={TB.validString(errors.name)} />
                    {TB.validString(errors.name) && <span className="invalid-feedback shown">{getStaticText(errors.name)}</span>}
                </FlatLabelInput>
            </Col>
            <Col md={6}>
                <FlatLabelInput labelWidth={5} label={getStaticText(TC.BR_FLOOR_DEFAULT_AREA)}>
                    <Form.Control type="number" value={area ?? ""} onChange={e => setArea(e.target.value)} />
                </FlatLabelInput>
            </Col>
        </Row>
        <Row className="mt-2 mb-3">
            <Col md={4}>
                <FlatLabelInput labelWidth={3} label={getStaticText(TC.BUILD_EDIT_ICON)}>
                    <ImgSelect options={optionArray} onSelect={selectIcon => setVisual(p => ({ ...p, selectIcon }))} selected={selectIcon} />
                </FlatLabelInput>
            </Col>
            <Col md={4}>
                <FlatLabelInput labelWidth={3} label={getStaticText(TC.BUILD_EDIT_COLOR)}>
                    <ColorSelect onSelect={color => setVisual(p => ({ ...p, color: color ?? "#FFFFFF" }))} noAutoClose selected={color} />
                </FlatLabelInput>
            </Col>
        </Row>
        <Row className="mt-2 mb-3">
            <Col md={12}>
                <Button onClick={onValidate} className="w-100">
                    <i className="fa fa-magic me-2"></i>
                    {getStaticText(TC.BR_B_AUTO_CREATE)}
                </Button>
            </Col>
        </Row>
    </div>
}

export default FloorModal;