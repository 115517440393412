import { TB, T } from "../../../../Constants";

const toPdf: T.DiaPdfFn = (doc, position, { context }) => {
    let contentSize = { height: 100, width: 150 };
    if (!context.data.color) context.data.color = '#FFFFFF';

    contentSize.width -= 2;
    contentSize.height -= 2;

    doc.save();

    /* item border */
    doc.rect(position.x, position.y, position.width, position.height)
        .lineWidth(1)
        .fillOpacity(1)
        .fillAndStroke(context.data.color, '#000');

    /* photo */
    try {
        /* TODO context.image (link) needs to be replaced by a b64 image */
        if (TB.validString(context.image)) doc.image(
            context.image,
            position.x + contentSize.width / 4,
            position.y + contentSize.height / 4,
            { width: contentSize.width / 2, height: (contentSize.height / 4) * 2.5 }
        );
    }
    catch (error) { console.log({ context, error }) };

    /* title */
    doc.fillColor('#000')
        .font('Helvetica', 12)
        .text(context.title, position.x, position.y + 5, {
            width: contentSize.width,
            height: contentSize.height,
            align: 'center'
        });

    doc.restore();
}

export default toPdf;