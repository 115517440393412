import React from "react";
import { T } from "../../Constants";
import { Dropdown } from "react-bootstrap";
import { useLanguage } from "../../hooks";

export type ButtonSelectProps = {
    /** The different options */
    options: T.Option<{ variant: T.ColorTypes }>[];
    /** The current selected option */
    value: string;
    /** The default value, when nothing is selected */
    default_value: string;
    /** Callback, when the value change */
    onChange?: (value: string) => void;
    /** The size of the button */
    size?: "sm" | "lg";
    /** Disable the ButtonSelect */
    disabled?: boolean;
    /** Title of the button, displayed instead of the current selection */
    title?: string | React.ReactElement;
}

const ButtonSelect: React.FC<ButtonSelectProps> = props => {
    const lg = useLanguage();

    const current_option = React.useMemo(() => {
        let value = props.value || props.default_value;
        return props.options?.filter(o => o?.value === value)?.[0];
    }, [props.value, props.options, props.default_value]);

    const toggle = React.useMemo(() => {
        let content = null,
            toggle_props = {
                size: props.size,
                disabled: props.disabled,
            } as any;

        if (typeof props.title === "string") content = lg.getStaticText(props.title);
        else if (props.title) content = props.title;
        else if (current_option) {
            toggle_props.variant = current_option?.variant;
            content = lg.getStaticElem(current_option?.label);
        }
        else return null;

        return <Dropdown.Toggle {...toggle_props}>
            {content}
        </Dropdown.Toggle>;
    }, [current_option, lg, props.disabled, props.size, props.title]);

    if (!toggle || !props.options) return null;
    return <Dropdown>
        {toggle}
        <Dropdown.Menu>
            {props.options.map(o => <Dropdown.Item onClick={() => props.onChange?.(o.value)} key={o.value}>
                {lg.getStaticElem(o.label)}
            </Dropdown.Item>)}
        </Dropdown.Menu>
    </Dropdown>
}

export default ButtonSelect;