import classNames from 'classnames';
import { T } from '../../../Constants';
import { AppContext } from '../../../Context';
import { Nav, Collapse } from 'react-bootstrap';
import { useHideUnavailable } from '../../../hooks';
import { NavLink, useLocation } from 'react-router-dom';
import NavbarVerticalMenuItem from './NavbarVerticalMenuItem';
import React, { FC, useCallback, useContext, useMemo, useState } from 'react';

const CollapseItems: FC<{ route: T.RoutesType }> = ({ route }) => {
  const { pathname } = useLocation();

  const openCollapse = childrens => {
    const checkLink = children => {
      if (children.to === pathname) return true;
      return Array.isArray(children?.children) && children.children.some(checkLink);
    };
    return Array.isArray(childrens) ? childrens.some(checkLink) : false;
  };

  const [open, setOpen] = useState(openCollapse(route.children));
  const linkClass = useMemo(() => classNames('dropdown-indicator cursor-pointer'), []);

  return <Nav.Item as="li">
    <Nav.Link aria-expanded={open} className={linkClass} onClick={() => setOpen(!open)} >
      <NavbarVerticalMenuItem route={route} />
    </Nav.Link>
    <Collapse in={open}>
      <Nav className="flex-column nav" as="ul">
        <NavbarVerticalMenu routes={route.children} />
      </Nav>
    </Collapse>
  </Nav.Item>
};

const NavbarVerticalMenu: FC<{ routes: T.RoutesType[] }> = ({ routes }) => {
  const hide = useHideUnavailable();
  const { config: { showBurgerMenu }, setConfig } = useContext(AppContext);
  const handleNavItemClick = useCallback(() => showBurgerMenu && setConfig('showBurgerMenu', !showBurgerMenu), [showBurgerMenu, setConfig]);

  return <>
    {routes.map((route, i) => {
      // Do not show the item
      if (hide && !route.active) return null;

      if (route.children.length === 0) return <Nav.Item as="li" key={i} onClick={handleNavItemClick}>
        <NavLink end to={route.active ? "/" + route.to : "#"} className={"nav-link " + (route.active ? "" : "text-muted fs-85 pointer-no")} >
          <NavbarVerticalMenuItem route={route} />
        </NavLink>
      </Nav.Item>;
      else return <CollapseItems route={route} key={i} />;
    })}
  </>
};

export default NavbarVerticalMenu;
