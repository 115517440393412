import React from "react";
import { Item } from "./Sort";
import { useDrag, useDrop } from "react-dnd";

//#region Types
type CardProp<A = Item> = {
    id: string;
    item: Item<A>;
    index: number;
    renderCard: (element: Item<A>) => React.ReactNode;
    moveCard: (dragIndex: number, hoverIndex: number) => void;
}
//#endregion

const Card = <A,>({ id, item, index, moveCard, renderCard }: CardProp<A>) => {
    const ref = React.useRef<null | HTMLDivElement>(null);

    const [{ handlerId }, drop] = useDrop<{ index: number }, {}, { handlerId: string | symbol }>({
        accept: "card",
        collect: monitor => ({ handlerId: monitor.getHandlerId() }),
        hover: (item, monitor) => {
            if (!ref.current) return;
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) return;
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        }
    });

    const [{ isDragging }, drag] = useDrag({
        type: "card",
        item: () => ({ id, index }),
        collect: monitor => ({ isDragging: monitor.isDragging() }),
    });

    const style = React.useMemo(() => {
        if (isDragging) return { opacity: 0.25, cursor: "grabbing" };
        return { opacity: 1, cursor: "grab" };
    }, [isDragging]);

    drag(drop(ref));

    return <div ref={ref} style={style} data-handler-id={handlerId} children={renderCard?.(item)} />;
}

export default Card;