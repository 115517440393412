import React from "react";
import { FormControl } from "react-bootstrap";
import { REGEX, TB } from "../../../Constants";
import { ComponentWrapper, InputProps } from "../Input";

//#region Type
export type TimeProps = InputProps & {
    minHour?: string;
    maxHour?: string;
}
//#endregion

const Time: React.FC<TimeProps> = ({ minHour, maxHour, onChange, disabled, noOverride, ...props }) => {
    const [min, max] = React.useMemo(() => [minHour, maxHour].map(d => TB.durationToSeconds(d) || NaN), [minHour, maxHour]);
    const [minStr, maxStr] = React.useMemo(() => [min, max].map(TB.secondsToDuration), [min, max]);

    const getDuration = React.useCallback((duration: string) => {
        if (!TB.getString(duration).match(REGEX.DURATION_REGEX)) return "";
        let seconds = TB.durationToSeconds(duration);

        if (isNaN(seconds)) return "";
        else if (!isNaN(min) && seconds < min) return minStr;
        else if (!isNaN(max) && seconds > max) return maxStr;
        else return duration;
    }, [min, max, minStr, maxStr]);

    const isDisabled = React.useMemo(() => disabled || noOverride, [disabled, noOverride]);

    return <ComponentWrapper {...props} disabled={isDisabled}>
        <FormControl
            type="time"
            disabled={isDisabled}
            readOnly={props.readonly}
            value={getDuration(props.value) || ""}
            onChange={e => onChange?.(getDuration(e.target.value))}
        />
    </ComponentWrapper>
}

export default Time;