import { TB } from "../../../Constants";
import { useLanguage } from "../../../hooks";
import { ComponentWrapper, InputProps } from "../Input";
import { FormControl, InputGroup } from "react-bootstrap";
import React, { useCallback, useMemo, useState } from "react";

export type ColorProps = InputProps & {
    prefix?: string;
    suffix?: string;
    /** Disable the autofocus */
    no_auto_focus?: boolean;
    /** Open the color picker on render ? */
    open_on_render?: boolean;
    /** Callback that fires on every change, not just when 'onBlur' is triggered */
    onChangeUncontrolled?: (color?: string) => void;
};

export type ColorRef = {
    /** The currently selected value */
    value: string;
    /** Set focus on the component */
    focus: () => void;
};

const ColorPicker = React.forwardRef<ColorRef, ColorProps>(({ onChange, ...props }, ref) => {
    const lg = useLanguage();
    const [controlled, setControlled] = useState("");
    const input = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => setControlled(TB.getColor(props.value) || ""), [props.value]);

    const vPrefix = useMemo(() => props.prefix && lg.getStaticText(props.prefix), [props.prefix, lg]);
    const vSuffix = useMemo(() => props.suffix && lg.getStaticText(props.suffix), [props.suffix, lg]);
    const isDisabled = useMemo(() => props.disabled || props.noOverride, [props.disabled, props.noOverride]);

    const onLostFocus = useCallback(() => !isDisabled && onChange?.(controlled), [onChange, isDisabled, controlled]);

    React.useEffect(() => {
        if (props.open_on_render) input.current?.click?.();
    }, [props.open_on_render]);

    React.useImperativeHandle(ref, () => ({ value: controlled, focus: input.current.focus }), [controlled]);

    return <ComponentWrapper {...props} disabled={isDisabled}>
        <InputGroup>
            {vPrefix && <InputGroup.Text>{vPrefix}</InputGroup.Text>}
            <FormControl
                ref={input}
                type="color"
                value={controlled}
                onBlur={onLostFocus}
                disabled={isDisabled}
                readOnly={props.readonly}
                autoFocus={!props.no_auto_focus}
                onChange={e => {
                    setControlled(e.target.value);
                    props.onChangeUncontrolled?.(e.target.value);
                }}
            />
            {vSuffix && <InputGroup.Text>{vSuffix}</InputGroup.Text>}
        </InputGroup>
    </ComponentWrapper>
});

export default ColorPicker;