import _ from "lodash";
import './Annotations.scss';
import ReactDom from "react-dom";
import { Form } from "../Form";
import { toPng } from "html-to-image";
import { Dialog } from "@material-ui/core";
import PolygonSelector from "./PolygonType";
import DrawingSelector from "./DrawingType";
import { useLanguage } from "../../hooks";
import * as Text from "../../Constants/text";
import Annotation from "react-image-annotation";
import { TB, LT, FP, TC } from "../../Constants";
import ImgUploader from "../FileUploader/ImgUploader";
import { Confirm, BlankModal, askPrompt } from "../Modal";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { editorStyle, renderHighlight, selectorRender } from "./SelectorRender";
import { PointSelector, RectangleSelector } from "react-image-annotation/lib/selectors";
import { advancedSearch, getFormsFromFilter, getManySubmissionsFromFilter } from "../../services/user.service";

const Annotations = ({ close, locations = [], defaultPlans, offerWiderOrigins = false, allowVentsEdit = false, ventsMeasure, setVents, onSave, ...props }) => {
    //#region State
    const [plans, setPlans] = useState(Array.isArray(defaultPlans) ? defaultPlans : []);
    const [pageActive, setPageActive] = useState(0);
    const [annotation, setAnnotation] = useState({});
    const [annotations, setAnnotations] = useState([]);
    const [type, setType] = useState(PointSelector.TYPE);

    // Db Data
    const [forms, setForms] = useState();
    const [linkTypes, setLinkTypes] = useState();
    const [descendants, setDescendants] = useState();

    // Temp for Upload
    const [tempName, setTempName] = useState("");
    const [tempBase64, setTempBase64] = useState();
    const [tempLocation, setTempLocation] = useState();

    // If it is needed to ask for a save
    const [askForSave, setAskForSave] = useState(false);

    // Redux
    const { language } = useLanguage();

    const annotContainer = useRef();
    const loaderContainer = useRef();
    const successContainer = useRef();
    const confirmContainer = useRef();
    const ventPopUpContainer = useRef();
    //#endregion

    //#region Forms
    const formsPath = useMemo(() => [FP.EMPLACEMENT_FORM, FP.EQUIPEMENT_FORM, FP.LINK_TYPE_FORM], []);

    const empFormId = useMemo(() => forms?.filter?.(({ path }) => path === FP.EMPLACEMENT_FORM)?.[0]?._id, [forms]);
    const equipFormId = useMemo(() => forms?.filter?.(({ path }) => path === FP.EQUIPEMENT_FORM)?.[0]?._id, [forms]);
    const linkTypeFormId = useMemo(() => forms?.filter?.(({ path }) => path === FP.LINK_TYPE_FORM)?.[0]?._id, [forms]);

    useEffect(() => {
        const getForms = async () => {
            let reply = await getFormsFromFilter({ path: formsPath });
            return Array.isArray(reply.data) ? reply.data : null;
        }

        getForms().then(setForms);
    }, [formsPath]);

    useEffect(() => {
        const getMissingForms = async () => {
            let knownFormIds = forms.map(({ _id }) => _id);
            let missingIds = _.uniq(descendants.filter(({ form }) => !knownFormIds.includes(form)).map(({ form }) => form)).filter(TB.mongoIdValidator);
            if (missingIds.length === 0) return forms;
            let reply = await getFormsFromFilter({ _id: missingIds });
            return Array.isArray(reply.data) ? forms.concat(reply.data) : forms;
        }
        if ([descendants, forms].every(Array.isArray)) getMissingForms().then(setForms);
    }, [descendants, forms]);
    //#endregion

    //#region Link Types
    const linkTypesNames = useMemo(() => [LT.LINK_TYPE_OWN], []);
    const ownTypeLink = useMemo(() => linkTypes?.filter?.(({ data }) => data?.type === LT.LINK_TYPE_OWN)?.[0]?._id, [linkTypes]);

    useEffect(() => {
        const getLinkTypes = async () => {
            let reply = await getManySubmissionsFromFilter({ form: linkTypeFormId, "data.type": linkTypesNames });
            return Array.isArray(reply.data) ? reply.data : null;
        }
        if (TB.mongoIdValidator(linkTypeFormId)) getLinkTypes().then(setLinkTypes);
    }, [linkTypeFormId, linkTypesNames]);
    //#endregion

    //#region Current Plan
    const currentPlan = useMemo(() => plans?.[pageActive], [plans, pageActive]);
    const currentLocation = useMemo(() => currentPlan?.location, [currentPlan]);
    const currentImage = useMemo(() => currentPlan?.src || "", [currentPlan]);
    const currentPlanName = useMemo(() => typeof currentPlan?.name === "string" && currentPlan.name.length > 0 ? currentPlan.name : Text.ANNOT_DEFAULT_PLAN[language], [currentPlan, language]);
    //#endregion

    //#region Descendance
    const emplacements = useMemo(() => descendants?.filter?.(({ form }) => form === empFormId) || [], [descendants, empFormId]);
    const equipments = useMemo(() => descendants?.filter?.(({ form }) => form === equipFormId) || [], [descendants, equipFormId]);

    const descendanceOptions = useMemo(() => {
        let groups;
        if (!offerWiderOrigins) {
            if ([emplacements, equipments].every(x => x.length === 0)) return [];
            if ([emplacements, equipments].some(x => x.length === 0)) return (emplacements.length === 0 ? equipments : emplacements).map(({ _id, data }) => <option key={_id} value={_id}>{data?.name}</option>);
            groups = [
                { label: Text.GLOBAL_LABEL_EQUIPMENT[language], values: equipments },
                { label: Text.GLOBAL_LABEL_EMPLACEMENT[language], values: emplacements }
            ];
        }
        else if ([descendants, forms].every(Array.isArray)) groups = Object.entries(_.groupBy(descendants, "form")).map(([formId, values]) => ({
            values,
            label: forms.filter(({ _id }) => _id === formId)?.[0]?.title ?? "",
        }));
        else return [];

        return groups.filter(({ values }) => values.length > 0).map(({ label, values }, i) => <optgroup key={i} label={label}>
            {values.map(({ _id, data }) => <option key={_id} value={_id}>{data?.name}</option>)}
        </optgroup>);
    }, [emplacements, equipments, language, descendants, forms, offerWiderOrigins]);

    useEffect(() => {
        const getDescendance = async () => {
            let reply = await advancedSearch(currentLocation, undefined, false, ownTypeLink, undefined, false);
            return Array.isArray(reply.data) ? reply.data : null;
        }
        if (TB.multiMongoIdValidator([currentLocation, ownTypeLink])) getDescendance().then(setDescendants);
        else setDescendants([]);
    }, [currentLocation, ownTypeLink]);
    //#endregion

    //#region Selectors
    const selectorsLabelled = useMemo(() => [
        { label: Text.ANNOT_POINT_SELECTOR, s: PointSelector },
        { label: Text.ANNOT_RECTANGLE_SELECTOR, s: RectangleSelector },
        { label: Text.ANNOT_POLYGON_SELECTOR, s: PolygonSelector },
        { label: Text.ANNOT_DRAW_SELECTOR, s: DrawingSelector },
    ], []);

    const selectors = useMemo(() => selectorsLabelled.map(({ s }) => s), [selectorsLabelled]);

    const typeSelect = useMemo(() => <div className="form-floating">
        <select className="form-select" id="typeSelectAnnot" value={type} onChange={e => setType(e.target.value)}>
            {selectorsLabelled.map(({ label, s }) => <option value={s.TYPE} key={s.TYPE}>{label[language]}</option>)}
        </select>
        <label htmlFor="typeSelectAnnot">{Text.ANNOT_FORM_TYPE[language]}</label>
    </div>, [type, selectorsLabelled, language]);
    //#endregion

    //#region Polygon Selector Specials
    const isTypePolygon = useMemo(() => type === PolygonSelector.TYPE, [type]);
    const enablePolygonSubmit = useMemo(() => isTypePolygon && annotation?.geometry?.points?.length > 2, [isTypePolygon, annotation]);
    const enablePolygonClearUndo = useMemo(() => isTypePolygon && annotation?.geometry?.points?.length > 0, [isTypePolygon, annotation]);

    useEffect(() => isTypePolygon ? console.log(annotation) : undefined, [isTypePolygon, annotation])

    const polygonTools = useMemo(() => !isTypePolygon ? undefined : <div className="polygonGrouping">
        <label>{Text.ANNOT_POLYGON_ACTIONS[language]}</label>
        <div className="btn-group polygonGroup" role="group">
            <button className="btn btn-primary" disabled={!enablePolygonSubmit} onClick={() => setAnnotation(prev => PolygonSelector.methods.onSelectionComplete(prev))}>{Text.ANNOT_COMPLETE_POLYGON[language]}</button>
            <button className="btn btn-primary" disabled={!enablePolygonClearUndo} onClick={() => setAnnotation(prev => PolygonSelector.methods.onSelectionUndo(prev))}>{Text.GLOBAL_CANCEL[language]}</button>
            <button className="btn btn-danger" disabled={!enablePolygonClearUndo} onClick={() => setAnnotation({})}>{Text.DIA_REINITIALIZE[language]}</button>
        </div>
    </div>, [isTypePolygon, enablePolygonSubmit, enablePolygonClearUndo, language]);
    //#endregion

    //#region Data changes
    const onSubmit = useCallback((annotation) => {
        const { geometry, data } = annotation

        setAnnotation({});
        setAnnotations(prev => prev.concat({
            geometry,
            data: {
                ...data,
                id: Math.random()
            }
        }));
        setAskForSave(true);
    }, []);

    const onChangeExisting = useCallback(annotation => {
        let id = annotation?.data?.id;
        if (id) {

            setAnnotations(prev => prev.map(a => {
                if (a.data.id === id) return annotation;
                return a;
            }));
            setAskForSave(true);
        }
    }, []);

    const onChange = useCallback(setAnnotation, [setAnnotation]);
    useEffect(() => setAnnotation({}), [type]);
    //#endregion

    //#region Vent Detail Measure
    const currentVentMeasure = useMemo(() => {
        if (!Array.isArray(ventsMeasure)) return null;
        return ventsMeasure.filter(({ obj }) => obj === currentLocation)?.[0];
    }, [currentLocation, ventsMeasure]);

    const equipFilter = useMemo(() => "_id__in=" + equipments.map(({ _id }) => _id).join(), [equipments]);
    const showVentOptions = useMemo(() => allowVentsEdit && typeof setVents === "function", [allowVentsEdit, setVents]);

    const doesVentExists = useCallback(originId => {
        // No origin selected, no measure allowed
        if (!TB.mongoIdValidator(originId)) return null;
        let equip = equipments.filter(({ _id }) => _id === originId)?.[0]?._id;
        // Origin is an equipment, send back existing measure or undefined
        if (TB.mongoIdValidator(equip)) return currentVentMeasure?.vents?.vents?.filter?.(({ equipment }) => equipment === equip)?.[0];
        return null;
    }, [currentVentMeasure, equipments]);

    const editVentMeasure = useCallback((originId, measure) => {
        let submission = { equipmentAvailable: equipFilter };
        if (measure === undefined) submission.equipment = originId;
        else submission = Object.assign(submission, measure);

        let ventData = new Promise(resolve => ReactDom.render(<Dialog open={true} onClose={() => resolve(null)} maxWidth="lg" fullWidth={true}>
            <BlankModal title="Vent measure" size="lg" onQuit={() => resolve(null)}>
                <Form
                    noDbSaving
                    onSave={resolve}
                    path={FP.VENT_DETAIL_PATH}
                    forcedSubmission={TB.submissionToArrayUpdate(submission)}
                />
            </BlankModal>
        </Dialog>, ventPopUpContainer.current));

        ventData.then(form => {
            // The form wasn't closed
            if (form !== null && TB.mongoIdValidator(form?.data?.equipment)) setVents?.(ventMeasures => {
                // Had nothing beforehand
                if (!Array.isArray(ventMeasures)) return [{ obj: currentLocation, vents: { total: {}, vents: [form.data] } }];

                // If the current Location didn't exists before
                if (ventMeasures.filter(({ obj }) => obj === currentLocation).length === 0) return ventMeasures.concat({
                    obj: currentLocation,
                    vents: { total: {}, vents: [form.data] }
                });

                // If the current location existed before
                return ventMeasures.map(({ obj, vents }) => {
                    if (obj !== currentLocation) return { obj, vents };

                    // If no vents measure before
                    if (!Array.isArray(vents?.vents)) return { obj, vents: { total: vents?.total || {}, vents: [form.data] } };

                    let updatedVent = [];

                    // That equipment didn't have an entry yet
                    if (vents.vents.filter(({ equipment }) => equipment === form?.data?.equipment).length === 0) updatedVent = vents.vents.concat(form.data);
                    else updatedVent = vents.vents.map(({ equipment, ...r }) => {
                        // Not the equipment we are looking for
                        if (equipment !== form?.data?.equipment) return { equipment, ...r };
                        // The equipment we are looking for
                        return form.data;
                    });

                    return { obj, vents: { total: vents?.total || {}, vents: updatedVent } };
                });
            });
            ReactDom.unmountComponentAtNode(ventPopUpContainer.current);
        });
    }, [equipFilter, currentLocation, setVents]);
    //#endregion

    //#region Custom Components
    const validName = useCallback(name => typeof name === "string" && name.length > 0, []);

    useEffect(() => {
        let duplicates = Object.entries(_.groupBy(annotations, ({ data }) => data.text))
            .filter(([key, array]) => typeof key === "string" && key.length > 0 && array.length > 1)
            .map(([key, array]) => array);

        if (duplicates.length === 0) return;

        let textTransformed = annotations.map(({ data, ...a }) => ({ ...a, data: { ...data, text: data.text.replace(/\(\d+\)/g, "") } }))
        let duplicatesSimilar = Object.entries(_.groupBy(textTransformed, ({ data }) => data.text))
            .filter(([key, array]) => typeof key === "string" && key.length > 0 && array.length > 1)
            .map(([key, array]) => array);

        // Rename the duplicates
        let transformed = _.flatten(duplicatesSimilar.map(array => array.map(({ data }, i) => [data.id, (i === 0 ? data.text : `${data.text}(${i})`)])));
        let newNameObjects = Object.fromEntries(transformed);

        setAnnotations(prev => prev.map(a => {
            let val = newNameObjects?.[a?.data?.id];
            if (val !== undefined) a.data.text = val;
            return a;
        }));
    }, [annotations]);

    const descendanceSelect = useCallback((val, setter, id) => descendanceOptions.length === 0 ? undefined : <div className="form-floating">
        <select className="form-select valInput" value={val} onChange={setter}>
            <option></option>
            {descendanceOptions}
        </select>
        <label>{Text.ANNOT_ORIGIN[language]}</label>
    </div>, [descendanceOptions, language]);

    const itemForm = useCallback(({ geometry, selection, data = {} }, onChange, addMeasure = false) => {
        let { text, color, origin, ...other } = data;
        let ventMeasure = showVentOptions ? doesVentExists(origin) : null;

        return <div className="editorContent">
            <div className="form-floating valInput">
                <input autoFocus autoComplete="off" className="form-control" value={text || ""} id="annotName" placeholder="name" onChange={e => onChange({ geometry, selection, data: { ...data, ...other, text: e.target.value } })} />
                <label htmlFor="annotNam">{Text.GLOBAL_NAME[language]}</label>
            </div>

            <div className="form-floating valInput">
                <input type="color" className="form-control" id="exampleColorInput" value={color || "#FFFFFF"} onChange={e => onChange({ geometry, selection, data: { ...data, ...other, color: e.target.value } })} />
                <label htmlFor="exampleColorInput">{Text.GLOBAL_COLOR[language]}</label>
            </div>

            {descendanceSelect(origin, e => onChange({ geometry, selection, data: { ...data, ...other, origin: e.target.value } }))}

            {ventMeasure !== null && <div className="d-grid gap-2 mt-2">
                <button onClick={() => editVentMeasure(origin, ventMeasure)} className="btn btn-primary">
                    <i className={`fa fa-${ventMeasure === undefined ? "plus" : "pen"} mr-2`}></i>
                    {ventMeasure === undefined ? Text.ANNOT_ADD_MEASURE[language] : Text.ANNOT_EDIT_MEASURE[language]}</button>
            </div>}
        </div>;
    }, [showVentOptions, language, descendanceSelect, doesVentExists, editVentMeasure]);

    const renderEditor = useCallback(({ annotation, onChange, onSubmit }) => {
        let { geometry, data } = annotation;
        if (typeof data !== "object" || data === null) data = {};
        let { text } = data;

        return <div className="editorWrapper" style={editorStyle(geometry)}>
            {itemForm(annotation, onChange)}
            {validName(text) && <div className="editorSubmit">
                <button onClick={() => onSubmit({ geometry, data: { ...data } })}>{Text.ANNOT_SUBMIT[language].toUpperCase()}</button>
            </div>}
        </div>
    }, [language, itemForm, validName]);
    //#endregion

    //#region Annotation Recap
    const toggleAnnotation = useCallback(id => {
        setAnnotations(prevState => prevState.map(a => {
            if (a?.data?.id === id) return { ...a, data: { ...a.data, hidden: !a.data.hidden } };
            return a;
        }));
        setAskForSave(true);
    }, []);

    const openAnnotation = useCallback(id => setAnnotations(prevState => prevState.map(a => {
        if (a?.data?.id === id) return { ...a, data: { ...a.data, open: !a.data.open } };
        return a;
    })), []);

    const deleteAnnotation = useCallback(id => {
        let confirmDelete = new Promise(resolve => ReactDom.render(<Confirm
            language={language}
            onValidate={resolve}
            onQuit={() => resolve(null)}
            title={Text.DELETE[language]}
            text={Text.GLOBAL_CONFIRM_DELETE[language]}
        />, confirmContainer.current));

        confirmDelete.then(doDelete => {
            if (doDelete) {
                setAnnotations(prevState => prevState.filter(({ data }) => data.id !== id));
                setAskForSave(true);
            }
            ReactDom.unmountComponentAtNode(confirmContainer.current);
        });
    }, [language]);

    const activeAnnotation = useMemo(() => annotations.filter(({ data }) => !data?.hidden), [annotations]);
    const activeAnnotationIds = useMemo(() => activeAnnotation.map(({ data }) => data?.id), [activeAnnotation]);

    const openedAnnotationIds = useMemo(() => annotations.filter(({ data }) => data?.open).map(({ data }) => data.id), [annotations]);

    const annotationsRecap = useMemo(() => annotations.length === 0 ? undefined : <div className="annotationList">
        <label>{Text.ANNOT_NAME[language]}</label>
        {annotations.map(({ data, ...annot }) => <div className="annotationItem" key={data.id}>
            <div className="annot_overview input-group">
                <div className="input-group-text">
                    <input type="checkbox" checked={activeAnnotationIds.includes(data.id)} onChange={() => toggleAnnotation(data.id)} />
                </div>
                <span className="input-group-text nameInput">{data.text}</span>
                <button className="btn btn-outline-primary" onClick={() => openAnnotation(data.id)}>
                    <i className={`fa fa-chevron-${openedAnnotationIds.includes(data.id) ? "up" : "down"}`}></i>
                </button>
                <button className="btn btn-outline-danger" onClick={() => deleteAnnotation(data.id)}><i className="fa fa-trash"></i></button>
            </div>
            {openedAnnotationIds.includes(data.id) && <div className="annot_edit">
                {itemForm({ data, ...annot }, onChangeExisting)}
            </div>}
        </div>)}
    </div>, [annotations, activeAnnotationIds, openedAnnotationIds, language, toggleAnnotation, openAnnotation, deleteAnnotation, itemForm, onChangeExisting]);
    //#endregion

    //#region Saving
    const uploadTabIndex = useMemo(() => plans.length, [plans]);

    const saveSuccessPanel = useMemo(() => <div className="alert alert-success" role="alert">
        <i className="fa fa-check-circle"></i> {Text.NOTIF_SAVE_SUCCESS[language]}
    </div>, [language]);

    const loader = useMemo(() => <div className="loader_bg">
        <span>{Text.ANNOT_SAVING[language]}...</span>
        <div className="loader"></div>
    </div>, [language]);

    const savePlans = useCallback(async () => {
        if (pageActive !== uploadTabIndex) {
            let node = annotContainer.current?.children?.[0];

            if (node instanceof Element || node instanceof Document) {
                ReactDom.render(loader, loaderContainer.current);

                let img = await toPng(node);
                ReactDom.unmountComponentAtNode(loaderContainer.current);
                onSave(plans.map((plan, i) => {
                    if (i === pageActive) return { ...plan, planImg: img, annotations };
                    return plan;
                }));
                setAskForSave(false);

                // Success Notification
                if (successContainer.current !== null) ReactDom.render(saveSuccessPanel, successContainer.current);
                setTimeout(() => successContainer.current !== null ? ReactDom.unmountComponentAtNode(successContainer.current) : undefined, 10000);
            }
        }
    }, [pageActive, uploadTabIndex, saveSuccessPanel, loader, plans, annotations, onSave]);
    //#endregion

    //#region Header
    const closePopUp = useCallback(() => {
        if (!askForSave) close?.();
        else {
            let confirmSave = new Promise(resolve => ReactDom.render(<Confirm
                language={language}
                onValidate={resolve}
                onQuit={() => resolve(null)}
                title={Text.GLOBAL_QUIT[language]}
                noText={Text.ANNOT_NO_SAVE[language]}
                text={Text.GLOBAL_SAVE_BEFORE_QUIT[language]}
            />, confirmContainer.current));

            confirmSave.then(async save => {
                if (save !== null) {
                    if (confirmContainer.current !== null) ReactDom.unmountComponentAtNode(confirmContainer.current);
                    if (save) await savePlans();
                    close?.();
                }
                else ReactDom.unmountComponentAtNode(confirmContainer.current);
            });
        }
    }, [close, language, askForSave, savePlans]);

    const changeTab = useCallback(async newPage => {
        // Update the plans we were working on
        if (pageActive !== uploadTabIndex && pageActive !== newPage) {
            ReactDom.render(loader, loaderContainer.current);
            let img = await toPng(annotContainer.current);
            ReactDom.unmountComponentAtNode(loaderContainer.current);
            setPlans(prev => prev.map((plan, i) => {
                if (i === pageActive) return { ...plan, planImg: img, annotations };
                return plan;
            }));
        }

        setPageActive(newPage);
    }, [pageActive, uploadTabIndex, annotations, loader]);

    const plansTabs = useMemo(() => plans.map(({ name }, i) => <li key={i} className="nav-item">
        <button onClick={() => changeTab(i)} className={"nav-link " + (pageActive === i ? 'active' : '')}>{name}</button>
    </li>), [plans, pageActive, changeTab]);

    const uploadTab = useMemo(() => <li className="nav-item">
        <button className={"nav-link " + (pageActive === uploadTabIndex ? "active" : "")} onClick={() => changeTab(uploadTabIndex)}>
            <i className="fa fa-plus mr-2"></i>
            {Text.GLOBAL_NEW[language]}
        </button>
    </li>, [uploadTabIndex, pageActive, language, changeTab]);

    const navBar = useMemo(() => <div className="full_header">
        <div className="nav_header">
            <ul className="nav nav-tabs nav-justified">
                {plansTabs}
                {uploadTab}
            </ul>
        </div>
        <button onClick={closePopUp} className="quitButton"><i className="fa fa-times"></i></button>
    </div>, [plansTabs, uploadTab, closePopUp]);
    //#endregion

    //#region Plan Edition
    const deletePlan = useCallback(planName => {
        let confirmDelete = new Promise(resolve => ReactDom.render(<Confirm
            language={language}
            onValidate={resolve}
            onQuit={() => resolve(null)}
            title={Text.DELETE[language]}
            text={Text.GLOBAL_CONFIRM_DELETE[language]}
        />, confirmContainer.current));

        confirmDelete.then(doDelete => {
            if (doDelete) {
                setPlans(prev => prev.filter(({ name }) => name !== planName));
                setAskForSave(true);
            }
            ReactDom.unmountComponentAtNode(confirmContainer.current);
        });
    }, [language]);

    const plansNameList = useMemo(() => plans?.map?.(({ name }) => name), [plans]);

    const validPrompt = useMemo(() => str => {
        if (str === currentPlanName) return true;
        if (plansNameList.includes(str)) return { isValid: false, message: Text.ANNOT_NAME_USED[language] };
        return true;
    }, [plansNameList, currentPlanName, language]);

    const renamePlan = useCallback(planName => {
        askPrompt({ isRequired: true, defaultVal: planName, label: TC.GLOBAL_NAME, valChecker: validPrompt })
            .then(val => {
                if (typeof val === "string") {
                    setAskForSave(true);
                    setPlans(prev => prev.map(plan => {
                        if (plan.name === planName) return { ...plan, name: val, annotations };
                        return plan;
                    }));
                }
            });
    }, [annotations, validPrompt]);

    const editPlanPanel = useMemo(() => <div className="btn-group mb-2" style={{ width: "100%" }}>
        <button className="btn btn-primary" onClick={() => renamePlan(currentPlanName)}>{Text.GLOBAL_RENAME[language]}</button>
        <button className="btn btn-danger" onClick={() => deletePlan(currentPlanName)}><i className="fa fa-trash mr-2"></i>{Text.GLOBAL_DELETE[language]}</button>
    </div>, [language, currentPlanName, deletePlan, renamePlan]);
    //#endregion

    //#region ToolBar
    const hasError = useMemo(() => [descendants, forms].includes(null), [descendants, forms]);

    const errorPanel = useMemo(() => !hasError ? undefined : <div class="alert alert-danger" role="alert">
        <i className="fa fa-exclamation-circle"></i> {Text.NOTIF_DIA_ERR_UPDATE_FETCH[language]}
    </div>, [hasError, language]);

    const downloadImage = useCallback(() => {
        let node = annotContainer.current?.children?.[0];
        if (node instanceof Element || node instanceof Document) {

            ReactDom.render(loader, loaderContainer.current);
            toPng(node).then(res => {
                TB.downloadPNG(res, currentPlanName);
                ReactDom.unmountComponentAtNode(loaderContainer.current);
            });
        }
    }, [currentPlanName, loader]);

    const toolbar = useMemo(() => <div className="toolBar">
        <div className="main_tools">
            {editPlanPanel}
            <div ref={successContainer}></div>
            {errorPanel}
            {typeSelect}
            {polygonTools}
            {annotationsRecap}
        </div>
        <div className="close_menu">
            <button className="btn btn-primary" disabled={!askForSave} onClick={savePlans} >{Text.GLOBAL_SAVE[language]}</button>
            <button className="btn btn-primary" onClick={downloadImage} ><i className="fa fa-file-download mr-2"></i>{Text.GLOBAL_DOWNLOAD[language]}</button>
            <button className="btn btn-danger" onClick={closePopUp}>{Text.GLOBAL_QUIT[language]}</button>
        </div>
    </div>, [typeSelect, editPlanPanel, errorPanel, annotationsRecap, askForSave, polygonTools, language, downloadImage, closePopUp, savePlans]);
    //#endregion

    //#region Update
    useEffect(() => setAnnotation({}), [pageActive]);
    useEffect(() => setAnnotations(Array.isArray(currentPlan?.annotations) ? currentPlan?.annotations : []), [currentPlan]);
    //#endregion

    //#region Body
    const showUpload = useMemo(() => pageActive === plans.length, [pageActive, plans]);
    const allowConfirm = useMemo(() => [tempName, tempBase64].every(x => typeof x === "string" && x.length > 0), [tempName, tempBase64]);

    const addPlan = useCallback(() => {
        let newPlans = plans.concat({ src: tempBase64, name: tempName, location: tempLocation });
        setAskForSave(true);
        setPlans(newPlans);
        setPageActive(newPlans.length - 1);
        setTempBase64();
        setTempLocation();
        setTempName('');
    }, [plans, tempBase64, tempName, tempLocation]);

    const locationsOptions = useMemo(() => (Array.isArray(locations) ? locations : []).map(({ _id, data }) => <option key={_id} value={_id}>{data?.name}</option>), [locations]);

    const locationSelect = useMemo(() => locationsOptions.length === 0 ? undefined : <div className="form-floating">
        <select value={tempLocation} onChange={e => setTempLocation(e.target.value)} className="form-select" id="locationSelectPlan">
            <option></option>
            {locationsOptions}
        </select>
        <label htmlFor="locationSelectPlan">{Text.GLOBAL_LABEL_EMPLACEMENT[language]}</label>
    </div>, [locationsOptions, tempLocation, language]);

    const uploadBody = useMemo(() => <>
        <div className="card uploadCard">
            <div className="card-body">
                <h5 className="card-title">{Text.GLOBAL_UPLOAD_PLAN[language]}</h5>
                <div className="form-floating formInput mb-3">
                    <input value={tempName} id="floatingNamePlan" className="form-control" placeholder="name" onChange={e => setTempName(e.target.value)} />
                    <label htmlFor="floatingNamePlan">{Text.GLOBAL_NAME_PLAN[language]}</label>
                </div>

                <div className="formInput mb-3">
                    <label htmlFor="filePlan" className="form-label">{Text.ANNOT_DEFAULT_PLAN[language]}</label>
                    <ImgUploader
                        id="filePlan"
                        multiple={false}
                        setBase64={img => Array.isArray(img) && img.length > 0 ? setTempBase64(img[0]) : undefined}
                    />
                </div>

                {locationSelect}

                <div className="form-confirm">
                    <button disabled={!allowConfirm} onClick={addPlan} className="btn btn-primary">{Text.GLOBAL_CONFIRM[language]}</button>
                </div>
            </div>
        </div>
    </>, [tempName, allowConfirm, locationSelect, language, addPlan]);

    const annotBody = useMemo(() => showUpload ? undefined : <div className="annotationWrapper">
        <div ref={annotContainer} className="annotationEditor">
            <Annotation
                src={currentImage}
                alt="alt"
                annotations={activeAnnotation}
                onChange={onChange}
                onSubmit={onSubmit}
                value={annotation}
                renderEditor={renderEditor}
                renderSelector={selectorRender}
                renderHighlight={renderHighlight}
                renderContent={() => { }} //TODO
                type={type}
                selectors={selectors}
            />
        </div>
        {toolbar}
    </div>, [activeAnnotation, annotation, selectors, currentImage, showUpload, toolbar, type, onChange, onSubmit, renderEditor]);

    const body = useMemo(() => showUpload ? uploadBody : annotBody, [annotBody, showUpload, uploadBody]);
    //#endregion

    return <Dialog open={true} fullScreen={true}>
        <div style={{ display: "grid", gridTemplateRows: "45px auto", height: "100%" }}>
            {navBar}
            <div className="annot_body position-relative">
                {body}
            </div>
        </div>
        <div className="loadContainer" ref={loaderContainer}></div>
        <div ref={confirmContainer}></div>
        <div ref={ventPopUpContainer}></div>
    </Dialog>
}

export default Annotations;