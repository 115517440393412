import { validString } from "./string";

/**
 * Return a boolean if the value is a boolean or a string containing a boolean, null otherwise
 */
export const getBoolean = (value: any) => {
    if (typeof value === "boolean") return value;
    else if (validString(value)) {
        let minVal = value.trim().toLowerCase();
        if (["true", "false"].includes(minVal)) return minVal === "true";
        else return null;
    }
    else return null;
}