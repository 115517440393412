import ReactDOM from 'react-dom';
import DefaultComp from './NoRenderComp';
import { ReactComponent } from 'react-formio';

export default class RegProperties extends ReactComponent {
    static get builderInfo() {
        return {
            weight: 1,
            icon: "table",
            group: "Custom",
            documentation: "",
            title: "RegProperties",
            schema: RegProperties.schema(),
        };
    }

    static schema = () => ReactComponent.schema({ type: "RegProperties", label: "RegProperties" });
    attachReact = element => ReactDOM.render(<DefaultComp />, element);
    detachReact = element => element ? ReactDOM.unmountComponentAtNode(element) : undefined;
}