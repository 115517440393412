import XLSX from 'xlsx';
import React from 'react';
import moment from 'moment';
import * as T from "./Types";
import * as TB from "./Tools";
import { validate } from "uuid";
import { LANG, FP } from "./Data";
import { APP_DOMAIN } from "./Constants.URL";

export * from "./Tools";

export const isFile = (obj: any): obj is T.File => {
    if (!TB.validObject(obj)) return false;
    if (!TB.validString(obj.storage)) return false;
    return TB.validString(obj.url);
}

export const isMatrixObject = (obj: any): obj is T.MatrixObj => {
    if (!TB.validObject(obj)) return false;
    if (!TB.mongoIdValidator(obj._id)) return false;
    if (!FP.RESOURCE_FORMS.includes(obj.form)) return false;
    if (!Array.isArray(obj.selectors) || !obj.selectors.every(isMatrixSelector)) return false;
    return true;
}

export const isMatrixSelector = (obj: any): obj is T.MatrixSelector => {
    if (!TB.validObject(obj)) return false;
    if (!validate(obj.id)) return false;
    if (typeof obj.isFormProp !== "boolean") return false;
    if (obj.importance !== null && typeof obj.importance !== "boolean") return false;
    if (!TB.validString(obj.propAffected)) return false;
    return Array.isArray(obj.comparators) && obj.comparators.every(isMatrixComparator)
}

export const isMatrixComparator = (obj: any): obj is T.MatrixComparator => {
    if (!TB.validObject(obj)) return false;
    if (!TB.validObject(obj.values)) return false;
    if (!TB.validString(obj.type)) return false;
    return typeof obj.propChecked === "string";
}

export const isRenovationAction = (obj: any): obj is T.RenovationActionType => {
    if (validSubmission(obj)) {
        let stringProp = ["name", "cost", "lifetime", "emissions", "energySavings", "energyProduction"];
        return stringProp.every(p => TB.validString(obj.data[p])) && Array.isArray(obj.data.gammes);
    }
    return false;
}

export const isLocOption = <A extends {} = {}>(loc: T.FullOptionsLocations, path: string): loc is T.FullOptionsLocationsTyped<T.Submission<A>> => loc.path === path;

export const isSite = (obj: any): obj is T.SiteType => validSubmission(obj);
export const isUser = (obj: any): obj is T.UserType => validSubmission(obj);
export const isBuilding = (obj: any): obj is T.BuildingType => validSubmission(obj);
export const isContract = (obj: any): obj is T.ContractType => validSubmission(obj);
export const isValidEquipment = (obj: any): obj is T.EquipmentType => validSubmission(obj);
export const isValidCrumb = (obj: any): obj is T.CrumbsRedux => TB.validObject(obj) && TB.validString(obj.label);
export const isTicket = (obj: any): obj is T.TicketType => validSubmission(obj) && TB.validString(obj.data.title);
export const isValidLocationObj = (obj: any): obj is T.LocationType => TB.validObject(obj) && TB.mongoIdValidator(obj.id);
export const isValidEquipGamme = (obj: any): obj is T.EquipGammeType => validSubmission(obj) && TB.validString(obj.data.omniclass);
export const validLink = (obj: any): obj is T.Link => TB.validObject(obj) && TB.multiMongoIdValidator([obj._id, obj.input, obj.output, obj.type]);
export const isValidForm = (obj: any): obj is T.FormType => TB.validObject(obj) && TB.mongoIdValidator(obj._id) && FP.ALL_OBJECT_TYPES.includes(obj.path);
export const isEmplacement = (obj: any): obj is T.EmplacementType => validSubmission(obj) && ["zone", "local", "floor", "parking"].includes(obj.data.type);
export const isFavorite = <A = T.AnyObject>(obj: any): obj is T.BaseFavorite<A> => TB.validObject(obj) && TB.validString(obj.name) && TB.validObject(obj.filters);
export const isRenovationItem = (obj: any): obj is T.RenovationItem => TB.validObject(obj) && ((typeof obj.date === "number" && !isNaN(obj.date)) || ["description", "type"].some(prop => TB.validString(obj[prop])));
export const validSubmission = <A extends {} = T.AnyObject>(obj: any): obj is T.Submission<A> => TB.validObject(obj) && TB.mongoIdValidator(obj._id) && TB.validObject(obj.data);
export const isSub = <A extends {} = T.AnyObject>(obj?: T.Submission<A>): obj is T.Submission<A> => TB.validObject(obj) && TB.mongoIdValidator(obj._id) && TB.validObject(obj.data);

export const isIterableArray = (obj: any): obj is any[] => Array.isArray(obj) && obj.length > 0;
export const isArrayString = (array: any): array is string => Array.isArray(array) && array.every(TB.validString);
export const isDomElement = (elem: any): elem is Element | Document => elem instanceof Element || elem instanceof Document;
export const isDomOrReactElement = (elem: any): elem is Element | Document | React.ReactElement<string | React.JSXElementConstructor<any>> => [isDomElement, React.isValidElement].some(fn => fn(elem));

type transformTableStateFn = (tableState: T.TableState) => T.TableState;

export const transformTableState: transformTableStateFn = (tableState: T.TableState) => {
    let { filters, adaptable } = tableState;
    if (typeof filters !== "string") filters = "{}";
    try { filters = JSON.parse(filters); }
    catch (error) { filters = {}; }
    if (typeof adaptable !== "string") adaptable = "{}";
    try { adaptable = JSON.parse(adaptable); }
    catch (error) { adaptable = {}; }
    return { ...tableState, filters, adaptable };
}

export const blankSub = <D = {}>(owner: string, form: string, data?: D): Omit<T.Submission<Partial<D>>, "_id"> => ({
    form,
    owner,
    roles: [],
    access: [],
    deleted: null,
    externalIds: [],
    modifiedBy: owner,
    data: TB.getObject(data),
    created: new Date().toISOString(),
    modified: new Date().toISOString(),
    metadata: {
        onLine: TB.getBoolean(navigator?.onLine),
        offset: new Date().getTimezoneOffset(),
        userAgent: TB.getString(navigator?.userAgent),
        browserName: TB.getString(navigator?.appName),
        origin: TB.getString(window?.location?.origin),
        referrer: TB.getString(window?.document?.referrer),
        pathName: TB.getString(window?.location?.pathname),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || "",
    },
});

export const blankSubBackend = <D = {}>(owner: string, form: string, data?: D): Omit<T.Submission<Partial<D>>, "_id"> => ({
    form,
    owner,
    roles: [],
    access: [],
    deleted: null,
    externalIds: [],
    modifiedBy: owner,
    data: TB.getObject(data),
    created: new Date().toISOString(),
    modified: new Date().toISOString(),
    metadata: {
        onLine: true,
        referrer: "",
        pathName: '/',
        origin: APP_DOMAIN,
        browserName: "Netscape",
        offset: new Date().getTimezoneOffset(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || "",
        userAgent: "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/94.0.4606.61 Safari/537.36",
    },
});

export const downloadFile = (dataUrl: string, name: string, extension: string, isB64 = true) => {
    if (!isB64) dataUrl = window.URL.createObjectURL(new Blob([dataUrl]));
    if ([dataUrl, name, extension].every(TB.validString) && document) {
        let a = document.createElement('a');
        a.setAttribute("href", dataUrl);
        a.setAttribute("download", name + (TB.validString(extension) ? "." + extension : ""));
        document.body.appendChild(a);
        a.click();
        a.remove();
        return true;
    }
    else return null;
}

export const downloadObjectAsJson = (exportObj: object, exportName: string) => {
    let dataUrl = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(TB.validObject(exportObj) ? exportObj : {}));
    return downloadFile(dataUrl, exportName, "json");
}

export const downloadPNG = (dataUrl: string, name: string) => downloadFile(dataUrl, name, "png");

export const dateToFullString = (date: Date | string, language = 0, showHourMin = false): string => {
    let format = "dddd D MMMM YYYY";
    if (showHourMin) format += " HH:mm";
    let momentDate = moment(date);
    return momentDate.locale(LANG.MOMENT_LOCAL(language)).format(format);
}

export const getBrowserTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

/**
 * 
 * @param {*} value The value to stringify
 * @param {Boolean} [doNormalize=true] Should the string be normalised ?
 * @param {Boolean = false | null} toAllCaps null if untouched
 * @returns {string | null} null if no conversion possible
 */
export const normalizedString = (value: any, language = 0, doNormalize = true, toAllCaps: boolean | null = false, arrayToString = false) => {
    // Drop value if no value
    if (value === undefined || value === null) return null;
    // Cast Date to string //TODO Find a way to differentiate the string that do represent a date and the others
    let searchValue = ((typeof value === "object" && !Array.isArray(value)/*  || typeof value === "string" */) && !isNaN(Date.parse(value)) && Date.parse(value) > 0 && isNaN(parseInt(value)) ? dateToFullString(new Date(value), language) : value);
    // Cast number to string
    searchValue = (typeof searchValue === "number" ? searchValue.toString() : searchValue);
    // Cast Array to string
    searchValue = (Array.isArray(searchValue) && !arrayToString ? `[ ${searchValue.map(val => normalizedString(val, language, doNormalize, toAllCaps)).join(', ')} ]` : searchValue);
    // Cast Array to an array of string
    searchValue = (Array.isArray(searchValue) && arrayToString) ? searchValue.map(val => normalizedString(val, language, doNormalize, toAllCaps)) : searchValue;
    // Cast Object to string
    searchValue = (typeof searchValue === "object" && !Array.isArray(searchValue) ? `{ ${Object.values(searchValue).map(val => normalizedString(val, language, doNormalize, toAllCaps)).join(', ')} }` : searchValue);
    // Cast Boolean to string
    searchValue = (typeof searchValue === "boolean" ? searchValue.toString() : searchValue);
    // Normalize string
    if (typeof searchValue === "string") {
        searchValue = (doNormalize ? searchValue.normalize('NFD').replace(/[\u0300-\u036f]/g, "") : searchValue);
        if (toAllCaps) searchValue = searchValue.toUpperCase();
        else if (toAllCaps !== null) searchValue = searchValue.toLowerCase();
    }
    else if (Array.isArray(searchValue) && arrayToString) {
        searchValue = (doNormalize ? searchValue.map(str => TB.getString(str).normalize("NFD").replace(/[\u0300-\u036f]/g, "")) : searchValue);
        if (toAllCaps) searchValue = searchValue.map(str => TB.getString(str).toUpperCase());
        else if (toAllCaps !== null) searchValue = searchValue.map(str => TB.getString(str).toLowerCase());
    }
    return (typeof searchValue === "string" || (Array.isArray(searchValue) && arrayToString) ? searchValue : null);
}

/**
 * Downloads an excel file
 * @param {[Array]} aoa An array of array that contains the data
 * @param {String} name The name of the file
 * @returns {null | undefined} null if data was not of a good format
 */
export const aoaToExcel = (aoa, name, sheetName) => {
    if (!Array.isArray(aoa)) return null;
    if (typeof name !== "string" || name.length === 0) name = 'excel';
    if (typeof sheetName !== "string" || sheetName.length === 0) sheetName = "worksheet";

    // Create a workbook
    let workbook = XLSX.utils.book_new();

    // Add a worksheet
    workbook.SheetNames.push(sheetName);
    workbook.Sheets[sheetName] = XLSX.utils.aoa_to_sheet(aoa);
    XLSX.writeFile(workbook, name + ".xlsx");
}

export const jsonToExcel = (json, name, sheetName) => {
    if (!Array.isArray(json) || !TB.validObject(json)) return null;
    if (typeof name !== "string" || name.length === 0) name = 'excel';
    if (typeof sheetName !== "string" || sheetName.length === 0) sheetName = "worksheet";

    // Create a workbook
    let workbook = XLSX.utils.book_new();

    // Add a worksheet
    workbook.SheetNames.push(sheetName);
    workbook.Sheets[sheetName] = XLSX.utils.json_to_sheet(json);
    XLSX.writeFile(workbook, name + ".xlsx");
}