import React from "react";
import moment from "moment";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import * as S from "../../../services";
import { T, TC, URL, FP, TB } from "../../../Constants";

type PointHeritageProps = Record<"children", T.AllowArray<React.ReactElement>>;

type ProofProps = {
    /** The id of the action associated with the proof */
    action_id: string;
    /** A callback to fire after the creation of a proof document */
    on_proof: (proof: "deleted" | ProofProps["proof"]) => void;
    /** In case a custom label is needed */
    label?: string;
    /** The content of the proof */
    proof?: AlignmentProps["data"]["infos"]["proofs"][number];
    /** The mission's asset id */
    asset_id: string;
}

export type AlignmentProps = {
    /** The necessary data to display in the wizard */
    data: ReturnType<T.API.Utils.Missions.MissionResumeEuTaxonomy>;
    /** A callback to update the data */
    update: React.Dispatch<React.SetStateAction<AlignmentProps["data"]>>;
    /** Should the value be editable */
    read_only: boolean;
    /** Callback to reload the data, when country or region changes */
    reload: () => void;
}

const Alignment: React.FC<AlignmentProps> = ({ update, reload, ...props }) => {
    const lg = H.useLanguage();
    const [forms] = H.useFormIds();
    const [regions, set_regions, region_status] = H.useAsyncState<T.Option[]>([]);
    const [countries, set_countries, country_status] = H.useAsyncState<T.Option[]>([]);

    //#region Load options
    React.useEffect(() => {
        let isSubscribed = true;
        S.getCountries(lg.prop)
            .then(({ data }) => isSubscribed && set_countries(data, "done"))
            .catch(() => isSubscribed && set_countries([], "error"));
        return () => {
            isSubscribed = false;
            set_countries([], "load");
        }
    }, [set_countries, lg.prop]);

    React.useEffect(() => {
        let isSubscribed = true;
        if (!props.data?.site?.country) set_regions([], "done");
        else S.getRegions(props.data?.site?.country)
            .then(({ data }) => isSubscribed && set_regions(data, "done"))
            .catch(() => isSubscribed && set_regions([], "error"));
        return () => {
            isSubscribed = false;
            set_regions([], "load");
        }
    }, [set_regions, props.data?.site?.country]);
    //#endregion

    //#region Events & Conditions
    const events = React.useMemo(() => ({
        update_site: (prop: keyof AlignmentProps["data"]["site"], value: any) => {
            const updatePromise = new Promise<"updated" | "non_updated">((resolve, reject) => {
                let api_params = {
                    field: prop,
                    new_value: value,
                    _id: props.data?.site?._id,
                    old_value: props.data?.site?.[prop],
                } as Parameters<typeof S.editSiteField>[0];

                S.editSiteField(api_params).then(({ data }) => {
                    if (data === "changed") M.askConfirm({ title: TC.UPDATE_FORCE_CHANGE, text: TC.UPDATE_VALUE_UNFRESH }).then(confirmed => {
                        if (confirmed) S.editSiteField({ ...api_params, force_update: true }).then(({ data }) => {
                            if (data === "changed") reject("Error");
                            else resolve("updated");
                        }).catch(reject);
                        else resolve("non_updated");
                    });
                    else resolve("updated");
                }).catch(reject);
            });

            updatePromise.then(state => {
                if (state === "updated") {
                    if (prop === "country" || prop === "region") reload();
                    update(p => ({ ...p, site: { ...p.site, [prop]: value } }));
                }
            }).catch(M.Alerts.updateError);
        },
        update_building: (prop: keyof AlignmentProps["data"]["building"], value: any) => {
            const updatePromise = new Promise<"updated" | "non_updated">((resolve, reject) => {
                let api_params = {
                    field: prop,
                    new_value: value,
                    _id: props.data?.building?._id,
                    old_value: props.data?.building?.[prop],
                } as Parameters<typeof S.editBuildingField>[0];

                S.editBuildingField(api_params).then(({ data }) => {
                    if (data === "changed") M.askConfirm({ title: TC.UPDATE_FORCE_CHANGE, text: TC.UPDATE_VALUE_UNFRESH }).then(confirmed => {
                        if (confirmed) S.editBuildingField({ ...api_params, force_update: true }).then(({ data }) => {
                            if (data === "changed") reject("Error");
                            else resolve("updated");
                        }).catch(reject);
                        else resolve("non_updated");
                    });
                    else resolve("updated");
                }).catch(reject);
            });

            updatePromise.then(state => {
                if (state === "updated") update(p => ({ ...p, building: { ...p.building, [prop]: value } }));
            }).catch(M.Alerts.updateError);
        },
        change_picture: (files: T.File[]) => {
            let file = files[0];
            // No file somehow, or file was removed
            if (!file || file.storage === "removed") events.update_building("picture", []);
            // New file uploaded
            else if (file.storage === "temp") {
                // Move the file out of temporary storage
                S.moveTempFile({ name: file.name, id: props.data?.building?._id }).then(() => {
                    let url = URL.CRAFT_FILE_URL(props.data?.building?._id, file.name);
                    events.update_building("picture", [{ ...file, storage: "local", url }]);
                }).catch(M.Alerts.failedFileUpload);
            }
        },
        change_question: (prop: keyof AlignmentProps["data"]["taxonomy"]["questions"], value?: boolean, sub_props?: T.AllowArray<keyof AlignmentProps["data"]["taxonomy"]["questions"]>) => {
            let new_taxonomy = { ...props.data?.taxonomy, questions: { ...props.data?.taxonomy?.questions, [prop]: value } };
            if (sub_props && value === false) TB.arrayWrapper(sub_props).forEach(sub_prop => new_taxonomy.questions[sub_prop] = false);
            // Update in the database
            S.saveMissionTaxonomy(new_taxonomy)
                .then(() => update(p => ({ ...p, taxonomy: new_taxonomy })))
                .catch(M.Alerts.updateError);
        },
        change_proof: (prop: keyof AlignmentProps["data"]["proofs"], proof: Parameters<ProofProps["on_proof"]>[0]) => {
            // The current proof was deleted, reload the data to find the one to use
            if (proof === "deleted") reload();
            else {
                // Check that the created new proof is not older than the former one
                let can_update_proof = false;
                let former_proof_id = props.data.proofs[prop];
                let former_proof = props.data.infos.proofs.find(p => p._id === former_proof_id);

                // Found a former proof, to compare the date of the two
                if (former_proof) {
                    // Compare the dates of the proofs
                    let new_date = new Date(proof.date);
                    let former_date = new Date(former_proof.date);

                    // The new proof is older than the former one
                    if (new_date.getTime() <= former_date.getTime()) M.renderAlert({ title: TC.TAXO_MISSION_PROOF_DATE_ERROR, message: TC.TAXO_MISSION_PROOF_DATE_ERROR_TEXT });
                    else can_update_proof = true;
                }
                else can_update_proof = true;

                // The new proof is newer than the former one, Replace the reg doc in the props
                if (can_update_proof) update(p => ({
                    ...p,
                    proofs: { ...p.proofs, [prop]: proof._id },
                    infos: {
                        ...p.infos,
                        proofs: p.infos.proofs
                            .filter(p => p._id !== former_proof_id)
                            .concat(proof),
                    }
                }));
            }
        },
    }), [props.data?.infos?.proofs, props.data?.building, props.data?.taxonomy, props.data?.site, props.data.proofs, update, reload]);

    const show = React.useMemo(() => {
        let build_year = TB.getNumber(props.data?.building?.ENT_TECH_BUILD_YEAR);
        let is_wallonia = props.data?.site?.region === props.data?.infos?.wallonia_region_id;

        let power_class_name = "fw-bold ";
        let current_power = props.data?.infos?.total_nominal_power || 0;
        if (current_power < 290) power_class_name += "text-success";
        else power_class_name += "text-danger";
        let power_text = lg.getStaticText(TC.TAXO_MISSION_POWER_SUM, current_power);

        return {
            non_epc: !isNaN(build_year) && build_year >= 2021,
            epc: !isNaN(build_year) && build_year < 2021 && !is_wallonia && props.data.infos.reg_actions.peb,
            power: <C.Flex children={power_text} className={power_class_name} alignItems="center" justifyContent="end" />,
        }
    }, [props.data, lg]);

    const proofs = React.useMemo<Partial<Record<keyof AlignmentProps["data"]["proofs"], AlignmentProps["data"]["infos"]["proofs"][number]>>>(() => {
        let proof_dic = props.data?.proofs || {};
        let proof_lists = props.data?.infos?.proofs || [];
        return {
            peb: proof_lists.find(p => p._id === proof_dic.peb),
            heat_power: proof_lists.find(p => p._id === proof_dic.heat_power),
            ped_threshold: proof_lists.find(p => p._id === proof_dic.ped_threshold),
            crva_available: proof_lists.find(p => p._id === proof_dic.crva_available),
            min_safeguards: proof_lists.find(p => p._id === proof_dic.min_safeguards),
            energy_monitoring: proof_lists.find(p => p._id === proof_dic.energy_monitoring),
            adaptation_solutions: proof_lists.find(p => p._id === proof_dic.adaptation_solutions),
        }
    }, [props.data?.infos?.proofs, props.data?.proofs]);
    //#endregion

    return <C.Spinner loading={!props.data}>
        <div>
            <C.Title level={4} className="mb-3" text={TC.TAB_GENERAL} />

            <C.Form.Select
                required
                no_clear_btn
                options={countries}
                labelPosition="left"
                disabled={props.read_only}
                value={props.data?.site?.country}
                loading={country_status === "load"}
                label={{ _id: forms[FP.SITE_FORM], prop: "country" }}
                onChange={value => events.update_site("country", value)}
            />

            <C.Form.Select
                required
                no_clear_btn
                options={regions}
                labelPosition="left"
                disabled={props.read_only}
                value={props.data?.site?.region}
                loading={region_status === "load"}
                label={{ _id: forms[FP.SITE_FORM], prop: "region" }}
                onChange={value => events.update_site("region", value)}
            />

            <C.Form.FileUploader
                image
                hide_info
                confirm_remove
                direction="row"
                labelPosition="left"
                disabled={props.read_only}
                onChange={events.change_picture}
                value={props.data?.building?.picture}
                label={{ _id: forms[FP.BUILDING_FORM], prop: "picture" }}
            />

        </div>

        <div>
            <C.Title level={4} className="mb-3" text={TC.TAXO_MISSION_CLIMATE_MITIGATION} />

            <C.Form.NumField
                required
                labelPosition="left"
                disabled={props.read_only}
                value={props.data?.building?.ENT_TECH_BUILD_YEAR}
                label={{ _id: forms[FP.BUILDING_FORM], prop: "ENT_TECH_BUILD_YEAR" }}
                onChange={value => events.update_building("ENT_TECH_BUILD_YEAR", value)}
            />

            {show.epc && <Proof
                proof={proofs.peb}
                label={TC.TAXO_MISSION_EPC}
                asset_id={props.data.asset_id}
                action_id={props.data.infos.reg_actions.peb}
                on_proof={doc => events.change_proof("peb", doc)}
            />}

            {show.non_epc && <>
                <C.Form.RadioBool
                    required
                    noBottomMargin
                    always_selected
                    name="ped_threshold"
                    labelPosition="left"
                    disabled={props.read_only}
                    tooltip={TC.TAXO_MISSION_NZEB_TIP}
                    label={TC.TAXO_MISSION_PED_THRESHOLD}
                    value={props.data?.taxonomy?.questions?.ped_threshold}
                    onChange={value => events.change_question("ped_threshold", value, "building_criteria")}
                />
                {props.data.taxonomy?.questions?.ped_threshold && <PointHeritage>
                    <Proof
                        proof={proofs.ped_threshold}
                        asset_id={props.data.asset_id}
                        action_id={props.data.infos.reg_actions.ped_threshold}
                        on_proof={doc => events.change_proof("ped_threshold", doc)}
                    />
                    <C.Form.RadioBool
                        required
                        noBottomMargin
                        always_selected
                        labelPosition="left"
                        name="building_criteria"
                        disabled={props.read_only}
                        tooltip={TC.TAXO_MISSION_NZEB_NEW}
                        label={TC.TAXO_MISSION_BUILDING_CRITERIA}
                        value={props.data?.taxonomy?.questions?.building_criteria}
                        onChange={value => events.change_question("building_criteria", value)}
                    />
                </PointHeritage>}
            </>}

            <C.Form.RadioBool
                required
                noBottomMargin
                always_selected
                name="heat_power"
                labelPosition="left"
                disabled={props.read_only}
                label={TC.TAXO_MISSION_HEAT_POWER}
                tooltip={TC.TAXO_MISSION_HEAT_POWER_TIP}
                value={props.data?.taxonomy?.questions?.heat_power}
                onChange={value => events.change_question("heat_power", value, "energy_monitoring")}
            />
            {show.power}

            <PointHeritage>
                {!props.data.taxonomy?.questions?.heat_power
                    ? <>
                        <C.Form.RadioBool
                            required
                            noBottomMargin
                            always_selected
                            labelPosition="left"
                            name="energy_monitoring"
                            disabled={props.read_only}
                            label={TC.TAXO_MISSION_ENERGY_MONITORING}
                            value={props.data?.taxonomy?.questions?.energy_monitoring}
                            onChange={value => events.change_question("energy_monitoring", value)}
                        />

                        {props.data.taxonomy?.questions?.energy_monitoring && <PointHeritage
                            children={<Proof
                                asset_id={props.data.asset_id}
                                proof={proofs.energy_monitoring}
                                action_id={props.data.infos.reg_actions.energy_monitoring}
                                on_proof={doc => events.change_proof("energy_monitoring", doc)}
                            />}
                        />}
                    </>

                    : <Proof
                        proof={proofs.heat_power}
                        asset_id={props.data.asset_id}
                        action_id={props.data.infos.reg_actions.heat_power}
                        on_proof={doc => events.change_proof("heat_power", doc)}
                    />}
            </PointHeritage>

            <C.Form.RadioBool
                required
                noBottomMargin
                name="no_fuels"
                always_selected
                labelPosition="left"
                disabled={props.read_only}
                label={TC.TAXO_MISSION_NO_FUEL}
                tooltip={TC.TAXO_MISSION_FOSSIL_FUEL_TIP}
                value={props.data?.taxonomy?.questions?.no_fuels}
                onChange={value => events.change_question("no_fuels", value)}
            />
        </div>

        <div>
            <C.Title level={4} className="mb-3" text={TC.TAXO_MISSION_CLIMATE_ADAPTATION_TITLE} />

            <C.Form.RadioBool
                required
                noBottomMargin
                always_selected
                labelPosition="left"
                name="crva_available"
                disabled={props.read_only}
                label={TC.TAXO_MISSION_CRVA_AVAILABLE}
                value={props.data?.taxonomy?.questions?.crva_available}
                onChange={value => events.change_question("crva_available", value, ["crva_best_practice", "adaptation_solutions", "adaptation_min_req"])}
            />
            {props.data.taxonomy?.questions?.crva_available && <PointHeritage>
                <Proof
                    proof={proofs.crva_available}
                    asset_id={props.data.asset_id}
                    action_id={props.data.infos.reg_actions.crva_available}
                    on_proof={doc => events.change_proof("crva_available", doc)}
                />
                <C.Form.RadioBool
                    required
                    noBottomMargin
                    always_selected
                    labelPosition="left"
                    name="crva_best_practice"
                    disabled={props.read_only}
                    label={TC.TAXO_MISSION_CRVA_BEST_PRACTICE}
                    tooltip={TC.TAXO_MISSION_CRVA_BEST_PRACTICE_TIP}
                    value={props.data?.taxonomy?.questions?.crva_best_practice}
                    onChange={value => events.change_question("crva_best_practice", value, ["adaptation_solutions", "adaptation_min_req"])}
                />
                {props.data.taxonomy?.questions?.crva_best_practice && <PointHeritage>
                    <C.Form.RadioBool
                        required
                        noBottomMargin
                        always_selected
                        labelPosition="left"
                        disabled={props.read_only}
                        name="adaptation_solutions"
                        label={TC.TAXO_MISSION_ADAPTATION_SOLUTIONS}
                        value={props.data?.taxonomy?.questions?.adaptation_solutions}
                        onChange={value => events.change_question("adaptation_solutions", value, "adaptation_min_req")}
                    />
                    {props.data.taxonomy?.questions?.adaptation_solutions && <PointHeritage>
                        <Proof
                            asset_id={props.data.asset_id}
                            proof={proofs.adaptation_solutions}
                            action_id={props.data.infos.reg_actions.adaptation_solutions}
                            on_proof={doc => events.change_proof("adaptation_solutions", doc)}
                        />
                        <C.Form.RadioBool
                            required
                            noBottomMargin
                            always_selected
                            labelPosition="left"
                            name="adaptation_min_req"
                            disabled={props.read_only}
                            label={TC.TAXO_MISSION_ADAPTATION_MIN_REQ}
                            tooltip={TC.TAXO_MISSION_ADAPT_SOLUTIONS_MIN_REQ_TIP}
                            value={props.data?.taxonomy?.questions?.adaptation_min_req}
                            onChange={value => events.change_question("adaptation_min_req", value)}
                        />
                    </PointHeritage>}
                </PointHeritage>}
            </PointHeritage>}
        </div>

        <div>
            <C.Title level={4} className="mb-3" text={TC.TAXO_MISSION_MIN_SAFEGUARD_TITLE} />
            <C.Form.RadioBool
                required
                noBottomMargin
                always_selected
                labelPosition="left"
                name="min_safeguards"
                disabled={props.read_only}
                label={TC.TAXO_MISSION_MIN_SAFEGUARD}
                tooltip={TC.TAXO_MISSION_MIN_SAFEGUARD_TIP}
                value={props.data?.taxonomy?.questions?.min_safeguards}
                onChange={value => events.change_question("min_safeguards", value, ["min_safeguards_1", "min_safeguards_2"])}
            />
            {props.data.taxonomy?.questions?.min_safeguards && <PointHeritage>
                <Proof
                    proof={proofs.min_safeguards}
                    asset_id={props.data.asset_id}
                    action_id={props.data.infos.reg_actions.min_safeguards}
                    on_proof={doc => events.change_proof("min_safeguards", doc)}
                />
                <C.Form.RadioBool
                    required
                    noBottomMargin
                    always_selected
                    labelPosition="left"
                    name="min_safeguards_1"
                    disabled={props.read_only}
                    tooltip={TC.TAXO_MISSION_MIN_SG_1_TIP}
                    label={TC.TAXO_MISSION_MIN_SAFEGUARD_1}
                    value={props.data?.taxonomy?.questions?.min_safeguards_1}
                    onChange={value => events.change_question("min_safeguards_1", value)}
                />
                <C.Form.RadioBool
                    required
                    noBottomMargin
                    always_selected
                    labelPosition="left"
                    name="min_safeguards_2"
                    disabled={props.read_only}
                    tooltip={TC.TAXO_MISSION_MIN_SG_2_TIP}
                    label={TC.TAXO_MISSION_MIN_SAFEGUARD_2}
                    value={props.data?.taxonomy?.questions?.min_safeguards_2}
                    onChange={value => events.change_question("min_safeguards_2", value)}
                />
            </PointHeritage>}
        </div>
    </C.Spinner>;
};

export default Alignment;

const PointHeritage: React.FC<PointHeritageProps> = props => <C.Flex direction="row">
    <div style={{ borderStyle: "dashed" }} className="me-2 border-end border-2" />
    <div className="flex-grow-1" children={props.children} />
</C.Flex>;

const Proof: React.FC<ProofProps> = ({ on_proof, ...props }) => {
    const lg = H.useLanguage();

    const create_doc = React.useCallback((is_create = false) => {
        let parameters = { forceNewDoc: is_create, elemId: props.asset_id } as Parameters<typeof M.renderRegDoc>[0];
        // Creating a new Document
        if (is_create) parameters.action = props.action_id;
        // Editing the current document
        else parameters.regFormId = props.proof?._id;

        M.renderRegDoc(parameters).then(result => {
            if (result) {
                // If the document was deleted, just update the state
                if (result.action === "deleted") on_proof("deleted");
                // If the document was created / updated, update the state with the new document
                else if (result.doc) S.getRegActionDetails(props.action_id)
                    .then(action => on_proof({ _id: result.doc._id, name: action.data[0]?.name || "", date: result.doc.last_control }))
                    .catch(() => on_proof({ _id: result.doc._id, name: "", date: result.doc.last_control }));
            }
        });
    }, [on_proof, props.proof, props.action_id, props.asset_id]);

    const proof_name = React.useMemo(() => {
        if (!props.proof) return "";
        let reg_name = lg.getTextObj(props.proof._id, "name", props.proof.name);
        let date = moment(props.proof.date).format("DD/MM/YYYY");
        return `${reg_name} (${date})`;
    }, [props.proof, lg]);

    return <C.Flex className="mb-2" justifyContent="between">
        <div>{lg.getStaticText(props.label || TC.TAXO_MISSION_PROOF)} <span className="ms-1 text-danger" children="*" /></div>
        {props.proof
            ? <C.Flex className="flex-grow-1" alignItems="center" justifyContent="end">
                <C.Form.TextField readonly hideLabel noBottomMargin customClass="w-50" value={proof_name} />
                <BS.ButtonGroup className="h-100">
                    <C.Button size="sm" variant="primary" icon="plus" onClick={() => create_doc(true)} />
                    <C.Button size="sm" variant="info" icon="pencil-alt" onClick={() => create_doc(false)} />
                </BS.ButtonGroup>
            </C.Flex>
            : <C.Button
                size="sm"
                icon="plus"
                className="px-5 py-1"
                variant="outline-secondary"
                onClick={() => create_doc(true)}
                text={TC.TAXO_MISSION_ADD_DOCUMENT}
            />}
    </C.Flex>;
};