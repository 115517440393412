import * as TC from "../textCode";
import moment from "moment-timezone"
import { getNumber } from "./number";
import { validString } from "./string";
import { validObject } from "./objects";
import { T } from "../../../../Constants";

type MomentTimeUnit = "y" | "d" | "w" | "m" | "M" | "h";
type SplitFrequencyType = (frequency: string) => { num: number, unit: MomentTimeUnit } | null;

type TimeConfig = {
    to?: string;
    from?: string;
    interval?: string;
}

export const Frequency_Units: T.Option<{}, MomentTimeUnit>[] = [
    { value: "M", label: TC.GP_GROUP_MONTH },
    { value: "d", label: TC.GP_GROUP_DAY },
    { value: "h", label: TC.GP_GROUP_HOUR },
    { value: "m", label: TC.GP_GROUP_SINGLE_MINUTE },
    { value: "w", label: TC.GP_GROUP_WEEK },
    { value: "y", label: TC.GP_GROUP_YEAR },
];

export const isDateObj = (date: unknown): date is Date => validObject(date) && date instanceof Date;
export const isDateString = (date: unknown): date is string => validString(date) && !isNaN(Date.parse(date));
export const isTimeUnit = (str: any): str is MomentTimeUnit => validString(str) && ["y", "d", "w", "m", "M", "h"].includes(str.toLowerCase());

/**
 * Cast a value into a Date object, if possible
 */
export const getDate = (value: any) => {
    const isNaNCheck = (date: Date | string) => isNaN(Date.parse(validString(date) ? date : date.toString()));

    if (validObject(value) && value instanceof Date && !isNaNCheck(value)) return value;
    else if (typeof value === "number" && value > 0 && !isNaNCheck(new Date(value))) {
        // Most likely just a year
        if (value < 9999) return new Date("01/01/" + value);
        // Possibly unix time
        return new Date(value);
    }
    else if (validString(value)) {
        let parsedValue = parseInt(value);
        if (!isNaNCheck(value)) return new Date(Date.parse(value));
        else if (parsedValue > 0 && !isNaNCheck(new Date(parsedValue))) return new Date(parsedValue);
    }
    return null;
}

/**
 * Format a date to a more readable format
 */
export const formatDate = (value: Date | string | any, format = "DD/MM/YYYY", useTodayDefault?: boolean) => {
    let dateObj = getDate(value);
    if (dateObj === null) {
        if (useTodayDefault) dateObj = new Date();
        else return "";
    }
    return moment(dateObj).format(format);
}

/**
 * Split a frequency in a pair unit / amount
 */
export const splitFrequency: SplitFrequencyType = (frequency) => {
    if (!validString(frequency) || frequency === "SN") return null;

    /* Must change Frequencies in Db to avoir all those if */
    if (frequency === "J") return { num: 1, unit: "d" };
    else if (frequency === "H") return { num: 1, unit: "w" };
    else if (frequency === "2H") return { num: 2, unit: "w" };
    else if (frequency === "M") return { num: 1, unit: "M" };
    else if (frequency === "2M") return { num: 2, unit: "M" };
    else if (frequency === "T") return { num: 3, unit: "M" };
    else if (frequency === "S") return { num: 6, unit: "M" };
    else if (frequency === "A") return { num: 1, unit: "y" };
    else if (frequency === "2A") return { num: 2, unit: "y" };
    else if (frequency === "3A") return { num: 3, unit: "y" };
    else if (frequency === "4A") return { num: 4, unit: "y" };
    else if (frequency === "5A") return { num: 5, unit: "y" };
    else if (frequency === "8A") return { num: 8, unit: "y" };
    else if (frequency === "10A") return { num: 10, unit: "y" };
    else if (frequency === "15A") return { num: 15, unit: "y" };
    else {
        let num = frequency.match(/\d+/g);
        let unit = frequency.match(/[a-zA-Z]/g);

        if (num === null || unit === null) return null;
        let parsedNum = getNumber(num[0]);
        let goodUnit = unit[0];
        if (goodUnit !== "M") goodUnit = goodUnit?.toLowerCase?.();
        if (isNaN(parsedNum) || parsedNum <= 0) return null;
        if (!isTimeUnit(goodUnit)) return null;
        return { num: parsedNum === 0 ? 1 : parsedNum, unit: goodUnit };
    }
}

/**
 * Find the correct text to translate a frequency into text
 */
export const translate_frequency = (frequency: string) => {
    let split = splitFrequency(frequency);
    if (split === null) return { amount: null, ref: null };
    let unit_code = Frequency_Units.filter(u => u.value === split.unit)[0]?.label;
    if (!unit_code) return { amount: null, ref: null };
    return { amount: split.num, ref: unit_code };
}

/**
 * Transform an SQL-like interval into a number of time unit
 */
export const getInterval = (interval?: string) => {
    if (!validString(interval)) return {
        number: 0,
        time: "seconds" as Parameters<moment.Moment["subtract"]>[0],
    };
    let [number, time] = interval.split(/\s/g);
    return {
        number: parseInt(number),
        time: time.toLowerCase() + "s" as Parameters<moment.Moment["subtract"]>[0],
    };
};

/**
 * Gets a date from and a date to from an interval of a pair of dates in ISO format
 */
export const getFromTo = (params: TimeConfig) => {
    let interval = getInterval(params?.interval);
    let [from, to] = [params?.from, params?.to].map(getDate);

    if (from && to) return { from: from.toISOString(), to: to.toISOString() };
    else if (interval !== null) return {
        to: new Date().toISOString(),
        from: moment().subtract(interval.number, interval.time).toISOString(),
    }
    // Default = 2 Weeks
    return { from: moment().subtract(2, "w").toISOString(), to: new Date().toISOString() };
};

/**
 * Gets a date from and a date to from an interval of a pair of dates in unix format
 */
export const getFromToUnix = (params: TimeConfig) => {
    let interval = getInterval(params?.interval);
    let [from, to] = [params?.from, params?.to].map(getDate);

    if (from && to) return { from: from.getTime(), to: to.getTime() };
    else if (interval !== null) return {
        to: new Date().getTime(),
        from: moment().subtract(interval.number, interval.time).unix() * 1000,
    }
    // Default = 2 Weeks
    return { from: moment().subtract(2, "w").unix() * 1000, to: new Date().getTime() };
};

/**
 * Parse a date from a provided format, and also timezone if provided
 */
export const parseDate = (date: string, format: string, timezone?: string, timezone_data?: string) => {
    // Check that a valid string is provided for the "date" and it's format
    if (!validString(date) || !validString(format)) return null;
    // Dealing with timezones
    if (timezone && timezone_data) {
        moment.tz.add(timezone_data);
        // Parse the date string based on the format template, it will be considered in the current timezone
        let moment_date = moment.tz(date, format, timezone)?.utc?.();
        // Could not create a proper date
        if (!moment_date?.isValid()) return null;
        else return moment_date;
    }
    // Not dealing with timezones
    else {
        // Parse the date string based on the format template, it will be considered in the current timezone
        let moment_date = moment(date, format);
        // Could not create a proper date
        if (!moment_date?.isValid()) return null;
        else return moment_date;
    }
};