import * as T from "../Types";
import * as TC from "../textCode";

type Row = ReturnType<T.API.Utils.Tables.GetCapexRows>[number];

export const CAPEX = {
    time_range: {
        under_2: TC.KPI_API_END_LIFE_NOW,
        over_10: TC.KPI_API_END_LIFE_OVER_TEN,
        under_10: TC.KPI_API_END_LIFE_FIVE_TEN,
        under_5: TC.KPI_API_END_LIFE_UNDER_FIVE,
        unknown: TC.KPI_API_END_LIFE_MISSING_DATA,
    } as Record<Row["time_range"], string>,
    context_origin: {
        from_rem: TC.CAPEX_ORIGIN_FROM_REM,
        from_reg: TC.CAPEX_ORIGIN_FROM_REG,
        auto_reg: TC.CAPEX_ORIGIN_AUTO_REG,
        auto_maintenance: TC.CAPEX_ORIGIN_AUTO_MAINTENANCE,
        suggestion_lifespan_th: TC.CAPEX_ORIGIN_END_LIFE_TH,
        suggestion_lifespan_audit: TC.CAPEX_ORIGIN_END_LIFE_ESTIMATED,
    } as Record<Row["context_origin"], string>,
}