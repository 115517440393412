import { TB } from "../../../Constants";

//#region Pre-defined values
export const ignoreKeys = keys => Array.isArray(keys) ? keys.map(key => ({ key, ignore: true })) : [];

//#region Default + Calculated
const getVariableTable = additional => ({
    type: 'htmlelement',
    tag: 'div',
    /* eslint-disable prefer-template */
    content: `<p>The following variables are available in all scripts.</p>
        <table class="table table-bordered table-condensed table-hover">
            ${TB.validString(additional) ? additional : ""}
            <tr>
                <th>data</th>
                <td>The complete submission data object.</td>
            </tr>
            <tr>
                <th>row</th>
                <td>Contextual "row" data, used within DataGrid, EditGrid, and Container components</td>
            </tr>
            <tr>
                <th>value</th>
                <td>The current value of the component. Only avaible in read in validation.</td>
            </tr>
            <tr>
                <th>submissionId</th>
                <td>The _id of the current submission, if it exists</td>
            </tr>
            <tr>
                <th>moment</th>
                <td>The moment.js library for date manipulation.</td>
            </tr>
            <tr>
                <th>dbAccess</th>
                <td>A function that will asynchronously execute a query, the params are : isSubmission(bool), isMany(bool) and filter(Object)</td>
            </tr>
            <tr>
                <th>getFormId</th>
                <td>A function that will asynchronously fetch a formId based on the given form path</td>
            </tr>
            <tr>
                <th>log</th>
                <td>A function that will log values, after the execution of the script</td>
            </tr>
            <tr>
                <th>_</th>
                <td>An instance of <a href="https://lodash.com/docs/" target="_blank">Lodash</a>.</td>
            </tr>
        </table>
        <br/>`
    /* eslint-enable prefer-template */
});

const recalculateOnComp = {
    type: 'select',
    input: true,
    multiple: true,
    key: 'recalculateOn',
    label: 'Recalculate On',
    weight: 600,
    validate: { required: true },
    tooltip: 'Recalculate the value only when one of these values change. Used to avoid unnecessary recalculation.',
    dataSrc: 'custom',
    valueProperty: 'value',
    data: {
        custom: context => {
            var values = [];
            values.push({ label: 'Any Change', value: 'data' });
            context.utils.eachComponent(context.instance.options.editForm.components, (component, path) => {
                if (component.key !== context.data.key) values.push({ label: `${component.label || component.key} (${component.key})`, value: path });
            });
            return values;
        }
    },
    customConditional: ({ data }) => TB.validString(data.calculateValue),
};

export const JS_VALUE_SETTINGS = (title, property, weight, exampleHTML, additionalParams, recalculateOn = false) => [
    {
        title,
        type: 'panel',
        theme: 'default',
        collapsible: true,
        collapsed: true,
        key: `${property}_Panel`,
        weight,
        components: [
            getVariableTable(additionalParams),
            {
                type: 'panel',
                title: 'JavaScript',
                key: `${property}-js`,
                components: [
                    { type: 'textarea', key: property, rows: 5, editor: 'ace', hideLabel: true, as: 'javascript', input: true },
                    { type: 'htmlelement', tag: 'div', content: "<p>Enter custom javascript code.</p>".concat(exampleHTML) }
                ]
                    .concat(recalculateOn === true ? recalculateOnComp : null)
                    .filter(TB.validObject)
            },
        ]
    },
    { key: `${property}Panel`, ignore: true },
];

export const JS_VALIDATION_SETTINGS = [
    {
        key: "new-custom-validation-js",
        weight: 300,
        components: [
            getVariableTable("<p>You must assign the <strong>valid</strong> variable as either <strong>true</strong> or an error message if validation fails.</p>"),
            { type: 'textarea', key: 'validate.custom', rows: 5, editor: 'ace', hideLabel: true, as: 'javascript', input: true },
        ]
    },
    { key: "custom-validation-js", ignore: true },
    { key: "json-validation-json", ignore: true },
];
//#endregion

//Conditional
const condExample = `
    <p>You must assign the <strong>show</strong> variable a boolean result.</p>
    <h5>Example</h5><pre>show = !data.showMe;</pre>
`;
export const conditionalOverride = { key: "conditional", components: [...JS_VALUE_SETTINGS("Advanced Conditions", "customConditional", 1100, condExample)] };

//Calculate Value + custom default value
export const customValuesPanels = [
    ...JS_VALUE_SETTINGS('Custom Default Value', 'customDefaultValue', 1000, '<p><h4>Example:</h4><pre>value = data.firstName + " " + data.lastName;</pre></p>'),
    ...JS_VALUE_SETTINGS('Calculated Value', 'calculateValue', 1050, '<p><h4>Example:</h4><pre>value = data.a + data.b + data.c;</pre></p>', undefined, true),
];

export const customValuesPanelsExtraVar = extraVar => [
    ...JS_VALUE_SETTINGS('Custom Default Value', 'customDefaultValue', 1000, '<p><h4>Example:</h4><pre>value = data.firstName + " " + data.lastName;</pre></p>', extraVar),
    ...JS_VALUE_SETTINGS('Calculated Value', 'calculateValue', 1050, '<p><h4>Example:</h4><pre>value = data.a + data.b + data.c;</pre></p>', extraVar, true),
]
//#endregion