import React from "react";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import { T, TB, TC, FP, DS } from "../../../Constants";
// import { TIME_GROUPS } from "../../Dashboard/EnergyDashboard";
import { StyleModalProps, default as Modal } from "../BlankModal";

type ImportValue = Omit<Parameters<T.API.Utils.Energy.EntityDataCsv>[0], "entities">;

export type DataExportProps = {
    /** Callback for cancellation */
    quit?: () => void;
    /** Callback for confirmation */
    validate?: (values: ImportValue) => void;
    /** Extra params to change the modal */
    modal?: StyleModalProps;
    /** Default value */
    value?: ImportValue;
}

const formatting_props = [
    { value: "site", label: FP.SITE_FORM },
    { value: "building", label: FP.BUILDING_FORM },
    { value: "emplacement", label: FP.EMPLACEMENT_FORM },
    { value: "local", label: TC.GLOBAL_LOCAL },
    { value: "element", label: TC.DATASET_ELEMENT },
    { value: "renter", label: TC.GLOBAL_LABEL_RENTER },
    { value: "name", label: TC.DATASET_NAME },
    { value: "unit", label: TC.DATASET_UNIT },
    { value: "type", label: TC.DATASET_TYPE },
] as T.Option<{}, keyof T.API.Utils.Datasets.DatasetTableItem>[];

const DataExport: React.FC<DataExportProps> = ({ validate, ...props }) => {
    const lg = H.useLanguage();
    const [errors, set_errors] = React.useState<T.Errors<ImportValue>>({});
    const [values, set_values] = React.useState<ImportValue>(props.value || {
        formatting: "",
        group: "minute",
        to: new Date().toISOString(),
        from: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 14).toISOString(),
    });

    const try_validate = React.useCallback(() => {
        let to = TB.getDate(values.to);
        let from = TB.getDate(values.from);
        let new_errors = {} as typeof errors;

        if (!to) new_errors.to = TC.GLOBAL_REQUIRED_FIELD;
        if (!from) new_errors.from = TC.GLOBAL_REQUIRED_FIELD;
        if (!values.group) new_errors.group = TC.GLOBAL_REQUIRED_FIELD;
        if (from && to && from.getTime() >= to.getTime()) {
            new_errors.to = TC.ERR_DATE_TO_LOWER_DATE_FROM;
            new_errors.from = TC.ERR_DATE_FROM_HIGHER_DATE_TO;
        }

        if (Object.keys(new_errors).length > 0) set_errors(new_errors);
        else validate?.(values);
    }, [values, validate]);

    const footer = React.useMemo(() => <C.Flex alignItems="end">
        <C.Button onClick={props.quit} className="mx-2" variant="danger" text={TC.GLOBAL_CANCEL} />
        <C.Button onClick={try_validate} text={TC.GLOBAL_CONFIRM} />
    </C.Flex>, [try_validate, props.quit]);

    const formatting_description = React.useMemo(() => <div className="text-muted fs-85">
        <div>{lg.getStaticText(TC.DATA_EXPORT_FORMATTING_HELP)}</div>
        <div>Ex: {"{{site}} - {{name}}"}</div>
        <ul children={formatting_props.map(p => <li key={p.value} children={<>{p.value} - {lg.getStaticText(p.label)}</>} />)} />
    </div>, [lg]);

    return <Modal
        {...props.modal}
        footer={footer}
        onQuit={props.quit}
        size={props.modal?.size || "sm"}
        title={props.modal?.title || TC.DATA_EXPORT_TITLE}
    >
        <C.Form.DateTime
            required
            error={errors.from}
            value={values.from}
            label={TC.GLOBAL_FROM}
            onChange={from => set_values(p => ({ ...p, from }))}
        />

        <C.Form.DateTime
            required
            error={errors.to}
            value={values.to}
            label={TC.GLOBAL_TO}
            onChange={to => set_values(p => ({ ...p, to }))}
        />

        <C.Form.Select
            required
            no_clear_btn
            error={errors.group}
            value={values.group}
            options={DS.TIME_GROUPS}
            label={TC.GLOBAL_TIME_GROUP}
            onChange={group => set_values(p => ({ ...p, group }))}
        />

        <C.Form.TextField
            rows={2}
            textArea
            autoExpand
            error={errors.formatting}
            value={values.formatting}
            label={TC.DATA_EXPORT_FORMATTING}
            description={formatting_description}
            onChange={formatting => set_values(p => ({ ...p, formatting }))}
        />
    </Modal>;
}

const WrappedDataExport: React.FC<DataExportProps> = props => <C.ReduxWrapper children={<DataExport {...props} />} />;
export default WrappedDataExport;