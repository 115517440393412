import { LANG as Languages } from "./Data";

export const GENERAL_USERNAME = ["Nom d'utilisateur", "Username", "Usernaam", "Nome utente", "Nombre de usuario", "اسم االمستخدم"]

export const SM_LOOK_IN = ["Rechercher dans", "Look in", "todo", "todo", "todo"];
export const SM_ADD_FAV = ["Ajouter un favori", "todo", "todo", "todo", "todo"];
export const SM_DEFAULT_FAV = ["Appliquer ce favori lors du chargement de la page", "todo", "todo", "todo", "todo"];
export const SM_ASK_FAV_NAME = ["Comment voulez-vous appeler ce nouveau favori ?", "todo", "todo", "todo", "todo"];

export const TAB_SITE_FORM = ["Editeur de site", "todo", "todo", "todo", "todo", "todo"];
export const TAB_USER = ["Utilisateur", "User", "todo", "todo", "todo", "todo"];

export const DIA_FOCUS = ["Rechercher", "todo", "todo", "todo", "todo", "todo"];
export const DIA_FILTER_TYPE = ["Filtrage", "todo", "todo", "todo", "todo", "todo"];
export const DIA_JUST_SEARCH = ["Just les éléments recherchés", "todo", "todo", "todo", "todo", "todo"];
export const DIA_SEARCH_PATH = ["La recherche est son arborescence", "todo", "todo", "todo", "todo", "todo"];
export const DIA_APPLY_CHANGES = ["Appliquer les changements", "todo", "todo", "todo", "todo", "todo"];
export const DIA_REINITIALIZE = ["Réinitialiser", "todo", "todo", "todo", "todo", "todo"];

export const UF_BASE_INFO = ["Informations de base", "todo", "todo", "todo", "todo", "todo"];
export const UF_ROLE_TABS = ["Rôles et menus", "todo", "todo", "todo", "todo", "todo"];
export const UF_DEFAULT_NODE = ["Accès", "todo", "todo", "todo", "todo", "todo"];
export const UF_HIERARCHY = ["Supérieur(s)", "todo", "todo", "todo", "todo", "todo"];
export const UF_TECH_PROFILE = ["Profile de technicien", "todo", "todo", "todo", "todo", "todo"];
export const UF_TAB = ["Menus accessible", "todo", "todo", "todo", "todo", "todo"];
export const UF_TAB_EXTRA = ["Donner accès à des menus non accordé à/aux rôles de l'utilisateur", "todo", "todo", "todo", "todo", "todo"];

export const G_ACTION_REG = ["Action réglementaire", "todo", "todo", "todo", "todo", "todo"];

export const ANNOT_DEFAULT_PLAN = ["Plan", "Schematic", "todo", "todo", "todo", "todo"];
export const ANNOT_POINT_SELECTOR = ["Point", "Dot", "todo", "todo", "todo", "todo", "todo"];
export const ANNOT_RECTANGLE_SELECTOR = ["Rectangle", "Rectangle", "todo", "todo", "todo", "todo"];
export const ANNOT_POLYGON_SELECTOR = ["Polygone", "Polygon", "todo", "todo", "todo", "todo"];
export const ANNOT_DRAW_SELECTOR = ["Main levée", "todo", "todo", "todo", "todo", "todo"];
export const ANNOT_FORM_TYPE = ["Forme", "todo", "todo", "todo", "todo", "todo"];
export const ANNOT_POLYGON_ACTIONS = ["Edition de polygone", "todo", "todo", "todo", "todo", "todo"];
export const ANNOT_COMPLETE_POLYGON = ["Tracer", "todo", "todo", "todo", "todo", "todo"];
export const ANNOT_ORIGIN = ["Origine", "Origin", "todo", "todo", "todo", "todo"];
export const ANNOT_ADD_MEASURE = ["Ajouter une mesure", "todo", "todo", "todo", "todo", "todo"];
export const ANNOT_EDIT_MEASURE = ["Modifier une mesure", "todo", "todo", "todo", "todo", "todo"];
export const ANNOT_SUBMIT = ["Ajouter", "Submit", "todo", "todo", "todo", "todo"];
export const ANNOT_NAME = ["Annotations", "todo", "todo", "todo", "todo", "todo"];
export const ANNOT_SAVING = ["Sauvegarde du plan", "todo", "todo", "todo", "todo", "todo"];
export const ANNOT_NO_SAVE = ["Fermer sans sauvegarder", "todo", "todo", "todo", "todo", "todo"];
export const ANNOT_NAME_USED = ["Un autre plan porte déja ce nom", "todo", "todo", "todo", "todo", "todo"];
export const ANNOT_REQUIRED_NAME = ["Veuillez spécifier une valeur.", "todo", "todo", "todo", "todo", "todo"];
export const ANNOT_NO_PLAN = ["Pas de plans", "todo", "todo", "todo", "todo", "todo"];
export const ANNOT_ERROR_LOAD_PLANS = ["Erreur lors du chargement des plans", "todo", "todo", "todo", "todo", "todo"];

export const IMG_NO_IMAGES = ["Aucunes images à afficher", "todo", "todo", "todo", "todo", "todo"];
export const IMG_FAIL_LOAD = ["Erreur lors du chargement de l'image", "todo", "todo", "todo", "todo", "todo"];

export const FORM_DELETE_CONFIRM = ["Ceci supprimera définitivement cet équipement. \r\nÊtes-vous sûr de vouloir continuer ?", "todo", "todo", "todo", "todo", "todo"];
export const FORM_SUGGESTED_GAMME_EQUIP = ["Gamme suggérée", "todo", "todo", "todo", "todo", "todo"];
export const FORM_NOT_ALL_FIELDS_CORRECT = ["Tout les champs ne sont pas correct", "todo", "todo", "todo", "todo", "todo"];

export const GLOBAL_NAME = ["Nom", "Name", "Naam", "todo", "todo", "todo"];
export const GLOBAL_COLOR = ["Couleur", "Color", "todo", "todo", "todo", "todo"];
export const GLOBAL_SAVE_BEFORE_QUIT = ["Sauvegarder avant de quitter ?", "todo", "todo", "todo", "todo", "todo"];
export const GLOBAL_QUIT = ["Quitter", "todo", "todo", "todo", "todo", "todo"];
export const GLOBAL_NEW = ["Nouveau", "todo", "todo", "todo", "todo", "todo"];
export const GLOBAL_DOWNLOAD = ["Télécharger", "todo", "todo", "todo", "todo", "todo"];
export const GLOBAL_RENAME = ["Renommer", "todo", "todo", "todo", "todo", "todo"];
export const GLOBAL_SELECT = ["Selection", "todo", "todo", "todo", "todo", "todo"];
export const GLOBAL_NO_OPTIONS_SELECT = ["Pas d'options trouvées", "todo", "todo", "todo", "todo", "todo"];
export const GLOBAL_NO_SELECTION = ["Pas de sélection", "todo", "todo", "todo", "todo", "todo"];
export const GLOBAL_SHOW = ["Afficher", "todo", "todo", "todo", "todo", "todo"];
export const GLOBAL_ERROR_OCCURRED = ["Une erreur est survenue", "todo", "todo", "todo", "todo", "todo"];
export const GLOBAL_HIDE = ["Cacher", "todo", "todo", "todo", "todo", "todo"];
export const GLOBAL_ERROR_UPDATE = ["Une erreur est survenue lors de la sauvegarde", "todo", "todo", "todo", "todo", "todo"];
export const GLOBAL_ERROR_DELETE = ["Une erreur est survenue lors de la suppression.", "todo", "todo", "todo", "todo", "todo"];
export const GLOBAL_SUBMIT = ["Soumettre", "Submit", "todo", "todo", "todo", "todo"];
export const GLOBAL_LABEL_PARCEL = ["Parcelles cadastrales", "todo", "todo", "todo", "todo", "todo"];
export const GLOBAL_LABEL_PARKING = ["Parkings", "todo", "todo", "todo", "todo", "todo"];
export const GLOBAL_LABEL_RENTER = ["Locataires", "todo", "todo", "todo", "todo", "todo"];
export const GLOBAL_LABEL_CELLS = ["Cellules", "todo", "todo", "todo", "todo", "todo"];
export const GLOBAL_LABEL_ENSEIGNE = ["Enseigne", "todo", "todo", "todo", "todo", "todo"];
export const GLOBAL_LABEL_BAIL = ["Bail", "todo", "todo", "todo", "todo", "todo"];
export const GLOBAL_TITLE_TIME_PICKER = ["Calendrier", "todo", "todo", "todo", "todo", "todo"];
export const GLOBAL_UPLOAD_PLAN = ["Importer un plan", "todo", "todo", "todo", "todo"];
export const GLOBAL_NAME_PLAN = ["Nom du plan", "todo", "todo", "todo", "todo"];

// LT === Light Tree
export const LT_SELECT = ["Choisir", "todo", "todo", "todo", "todo", "todo"];
export const LT_LIGHT_TREE_TITLE = ["Arborescence", "todo", "todo", "todo", "todo", "todo"];
export const LT_INVALID_LINK_FORMAT = ["Pas de lien possible pour cette selection", "todo", "todo", "todo", "todo", "todo"];
export const LT_INVALID_ROOT = ["La racine fournié n'est pas correct", "todo", "todo", "todo", "todo", "todo"];
export const LT_NO_NODES = ["Aucun noeud", "todo", "todo", "todo", "todo", "todo"];

// SF === SiteForm
export const SF_NO_PARKINGS = ["Pas de parking", "todo", "todo", "todo", "todo", "todo"];
export const SF_PARKING_LIGHT_LABEL = ["Lampes de parking", "todo", "todo", "todo", "todo", "todo"];

// EM === Excel Modal
export const EM_TITLE = ["Import d'un fichier", "todo", "todo", "todo", "todo", "todo"];

export const CFQ_WORD_GENERATION = ["Génération du fichier Word", "todo", "todo", "todo", "todo", "todo"];
export const CFQ_NO_AFFECTATION_BENCHMARK = ["Aucune affectation trouvée", "todo", "todo", "todo", "todo", "todo"];
export const CFQ_AFFECTATION = ["Affectation", "todo", "todo", "todo", "todo", "todo"];

export const VD_DEFAULT_TITLE = ["Valeurs par défaut", "todo", "todo", "todo", "todo", "todo"];
export const VD_REMARQUES_TITLES = ["Remarque pour", "todo", "todo", "todo", "todo", "todo"];
export const VD_REMARQUES_LABEL = ["Remarque", "todo", "todo", "todo", "todo", "todo"];

export const NOTIF_SAVE_SUCCESS = ["Sauvegarde réussie", "todo", "todo", "todo", "todo", "todo"];

// FE === Formula Editor
export const FE_NO_FORMULA_NAME = ["Toute les formules n'ont pas de noms", "todo", "todo", "todo", "todo", "todo"];
export const FE_NO_TAG_AUTOMATIC = ["Certains tags n'ont pas été mentionné", "todo", "todo", "todo", "todo", "todo"];
export const FE_NO_ENERGY_TYPE = ["Aucun type d'énergie n'a été sélectionné", "todo", "todo", "todo", "todo", "todo"];
export const FE_DUPLICATE_FORMULA_NAME = ["Des noms de formule sont en double", "todo", "todo", "todo", "todo", "todo"];

// FR === Form Render
export const FR_INVALID_FORM_SOURCES = ["Aucune source valide de formulaire n'a été fournie", "todo", "todo", "todo", "todo", "todo"];
export const FR_NO_FORM_FOUND = ["Aucun formulaire correspondant aux sources n'a été trouvé", "todo", "todo", "todo", "todo", "todo"];
export const FR_NO_SUBMISSION_FOUND = ["Aucune soumission correspondantes n'a été trouvée", "todo", "todo", "todo", "todo", "todo"];

// FR_TAB === Tabs Components
export const FR_TAB_NO_COMPONENTS = ["Aucun composants", "todo", "todo", "todo", "todo", "todo"];

//#region Old
export const ACTION = ['Action', ' Action', ' Actie', 'Azione', 'Acción', 'حركة'];
export const DELETE = ['Supprimer', ' Delete', ' verwijderen', 'Cancellare', 'Borrar', 'حذف'];
export const EXPORT = ['Exporter', ' Export', 'Exporteren', 'Esportare', 'Exportar', 'تصدير'];
export const ADD = ['Ajouter', ' Add', 'Toevoegen', 'Aggiungere', 'Agregar', 'إضافة'];
export const IMPORT = ['Importer', ' Import', 'Importeren', 'Importare', 'Importar', 'استراد '];
export const CONFIRM = ['Confirmer', ' Confirm', 'Bevestigen', 'Per confermare', 'Confirmar', 'تأكيد'];
export const IGNORE = ['Ignorer', ' Ignore', 'Negeren', 'Ignorare', 'Ignorar', 'تجاهل'];
export const SIGNIN = ['S\'inscrire', ' Sign up', 'Registreer nu', 'Iscriviti ora', 'Inscribirse', 'تسجيل'];
export const NEXT = ['Suivant', ' Nex', 'Volgend op', 'seguente', 'Próximo', 'التالي'];
export const PREVIOUS = ['Précédent', ' Previous', 'vorig', 'Precedente', 'Anterior', 'السابق'];
export const SAVE = ['Sauver', ' Save', 'redden', 'Salvare', 'Ahorrar', 'حفظ '];
export const REMOVE = ['Retirer', ' to withdraw', 'Terugtrekken', 'Per ritirare', 'Retirar', 'سحب'];
export const UPDATE = ['Modifier', ' Edit', 'Bewerken', 'Modificare', 'Editar', 'تعديل'];
export const LOADING = ['Chargement', ' Loading', 'Bezig met laden', 'Caricamento in corso', 'Cargando', 'التحميل'];
export const NOT_FOUND = ['Pas trouvé', ' Not found', 'Niet gevonden', 'Non trovato', 'Extraviado', 'لم يتم العثور على'];
export const SELECT_EXISTING = ['Sélectionner un élément existant', 'Select an existing item', 'Selecteer een bestaand item', 'Seleziona un articolo esistente', 'Seleccionar un artículo existente', 'حدد عنصرًا موجودًا'];
export const NO_ITEM = ['Aucun élement', ' No element', 'Geen element', 'Nessun elemento', 'Sin elemento', 'لا يوجد عنصر'];
export const CREAT_NEW = ['Créer un nouvel élément', 'Create a new element', 'Een nieuw item maken', 'Crea un nuovo oggetto', 'Crea un artículo nuevo', 'إنشاء عنصر جديد'];
export const NAME = ['Nom', 'Name', 'Naam', 'Cognome', 'apellido', 'اسم'];
export const TICKETS = ['Tickets', 'Tickets', 'Kaartjes', 'Biglietti', 'Entradas', 'تذكرة'];
export const EQUIPMENTS = ['Equipements', 'Equipments', 'Uitrusting', 'Attrezzature', 'Equipos', 'معدات'];
export const BRAND = ['Marque', 'Mark', 'Mark', 'Marca', 'Marcos', 'علامة'];
export const BRAND_MODEL = ['Modèle', 'Model', 'Model', 'Modello', 'Modelo', 'نموذج'];
export const TYPE = ['Type', 'Type', 'Type', 'Tipo', 'Escribe', 'نوع'];
export const BUILDING = ['Bâtiment', 'Building', 'Gebouw', 'Costruzione', 'Edificio', 'مبنى'];
export const FLOOR = ['Etage', 'Floor', 'Fase', 'piano', 'Escenario', 'طابق'];
export const LOCAL = ['Local', 'Local', 'lokaal', 'Locale', 'Local', 'المحل'];
export const YEAR = ['Année', 'Year', 'Jaar', 'Anno', 'Año', 'عام'];
export const UNIT_YEAR = ['Ans', 'Years', 'jaren', 'Anni', 'Años', 'أعوام'];
export const TIMELIFE = ['Durée de vie', ' Lifetime', ' Levenslang', 'Tutta la vita', 'Esperanza de vida', 'مدة الحياة'];
export const STATE = ['Etat', ' State', 'staat', 'stato', 'estado', 'حالة'];
export const M_A_HS = ['M/A/HS', ' todo', ' todo', ' todo', 'M / A / HS', 'je ne sais pas comment je dois le traduire (M/A/HS)'];
export const DESCRIPTION = ['Description', ' Description', 'Beschrijving', 'Descrizione', 'Descripción', 'وصف'];
export const FREQUANCE = ['Fréquence', ' Frequency', 'Frequentie', 'Frequenza', 'Frecuencia', 'تردد'];
export const TECHNIQUE_DEPLASSE = ['Technique Deplasse', ' Deplasse technician', 'Deplasse techniek', 'Tecnica deplasse', 'Técnica de deplasse', 'تقنية DEPLASSE'];
export const PRICE = ['Prix', ' Price', 'Prijs', 'Prezzo', 'Premio', 'سعر'];
export const FIRSTNAME = ['Prénom', ' First name', ' Voornaam', 'Nome', 'Nombre de pila', 'اسم شخصي'];
export const LASTNAME = ['Nom', 'Name', 'Naam', 'Cognome', 'apellido', 'اسم العائلة'];
export const PASSWORD = ['Mot De Passe', ' Password', 'Wachtwoord', 'Parola d\'ordine', 'Contraseña', 'كلمة السر'];
export const MAIL = ['Email', ' E-mail', 'E-mail', 'E-mail', 'Correo electrónico', 'بريد الالكتروني'];
export const PHONE = ['Téléphone', ' Telephone', 'Telefoon', 'Telefono', 'Teléfono', 'هاتف'];
export const COMPANY = ['Société', 'Society', ' Maatschappij', 'Società', 'Sociedad', 'الشركة'];
export const SCHEDULE = ['Horaire', ' Hourly', 'per uur', 'Ogni ora', 'Cada hora', 'جدول مواعيد'];
export const SKILL = ['Expertise', 'Expertise', ' Expertise', 'Competenza', 'Experticia', 'خبرة'];
export const TECHNICIENS = ['Techniciens', 'Technicians', 'technici', 'Tecnici', 'Técnicos', 'التقنيون'];
export const DURATION = ['Durée', 'Duration', 'Looptijd', 'Durata', 'Duración', 'المدة'];
export const TECHNICIAN_RESP = ['Technicien responsable', 'Responsible technician', 'Verantwoordelijke technicus', 'Tecnico responsabile', 'Técnico responsable', 'المسؤول التقني '];
export const DATE = ['Date', 'Dated', 'Gedateerd', 'Datato', 'Con fecha de', 'التاريخ'];
export const DATE_HOUR = ['Date / Heure', ' Date /hour', 'Datum uur', 'Data ora', 'Hora de la fecha', 'التاريخ / الساعة'];
export const SUBJECT = ['Sujet', 'Topic', 'Onderwerp', 'Soggetto', 'Tema', 'موضوع'];
export const MAINTENANCE = ['Maintenance', 'Maintenance', 'Onderhoud', 'Manutenzione', 'Mantenimiento', 'الصيانة'];
export const GAMME = ['Gammes de Maintenance', ' Maintenance ranges', ' Onderhoudsbereiken', 'Intervalli di manutenzione', 'Rangos de mantenimiento', 'نطاق الصيانة'];
export const SUB_EQUIPMENT = ['Sous-Equipement', 'Sub-Equipment', 'Subapparatuur', 'Sotto-attrezzatura', 'Sub-equipo', 'تحت المعدات'];
export const ACTION_MAINTENANCE = ['Action de maintenance', ' Maintenance action', ' Onderhoudsactie', 'Azione di manutenzione', 'Acción de mantenimiento', 'إجراء الصيانة'];
export const OTHER = ['Autre...', ' Other…', 'Ander…', 'Altro...', 'Otro...', 'آخر...'];
export const CURATIVE = ['curative', ' Curative', 'genezend', 'curativo', 'curativo', 'علاج'];
export const PREVENTIVE = ['préventive', ' preventive', ' preventief', 'preventivo', 'preventivo', 'وقائي'];
export const OPERATION = ['Opération', ' Surgery', 'Chirurgie', 'Chirurgia', 'Cirugía', 'عملية'];
export const REFERENCE = ['Référence', ' Reference', 'Verwijzing', 'Riferimento', 'Referencia', 'المرجع'];
export const STREET = ['Rue', ' Street', 'Straat', 'Strada', 'calle', 'شارع'];
export const NUMBER = ['Numéro', ' Number', 'Nummer', 'Numero', 'Número', 'رقم'];
export const ZIP = ['Code ZIP', 'ZIP Code', 'Postcode', 'Cap', 'Código postal', 'الرمز البريدي'];
export const TOWN = ['Ville', ' City', ' Stad', 'città', 'Ciudad', 'مدينة'];
export const COUNTRY = ['Pays', ' Country', 'Land', 'Nazione', 'País', 'دولة'];
export const VETUCITY = ['Vétusté', 'Dilapidated', ' Vervallen', 'fatiscente', 'Arruinado', 'خربت'];
export const FAILURE_CRITICITY = ['Criticité de la panne', 'Criticality of the failure', 'Kritiek van de mislukking', 'Criticità del fallimento', 'Criticidad del fracaso', 'أهمية الفشل'];
export const CRITICITY = ['Criticité', 'Criticality', 'Kritiek', 'Criticità', 'Criticidad', 'حرجية'];
export const DO_MAINTENANCE = ['Réaliser la maintenance', ' Perform maintenance', 'Onderhoud uitvoeren', 'Eseguire la manutenzione', 'Realizar mantenimiento', 'أجري الصيانة'];
export const ADD_EQUIPMENT = ['Ajouter équipement', ' Add equipment', 'Apparatuur toevoegen', 'Aggiungi attrezzatura', 'Agregar equipo', 'أضف المعدات'];
export const ADD_TICKET = ['Ajouter ticket', ' Add ticket', 'Ticket toevoegen', 'Aggiungi biglietto', 'Agregar ticket', 'أضف تذكرة'];
export const CREAT_TICKET = ['Créer ticket', 'Create ticket', 'Ticket maken', 'Crea biglietto', 'Crear Ticket', 'إنشاء تذكرة'];
export const CREAT_BUILDING = ['Nouveau bâtiment', 'New building', 'Nieuw gebouw', 'Nuova costruzione', 'Nuevo edificio', 'مبنى جديد'];
export const CREAT_SUB_EQUIPMENT = ['Nouveau sous-équipement', 'New under-equipment', 'Nieuwe onderuitrusting', 'Nuova sotto-attrezzatura', 'Nuevo equipamiento inferior', 'معدات جديدة'];
export const CREAT_MAINTENANCE = ['Nouvelle maintenance', ' New maintenance', 'Nieuw onderhoud', 'Nuova manutenzione', 'Nuevo mantenimiento', 'صيانة جديدة'];
export const CREAT_GAMME = ['Nouvelle Gamme', 'New collection', 'Nieuwe collectie', 'Nuova collezione', 'Nueva colección', 'مجموعة جديدة'];
export const NO_TICKET = ['Aucun tickets', 'No tickets', 'Geen kaartjes', 'Nessun biglietto', 'Sin boletos', 'لا تذكرة'];
export const CREAT_USER = ['Créer utilisateur', ' Create user', 'Gebruiker maken', 'Creare un utente', 'Crear usuario', 'إنشاء مستخدم'];
export const TICKET = ['Ticket', ' Ticket', 'Ticket', 'Biglietto', 'Billete', 'تذكرة'];
export const TREE_STRUCT = ['Arborescence', 'Tree structure', 'Boomstructuur', 'struttura ad albero', 'Estructura de árbol', 'هيكل شجرة'];
export const FORMS = ['Formulaires', 'Forms', 'Formulieren', 'Forme', 'Formularios', 'نماذج'];
export const RESOURCE = ['Ressources', 'Resources', 'Bronnen', 'risorse', 'Recursos', 'موارد'];
export const MANAGEMENT = ['Gestion', 'Management', 'Beheer', 'Gestione', 'Gestión', 'إدارة'];
export const PLANNING = ['Planning', 'Planning', 'Planning', 'Pianificazione', 'Planificación', 'تخطيط'];
export const STORE_EQUIPMENT = ['Equipement Type', ' Equipment Type', 'Apparatuurtype:', 'Tipo di apparecchiatura', 'tipo de equipo', 'نوع معدات'];
export const TECHNICIEN = ['Technicien', 'Technician', 'Technicus', 'Tecnico', 'Técnico', 'تقني'];
export const GAMME_MAINTENANCE = ['Gamme de Maintenance', 'Maintenance Range', 'Onderhoudsbereik:', 'Gamma di manutenzione', 'Rango de mantenimiento', 'نطاق الصيانة'];
export const PROFILE = ['Profil', 'Profile', 'Profiel', 'Profilo', 'Perfil', 'الملف الشخصي'];
export const LOGOUT = ['Se déconnecter', ' Sign off', 'Afmelden', 'disconnessione', 'Desconectarse', 'تسجيل الخروج'];
export const LOGIN = ['Se connecter', ' Sign in', 'Inloggen', 'Per accedere', 'Conectarse', 'يتصل'];
export const COVID_FORM_TAB_NAME = ['Formulaire Covid', ' Covid Form', 'Covid-formulier', 'Modulo Covid', 'Forma Covid', 'نموذج كوفيد'];
export const LANGUAGE_TAB = ['Langue', ' Language', 'Tong', 'Lingua', 'Lengua', 'اللغة'];
export const REF_TAB = ['Reference JOOL', 'Reference JOOL', 'Referentie JOOL', 'Riferimento JOOL', 'Referencia JOOL', 'المرجع JOOL'];
//#endregion

//#region Tabs (TAB)
export const TAB_EVENT_READER = ['Evénement de Maintenance', 'Maintenance Event', 'Onderhoudsgebeurtenis', 'Evento di manutenzione', 'Evento de mantenimiento', 'حدث الصيانة'];
export const TAB_CHECK_MANUAL_RECORD = ['Valider relevé manuel', 'Validate manual statement', 'Handmatige verklaring valideren', 'Convalida dichiarazione manuale', 'Validar extracto manual', 'تحقق من صحة البيان اليدوي'];
export const TAB_ADMIN = ['Admin', 'Admin', 'beheerder', 'Amministratore', 'administración', 'مشرف'];
export const USER_GESTION = ['Utilisateurs', 'Users', 'Gebruikers', 'Utenti', 'Usuarios', 'المستخدمون'];
export const TAB_ENTITY_EDITOR = ['Édition de compteur', 'Counter editing', 'Teller editie', 'Edizione da banco', 'Edición de contador', 'طبعة مضادة'];
export const TAB_DASHBOARD_GMAO = ['Statistiques des tickets', 'Ticket statistics', 'Ticketstatistieken', 'Statistiche dei biglietti', 'Estadísticas de entradas', 'إحصائيات التذاكر'];
//#endregion

//#region Gestion (G, GT, GU)
export const G_ERROR_FETCH = ['Erreur chargement', ' Loading error', 'Laadfout', 'Errore di caricamento', 'Error de carga', 'خطأ في التحميل'];
export const G_YEAR_SUFFIX = ['an(s)', ' year(s)', 'jaar', 'anni', 'años', 'أعوام'];
export const G_PRICE_YEAR = ['Année du prix', ' Year of the award', 'Jaar van de prijs', 'Anno del premio', 'Año del premio', 'عام الجائزة'];
export const G_ERROR_SCHEDULE_FORMAT = ['Infos manquantes', ' Missing informations', 'Ontbrekende informatie', 'Informazioni mancanti', 'Falta información', 'معلومات مفقودة'];
export const G_FILE_LABEL = ['Fichier', ' File', 'Bestand', 'File', 'Expediente', 'ملف'];
export const G_FILES_LABEL = ['Fichiers', ' Files', 'Bestanden', 'File', 'Archivos', 'الملفات'];
export const G_ERROR_FILE_TYPE = ['Le format du fichier doit être un .xlsx (Microsoft Excel).', ' The file format must be .xlsx (Microsoft Excel).', 'Het bestandsformaat moet .xlsx (Microsoft Excel) zijn.', 'Il formato del file deve essere .xlsx (Microsoft Excel).', 'El formato de archivo debe ser .xlsx (Microsoft Excel).', 'يجب أن يكون تنسيق الملف هو xlsx. (Microsoft Excel).'];
export const G_ERROR_CONTENT = ['Le contenu du fichier ne contient pas ce qu\'il en était attendu.', 'The contents of the file do not contain what was expected.', 'De inhoud van het bestand bevat niet wat werd verwacht.', 'Il contenuto del file non contiene quanto previsto.', 'El contenido del archivo no contiene lo que se esperaba.', 'محتويات الملف لا تحتوي على ما كان متوقعا'];
export const G_ERROR_CONTENT_HELP = ['Le fichier devrait contenir une feuille de travail nommée', ' The file should contain a worksheet named', 'Het bestand moet een werkblad bevatten met de naam', 'Il file dovrebbe contenere un foglio di lavoro denominato', 'El archivo debe contener una hoja de trabajo llamada', 'يجب أن يحتوي الملف على ورقة عمل مسماة'];
export const G_ERROR_EMPTY_SELECT = ['Aucune option n\'a été selectionnée', ' No option has been selected', 'Er is geen optie geselecteerd', 'Nessuna opzione è stata selezionata', 'No se ha seleccionado ninguna opción', 'لم يتم تحديد أي خيار'];
export const G_DID_YOU_MEAN = ['Vouliez-vous dire', ' Did you mean', 'Bedoelde je', 'Intendevi', 'Querías decir', 'هل تعني'];
export const G_CREATE_NEW = ['Créer un nouvel objet', ' Create a new object', 'Een nieuw object maken', 'Crea un nuovo oggetto', 'Crea un nuevo objeto', 'قم بإنشاء كائن جديد'];
export const G_VISUALIZE_TITLE = ['Visualisation de l\'objet', ' Object visualization', 'Objectvisualisatie', 'Visualizzazione dell\'oggetto', 'Visualización de objetos', 'تصور الكائن'];
export const G_CANCEL_IMPORT = ['Êtes-vous sûr de vouloir annuler l\'import ?', 'Are you sure you want to cancel the import?', 'Weet u zeker dat u het importeren wilt annuleren?', 'Sei sicuro di voler annullare l\'importazione?', '¿Estás seguro de que deseas cancelar la importación?', 'هل أنت متأكد أنك تريد إلغاء الاستيراد؟'];
export const G_NO_SIMILAR_DATA_FOUND = ['Aucune donnée similaire n\'a été trouvée', 'No similar data was found', 'Geen vergelijkbare gegevens gevonden', 'Non sono stati trovati dati simili', 'No se encontraron datos similares', 'لم يتم العثور على بيانات مماثلة'];
export const G_EXCEL_LINE_INFOS = ['Trouver aux lignes n°', 'Find at lines n °', 'Zoek op regels n °', 'Trova alle righe n °', 'Encuentra en las líneas n °', 'تجد في الخطوط رقم °'];
export const G_EXCEL_SHEET = ['Feuille de travail', ' Worksheet', 'werkblad', 'Foglio di lavoro', 'Hoja de cálculo', 'ورقة العمل'];
export const G_NO_SUB_EQUIP_SELECTED = ['Aucun equipement n\'a été sélectionner', ' No equipment has been selected', 'Er is geen apparatuur geselecteerd', 'Nessuna attrezzatura è stata selezionata', 'No se ha seleccionado ningún equipo', 'لم يتم اختيار أي معدات'];
export const G_NO_PARENT_EQUIP = ['Aucun equipement parent n\'a été sélectionner', 'No parent equipment has been selected', 'Er is geen ouderuitrusting geselecteerd', 'Nessuna attrezzatura genitore è stata selezionata', 'No se ha seleccionado ningún equipo principal', 'لم يتم اختيار معدات الوالدين'];
export const G_FULL_REFERENCE = ["Référence Complete", "Reference Complete", "Referentie voltooid", "Riferimento completo", "Referencia completa", "todo"];
export const G_ACTION = ["Action", "Action", "actie", "azione", "acción", "todo"];
export const G_TECHNIQUE_DEPLASSE = ["Technique Deplasse", "Deplasse technique", "Deplasse techniek", "Tecnica Deplasse", "Técnica de Deplasse", "todo"];
export const G_EXPLOITATION_UNIT = ["Unité d'exploitation", "Operating unit", "Bedieningseenheid", "Unità Operativa", "Unidad Operativa", "todo"];
export const G_FUNCTIONAL_UNIT = ["Unité fonctionnelle", "Functional unit", "Functionele eenheid", "Unità funzionale", "Unidad Funcional", "todo"];
export const G_ENSEMBLE = ["Ensemble", "together", "samen", "insieme", "juntos", "todo"];
export const G_EQUIPMENT_TYPE = ["Type d'équipement", "Equipment type", "Type uitrusting", "Tipo di apparecchiatura", "Tipo de equipo", "todo"];
export const G_FREQUENCY = ["Fréquence", "Frequency", "Frequentie", "Frequenza", "Frecuencia", "todo"];
export const G_ENTITY_CONTROL = ['Entité concernée', 'Entity concerned', 'Betrokken entiteit', 'Ente interessato', 'Entidad interesada', 'الكيان المعني'];
export const GT_CLOSE_TICKET = ['Clôturer le ticket', 'Close ticket', 'Ticket sluiten', 'Chiudere il biglietto', 'Cerrar ticket', 'إغلاق التذكرة'];
export const GU_LOAD_TYPE = ['Accès donnés', 'Data access', 'Toegang  gegeven', 'Accesso concesso', 'Acceso dado', 'منح حق الوصول'];
export const GU_SUPERIORS = ['Supérieurs Hiérarchique', 'Line Managers', 'Lijnmanagers', 'Responsabili di linea', 'Los gerentes de línea', 'مدراء الخط'];
export const GU_SUPERIORS_REQUIRED = ['Au moins 1 supérieur est requis', 'At least 1 superior is required', 'Er is minimaal 1 leidinggevende vereist', 'È richiesto almeno 1 superiore', 'Se requiere al menos 1 superior', 'مطلوب متفوقة واحدة على الأقل.'];
export const GU_EMPTY_USER_TABS_SELECTION = ['Veuillez sélectionner des menus avant de modifier les droits', 'Please select menus before modifying rights', 'Selecteer menu\'s voordat u rechten wijzigt', 'Si prega di selezionare i menu prima di modificare i diritti', 'Seleccione los menús antes de modificar los derechos.', 'الرجاء تحديد القوائم قبل تعديل الحقوق'];
//#endregion

//#region Planning (P)
export const P_ADD_TICKET = ['Nouveau ticket', ' New ticket', 'Nieuw kaartje', 'Nuovo biglietto', 'Boleto nuevo', 'تذكرة جديدة'];
export const P_TICKET_DURATION = ['Temps de travail estimé', ' Estimated working time', 'Geschatte werktijd', 'Orario di lavoro stimato', 'Tiempo de trabajo estimado', 'وقت العمل المقدر'];
export const P_RESERVED_DURATION = ['Temps réservé', ' Reserved time', 'Gereserveerde tijd', 'Tempo riservato', 'Tiempo reservado', 'الوقت المحجوز'];
export const P_ASSIGNED_TICKET = ['Tickets assigné', ' Assigned tickets', 'Toegewezen tickets', 'Biglietti assegnati', 'Entradas asignadas', 'التذاكر المخصصة'];
export const P_UNASSIGNED_TICKET = ['Tickets disponible', ' Tickets available', 'Tickets beschikbaar', 'Biglietti disponibili', 'Entradas disponibles', 'التذاكر متوفرة'];
export const P_HIDE_TICKET = ['Cacher les tickets', ' Hide tickets', 'Verberg tickets', 'Nascondi i biglietti', 'Ocultar entradas', 'إخفاء التذاكر'];
export const P_REVEAL_TICKET = ['Afficher les tickets', ' Show tickets', 'Toon tickets', 'Mostra biglietti', 'Mostrar entradas', 'عرض التذاكر'];
export const P_END_DATE = ['Date de fin', 'End date', 'Einddatum', 'Data di fine', 'Fecha final', 'تاريخ النهاية'];
export const P_START_DATE = ['Date de début', 'Start date', 'Begin datum', 'Data d\'inizio', 'Fecha de inicio', 'تاريخ البدء'];
export const P_CLOSE_TICKETS = ['Clôturer les tickets', 'Close tickets', 'Sluit tickets', 'Chiudere i biglietti', 'Cerrar entradas', 'إغلاق التذاكر'];
export const P_TICKETS_LATE = ['Tickets en retard', 'Late tickets', 'Late tickets', 'Biglietti in ritardo', 'Entradas tardías', 'التذاكر المتأخرة'];
export const P_TICKETS_END_SOON = ['Tickets à finir cette semaine', 'Tickets to end this week', 'Tickets eindigen deze week', 'I biglietti termineranno questa settimana', 'Entradas para terminar esta semana', 'تذاكر نهاية هذا الاسبوع'];
export const P_TICKETS_URGENT = ['Tickets urgent', 'Urgent tickets', 'Spoedkaarten', 'Biglietti urgenti', 'Tickets urgentes', 'تذاكر عاجلة'];
export const P_CLICK_SHOW = ['Cliquez pour filtrer', ' Click to filter', 'Klik om te filteren', 'Fare clic per filtrare', 'Haga clic para filtrar', 'انقر للتصفية'];
export const P_UNDO_FILTERS = ['Supprimer les filtres', ' Reset the filters', 'Filters verwijderen', 'Rimuovere i filtri', 'Eliminar filtros', 'إزالة المرشحات'];
export const P_EMERGENCY = ['Urgence', ' Emergency', 'Noodgeval', 'Emergenza', 'Emergencia', 'طارئ'];
//#endregion

//#region Tickets (T)
export const T_CREATED_BY = ['Demande soumise par', ' Request submitted by', 'Verzoek ingediend door', 'Richiesta presentata da', 'Solicitud enviada por', 'طلب مقدم من'];
export const T_CREATED_THE = ['le', ' the', 'de', 'il', 'los', 'le'];
export const T_SELECT_EQUIPMENT = ['Sélectionnez un équipement', 'Select an equipment', 'Selecteer een uitrusting', 'Seleziona un\'attrezzatura', 'Seleccione un equipo', 'اختر أحد المعدات'];
export const T_REQUIRED_EQUIPMENT = ['La sélection d\'un équipement est obligatoire.', 'Selection of equipment is mandatory.', 'Selectie van apparatuur is verplicht.', 'La selezione dell\'attrezzatura è obbligatoria.', 'La selección de equipo es obligatoria.', 'اختيار المعدات إلزامي.'];
export const T_CLOSED = ['Clôturé', ' Closed', 'Hek', 'Scherma', 'Esgrima', 'إغلا ق'];
export const T_OPEN = ['Ouvert', ' Open', 'Open', 'Aprire', 'Abierto', 'افتح'];
export const T_SAVE_AND_NEXT = ['Sauver & Suivant', ' Save & Next', 'Opslaan en volgende', 'Salva e Avanti', 'Guardar y siguiente', 'حفظ ثم التالي'];
export const T_SAVE_AND_CLOSE = ['Sauver & Quitter', ' Save & Quit', 'Opslaan afsluiten', 'Salva ed esci', 'Guardar la salida', 'حفظ وخروج'];
export const T_UNASSIGNED = ['Non-attribué', 'Not attributed', 'Niet toegeschreven', 'Non attribuito', 'no atribuido', 'لا ينسب'];
//#endregion

//#region Register (REG)
export const REG_INVALID_NAME = ['Le nom ne peut pas être vide', 'Name cannot be empty', 'Naam mag niet leeg zijn', 'Il nome non può essere vuoto', 'El nombre no puede estar vacío', 'لا يمكن أن يكون الاسم فارغًا'];
export const REG_EMPTY_PASSWORD = ['Le mot de passe ne peut pas être vide', ' Password cannot be empty', 'Wachtwoord mag niet leeg zijn', 'La password non può essere vuota', 'La contraseña no puede estar vacía', 'لا يمكن أن تكون كلمة المرور فارغة'];
export const REG_PASSWORD_DIFFERENT = ['Veuillez introduire le même mot de passe dans les deux champs', 'Please enter the same password in both fields', 'Voer in beide velden hetzelfde wachtwoord in', 'Si prega di inserire la stessa password in entrambi i campi', 'Ingrese la misma contraseña en ambos campos', 'الرجاء إدخال نفس كلمة المرور في كلا الحقلين'];
export const REG_MAIL_INVALID = ['Le format ne correpond pas à une addresse mail valide', 'The format does not correspond to a valid email address', 'Het formaat komt niet overeen met een geldig e-mailadres', 'Il formato non corrisponde a un indirizzo email valido', 'El formato no corresponde a una dirección de correo electrónico válida', 'لتنسيق لا يتوافق مع عنوان بريد إلكتروني صالح'];
export const REG_MAIL_EXIST = ['Ce mail existe déja', 'This email already exists', 'Dit e-mailadres bestaat al', 'questa email esiste già', 'Ya existe Este email', 'هذا البريد الإلكتروني موجود بالفعل'];
export const REG_INVALID_SUBJECT = ['Le sujet du ticket ne peut pas être vide', ' The subject of the ticket cannot be empty', 'Het onderwerp van het ticket mag niet leeg zijn', 'L\'oggetto del biglietto non può essere vuoto', 'El asunto del ticket no puede estar vacío', 'لا يمكن أن يكون موضوع التذكرة فارغًا'];
export const REG_INVALID_DESC = ['La description ne peut pas être vide', 'Description cannot be empty', 'Beschrijving mag niet leeg zijn', 'La descrizione non può essere vuota', 'La descripción no puede estar vacía', 'لا يمكن أن يكون الوصف فارغًا'];
export const REG_NAME_LABEL = ['Nom', 'Name', 'Naam', 'Cognome', 'apellido', 'اسم'];
export const REG_SURNAME_LABEL = ['Prénom', 'First name', 'Voornaam', 'Nome', 'Nombre de pila', 'اسم شخصي'];
export const REG_MAIL_LABEL = ['Mail', ' Mail', 'Mail', 'Mail', 'mail', 'بريد'];
export const REG_PASSWORD = ['Mot de passe', ' Password', 'Wachtwoord', 'Parola d\'ordine', 'Contraseña', 'كلمة السر'];
export const REG_CONFIRMATION = ['Confirmation', 'Confirmation', 'Bevestiging', 'Conferma', 'Confirmación', 'تأكيد'];
export const REG_MAIL_USED = ['Cette adresse mail est déjà utilisée, veuillez réessayer dans 5 minutes', 'This email address is already in use, please try again in 5 minutes', 'Dit e-mailadres is al in gebruik, probeer het over 5 minuten opnieuw', 'Questo indirizzo email è già in uso, riprova tra 5 minuti', 'Esta dirección de correo electrónico ya está en uso. Vuelva a intentarlo en 5 minutos.', 'عنوان البريد الإلكتروني هذا قيد الاستخدام بالفعل ، يرجى المحاولة مرة أخرى بعد 5 دقائق'];
export const REG_IP_LOCKED = ['Votre dernière tentative de connexion remonte à moins de 5 minutes, veuillez attendre avant de réessayer', 'Your last connection attempt was less than 5 minutes ago, please wait before trying again', 'Uw laatste verbindingspoging was minder dan 5 minuten geleden, wacht alstublieft voordat u het opnieuw probeert', 'Il tuo ultimo tentativo di connessione è stato meno di 5 minuti fa, attendi prima di riprovare', 'Su último intento de conexión fue hace menos de 5 minutos. Espere antes de volver a intentarlo.', 'كانت آخر محاولة اتصال لك منذ أقل من 5 دقائق ، يرجى الانتظار قبل المحاولة مرة أخرى'];
export const REG_SUCCESS = ['Un email de validation vous a été envoyer afin de confirmer la création de votre compte', 'A validation email has been sent to you to confirm the creation of your account', ' Er is een validatie-e-mail naar u verzonden om het aanmaken van uw account te bevestigen', 'Ti è stata inviata un\'email di convalida per confermare la creazione del tuo account', 'Se le ha enviado un correo electrónico de validación para confirmar la creación de su cuenta.', 'تم إرسال بريد إلكتروني للتحقق لك لتأكيد إنشاء حسابك'];
export const REG_ALREADY_HAVE_ACCOUNT = ['Vous posséder déja un compte ? Loggez vous ici', 'Do you already have an account? Log in here', 'Heeft u al een account? Hier inloggen', 'Hai già un account? Accedi qui', 'Ya tienes una cuenta? Entre aquí', 'هل لديك حساب بالفعل؟ سجل الدخول هنا'];
export const REG_ATTEMPT_REMAINING = ['tentative(s) restante(s)', ' attempt(s) remaining', 'poging(en) resterend', 'tentativo (s) rimanente', 'intentos restantes', 'المحاولات المتبقية'];
export const REG_EDIT_PASSWORDS = ["Modifier le mot de passe", "Change the password", "verander het wachtwoord", "cambia la password", "cambiar la contraseña", "todo"];
export const REG_CANCEL_EDIT_PASSWORD = ["Annuler l'édition du mot de passe", "Cancel password editing", "Bewerken van wachtwoord annuleren", "Annulla la modifica della password", "Cancelar la edición de la contraseña", "todo"];
export const REG_CHECK_MAIL_LINK_SENT = ["Un lien de vérification a été envoyée à l'adresse mail introduite.", "A verification link has been sent to the email address entered", "Er is een verificatielink verzonden naar het ingevoerde e-mailadres", "Un link di verifica è stato inviato all'indirizzo email inserito", "Se envió un enlace de verificación a la dirección de correo electrónico ingresada.", "todo"];
//#endregion

//#region Mail Check (MC)
export const MC_OUTDATED = ['Les données datent d\'il y a plus de 24h, elle ne peuvent donc plus être utilisées', 'The data is more than 24 hours ago, so it can no longer be usedThe data is more than 24 hours ago, so it can no longer be used', 'De gegevens zijn meer dan 24 uur geleden, dus ze kunnen niet meer worden gebruikt', 'I dati sono più di 24 ore fa, quindi non possono più essere utilizzati', 'Los datos son de hace más de 24 horas, por lo que ya no se pueden usar', 'مضى أكثر من 24 ساعة على البيانات ، لذا لم يعد من الممكن استخدامها'];
export const MC_OK = ['Vous avez confirmer votre compte avec succès', ' You have successfully confirmed your account', 'Je hebt je account succesvol bevestigd', 'Hai confermato con successo il tuo account', 'Has confirmado tu cuenta con éxito', 'لقد قمت بتأكيد حسابك بنجاح'];
export const MC_COUNTDOWN = ['Redirection vers la page de login dans ', 'Redirection to the login page in', 'Omleiding naar de inlogpagina in', 'Reindirizzamento alla pagina di accesso in', 'Redirección a la página de inicio de sesión en', 'إعادة التوجيه إلى صفحة تسجيل الدخول في'];
export const MC_DONE = ['Ce lien n\'est plus disponible', 'This link is no longer available', 'Deze link is niet meer beschikbaar', 'Questo link non è più disponibile', 'Este enlace ya no está disponible', 'هذا الرابط لم يعد متاحا'];
//#endregion

//#region Day (DAY)
export const DAY_MONDAY = ['Lundi', ' Monday', 'Op maandag', 'Di lunedi', 'Los lunes', 'الاثنين'];
export const DAY_TUESDAY = ['Mardi', ' Tuesday', 'Dinsdag', 'Martedì', 'martes', 'الثلاثاء'];
export const DAY_WEDNESDAY = ['Mercredi', ' Wednesday', 'woensdag', 'mercoledì', 'miércoles', 'الأربعاء'];
export const DAY_THURSDAY = ['Jeudi', ' Thursday', 'vandaag', 'giovedi', 'Hoy', 'الخميس'];
export const DAY_FRIDAY = ['Vendredi', ' Friday', 'vrijdag', 'venerdì', 'viernes', 'الجمعة'];
export const DAY_SATURDAY = ['Samedi', ' Saturday', 'zaterdag', 'il sabato', 'sábado', 'السبت'];
export const DAY_SUNDAY = ['Dimanche', ' Sunday', 'zondag', 'Domenica', 'domingo', 'الأحد'];
export const DAY_DAY = ['jour', ' day', 'dag', 'giorno', 'día', 'يوم'];
export const DAY_DAYS = ['jours', ' days', 'dagen', 'giorni', 'dias', 'أيام'];
export const DAY_HOUR = ['heure', ' hour', 'tijd', 'tempo', 'hora', 'ساعة'];
export const DAY_HOURS = ['heures', ' hours', 'tijd', 'tempo', 'tiempo', 'الساعات'];
export const DAY_MINUTE = ['minute', ' Minute', 'minuut', 'minuto', 'minuto', 'دقيقة'];
export const DAY_MINUTES = ['minutes', ' minutes', 'minuten', 'minuti', 'minutos', 'الدقائق'];
//#endregion

//#region Global (GLOBAL)
export const GLOBAL_LABEL_CONTACT = ['Contact', ' Contact', 'Contact', 'Contatto', 'Contactar', 'اتصل'];
export const GLOBAL_LABEL_ENTERPRISE = ['Entreprise', ' Company', 'Onderneming', 'Azienda', 'Negocio', 'شركة'];
export const GLOBAL_LABEL_SITE = ['Site', ' Site', 'site', 'Posto', 'Sitio', 'موقع'];
export const GLOBAL_LABEL_BUILD = ['Bâtiment', 'Building', 'Gebouw', 'Costruzione', 'Edificio', 'مبنى'];
export const GLOBAL_LABEL_EMPLACEMENT = ['Emplacement', 'Site', 'Standplaats', 'Posto', 'Sitio', 'موقع'];
export const GLOBAL_LABEL_EQUIPMENT = ['Equipement', ' Equipment', 'Apparatuur', 'Attrezzatura', 'Equipo', 'معدات'];
export const GLOBAL_CONFIRM = ['Valider', ' Confirm', 'bevestigen', 'convalidare', 'Validar', 'صالح'];
export const GLOBAL_EDIT = ['Editer', ' Edit', 'Bewerking', 'Modificare', 'Editar', 'يحرر'];
export const GLOBAL_DELETE = ['Supprimer', ' Delete', 'verwijderen', 'Cancellare', 'Borrar', 'حذف'];
export const GLOBAL_ERROR = ['Erreur', ' Error', 'Schuld', 'Colpa', 'Culpa', 'خطأ'];
export const GLOBAL_SAVE = ['Sauvegarder', ' Save', 'Besparen', 'Salvaguardare', 'Para salvaguardar', 'حفظ'];
export const GLOBAL_SAVE_AND_QUITE = ['Sauvergarder & quitter', ' Save & Quit', 'Opslaan en afsluiten', 'Salva ed esci', 'Guardar y salir', 'احفظ واخرج'];
export const GLOBAL_FROM = ['De', ' From', 'Van', 'Di', 'De', 'من '];
export const GLOBAL_TO = ['A', ' To', 'TOT', 'A', 'PARA', 'الى'];
export const GLOBAL_LOADING = ['Chargement', ' Loading', 'Bezig met laden', 'Caricamento in corso', 'Cargando', 'تحميل'];
export const GLOBAL_NO_DATA_AVAILABLE = ['Pas de données disponible', ' No data available', 'Geen gegevens beschikbaar', 'Nessun dato disponibile', 'Datos no disponibles', 'لا تتوافر بيانات'];
export const GLOBAL_ALL = ['Tout', ' All', 'Alle', 'tutto', 'Todo', 'جميع'];
export const GLOBAL_FLOOR = ['Étage', ' Floor', 'Fase', 'Piano', 'Escenario', 'طابق'];
export const GLOBAL_RESTROOM = ['Sanitaire', ' Restroom', 'Sanitair', 'sanitari', 'Sanitario', 'صحية'];
export const GLOBAL_LOCAL = ['Local', ' Local', 'lokaal', 'Locale', 'Local', 'محلي'];
export const GLOBAL_ZONE = ['Zone', ' Zone', 'gezoneerd', 'suddivisa in zone', 'Zonificado', 'مخصصة'];
export const GLOBAL_NONE = ['Aucun', ' None', 'Nee', 'No', 'No', 'لاشي '];
export const GLOBAL_TYPE = ['type', ' Type', 'Type', 'Tipo', 'Escribe', 'نوع '];
export const GLOBAL_CONFIRM_DELETE = ['Êtes-vous sûr de vouloir supprimer votre sélection ?', ' Are you sure you want to delete your selection?', 'Weet je zeker dat je je selectie wilt verwijderen?', 'Sei sicuro di voler eliminare la tua selezione?', '¿Está seguro de que desea eliminar su selección?', 'هل أنت متأكد أنك تريد حذف اختيارك؟'];
export const GLOBAL_SEARCH_PLACEHOLDER = ['Recherche', ' Search', 'Zoeken', 'Ricerca', 'Investigar', 'بحث'];
export const GLOBAL_VISUALIZE = ['Visualiser', ' Visualize', 'visualiseren', 'Visualizzare', 'Visualizar', 'تصور'];
export const GLOBAL_FAILED_LOAD = ['Erreur lors du chargement des données', ' Error loading data', 'Fout bij het laden van gegevens', 'Errore durante il caricamento dei dati', 'Error al cargar datos', 'خطأ في تحميل البيانات'];
export const GLOBAL_REQUIRED_FIELD = ['Ce champ est requis.', ' This field is required.', 'Dit veld is verplicht.', 'Questo campo è obbligatorio.', 'Este campo es requerido.', 'هذه الخانة مطلوبه.'];
export const GLOBAL_CALCULATED = ['Valeur calculée', 'Calculated value', 'Berekende waarde', 'Valore calcolato', 'Valor calculado', 'القيمة المحسوبة'];
export const GLOBAL_IGNORE = ['Ignorer', ' Ignore', 'Negeren', 'Ignorare', 'Ignorar', 'تجاهل'];
export const GLOBAL_NOTES = ['Notes', 'Notes', 'Opmerkingen', 'Appunti', 'Notas', 'ملاحظات'];
export const GLOBAL_SAVED = ['Sauvergardé', ' Saved', 'Opgeslagen', 'Salvato', 'Salvado', 'حفظ'];
export const GLOBAL_EVENT = ['Evenement', ' Event', 'Evenement', 'Evento', 'Evento', 'حدث'];
export const GLOBAL_CANCEL = ['Annuler', ' to Cancel', 'annuleren', 'per cancellare', 'anular', 'الغاء'];
export const GLOBAL_SUBJECT = ['Sujet', ' Subject', 'Onderwerp', 'Soggetto', 'Tema', 'موضوع'];
export const GLOBAL_START = ['Début', ' Start', 'Begin', 'Cominciare', 'Inicio', 'بدأ'];
export const GLOBAL_END = ['Fin', ' End', 'Einde', 'Fine', 'Fin', 'نهاية'];
export const GLOBAL_TODAY = ['Aujourd\'hui', ' Today', 'Vandaag', 'Oggi', 'Hoy dia', 'اليوم'];
export const GLOBAL_WEEK = ['Semaine', ' Week', 'Week', 'Settimana', 'Semana', 'أسبوع'];
export const GLOBAL_MONTH = ['Mois', ' Month', 'Maand', 'Mese', 'Mes', 'شهر'];
export const GLOBAL_YES = ['Oui', ' Yes', 'Ja', 'sì', 'sí', 'نعم'];
export const GLOBAL_NO = ['Non', ' No', 'Neen', 'No', 'No', 'لا'];
export const GLOBAL_ORDER_BY = ['Trier par :', 'Sort by :', 'Sorteer op :', 'Ordina per :', 'Ordenar por :', 'الترتيب حسب :'];
export const GLOBAL_LOCATION = ['Localisation', 'location', 'Plaats', 'Posizione', 'Ubicación', 'موقع'];
export const GLOBAL_TITLE = ['Titre', ' Title', 'Titel', 'Titolo', 'Título', 'عنوان'];
export const GLOBAL_ROLES = ['Rôles', 'Roles', 'Rollen', 'ruoli', 'roles', 'الأدوار'];
export const GLOBAL_REPLY = ['Répondre', ' Reply', 'beantwoorden', 'Rispondere', 'Responder', 'ليجيب'];
export const GLOBAL_BY = ['Par', ' By', 'Er doorheen', 'Tramite', 'Mediante', 'عبر'];
export const GLOBAL_EDITED_THE = ['Modifié le', 'Modified', 'gewijzigd', 'Modificati', 'Modificado', 'تم التعديل'];
export const GLOBAL_SHOW_MORE = ['Voir plus', ' Show more', 'Bekijk meer', 'Vedi altro', 'Ver más', 'شاهد المزيد'];
export const GLOBAL_LABEL_ENTITY = ['Compteur', 'Counter', 'Balie', 'Contatore', 'contador', 'العداد'];
export const GLOBAL_VALUE = ['Valeur', 'Value', 'Waarde', 'Valore', 'Valor', 'قيمة'];
export const GLOBAL_TIME_CHECK = ['Date de relevé', 'Statement date', 'Afschriftdatum', 'Data della dichiarazione', 'Fecha de declaración', 'تاريخ كشف الحساب'];
export const GLOBAL_PICTURE = ['Photo', 'picture ', 'Foto', 'Foto', 'Foto', 'صورة فوتوغرافية'];
export const GLOBAL_OWNER = ['Propriétaire', 'Owner', 'Baasje', 'Proprietario', 'Dueño', 'صاحب'];
export const GLOBAL_BEFORE = ['Avant', ' Before', 'Voordat', 'Prima', 'Antes', 'قبل'];
export const GLOBAL_REMARKS = ['Remarques', 'Remarks', 'Opmerkingen', 'Osservazioni', 'Observaciones', 'ملاحظات'];
export const GLOBAL_AFTER = ['Après', ' After', 'Na', 'A seguito di', 'Después', 'بعد'];
export const GLOBAL_TIME_GROUP = ['Grouper par', 'Group by', 'Groeperen op', 'Raggruppa per', 'Agrupar por', 'مجموعة من'];
export const GLOBAL_EXPORT_ERROR_OCCURRED = ['Une erreur est survenue, l\'export a échoué', 'An error has occurred, the export failed', 'Er is een fout opgetreden, de export is mislukt', 'Si è verificato un errore, l\'esportazione non è riuscita', 'Ha ocurrido un error, la exportación falló', 'حدث خطأ ، فشل التصدير'];
export const GLOBAL_AGENDAS = ['Calendriers', ' Agendas', 'Kalenders', 'Calendari', 'calendarios', 'التقويمات'];
//#endregion

//#region QR Entity (QRE)
export const QRE_OLD_VAL = ['Dernière valeur', 'Last value', 'Laatste waarde', 'Ultimo valore', 'último valor', 'آخر قيمة'];
export const QRE_NEW_VAL = ['Nouvelle valeur', 'New value', 'Nieuwe waarde', 'Nuovo valore', 'Nuevo valor', 'قيمة جديدة'];
//#endregion

//#region Dia (DIA)
export const DIA_NO_DATA = ['Pas de données à afficher', ' No data to display', 'geen gegevens om weer te geven', 'nessun dato da visualizzare', 'no hay información para mostrar', 'لا توجد بيانات لعرض'];
export const DIA_CONFIRM_BUTTON = ['Confirmer', ' Confirm', 'Bevestigen', 'Per confermare', 'Confirmar', 'أكد'];
export const DIA_EDIT_MODE_BUTTON_ON = ['Quitter l\'édition', ' Leave the edit mode', 'Stop met bewerken', 'Esci dalla modifica', 'Salir de la edición', 'إنهاء/ تحرير'];
export const DIA_EDIT_MODE_BUTTON_OFF = ['Editer', ' Edit', 'Bewerking', 'Modificare', 'Editar', 'تحرير'];
export const DIA_ZOOM_PLUS_BUTTON = ['Agrandir', ' Zoom in', 'Vergroten', 'Ingrandire', 'Agrandar', 'تكبير'];
export const DIA_ZOOM_MINUS_BUTTON = ['Réduire', ' Zoom out', 'Verminderen', 'Ridurre', 'Reducir', 'تقليص'];
export const DIA_LINK_TYPES = ['Types de liens', ' Link Types', 'Soorten links', 'Tipi di link', 'Tipos de enlaces', 'أنواع الروابط'];
export const DIA_GROUP_LINK_TYPES = ['Groupes', 'group', 'Groepen', 'Gruppi', 'Grupos', 'مجموعات'];
export const DIA_ORIENTATION = ['Orientation', ' Orientation', ' Oriëntatie', 'Orientamento', 'Orientación', 'توجيه'];
export const DIA_NAME_SEARCH = ['Recherche par nom', ' Name search', 'Zoek op naam', 'Ricerca per nome', 'Buscar por nombre', 'البحث عن طريق الإسم'];
export const DIA_TEMPLATE_PICK = ['Affichage des objets', ' Objects Templates', 'Weergave van objecten', 'Esposizione di oggetti', 'Visualización de objetos', 'عرض الأشياء'];
export const DIA_SHOW_TAG_VALUES = ['Afficher les valeurs des compteurs', 'Display counter values', 'Tellerwaarden weergeven', 'Visualizza i valori del contatore', 'Mostrar valores de contador', 'عرض قيم العداد'];
export const DIA_LINK_CODE_HIDDEN = ['Caché', 'Hidden', 'Verborgen', 'Nascosto', 'Oculto', 'مختفي'];
export const DIA_LINK_CODE_EXT = ['Annotation', 'Annotation', 'annotatie', 'Annotazione', 'Anotación', 'ملاحظة'];
export const DIA_LINK_CODE_HERITAGE = ['Parenté', ' Kinship', 'Verwantschap', 'Parentela', 'Parentesco', 'القرابة'];
export const DIA_DOWNLOAD_PDF = ['Télécharger en PDF', ' Download as PDF', 'Downloaden als PDF', 'Scarica come PDF', 'Descargar como PDF', 'تنزيل ملف PDF'];
export const DIA_REVEAL_ALL = ['Afficher tout les noeuds caché', ' Reveal all hidden nodes', 'Toon alle verborgen knooppunten', 'Mostra tutti i nodi nascosti', 'Mostrar todos los nodos ocultos', 'إظهار كافة العقد المخفية'];
export const DIA_OBJ_TYPES = ['Types d\'objets', ' Object types', 'Soorten objecten', 'Tipi di oggetti', 'Tipos de objetos', 'أنواع الأشياء'];
export const DIA_TECH_DEPLASSE = ['Technique Deplasse', ' Deplasse  technique', 'Deplasse techniek', 'Tecnica deplasse', 'Técnica de deplasse', 'تقنية Deplasse'];
export const DIA_DESCEND_FROM = ['descend de', 'descends from', 'stamt af van', 'discende da', 'desciende de', 'ينزل من'];
//#endregion

//#region Event Reader (ER)
export const ER_DELETE_TICKETS_AS_WELL = ['Supprimer également les tickets associés ?', 'Also delete associated tickets?', 'Ook gekoppelde tickets verwijderen?', 'Eliminare anche i biglietti associati?', '¿Eliminar también las entradas asociadas?', 'هل تريد أيضًا حذف التذاكر المرتبطة؟'];
export const ER_DELETE_EVENT = ['Voulez-vous vraiment supprimer cet événement', 'Are you sure you want to delete this event?', 'Weet je zeker dat je deze afspraak wilt verwijderen?', 'Vuoi davvero eliminare questo evento?', '¿Realmente desea eliminar este evento?', 'هل أنت متأكد من حذف هذا الحدث؟'];
export const ER_TICKET_DELETE_WARNING = ['Cela supprimera également les tickets associés à cet événement', 'This will also delete the tickets associated with this event', 'Hiermee worden ook de tickets verwijderd die aan dit evenement zijn gekoppeld', 'Questo cancellerà anche i biglietti associati a questo evento', 'Esto también eliminará las entradas asociadas con este evento.', 'سيؤدي هذا أيضًا إلى حذف التذاكر المرتبطة بهذا الحدث'];
export const ER_CLOSED_BY = ['Clôturé par', 'Closed by', 'Gesloten door', 'Chiuso da', 'Cerrado por', 'أغلقت'];
export const ER_CLOSED_THE = ['Clôturé le', 'closes on', 'Gesloten op', 'Chiuso', 'Cerrado el', 'أغلقت يوم'];
export const ER_SIGNATURE = ['Signature', 'Signature', 'Handtekening', 'Firma', 'Firma', 'إمضاء'];
export const ER_DATES = ['Dates', 'dates', 'De datum', 'l\'appuntamento', 'las fechas', 'تواريخ'];
export const ER_HOST = ['Organisateur', ' Host', 'Organisator', 'Organizzatore', 'Organizador', 'منظم'];
export const ER_PARTICIPANTS = ['Participants', 'Attendees', 'deelnemers', 'Partecipanti', 'asistentes', 'الحاضرين'];
export const ER_EQUIP_FRAME = ['Equipement', 'Equipment', 'Apparatuur', 'Attrezzatura', 'Equipo', 'ادوات'];
export const ER_PICTURES = ['Photos', ' Pictures', 'Foto\'s', 'Immagini', 'Fotos', 'الصور'];
export const ER_NOTE_FRAME = ['Notes', 'Ratings', 'Waarderingen', 'Giudizi', 'Calificaciones', 'التقييمات'];
export const ER_CLOSE_TICKET_FRAME = ['Clôture', 'closing', 'Hek', 'Scherma', 'Esgrima', 'إغلا ق'];
export const ER_NO_TICKET_SELECTED = ['Aucun ticket sélectionner', 'No tickets selected', 'Geen tickets geselecteerd', 'Nessun biglietto selezionato', 'No hay boletos seleccionados', 'لم يتم اختيار تذاكر'];
export const ER_NO_ASSOCIATED_EQUIPMENT = ['Aucun équipement attribuer à ce ticket', 'No equipment assigned to this ticket', 'Geen uitrusting toegewezen aan dit ticket', 'Nessuna attrezzatura assegnata a questo biglietto', 'No hay equipo asignado a este boleto', 'لا توجد معدات مخصصة لهذه التذكرة'];
export const ER_CLOSE_ALL = ['Clôturer tout les tickets', 'Close all tickets', 'Sluit alle tickets', 'Chiudi tutti i biglietti', 'Cerrar todas las entradas', 'أغلق جميع التذاكر'];
//#endregion

//#region Covid Form Select (CFS)
export const CFS_FORM_ALREADY_EXISTS = ['Un formulaire existe déjà pour ce bâtiment / emplacement!', ' You already have a form for that building/zone!', 'Voor dit pand / deze locatie bestaat al een formulier!', 'Esiste già un modulo per questo edificio/località!', '¡Ya existe un formulario para este edificio / ubicación!', 'هناك صيغة موجودة للعملية / التمركز!'];
export const CFS_FORM_FILLED_WRONG = ['Veuillez remplir le formulaire correctement', ' Please fill the form correctly', 'Vul het formulier correct in', 'Si prega di compilare correttamente il modulo', 'Por favor complete el formulario correctamente', 'الرجاء إكمال النموذج بشكل صحيح'];
export const CFS_MAIN_TITLE = ['Formulaire Covid', ' Covid Form', 'Covid-formulier', 'Modulo Covid', 'Forma Covid', 'نموذج كوفيد'];
export const CFS_RESUME_EXISTING_FORM = ['Reprendre ou modifier un formulaire existant', ' Resume or modify an existing form', 'Een bestaand formulier hervatten of wijzigen', 'Riprendi o modifica un modulo esistente', 'Reanudar o modificar un formulario existente', 'استئناف أو تعديل نموذج موجود'];
export const CFS_NO_DATA = ['Pas de données', ' No Data', 'Geen informatie', 'Nessun dato', 'Sin datos', 'لايوجد بيانات'];
export const CFS_TITLE_FORM_DISPLAY_TREE = ['Ouvrir l\'arborescence', ' Display the tree', 'Open de boom', 'Apri l\'albero', 'Abre el arbol', 'افتح هيكل الشجرة'];
export const CFS_OPTIONAL_LABEL = ['Optionnel', ' Optional', 'Optioneel', 'Opzionale', 'Opcional', 'اختياري'];
export const CFS_ERROR_LOAD_EXISTING_COVID_FORM = ['Nous n\'avons pas su charger les formulaires existant, veuillez recharger la page', 'We were unable to load the existing forms, please reload the page', 'We konden de bestaande formulieren niet laden, laad de pagina opnieuw', 'Non è stato possibile caricare i moduli esistenti, ricaricare la pagina', 'No pudimos cargar los formularios existentes, vuelva a cargar la página.', 'لم نتمكن من تحميل النماذج الموجودة ، يرجى إعادة تحميل الصفحة'];
export const CFS_FAILED_DELETE = ['Une erreur est survenue, nous n\'avons pas pu supprimer ce formulaire', 'An error occured, we could not delete this form', 'Er is een fout opgetreden, we konden dit formulier niet verwijderen', 'Si è verificato un errore, non è stato possibile eliminare questo modulo', 'Ocurrió un error, no pudimos borrar este formulario', 'حدث خطأ ، لم نتمكن من حذف هذا النموذج'];
export const CFS_FORM_LOCKED_BY = ['Ce formulaire est déja ouvert par', ' This form is already open by', 'Dit formulier is al geopend door', 'Questo modulo è già stato aperto da', 'Este formulario ya está abierto por', 'هذا النموذج مفتوح بواسطة'];
export const CFS_DOWNLOAD_WORD = ['Télécharger sous format Word', ' Download as a Word document', 'Downloaden in Word-formaat', 'Scarica in formato Word', 'Descarga en formato Word', 'تحميل على الشكلword   '];
//#endregion

//#region Dashboard Ticket (DB)
export const DB_TICKETS_CURRENT_TIME = ['Intervalle de temps actuel', 'Current time interval', 'Huidig ​​tijdsinterval', 'Intervallo di tempo corrente', 'Intervalo de tiempo actual', 'الفاصل الزمني الحالي'];
export const DB_TICKETS_NO_TIME_RESTRICTION = ['Ne pas en tenir compte pour l\'export', 'Ignore it for export', 'Negeer het voor export', 'Ignoralo per l\'esportazione', 'Ignóralo para exportar', 'تجاهله للتصدير'];
//#endregion

//#region Covid From Question (CFQ)
export const CFQ_OBS_LABEL = ['Remarques', ' Remarks', 'Opmerkingen', 'Osservazioni', 'Observaciones', 'ملاحظات'];
export const CFQ_QUOT_MIN = ['MIN', ' MIN', 'MIN', 'MIN', 'MIN', 'الحد الأدنى'];
export const CFQ_QUOT_MAX = ['MAX', ' MAX', 'MAX', 'MAX', 'MAX', 'الحد أقصى'];
export const CFQ_QUOT_UNASSIGNED = ['N/A', ' N/A', 'N.v.t', 'N / A', 'N / A', 'لا جواب'];
export const CFQ_TITLE_AUTO_SAVE_NOTIF = ['Sauvegarde Automatique', ' Auto Save', 'Automatische back-up', 'Backup automatico', 'Copia de seguridad automática', 'النسخ الاحتياطي التلقائي'];
export const CFQ_TITLE_SAVE_NOTIF = ['Sauvegarde', ' Save', 'back-up', 'Backup', 'Respaldo', 'حفظ'];
export const CFQ_SAVED_MESSAGE_NOTIF = ['Les informations ont bien été sauvegardées', ' Your information were saved successfully', 'De informatie is opgeslagen', 'Le informazioni sono state salvate', 'La información ha sido guardada', 'تم حفظ المعلومات'];
export const CFQ_ERROR_FORM_LOAD = ['Une erreur est survenue lors du chargement des formulaires, veuillez reessayer', ' An error occured', 'Er is een fout opgetreden tijdens het laden van de formulieren, probeer het opnieuw', 'Si è verificato un errore durante il caricamento dei moduli, riprovare', 'Se produjo un error al cargar los formularios. Vuelva a intentarlo.', 'حدث خطأ أثناء تحميل النماذج ، يرجى المحاولة مرة أخرى'];
export const CFQ_NOTIF_FORM_LOCKED_TITLE = ['Formulaire verrouillé', ' Form Locked', 'Vergrendeld formulier', 'Modulo bloccato', 'Forma bloqueada', 'استمارة مغلقة '];
export const CFQ_NOTIF_FORM_LOCKED_MESSAGE = ['Ce formulaire est déjà ouvert par un autre utilisateur', 'This form is already opened by another user', 'Dit formulier is al geopend door een andere gebruiker', 'Questo modulo è già stato aperto da un altro utente', 'Este formulario ya está abierto por otro usuario', 'تم فتح هذا النموذج من قبل مستخدم آخر'];
//#endregion

//#region Benchmark graph (BG)
export const BG_Y_AXIS_MIN_LABEL = ['Min axe Y', ' Min Y axis', 'Min Y-as', 'Asse Y minimo', 'Min eje Y', 'الحد الأدنى للمحور Y'];
export const BG_Y_AXIS_MAX_LABEL = ['Max axe Y', ' Max Y axis', 'Max Y-as', 'Asse Y massimo', 'Eje Y máximo', 'الحد الأقصى لمحور Y.'];
export const BG_DEBIT_LABEL_TOOLTIP = ['Débit d\'air neuf par personne', 'Fresh air flow per person', 'Frisse luchtstroom per persoon', 'Flusso d\'aria fresca per persona', 'Flujo de aire fresco por persona', 'تدفق الهواء النقي لكل شخص'];
export const BG_OCCUPATION_LABEL_TOOLTIP = ['Capacité estimée du nombre de personne', 'Estimated capacity of the number of people', 'Geschatte capaciteit van het aantal personen', 'Capacità stimata del numero di persone', 'Capacidad estimada del número de personas', 'القدرة المقدرة لعدد الأشخاص'];
//#endregion

//#region Air renewal Graph (ARG)
export const ARG_MOY = ['Moyenne', 'Mean', 'Gemeen', 'Significare', 'Significar', 'متوسط'];
export const ARG_VAL = ['Taux de renouvellement d\'air', 'Air renewal rate', 'Luchtverversingssnelheid ', 'Tariffa rinnovo aria ', 'Tasa de renovación de aire ', 'معدل تجديد الهواء'];
export const ARG_ET_PLUS = ['Ecart Type +', 'Standard deviation +', 'Standaarddeviatie +', 'Deviazione standard +', 'Desviación estándar +', 'الانحراف المعياري +'];
export const ARG_ET_MINUS = ['Ecart Type -', 'Standard deviation -', 'Standaardafwijking -', 'Deviazione standard -', 'Desviación Estándar -', 'الانحراف المعياري -'];
//#endregion

//#region Schema - Canvas (SCH, CAN)
export const SCH_UNNAMED_SCHEMA = ['Sans titre', ' No title', 'Ongetiteld', 'Senza titolo', 'Sin título', 'بدون عنوان'];
export const SCH_SAVE_BEFORE_QUIT = ['Voulez-vous déjà sauvegarder les modifications non-enregistrées ?', ' Do you already want to save unsaved changes?', 'Wilt u niet-opgeslagen wijzigingen al opslaan?', 'Vuoi già salvare le modifiche non salvate?', '¿Ya quieres guardar los cambios no guardados?', 'هل تريد بالفعل حفظ التغييرات غير المحفوظة؟'];
export const SCH_FILE_TOO_LARGE = ['Un ou des fichier(s) sont trop volumineux', 'One or more files are too large', ' Een of meer bestanden zijn te groot', 'Uno o più file sono troppo grandi', 'Uno o más archivos son demasiado grandes', 'واحد أو أكثر من الملفات كبيرة جدًا'];
export const CAN_CLEAR = ['Réinitialiser', ' Clear', 'Resetten', 'Ripristina', 'Reiniciar', 'إعادة ضبط'];
export const CAN_UNDO = ['Annuler', ' Undo', 'annuleren', 'per cancellare', 'anular', 'الغاء'];
export const CAN_SAVE = ['Sauvergarder', ' Save', 'Opslaan', 'Salva', 'Ahorrar', 'حفظ'];
export const CAN_RELOAD = ['Recharger la dernière sauvegarde', ' Reload the last save', 'Laad de laatste save opnieuw', 'Ricarica l\'ultimo salvataggio', 'Recargar el último guardado', 'إعادة تحميل آخر حفظ'];
//#endregion

//#region Table Intervenants (TI)
export const TI_ROLE_LABEL = ['Rôle', ' Role', 'Rol', 'Ruolo', 'Papel', 'دور'];
export const TI_FIRM_LABEL = ['Firme', ' Firm', 'Stevig', 'Ditta', 'Firma', 'مؤسسة'];
export const TI_NAME_LABEL = ['Nom', ' Name', ' Naam', 'Nome', 'apellido', 'اسم'];
export const TI_MAIL_LABEL = ['Email', ' E-Mail', 'E-mail', 'E-mail', 'E-mail', 'بريد الالكتروني'];
export const TI_TEL_LABEL = ['Téléphone', ' Phone', 'Telefoon', 'Telefono', 'Teléfono', 'هاتف'];
export const TI_PRESENCE_LABEL = ['Présent', ' Present', 'Hier', 'Qui', 'Aquí', 'حاضر'];
//#endregion

//#region Question Observations (QO)
export const QO_REM = ['Commentaire', ' Comment', 'Opmerking', 'osservazione', 'Observación', 'ملاحظة'];
export const QO_NOTE = ['Note', ' Note', 'Opmerking', 'Nota', 'Nota', 'ملحوظة'];
export const QO_NEW_OBS = ['Nouvelle remarque', ' New remark', 'Nieuwe opmerking', ' Nuova osservazione', 'Nuevo comentario', 'ملاحظة جديدة'];
export const QO_EDIT_OBS = ['Modifier une remarque', ' Edit a remark', 'Een opmerking wijzigen', 'Modifica un commento', 'Modificar un comentario', 'تعديل ملاحظة'];
export const QO_ANOMALIES = ['Anomalies', ' Anomalies', 'Afwijkingen', 'anomalie', 'Anomalías', 'تشوهات'];
//#endregion

//#region Observations (OBST)
export const OBST_FLOOR = ['Etage', ' Floor', 'Fase', 'piano', 'Escenario', 'طابق'];
export const OBST_PLACE = ['Lieu', ' Location', 'Plaats', 'Luogo', 'Lugar', 'مكان'];
export const OBST_EQUIP = ['Equipement', ' Equipment', 'Apparatuur', 'Attrezzatura', 'Equipo', 'معدات'];
export const OBST_CONST = ['Constituant', 'Component', 'onderdeel', 'Componente', 'Componente', 'مكون'];
export const OBST_ACTION = ['Action', ' Action', 'Actie', 'Azione', 'Acción', 'حركة'];
export const OBST_PIC = ['Photos', ' Pictures', 'Foto\'s', 'Immagini', 'Imágenes', 'الصور'];
export const OBST_REM = ['Remarques', ' Remarks', 'Opmerkingen', 'Osservazioni', 'Observaciones', 'ملاحظات'];
//#endregion

//#region Vent Detail (VD)
export const VD_ADD_ROW = ['Ajouter une nouvelle entrée', ' Add a new entry', 'Een nieuw item toevoegen', 'Aggiungi una nuova voce', 'Agregar una nueva entrada', 'إضافة إدخال جديد'];
export const VD_CARACT = ['Caractéristiques', ' Characteristics', 'Kenmerken', 'Caratteristiche', 'Caracteristicas', 'مميزات'];
export const VD_SURFACE_BRUT = ['Surface brute', ' Gross area', 'Vies gebied', 'Area lorda', 'Área asquerosa', 'المساحة الإجمالية'];
export const VD_SURFACE_NET = ['Surface nette', ' Net area', ' Netto oppervlakte', 'Area netta', 'Area neta', 'المساحة الصافية'];
export const VD_THEORETICAL_DEBIT_PULSION = ['Débit nominal annoncé sur plan', 'Nominal flow announced on plan', 'Nominaal debiet aangekondigd op plan', 'Flusso nominale annunciato sul piano', 'Caudal nominal anunciado en el plan', 'أعلن التدفق الاسمي على الخطة'];
export const VD_PULSION = ['Débit nominal mesuré', 'Nominal measured flow', 'Nominaal gemeten debiet', 'Portata nominale misurata', 'Caudal nominal medido', 'التدفق المُقاس الاسمي'];
export const VD_EXTRACTION = ['Débit d\'extractio', 'Extraction rate', 'Afzuigsnelheid ', 'Tasso di estrazione', 'Tasa de extracción', 'معدل الاستخراج'];
export const VD_DEBIT = ['Débit d\'extraction sanitaire mesurés', ' Measured sanitary extraction flow', 'Gemeten sanitair afzuigdebiet ', 'Flusso di estrazione sanitario misurato', 'Caudal de extracción sanitaria medido', 'قياس تدفق الاستخراج الصحي'];
export const VD_OCCUPATION = ['Occupation (nombre maximum de personnes admises)', 'Occupancy (maximum number of people admitted)', 'Bezetting (maximaal aantal personen toegelaten)', 'Occupazione (numero massimo di persone ammesse)', 'Ocupación (número máximo de personas admitidas)', 'الإشغال (الحد الأقصى لعدد الأشخاص المقبولين)'];
export const VD_AIR_QUALITY = ['Qualité de l\'air par personne (Norme NBN EN 13779) ', 'Air quality per person (NBN EN 13779 standard)', 'Luchtkwaliteit per persoon (NBN EN 13779 norm) ', 'Qualità dell\'aria per persona(norma NBN EN 13779)', 'Calidad del aire por persona(norma NBN EN 13779) ', 'جودة الهواء لكل شخص(معيار NBN EN 13779)'];
export const VD_DEBIT_PER_PERS = ['Débit par personne (Norme NBN EN 13779)', ' Flow per person (NBN EN 13779 standard)', 'Stroom per persoon (NBN EN 13779 norm)', 'Portata per persona (norma NBN EN 13779)', 'Caudal por persona (norma NBN EN 13779)', 'التدفق لكل شخص (معيار NBN EN 13779)'];
export const VD_TOOLTIP_TOT_NORM = ['Calculée selon la somme des débits, sur le nombre d\'occupants', ' Calculated according to the sum of the flows, on the number of occupants', 'Berekend volgens de som van de stromen, op het aantal inzittenden', 'Calcolato in base alla somma dei flussi, sul numero degli occupanti', 'Calculado según la suma de los caudales, sobre el número de ocupantes', 'محسوبة وفقًا لمجموع التدفقات وعدد المقيمين'];
export const VD_CONFIRM_DELETE_VENT = ['Êtes-vous sûr de vouloir supprimer cette valeur ?', ' Are you sure you want to delete this entry ?', 'Weet u zeker dat u deze waarde wilt verwijderen?', 'Sei sicuro di voler eliminare questo valore?', '¿Está seguro de que desea eliminar este valor?', 'هل أنت متأكد أنك تريد حذف هذه القيمة؟'];
export const VD_ASTERISK_OCCUPATION = ['Valeur théorique calculée selon la surface nette', ' Theoretical value calculated according to the net area', ' Theoretische waarde berekend volgens de netto oppervlakte', 'Valore teorico calcolato in base all\'area netta', 'Valor teórico calculado según el área neta', 'القيمة النظرية تحسب حسب المساحة الصافية'];
export const VD_STRUCT_TREE = ['Arboresence structurelle', ' Structural tree', 'structurele boom', ' albero strutturale', 'Árbol estructural', 'شجرة هيكلية'];
export const VD_PULSE_TREE = ['Arborescence de la pulsion', ' Pulse Tree', 'Drive-boomstructuur', 'Struttura ad albero dell\'unità', 'Estructura del árbol de impulsión', 'محرك هيكل شجرة'];
export const VD_EXTRACTION_TREE = ['Arborescence de l\'extraction', ' Extraction Tree', 'Extractie boom ', 'Albero di estrazione ', 'Árbol de extracción ', 'شجرة الاستخراج'];
export const VD_THEORETICAL_SAN_DEBIT = ['Débit sanitaire annoncé', 'Announced sanitary flow', 'Aangekondigde sanitaire stroom', 'Flusso sanitario annunciato', 'Flujo sanitario anunciado', 'أعلن التدفق الصحي'];
export const VD_MEASURED_SAN_DEBIT = ['Débit sanitaire mesuré', 'Measured sanitary flow', 'Gemeten sanitaire stroom', 'Flusso sanitario misurato', 'Caudal sanitario medido', 'قياس التدفق الصحي'];
export const VD_RESTROOM_NUMBER = ['Quantité d\'urinoir', 'Urinal quantity', 'Urinoir aantal', 'Quantità dell\'orinatoio', 'Cantidad de urinarios', 'كمية المبولة'];
export const VD_NORM_EXIGENCE = ['Exigence norme NBN EN 13779', 'NBN EN 13779 standard requirement', 'NBN EN 13779 standaardvereiste', 'Requisito della norma NBN EN 13779', 'Requisito de la norma NBN EN 13779', 'المتطلبات القياسية NBN EN 13779'];
export const VD_EXTRACTION_QUALITY = ['Qualité d\'extraction', 'Extraction quality', 'Extractiekwaliteit', 'Qualità di estrazione', 'Calidad de extracción', 'جودة الاستخراج'];
export const VD_OCCUPATION_TH = ['Occupation théorique (1pers / 15m²)', ' Theoretical occupancy (1pers / 15m²)', 'Theoretische bezetting (1pers / 15m²)', 'Occupazione teorica (1persona / 15m²)', 'Ocupación teórica (1pers / 15m²)', 'الإشغال النظري (شخص / 15 م²)'];
export const VD_CALCULATE_DEFAULT = ['Calculer les valeurs par défaut', 'Calculate default values', 'Standaardwaarden berekenen', 'Calcola valori predefiniti', 'Calcular valores predeterminados', 'احسب القيم الافتراضية'];
export const VD_CONFIRM_DEFAULT_DATA = ['Voulez-vous vraiment appliquer les valeures par défaut ? Tout les données déja entrées seront effacées', 'Are you sure you want to apply the defaults? All data already entered will be erased', 'Weet u zeker dat u de standaardinstellingen wilt toepassen? Alle reeds ingevoerde gegevens worden gewist', 'Sei sicuro di voler applicare le impostazioni predefinite? Tutti i dati già inseriti verranno cancellati', '¿Está seguro de que desea aplicar los valores predeterminados? Se borrarán todos los datos ya ingresados', 'هل أنت متأكد أنك تريد تطبيق القيمة الافتراضية؟ سيتم مسح جميع البيانات التي تم إدخالها'];
export const VD_EDIT_ROW = ['Modifier une mesure', 'Modify a measurement', 'Een maat wijzigen', 'Modifica una misura', 'Modificar una medida', 'تعديل مقياس'];
export const VD_COPY_ROW = ['Copier une mesure', 'Copy a measurement', 'Kopieer een meting', 'Copia una misura', 'Copiar una medida', 'انسخ القياس'];
export const VD_DELETE_ROW = ['Supprimer une mesure', 'Delete a measure', 'Een maat verwijderen', 'Elimina una misura', 'Eliminar una medida', 'حذف مقياس'];
export const VD_SWITCH_ROW_PULSE = ['Passer en pulsion', 'Switch to impulse', 'Overschakelen naar impuls', 'Passa all\'impulso', 'Cambiar a impulso', 'التبديل إلى الدافع'];
export const VD_SWITCH_ROW_EXT = ['Passer en extraction', 'Switch to extraction', 'Overschakelen naar extractie', 'Passa all\'estrazione', 'Cambiar a extracción', 'قم بالتبديل إلى الاستخراج'];
//#endregion

//#region Time Selector (TS)
export const TS_MENU_BUTTON = ['Interval de temps', ' Time range', 'Tijdsinterval', 'Intervallo di tempo', 'Intervalo de tiempo', 'فاصل زمني'];
export const TS_ABSOLUTE_TIME = ['Plage de temps absolue', ' Absolute Time Range', 'Absoluut tijdsbereik', 'Intervallo di tempo assoluto', 'Rango de tiempo absoluto', 'النطاق الزمني المطلق'];
export const TS_RELATIVE_TIME = ['Plage de temps relative', ' Relative Time Range', 'Relatief tijdsbestek', 'Intervallo di tempo relativo', 'Rango de tiempo relativo', 'النطاق الزمني النسبي'];
export const TS_CONFIRM_ABSOLUTE = ['Appliquer la plage de temps absolue', ' Apply absolute time range', 'Absoluut tijdsbereik toepassen', 'Applica intervallo di tempo assoluto', 'Aplicar rango de tiempo absoluto', 'تطبيق النطاق الزمني المطلق'];
export const TS_GET_TAGS = ['Afficher les valeurs des compteurs', ' Display counter values', 'Tellerwaarden weergeven', 'Visualizza i valori del contatore', 'Mostrar valores de contador', 'عرض قيم العداد'];
export const TS_LAST_5_MIN = ['5 dernières minutes', 'Last 5 minutes', 'Laatste 5 minuten', 'Ultimi 5 minuti', 'Últimos 5 minutos', 'آخر 5 دقائق'];
export const TS_LAST_15_MIN = ['15 dernières minutes', 'Last 15 minutes', 'Laatste 15 minuten', 'Ultimi 15 minuti', 'Últimos 15 minutos', 'آخر 15 دقيقة'];
export const TS_LAST_30_MIN = ['30 dernières minutes', ' Last 30 minutes', 'Laatste 30 minuten', 'Ultimi 30 minuti', 'Últimos 30 minutos', 'آخر 30 دقيقة'];
export const TS_LAST_1_HOUR = ['Dernière heure', 'Last hour', 'Laatste uur', 'Ultima ora', 'Ultima hora', 'الساعة الأخيرة'];
export const TS_LAST_3_HOUR = ['3 dernières heures', 'Last 3 hours', 'Laatste 3 uur', 'Ultime 3 ore', 'Últimas 3 horas', 'آخر 3 ساعات'];
export const TS_LAST_6_HOUR = ['6 dernières heures', ' Last 6 hours', 'Laatste 6 uur', 'Ultime 6 ore', 'Últimas 6 horas', 'آخر 6 ساعات'];
export const TS_LAST_12_HOUR = ['12 dernières heures', ' Last 12 hours', 'Laatste 12 uur', 'Ultime 12 ore', 'Últimas 12 horas', 'آخر 12 ساعة'];
export const TS_LAST_24_HOUR = ['24 dernières heures', 'Last 24 hours', 'Laatste 24 uur', 'Ultime 24 ore', 'Últimas 24 horas', 'أخر 24 ساعه'];
export const TS_LAST_2_DAY = ['2 derniers jours', ' Last 2 days', 'Laatste 2 dagen', 'Ultimi 2 giorni', 'Últimos 2 días', 'آخر يومين'];
export const TS_LAST_7_DAY = ['7 derniers jours', ' Last 7 days', 'Laatste 7 dagen', 'Ultimi 7 giorni', 'Los últimos 7 días', 'اخر 7 ايام'];
export const TS_LAST_15_DAYS = ['15 derniers jours', 'last 15 days', 'laatste 15 dagen', 'ultimi 15 giorni', 'últimos 15 días', 'آخر 15 يومًا'];
export const TS_LAST_30_DAY = ['30 derniers jours', ' Last 30 days', 'Laatste 30 dagen', 'Ultimi 30 giorni', 'Últimos 30 días', 'آخر 30 يومًا'];
export const TS_LAST_90_DAY = ['90 derniers jours', 'Last 90 days', 'Laatste 90 dagen', 'Ultimi 90 giorni', 'Últimos 90 días', 'آخر 90 يومًا'];
export const TS_LAST_6_MONTH = ['6 derniers mois', 'Last 6 months', 'Laatste 6 maanden', 'Ultimi 6 mesi', 'Últimos 6 meses', 'آخر 6 أشهر'];
export const TS_LAST_1_YEAR = ['Dernière année', 'Last year', 'Vorig jaar', 'L\'anno scorso', 'El año pasado', 'العام الماضي'];
export const TS_LAST_2_YEAR = ['2 dernières années', 'Last 2 years', 'Laatste 2 jaar', 'Ultimi 2 anni', 'Últimos 2 años', 'آخر عامين'];
export const TS_LAST_5_YEAR = ['5 dernières années', 'Last 5 Years', 'Laatste 5 jaar', 'ultimi 5 anni', 'Últimos 5 años', 'آخر 5 سنوات'];
//#endregion

//#region User Form (UF)
export const UF_TECH_COMPANIES = ['Entreprise(s) du technicien', ' Technician\'s company(ies)', 'Technicusbedrijf(en)', 'Società del tecnico(i)', 'Empresa(s) del técnico', 'شركة(شركات) التقنيون '];
export const UF_RIGHTS = ['Droits', ' Rights', 'Rechten', 'diritti', 'Derechos', 'حقوق'];
//#endregion

//#region Notif (NOTIF, DIA_NOTIF)
export const NOTIF_NOTHING_IMPORT = ['Aucun nouvel élément', 'No new items', 'Geen nieuwe items', 'Nessun nuovo articolo', 'Sin elementos nuevos', 'لا توجد عناصر جديدة'];
export const NOTIF_NOTHING_IMPORT_MESSAGE = ['Il n\'y a rien à importer du fichier fournir', 'There is nothing to import from the provide file', 'Er valt niets te importeren uit het aangeleverde bestand', 'Non c\'è nulla da importare dal file di fornitura.', 'No hay nada que importar desde el archivo proporcionado.', 'لا يوجد شيء لاستيراده من ملف التزويد'];
export const NOTIF_EDIT_SUPER_ADMIN = ['Modification de Super Admin', 'Editing Super Admin', 'Superbeheerder bewerken', 'Modifica super amministratore', 'Edición de superadministrador', 'تحرير المشرف المتميز'];
export const NOTIF_EDIT_SUPER_ADMIN_MESSAGE = ['Veuillez passer par le service de form.io pour modifier un profil de Super admin', 'Please go through the form.io service to modify a Super admin profile', 'Ga door de form.io-service om een ​​superbeheerdersprofiel te wijzigen', 'Passa attraverso il servizio form.io per modificare un profilo di super amministratore', 'Vaya al servicio form.io para modificar un perfil de superadministrador', 'يرجى الانتقال إلى خدمة form.io لتعديل ملف تعريف المشرف المتميز'];
export const NOTIF_DIA_ERR_UPDATE_MESSAGE = ['Erreur mise à jour', ' Update error', 'Update fout', 'Errore di aggiornamento', 'Error de actualización', 'خطأ في التحديث'];
export const NOTIF_DIA_ERR_UPDATE_FETCH = ['Certaines infos n\'ont pas su être chargé depuis la base de données.', ' Some information could not be loaded from the database ......', 'Sommige informatie kon niet uit de database worden geladen......', 'Alcune informazioni non possono essere caricate dal database ......', 'Parte de la información no se pudo cargar desde la base de datos ...', 'تعذر تحميل بعض المعلومات من قاعدة البيانات ......'];
export const DIA_NOTIF_OFFLINE_ENTITY_TITLE = ['Compteurs Hors-Ligne', 'Offline Counters', 'Offline tellers', 'Contatori offline', 'Contadores sin conexión', 'عدادات غير متصل'];
export const DIA_NOTIF_OFFLINE_ENTITY = ['Certains compteurs sont hors-ligne depuis plus d\'une heure.', ' Some meters have been offline for more than an hour. ', 'Sommige meters staan ​​al meer dan een uur offline.', 'Alcuni contatori sono stati offline per più di un\'ora.', 'Algunos medidores han estado desconectados durante más de una hora.', 'ظلت بعض الأمتار غير متصلة بالإنترنت لأكثر من ساعة.'];
export const DIA_NOTIF_NO_TAG_TITLE = ['Aucun compteurs', 'No counters', 'Geen tellers', 'Nessun contatore', 'Sin contadores', 'لا عدادات'];
export const DIA_NOTIF_NO_TAG = ['Il n\'y a aucun compteur avec des données à aller chercher', 'There is no meter with data to fetch ', 'Er is geen meter met gegevens om op te halen', 'Non c\'è nessun contatore con dati da recuperare', 'No hay medidor con datos para buscar ', 'لا يوجد عداد مع البيانات لجلبها'];
export const DIA_NOTIF_ADD_LINK_TITLE = ['Créer un nouveau lien', 'Create a new link', 'Maak een nieuwe link', 'Crea un nuovo collegamento', 'Crea un enlace nuevo', 'قم بإنشاء ارتباط جديد'];
export const DIA_NOTIF_ADD_LINK = ['Faites un Shift + Double Clic sur le noeud à relier.Faites un clic droit pour annuler', ' Shift + Double Click on the node to connect. Right click to cancel', 'Shift + Dubbelklik op het knooppunt om verbinding te maken. rechts klikken om te annuleren', 'Maiusc + doppio clic sul nodo da connettere. Fare clic con il tasto destro per annullare', 'Shift + Haga doble clic en el nodo para conectarse. Haga clic derecho para cancelar', 'Shift + انقر نقرًا مزدوجًا فوق العقدة للاتصال. انقر بزر الماوس الأيمن للإلغاء'];
export const DIA_NOTIF_ANNOT_NO_CREATE_LINK_TITLE = ['Non disponible', ' Not available', 'Niet beschikbaar', 'Non disponibile', 'No disponible', 'غير متوفرة'];
export const DIA_NOTIF_ANNOT_NO_CREATE_LINK = ['Cette action n\'est pas possible lorsque des annotations sont à l\'écran. Veuillez cacher les annotations d\'abord', ' This action is not possible when annotations are on the screen.Please hide the annotations first', 'Deze actie is niet mogelijk wanneer er annotaties op het scherm staan.Verberg eerst de annotaties', 'Questa azione non è possibile quando le annotazioni sono sullo schermo.Per favore nascondi prima le annotazioni', 'Esta acción no es posible cuando hay anotaciones en la pantalla.Primero, oculte las anotaciones', 'هذا الإجراء غير ممكن عندما تكون التعليقات التوضيحية على الشاشة.الرجاء إخفاء التعليقات التوضيحية أولا'];
export const DIA_NOTIF_SAME_ID_TITLE = ['Non Autorisé', ' Unauthorized', 'ongeautoriseerd', 'non autorizzato', 'No autorizado', 'غير مسموح'];
export const DIA_NOTIF_SAME_ID = ['Il est impossible de faire un lien d\'un objet vers lui - même', 'It is impossible to link an object to itself', 'Het is onmogelijk om een ​​object aan zichzelf te koppelen', 'È impossibile collegare un oggetto a se stesso', 'Es imposible vincular un objeto consigo mismo.', 'من المستحيل ربط كائن بنفسه'];
export const DIA_NOTIF_ROOT_OUTPUT_TITLE = ['Non Autorisé', 'Unauthorized', 'ongeautoriseerd', 'non autorizzato', 'No autorizado', 'غير مسموح'];
export const DIA_NOTIF_ROOT_OUTPUT = ['La racine ne devrait pas être un output', 'The root should not be an output', 'De root mag geen uitvoer zijn', 'La radice non dovrebbe essere un output', 'La raíz no debe ser una salida.', 'يجب ألا يكون الجذر ناتجًا'];
export const DIA_NOTIF_NO_INFO_GRAPH_TITLE = ['Non disponible', 'Not available', 'Niet beschikbaar', 'Non disponibile', 'No disponible', 'غير متوفرة'];
export const DIA_NOTIF_NO_INFO_GRAPH = ['Il n\'a pas été possible de récupérer les information nécessaire à l\'ouverture du graphique', 'It was not possible to retrieve the information necessary to open the graph', 'Het was niet mogelijk om de benodigde informatie op te halen om de grafiek te openen', 'Non è stato possibile recuperare le informazioni necessarie per aprire il grafico', 'No fue posible recuperar la información necesaria para abrir el gráfico', 'لم يكن من الممكن استرداد المعلومات اللازمة لفتح الرسم البياني'];
export const DIA_NOTIF_NO_LINK_SELECTED_TITLE = ['Aucune sélection', 'No selection', 'Geen selectie', 'Nessuna selezione', 'Sin selección', 'لا اختيار'];
export const DIA_NOTIF_NO_LINK_SELECTED = ['Aucun lien n\'a été supprimé, car aucun n\'a été sélectionner', 'No link was deleted because none was selected', 'Er is geen link verwijderd omdat er geen is geselecteerd', 'Nessun collegamento è stato eliminato perché non ne è stato selezionato nessuno', 'No se eliminó ningún enlace porque no se seleccionó ninguno', 'لم يتم حذف أي روابط لأنه لم يتم تحديد أي روابط'];
export const DIA_NOTIF_NO_LINK_DESCENDANT_TITLE = ['Aucun lien trouvé', ' No link found', 'Geen link gevonden', 'Nessun collegamento trovato', 'No se encontró ningún enlace', 'لم يتم العثور على روابط'];
export const DIA_NOTIF_NO_LINK_DESCENDANT = ['Aucun noeud descendant de cet élement n\'a été trouvé', 'No descendant node of this element was found', 'Er is geen afstammelingsknooppunt van dit element gevonden', 'Nessun nodo discendente di questo elemento è stato trovato', 'No se encontró ningún nodo descendiente de este elemento', 'لم يتم العثور على عقدة تابعة لهذا العنصر'];
export const DIA_NOTIF_ONE_LINK_DESCENDANT_TITLE = ['Un seul lien trouvé', ' Only one link found', 'Slechts één link gevonden', 'Un solo link trovato', 'Solo se encontró un enlace', 'تم العثور على ارتباط واحد فقط'];
export const DIA_NOTIF_ONE_LINK_DESCENDANT = ['Il n\'y a qu\'un seul noeud descendant de cet élement, pensez plutôt à supprimer ce descendant.', 'There is only one descendant node of this element, think about deleting this descendant instead.', 'Er is maar één afstammelingknoop van dit element, denk erover om deze afstammeling in plaats daarvan te verwijderen.', 'Esiste un solo nodo discendente di questo elemento, considera invece l\'eliminazione di questo discendente.', 'Solo hay un nodo descendiente de este elemento, considere eliminar este descendiente en su lugar.', 'لا يوجد سوى عقدة فرعية واحدة لهذا العنصر ، فكر في حذف هذا التابع بدلاً من ذلك.'];
export const DIA_NOTIF_NO_OTHER_INPUT_TITLE = ['Pas d\'autre parent', 'No other parent', 'Geen andere ouder', 'Nessun altro genitore', 'Ningún otro padre', 'لا والد آخر'];
export const DIA_NOTIF_NO_OTHER_INPUT = ['Le lien ne peut pas être supprimé, car le descendant n\'a aucun autre parent.', 'The link cannot be removed because the descendant has no other parents.', 'De link kan niet worden verwijderd omdat de afstammeling geen andere ouders heeft.', 'Il collegamento non può essere rimosso perché il discendente non ha altri genitori.', 'El vínculo no se puede eliminar porque el descendiente no tiene otros padres.', 'لا يمكن إزالة الرابط لأن التابع ليس له آباء آخرون.'];
export const DIA_NOTIF_NO_BROWSER_SUPPORT_TITLE = ['Changer de navigateur', 'Change browser', 'Browser wijzigen', 'Cambia browser', 'Cambiar navegador', 'تغيير المتصفح'];
export const DIA_NOTIF_NO_BROWSER_SUPPORT = ['Ce navigateur ne supporte pas cette fonctionnalité, veuillez essayer avec un autre', 'This browser does not support this feature, please try with another', 'Deze browser ondersteunt deze functie niet, probeer het met een andere', 'Questo browser non supporta questa funzione, prova con un altro', 'Este navegador no es compatible con esta función, intente con otra', 'هذا المتصفح لا يدعم هذه الميزة ، يرجى المحاولة مع أخرى'];
export const DIA_NOTIF_FAKE_NODE_LINK_TITLE = ['Non-Autorisé', 'Unauthorized', 'ongeautoriseerd', 'non autorizzato', 'No autorizado', 'غير مسموح'];
export const DIA_NOTIF_FAKE_NODE_LINK = ['La création d\'un lien vers ce noeud n\'est pas autorisée.', 'Creating a link to this node is not allowed.', 'Het maken van een link naar dit knooppunt is niet toegestaan.', 'La creazione di un collegamento a questo nodo non è consentita.', 'No se permite crear un enlace a este nodo.', 'إنشاء ارتباط لهذه العقدة غير مسموح به.'];
export const DIA_NOTIF_TOO_MUCH_DATA = ['Il y a eu trop de données que pour pouvoir tout afficher en une fois. Veuillez recharger pour tout afficher.', 'There was too much data to be able to display it all at once. Please reload to view all.', 'Er waren te veel gegevens om alles in één keer te kunnen weergeven. Gelieve opnieuw te laden om alles te bekijken.', 'C\'erano troppi dati per poterli visualizzare tutti in una volta.Si prega di ricaricare per visualizzare tutto.', 'Había demasiados datos para poder mostrarlos todos a la vez.Vuelva a cargar para ver todo.', 'كان هناك الكثير من البيانات بحيث لا يمكن عرضها كلها مرة واحدة.الرجاء إعادة التحميل لعرض الكل'];
export const DIA_NOTIF_CHANGES_MADE_TITLE = ['Des changements ont étés effectués', ' Changes were made', 'Veranderingen zijn gemaakt', 'Sono state apportate modifiche', 'Se han realizado cambios', 'تم إجراء تغييرات'];
export const DIA_NOTIF_CHANGES_MADE = ['Le diagramme a subi des changements, veuillez rafraîchir pour les affichés', 'The diagram has undergone some changes, please refresh to display them', 'Het diagram heeft enkele wijzigingen ondergaan, ververs alstublieft om ze weer te geven', 'Il diagramma ha subito alcune modifiche, aggiorna per visualizzarle', 'El diagrama ha sufrido algunos cambios, actualice para mostrarlos', 'تم إجراء بعض التغييرات على الرسم التخطيطي ، يرجى التحديث لعرضها'];
export const DIA_NOTIF_TREE_LOCKED_TITLE = ['Diagramme déjà ouvert', 'Diagram already open', 'Schema al geopend', 'Diagramma già aperto', 'Diagrama ya abierto', 'الرسم التخطيطي مفتوح بالفعل'];
export const DIA_NOTIF_TREE_LOCKED = ['Le diagramme est ouvert pour édition par un autre utilisateur. Nous vous ferons savoir quand il sera disponible', ' The diagram is opened for editing by another user. We will let you know when it is available', 'Het diagram wordt geopend voor bewerking door een andere gebruiker. We laten je weten wanneer het beschikbaar is', 'Il diagramma viene aperto per la modifica da parte di un altro utente. Ti faremo sapere quando sarà disponibile', 'El diagrama se abre para que lo edite otro usuario. Te avisaremos cuando esté disponible', 'تم فتح الرسم التخطيطي للتحرير من قبل مستخدم آخر. سنخبرك عندما يكون متاحًا'];
export const DIA_NOTIF_EDIT_MODE_AVAILABLE_TITLE = ['Mode édition disponible', 'Edit mode available', 'Bewerkingsmodus beschikbaar', 'Modalità di modifica disponibile', 'Modo de edición disponible', 'وضع التحرير متاح'];
export const DIA_NOTIF_EDIT_MODE_AVAILABLE = ['Vous êtes désormais autorisé à passer en mode édition sur ce diagramme', 'You are now allowed to switch to edit mode on this diagram', 'U mag nu overschakelen naar de bewerkingsmodus op dit diagram', 'Ora puoi passare alla modalità di modifica su questo diagramma', 'Ahora puede cambiar al modo de edición en este diagrama.', 'يُسمح لك الآن بالتبديل إلى وضع التحرير في هذا الرسم التخطيطي'];
export const DIA_NOTIF_KICKED_OUT_TITLE = ['Perte du mode édition', 'Loss of edit mode', 'Verlies van bewerkingsmodus', 'Perdita della modalità di modifica', 'Pérdida del modo de edición', 'فقدان وضع التحرير'];
export const DIA_NOTIF_KICKED_OUT = ['Vous avez perdu le droit d\'édition pour avoir été inactif trop longtemps', 'You lost your editing rights for being inactive for too long', 'Je bent je bewerkingsrechten kwijt omdat je te lang inactief bent geweest', 'Hai perso i diritti di modifica per essere rimasto inattivo per troppo tempo ', 'Perdiste tus derechos de edición por estar inactivo durante demasiado tiempo ', 'لقد فقدت حقوق التحرير لكونك غير نشط لفترة طويلة'];
export const NOTIF_CLIPBOARD_TITLE = ['Lien Copié !', ' Link Copied !', 'Link gekopieerd!', 'Collegamento copiato!', '¡Enlace copiado!', 'تم نسخ الرابط!'];
export const NOTIF_CLIPBOARD = ['Le lien a été copié avec succès', 'The link was successfully copied', 'De link is succesvol gekopieerd', 'Il collegamento è stato copiato correttamente', 'El enlace se copió con éxito.', 'تم نسخ الارتباط بنجاح'];
//#endregion

//#region Grafana PopUp (GP)
export const GP_ELEC = ['Électricité', 'electricity', 'Elektriciteit', 'Elettricità', 'Electricidad', 'كهرباء'];
export const GP_ELEC_PPV = ['Photovoltaique', 'Photovoltaic', 'Fotovoltaïsche', 'Fotovoltaico', 'fotovoltaica', 'الكهروضوئية'];
export const GP_WATER = ['Eau', 'water', 'Water', 'Acqua', 'Agua', 'ماء'];
export const GP_GAS = ['Gaz', 'Gas', 'Gas', 'Gas', 'Gas', 'غاز'];
export const GP_THCH = ['Thermique Chaud', 'Thermal Hot', 'Thermisch Heet', 'Caldo Termico', 'Térmica Caliente', 'حراري ساخن'];
export const GP_THFR = ['Thermique Froid', 'Cold Thermal', 'Koud Thermisch', 'Freddo Termico', 'Térmica Fría', 'بارد حرارى'];
export const GP_FUEL = ['Mazout', 'fuel oil', 'brandstof', 'carburante', 'gasolina', 'زيت الوقود'];
export const GP_GROUP_MIN = ['15 minutes', '15 minutes', '15 minuten', '15 minuti', '15 minutos', '15 دقيقة'];
export const GP_GROUP_HOUR = ['Heure', 'Hour', 'Tijd', 'Volta', 'Hora', 'زمن'];
export const GP_GROUP_DAY = ['Jour', 'day', 'Dag', 'Giorno', 'Día', 'يوم'];
export const GP_GROUP_WEEK = ['Semaine', 'week', 'Week', 'Settimana', 'Semana', 'أسبوع'];
export const GP_GROUP_MONTH = ['Mois', 'month', 'Maand', 'Mese', 'Mes', 'شهر'];
export const GP_GROUP_YEAR = ['Année', 'year', 'Jaar', 'Anno', 'Año', 'عام'];
export const GP_BUILD_TAB_GLOBAL = ['Global', 'Global', 'Globaal', 'Globale', 'Global', 'عالمي'];
export const GP_BUILD_TAB_COST = ['Coût', 'Cost', 'Kosten', 'Costo', 'Costo', 'كلفة'];
export const GP_BUILD_TAB_ENERGY = ['Différences', 'Differences', 'Verschillen', 'Differenze', 'diferencias', 'اختلافات'];
export const GP_BUILD_TAB_HEATMAP = ['HeatMap', 'heat map', 'warmtekaart', 'mappa di calore', 'mapa de calor', 'خريطة الحرارة'];
export const GP_BUILD_TAB_ENERGY_SIGN = ['Signature Énergétique', 'Energy Signature', 'Energiehandtekening', 'Firma energetica', 'Firma de energía', 'توقيع الطاقة'];
export const GP_BUILD_TAB_ENTITY = ['Compteurs', 'Counters', 'Tellers', 'Contatori', 'Contadores', 'عدادات'];
//#endregion

//#region Audio Recorder (AR)
export const AR_VOICE_RECORDER = ['Enregistreur audio', ' Voice Recorder', 'Audiorecorder', 'Registratore audio', 'Grabadora de audio', 'مسجل الصوت'];
export const AR_SAVE_RECORDING = ['Sauver l\'enregistrement', ' Save Recording', 'record opslaan', 'salva record', 'guardar registro', 'حفظ السجل'];
export const AR_START_RECORDING = ['Démarrer l\'enregistrement', ' Start Recording', 'Start met opnemen', 'Inizia a registrare', 'Iniciar la grabación', 'ابدأ التسجيل'];
export const AR_CANCEL_RECORDING = ['Annuler l\'enregistrement', ' Cancel Recording', 'Registratie annuleren', 'Annulla la registrazione', 'Cancelar registro', 'إلغاء التسجيل'];
export const AR_MENU_RECORDING = ['Afficher les enregistrements', ' Show Recordings', 'Opnames weergeven', 'Mostra registrazioni', 'Mostrar grabaciones', 'عرض التسجيلات'];
export const AR_DELETE_RECORD = ['Supprimer l\'enregistrement', ' Delete record', 'Record verwijderen', 'Elimina record', 'Eliminar el registro', 'حذف سجل'];
export const AR_NO_RECORDS = ['Aucuns enregistrements', ' No records', 'Geen records', 'Nessuna registrazione', 'No hay registros', 'لا تسجيلات'];
export const AR_YOUR_RECORDS = ['Vos enregistrements', ' Your records', 'Uw opnames', 'Le tue registrazioni', 'tus grabaciones', 'التسجيلات الخاصة بك'];
//#endregion

//#region Build Resume (BR)
export const BR_NOTIF_ERROR_CREATION = ['Une erreur est survenue, la création du bâtiment n\'a pas être faite', 'An error occurred, the building was not created', 'Er is een fout opgetreden, het gebouw is niet gemaakt', 'Si è verificato un errore, l\'edificio non è stato creato', 'Ocurrió un error, el edificio no fue creado', 'حدث خطأ ، لم يتم إنشاء المبنى'];
export const BR_ERROR_ENTERPRISE = ['Aucune entreprise n\'a été spécifiée', 'No company has been specified', 'Er is geen bedrijf opgegeven', 'Nessuna azienda è stata specificata', 'No se especificó ninguna empresa', 'لم يتم تحديد شركة'];
export const BR_ERROR_SITE = ['Aucun site n\'a été spécifié', 'No site has been specified', 'Er is geen site opgegeven', 'Nessun sito è stato specificato', 'No se ha especificado ningún sitio', 'لم يتم تحديد موقع'];
export const BR_ERROR_BUILD_NAME = ['Aucun nom n\'a été renseigné pour le bâtiment', 'No name has been entered for the building', 'Er is geen naam ingevoerd voor het gebouw', 'Nessun nome è stato inserito per l\'edificio', 'No se ha introducido ningún nombre para el edificio.', 'لم يتم إدخال اسم للمبنى'];
export const BR_ERROR_BUILD_DETAILS = ['Certains champ n\'ont pas été remplit correctement', 'Some fields have not been filled in correctly', 'Sommige velden zijn niet correct ingevuld', 'Alcuni campi non sono stati compilati correttamente', 'Algunos campos no se han completado correctamente.', 'لم يتم ملء بعض الحقول بشكل صحيح'];
export const BR_ERROR_LOCATION_NAME = ['Le nom spécifié est incorrect', 'The name specified is incorrect', 'De opgegeven naam is onjuist', 'Il nome specificato non è corretto', 'El nombre especificado es incorrecto', 'الاسم المحدد غير صحيح'];
export const BR_ERROR_LOCATION_REF = ['La référence spécifiée est incorrecte', ' The reference specified is incorrect', 'De opgegeven referentie is onjuist', 'Il riferimento specificato non è corretto', 'La referencia especificada es incorrecta', 'المرجع المحدد غير صحيح'];
export const BR_ERROR_LOCATION_TYPE = ['Le type spécifié est incorrect', 'The specified type is incorrect', 'Het opgegeven type is onjuist', 'Il tipo specificato non è corretto', 'El tipo especificado es incorrecto', 'النوع المحدد غير صحيح'];
export const BR_ERROR_GP_GE_UNLOCATED = ['La location d\'un ou des GP(s) / GE(s) n\'a pas été spécifiée', 'The rental of one or more GP (s) / GE (s) has not been specified', 'De verhuur van één of meerdere huisarts(en)/GE(s) is niet gespecificeerd', 'Il noleggio di uno o più GP (s) / GE (s) non è stato specificato', 'No se ha especificado el alquiler de uno o más GP (s) / GE (s)', 'لم يتم تحديد استئجار واحد أو أكثر من الممارسين العامين) / GEns)'];
export const BR_ERROR_GP_GE_DISTRIB = ['Certains locaux / Etages n\'ont pas été entrés', 'Some rooms / Floors have not been entered', 'Sommige kamers / verdiepingen zijn niet betreden', 'Alcune stanze / piani non sono state inserite', 'No se han ingresado algunas habitaciones / pisos', 'لم يتم إدخال بعض الغرف / الطوابق'];
export const BR_ERROR_DEFAULT_BOUCHE = ['Aucun equipement par défaut n\'a été spécifié pour ce type : ', 'No default equipment has been specified for this type: ', 'Er is geen standaarduitrusting gespecificeerd voor dit type: ', 'Nessuna attrezzatura predefinita è stata specificata per questo tipo: ', 'No se ha especificado ningún equipo predeterminado para este tipo: ', 'م يتم تحديد معدات افتراضية لهذا النوع: '];
export const BR_ERROR_UNSPECIFIED_HOPPER = ['Une ou plusieurs trémie n\'ont pas eu de GP spécifié', 'One or more hopper did not have a GP specified', 'Een of meer hoppers hadden geen GP gespecificeerd', 'Uno o più hopper non avevano un GP specificato', 'Una o más tolvas no tenían un GP especificado', 'لم يتم تحديد GP لواحد أو أكثر من النطاطات'];
export const BR_CREATE_BUILD = ['Créer le bâtiment', ' Create building', 'Maak het gebouw', 'Crea l\'edificio', 'Crea el edificio', 'قم بإنشاء المبنى'];
export const BR_BUILDING_NAME = ['Nom du bâtiment', ' Building name', 'naam van gebouw', 'nome dell\'edificio', 'nombre del edificio', 'اسم المبني'];
export const BR_HEAD_ENTITIES = ['Compteurs de tête', ' Head entities', 'Hoofdtellers', 'Contatori di testa', 'Contadores de cabezas', 'عدادات الرأس'];
export const BR_TAB_LABEL_GENERAL = ['Général', ' Summary', 'Algemeen', 'Generale', 'General', 'عام'];
export const BR_TAB_LABEL_DETAILS = ['Détails', ' Details', 'Details', 'Particolari', 'Detalles', 'تفاصيل'];
export const BR_TAB_LABEL_FLOORS = ['Étages', ' Floors', 'vloeren', 'Piani', 'Pisos', 'طوابق'];
export const BR_TAB_LABEL_GP_GE = ['Groupe de pulsion & extraction', ' GP & GE', 'Aandrijfeenheid & afzuiging', 'Unità di azionamento ed estrazione', 'Unidad de accionamiento y extracción', 'وحدة التوريد والاستخراج'];
export const BR_TAB_LABEL_HOPPERS = ['Trémies', ' Hoppers', 'Hoppers', 'tramogge', 'Tolvas', 'النطاطات'];
export const BR_TAB_LABEL_BOUCHE = ['Bouche de pulsion & extraction', ' BP & BE', 'Puls mond & extractie', ' Bocca a impulsi ed estrazione', 'Pulso de boca y extracción', 'نبض وخلع فتحة'];
export const BR_FLOOR_NB = ['Nombre d\'étage', ' Number of floors', 'Aantal verdiepingen', 'Numero di piani', 'Numero de pisos', 'عدد الطوابق'];
export const BR_FLOOR_START_AT = ['Démarrer à l\'étage numéro', ' Start at floor number', 'Begin bij verdieping nummer', 'Inizia dal numero del piano', 'Comience en el número de piso', 'ابدأ من الطابق رقم'];
export const BR_FLOOR_NAME_FORMAT = ['Format du nom par défaut', ' Default name format', 'Standaard naamformaat', 'Formato nome predefinito', 'Formato de nombre predeterminado', 'تنسيق الاسم الافتراضي'];
export const BR_FLOOR_REF_FORMAT = ['Format de la référence par défaut', ' Default reference format', 'Standaard referentieformaat', 'Formato di riferimento predefinito', 'Formato de referencia predeterminado', 'تنسيق المرجع الافتراضي'];
export const BR_FLOOR_FORMAT_TOOLTIP = [' représente le numéro de l\'étage.', '  represents the floor number.', 'staat voor het verdiepingsnummer.', 'rappresenta il numero del piano.', 'representa el número de piso.', 'يمثل رقم الطابق.'];
export const BR_FLOOR_FORMAT_INCORRECT = ['Le format n\'est pas valide, le numéro d\'étage ne sera pas affiché.', 'The format is not valid, the floor number will not be displayed.', 'Het formaat is niet geldig, het verdiepingsnummer wordt niet weergegeven.', 'Il formato non è valido, il numero del piano non verrà visualizzato.', 'El formato no es válido, no se mostrará el número de piso.', 'التنسيق غير صالح ، ولن يتم عرض رقم الطابق.'];
export const BR_FLOOR_DEFAULT_ICON = ['Icône par défaut', ' Default Icon', 'Standaardpictogram', 'Icona predefinita', 'Icono predeterminado', 'الرمز الافتراضي'];
export const BR_FLOOR_DEFAULT_COLOR = ['Couleur par défaut', ' Default color', 'Standaardkleur', 'Colore predefinito', 'Color predeterminado', 'اللون الافتراضي'];
export const BR_FLOOR_HAS_RESTROOM = ['Dispose de sanitaires', ' Restroom present', 'Heeft sanitair', 'Dispone di servizi igienici', 'Tiene instalaciones sanitarias', 'يوجد بها مرافق صحية'];
export const BR_FLOOR_NB_RESTROOM = ['Nombre de sanitaires', ' Number of restrooms', 'Aantal toiletten', 'Numero di bagni', 'Cantidad de baños', 'عدد الحمامات'];
export const BR_FLOOR_DEFAULT_AREA = ['Superficie par défaut d\'un étage', ' Default floor area', 'Standaard vloeroppervlak', 'Area del pavimento predefinita', 'Área de piso predeterminada', 'مساحة الأرضية الافتراضية'];
export const BR_FLOOR_REINITIALIZE = ['Réinitialiser les étages', 'Reset floors', 'Verdiepingen resetten', 'Ripristina piani', 'Restablecer pisos', 'إعادة تعيين الأرضيات'];
export const BR_GP_DELETE_MESSAGE = ['Êtes-vous sûr de vouloir supprimer cette valeur ? ', 'Are you sure you want to delete this value?', 'Weet u zeker dat u deze waarde wilt verwijderen?', 'Sei sicuro di voler eliminare questo valore?', '¿Está seguro de que desea eliminar este valor?', 'هل أنت متأكد أنك تريد حذف هذه القيمة؟'];
export const BR_GP_GP = ['Groupes de pulsion', 'Drive groups', 'Drive-groepen', 'Gruppi di unità', 'Grupos de conducción', 'مجموعات القيادة'];
export const BR_GP_GE = ['Groupes d\'extraction', 'Extraction groups', 'Extractiegroepen', 'Gruppi di estrazione', 'Grupos de extracción', 'مجموعات الاستخراج'];
export const BR_GP_LOCATION = ['Emplacement', ' Location', 'Standplaats', 'Posto', 'Sitio', 'موقع'];
export const BR_GP_DISTRIBUTION = ['Distribue à ', 'Distributes to', 'Distribueert naar', 'Distribuisce a', 'Distribuye a', 'يوزع على'];
export const BR_GP_ADD_GP = ['Créer un nouveau groupe de pulsion', 'Create a new drive group', 'Een nieuwe aandrijfgroep maken', ' Crea un nuovo gruppo di unità', 'Crear un nuevo grupo de pulsos', 'قم بإنشاء مجموعة قيادة جديدة'];
export const BR_GP_ADD_GE = ['Créer un nouveau groupe d\'extraction', 'Create a new extraction group', 'Een nieuwe extractiegroep maken', 'Crea un nuovo gruppo di estrazione', 'Crear un nuevo grupo de extracción', 'قم بإنشاء مجموعة استخراج جديدة'];
export const BR_B_DEFAULT_EQUIP = ['Définir l\'équipement par défaut pour une', 'Define the default equipment for a', 'Definieer de standaarduitrusting voor a', 'Definire l\'equipaggiamento predefinito per a', 'Establecer el equipo predeterminado para una', 'تحديد المعدات الافتراضية لـ'];
export const BR_B_RECALCULATE = ['Recalculer les valeures', 'Recalculate values', 'Herbereken waarden', 'Ricalcola valori', 'Volver a calcular los valores', 'عادة حساب القيم'];
export const BR_B_AREA = ['Superficie', ' Area', 'Gebied', 'La zona', 'Zona', 'مساحة'];
export const BR_B_NB_EQUIP = ['Nombre d\'équipements', 'Number of equipments', 'Aantal apparatuur', 'Numero di apparecchiature', 'Numero de equipos', 'عدد المعدات'];
export const BR_B_AUTO_CREATE = ['Création automatique', 'Automatic creation', 'Automatisch aanmaken', 'Creazione automatica', 'Creación automática', 'إنشاء تلقائي'];
export const BR_B_NB_EQUIP_AUTO_CREATE = ['Nombre d\'équipement créer automatiquement', 'Number of equipment created automatically(20 %)', 'Aantal automatisch aangemaakte apparatuur(20 %)', 'Numero di attrezzature create automaticamente(20 %)', 'Número de equipos creados automáticamente(20 %)', 'عدد المعدات التي تم إنشاؤها تلقائيًا(20٪)'];
export const BR_B_GP_ASSOCIATED = ['Groupe de pulsion associé', 'Associated drive group', 'Bijbehorende aandrijfgroep', 'Gruppo di azionamento associato', 'Grupo de accionamientos asociado', 'مجموعة القيادة المرتبطة'];
export const BR_B_PULSE_BOUCHE = ['Bouche de pulsion', 'Pulse mouth', ' Puls mond', 'Bocca a impulsi', 'Pulso de boca', 'نبض الفُتْحَة'];
export const BR_B_EXT_BOUCHE = ['Bouche d\'extraction', 'Extraction mouth', 'extractie mond', 'Bocca di estrazione', 'Boca de extracción', 'شفط الفُتْحَة'];
export const BR_B_PULSE = ['Pulsion', 'Drive', 'Rit', 'Unità', 'Conducir', 'قيادة'];
export const BR_B_EXT = ['Extraction', 'Extraction', 'extractie', 'Estrazione', 'Extracción', 'استخلاص'];
export const BR_B_VIRTUAL_PULSE = ['Gaine de pulsion', 'Pulse duct', 'Pulskanaal', 'Condotto di impulso', 'Conducto de pulso', 'قناة النبض'];
export const BR_B_VIRTUAL_EXT = ['Gaine d\'extraction', ' Extraction sheath', 'extractie schede: ', 'Guaina di estrazione', 'Vaina de extracción', 'غمد الاستخراج'];
export const BR_B_HOPPER_PULSE = ['Trémie de pulsion', 'Pulse hopper', 'Puls hopper', 'Tramoggia di impulsi', 'Tolva de pulsos', 'قادوس النبض'];
export const BR_B_HOPPER_EXT = ['Trémie d\'extraction', 'Extraction hopper ', 'Afzuigbunker', 'Tramoggia di estrazione ', 'Tolva de extracción ', 'قادوس الاستخراج'];
export const BR_B_ADD_HOPPER_PULSE = ['Ajouter une trémie de pulsion', 'Add a pulse hopper', 'Voeg een pulshopper toe', 'Aggiungi una tramoggia di impulsi', 'Agregue una tolva de pulso', 'أضف وعاء النبض'];
export const BR_B_ADD_HOPPER_EXT = ['Ajouter une trémie d\'extraction', 'Add an extraction hopper ', 'Voeg een afzuigbunker toe ', 'Aggiungi una tramoggia di estrazione ', 'Agregue una tolva de extracción ', 'إضافة قادوس استخراج'];
export const BR_B_HOPPER_INPUT = ['Reçoit de', 'Receives from', 'Ontvangt van', 'Riceve da', 'Recibe de', 'يستقبل من'];
export const BR_B_HOPPER_OUTPUT = ['Envoie à', 'Receives from', 'Verzenden naar', 'Inviare a', 'Enviar a', 'ارسل إلى'];
//#endregion

//#region DEFAULT FORMIO MESSAGES
const DEFAULT_FORMIO_FR = {
    "error": "Veuillez corriger les erreurs suivantes avant de soumettre. ",
    "invalid_date": "{{field}} n'est pas une date valide.",
    "invalid_email": "{{field}} doit être une adresse mail valide.",
    "invalid_regex": "{{field}} ne correspond pas au modèle {{regex}}.",
    "mask": "{{field}} ne correspond pas au masque.",
    "max": "{{field}} ne peut pas être plus grand que {{max}}.",
    "maxLength": "{{field}} doit faire moins de {{length}} caractères.",
    "min": "{{field}} ne peut pas être plus petit que {{min}}.",
    "minLength": "{{field}} doit faire plus de {{length}} caractères",
    "next": "Précédent",
    "pattern": "{{field}} ne correspond pas au modèle {{pattern}}",
    "previous": "Suivant",
    "required": "{{field}} est requis"
};

const DEFAULT_FORMIO_EN = {
    "error": "Please fix the following errors before submitting.",
    "invalid_date": "{{field}} is not a valid date.",
    "invalid_email": "{{field}} must be a valid email.",
    "invalid_regex": "{{field}} does not match the pattern {{regex}}.",
    "mask": "{{field}} does not match the mask.",
    "max": "{{field}} cannot be greater than {{max}}.",
    "maxLength": "{{field}} must be shorter than {{length}} characters.",
    "min": "{{field}} cannot be less than {{min}}.",
    "minLength": "{{field}} must be longer than {{length}} characters.",
    "next": "Next",
    "pattern": "{{field}} does not match the pattern {{pattern}}",
    "previous": "Previous",
    "required": "{{field}} is required"
};

const DEFAULT_FORMIO_DU = {
    "error": "todo",
    "invalid_date": "{{field}} todo",
    "invalid_email": "{{field}} todo",
    "invalid_regex": "{{field}} todo {{regex}}.",
    "mask": "{{field}} todo",
    "max": "{{field}} todo {{max}}.",
    "maxLength": "{{field}} todo {{length}}",
    "min": "{{field}} todo {{min}}.",
    "minLength": "{{field}} todo {{length}}",
    "next": "todo",
    "pattern": "{{field}} todo {{pattern}}",
    "previous": "todo",
    "required": "{{field}} todo"
};

const DEFAULT_FORMIO_IT = {
    "error": "todo",
    "invalid_date": "{{field}} todo",
    "invalid_email": "{{field}} todo",
    "invalid_regex": "{{field}} todo {{regex}}.",
    "mask": "{{field}} todo",
    "max": "{{field}} todo {{max}}.",
    "maxLength": "{{field}} todo {{length}}",
    "min": "{{field}} todo {{min}}.",
    "minLength": "{{field}} todo {{length}}",
    "next": "todo",
    "pattern": "{{field}} todo {{pattern}}",
    "previous": "todo",
    "required": "{{field}} todo"
};

const DEFAULT_CREATE_FORMIO_TRANSLATE_OBJ = (fr, en, du, it) => {
    let translation = {};
    translation[Languages.FR.toString()] = Object.assign(DEFAULT_FORMIO_FR, fr);
    translation[Languages.EN.toString()] = Object.assign(DEFAULT_FORMIO_EN, en);
    translation[Languages.DU.toString()] = Object.assign(DEFAULT_FORMIO_DU, du);
    translation[Languages.IT.toString()] = Object.assign(DEFAULT_FORMIO_IT, it);
    return translation;
}
//#endregion

//#region ConfirmOpenTree
const ConfirmOpenTree_FR = {
    "Do you want to see or edit this structure in the editor ?": "Voulez vous afficher ou éditer cette structure dans l'éditeur ?",
    "Yes": "Oui",
    "No": "Non"
};
const ConfirmOpenTree_EN = {
    "Do you want to see or edit this structure in the editor ?": "Do you want to see or edit this structure in the editor ?",
    "Yes": "Yes",
    "No": "No"
};
const ConfirmOpenTree_DU = {
    "Do you want to see or edit this structure in the editor ?": "Wilt u deze structuur in de editor zien of bewerken?",
    "Yes": "Ja",
    "No": "Nee"
};
const ConfirmOpenTree_IT = {
    "Do you want to see or edit this structure in the editor ?": "Vuoi vedere o modificare questa struttura nell'editor?",
    "Yes": "Sì",
    "No": "No"
};

export const FORMIO_CONFIRM_OPEN_TREE = DEFAULT_CREATE_FORMIO_TRANSLATE_OBJ(ConfirmOpenTree_FR, ConfirmOpenTree_EN, ConfirmOpenTree_DU, ConfirmOpenTree_IT);
//#endregion