import * as UserService from '../services/user.service';
import _ from 'lodash';

/**
 * A function that creates multiple links and rights for those links in the diagram
 * @param {[{input : string, output : string, type : string}]} links An array of new links
 * @param {{_id : string, data : {name ?: string}}} user The user that created those links
 * @returns True or null
 */
const AttachMultipleObjParents = async (links = [], user = null) => {
    //#region Functions
    const createLinks = async (links) => {
        let filter = links.map(({ input, output, type }) => {
            return {
                updateOne: {
                    filter: { input, output, type },
                    update: { input, output, type },
                    upsert: true,
                }
            };
        });

        try {
            let replyLinks = await UserService.upsertManyLinks(filter);
            console.log('Upsert Links : ', replyLinks);
            if (replyLinks.data?.ok) return true;
        }
        catch (err) {
            try {
                // Handle Payload too large
                let promises = [];
                while (filter.length > 0) promises.push(UserService.upsertManyLinks(filter.splice(0, 25)));
                let temp = await Promise.all(promises);
                console.log("Upsert Links en plusieurs fois : ", _.flatten(temp.map(({ data }) => data)));
                return true;
            }
            catch (err) {
                console.error("Unable to create many links.\n", err);
                return null;
            }
        }
    }

    const createPerms = async (links) => {
        let replyPerms = await UserService.getManySubmissionsFromFilter({
            $or: links.map(({ input }) => input).concat(links.map(({ output }) => output)).map(id => {
                return { 'data.idObj': id };
            })
        });

        if (Array.isArray(replyPerms.data)) {
            let perms = _.uniq(links.map(({ input }) => input).concat(links.map(({ output }) => output))).map(id => {
                return getPermParent(links.filter(({ output }) => output === id).map(({ input }) => input), id, links, replyPerms.data, id);
            });

            try {
                //Insert many
                let replyInsert = await UserService.createManySubmissions(perms);
                console.log('Insert Submissions', replyInsert);
                return (Array.isArray(replyInsert.data));
            }
            catch (err) {
                try {
                    // Handle Payload too large
                    let promises = [];
                    while (perms.length > 0) promises.push(UserService.createManySubmissions(perms.splice(0, 15)));
                    let temp = await Promise.all(promises);
                    console.log("Insert Submissions en plusieurs fois : ", _.flatten(temp.map(({ data }) => data)));
                    return _.flatten(temp.map(({ data }) => data));
                }
                catch (err) {
                    console.error("Unable to create many Submissions.\n", err);
                    return null;
                }
            }
        }
        else return null;
    }

    const getPermParent = (parents, child, links, perms, ogChild, alreadySearched = []) => {
        if (Array.isArray(parents) && parents.length > 0) {
            if (perms.filter(p => parents.includes(p.data.idObj)).length > 0) {
                //Deep copy the object
                let copy = Object.assign({}, perms.filter(p => parents.includes(p.data.idObj))[0]);
                copy.data = Object.assign({}, perms.filter(p => parents.includes(p.data.idObj))[0].data);
                //delete the id
                _.unset(copy, '_id');
                //Change the owner
                copy.owner = user._id;
                //Change the id of the node it's linked to
                copy.data.idObj = ogChild;

                return copy;
            }
            else {
                let results;
                for (let parent of parents) {
                    if (!results) {
                        if (!alreadySearched.includes(parent)) results = getPermParent(links.filter(({ output }) => output === parent).map(({ input }) => input), parent, links, perms, ogChild, alreadySearched);
                    }
                    else return results;
                }
                return results;
            }
        }
        //Create a new perm object
        return newPerm(ogChild);
    }

    const newPerm = (id) => ({
        form: replyFormId.data._id,
        owner: user._id,
        deleted: null,
        data: {
            //Les permissions de groupes
            permGroup: [{
                //Les groupes
                permGroupGroup: [],
                //Les permissions pour ces groupes
                permGroupPerm: []
            }],
            //Les permissions des users
            permUser: [{
                //Les user
                permUserUser: [{
                    _id: user._id,
                    data: {
                        name: user.data.name
                    }
                }],
                //Les permissions pour ces users
                permUserPerm: [],
            }],
            idObj: id
        },
        roles: [],
        access: [],
        externalIds: [],
        created: new Date(),
        modified: new Date(),
    })
    //#endregion

    if (!Array.isArray(links) || !user?._id) return null;

    //Create the links
    let newLinksAdded = await createLinks(links);
    if (!newLinksAdded) return null;

    //Get somme necessary infos
    let replyFormId = await UserService.getPermissionsFormId();

    if (replyFormId.data) return await createPerms(links);
    else return null;
}

export default AttachMultipleObjParents;