import _ from "lodash";
import moment from "moment";
import ReactDom from "react-dom";
import PopUpForms from "../PopUpForms";
import LightTree from "../../NewDia/LightTree";
import * as Text from "../../../Constants/text";
import { TB, EC, FP, LT } from "../../../Constants";
import { useSelector } from "react-redux";
import * as S from "../../../services/user.service";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import AttachMultipleObjParents from "../../../helpers/AttachMultipleObjParents";
import { askSelect, Confirm, Loader, ErrorModal, askTime, askPrompt } from "../../Modal";
import { ReduxWrapper, Form } from "../../../Common";

const CellPanel = ({ client, site, bails, cells, addEntity, relatedLinks, linkTypes, forms, addNote, onUpdateDescendants, onUpdateTree, onUpdateBails, ...props }) => {
    const modalRef = useRef();
    const auth = useSelector(({ auth }) => auth);
    const [location, setLocations] = useState([]);
    const [activeCell, setActiveCell] = useState();
    const [{ user, isAdmin }, setUser] = useState({});
    const [isRentCell, setIsRentCell] = useState(true);
    const language = useSelector(({ language }) => language);
    const [developedBuild, setDevelopedBuild] = useState([]);

    //#region User
    useEffect(() => auth.then(setUser), [auth]);
    //#endregion

    //#region Forms
    const clientFormId = useMemo(() => forms?.filter?.(({ path }) => path === FP.CLIENT_FORM)?.[0]?._id, [forms]);
    const enseigneFormId = useMemo(() => forms?.filter?.(({ path }) => path === FP.ENSEIGNE_FORM)?.[0]?._id, [forms]);
    const emplacementFormId = useMemo(() => forms?.filter?.(({ path }) => path === FP.EMPLACEMENT_FORM)?.[0]?._id, [forms]);
    //#endregion

    //#region LinkTypes
    const ownLinkType = useMemo(() => linkTypes?.filter?.(({ data }) => data.type === LT.LINK_TYPE_OWN)?.[0]?._id, [linkTypes]);
    const rentalLinkType = useMemo(() => linkTypes?.filter?.(({ data }) => data.type === LT.LINK_TYPE_RENTAL)?.[0]?._id, [linkTypes]);
    // const enseigneLinkType = useMemo(() => linkTypes?.filter?.(({ data }) => data.type === LT.LINK_TYPE_ENSEIGNE)?.[0]?._id, [linkTypes]);
    const exploitationLinkType = useMemo(() => linkTypes?.filter?.(({ data }) => data.type === LT.LINK_TYPE_EXPLOITATION)?.[0]?._id, [linkTypes]);
    //#endregion

    //#region Utilities
    const cellsList = useMemo(() => Array.isArray(cells) ? cells : [], [cells]);
    const bailsList = useMemo(() => Array.isArray(bails) ? bails : [], [bails]);
    const links = useMemo(() => Array.isArray(relatedLinks) ? relatedLinks : [], [relatedLinks]);

    const cellsBailsClients = useMemo(() => cellsList.map(({ _id, children, parents, ...r }) => {
        let cellClients = parents.filter(({ form }) => form === clientFormId)
            .filter(client => links.filter(({ input, output, type }) => input === client?._id && output === _id && type === rentalLinkType).length > 0);

        let exploiter = parents.filter(({ form }) => form === clientFormId)
            .filter(ent => links.filter(({ input, output, type }) => input === ent?._id && output === _id && type === exploitationLinkType).length > 0)?.[0];

        let clients = cellClients.map(c => {
            let clientLinks = links.filter(({ input, output, type }) => input === c?._id && output === _id && type === rentalLinkType).map(({ _id }) => _id).filter(TB.mongoIdValidator);
            let bail = bailsList.filter(({ data }) => clientLinks.includes(data.link))?.[0];
            return { ...c, bail };
        });

        if (clients.length === 0) clients = [{}];
        return { _id, children, clients, exploiter, parents, ...r };
    }), [cellsList, links, bailsList, clientFormId, rentalLinkType, exploitationLinkType]);

    const showErrorModal = useCallback(errorCode =>
        new Promise(resolve => ReactDom.render(<ErrorModal onQuit={resolve} language={language} errorCode={errorCode} />, modalRef.current))
            .then(() => ReactDom.unmountComponentAtNode(modalRef.current))
        , [language]);
    //#endregion

    //#region Location
    const locationsEntityId = useMemo(() => location.filter(({ error }) => !error).map(({ id }) => id), [location]);

    const locationObj = useMemo(() => Object.fromEntries(
        location.map(({ id, building, emplacement, local, parking, error }) => [id, { building, emplacement, local, parking, error }])
            .map(([key, obj]) => {
                if (obj?.error) return [key, Object.fromEntries(Object.keys(obj).map(key => [key, { data: { name: "ERROR" } }]))];
                let filterObject = Object.fromEntries(Object.entries(obj).map(([key, array]) => [key, array?.[0] ?? array]));
                return [key, filterObject];
            })
    ), [location]);

    const getEmplacement = useCallback(id => {
        let keys = ["parking", "local", "emplacement"];
        let value = keys.map(key => locationObj?.[id]?.[key]?.data?.name).filter(TB.validString)?.[0];
        return TB.validString(value) ? value : "";
    }, [locationObj]);

    useEffect(() => {
        let isSubscribed = true;

        let toSearch = cellsList.map(({ _id }) => _id).filter(id => !locationsEntityId.includes(id));
        if (toSearch.length > 0) S.itemLocater(toSearch).then(({ data }) => {
            if (!isSubscribed) return;
            if (Array.isArray(data)) {
                let foundIds = data.map(({ id }) => id);

                if (data.length > 0) setLocations(prev => prev.filter(({ id }) => !foundIds.includes(id)).concat(data));
                else setLocations(prev => prev.concat(toSearch.filter(({ id }) => !foundIds.includes(id)).map(id => ({ id }))));
            }
            else setLocations(prev => _.uniqBy(prev.concat(toSearch.map(id => ({ id, error: true }))), "id"));
        })

        return () => isSubscribed = false;
    }, [cellsList, locationsEntityId]);
    //#endregion

    //#region Buildings
    const buildings = useMemo(() => _.uniqBy(cellsList.map(({ building }) => building).filter(b => TB.mongoIdValidator(b?._id)), "_id"), [cellsList]);
    const buildLabelObj = useMemo(() => Object.fromEntries(buildings.map(({ _id, data }) => [_id, data?.name])), [buildings]);
    //#endregion

    //#region Enseignes
    const enseignes = useMemo(() => _.uniqBy(cellsList.map(({ enseigne }) => enseigne).filter(e => TB.mongoIdValidator(e?._id)), "_id"), [cellsList]);
    const enseigneLabelObj = useMemo(() => Object.fromEntries(enseignes.map(({ _id, data }) => [_id, data?.name])), [enseignes]);
    //#endregion

    //#region Callbacks
    const siteId = useMemo(() => site?._id, [site]);
    const stringedDate = useCallback(date => isNaN(Date.parse(date)) ? '' : moment(date).format("DD/MM/YYYY"), [])

    const onChangeCell = useCallback(async (_id, key, val) => {
        let update = Object.fromEntries([["data." + key, val]]);
        let reply = await S.updateSubmission(_id, update);
        if (TB.mongoIdValidator(reply?.data?._id)) onUpdateDescendants?.(prev => prev.map(n => {
            if (n._id === _id) n.data[key] = val;
            return n;
        }));
        else showErrorModal(EC.CODE_DB_UPDATE_FAIL);
    }, [onUpdateDescendants, showErrorModal]);

    const onClickPromptCell = useCallback((_id, key, oldVal) => {
        askPrompt({ isRequired: true, defaultVal: oldVal }).then(val => {
            if (TB.validString(val)) onChangeCell(_id, key, val);
        });
    }, [onChangeCell]);

    const onClickPromptOrSelect = useCallback((_id, formId, key, oldVal, cellId) => {
        let title = (formId === enseigneFormId ? Text.GLOBAL_LABEL_ENSEIGNE : Text.GLOBAL_LABEL_RENTER);

        // Select other or rename current
        if (TB.mongoIdValidator(_id)) {
            let changePromise = new Promise(resolve => ReactDom.render(<Confirm
                text={"Renommer ou choisir autre déja existant"}
                title={title[language]}
                language={language}
                yesText={"renommer"}
                onValidate={resolve}
                onQuit={() => resolve(null)}
                extraOptions={<button onClick={() => resolve(undefined)} className="btn btn-primary">Select other</button>}
            />, modalRef.current));

            changePromise.then(async confirm => {
                ReactDom.unmountComponentAtNode(modalRef.current);
                // Rename
                if (confirm) onClickPromptCell(_id, key, oldVal);
                // Select other
                else if (confirm === undefined) {
                    ReactDom.render(<Loader />, modalRef.current);
                    let replyValues = await S.advancedSearch(client, undefined, false, undefined, formId, true);
                    ReactDom.unmountComponentAtNode(modalRef.current);

                    if (!Array.isArray(replyValues.data)) showErrorModal(EC.CODE_LOAD_DATA_FAIL);
                    else {
                        let options = replyValues.data.sort(TB.sortByNameSubmission).map(({ _id, data }) => ({ value: _id, label: data?.name }));
                        askSelect({ isRequired: true, defaultVal: _id, title: title[language], options }).then(async newVal => {
                            if (TB.mongoIdValidator(newVal) && newVal !== _id) {
                                let oldLink = { input: _id, output: cellId, type: rentalLinkType }; //OK
                                // if (formId === enseigneFormId) oldLink = { input: _id, output: cellId, type: enseigneLinkType }; //TODO update cellId par locataire id

                                let newLink = { input: newVal, output: cellId, type: rentalLinkType }; //OK
                                // if (formId === enseigneFormId) newLink = { input: newVal, output: cellId, type: enseigneLinkType }; //TODO update cellId par locataire id
                                let replyUpdate = await S.updateManyLinks(oldLink, newLink);
                                if (replyUpdate?.data?.ok === 1) onUpdateTree?.(({ descendance, links }) => ({
                                    descendance,
                                    links: links.map(link => {
                                        if (link.input === oldLink.input && link.output === oldLink.output && link.type === oldLink.type) return newLink;
                                        return link;
                                    })
                                }));
                                else showErrorModal(EC.CODE_DB_UPDATE_FAIL);
                            }
                        })
                    }
                }
            });
        }
        // Create a new one
        else {
            let formPromise = new Promise(resolve => ReactDom.render(<ReduxWrapper>
                <PopUpForms
                    sendBackFullObj
                    handleForm={resolve}
                    title={title[language]}
                    idForm={formId}
                />
            </ReduxWrapper>, modalRef.current));

            formPromise.then(async submission => {
                ReactDom.unmountComponentAtNode(modalRef.current);
                if (TB.mongoIdValidator(submission?._id)) {
                    let newLink = { input: submission?._id, output: cellId, type: rentalLinkType };
                    // if (formId === enseigneFormId) newLink = { input: submission?._id, output: cellId, type: enseigneLinkType };//TODO update cellId par locataire id

                    let reply = await AttachMultipleObjParents([newLink], user);
                    if (reply) onUpdateTree?.(({ descendance, links }) => ({
                        descendance: descendance.concat(submission),
                        links: links.concat(newLink)
                    }));
                    else {
                        S.removeSubmission(submission._id);
                        showErrorModal(EC.CODE_DB_UPDATE_FAIL)
                    };
                }
            });
        }
    }, [language, enseigneFormId, rentalLinkType, user, /* enseigneLinkType, */ onClickPromptCell, showErrorModal, onUpdateTree, client]);

    const onClickExploiter = useCallback(async (id, oldVal, cellId) => {
        // Select other or rename current
        if (TB.mongoIdValidator(id)) {
            let changePromise = new Promise(resolve => ReactDom.render(<Confirm
                text="Renommer ou choisir autre déja existant"
                title="Exploitant"
                yesText="Renommer"
                language={language}
                onValidate={resolve}
                onQuit={() => resolve(null)}
                extraOptions={<button onClick={() => resolve(undefined)} className="btn btn-primary">Select other</button>}
            />, modalRef.current));

            changePromise.then(async confirm => {
                ReactDom.unmountComponentAtNode(modalRef.current);
                // Rename
                if (confirm) onClickPromptCell(id, "name", oldVal);
                // Select Other
                else if (confirm === undefined) {
                    ReactDom.render(<Loader />, modalRef.current);
                    let replyValues = {};
                    if (isAdmin) replyValues = await S.getManySubmissionsFromFilter({ form: clientFormId }, 1000);
                    else replyValues = await S.accessibleNodesUser(user._id, undefined, clientFormId, false, false);
                    ReactDom.unmountComponentAtNode(modalRef.current);

                    if (!Array.isArray(replyValues.data)) showErrorModal(EC.CODE_LOAD_DATA_FAIL);
                    else {
                        let options = replyValues.data.sort(TB.sortByNameSubmission).map(({ _id, data }) => ({ value: _id, label: data?.name }));
                        askSelect({ isRequired: true, defaultVal: id, title: "Exploitant", options }).then(async newVal => {
                            if (TB.mongoIdValidator(newVal) && newVal !== id) {
                                let oldLink = { input: id, output: cellId, type: exploitationLinkType };
                                let newLink = { input: newVal, output: cellId, type: exploitationLinkType };

                                let replyUpdate = await S.updateManyLinks(oldLink, newLink);
                                if (replyUpdate?.data?.ok === 1) onUpdateTree?.(({ descendance, links }) => ({
                                    descendance,
                                    links: links.map(link => {
                                        if (link.input === oldLink.input && link.output === oldLink.output && link.type === oldLink.type) return newLink;
                                        return link;
                                    })
                                }));
                                else showErrorModal(EC.CODE_DB_UPDATE_FAIL);
                            }
                        });
                    }
                }
            });
        }
        // Create a new one
        else if (TB.multiMongoIdValidator([activeCell, rentalLinkType, clientFormId])) {
            ReactDom.render(<Loader />, modalRef.current);
            let replyValues = {};
            if (isAdmin) replyValues = await S.getManySubmissionsFromFilter({ form: clientFormId }, 1000);
            else replyValues = await S.accessibleNodesUser(user?._id, undefined, clientFormId, false, false);
            ReactDom.unmountComponentAtNode(modalRef.current);

            if (Array.isArray(replyValues.data)) {
                let options = replyValues.data.map(({ _id, data }) => ({ label: data?.name, value: _id }));
                askSelect({ title: "Exploitant", noSelectionText: Text.CREAT_NEW[language], options }).then(async renter => {
                    // Link the existing renter to the cell
                    if (TB.mongoIdValidator(renter)) {
                        let link = { input: renter, output: activeCell, type: exploitationLinkType };
                        ReactDom.render(<Loader />, modalRef.current);
                        let reply = await AttachMultipleObjParents([link], user);
                        let sub = await S.getSubmission(renter);
                        ReactDom.unmountComponentAtNode(modalRef.current);

                        if (reply === true && TB.mongoIdValidator(sub?.data?._id)) onUpdateTree(({ links, descendance }) => ({
                            links: links.concat(link),
                            descendance: descendance.filter(({ _id }) => _id !== renter).concat(sub.data),
                        }));
                        else showErrorModal(EC.CODE_DB_UPDATE_FAIL);
                    }
                    // Create a new renter
                    else if (renter === undefined) {
                        let formPromise = new Promise(resolve => ReactDom.render(<ReduxWrapper>
                            <PopUpForms
                                sendBackFullObj
                                title="Exploitant"
                                handleForm={resolve}
                                pathForm={FP.CLIENT_FORM}
                            />
                        </ReduxWrapper>, modalRef.current));

                        formPromise.then(async renter => {
                            ReactDom.unmountComponentAtNode(modalRef.current);
                            if (TB.mongoIdValidator(renter?._id)) {
                                let link = { input: renter, output: activeCell, type: exploitationLinkType };
                                ReactDom.render(<Loader />, modalRef.current);
                                let reply = await AttachMultipleObjParents([link], user);
                                ReactDom.unmountComponentAtNode(modalRef.current);
                                if (reply === true) onUpdateTree(({ links, descendance }) => ({
                                    links: links.concat(link),
                                    descendance: descendance.concat(renter)
                                }));
                                else showErrorModal(EC.CODE_DB_UPDATE_FAIL);
                            }
                        });
                    }
                });
            }
            else showErrorModal(EC.CODE_LOAD_DATA_FAIL);
            /* let formPromise = new Promise(resolve => ReactDom.render(<Provider store={store}>
                <PopUpForms sendBackFullObj title="Exploitant" handleForm={resolve} pathForm={FP.CLIENT_FORM} />
            </Provider>, modalRef.current));
 
            formPromise.then(async submission => {
                ReactDom.unmountComponentAtNode(modalRef.current);
                if (TB.mongoIdValidator(submission?._id)) {
                    let link = { input: submission._id, output: cellId, type: exploitationLinkType };
                    let reply = await AttachMultipleObjParents([link], user);
                    if (reply) onUpdateTree?.(({ descendance, links }) => ({
                        descendance: descendance.concat(submission),
                        links: links.concat(link)
                    }));
                    else {
                        S.removeSubmission(submission._id);
                        showErrorModal(EC.CODE_DB_UPDATE_FAIL)
                    }
                }
            }) */
        }
    }, [clientFormId, exploitationLinkType, isAdmin, language, activeCell, rentalLinkType, user, onClickPromptCell, showErrorModal, onUpdateTree]);

    const onClickBail = useCallback((cellId, clientId, bailId, key, oldVal) => {
        // Create a new Bail
        if (!TB.mongoIdValidator(bailId)) {
            let affectation = cellsList.filter(({ _id }) => _id === cellId)?.[0]?.data?.actifType ?? undefined;
            let link = links.filter(({ input, output, type }) => input === clientId && output === cellId && type === rentalLinkType)?.[0]?._id;

            let formPromise = new Promise(resolve => ReactDom.render(<ReduxWrapper>
                <PopUpForms
                    sendBackFullObj
                    handleForm={resolve}
                    pathForm={FP.BAIL_FORM}
                    submission={{ affectation, link }}
                    title={Text.GLOBAL_LABEL_BAIL[language]}
                />
            </ReduxWrapper>, modalRef.current));

            formPromise.then(bail => {
                ReactDom.unmountComponentAtNode(modalRef.current);
                if (TB.mongoIdValidator(bail?._id)) onUpdateBails?.(prev => prev.concat(bail));
            });
        }
        // Update an existing one
        else {
            let promise;

            if (["start", "end"].includes(key)) promise = askTime({ required: true, date: oldVal });
            else if (key === "break") promise = askPrompt({
                isRequired: true,
                defaultVal: oldVal,
                isNumberPrompt: true,
                numberProps: { min: 0 },
            });
            else promise = askPrompt({ isRequired: true, defaultVal: oldVal });

            promise.then(async newVal => {
                ReactDom.unmountComponentAtNode(modalRef.current);
                if (newVal !== null) {
                    let update = Object.fromEntries([["data." + key, newVal]]);
                    let reply = await S.updateSubmission(bailId, update);
                    if (TB.mongoIdValidator(reply?.data?._id)) onUpdateBails?.(prev => prev.map(b => {
                        if (b._id === bailId) b.data[key] = newVal;
                        return b;
                    }))
                    else showErrorModal(EC.CODE_DB_UPDATE_FAIL);
                }
            });
        }
    }, [cellsList, language, links, rentalLinkType, showErrorModal, onUpdateBails]);

    const onAddRenter = useCallback(async () => {
        if (TB.multiMongoIdValidator([activeCell, rentalLinkType, clientFormId])) {
            ReactDom.render(<Loader />, modalRef.current);
            let replyValues = {};
            if (isAdmin) replyValues = await S.getManySubmissionsFromFilter({ form: clientFormId }, 1000);
            else replyValues = await S.accessibleNodesUser(user?._id, undefined, clientFormId, false, false);
            ReactDom.unmountComponentAtNode(modalRef.current);

            if (Array.isArray(replyValues.data)) {
                let options = replyValues.data.map(({ _id, data }) => ({ label: data?.name, value: _id }));
                askSelect({ title: "Locataire", noSelectionText: Text.CREAT_NEW[language], options }).then(async renter => {
                    // Link the existing renter to the cell
                    if (TB.mongoIdValidator(renter)) {
                        let link = { input: renter, output: activeCell, type: rentalLinkType };
                        ReactDom.render(<Loader />, modalRef.current);
                        let reply = await AttachMultipleObjParents([link], user);
                        let sub = await S.getSubmission(renter);
                        ReactDom.unmountComponentAtNode(modalRef.current);

                        if (reply === true && TB.mongoIdValidator(sub?.data?._id)) onUpdateTree(({ links, descendance }) => ({
                            links: links.concat(link),
                            descendance: descendance.filter(({ _id }) => _id !== renter).concat(sub.data),
                        }));
                        else showErrorModal(EC.CODE_DB_UPDATE_FAIL);
                    }
                    // Create a new renter
                    else if (renter === undefined) {
                        let formPromise = new Promise(resolve => ReactDom.render(<ReduxWrapper>
                            <PopUpForms
                                sendBackFullObj
                                title="Locataire"
                                handleForm={resolve}
                                pathForm={FP.CLIENT_FORM}
                            />
                        </ReduxWrapper>, modalRef.current));

                        formPromise.then(async renter => {
                            ReactDom.unmountComponentAtNode(modalRef.current);
                            if (TB.mongoIdValidator(renter?._id)) {
                                let link = { input: renter, output: activeCell, type: rentalLinkType };
                                ReactDom.render(<Loader />, modalRef.current);
                                let reply = await AttachMultipleObjParents([link], user);
                                ReactDom.unmountComponentAtNode(modalRef.current);
                                if (reply === true) onUpdateTree(({ links, descendance }) => ({
                                    links: links.concat(link),
                                    descendance: descendance.concat(renter)
                                }));
                                else showErrorModal(EC.CODE_DB_UPDATE_FAIL);
                            }
                        });
                    }
                });
            }
            else showErrorModal(EC.CODE_LOAD_DATA_FAIL);
        }
    }, [activeCell, rentalLinkType, clientFormId, isAdmin, language, user, showErrorModal, onUpdateTree]);

    const addCell = useCallback(() => {
        let treePromise = new Promise(resolve => ReactDom.render(<LightTree
            popUpSize
            root={siteId}
            title="Select parent"
            restrictOwnLinks
            onValidate={resolve}
            onClose={() => resolve(null)}
            linkRestriction={{ objForm: emplacementFormId, linkType: LT.LINK_TYPE_OWN, isInput: false }}
        />, modalRef.current));

        treePromise.then(item => {
            ReactDom.unmountComponentAtNode(modalRef.current)
            if (TB.mongoIdValidator(item)) {
                let sub = { isRentable: isRentCell, type: "local" };

                let formPromise = new Promise(resolve => ReactDom.render(<ReduxWrapper>
                    <PopUpForms
                        sendBackFullObj
                        submission={sub}
                        handleForm={resolve}
                        pathForm={FP.EMPLACEMENT_FORM}
                        title={Text.GLOBAL_LABEL_EMPLACEMENT[language]}
                    />
                </ReduxWrapper>, modalRef.current));

                formPromise.then(async cell => {
                    ReactDom.unmountComponentAtNode(modalRef.current);
                    if (TB.mongoIdValidator(cell?._id)) {
                        ReactDom.render(<Loader />, modalRef.current);
                        let newLink = { input: item, output: cell._id, type: ownLinkType };
                        let reply = await AttachMultipleObjParents([newLink], user);
                        ReactDom.unmountComponentAtNode(modalRef.current);
                        console.log(reply);
                        if (reply === true) onUpdateTree(({ descendance, links }) => ({
                            descendance: descendance.concat(cell),
                            links: links.concat(newLink),
                        }));
                        else {
                            S.removeSubmission(cell._id);
                            showErrorModal(EC.CODE_DB_UPDATE_FAIL);
                        }
                    }
                })
            }
        });
    }, [siteId, emplacementFormId, isRentCell, language, ownLinkType, user, showErrorModal, onUpdateTree]);
    //#endregion

    //#region Rentable vs Private
    const sortByBuild = useCallback(([a], [b]) => {
        if (![a, b].every(TB.mongoIdValidator)) return TB.mongoIdValidator(a) ? -1 : 1;

        let buildA = buildLabelObj[a], buildB = buildLabelObj[b];
        if (buildA > buildB) return 1;
        if (buildA < buildB) return -1;
        return 0;
    }, [buildLabelObj]);

    const filteredCells = useMemo(() => cellsBailsClients.filter(({ data }) => Boolean(data.isRentable) === isRentCell), [cellsBailsClients, isRentCell]);
    const groupedByBuildingBis = useMemo(() => Object.entries(_.groupBy(filteredCells, "building._id")).sort(sortByBuild), [filteredCells, sortByBuild]);

    useEffect(() => {
        let filteredIds = cellsList.map(({ _id }) => _id);
        if (!filteredIds.includes(activeCell)) setActiveCell(filteredIds?.[0]);
    }, [cellsList, activeCell]);
    //#endregion

    //#region Nav Bar Callbacks
    const onClickCaret = useCallback(buildId => setDevelopedBuild(prev => {
        if (prev.indexOf(buildId) === -1) return prev.concat(buildId);
        return prev.filter(id => id !== buildId);
    }), []);
    //#endregion

    //#region Nav Bar
    const navHeader = useMemo(() => <div className="modal-header p-0 border-bottom-0">
        <ul className="nav nav-tabs nav-fill w-100">
            <li className="nav-item">
                <button onClick={() => setIsRentCell(true)} className={`nav-link ${isRentCell ? "active" : ""}`}>Locative</button>
            </li>
            <li className="nav-item">
                <button onClick={() => setIsRentCell(false)} className={`nav-link ${isRentCell ? "" : "active"}`}>Commune</button>
            </li>
        </ul>
    </div>, [isRentCell]);

    const navFooter = useMemo(() => <div className="modal-footer p-1 border-top-0">
        <button onClick={addCell} className="btn btn-primary">
            <i className="fa fa-plus mr-2"></i> Nouvelle Cellule
        </button>
    </div>, [addCell]);

    const emptyBody = useMemo(() => <div className="position-absolute top-50 start-50 translate-middle text-center">
        <div className="alert alert-secondary">
            {isRentCell ? "Aucune Cellules" : "Aucun Emplacement"}
        </div>
    </div>, [isRentCell]);

    const navBar = useMemo(() => <div className="modal-content h-100">
        {navHeader}
        <div className="modal-body flex-grow-1 overflow-scroll position-relative" style={{ maxHeight: "42.5rem" }}>
            {groupedByBuildingBis.length === 0 ? emptyBody : <ul className="list-group">
                {groupedByBuildingBis.map(([buildId, cells]) => <li className="list-group-item p-2" key={buildId} style={{ backgroundColor: "#EEEEEE" }}>
                    <div onClick={() => onClickCaret(buildId)} className="d-flex align-items-center pointer">
                        <div className="flex-shrink-1 p-1 mr-1">
                            <i className={`fa fa-caret-${developedBuild.includes(buildId) ? "down" : "right"}`}></i>
                        </div>

                        <div>{TB.mongoIdValidator(buildId) ? buildLabelObj[buildId] : <em>Sans Bâtiment</em>}</div>
                    </div>
                    <div hidden={!developedBuild.includes(buildId)} className="animated_swing_in">
                        <ul className="list-group">
                            {cells.map(({ _id, data, note }) => <li onClick={() => setActiveCell(_id)} className={`list-group-item p-2 pointer ${activeCell === _id ? "active" : ""}`} key={_id}>
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">{data?.name}{typeof note === "number" ? ` (${note}%)` : ""}</div>
                                    {getEmplacement(_id)}
                                </div>
                            </li>)}
                        </ul>
                    </div>
                </li>)}
            </ul>}
        </div>
        {navFooter}
    </div>, [navFooter, activeCell, navHeader, emptyBody, groupedByBuildingBis, buildLabelObj, developedBuild, onClickCaret, getEmplacement]);
    //#endregion

    //#region Content
    const { clients, enseigne, exploiter, ...currentCell } = useMemo(() => cellsBailsClients.filter(({ _id }) => _id === activeCell)?.[0] ?? {}, [cellsBailsClients, activeCell]);
    const hasClients = useMemo(() => Array.isArray(clients) && clients.length > 0, [clients]);

    const cellPanel = useMemo(() => <div className="row">
        <div className="col-md-12">
            <p className="h4">Cellule</p>
        </div>
        <div className="col-md-4 pointer" onClick={() => onClickPromptCell(currentCell._id, "name", currentCell.data?.name)}>
            <label className="form-label mt-0">{Text.GLOBAL_NAME[language]}</label>
            <input className="form-control pointer" value={currentCell?.data?.name ?? ""} onChange={() => { }} />
        </div>
        <div className="col-md-3 d-flex align-items-center justify-content-center flex-column">
            <label className="form-label">Est une cellule locative</label>
            <Form.CheckBox
                hideLabel
                label="Est une cellule locative"
                value={currentCell?.data?.isRentable}
                onChange={val => onChangeCell(currentCell._id, "isRentable", val)}
            />
        </div>
        <div className="col-md-3 d-flex align-items-center justify-content-center flex-column">
            <div className="btn-group mt-2">
                <button onClick={() => addEntity?.(siteId, currentCell._id)} className="btn btn-primary">
                    <i className="fa fa-tachometer-alt mr-2"></i>{Text.GLOBAL_LABEL_ENTITY[language]}
                </button>
                <button onClick={() => addNote(currentCell._id)} className="btn btn-secondary">
                    <i className="fa fa-comment-alt mr-2"></i>Notes
                </button>
            </div>
        </div>
    </div>, [language, currentCell, siteId, onClickPromptCell, onChangeCell, addEntity, addNote]);

    const enseignePanel = useMemo(() => <div className="row">
        <div className="col-md-12">
            <p className="h4">Enseigne</p>
        </div>
        <div className="col-md-4 pointer" /* onClick={() => onClickPromptOrSelect(enseigne?._id, enseigneFormId, "name", enseigneLabelObj[enseigne?._id], currentCell._id)}*/ >
            <label className="form-label mt-0">{Text.GLOBAL_NAME[language]}</label>
            <input disabled onChange={() => { }} className="form-control pointer" value={enseigneLabelObj[enseigne?._id] ?? ""} />
        </div>
    </div>, [enseigneLabelObj, enseigne, language]);

    const renterPanel = useMemo(() => <div className="row">
        <div className="col-md-12">
            <span className="h4">Locataire</span>
            <button onClick={onAddRenter} className="btn btn-sm btn-secondary ml-3">
                <i className="fa fa-plus"></i>
            </button>
        </div>
        <div className="col-md-11 mt-2">
            {hasClients && clients.map(({ _id, data }, i) => <div key={_id} className={`row ${clients.length > 1 ? "border rounded p-2 bg-light" : ""} ${i === 0 ? "" : "border-top-0"}`}>
                <div className="col-md-4 pointer" onClick={TB.mongoIdValidator(_id) ? () => onClickPromptOrSelect(_id, clientFormId, "name", data?.name, currentCell?._id) : onAddRenter}>
                    <label className="form-label">Nom</label>
                    <input className="form-control pointer" value={data?.name ?? ""} onChange={() => { }} />
                </div>
                <div className="col-md-4 pointer" onClick={() => onClickPromptCell(_id, 'numSiren', data?.numSiren)}>
                    <label className="form-label">TVA / SIREN</label>
                    <input className="form-control pointer" value={data?.numSiren ?? ""} onChange={() => { }} />
                </div>
                <div className="col-md-2 d-flex align-items-center justify-content-center flex-column">
                    <label className="form-label">Est une franchise</label>
                    <button className="btn-none" onClick={() => onChangeCell(_id, 'isFranchise', !data?.isFranchise)}>
                        <i className={`fa fa-check text-success mr-2 ${data?.isFranchise ? "fa-2x" : ""}`}></i>
                        <i className={`fa fa-times text-danger ml-2 ${data?.isFranchise ? "" : "fa-2x"}`}></i>
                    </button>
                </div>
            </div>)}
            {!hasClients && <div className="alert alert-secondary text-center m-2">
                Pas de locataire
            </div>}
        </div>
    </div>, [clients, currentCell, clientFormId, hasClients, onClickPromptOrSelect, onClickPromptCell, onChangeCell, onAddRenter]);

    const bailPanel = useMemo(() => <div className="row">
        <div className="col-md-12">
            <p className="h4">Bail</p>
        </div>
        <div className="col-md-11">
            {hasClients && clients.map(({ _id, bail, data }, i) => <div key={_id} className={`row ${clients.length > 1 ? "p-2 border rounded bg-light" : ""} ${i === 0 ? "" : "border-top-0"}`}>
                <div className="col-md-4 d-flex justify-content-center flex-column">
                    <label className="form-label">Client</label>
                    <span className="h5">{data?.name}</span>
                </div>
                <div className="col-md-4 pointer" onClick={() => onClickBail(currentCell?._id, _id, bail?._id, "start", bail?.data?.start)}>
                    <label className="form-label">Date de début</label>
                    <input className="form-control" value={stringedDate(bail?.data?.start)} onChange={() => { }} />
                </div>
                <div className="col-md-4 pointer" onClick={() => onClickBail(currentCell?._id, _id, bail?._id, "end", bail?.data?.end)}>
                    <label className="form-label">Date de fin</label>
                    <input className="form-control" value={stringedDate(bail?.data?.end)} onChange={() => { }} />
                </div>
                <div className="col-md-2 pointer" onClick={() => onClickBail(currentCell?._id, _id, bail?._id, "break", bail?.data?.break)}>
                    <label className="form-label">Break</label>
                    <input className="form-control" value={bail?.data?.break ?? ""} onChange={() => { }} />
                </div>
                <div className="col-md-5 pointer" onClick={() => onClickBail(currentCell?._id, _id, bail?._id, "affectation", bail?.data?.affectation)}>
                    <label className="form-label">Affectation</label>
                    <input className="form-control" value={bail?.data?.affectation ?? ""} onChange={() => { }} />
                </div>
                <div className="col-md-5 pointer" onClick={() => onClickBail(currentCell?._id, _id, bail?._id, "loadDistribution", bail?.data?.loadDistribution)}>
                    <label className="form-label">Répartition des charges</label>
                    <input className="form-control" value={bail?.data?.loadDistribution ?? ""} onChange={() => { }} />
                </div>
            </div>)}
            {!hasClients && <div className="alert alert-secondary text-center m-2">
                Pas de locataire
            </div>}
        </div>
    </div>, [clients, hasClients, currentCell, onClickBail, stringedDate]);

    const exploitersPanel = useMemo(() => <div className="row">
        <div className="col-md-12">
            <p className="h4">Exploitant</p>
        </div>
        <div className="col-md-4 pointer" onClick={() => onClickExploiter(exploiter?._id, exploiter?.data?.name, currentCell?._id)}>
            <label className="form-label">{Text.GLOBAL_NAME[language]}</label>
            <input className="form-control" value={exploiter?.data?.name ?? ""} onChange={() => { }} />
        </div>
        <div className="col-md-4 pointer" onClick={() => onClickPromptCell(exploiter?._id, 'numSiren', exploiter?.data?.numSiren)}>
            <label className="form-label">TVA / SIREN</label>
            <input className="form-control pointer" value={exploiter?.data?.numSiren ?? ""} onChange={() => { }} />
        </div>
    </div>, [currentCell, exploiter, language, onClickExploiter, onClickPromptCell]);

    const noSelection = useMemo(() => <div className="position-absolute top-50 start-50 translate-middle text-center">
        <div className="alert alert-secondary">
            Aucune cellule selectionnée
        </div>
    </div>, []);

    const fullContent = useMemo(() => [
        { content: cellPanel },
        { content: enseignePanel, hide: !currentCell?.data?.isRentable },
        { content: renterPanel, hide: !currentCell?.data?.isRentable },
        { content: bailPanel, hide: !currentCell?.data?.isRentable },
        { content: exploitersPanel, hide: !currentCell?.data?.isRentable },
    ].filter(({ hide }) => !hide), [enseignePanel, bailPanel, exploitersPanel, cellPanel, currentCell, renterPanel])
    //#endregion

    return <div className="p-2 row g-1 h-100">
        <div className="col-md-3 h-100">
            {navBar}
        </div>
        <div className="col-md-9 h-100 position-relative overflow-scroll" style={{ maxHeight: "47.5rem" }}>
            {TB.mongoIdValidator(activeCell) ? fullContent.map(({ content }, i) => <div className="my-3" key={i}>{content}</div>) : noSelection}
        </div>
        {/* <div className="my-2 p-1">
            <button onClick={addCell} className="btn btn-primary"><i className="fa fa-plus mr-2"></i>Nouvelle Cellule</button>
        </div>
        {cellsTable} */}
        <div ref={modalRef}></div>
    </div>
}

export default CellPanel;