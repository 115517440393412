import React from "react";
import moment from "moment";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import * as S from "../../../services";
import * as PM from "../../../PurposeModal";
import { TC, T, TB } from "../../../Constants";

export type FormProps = {
    /** A string version of the value */
    str_value?: string;
    /** The real value saved */
    value?: any;
    /** The _id of the item that will have a value certified */
    item_id: string;
    /** The context to use to find documents in */
    context: T.ContextParams;
    /** The prop of the item that will be certified */
    prop: string;
    /** Display the form in a modal ? */
    popup?: boolean;
    /** Extra style properties for the modal */
    modal?: M.StyleModalProps;
    /** The form id of the item */
    form: string;
    /** Callback for form cancellation */
    quit?: () => void;
    /** Callback for submission */
    submit?: (certification: T.Certification) => void;
}

type Document = ReturnType<T.API.Reg.FindDocuments>[number];

const Form: React.FC<FormProps> = ({ submit, ...props }) => {
    const lg = H.useLanguage();
    const [{ userId }] = H.useAuth();
    const [documents, set_documents, status] = H.useAsyncState<Document[]>([]);
    const [errors, set_errors] = React.useState<T.Errors<T.Certification>>({});
    const [certification, set_certification] = React.useState<T.Certification>({
        _id: "",
        owner: "",
        files: [],
        data_date: "",
        description: "",
        prop: props.prop,
        item: props.item_id,
        value: TB.valueToString(props.value),
    });

    React.useEffect(() => {
        if (userId) set_certification(p => ({ ...p, owner: userId }));
    }, [userId]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.findDocuments(props.context)
            .then(({ data }) => isSubscribed && set_documents(data, "done"))
            .catch(() => isSubscribed && set_documents([], "error"));
        return () => {
            isSubscribed = false;
            set_documents([], "load");
        }
    }, [props.context, set_documents]);

    const on_submit = React.useCallback(() => {
        let new_errors: typeof errors = {};
        let data_data = TB.getDate(certification.data_date);
        if (!data_data) new_errors.data_date = TC.GLOBAL_REQUIRED_FIELD;

        if (Object.keys(new_errors).length > 0) set_errors(new_errors);
        else S.certifyValue(certification)
            .then(({ data }) => typeof submit === "function" && submit(data))
            .catch(M.Alerts.updateError);
    }, [submit, certification]);

    const on_pick_document = React.useCallback((doc?: Document) => {
        // Use the document's data to fill the certification
        if (doc) set_certification(p => ({
            ...p,
            files: [],
            document: doc.value,
            data_date: doc.date,
            description: doc.notes || "",
        }));
        // Clear the certification
        else set_certification(p => ({ ...p, document: "", data_date: "", files: [], description: "" }));
    }, []);

    const footer = React.useMemo(() => <C.Flex className="w-100" justifyContent="end" alignItems="center">
        <C.Button text={TC.GLOBAL_SAVE} icon="save" onClick={on_submit} />
    </C.Flex>, [on_submit]);

    const new_document = React.useCallback((text?: string) => {
        let selected_action_name = "";
        const askAction = () => new Promise<string | null>((resolve) => {
            PM.renderSiteSelect({ context: props.context, isRequired: true }).then(site => {
                if (site) {
                    let dismount = M.renderLoader();
                    S.contextActions({ roots: site, type: "other", onlyCheckRegions: true }).then(({ data }) => {
                        let options = data.map(a => ({ ...a, value: a._id, label: lg.getTextObj(a._id, 'name', a.name) })).sort(TB.sortOptions);

                        M.askSelect({
                            options,
                            size: "md",
                            isRequired: true,
                            selectProps: {
                                renderItem: (option: typeof options[number]) => <BS.Row key={option.value}>
                                    <BS.Col md={7} className="text-break" style={{ whiteSpace: "break-spaces" }}>{option.label}</BS.Col>
                                    <BS.Col md={4} className="text-break text-end" style={{ whiteSpace: "break-spaces" }}>
                                        {option.regions.length > 0 ? option.regions.map(r => r.name).join() : option.countries.join()}
                                    </BS.Col>
                                    <BS.Col md={1}>{option.noFreq ? "∞" : option.frequency || "X"}</BS.Col>
                                </BS.Row>
                            }
                        })
                            .then(option => {
                                selected_action_name = options.find(o => o.value === option)?.label || "";
                                resolve(option);
                            });
                    })
                        .catch(M.Alerts.loadError)
                        .finally(dismount);
                }
                else resolve(null);
            });
        });

        askAction().then(actionId => {
            if (TB.mongoIdValidator(actionId)) M.renderRegDoc({ action: actionId, forceNewDoc: true, ...props.context }).then(reg_doc => {
                if (reg_doc && reg_doc.action === "saved") {

                    let new_doc: Document = {
                        label: "",
                        action_id: actionId,
                        value: reg_doc.doc._id,
                        date: reg_doc.doc.last_control,
                        notes: reg_doc.doc.notes || "",
                        default_label: selected_action_name,
                    }

                    set_documents(d => d.concat(new_doc));
                    on_pick_document(new_doc);
                }
            })
        });
    }, [lg, props.context, set_documents, on_pick_document]);

    const translated_documents = React.useMemo(() => {
        return documents.map(d => ({ ...d, label: lg.getTextObj(d.action_id, "name", d.default_label) + ` (${moment(d.date).format("DD/MM/YYYY")})` }));
    }, [documents, lg]);

    return React.createElement(
        props.popup ? M.BlankModal : React.Fragment,
        props.popup ? {
            ...props.modal,
            footer,
            onQuit: props.quit,
            size: props.modal?.size || "sm",
            title: props.modal?.title || lg.getStaticText(TC.CERT_FORM_CERTIFY_FOR, lg.getTextObj(props.form, props.prop, props.prop)),
        } as M.BlankModalProps : null,
        <>
            <C.Form.ComponentWrapper
                required
                labelPosition="left"
                label={TC.CERT_FORM_VALUE}
                children={<C.Flex className="w-100" justifyContent="start" alignItems="center">
                    {TB.valueToString(props.str_value) || <span className="fst-italic text-muted">{lg.getStaticText(TC.CERTIF_EMPTY_VALUE)}</span>}
                </C.Flex>}
            />

            {!certification.document && <C.Form.TextField
                textArea
                rows={3}
                autoExpand
                labelPosition="left"
                error={errors.description}
                label={TC.CERT_FORM_DESCRIPTION}
                value={certification.description}
                onChange={description => set_certification(p => ({ ...p, description }))}
            />}

            {!certification.document && <C.Form.DateTime
                required
                labelPosition="left"
                error={errors.data_date}
                label={TC.CERT_FORM_DATE}
                value={certification.data_date}
                onChange={date => set_certification(p => ({ ...p, data_date: date }))}
            />}

            <C.Form.Select
                addResource
                labelPosition="left"
                loading={status === "load"}
                label={TC.CERT_PROOF_DOCUMENT}
                value={certification.document}
                options={translated_documents}
                addResourceLabel={TC.CERT_PROOF_DOCUMENT_ADD}
                typeahead={{ dropdownFit: true, onAddOption: new_document }}
                error={status === "error" ? TC.GLOBAL_FAILED_LOAD : undefined}
                onChange={document => on_pick_document(document ? translated_documents.find(d => d.value === document) : undefined)}
            />

            {!certification.document && <C.Form.FileUploader
                multiple
                labelPosition="left"
                error={errors.files}
                label={TC.CERT_FORM_FILE}
                value={certification.files}
                onChange={files => set_certification(p => ({ ...p, files }))}
            />}

            {!props.popup && footer}
        </>
    );
}

export default Form;