import { useDrop } from "react-dnd";
import React, { useMemo } from "react";
import { ActionItemProps } from "./ActionItem";
import DraggableContainerAction from "./DraggableContainerAction";

export type ActionContainerProps = {
    /** The actions in this container */
    actions: ActionItemProps[];
    /** A callback for when an action is drop in the container */
    onDrop: (action: ActionItemProps["action"]) => void;
}

const ActionContainer: React.FC<ActionContainerProps> = props => {

    const [{ isOver }, dropRef] = useDrop({
        accept: "action",
        collect: monitor => ({ isOver: monitor.isOver() }),
        drop: (item: ActionItemProps["action"]) => props.onDrop?.(item),
    });

    const borderClass = useMemo(() => isOver ? "border border-primary" : "", [isOver]);

    return <div className={`h-100 ${borderClass} p-2`} ref={dropRef}>
        {props.actions.map(a => <DraggableContainerAction key={a.action.id} {...a} />)}
    </div>;
}

export default ActionContainer;