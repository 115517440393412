import { Button } from "../../Item";
import { Select } from "../Components";
import { InputGroup } from "react-bootstrap";
import { T, FP, TB } from "../../../Constants";
import React, { FC, useCallback, useMemo } from "react";
import { ComponentWrapper, InputProps } from "../Input";
import { renderFormModal } from "../../../Components/Modal";

export type StoreEquipProps = InputProps;

const StoreEquip: FC<StoreEquipProps> = ({ onChange, ...props }) => {

    const vOptions = useMemo(() => TB.getArray(props.options) as T.Option[], [props.options]);
    const isDisabled = useMemo(() => props.disabled || props.noOverride, [props.disabled, props.noOverride]);

    const addItem = useCallback(() => {
        let params: Parameters<typeof renderFormModal>[0] = {
            path: FP.EQUIPSTORE_FORM,
            forcedSubmission: TB.submissionToArrayUpdate({
                brand: props.fullSubmission?.brand,
                model: props.fullSubmission?.model,
                category: props.fullSubmission?.category,
            }),
        };

        renderFormModal(params)
            .then(equipStore => {
                if (equipStore) onChange?.(equipStore._id);
            });
    }, [props.fullSubmission, onChange]);

    return <ComponentWrapper {...props} disabled={isDisabled}>
        <InputGroup>
            <Select
                positionFixed
                noBottomMargin
                key={props.value}
                options={vOptions}
                value={props.value}
                disabled={isDisabled}
                customClass="flex-grow-1"
                onChange={v => onChange?.(v)}
            />
            <Button onClick={addItem} disabled={isDisabled} icon={{ icon: "plus" }} />
        </InputGroup>
    </ComponentWrapper>
};

export default StoreEquip;