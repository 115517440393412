import { Predictions as P } from "../../Form";
import renderInContainer from "../getContainer";

type RenderPredFormProps = Omit<P.PredictionProps, "popup" | "on_finish">;
export type RenderPredForm = (params: RenderPredFormProps) => Promise<Parameters<P.PredictionProps["on_finish"]>[0] | null>;

const renderPredForm: RenderPredForm = params => new Promise(resolve => {
    let [render, dismount] = renderInContainer();
    if (render && dismount && params) {
        const on_finish: P.PredictionProps["on_finish"] = datasets => dismount?.(() => resolve(datasets || null));
        render(<P.Main {...params} popup on_finish={on_finish} />);
    }
    else resolve(null);
});

export default renderPredForm;