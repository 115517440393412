import * as T from "../Types";
import { findIds } from "./id";
import { getNumber } from "./number";
import { splitFrequency } from "./date";

/** Splits a js period to the sql splitted version */
const period_split = (period_str: string) => {
    let period_number: number,
        period = splitFrequency(period_str),
        period_text: T.pSQL.Alarm["period_text"];

    if (period) {
        period_number = period.num;
        if (period.unit === "M") period_text = "month";
        else if (period.unit === "h") period_text = "hourly";
        else if (period.unit === "d") period_text = "day";
        else if (period.unit === "m") period_text = "minutely";
        else if (period.unit === "w") period_text = "week";
        else if (period.unit === "y") period_text = "year";
        return { text: period_text, num: period_number };
    }
    else return null;
}

/** Re-join a splitted frequency */
const period_join = (num: number, unit: T.pSQL.Alarm["period_text"]) => {
    let period = num?.toString?.() || "1";
    if (unit === "day") period += "d";
    else if (unit === "hourly") period += "h";
    else if (unit === "minutely") period += "m";
    else if (unit === "month") period += "M";
    else if (unit === "week") period += "w";
    else if (unit === "year") period += "y";
    else period += "w";
    return period;
}

/** Transform a javascript alarm object to a pSQL alarm */
export const to_pSQL = (alarm: T.Alarm): T.pSQL.Alarm<string> => {
    let next_schedule_timestamp: T.pSQL.Alarm["next_schedule_timestamp"];
    if (alarm.next_schedule_timestamp) next_schedule_timestamp = new Date(alarm.next_schedule_timestamp);

    let period = period_split(alarm.period);
    let range = period_split(alarm.considered_range);

    let tags = (alarm.tags || []).join();
    let data_group_period: T.pSQL.Alarm["data_group_period"];
    let group_period = splitFrequency(alarm.data_group_period);

    if (group_period) {
        data_group_period = group_period.num.toString();
        if (group_period.unit === "M") data_group_period += "M";
        else if (group_period.unit === "d") data_group_period += "D";
        else if (group_period.unit === "h") data_group_period += "H";
        else if (group_period.unit === "m") data_group_period += "min";
        else if (group_period.unit === "w") data_group_period += "W";
        else if (group_period.unit === "y") data_group_period += "Y";
    }

    return {
        tags,
        name: alarm.name,
        type: alarm.type,
        data_group_period,
        user_id: alarm.user,
        active: alarm.active,
        id: getNumber(alarm.id),
        next_schedule_timestamp,
        period_text: period.text,
        period_number: period.num,
        considered_range_text: range.text,
        considered_range_number: range.num,
        threshold_func: alarm.threshold_func,
        dataset_id: alarm.datasets_ids.join(),
        start_date: new Date(alarm.start_date),
        threshold_value: alarm.threshold_value,
        threshold_group: alarm.threshold_group,
        data_group_aggregate: alarm.data_group_aggregate,
        data_include_period: JSON.stringify(alarm.data_include),
        end_of_considered_range: new Date(alarm.end_of_considered_range),
    }
}

/** Transform a pSQL alarm to a javascript alarm object */
export const to_JS = (alarm: T.pSQL.Alarm): T.Alarm => {
    let period = period_join(alarm.period_number, alarm.period_text);
    let range = period_join(alarm.considered_range_number, alarm.considered_range_text);

    let data_group_period = "";
    let tags = (alarm?.tags || "").split(",");
    let unit = alarm.data_group_period?.substring?.((alarm.data_group_period?.length || 0) - 1) || "W",
        num = alarm.data_group_period?.substring?.(0, (alarm.data_group_period?.length || 0) - 1) || "1";
    
    data_group_period += num;
    if (unit === "M") data_group_period += "M";
    else if (unit === "D") data_group_period += "d";
    else if (unit === "H") data_group_period += "h";
    else if (unit === "min") data_group_period += "m";
    else if (unit === "W") data_group_period += "w";
    else if (unit === "Y") data_group_period += "y";
    else data_group_period += "w";

    return {
        tags,
        period,
        id: alarm.id,
        name: alarm.name,
        type: alarm.type,
        data_group_period,
        user: alarm.user_id,
        active: alarm.active,
        considered_range: range,
        threshold_func: alarm.threshold_func,
        threshold_value: alarm.threshold_value,
        threshold_group: alarm.threshold_group,
        data_include: alarm.data_include_period,
        datasets_ids: findIds(alarm.dataset_id),
        start_date: alarm.start_date.toISOString(),
        data_group_aggregate: alarm.data_group_aggregate,
        end_of_considered_range: alarm.end_of_considered_range.toISOString(),
        next_schedule_timestamp: alarm.next_schedule_timestamp?.toISOString?.(),
    }
}