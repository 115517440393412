import * as T from "../Types";
import * as FP from "./FormPaths";
import * as TC from "../textCode";

export const ICONS: Record<T.DataSet["src"], string> = {
    manual: "hand-paper",
    automatic: "database",
    calculated: "calculator",
    prediction: "project-diagram",
}

export const ALARMS = {
    threshold_group: [
        { value: 'avg', label: TC.DATASET_AGG_AVG },
        { value: "sum", label: TC.DATASET_AGG_SUM },
        { value: "min", label: TC.DATASET_AGG_MIN },
        { value: "max", label: TC.DATASET_AGG_MAX },
        { value: "count", label: TC.DATASET_AGG_COUNT },
        { value: "first", label: TC.DATASET_AGG_FIRST },
        { value: "last", label: TC.DATASET_AGG_LAST },
    ] as T.Option<[{}, T.Alarm["data_group_aggregate"]]>[],
    type: [
        { value: "custom", label: TC.ALARM_PRESET_CUSTOM },
        { value: "missing_data", label: TC.ALARM_PRESET_DATA_MISS },
        { value: "negative_values", label: TC.ALARM_PRESET_NEG_VALUES },
        { value: "null_values", label: TC.ALARM_PRESET_NULL_VALUES },
    ] as T.Option<{}, T.Alarm["type"]>[],
    aggregate: [
        { value: 'avg', label: TC.DATASET_AGG_AVG },
        { value: "sum", label: TC.DATASET_AGG_SUM },
        { value: "min", label: TC.DATASET_AGG_MIN },
        { value: "max", label: TC.DATASET_AGG_MAX },
        { value: "count", label: TC.DATASET_AGG_COUNT },
        { value: "first", label: TC.DATASET_AGG_FIRST },
        { value: "last", label: TC.DATASET_AGG_LAST },
    ] as T.Option<{}, T.Alarm["data_group_aggregate"]>[],
    threshold_func: [
        { value: ">", label: ">" },
        { value: "<", label: "<" },
        { value: ">=", label: ">=" },
        { value: "<=", label: "<=" },
        { value: "==", label: "==" },
        { value: "!=", label: "!=" },
    ] as T.Option[],
    energies: [
        { label: TC.GP_GAS, value: "GAS" },
        { label: TC.GP_ELEC, value: "ELEC" },
        { label: TC.GP_THCH, value: "THCH" },
        { label: TC.GP_THFR, value: "THFR" },
        { label: TC.GP_FUEL, value: "FUEL" },
        { label: TC.GP_WATER, value: "WATER" },
        { label: TC.GP_ELEC_PPV, value: "ELEC_PPV" },
        { label: TC.GP_OTHER, value: "OTHER" },
    ] as T.Option<{}, T.DataSet["type"]>[],
    status: [
        { value: "urgent", label: TC.ALERT_STATUS_URGENT, color: "#fe0101" },
        { value: "invalid", label: TC.ALERT_STATUS_INVALID, color: "#f9ffff" },
        { value: "to_watch", label: TC.ALERT_STATUS_TO_WATCH, color: "#fdc302" },
        { value: "corrected", label: TC.ALERT_STATUS_CORRECTED, color: "#92d150" },
        { value: "in_action", label: TC.ALERT_STATUS_IN_ACTION, color: "#9dc1e3" },
        { value: "to_evaluate", label: TC.ALERT_STATUS_TO_EVALUATE, color: "#d9d9d9" },
    ] as T.Option<{ color: string }, T.pSQL.Alert["status"]>[],
    paths: FP.GROUP_DATASETS.map(value => ({ value, label: value })) as T.Option[],
};

export const PRESET_ALARMS = {
    custom: {
        name: "",
        period: "",
        active: true,
        considered_range: "",
        data_group_period: "",
        data_group_aggregate: "sum",
        end_of_considered_range: "",
        data_include: [{ from: '00:00', to: "23:59", days: [0, 1, 2, 3, 4, 5, 6, 7] }],
    },
    missing_data: {
        active: true,
        period: "1D",
        threshold_func: "<",
        threshold_value: "96",
        considered_range: "1D",
        threshold_group: "sum",
        data_group_period: "1D",
        data_group_aggregate: "count",
        name: TC.ALARM_PRESET_DATA_MISS,
        data_include: [{ from: '00:00', to: "23:59", days: [0, 1, 2, 3, 4, 5, 6, 7] }],
    },
    negative_values: {
        active: true,
        period: "1D",
        threshold_func: "<",
        threshold_value: "0",
        considered_range: "1D",
        threshold_group: "min",
        data_group_period: "1D",
        data_group_aggregate: "min",
        name: TC.ALARM_PRESET_NEG_VALUES,
        data_include: [{ from: '00:00', to: "23:59", days: [0, 1, 2, 3, 4, 5, 6, 7] }],
    },
    null_values: {
        active: true,
        period: "1D",
        threshold_func: "==",
        threshold_value: "0",
        considered_range: "1D",
        threshold_group: "sum",
        data_group_period: "1D",
        data_group_aggregate: "sum",
        name: TC.ALARM_PRESET_NULL_VALUES,
        data_include: [{ from: '00:00', to: "23:59", days: [0, 1, 2, 3, 4, 5, 6, 7] }],
    },
} as Record<T.Alarm["type"], Partial<T.Alarm>>;

export const DATASETS = {
    type: [
        { value: "GAS", label: TC.GP_GAS, unit: ["kWh", "m³"] },
        { value: "ELEC", label: TC.GP_ELEC, unit: "kWh" },
        { value: "THFR", label: TC.GP_THFR, unit: "kWh" },
        { value: "THCH", label: TC.GP_THCH, unit: "kWh" },
        { value: "FUEL", label: TC.GP_FUEL, unit: ["kWh", "m³"] },
        { value: "WATER", label: TC.GP_WATER, unit: "m³" },
        { value: "ELEC_PPV", label: TC.GP_ELEC_PPV, unit: "kWh" },
        { value: "OTHER", label: TC.GP_OTHER },
        { value: "GAS_HEAD", label: TC.NRJ_HEAD_GAS, unit: ["kWh", "m³"] },
        { value: "ELEC_HEAD", label: TC.NRJ_HEAD_ELEC, unit: "kWh" },
        { value: "THFR_HEAD", label: TC.NRJ_HEAD_THFR, unit: "kWh" },
        { value: "THCH_HEAD", label: TC.NRJ_HEAD_THCH, unit: "kWh" },
        { value: "FUEL_HEAD", label: TC.NRJ_HEAD_FUEL, unit: ["kWh", "m³"] },
        { value: "WATER_HEAD", label: TC.NRJ_HEAD_WATER, unit: "m³" },
        { value: "ELEC_PPV_HEAD", label: TC.NRJ_HEAD_ELEC_PPV, unit: "kWh" },
        { value: "MONEY", label: TC.NRJ_MONEY },
        { value: "OTHER_HEAD", label: TC.NRJ_HEAD_OTHER },
        { value: "NB_START", label: TC.NRJ_NB_START, unit: "" },
        { value: "HOURS_WORK", label: TC.NRJ_WORK_HOURS, unit: "h" },
        { value: "PRESSURE", label: TC.NRJ_PRESSURE, unit: ["bar", "mbar"] },
        { value: "TEMPERATURE", label: TC.NRJ_TEMPERATURE, unit: "°C" },
        { value: "CONDUCTIVITY", label: TC.NRJ_CONDUCTIVITY, unit: "µS" },
        { value: "CO2", label: TC.NRJ_CO2, unit: "kgCO2e/m²" },
        { value: "WASTE", label: TC.NRJ_WASTE, unit: "T" },
    ] as T.Option<{ unit?: T.AllowArray<T.DataSet["unit"]> | "free" }, T.DataSet["type"]>[],
    src: [
        { label: TC.DATASET_SRC_AUTO, value: "automatic" },
        { label: TC.DATASET_SRC_CALCULATED, value: "calculated" },
        { label: TC.DATASET_SRC_MANUAL, value: "manual" },
    ] as T.Option<{}, T.DataSet["src"]>[],
    aggregate: [
        { value: 'avg', label: TC.DATASET_AGG_AVG },
        { value: "sum", label: TC.DATASET_AGG_SUM },
        { value: "min", label: TC.DATASET_AGG_MIN },
        { value: "max", label: TC.DATASET_AGG_MAX },
        { value: "last", label: TC.DATASET_AGG_LAST },
        { value: "count", label: TC.DATASET_AGG_COUNT },
        { value: "first", label: TC.DATASET_AGG_FIRST },
        { value: "factor", label: TC.DATASET_AGG_FACTOR },
        { value: "annualthreshold", label: TC.DATASET_AGG_ANNUAL_THRESHOLD },
    ] as T.Option<{}, T.DataSet["aggregate"]>[],
    normalized: [
        { value: "false", label: TC.DATASET_NORMALIZED_FALSE },
        { value: "cold", label: TC.DATASET_NORMALIZED_COLD },
        { value: "hot", label: TC.DATASET_NORMALIZED_HOT },
    ] as T.Option<{}, T.DataSet["normalized"]>[],
};

export const TIME_GROUPS = [
    { label: TC.GP_GROUP_MIN, value: "minute" },
    { label: TC.GP_GROUP_HOUR, value: "hour" },
    { label: TC.GP_GROUP_DAY, value: "day" },
    { label: TC.GP_GROUP_WEEK, value: "week" },
    { label: TC.GP_GROUP_MONTH, value: "month" },
    { label: TC.GP_GROUP_QUARTER, value: "quarter" },
    { label: TC.GP_GROUP_YEAR, value: "year" },
] as T.Option<{}, Parameters<T.API.Utils.Energy.EntityDataCsv>[0]["group"]>[];