import renderInContainer from "../getContainer";
import { DataCorrect, DataCorrectProps } from "../Components";

export type AskDataCorrectProps = Omit<DataCorrectProps, "on_done" | "on_quit" | "popup">;

const askDataCorrect = (params?: AskDataCorrectProps) => new Promise<Parameters<DataCorrectProps["on_done"]>[0]>(resolve => {
    let [render, dismount] = renderInContainer();
    if (render && dismount) {
        let on_quit: DataCorrectProps["on_quit"] = () => dismount(() => resolve(null));
        let on_done: DataCorrectProps["on_done"] = link => dismount(() => resolve(link));
        render(<DataCorrect {...params} popup on_quit={on_quit} on_done={on_done} />);
    }
    else resolve(null);
});

export default askDataCorrect;