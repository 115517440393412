import { validString } from "./string";
import { mongoIdValidator } from "./id";

type FormulaDissected = {
    /** The position in the string at which the item stops */
    end: number;
    /** The position in the string at which the item starts */
    start: number;
} & (
        {
            /** The item is just plain text */
            type: "text";
            /** The plain text */
            text: string;
        }
        | {
            /** The item is an item */
            type: "item";
            /** The id of item */
            item?: string;
            /** The property to load the history from */
            history_prop?: string;
            /** The property to get from the item */
            prop?: string;
            /** The dataset to get from the item */
            dataset?: string;
        }
        | {
            /** The item is a meteo tag */
            type: "station_tag";
            /** The pSQL station id */
            station: string;
            /** The pSQL tag id */
            tag?: string;
        }
    );

type OldFormulaDissected = ({ text?: string, id?: string, tag?: number });

/** Transforms a formula into an array items */
export const dissectFormula = (formula: string): FormulaDissected[] => {
    if (!validString(formula)) return [];

    let start = 0, end = 0;
    let dissections = [] as FormulaDissected[];

    // Split on each token, but does not remove the tokens
    let split = (formula.split(/([@$#£])/g) || []).filter(str => str.length > 0);

    for (let i = 0; i < split.length; i++) {
        let str = split[i];
        let lastItem = dissections[dissections.length - 1];

        start = end;
        end += str.length;

        if (str === "@") {
            let next = split[i + 1], surNext = split[i + 2];
            // Match what is expected
            if (mongoIdValidator(next) && surNext === "@") {
                // Skip the next two
                i += 2;
                // Update end position
                end += next.length + surNext.length;
                // Add item
                dissections.push({ start, end, item: next, type: "item" });
            }
            // Is a simple number, could be a meteo station id
            else if (!isNaN(parseInt(next)) && surNext === "@") {
                // Skip the next two
                i += 2;
                // Update end position
                end += next.length + surNext.length;
                // Add meteo item
                dissections.push({ type: "station_tag", station: next, start, end });
            }
            // Just the first @ to create an item
            else dissections.push({ start, end, type: "item" });
        }
        else if (str === "$") {
            // Is coming right after an item without props or dataset
            if (lastItem && lastItem.type === "item" && !lastItem.prop && !lastItem.dataset && !lastItem.history_prop) {
                let next = split[i + 1], surNext = split[i + 2];
                // Match what is expected
                if (mongoIdValidator(next) && surNext === "$") {
                    // Skip the next two
                    i += 2;
                    // Update end position
                    end += next.length + surNext.length;
                    // Update item
                    lastItem.end = end;
                    lastItem.dataset = next;
                }
                // Update the end of the string
                else lastItem.end += 1;
            }
            // Is coming right after a meteo item with a station but no tag provided
            else if (lastItem && lastItem.type === "station_tag" && lastItem.station && !lastItem.tag) {
                let next = split[i + 1], surNext = split[i + 2];
                // Match what is expected
                if (!isNaN(parseInt(next)) && surNext === "$") {
                    // Skip the next two
                    i += 2;
                    // Update end position
                    end += next.length + surNext.length;
                    // Update item
                    lastItem.end = end;
                    lastItem.tag = next;
                }
                // Update the end of the string
                else lastItem.end += 1;
            }
            // Add as normal text
            else dissections.push({ start, end, type: "text", text: str });
        }
        else if (str === "#" || str === "£") {
            // Is coming right after an item without props or dataset
            if (lastItem && lastItem.type === "item" && !lastItem.prop && !lastItem.dataset && !lastItem.history_prop) {
                let next = split[i + 1], surNext = split[i + 2];
                // Match what is expected
                if (validString(next) && (surNext === "#" || surNext === "£")) {
                    // Skip the next two
                    i += 2;
                    // Update end position
                    end += next.length + surNext.length;
                    // Update item
                    lastItem.end = end;
                    if (surNext === "#") lastItem.prop = next;
                    else lastItem.history_prop = next;
                }
                // Update the end of the string
                else lastItem.end += 1;
            }
            // Add as normal text
            else dissections.push({ start, end, type: "text", text: str });
        }
        else dissections.push({ start, end, type: "text", text: str });
    }

    return dissections;
};

/** Transforms an old formula into an array items */
export const old_dissectFormula = (formula: string) => {
    if (!validString(formula)) return [];

    let split = [] as OldFormulaDissected[];
    let splitted = formula.split(/[{}@]/g).filter(validString);

    for (let i = 0; i < splitted.length; i++) {
        let str = splitted[i];

        // Str is an id  
        if (mongoIdValidator(str)) {
            // Next item is a dot
            if (splitted[i + 1] === ".") {
                let tag = splitted[i + 2];
                // Item after dot is a tag_id
                if (validString(tag) && tag.match(/[0-9]+/g)) {
                    splitted.splice(i + 1, 2);
                    split.push({ id: str, tag: parseInt(tag) });
                }
                else {
                    splitted.splice(i + 1, 1);
                    split.push({ id: str });
                }
            }
            else split.push({ id: str });
        }
        else split.push({ text: str });
    }


    return split;
};