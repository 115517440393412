import moment from "moment";
import * as T from "../Types";
import { getDate } from "./date";
import { getNumber } from "./number";
import { getBoolean } from "./boolean";
import { getString, validString } from "./string";

export const sortByLastControl = (a: { last_control: string }, b: { last_control: string }) => {
    let [momentA, momentB] = [a, b].map(obj => getDate(obj?.last_control) ? moment(obj.last_control) : null);
    if (momentA === momentB === null) return 0;
    if (momentA === null) return 1;
    if (momentB === null) return -1;
    if (momentA.isBefore(momentB)) return -1;
    if (momentB.isBefore(momentA)) return 1;
    return 0;
}

export const sortNumbers = (n1?: number | string, n2?: number | string) => {
    let [num1, num2] = [n1, n2].map(getNumber);
    if ([num1, num2].some(isNaN)) {
        if ([num1, num2].every(isNaN)) return 0;
        return isNaN(num1) ? 1 : -1;
    }
    else return num1 - num2;
}

export const sortDates = (d1: any, d2: any) => {
    let [date1, date2] = [d1, d2].map(getDate);
    if (date1 === null || date2 === null) {
        if ([date1, date2].every(d => d === null)) return 0;
        return date1 === null ? 1 : -1;
    }
    return date1.getTime() - date2.getTime();
}

export const sortBoolean = (b1: any, b2: any) => {
    let [bool1, bool2] = [b1, b2].map(getBoolean);
    if (bool1 === null || bool2 === null) {
        if ([bool1, bool2].every(b => b === null)) return 0;
        return bool1 === null ? 1 : -1;
    }
    return bool1 ? -1 : 1;
}

export const sortStringReverse = (string1: string, string2: string) => {
    let [str1, str2] = [string1, string2].map(str => getString(str));
    if (str1 > str2) return -1;
    if (str1 < str2) return 1;
    return 0;
}

export const sortString = (string1: string, string2: string) => {
    let [str1, str2] = [string1, string2].map(str => getString(str));
    if (str1 > str2) return 1;
    if (str1 < str2) return -1;
    return 0;
}

export const sortByNameSubmission = (a: T.Submission, b: T.Submission) => {
    let [strA, strB] = [a, b].map(sub => validString(sub?.data?.name) ? sub.data.name.toUpperCase() : "");
    if (strA > strB) return 1;
    if (strA < strB) return -1;
    return 0;
}

export const sortRegDocsPerDate = (a: Omit<T.RegDoc, "_id">, b: Omit<T.RegDoc, "_id">) => {
    let [momentA, momentB] = [a, b].map(x => moment(x.last_control));

    if (momentA.isBefore(momentB)) return -1;
    else if (momentA.isAfter(momentA)) return 1;
    return 0;
}

export const sortOptions = (a: { label: string }, b: { label: string }) => {
    let [strA, strB] = [a, b].map(x => getString(x?.label));
    if (strA > strB) return 1;
    if (strA < strB) return -1;
    return 0;
}

/**
 * Reverse the order of an array
 */
export const reverse_array = <A,>(array: A[]) => {
    if (!Array.isArray(array)) return [];
    else if (array.length <= 1) return array;
    else {
        let reversed_array: A[] = [];
        for (let elem of array) reversed_array.unshift(elem);
        return reversed_array;
    }
}