import Fuse from 'fuse.js';
import { Link } from 'react-router-dom';
import { TB } from "../../../Constants";
import { Form, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Falcon, SoftBadge, Flex, Avatar } from "../../../Common";
import { Fragment, useEffect, useState, FC, useMemo, useCallback } from 'react';

type SearchContentItem = {
  catagories?: string;
  url: string;
  icon?: {
    img: string,
    size?: string,
    status?: string
  },
  title?: string,
  text?: string,
  img?: string,
  time?: string,
  file?: boolean,
  imgAttrs?: { class: string };
  id?: string | number;
  breadCrumbTexts?: string[];
  type?: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'light' | 'dark' | string;
  key?: string;
}

type MediaSearchContentProps = {
  item: SearchContentItem;
};

type SearchBoxProps = {
  autoCompleteItem?: SearchContentItem[];
};

const tempSearchBoxItem = [
  {
    id: 1,
    url: '/events/event-detail',
    breadCrumbTexts: ['Pages ', ' Events'],
    catagories: 'recentlyBrowsedItems'
  },
  {
    id: 2,
    url: '/e-commerce/customers',
    breadCrumbTexts: ['E-commerce ', ' Customers'],
    catagories: 'recentlyBrowsedItems'
  },
  {
    id: 3,
    catagories: 'suggestedFilters',
    url: '/e-commerce/customers',
    key: 'customers',
    text: 'All customers list',
    type: 'warning'
  },
  {
    id: 4,
    catagories: 'suggestedFilters',
    url: '/events/event-detail',
    key: 'events',
    text: 'Latest events in current month',
    type: 'success'
  },
  {
    id: 5,
    catagories: 'suggestedFilters',
    url: '/e-commerce/product/product-grid',
    key: 'products',
    text: 'Most popular products',
    type: 'info'
  },
  {
    id: 6,
    catagories: 'suggestionFiles',
    url: '#!',
    img: "imageFile1",
    file: true,
    title: 'iPhone',
    imgAttrs: {
      class: 'border h-100 w-100 fit-cover rounded-lg'
    },
    time: '<span class="fw-semi-bold">Antony</span><span class="fw-medium text-600 ms-2">27 Sep at 10:30 AM</span>'
  },
  {
    id: 7,
    catagories: 'suggestionFiles',
    url: '#!',
    img: "imageFile2",
    file: true,
    title: 'Falcon v1.8.2',
    imgAttrs: {
      class: 'img-fluid'
    },
    time: '<span class="fw-semi-bold">John</span><span class="fw-medium text-600 ms-2">30 Sep at 12:30 PM</span>'
  },
  {
    id: 8,
    url: '/user/profile',
    catagories: 'suggestionMembers',
    icon: {
      img: "imageMember2",
      size: 'l',
      status: 'status-online'
    },
    title: 'Anna Karinina',
    text: 'Technext Limited'
  },
  {
    id: 9,
    url: '/user/profile',
    catagories: 'suggestionMembers',
    icon: {
      img: "imageMember1",
      size: 'l'
    },
    title: 'Antony Hopkins',
    text: 'Brain Trust'
  },
  {
    id: 10,
    url: '/user/profile',
    catagories: 'suggestionMembers',
    icon: {
      img: "imageMember3",
      size: 'l'
    },
    title: 'Emma Watson',
    text: 'Google'
  }
];

const MediaSearchContent: FC<MediaSearchContentProps> = ({ item }) => <Dropdown.Item className="px-card py-2" as={Link} to={item.url}>
  <Flex alignItems="center">
    {item.file && <div className="file-thumbnail">
      <img src={item.img} alt="" className={item.imgAttrs.class} />
    </div>}

    {item.icon && <Avatar src={item.icon.img} size="l" className={item.icon.status} />}

    <div className="ms-2">
      <h6 className="mb-0">{item.title}</h6>
      <p
        className="fs--2 mb-0"
        dangerouslySetInnerHTML={{ __html: item.text || item.time }}
      />
    </div>
  </Flex>
</Dropdown.Item>;

const SearchBox: FC<SearchBoxProps> = ({ autoCompleteItem = tempSearchBoxItem }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [resultItem, setResultItem] = useState<SearchContentItem[]>(autoCompleteItem);

  const fuseJsOptions = useMemo(() => ({
    includeScore: true,
    keys: ['title', 'text', 'breadCrumbTexts']
  }), []);

  const searchResult = useMemo(() => new Fuse(autoCompleteItem, fuseJsOptions)
    .search(searchInputValue)
    .map(item => item.item), [fuseJsOptions, searchInputValue, autoCompleteItem]);

  const recentlyBrowsedItems = useMemo(() => resultItem.filter(
    item => item.catagories === 'recentlyBrowsedItems'
  ), [resultItem]);

  const suggestedFilters = useMemo(() => resultItem.filter(
    item => item.catagories === 'suggestedFilters'
  ), [resultItem]);

  const suggestionFiles = useMemo(() => resultItem.filter(
    item => item.catagories === 'suggestionFiles'
  ), [resultItem]);

  const suggestionMembers = useMemo(() => resultItem.filter(
    item => item.catagories === 'suggestionMembers'
  ), [resultItem]);

  const toggle = useCallback(() => setDropdownOpen(!dropdownOpen), [dropdownOpen]);

  useEffect(() => {
    if (searchInputValue) {
      setResultItem(searchResult);
      TB.isIterableArray(searchResult) ? setDropdownOpen(true) : setDropdownOpen(false);
    }
    else setResultItem(autoCompleteItem);

    // eslint-disable-next-line
  }, [searchInputValue]);

  return <Dropdown onToggle={toggle} className="search-box">
    <Dropdown.Toggle as="div" data-toggle="dropdown" aria-expanded={dropdownOpen} bsPrefix="toggle" >
      <Form className="position-relative">
        <Form.Control
          type="search"
          placeholder="Search..."
          aria-label="Search"
          className="rounded-pill search-input"
          value={searchInputValue}
          onChange={({ target }) => setSearchInputValue(target.value)}
          onClick={() => setDropdownOpen(false)}
        />
        <FontAwesomeIcon
          icon="search"
          className="position-absolute text-400 search-box-icon"
        />
        {searchInputValue && <div className="search-box-close-btn-container" >
          <Falcon.CloseButton size="sm" noOutline onClick={() => setSearchInputValue('')} />
        </div>}
      </Form>
    </Dropdown.Toggle>

    <Dropdown.Menu>
      <div className="scrollbar py-3" style={{ maxHeight: '24rem' }}>
        {TB.isIterableArray(recentlyBrowsedItems) && <>
          <Dropdown.Header as="h6" className="px-card pt-0 pb-2 fw-medium">
            Recently Browsed
          </Dropdown.Header>
          {recentlyBrowsedItems.map(item => (
            <Dropdown.Item as={Link} to={item.url} className="fs--1 px-card py-1 hover-primary " key={item.id} >
              <Flex alignItems="center">
                <FontAwesomeIcon icon="circle" className="me-2 text-300 fs--2" />
                <div className="fw-normal">
                  {Array.isArray(item.breadCrumbTexts) && item.breadCrumbTexts.map((breadCrumbText, index) => <Fragment key={breadCrumbText}>
                    {breadCrumbText}
                    {item.breadCrumbTexts.length - 1 > index && <FontAwesomeIcon icon="chevron-right" className="mx-1 text-500 fs--2" transform="shrink 2" />}
                  </Fragment>)}
                </div>
              </Flex>
            </Dropdown.Item>
          ))}
          {[suggestedFilters, suggestionFiles, suggestionMembers].some(TB.isIterableArray) && <hr className="bg-200 dark__bg-900" />}
        </>}

        {TB.isIterableArray(suggestedFilters) && <>
          <Dropdown.Header as="h6" className="px-card pt-0 pb-2 fw-medium">
            Suggested Filter
          </Dropdown.Header>
          {suggestedFilters.map(item => (
            <Dropdown.Item as={Link} to={item.url} className="fs-0 px-card py-1 hover-primary " key={item.id} >
              <Flex alignItems="center">
                <SoftBadge bg={item.type as any} className="fw-medium text-decoration-none me-2" >
                  {item.key}:{' '}
                </SoftBadge>
                <div className="flex-1 fs--1">{item.text}</div>
              </Flex>
            </Dropdown.Item>
          ))}

          {[suggestionFiles, suggestionMembers].some(TB.isIterableArray) && <hr className="bg-200 dark__bg-900" />}
        </>}

        {TB.isIterableArray(suggestionFiles) && <>
          <Dropdown.Header as="h6" className="px-card pt-0 pb-2 fw-medium">
            Files
          </Dropdown.Header>
          {suggestionFiles.map(item => <MediaSearchContent item={item} key={item.id} />)}
          {TB.isIterableArray(suggestionMembers) && <hr className="bg-200 dark__bg-900" />}
        </>}

        {TB.isIterableArray(suggestionMembers) && <>
          <Dropdown.Header as="h6" className="px-card pt-0 pb-2 fw-medium">
            Members
          </Dropdown.Header>
          {suggestionMembers.map(item => <MediaSearchContent item={item} key={item.id} />)}
        </>}

        {resultItem.length === 0 && <p className="fs-1 fw-bold text-center mb-0">No Result Found.</p>}
      </div>
    </Dropdown.Menu>
  </Dropdown>
};

export default SearchBox;
