import React from "react";
import * as I from "../types";
import * as H from "../../../../hooks";
import * as C from "../../../../Common";
import { TB, TC } from "../../../../Constants";

export type Props = {
    /** For the date cell, the format to display the date as */
    format?: string;
    /** When editing the date, ask for the hours and minutes too ? */
    isDateTime?: boolean;
    /** When editing the date, save as unix instead of ISO ? */
    saveUnix?: boolean;
}
export type FilterModel = {
    /** Wether to check for values inside the provided range, or outside of it */
    inRange?: boolean;
    /** A starting date */
    dateTo?: string;
    /** An ending date */
    dateFrom?: string;
}

export const sort = TB.sortDates;
export const type = "agCustomDate";

export const Cell = React.forwardRef<{}, I.CellProps<Props>>((props, ref) => {

    const date = React.useMemo(() => {
        let params = props.colDef?.params || {};

        let date = TB.getDate(props.getValue?.());
        let format = params.format || "DD/MM/YYYY" + (params.isDateTime ? " HH:mm" : "");
        if (date) return TB.formatDate(date, format);
        return null;
    }, [props]);

    return React.createElement("div", { className: "text-center" }, date);
});

export const EditCell = React.forwardRef<I.EditorRef<string | number>, I.EditorProps<Props>>((props, ref) => {
    const input = React.useRef<C.Form.DateTimeRef>(null);
    const container = React.useRef<HTMLDivElement>(null);
    H.useOnClickOutside(container, () => props.api?.stopEditing?.());

    React.useImperativeHandle(ref, () => ({
        getValue: () => {
            let date_str = input.current?.getValue?.();
            if (!props.colDef?.params?.saveUnix) return date_str;
            else return TB.getDate(date_str)?.getTime?.();
        }
    }), [props.colDef?.params?.saveUnix]);

    return <C.Flex ref={container} className="h-100" alignItems="center">
        <C.Form.DateTime
            ref={input}
            noBottomMargin
            value={props.value}
            customClass="flex-grow-1"
            enableTime={props.colDef?.params?.isDateTime}
        />
    </C.Flex>
});

export const Filter = React.forwardRef<I.FilterRef<FilterModel>, I.FilterProps<Props>>((props, ref) => {
    const in_range = H.useBoolean(true);
    const [to, set_to] = React.useState("");
    const [from, set_from] = React.useState("");

    React.useImperativeHandle(ref, () => ({
        isFilterActive: () => !!to && !!from,
        getModel: () => ({ value: { inRange: in_range.value, dateFrom: from, dateTo: to } }),
        setModel: model => {
            set_to(model?.value?.dateTo);
            set_from(model?.value?.dateFrom);
            in_range.setValue(typeof model?.value?.inRange === "boolean" ? model.value.inRange : true);
        },
        doesFilterPass: params => {
            // Get the value for the examined row
            let value = props.valueGetter({ ...props, node: params?.node, data: params?.node?.data, getValue: field => params?.node?.data[field] });
            // Check if the filter is active
            if (from && to) {
                // Check if the value is a date
                let value_date = TB.getDate(value);
                // The value is not a date, so it doesn't pass the filter
                if (!value_date) return false;
                else {
                    let time_to = new Date(to).getTime();
                    let time_value = value_date.getTime();
                    let time_from = new Date(from).getTime();
                    // We need to check if the value is inside the range
                    if (in_range.value) return time_value >= time_from && time_value <= time_to;
                    // We need to check if the value is outside the range
                    else return time_value < time_from || time_value > time_to;
                }
            }
            else return true;
        }
    }), [to, from, in_range, props]);

    const params = React.useMemo(() => props.colDef?.params || {}, [props.colDef?.params]);
    React.useEffect(() => props.filterChangedCallback?.(), [from, to, in_range.value, props]);

    return <div className='p-1'>
        <C.Form.DateTime
            value={from}
            noBottomMargin
            customClass="w-100"
            label={TC.GLOBAL_FROM}
            enableTime={params?.isDateTime}
            onChange={date => set_from(date)}
        />
        <C.Form.DateTime
            value={to}
            noBottomMargin
            customClass="w-100"
            label={TC.GLOBAL_TO}
            enableTime={params?.isDateTime}
            onChange={date => set_to(date)}
        />
        <C.Form.RadioBool
            noBottomMargin
            name="in_range"
            labelPosition="top"
            customClass="w-100"
            value={in_range.value}
            label={TC.GLOBAL_DATE_IN_RANGE}
            onChange={bool => in_range.setValue(!!bool)}
        />
    </div>
});