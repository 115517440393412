import _ from 'lodash';
import './SearchModule.scss';
import ReactDom from "react-dom";
import { TB, TC } from "../../Constants";
import { AppContext } from "../../Context";
import { Confirm, askPrompt } from "../Modal";
import { reloadUser } from "../../reducers";
import * as Text from "../../Constants/text";
import { useSelector, useDispatch } from "react-redux";
import { updateFromFilter } from "../../services/user.service";
import { useCallback, useEffect, useMemo, useRef, useState, useContext } from "react";
import { GLOBAL_SEARCH_PLACEHOLDER, GLOBAL_NO_DATA_AVAILABLE } from "../../Constants/text";

const SearchModule = ({ refObj, searchList = [], testArray = false, minimalView = false, itemList = [], onSelect, onGrouping, allowGrouping = false, origin, allowFavorites = true, ...props }) => {
    const dispatch = useDispatch();
    const auth = useSelector(({ auth }) => auth);
    const [openedCat, setOpenedCat] = useState([]);
    const [favorites, setFavorites] = useState([]);
    const [openMenu, setOpenMenu] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [{ user, preferences }, setUser] = useState({});
    const { config: { isDark } } = useContext(AppContext);
    const [appliedFilters, setAppliedFilters] = useState([]);
    const language = useSelector(({ language }) => language);

    const ref = useRef();
    const modalRef = useRef();

    useEffect(() => {
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (openMenu && ref.current && !ref.current.contains(e.target)) {
                setOpenMenu(false)
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        }
    }, [openMenu])

    //#region User
    useEffect(() => auth.then(setUser), [auth]);
    //#endregion

    //#region Normalization
    const normalizedSearch = useMemo(() => TB.normalizedString(searchString, language), [searchString, language]);
    const checkIncludes = useCallback((str1, str2) => [str1, str2].some(str => typeof str !== "string") ? false : str2.toLowerCase().includes(str1.toLowerCase()), []);
    const checkSimilar = useCallback((str1, str2) => [str1, str2].some(str => typeof str !== "string") ? false : TB.checkStringSimilarity(str1, str2) >= 0.5, []);
    //#endregion

    //#region Data memo
    const purgedSearchList = useMemo(() => {
        if (!Array.isArray(searchList)) return [];
        return searchList.filter(({ label, property }) => typeof label === "string" && typeof property === "string" && property !== "id" && property.length > 0)
    }, [searchList]);

    const normalizedValues = useMemo(() => {
        if (!Array.isArray(itemList)) return [];
        return itemList
            .filter(obj => typeof obj === "object" && obj !== null)
            .map(obj => Object.fromEntries(Object.entries(obj).map(([key, val]) => [key, (key === "id" ? val : TB.normalizedString(val, language, true, null, testArray))])));
    }, [itemList, testArray, language]);

    const filterNoGrouping = useMemo(() => appliedFilters.filter(({ isFilter }) => isFilter), [appliedFilters]);

    const usableValues = useMemo(() => {
        if (filterNoGrouping.length === 0) return normalizedValues;
        let filteredValues = [...normalizedValues];

        filterNoGrouping.forEach(({ property, search, isStrict }) => filteredValues = filteredValues.filter(val => {
            let testVal = val?.[property];

            const fnArray = isStrict ? [checkIncludes] : [checkIncludes, checkSimilar];
            const checker = str => fnArray.some(f => f(search, str));

            if (Array.isArray(testVal)) return testVal.some(str => checker(str));
            else return checker(testVal);
        }));

        return filteredValues;
    }, [normalizedValues, filterNoGrouping, checkIncludes, checkSimilar]);
    //#endregion

    //#region Selection
    useEffect(() => onSelect?.(usableValues.map(({ id }) => id), usableValues), [usableValues, onSelect]);
    //#endregion

    //#region Grouping
    const groupAbleProperties = useMemo(() => purgedSearchList.filter(({ noGroup }) => !noGroup), [purgedSearchList]);
    const onApplyGrouping = useCallback(({ property, label }) => setAppliedFilters(prevState => prevState.concat({ label, property, isFilter: false })), []);
    const appliedGrouping = useMemo(() => appliedFilters.filter(({ isFilter }) => !isFilter).map(({ property }) => property), [appliedFilters]);
    useEffect(() => onGrouping?.(appliedGrouping), [appliedGrouping, onGrouping]);

    const groupButtonClass = useMemo(() => `dropdown-toggle btn ${minimalView ? "w-100 btn-outline" : "btn"}-secondary`, [minimalView]);

    const groupingButton = useMemo(() => !allowGrouping ? undefined : <div className={`dropdown ${minimalView ? "flex-grow-1" : ""}`}>
        <a title="GROUPING" className={groupButtonClass} role="button" href='/#' id="groupingButton" data-bs-toggle="dropdown" aria-expanded="false">
            <i className="fa fa-bars"></i>
        </a>
        <ul className="dropdown-menu" aria-labelledby="groupingButton">
            {groupAbleProperties.filter(({ property }) => !appliedGrouping.includes(property)).map(({ label, property }) => <li key={property}>
                <button onClick={() => onApplyGrouping({ property, label })} className="dropdown-item">{label}</button>
            </li>)}
        </ul>
    </div>, [groupAbleProperties, appliedGrouping, groupButtonClass, onApplyGrouping, allowGrouping, minimalView]);
    //#endregion

    //#region Tree based on the search
    const emptySearchList = useMemo(() => purgedSearchList.length === 0 ? <div className="noData"><span>{GLOBAL_NO_DATA_AVAILABLE[language]}</span></div> : undefined, [language, purgedSearchList]);
    const emptyVal = useMemo(() => <span className="search" style={{ fontStyle: "italic" }}>none</span>, []);

    const sortByName = useCallback((a, b) => {
        if (a.value > b.value) return 1;
        if (b.value > a.value) return -1;
        return 0;
    }, []);

    const filteredValues = useMemo(() => purgedSearchList.map(({ label, property }) => {
        // Keep only the data that match the search
        let values = usableValues.map(({ id, ...nv }) => ({ value: nv?.[property] || "", id }))
            .filter(({ value }) => {
                if (TB.validString(value)) return value.length > 0 && [checkIncludes, checkSimilar].some(f => f(normalizedSearch, value))
                if (Array.isArray(value)) return value.filter(v => TB.validString(v) && [checkIncludes, checkSimilar].some(f => f(normalizedSearch, v)));
                return false;
            })
            .map(({ value, ...r }) => {
                if (Array.isArray(value)) return { ...r, value: value.filter(v => TB.validString(v) && [checkIncludes, checkSimilar].some(f => f(normalizedSearch, v))) };
                return { value, ...r };
            })
            .filter(({ value }) => Array.isArray(value) ? value.length > 0 : true);

        // Group the values to avoid duplicates
        let groupedValues = Object.entries(_.groupBy(values, "value")).map(([value, array]) => ({ value, ids: array.map(({ id }) => id) })).sort(sortByName);

        if (testArray && values.some(({ value }) => Array.isArray(value))) {
            let valueObj = {};
            values.forEach(({ value, id }) => {
                if (!Array.isArray(value)) value = [value];
                value.forEach(v => {
                    if (!Array.isArray(valueObj[v])) valueObj[v] = [id];
                    else valueObj[v].push(id);
                });
            });
            groupedValues = Object.entries(valueObj).map(([value, ids]) => ({ value, ids: _.uniq(ids) })).sort(sortByName);
        }
        return { label, property, values: groupedValues };
    }), [checkIncludes, checkSimilar, sortByName, usableValues, purgedSearchList, normalizedSearch, testArray]);

    const toggleCategory = useCallback(property => {
        if (openedCat.includes(property)) setOpenedCat(openedCat.filter(prop => prop !== property));
        else setOpenedCat(openedCat.concat(property));
    }, [openedCat]);

    const applyFilter = useCallback((label, property, search, isStrict = false) => {
        setAppliedFilters(prevState => prevState.concat({ label, property, search, isFilter: true, isStrict }));
        // Empty the search and close the category
        setOpenedCat([]);
        setSearchString('');
    }, []);

    const searchListTree = useMemo(() => {
        if ([searchString.length, filteredValues.length].some(l => l === 0)) return;

        if (filteredValues.length === 1) return filteredValues.map(({ label, property, values }, i) => <ul style={{ paddingLeft: "0px" }} key={i}>
            {values.length > 0 && values.map(({ value }, i) => <li key={i}><span className="valueItem" onClick={() => applyFilter(label, property, value)}>{value.length === 0 ? emptyVal : value}</span></li>)}
            {values.length === 0 && <li><span className="valueItem"><em>{GLOBAL_NO_DATA_AVAILABLE[language]}</em></span></li>}
        </ul>);

        return filteredValues.map(({ label, property, values }) => <div className="propertyContainer" key={property}>
            <div className="identifier" style={{ display: "flex" }}>
                <span className="toggler" style={{ position: "absolute", marginLeft: "8px", "color": isDark ? "grey" : "" }} onClick={() => { toggleCategory(property); setOpenMenu(oldState => oldState) }}>
                    {values.length > 5 && <i className={`fa fa-angle-double-${openedCat.includes(property) ? "down" : "right"}`}></i>}
                    {values.length < 5 && values.length > 0 && <i className={`fa fa-caret-${openedCat.includes(property) ? "down" : "right"}`}></i>}
                </span>
                <span className="px-card pt-0 pb-2 fw-medium dropdown-header" style={{ cursor: "pointer" }} onClick={() => applyFilter(label, property, searchString)}>{Text.SM_LOOK_IN[language]} : <em style={{ fontWeight: "bold" }}>{label}</em></span>
            </div>
            {openedCat.includes(property) && <div className="valueList">
                <ul>
                    {values.length > 0 && values.map(({ value }, i) => <li style={{ color: isDark ? "grey" : "" }} key={i}><span className="valueItem" style={{ color: "rgba(23, 141, 226, 0.705)", cursor: "pointer" }} onClick={() => applyFilter(label, property, value, true)}>{value.length === 0 ? emptyVal : value}</span></li>)}
                    {values.length === 0 && <li><span className="valueItem"><em>{GLOBAL_NO_DATA_AVAILABLE[language]}</em></span></li>}
                </ul>
            </div>}
        </div>);
    }, [searchString, filteredValues, language, isDark, emptyVal, openedCat, toggleCategory, applyFilter]);
    //#endregion

    //#region Clear Search
    const clearSearches = useCallback(() => {
        setSearchString("");
        setOpenedCat([]);
        setAppliedFilters([]);
    }, []);

    const removeFilter = useCallback(index => setAppliedFilters(prevState => prevState.filter((f, i) => i !== index)), []);
    useEffect(() => typeof refObj?.current === "object" && refObj?.current !== null ? refObj.current.clearSearches = clearSearches : undefined, [refObj, clearSearches]);
    //#endregion

    //#region Preferences
    const searchFavorites = useMemo(() => preferences?.[origin], [preferences, origin]);
    const disabledAddFav = useMemo(() => appliedFilters.length === 0, [appliedFilters]);
    const currentDefaultFavName = useMemo(() => favorites?.filter?.(({ isDefault }) => isDefault)?.[0]?.name, [favorites]);
    const showFavorites = useMemo(() => allowFavorites && TB.validString(origin) && TB.mongoIdValidator(user?._id), [user, allowFavorites, origin]);
    const defaultFavoriteFilters = useMemo(() => Array.isArray(searchFavorites) ? searchFavorites.filter(({ isDefault }) => isDefault === true)?.[0]?.filters : undefined, [searchFavorites]);

    const selectedFavIndex = useMemo(() => {
        let equalFilters = favorites.map(({ filters }) => _.isEqual(filters, appliedFilters));
        return equalFilters.map((isEqual, i) => isEqual ? i : null).filter(index => index !== null);
    }, [favorites, appliedFilters]);

    useEffect(() => Array.isArray(searchFavorites) ? setFavorites(searchFavorites) : undefined, [searchFavorites]);
    useEffect(() => Array.isArray(defaultFavoriteFilters) ? setAppliedFilters(defaultFavoriteFilters) : undefined, [defaultFavoriteFilters]);
    const selectFavorite = useCallback((filters, i) => selectedFavIndex.includes(i) ? undefined : setAppliedFilters(filters), [selectedFavIndex]);

    const updatePreferences = useCallback(async (newFilter = null, newFilterArray = null) => {
        let newFavs = Array.isArray(newFilterArray) ? newFilterArray : favorites.concat(newFilter);

        // Update in database
        let update = {};
        update[`data.preferences.${origin}`] = newFavs;
        let reply = await updateFromFilter({ _id: user._id }, update);
        if (!TB.mongoIdValidator(reply.data._id)) return null;
        else {
            // Update localStorage
            let newUser = _.cloneDeep(user);
            if (typeof newUser.data.preferences !== "object") newUser.data.preferences = {};
            newUser.data.preferences[origin] = newFavs;
            localStorage.setItem('formioUser', JSON.stringify(newUser));

            // Update the promise from selector
            dispatch(reloadUser());
        }
    }, [user, favorites, dispatch, origin]);

    const favNames = useMemo(() => favorites?.map?.(({ name }) => name), [favorites]);

    const promptChecker = useMemo(() => str => {
        if (favNames.includes(str)) return { isValid: false, message: Text.ANNOT_NAME_USED[language] };
        return true;
    }, [favNames, language]);

    const addFavorite = useCallback(() => {
        askPrompt({ isRequired: true, label: TC.GLOBAL_NAME, valChecker: promptChecker, title: TC.SM_ASK_FAV_NAME })
            .then(name => {
                if (name !== null) {
                    let newFilter = { name, filters: appliedFilters, isDefault: false };
                    updatePreferences(newFilter).then(result => {
                        if (result === null) alert("ERROR : Failed to create a favorite");
                        else setFavorites(prev => prev.concat(newFilter));
                    });
                }
            });
    }, [appliedFilters, promptChecker, updatePreferences]);

    const removeFavorite = useCallback(favName => {
        let confirmPromise = new Promise(resolve => ReactDom.render(<Confirm
            language={language}
            onValidate={resolve}
            onQuit={() => resolve(null)}
        />, modalRef.current));

        confirmPromise.then(doDelete => {
            if (doDelete) {
                let newFilterArray = favorites.filter(({ name }) => name !== favName);
                updatePreferences(null, newFilterArray).then(result => {
                    if (result === null) alert("ERROR : Failed to delete a favorite");
                    else setFavorites(newFilterArray);
                });
            }
            ReactDom.unmountComponentAtNode(modalRef.current);
        });
    }, [language, favorites, updatePreferences]);

    const selectDefaultFavorite = useCallback(favName => {
        let newArray = favorites.map(({ isDefault, name, ...rest }) => {
            // If is by default, unDefault it, even if it is the selected one
            if (isDefault) return { name, isDefault: false, ...rest };
            // 
            else if (name === favName) return { name, isDefault: true, ...rest };
            return { name, isDefault, ...rest };
        });

        updatePreferences(null, newArray).then(result => {
            if (result === null) alert("ERROR : Failed to set a default favorite");
            else setFavorites(newArray);
        });
    }, [favorites, updatePreferences]);

    const favButtonClass = useMemo(() => `dropdown-toggle btn ${minimalView ? "w-100 btn-outline" : "btn"}-secondary`, [minimalView]);

    const favoritesButton = useMemo(() => !showFavorites ? undefined : <div className={`dropdown favoritesMenu ${minimalView ? "flex-grow-1" : ""}`}>
        {/* Styles are defined in SearchModule.scss */}
        <button className={favButtonClass} type="button" id="favoritesButton" data-bs-toggle="dropdown" aria-expanded="false">
            <i className="fa fa-star"></i>
        </button>
        <ul className="dropdown-menu" aria-labelledby="favoritesButton">
            {favorites.map(({ name, filters }, i) => <li key={i} className="favItem">
                <button onClick={() => selectFavorite(filters, i)} className="dropdown-item selectFav">
                    <div className="favLabels">
                        <span>{name}</span>
                        <span>{selectedFavIndex.includes(i) ? <i className="fa fa-check"></i> : undefined}</span>
                    </div>
                </button>
                <span className="favDefault actionFav" title={Text.SM_DEFAULT_FAV[language]} onClick={() => selectDefaultFavorite(name)}>
                    <i style={currentDefaultFavName !== name ? { opacity: "0.25" } : {}} className="fa fa-bookmark"></i>
                </span>
                <span className="favDeleter actionFav" onClick={() => removeFavorite(name)}><i className="fa fa-times"></i></span>
            </li>)}
            {favorites.length > 0 && <li><hr className="dropdown-divider"></hr></li>}
            <li><button disabled={disabledAddFav} onClick={addFavorite} className="dropdown-item"><i className="fa fa-plus fa-xs"></i>  {Text.SM_ADD_FAV[language]}</button></li>
        </ul>
    </div>, [language, showFavorites, disabledAddFav, favButtonClass, favorites, selectedFavIndex, currentDefaultFavName, minimalView, removeFavorite, addFavorite, selectFavorite, selectDefaultFavorite]);
    //#endregion

    //#region Display items
    const renderSearchItem = useCallback(({ label, search, isFilter }, i) => {
        if (isFilter) return <div key={i} className="filterContainer" style={{ backgroundColor: "none" }} >
            <span><i style={{ color: "white" }} className="fa fa-filter"></i></span>
            <span className="label" >{TB.shortenString(label, 15)}</span>
            {search.length === 0 ? emptyVal : <span className="search">{TB.shortenString(search, 10)}</span>}
            <span className="remover" onClick={() => removeFilter(i)}><i className="fa fa-times"></i></span>
        </div>;

        return <div key={i} className="filterContainer" style={{ backgroundColor: "none" }}>
            <span><i style={{ color: "white" }} className="fa fa-bars"></i></span>
            <span className="px-card pt-0 pb-2 label dropdown-header">{TB.shortenString(label, 15)}</span>
            <span className="remover" onClick={() => removeFilter(i)}><i className="fa fa-times"></i></span>
        </div>
    }, [emptyVal, removeFilter]);

    const input = useMemo(() => <div className="inputSearch  form-control" style={{ "--custom-background-color": isDark ? "black" : "white" }} onClick={() => setOpenMenu(oldState => !oldState)}>
        <div className="filtersList">
            {appliedFilters.map(renderSearchItem)}
            <input className="searchInputFlex " type="text" style={{ fontFamily: 'Mulish', "--custom-color": isDark ? "whitesmoke" : "#4c5054" }} value={searchString} placeholder={GLOBAL_SEARCH_PLACEHOLDER[language] + '...'} onChange={e => setSearchString(e.target.value)} />
        </div>
        <div className="clearSearches">
            <button onClick={clearSearches}><i className="fa fa-times" style={{ color: "grey" }}></i></button>
        </div>
    </div>, [clearSearches, renderSearchItem, language, searchString, appliedFilters, isDark]);
    //#endregion

    //#region View
    const inputView = useMemo(() => {
        if (minimalView) return <div>
            <div className="btn-group w-100">
                {groupingButton}
                {favoritesButton}
            </div>
            {input}
        </div>

        return <div className={"inputGroup " + (allowGrouping ? "on" : "") + (showFavorites ? " fav" : "")}>
            {input}
            {groupingButton}
            {favoritesButton}
        </div>
    }, [allowGrouping, favoritesButton, groupingButton, input, minimalView, showFavorites]);
    //#endregion

    return <div className="searchModule">
        {inputView}
        <div hidden={!openMenu || searchString.length === 0} className="dropdown-menu show" onClick={() => setOpenMenu(oldState => oldState)}>
            {searchListTree}
            {emptySearchList}
        </div>
        <div ref={modalRef}></div>
    </div>
}

export default SearchModule;