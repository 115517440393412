import { useEffect, useMemo } from "react";
import { TB } from "../Constants";

// Updates the height of a <textarea> when the value changes.
const useAutosizeTextArea = (textAreaRef: HTMLTextAreaElement | null, value: string, minRow?: number) => {
    const minPx = useMemo(() => TB.remToPx(TB.getNumber(minRow, 1) * 1.75), [minRow]);
    useEffect(() => TB.resizeTextArea(textAreaRef, minPx), [textAreaRef, value, minPx]);
};

export default useAutosizeTextArea;
