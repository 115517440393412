import { Certification } from "../../Form";
import renderInContainer from "../getContainer";

export type RenderCertHistory = (params: Omit<Certification.HistoryProps, "popup" | "on_quit">) => Promise<void>;
export type RenderCertForm = (params: Omit<Certification.FormProps, "quit" | "popup" | "submit">) => Promise<Parameters<Certification.FormProps["submit"]>[0]>;

export const renderCertForm: RenderCertForm = params => new Promise(resolve => {
    const [render, dismount] = renderInContainer();
    if (render && dismount) {
        const quit: Certification.FormProps["quit"] = () => dismount(() => resolve(null));
        const validate: Certification.FormProps["submit"] = certification => dismount(() => resolve(certification));
        render(<Certification.Form {...params} popup quit={quit} submit={validate} />);
    }
    else resolve(null);
});

export const renderCertHistory: RenderCertHistory = params => new Promise(resolve => {
    const [render, dismount] = renderInContainer();
    if (render && dismount) {
        const quit: Certification.HistoryProps["on_quit"] = () => dismount(() => resolve(null));
        render(<Certification.History {...params} popup on_quit={quit} />);
    }
    else resolve(null);
});