import _ from "lodash";
import React from "react";
import moment from "moment";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as HP from "../../../helpers";
import * as S from "../../../services";
import * as PM from "../../../PurposeModal";
import { Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CellsTypes as CT } from "../AgGridDefs";
import { FP, LT, RIGHTS, T, TABS, TB, TC, URL } from "../../../Constants";
import { ColDef, Table, TableProps, TableRef, ColDefParams } from "../Grid";
import { Flex, Spinner, QuickInput2, QuickInputColumn, QuickInputProps2 } from "../../../Common";

//#region Types
export type EquipmentTableProps = {
    /** The context to load the equipments from */
    context: T.ContextParams;
    /** The property to save the states under */
    origin?: string;
    /** Show an inline QuickInput above the table ? */
    quickInput?: boolean;
    /** Class to be added to the container */
    className?: string;
    /** Disable the state saver tool */
    hide_state_saver?: boolean;
    /** What category should be pre-filled when creation an equipment ? */
    default_category?: string;
    /** Restrict the elements to show and to create to these specific category ids */
    restricted_categories?: T.AllowArray<string>;
    /** Can only add equipments through the "same categories" option */
    add_only_same_category?: boolean;
    /** Disable the option to move an equipment */
    disable_move?: boolean;
    /** Add an import equipments button */
    allow_import?: boolean;
    /** Other Table props */
    table?: Partial<TableProps<Row>>;
    /** Extra buttons */
    buttons?: TableProps<Row>["extra_buttons"];
    /** Show all the properties */
    show_properties?: boolean;
    /** Do not allow any edit */
    read_only?: boolean;
    /** Set a defined list of equipments, instead of loading them */
    rows?: Row[];
    /** Do not create Sub-Columns for the properties loaded */
    no_sub_columns_for_loaded?: boolean;
    /** Callback after a change */
    onChange?: {
        /** Equipments were deleted */
        delete?: (ids: string[]) => void;
        /** Equipment have been edited, through the equipment form or through the inline edit */
        edit?: (elem: Row[]) => void;
        /** Equipments have been created */
        add?: (equipments: Row[]) => void;
        /** Equipment have had their value changed */
        value_edit?: (_id: string, prop: keyof T.EquipmentData, value: any) => void;
    }
}

export type EquipmentRow = Row;
type Row = ReturnType<T.API.Utils.Tables.GetEquipmentRows>[number];
export type EquipmentTableRef = React.MutableRefObject<TableRef<Row>>;
type Column = ReturnType<T.API.Utils.Tables.GetEquipmentsColumns>[number];
//#endregion

//#region Constants
const TEXT_CODES = [
    TC.GLOBAL_SAME_LOC, TC.AG_DUPLICATE, TC.AG_QUICK_INSERT, TC.GLOBAL_EDIT, TC.GLOBAL_DELETE,
    TC.GLOBAL_NEW, TC.GLOBAL_NEW_EQUIP, TC.EQUIP_ADD_SAME_CAT, TC.GLOBAL_QUICK_INSERTS, TC.GLOBAL_ADD_LOCATION,
    TC.TABLE_EQUIP_MOVE_ELEM, TC.GLOBAL_BULK_INSERT, TC.EQUIP_GO_TO_SHEET, TC.GLOBAL_SCAN_QR_CODE, TC.GLOBAL_TABLE_UNPIN_TOP_ROW,
];

const LOC_FIELDS: (keyof Row)[] = ["sites_names", "building_names", "floor_names", "parking_names", "local_names"];
const NO_EDIT_FIELDS: (keyof Row)[] = ["_id", "remarques", "materials", "sites_ids", "num_sites", "floor_ids", "local_ids", "file_names", "parking_ids", "places_names", "buildings_ids"];
//#endregion

export const Equipment = React.forwardRef<EquipmentTableRef, EquipmentTableProps>((props, ref) => {
    const rights = H.useRights();
    const [forms] = H.useFormIds();
    const navigate = useNavigate();
    const loading = H.useBoolean(false);
    const lg = H.useLanguage(TEXT_CODES);
    const [{ userId, isAdmin }] = H.useAuth();
    const pinned_ref = React.useRef<Row[]>([]);
    const grid = React.useRef<TableRef<Row>>(null);
    const import_data_ref = React.useRef<(() => void)>(null);
    const quickInputRef = React.useRef<EquipQuickInputRef>(null);
    const [equipments, setEquipments, equipStatus] = H.useAsyncState<Row[]>([]);
    const [form_columns, setColumns, col_status] = H.useAsyncState<Column[]>([]);

    const user_can = React.useMemo(() => ({
        read_rem: rights.isRightAllowed(RIGHTS.MISC.WRITE_OWN_REMARQUES),
        edit: !props.read_only && rights.isRightAllowed(RIGHTS.TECH.EDIT_EQUIPMENT),
        move: !props.read_only && rights.isRightAllowed(RIGHTS.TECH.MOVE_EQUIPMENT),
        add: !props.read_only && rights.isRightAllowed(RIGHTS.TECH.CREATE_EQUIPMENT),
        delete: !props.read_only && rights.isRightAllowed(RIGHTS.TECH.DELETE_EQUIPMENT),
        add_emp: !props.read_only && rights.isRightAllowed(RIGHTS.TECH.CREATE_EMPLACEMENT),
        add_rem: !props.read_only && rights.isRightAllowed(RIGHTS.MISC.WRITE_OWN_REMARQUES),
    }), [rights, props.read_only]);

    //#region Ref
    React.useImperativeHandle(ref, () => grid, []);
    //#endregion

    //#region Loading
    React.useEffect(() => {
        let isSubscribed = true;
        if (props.rows) setEquipments(props.rows, "done");
        else S.getEquipmentRows({ context: props.context })
            .then(({ data }) => isSubscribed && setEquipments(data, "done"))
            .catch(() => setEquipments([], "error"));
        return () => {
            isSubscribed = false;
            setEquipments([], "load");
        };
    }, [props.context, props.rows, setEquipments]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getEquipmentsColumns()
            .then(({ data }) => isSubscribed && setColumns(data, "done"))
            .catch(() => isSubscribed && setColumns([], "error"));
        return () => { isSubscribed = false };
    }, [setColumns]);
    //#endregion

    //#region Formatter
    const rows_translated = React.useMemo(() => {
        let filtered_equip = equipments;

        // Restrict categories to show
        if (props.restricted_categories) {
            if (TB.mongoIdValidator(props.restricted_categories)) filtered_equip = filtered_equip
                .filter(e => e.category === props.restricted_categories);
            else if (TB.multiMongoIdValidator(props.restricted_categories)) filtered_equip = filtered_equip
                .filter(e => props.restricted_categories.includes(e.category));
        }

        return filtered_equip.map(e => ({
            ...e,
            last_gamme: e.gammes[e.gammes.length - 1]?.name,
            state_label: lg.getTextObj(e.state, "label", e.state_label),
            vetusty_label: lg.getTextObj(e.vetusty, "level", e.vetusty_label),
            criticity_label: lg.getTextObj(e.criticity, "level", e.criticity_label),
            gammes: e.gammes.map(g => ({ ...g, name: lg.getTextObj(g.id, "name", g.name) })),
            t_materials: e.tr_materials.map(m => `${lg.getStaticText(m.ref)} ${m.precisions}`),
            failure_criticity_label: lg.getTextObj(e.failureCriticity, "level", e.failure_criticity_label),
        }));
    }, [equipments, lg, props.restricted_categories]);
    //#endregion

    //#region Errors & loading
    const is_error = React.useMemo(() => equipStatus === "error" || col_status === "error", [equipStatus, col_status]);
    const is_loading = React.useMemo(() => equipStatus === "load" || col_status === "load", [equipStatus, col_status]);
    //#endregion

    //#region Remarques, Pins & State Update
    const pinned = React.useMemo(() => ({
        /** Update the pinned rows if they changed */
        update: (rows: T.AllowArray<Row>) => {
            if (!Array.isArray(rows)) rows = [rows];
            let new_pinned = [] as typeof rows, changed = false;
            // Update the ref with the updated version
            for (let row of pinned_ref.current) {
                let updated_version = rows.filter(r => r._id === row._id)[0];
                if (updated_version) {
                    changed = true;
                    new_pinned.push(updated_version);
                }
                else new_pinned.push(row);
            }
            // If there was at least one change, update the grid
            if (changed) pinned.pin(new_pinned);
        },
        pin: (rows: T.AllowArray<Row>) => {
            if (!Array.isArray(rows)) rows = [rows];
            let already_in = pinned_ref.current.map(r => r._id);
            let [to_update, to_add] = _.partition(rows, r => already_in.includes(r._id));
            pinned_ref.current = pinned_ref.current
                // Replace the rows already in the ref
                .map(r => to_update.filter(u => u._id === r._id)[0] || r)
                // Add the new rows
                .concat(to_add);
            grid.current?.grid?.api?.setPinnedTopRowData(pinned_ref.current);
        },
        unpin: (rows_ids: T.AllowArray<string>) => {
            if (!Array.isArray(rows_ids)) rows_ids = [rows_ids];
            pinned_ref.current = pinned_ref.current.filter(r => !rows_ids.includes(r._id));
            grid.current?.grid?.api?.setPinnedTopRowData(pinned_ref.current);
        },
        unpin_all: () => {
            // Ask the grid to unpin the rows
            grid.current?.grid?.api?.setPinnedTopRowData();
            // Clear the ref
            pinned_ref.current = [];
        },
    }), []);

    React.useEffect(() => {
        // Clear the pinned elements when context change
        return pinned.unpin_all;
    }, [pinned.unpin_all]);

    const equipment_updates = React.useMemo(() => ({
        /** Replace old rows with new ones */
        replace: (rows: Row[]) => {
            pinned.update(rows);
            setEquipments(p => p.map(r => rows.filter(row => row._id === r._id)[0] || r))
        },
        /** Remove a row */
        remove: (ids: string[]) => {
            pinned.unpin(ids);
            setEquipments(p => p.filter(r => !ids.includes(r._id)));
        },
        /** Add or update rows */
        add: (rows: Row[]) => setEquipments(p => {
            let existing_ids = p.map(r => r._id);
            let [to_update, to_add] = _.partition(rows, r => existing_ids.includes(r._id));
            if (to_add.length > 0) pinned.pin(to_add);
            if (to_update.length > 0) pinned.update(to_update);
            return p.map(r => to_update.filter(row => row._id === r._id)[0] || r)
                .concat(to_add);
        }),
    }), [setEquipments, pinned]);

    const remarque_updates = React.useMemo(() => ({
        /** Update the number of remarques on an item */
        updates: (id: string, delta: number) => {
            if (delta !== 0) S.getEquipmentRowsByIds(id)
                .then(({ data }) => equipment_updates.replace(data))
                .catch(M.Alerts.loadError);
        },
        /** If an emplacement doesn't have a criticity yet, pick one */
        getCriticity: (row: Row) => new Promise((resolve, reject) => {
            // Already has a criticity
            if (TB.mongoIdValidator(row.criticity)) resolve(row.criticity);
            // Set a new criticity and push it directly in database
            else PM.renderEquipIndicatorModal({ type: "criticityEquipment", title: TC.REM_EQUIP_CRITICITY, toUpdate: row._id })
                .then(criticity => resolve(criticity));
        }),
    }), [equipment_updates]);

    const remarques_event = React.useMemo(() => ({
        show: (row: Row) => M.renderPopUpFormModal({
            title: row.name,
            defaultKey: "rem",
            submissionId: row._id,
            path: FP.EQUIPEMENT_FORM,
            updateRem: edits => remarque_updates.updates(row._id, edits.add.length - edits.delete.length),
        }),
        add: (row: Row) => remarque_updates.getCriticity(row).then(criticity => {
            let params = {
                title: TC.NEW_REM_DEF,
                path: FP.REMARQUES_DEFAULTS,
                forcedSubmission: [
                    { prop: "element", value: row._id },
                    { prop: "elementCriticity", value: criticity },
                ],
            } as Parameters<typeof M.renderFormModal>[0];

            if (TB.mongoIdValidator(criticity)) M.renderFormModal<T.RemarqueDefault>(params).then(remarque => {
                if (remarque) {
                    let freq = TB.splitFrequency(remarque.data.deadline);
                    // Ask to create a maintenance ticket
                    M.askConfirm({ text: TC.REM_TABLE_ADD_ACTION, title: TC.G_ACTION, noText: TC.GLOBAL_NO, yesText: TC.GLOBAL_YES }).then(confirmed => {
                        let ticketPromise = new Promise<void>(r => {
                            if (confirmed) M.renderFormModal<T.TicketData>({
                                path: FP.TICKET_FORM,
                                title: row.name,
                                forcedSubmission: [
                                    { prop: "equipment", value: row._id },
                                    { prop: "remarque", value: remarque._id },
                                    { prop: "description", value: remarque.data.description },
                                    { prop: "end", value: moment().add(freq.num, freq.unit).toISOString() },
                                ]
                            })
                                .then(() => r());
                            else r();
                        });

                        ticketPromise.then(() => remarque_updates.updates(row._id, 1));
                    });
                }
                // No remarque added, but criticity was updated
                else if (!TB.mongoIdValidator(row.criticity)) equipment_updates.replace([{ ...row, criticity }]);
            });
        }),
    }), [remarque_updates, equipment_updates]);

    React.useEffect(() => {
        // Update the pinned rows to always show the translations
        let tr_rows = pinned_ref.current.map(r => rows_translated.filter(tr => tr._id === r._id)[0] || null).filter(r => r !== null);
        grid.current?.grid?.api?.setPinnedTopRowData(tr_rows);
    }, [rows_translated]);
    //#endregion

    //#region Edits
    const location_edits = React.useMemo(() => ({
        pick: (forEmplacement = false, elements_ids?: T.AllowArray<string>) => new Promise<string>((resolve, reject) => {
            PM.renderSiteSelect({ context: props.context, isRequired: true }).then(root => {
                if (root) M.renderLightTree({
                    root,
                    restrictOwnLinks: true,
                    selection: elements_ids?.[0],
                    style: { size: "lg", title: TC.GLOBAL_PICK_PARENT },
                    linkRestriction: {
                        isInput: true,
                        no_children: true,
                        excludeIds: elements_ids,
                        linkType: LT.LINK_TYPE_OWN,
                        objForm: forms[forEmplacement ? FP.EMPLACEMENT_FORM : FP.EQUIPEMENT_FORM],
                    }
                })
                    .then(parent => resolve(parent))
                    .catch(reject);
            }).catch(reject);
        }),
    }), [forms, props.context]);

    const equip_edits = React.useMemo(() => ({
        /** Add an equipment */
        add: (row?: Row, type?: "dupe" | "same_loc" | "same_cat") => {
            // Find the parent's id
            let location_promise = new Promise<boolean | string>(r => r(false));
            if (type !== "dupe" && type !== "same_loc") location_promise = location_edits.pick();

            location_promise.then(parent => {
                // User did not cancel parent pick
                if (typeof parent === "string" || typeof parent === "boolean") {
                    // Create an equipment
                    let equipment_promise = new Promise<T.Submission<T.EquipmentData>>((resolve, reject) => {
                        if (type === "dupe") S.duplicateSubmissions(row._id)
                            .then(({ data }) => resolve(data[0] as T.Submission<T.EquipmentData>))
                            .catch(reject);
                        else {
                            let forcedSubmission = [] as Parameters<typeof M.renderFormModal>[0]["forcedSubmission"];

                            if (type === "same_cat") forcedSubmission.push({ prop: "category", value: row.category });
                            else if (props.default_category) forcedSubmission.push({ prop: "category", value: props.default_category });

                            M.renderFormModal<T.EquipmentData>({ path: FP.EQUIPEMENT_FORM, modalProps: { size: "lg" }, forcedSubmission })
                                .then(equip => resolve(equip))
                                .catch(reject);
                        }
                    });

                    let parent_id = typeof parent === "string" ? parent : undefined;

                    equipment_promise.then(new_equip => {
                        if (new_equip) {
                            let unmount = M.renderLoader();
                            S.attachNode({ parent: parent_id, sibling: row?._id, children: new_equip._id, type: LT.LINK_TYPE_OWN }).then(() => {
                                S.getEquipmentRowsByIds(new_equip._id)
                                    .then(({ data }) => {
                                        // Update local state
                                        equipment_updates.add(data);
                                        // Fire the callback
                                        props.onChange?.add?.(data);
                                    })
                                    .catch(M.Alerts.updateError)
                                    .finally(unmount);
                            }).catch(e => {
                                unmount();
                                M.Alerts.updateError(e);
                            });
                        }
                    }).catch(M.Alerts.updateError);
                }
            }).catch(M.Alerts.loadError);
        },
        /** Creates many equipments in the database */
        bulk_create: (equipments: BulkRowData[]) => {
            let unmount = M.renderLoader();
            S.bulk_equipments(equipments).then(({ data }) => {
                equipment_updates.add(data);
                // Fire the callback
                props?.onChange?.add?.(data);
            })
                .catch(M.Alerts.updateError)
                .finally(unmount);
        },
        /** Popup to create many equipments */
        bulk: () => {
            M.renderBlankModal({
                isFullScreen: true,
                disableEscapeKeyDown: true,
                title: TC.GLOBAL_BULK_INSERT,
                renderContent: resolve => <EquipmentQuickInput context={props.context} onSubmit={resolve} />,
            })
                .then(equipments => equipments && equip_edits.bulk_create(equipments))
                .catch(M.Alerts.updateError);
        },
        /** Edit an equipment */
        edit: (row: Row) => {
            let params: Parameters<typeof M.renderPopUpFormModal>[0] = {
                title: row.name,
                submissionId: row._id,
                path: FP.EQUIPEMENT_FORM,
                updateRem: edits => remarque_updates.updates(row._id, edits.add.length - edits.delete.length),
            };

            M.renderPopUpFormModal(params).then(edited => {
                if (edited) S.getEquipmentRowsByIds(edited._id)
                    .then(({ data }) => {
                        // Update local rows
                        equipment_updates.replace(data);
                        // Fire the callback
                        props.onChange?.edit?.(data);
                    })
                    .catch(M.Alerts.updateError);
            });
        },
        /** Delete an equipment */
        remove: (row: T.AllowArray<Row>) => {
            let rows_ids = TB.arrayWrapper(row).map(r => r._id);

            M.askConfirm().then(confirmed => {
                if (confirmed) S.removeEquipments(rows_ids).then(({ data }) => {
                    if (data === "has_children") M.renderAlert({ type: "warning", message: TC.ERROR_DELETE_DESCENDANT_FIRST });
                    else if (data === "has_datasets") M.renderAlert({ type: "warning", message: TC.ERROR_DELETE_DATASETS_FIRST });
                    else {
                        // Update local state
                        equipment_updates.remove(rows_ids);
                        // Fire the callback
                        props.onChange?.delete?.(rows_ids);
                    }
                }).catch(M.Alerts.deleteError);
            })
        },
        /** Move an equipment */
        move: (row: T.AllowArray<Row>) => {
            let rows_ids = TB.arrayWrapper(row).map(r => r._id);
            location_edits.pick(false, rows_ids).then(parent_id => {
                if (parent_id) {
                    let unmount = M.renderLoader();
                    S.moveNode({ removeOld: "sameType", children: rows_ids, type: LT.LINK_TYPE_OWN, parent: parent_id }).then(() => {
                        // Update the row
                        S.getEquipmentRowsByIds(rows_ids)
                            .then(({ data }) => {
                                // Update local state
                                equipment_updates.replace(data);
                                // Fire the callback
                                props.onChange?.edit?.(data);
                            })
                            .catch(M.Alerts.loadError)
                            .finally(unmount);
                    }).catch(error => {
                        unmount();
                        M.Alerts.updateError(error);
                    });
                }
            }).catch(M.Alerts.updateError)
        },
        /** Add a location */
        add_location: () => location_edits.pick(true).then(parent_id => {
            if (parent_id) M.renderFormModal<T.EmplacementData>({ path: FP.EMPLACEMENT_FORM }).then(sub => {
                if (sub) S.attachNode({ parent: parent_id, children: sub._id, type: LT.LINK_TYPE_OWN })
                    .then(() => {
                        // Reload locations options
                        quickInputRef.current?.reload_locations?.();
                        // Notify user of creation success
                        M.renderAlert({ type: "success", message: TC.TABLE_EMPLACEMENT_CREATED })
                    })
                    .catch(M.Alerts.updateError);
            }).catch(M.Alerts.updateError);
        }).catch(M.Alerts.updateError),
        /** Scan a qrcode to go to an equipment's sheet */
        scan_code: () => M.askScan()
            .then(code => code && navigate("/equipment/sheet?qr=" + code))
            .catch(M.Alerts.updateError),
        /** Scan a qrcode to go to an equipment's form on a certain tab */
        scan_code_form: (tab: "props" | "datasets") => M.askScan().then(code => {
            if (code) {
                let unmount = M.renderLoader();
                // Find the equipment and check user's access
                S.canUserReadFromQrCode(code).then(({ data }) => {
                    // The code doesn't match any equipment
                    if (!data.equip_id) M.renderAlert({ type: "warning", message: TC.EQUIP_QR_CODE_UNKNOWN });
                    // User can't access the equipment
                    else if (!data.can_access) M.Alerts.haveNotRight({ message: TC.EQUIP_QR_SCAN_NO_ACCESS });
                    // User can't access the equipment's datasets
                    else if (tab === "datasets" && !data.can_access_datasets) M.Alerts.haveNotRight({ message: TC.EQUIP_QR_SCAN_NO_DATASET_ACCESS });
                    else M.renderPopUpFormModal({
                        readOnly: !data.can_edit,
                        path: FP.EQUIPEMENT_FORM,
                        submissionId: data.equip_id,
                        defaultKey: tab === "props" ? "form" : "data",
                    });
                })
                    .catch(M.Alerts.loadError)
                    .finally(unmount);
            }
        }),
        /** Go to an equipment's sheet */
        show_sheet: (row: Row) => window.open("/equipment/sheet?id=" + row._id, "_blank", 'rel=noopener noreferrer'),
    }), [equipment_updates, remarque_updates, location_edits, props.context, props.onChange, props.default_category, navigate]);

    const onValueChange = React.useCallback<TableProps<Row>["onValueChange"]>(params => {
        let row = params.data,
            old_value = params.oldValue,
            field = params.colDef.field as keyof typeof rows_translated[number];

        if (field === "last_gamme") {
            field = "category";
            old_value = row.category;
        }

        // This field can't be edited
        if (field === "t_materials" || NO_EDIT_FIELDS.includes(field)) M.Alerts.updateError();
        // Updating the equip location
        else if (LOC_FIELDS.includes(field)) {
            // User can move equipment
            if (rights.isRightAllowed(RIGHTS.TECH.MOVE_EQUIPMENT)) {
                // Did not link to a new item
                if (!TB.mongoIdValidator(params.newValue)) M.Alerts.updateError({ message: "need a value" });
                // Reattach the node to the new parent
                else {
                    loading.setTrue();
                    S.moveNode({ removeOld: "sameType", parent: params.newValue, children: row._id, type: LT.LINK_TYPE_OWN }).then(() => {
                        // Get the updated row(s), and update state locally
                        S.getEquipmentRows({ context: { roots: row._id } })
                            .then(({ data }) => equipment_updates.add(data))
                            .catch(M.Alerts.loadError)
                            .finally(loading.setFalse);
                    })
                        .catch(error => {
                            loading.setFalse();
                            M.Alerts.updateError(error);
                        });
                }
            }
            // User not allowed to move equipment
            else M.Alerts.haveNotRight();
        }
        // Can the user edit this equipment
        else if (rights.isRightAllowed(RIGHTS.TECH.EDIT_EQUIPMENT)) {
            let prop: keyof T.EquipmentData;
            // Set the right prop
            if (field === "criticity_label") {
                prop = "criticity";
                old_value = row.criticity;
            }
            else if (field === "state_label") {
                prop = "state";
                old_value = row.state;
            }
            else if (field === "vetusty_label") {
                prop = "vetusty";
                old_value = row.vetusty;
            }
            else if (field === "failure_criticity_label") {
                prop = "failureCriticity";
                old_value = row.failureCriticity;
            }
            else if (field === "brand_name") {
                prop = "brand";
                old_value = row.brand;
            }
            else if (field === "model_name") {
                prop = "model";
                old_value = row.model;
            }
            else if (field === "tags_names") {
                prop = "tags";
                old_value = row.tags;
            }
            else if (field === "omniclass" || field.includes("gammes.")) {
                prop = "category";
                old_value = row.category;
            }
            else prop = field as typeof prop;


            const updatePromise = new Promise<"cancel" | Row[]>((resolve, reject) => {
                if (!_.isEqual(old_value, params.newValue)) {
                    let api_params = {
                        field: prop,
                        _id: row._id,
                        old_value: old_value,
                        new_value: params.newValue,
                    } as Parameters<typeof S.update_equipment_field>[0];

                    loading.setTrue();

                    S.update_equipment_field(api_params).then(({ data }) => {
                        if (data === "changed") M.askConfirm({ title: TC.UPDATE_FORCE_CHANGE, text: TC.UPDATE_VALUE_UNFRESH }).then(confirmed => {
                            if (confirmed) S.update_equipment_field({ ...api_params, force_update: true }).then(({ data }) => {
                                if (data === "changed") reject("Error");
                                else resolve(data);
                            }).catch(reject);
                            else resolve("cancel");
                        });
                        else resolve(data);
                    }).catch(reject);
                }
                else resolve("cancel");
            });

            updatePromise
                .then(rows => {
                    if (rows !== "cancel") {
                        // Update state locally
                        equipment_updates.add(rows);
                        // Fire global edit callback
                        props.onChange?.edit?.(rows);
                        // Fire Specific edit callback
                        props.onChange?.value_edit?.(row._id, prop, params.newValue);
                    }
                })
                .catch(M.Alerts.updateError)
                .finally(loading.setFalse);
        }
        else M.Alerts.haveNotRight();
    }, [equipment_updates, rights, loading, props.onChange]);
    //#endregion

    //#region Columns
    const form_id = React.useMemo(() => forms[FP.EQUIPEMENT_FORM], [forms]);

    const get_options = React.useMemo(() => ({
        brand: () => new Promise<T.Option[]>((resolve, reject) => S.getBrands().then(({ data }) => resolve(data)).catch(reject)),
        model: (row: Row) => new Promise<T.Option[]>((resolve, reject) => S.modelsForBrand(row.brand).then(({ data }) => resolve(data)).catch(reject)),
        criticity: () => new Promise<T.Option[]>((resolve, reject) => {
            S.getPreciseEquipIndicator("criticityEquipment")
                .then(({ data }) => resolve(data.map(d => ({ value: d._id, prop: "level", label: d.data.level }))))
                .catch(reject);
        }),
        fail_criticity: () => new Promise<T.Option[]>((resolve, reject) => {
            S.getPreciseEquipIndicator("criticityFailure")
                .then(({ data }) => resolve(data.map(d => ({ value: d._id, prop: "level", label: d.data.level }))))
                .catch(reject);
        }),
        vetusty: () => new Promise<T.Option[]>((resolve, reject) => {
            S.getPreciseEquipIndicator("vetusty")
                .then(({ data }) => resolve(data.map(d => ({ value: d._id, prop: "level", label: d.data.level }))))
                .catch(reject);
        }),
        state: () => new Promise<T.Option[]>((resolve, reject) => {
            S.getEquipmentStates()
                .then(({ data }) => resolve(data.map(d => ({ label: d.data.label, value: d._id, prop: "label" }))))
                .catch(reject);
        }),
        gamme: (row: Row, def: ColDef<Row>) => new Promise<T.Option[]>((resolve, reject) => {
            let previous_game_index = parseInt(def.field.replace("gammes.", "").replace(".name", "")) - 1;
            let previous_game_id = row.gammes[previous_game_index]?.id;

            S.getGammesFromParent({ lang: lg.prop, parent: previous_game_id })
                .then(({ data }) => resolve(data.map(d => ({ label: `${d.label} - ${d.omniclass}`, value: d.value }))))
                .catch(reject);
        }),
        omniclass: () => new Promise<T.Option[]>((resolve, reject) => {
            S.getGammesOptionsLight(lg.prop)
                .then(({ data }) => resolve(data.map(d => ({ label: `${d.label} - ${d.omniclass}`, value: d.value }))))
                .catch(reject);
        }),
        site: () => new Promise((resolve, reject) => {
            S.getContextOptions({ context: props.context, forms: FP.SITE_FORM }).then(({ data }) => {
                resolve(data)
            }).catch(reject);
        }),
        build: (row: Row) => new Promise((resolve, reject) => {
            S.getContextOptions({ context: { roots: row.sites_ids }, forms: FP.BUILDING_FORM })
                .then(({ data }) => resolve(data))
                .catch(reject);
        }),
        floor: (row: Row) => new Promise((resolve, reject) => {
            S.getContextOptions({ context: { roots: row.buildings_ids }, forms: FP.EMPLACEMENT_FORM })
                .then(({ data }) => resolve(data.filter(d => d.emplacement === "floor")))
                .catch(reject);
        }),
        local: (row: Row) => new Promise((resolve, reject) => {
            let roots = row.floor_ids.length > 0 ? row.floor_ids : row.buildings_ids;
            S.getContextOptions({ context: { roots }, forms: FP.EMPLACEMENT_FORM })
                .then(({ data }) => resolve(data.filter(d => d.emplacement === "local" || d.emplacement === "zone")))
                .catch(reject);
        }),
        parking: (row: Row) => new Promise((resolve, reject) => {
            S.getContextOptions({ context: { roots: row.buildings_ids }, forms: FP.EMPLACEMENT_FORM })
                .then(({ data }) => resolve(data.filter(d => d.emplacement === "parking")))
                .catch(reject);
        }),
        tags: (row: Row) => new Promise<T.Option[]>((resolve, reject) => {
            S.getNoteTags({ context: { roots: row._id }, type: "equip" })
                .then(r => resolve(r.data))
                .catch(reject);
        }),
        create_tag: (text: string, row: Row) => new Promise<T.Option>((resolve, reject) => {
            let submission = { name: text, users: [userId], sites: [], type: "equip" } as T.NoteTag;
            S.createSubmission({ submission, path: FP.NOTE_TAG_FORM }).then(({ data }) => {
                let new_tag = data.submissions[0] as T.Submission<T.NoteTag>;
                if (new_tag) {
                    let new_option = { label: new_tag.data.name, value: new_tag._id } as T.Option;
                    resolve(new_option);
                }
                else resolve(null);
            }).catch(reject);
        }),
    }), [userId, lg.prop, props.context]);

    const loaded_columns = React.useMemo<TableProps<Row>["columns"]>(() => {
        let columns = [] as TableProps<Row>["columns"];

        const recursive = (element: Column, parent = columns) => {
            if (element.col_type === "group") {
                if (props.no_sub_columns_for_loaded) {
                    for (let sub_elem of element.content) recursive(sub_elem);
                }
                else {
                    let name = "";
                    if (Array.isArray(element.labels)) name = element.labels.map(l => lg.getStaticText(l)).join(" - ");
                    else name = lg.getStaticText(element.label)

                    let new_col = { children: [], headerName: name } as typeof parent[number];
                    for (let sub_elem of element.content) recursive(sub_elem, (new_col as any).children);
                    parent.push(new_col);
                }
            }
            else {
                let field = element.prop;
                let hide = !!props.show_properties;
                let type = undefined, editable = true, params = {} as ColDefParams<Row>;

                if (field !== "tags") {
                    if ((element.prop as keyof T.EquipmentData) === "qrCode") type = CT.TYPE_QR_CODE;
                    else if (element.type === "date") type = CT.TYPE_DATE;
                    else if (element.type === "color") type = CT.TYPE_COLOR;
                    else if (element.type === "number") type = CT.TYPE_NUMBER;
                    else if (element.type === "boolean") {
                        type = CT.TYPE_CHECKBOX;
                        params.distinctFalseFromEmpty = true;
                    }
                    else if (element.type === "select") {
                        editable = true;
                        type = CT.TYPE_SELECT;
                        params = {
                            auto_fit: true,
                            empty_option: true,
                            getValues: () => new Promise<T.Option[]>((resolve, reject) => {
                                let type = element.prop;
                                if ((element.prop as keyof T.EquipmentData) === "openingType") type = "openingTypes";
                                else if ((element.prop as keyof T.EquipmentData) === "smokeApproval") type = "smoke";
                                else if ((element.prop as keyof T.EquipmentData) === "elecPhase") type = "elecPhases";
                                S.getEquipmentResource({ type: type as any })
                                    .then(r => resolve(r.data as any))
                                    .catch(reject);
                            }),
                        }
                    }
                    else if (element.type === "file") {
                        editable = false;
                        type = CT.TYPE_FILE;
                    }
                    // Special case : materials, point to the translated data
                    if ((element.prop as keyof T.EquipmentData) === "materials") {
                        field = "t_" + element.prop;
                        params.certify = { prop: "materials" };
                    }
                    else params.certify = true;

                    let name = lg.getTextObj(form_id, element.prop, element.prop);
                    if (element.extra?.unit) name += " [" + lg.getStaticText(element.extra.unit) + "]";
                    if (typeof element.extra?.editable === "boolean") editable = editable && element.extra.editable;
                    parent.push({ field, headerName: name, type, hide, editable, params: { ...params } });
                }
            }
        }

        for (let elem of form_columns) recursive(elem);
        return columns;
    }, [form_columns, lg, form_id, props.show_properties, props.no_sub_columns_for_loaded]);

    const columns = React.useMemo<TableProps<Row>["columns"]>(() => [
        {
            headerName: FP.REMARQUES_DEFAULTS,
            children: [
                {
                    field: "remarques",
                    headerName: " ",
                    editable: false,
                    type: CT.TYPE_REM_BUTTON,
                    params: {
                        isHidden: !user_can.read_rem,
                        action: row => remarques_event.show(row),
                        buttonProps: row => ({ size: "sm", variant: row?.remarques?.remarque_color }),
                        isDisabled: row => props.read_only || (row && row.remarques.nb_remarques === 0),
                        content: row => row && lg.getStaticElem(TC.REM_X_REMARQUES, row.remarques.nb_remarques.toString()),
                    }
                },
                {
                    field: "add_remarque",
                    headerName: " ",
                    editable: false,
                    type: CT.TYPE_ACTION_BUTTON,
                    params: {
                        isDisabled: !user_can.add_rem,
                        action: row => remarques_event.add(row),
                        buttonProps: { icon: "plus", size: "sm", tip: TC.NEW_REM_DEF },
                    }
                }
            ]
        },
        {
            headerName: " ",
            children: [
                {
                    hide: true,
                    field: "edit",
                    headerName: "",
                    editable: false,
                    type: CT.TYPE_ACTION_BUTTON,
                    params: {
                        action: equip_edits.edit,
                        isDisabled: () => !rights.isRightAllowed(RIGHTS.TECH.EDIT_EQUIPMENT),
                        buttonProps: { icon: "pencil-alt", size: "sm", tip: TC.GLOBAL_EDIT },
                    }
                },
                {
                    hide: true,
                    headerName: "",
                    editable: false,
                    field: "duplicate",
                    type: CT.TYPE_ACTION_BUTTON,
                    params: {
                        action: row => equip_edits.add(row, "dupe"),
                        buttonProps: { icon: "copy", size: "sm", tip: TC.AG_DUPLICATE },
                        isDisabled: () => !rights.isRightAllowed(RIGHTS.TECH.CREATE_EQUIPMENT),
                    }
                }
            ]
        },
        { field: "name", headerName: "name", params: { header_id: form_id, certify: true } },
        { field: "brand_name", headerName: "brand", type: CT.TYPE_SELECT, params: { header_id: form_id, getValues: get_options.brand, empty_option: true, certify: { prop: "brand" } } },
        { field: "model_name", headerName: "model", type: CT.TYPE_SELECT, params: { header_id: form_id, getValues: get_options.model, empty_option: true, certify: { prop: "model" } } },
        {
            headerName: "tags",
            field: "tags_names",
            type: CT.TYPE_SELECT,
            params: {
                multiple: true,
                header_id: form_id,
                field_value: "tags",
                getValues: get_options.tags,
                typeahead: { allowNewOption: true, onAddOption: get_options.create_tag },
            }
        },
        { field: "pictures", headerName: "pictures", params: { header_id: form_id }, editable: false, type: CT.TYPE_FILE },
        { field: "quantity", headerName: "quantity", params: { header_id: form_id, certify: true }, type: CT.TYPE_NUMBER },
        { field: "file_names", hide: true, editable: false, enableRowGroup: false, headerName: TC.EQUIP_TABLE_FILE_NAME_EXPORT },
        { field: "notes_str", editable: false, enableRowGroup: false, headerName: FP.NOTES_PATH },
        {
            headerName: TC.GLOBAL_LOCATION,
            children: [
                { field: "full_loc", headerName: TC.GLOBAL_FULL_LOC, editable: false },
                { field: "sites_names", headerName: FP.SITE_FORM, type: CT.TYPE_SELECT, hide: true, params: { getValues: get_options.site } },
                { field: "building_names", headerName: FP.BUILDING_FORM, type: CT.TYPE_SELECT, hide: true, rowGroupIndex: 0, params: { getValues: get_options.build } },
                { field: "floor_names", headerName: TC.GLOBAL_FLOOR, type: CT.TYPE_SELECT, hide: true, rowGroupIndex: 1, params: { getValues: get_options.floor } },
                { field: "local_names", headerName: TC.GLOBAL_LOCAL, type: CT.TYPE_SELECT, hide: true, rowGroupIndex: 2, params: { getValues: get_options.local } },
                { field: "parking_names", headerName: TC.GLOBAL_LABEL_PARKING, type: CT.TYPE_SELECT, hide: true, params: { getValues: get_options.parking } },
            ]
        },
        {
            headerName: TC.EQUIP_TAB_FORM_STATE,
            children: [
                { field: "state_label", headerName: "state", type: CT.TYPE_SELECT, params: { header_id: form_id, getValues: get_options.state, empty_option: true, certify: { prop: "state" } } },
                { field: "vetusty_label", headerName: "vetusty", type: CT.TYPE_SELECT, params: { header_id: form_id, getValues: get_options.vetusty, empty_option: true, certify: { prop: "vetusty" } } },
                { field: "criticity_label", headerName: "criticity", type: CT.TYPE_SELECT, params: { header_id: form_id, getValues: get_options.criticity, empty_option: true, certify: { prop: "criticity" } } },
                { field: "failure_criticity_label", headerName: "failureCriticity", type: CT.TYPE_SELECT, params: { header_id: form_id, getValues: get_options.fail_criticity, empty_option: true, certify: { prop: "failureCriticity" } } },
            ]
        },
        {
            headerName: TC.GLOBAL_CATEGORY,
            children: [
                { field: "omniclass", headerName: TC.EQUIP_STORE_OMNICLASS, type: CT.TYPE_SELECT, params: { getValues: get_options.omniclass, auto_fit: true, empty_option: true } },
                { field: "last_gamme", headerName: "category", type: CT.TYPE_SELECT, params: { header_id: form_id, getValues: get_options.omniclass, auto_fit: true, empty_option: true } },
                { field: "gammes.0.name", headerName: TC.EQUIP_GAMME_LABELS, hide: true, type: CT.TYPE_SELECT, params: { getValues: get_options.gamme, auto_fit: true, empty_option: true, header_template: 1 } },
                { field: "gammes.1.name", headerName: TC.EQUIP_GAMME_LABELS, hide: true, type: CT.TYPE_SELECT, params: { getValues: get_options.gamme, auto_fit: true, empty_option: true, header_template: 2 } },
                { field: "gammes.2.name", headerName: TC.EQUIP_GAMME_LABELS, hide: true, type: CT.TYPE_SELECT, params: { getValues: get_options.gamme, auto_fit: true, empty_option: true, header_template: 3 } },
                { field: "gammes.3.name", headerName: TC.EQUIP_GAMME_LABELS, hide: true, type: CT.TYPE_SELECT, params: { getValues: get_options.gamme, auto_fit: true, empty_option: true, header_template: 4 } },
                { field: "gammes.4.name", headerName: TC.EQUIP_GAMME_LABELS, hide: true, type: CT.TYPE_SELECT, params: { getValues: get_options.gamme, auto_fit: true, empty_option: true, header_template: 5 } },
                { field: "gammes.5.name", headerName: TC.EQUIP_GAMME_LABELS, hide: true, type: CT.TYPE_SELECT, params: { getValues: get_options.gamme, auto_fit: true, empty_option: true, header_template: 6 } },
                { field: "gammes.6.name", headerName: TC.EQUIP_GAMME_LABELS, hide: true, type: CT.TYPE_SELECT, params: { getValues: get_options.gamme, auto_fit: true, empty_option: true, header_template: 7 } },
            ]
        }
    ], [form_id, lg, remarques_event, rights, equip_edits, get_options, props.read_only, user_can]);

    const all_columns = React.useMemo(() => {
        let all_cols = columns.concat(loaded_columns);
        if (isAdmin) all_cols.push({ field: "_id", headerName: "_id", editable: false, hide: true });
        return all_cols;
    }, [columns, loaded_columns, isAdmin]);
    //#endregion

    //#region Context Menu
    const getContextMenu = React.useCallback<TableProps<Row>["getContextMenuItems"]>(event => {
        let row = event.node?.data;
        let selection = event.api.getSelectedRows() || [];
        let items = [] as ReturnType<TableProps<Row>["getContextMenuItems"]>;
        let pinned_rows = grid.current?.grid?.api?.getPinnedTopRowCount?.() || 0;

        // Create options
        if (user_can.add) items.push(
            {
                icon: "<i class='fa fa-plus'></i>",
                action: () => equip_edits.add(row),
                name: lg.getStaticText(TC.GLOBAL_NEW_EQUIP),
                subMenu: [
                    {
                        icon: "<i class='fa fa-plus'></i>",
                        action: () => equip_edits.add(row),
                        name: lg.getStaticText(TC.GLOBAL_NEW),
                        disabled: props.add_only_same_category,
                    },
                    {
                        disabled: !row,
                        icon: "<i class='fa fa-copy'></i>",
                        name: lg.getStaticText(TC.AG_DUPLICATE),
                        action: () => equip_edits.add(row, "dupe"),
                    },
                    {
                        name: lg.getStaticText(TC.GLOBAL_SAME_LOC),
                        icon: "<i class='fa fa-location-arrow'></i>",
                        disabled: !row || props.add_only_same_category,
                        action: () => equip_edits.add(row, "same_loc"),
                    },
                    {
                        disabled: !row,
                        icon: "<i class='fa fa-cog'></i>",
                        name: lg.getStaticText(TC.EQUIP_ADD_SAME_CAT),
                        action: () => equip_edits.add(row, "same_cat"),
                    },
                ]
            },
            // Create many equipments at once
            {
                action: equip_edits.bulk,
                disabled: props.add_only_same_category,
                icon: "<i class='fa fa-bolt text-info'></i>",
                name: lg.getStaticText(TC.GLOBAL_BULK_INSERT),
            }
        );
        // Edit options
        if (row && user_can.edit) items.push({
            action: () => equip_edits.edit(row),
            name: lg.getStaticText(TC.GLOBAL_EDIT),
            icon: "<i class='fa fa-pencil-alt'></i>",
        });
        // Delete options
        if (row && user_can.delete) items.push({
            action: () => equip_edits.remove(row),
            name: lg.getStaticText(TC.GLOBAL_DELETE),
            icon: "<i class='text-danger fa fa-times'></i>",
        });
        // Move an equipment
        if (row && user_can.move) items.push({
            disabled: props.disable_move,
            action: () => equip_edits.move(row),
            icon: "<i class='fa fa-arrows-alt'></i>",
            name: lg.getStaticText(TC.TABLE_EQUIP_MOVE_ELEM),
        });
        // Create a new emplacement
        if (user_can.add_emp) items.push({
            disabled: props.disable_move,
            action: equip_edits.add_location,
            icon: "<i class='fa fa-compass'></i>",
            name: lg.getStaticText(TC.GLOBAL_ADD_LOCATION),
        });
        // If multiple rows were selected
        if (selection.length > 1 && (user_can.delete || user_can.move)) {
            // Add a separation
            if (items.length > 0) items.push("separator");
            // Option to move the equipments
            if (user_can.move) items.push({
                disabled: props.disable_move,
                icon: "<i class='fa fa-arrows-alt'></i>",
                action: () => equip_edits.move(selection),
                name: lg.getStaticText(TC.GLOBAL_MOVE_N_ELEMENTS, selection.length),
            });
            // Option to remove the equipments
            if (user_can.delete) items.push({
                action: () => equip_edits.remove(selection),
                icon: "<i class='text-danger fa fa-times'></i>",
                name: lg.getStaticText(TC.GLOBAL_DELET_N_ITEMS, selection.length),
            });
        }

        // Add a separator
        if (items.length > 0) items.push("separator");
        // Go to the equipment's sheet
        if (row) items.push({
            icon: "<i class='fa fa-id-card'></i>",
            action: () => equip_edits.show_sheet(row),
            name: lg.getStaticText(TC.EQUIP_GO_TO_SHEET),
        });
        // Other misc options
        items.push(
            // Scan QR
            {
                action: equip_edits.scan_code,
                icon: "<i class='fa fa-qrcode'></i>",
                name: lg.getStaticText(TC.GLOBAL_SCAN_QR_CODE),
            },
            // Unpin top rows
            {
                action: pinned.unpin_all,
                disabled: pinned_rows === 0,
                icon: "<i class='fa fa-thumbtack text-danger'></i>",
                name: lg.getStaticText(TC.GLOBAL_TABLE_UNPIN_TOP_ROW),
            }
        );

        if (items.length > 0 && event.defaultItems?.length > 0) items.push("separator", ...event.defaultItems);
        return items;
    }, [equip_edits, lg, user_can, pinned, props.add_only_same_category, props.disable_move]);
    //#endregion

    //#region Languages
    React.useEffect(() => lg.fetchObjectTranslations(form_id), [form_id, lg]);

    React.useEffect(() => {
        let ids = [] as string[];
        for (let e of equipments) {
            let gammes_ids = e.gammes.map(g => g.id);
            ids.push(e.state, e.vetusty, e.failureCriticity, e.criticity, ...gammes_ids);
        }

        ids = ids.filter(TB.mongoIdValidator);
        if (ids.length > 0) lg.fetchObjectTranslations(ids);
    }, [lg, equipments]);
    //#endregion

    //#region Visual
    const expandRows = React.useCallback(() => {
        if (!is_loading) grid.current.grid.api.expandAll?.();
    }, [is_loading]);

    const auto_fit = React.useMemo(() => [
        "edit",
        "duplicate",
        "remarques",
        "add_remarque",
    ], []);

    const sideBar = React.useMemo<TableProps<Row>["sideBar"]>(() => {
        if (props.hide_state_saver) return "filters_columns";
        else return true;
    }, [props.hide_state_saver]);

    const quick_input = React.useMemo(() => user_can.add && props.quickInput && <Accordion className="mb-3">
        <Accordion.Item eventKey="quick_input">
            <Accordion.Header onClick={() => quickInputRef?.current?.load?.()}>
                {lg.getStaticText(TC.GLOBAL_BULK_INSERT)}
            </Accordion.Header>
            <Accordion.Body>
                <div style={{ overflowY: "auto", overflowX: "hidden", minBlockSize: "33vh", maxHeight: "55vh" }}>
                    <EquipmentQuickInput
                        asyncLoad
                        ref={quickInputRef}
                        context={props.context}
                        onSubmit={equip_edits.bulk_create}
                    />
                </div>
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>, [lg, user_can.add, equip_edits, props.quickInput, props.context]);
    //#endregion

    //#region Import
    const import_equipments = React.useCallback(() => {
        HP.equip_import({ context: props.context }).then(results => {
            if (results === "empty") M.Alerts.loadError({ message: TC.IMPORT_EQUIP_EMPTY_FILE });
            else if (results !== "cancelled") {
                equipment_updates.add(results);
                // Fire the callback
                props?.onChange?.add?.(results);
            }
        }).catch(M.Alerts.updateError);
    }, [equipment_updates, props.context, props.onChange]);

    React.useImperativeHandle(import_data_ref, () => import_equipments, [import_equipments]);

    const import_buttons = React.useMemo<TableProps<Row>["extra_buttons"]>(() => {
        let buttons = [] as T.OnlyArray<TableProps<Row>["extra_buttons"]>;
        if (props.allow_import && !props.read_only) buttons.push({
            onClick: () => import_data_ref.current(),
            icon: { element: "<i class='fa fa-file-import me-2'></i>" },
            hidden: () => !rights.isRightAllowed(RIGHTS.TECH.CREATE_EQUIPMENT),
            label: lg.getStaticText(TC.EXCEL_DATA_IMPORT_WITH_UNIT, FP.EQUIPEMENT_FORM),
        });
        if (Array.isArray(props.buttons)) buttons.push(...props.buttons);
        else if (props.buttons) buttons.push(props.buttons);
        return buttons;
    }, [lg, rights, props.allow_import, props.read_only, props.buttons]);

    const tool_bars = React.useMemo(() => {
        let tab_code = "qr_scan";
        let tab_title = lg.getStaticText(TC.GLOBAL_SCAN_QR_CODE);

        return {
            toolbar: {
                name: tab_code,
                toolbarButtons: [
                    {
                        onClick: () => equip_edits.scan_code_form("props"),
                        label: lg.getStaticText(TC.QR_EQUIP_SCAN_SHOW_PROP),
                        icon: { element: "<i class='me-2 fa fa-wrench'></i>" },
                    },
                    {
                        label: lg.getStaticText(TC.QR_EQUIP_SCAN_SHOW_DATA),
                        onClick: () => equip_edits.scan_code_form("datasets"),
                        icon: { element: "<i class='me-2 fa fa-database'></i>" },
                    },
                ]
            } as TableProps<Row>["toolbars"],
            config: { Dashboard: { Tabs: [{ Name: tab_title, Toolbars: [tab_code] }], IsCollapsed: true } } as TableProps<Row>["adaptable_config"],
        };
    }, [lg, equip_edits]);
    //#endregion

    const column_base = React.useMemo(() => {
        let allowed = ["filterable", "grouped", "sortable"] as Exclude<TableProps<Row>["columns_base"], "all" | string>;
        if (user_can.edit && !props.read_only) allowed.push("editable");
        return allowed;
    }, [user_can.edit, props.read_only]);

    return <div className={"w-100 " + (props.className || "")}>
        <Spinner error={is_error}>
            <Flex direction="column" className="h-100">
                {quick_input}
                <Table<Row>
                    {...props.table}
                    ref={grid}
                    sideBar={sideBar}
                    autoFit={auto_fit}
                    columns={all_columns}
                    rows={rows_translated}
                    rowSelection="multiple"
                    onReadyGrid={expandRows}
                    columns_base={column_base}
                    toolbars={tool_bars.toolbar}
                    onValueChange={onValueChange}
                    extra_buttons={import_buttons}
                    adaptable_config={tool_bars.config}
                    getContextMenuItems={getContextMenu}
                    loading={is_loading || loading.value}
                    adaptableId={props.origin || TABS.EQUIPMENTS_TABLE}
                    autoGroupColumnDef={React.useMemo(() => ({ pinned: "left" }), [])}
                    certification={React.useMemo(() => ({ form: form_id, item_id_prop: "_id", context: props.context }), [form_id, props.context])}
                    excel_process={(value, colDef, row) => {
                        if (colDef.field === "pictures") {
                            if (row && Array.isArray(value) && value.length > 0) return `${URL.APP_DOMAIN}${URL.HTML_API}carousel?id=${row._id}`;
                            else return null;
                        }
                        else return null;
                    }}
                />
            </Flex>
        </Spinner>
    </div>;
});

export const EquipmentContext: React.FC = () => {
    const [roots] = H.useRoots();
    H.useCrumbs(FP.EQUIPEMENT_FORM);
    H.useAuth({ tabName: TABS.EQUIPMENTS_TABLE });

    return <Equipment context={roots} origin={TABS.EQUIPMENTS_TABLE} quickInput />;
}

//#region QuickInput Props
export type EquipQuickInputProps = {
    /** Do not show the location column */
    hide_loc_col?: boolean;
    /** If true, the data load must be activated through the ref */
    asyncLoad?: boolean;
    /** The context, to load the locations */
    context: T.ContextParams;
    /** Callback for submit */
    onSubmit: (equipments: BulkRowData[]) => void;
}
type EquipQuickInputRef = {
    /** Trigger the data loading, just once, won't do anything if already loaded */
    load: () => void;
    /** If locations were already loaded, reload them */
    reload_locations: () => void;
}

type BulkRowData = T.EquipmentData & { input: string };
//#endregion

export const EquipmentQuickInput = React.forwardRef<EquipQuickInputRef, EquipQuickInputProps>((props, ref) => {
    const lg = H.useLanguage();
    const [brands, setBrands, brands_status] = H.useAsyncState<ReturnType<T.API.EDL.EQUIP_DATA.GetBrands>>([]);
    const [gammes, setGammes, gammes_status] = H.useAsyncState<ReturnType<T.API.Utils.Gammes.GetGammesOptionsLight>>([]);
    const [locations, setLocations, location_status] = H.useAsyncState<ReturnType<T.API.Utils.Context.GetContextOptions>>([]);

    //#region Work on options
    const locations_icons = React.useMemo(() => locations.map(l => {
        let icon = "question";
        if (l.type === FP.BUILDING_FORM) icon = "building";
        else if (l.type === FP.EMPLACEMENT_FORM && l.emplacement === "parking") icon = "parking";
        else if (l.type === FP.EMPLACEMENT_FORM) icon = "map-marker-alt";
        return { ...l, icon };
    }), [locations]);
    //#endregion

    //#region Loader
    const load_locations = React.useCallback(() => {
        if (!props.hide_loc_col) S.getContextOptions({ context: props.context, add_parent: true, forms: [FP.BUILDING_FORM, FP.EMPLACEMENT_FORM] })
            .then(({ data }) => setLocations(data, "done"))
            .catch(() => setLocations([], "error"));
    }, [props.context, props.hide_loc_col, setLocations]);

    const load_brands = React.useCallback(() => {
        S.getBrands()
            .then(({ data }) => setBrands(data, "done"))
            .catch(() => setBrands([], "error"));
    }, [setBrands]);

    const load_gammes = React.useCallback(() => {
        S.getGammesOptionsLight(lg.prop)
            .then(({ data }) => setGammes(data, "done"))
            .catch(() => setGammes([], "error"));
    }, [setGammes, lg.prop]);
    //#endregion

    //#region Locations
    React.useEffect(() => {
        if (!props.asyncLoad) load_locations();
    }, [props.asyncLoad, load_locations]);

    React.useEffect(() => {
        if (!props.asyncLoad) load_brands();
    }, [props.asyncLoad, load_brands]);

    React.useEffect(() => {
        if (!props.asyncLoad) load_gammes();
    }, [props.asyncLoad, load_gammes]);
    //#endregion

    //#region Ref
    React.useImperativeHandle(ref, () => ({
        reload_locations: load_locations,
        load: () => {
            if (brands_status !== "done") load_brands();
            if (gammes_status !== "done") load_gammes();
            if (location_status !== "done") load_locations();
        },
    }), [location_status, brands_status, gammes_status, load_brands, load_gammes, load_locations]);
    //#endregion

    //#region Check data validity
    const check = React.useCallback<QuickInputProps2<BulkRowData>["onCheck"]>(values => {
        if (!Array.isArray(values) || values.length === 0) return null;
        return values.map(v => {
            let error: T.Errors<BulkRowData> = {};

            // Check name
            if (!TB.validString(v.name)) error.name = TC.GLOBAL_REQUIRED_FIELD;
            // Check Quantity
            if (typeof v.quantity === "number") {
                if (isNaN(v.quantity)) error.quantity = TC.GLOBAL_REQUIRED_FIELD;
                else if (v.quantity <= 0) error.quantity = TC.GLOBAL_ERROR_MIN_1;
            }
            // Check model
            if (TB.mongoIdValidator(v.model) && !TB.mongoIdValidator(v.brand)) error.brand = TC.GLOBAL_REQUIRED_FIELD;
            // Check emplacement
            if (!TB.mongoIdValidator(v.input) && !props.hide_loc_col) error.input = TC.GLOBAL_REQUIRED_FIELD;
            return error;
        });
    }, [props.hide_loc_col]);
    //#endregion

    //#region Columns
    const formats = React.useMemo<QuickInputColumn<BulkRowData>[]>(() => {
        let cols: QuickInputColumn<BulkRowData>[] = [
            {
                size: 2,
                prop: "name",
                type: "text",
                label: TC.GLOBAL_NAME,
                placeholder: TC.GLOBAL_NAME,
            },
            {
                size: 2,
                prop: "input",
                type: "select",
                options: locations_icons,
                label: TC.BUILD_EDIT_LOCAL_FLOOR,
                loading: location_status === "load",
                error: location_status === "error" ? { code: TC.GLOBAL_FAILED_LOAD } : null,
                typeahead: {
                    dropdownFit: true,
                    renderItem: (option: typeof locations_icons[number]) => <Flex className="w-100" alignItems="center" justifyContent="between" >
                        <i className={`fa fa-${option.icon} me-2`} ></i>
                        <span className="me-2">{option.label}</span>
                        <span className="text-muted">{option.parent}</span>
                    </Flex>,
                }
            },
            {
                size: 3,
                type: "select",
                options: gammes,
                prop: "category",
                label: TC.GLOBAL_CATEGORY,
                loading: gammes_status === "load",
                error: gammes_status === "error" ? { code: TC.GLOBAL_FAILED_LOAD } : null,
                typeahead: {
                    dropdownFit: true,
                    renderItem: (option: typeof gammes[number]) => <Flex className="w-100" alignItems="center" justifyContent="between" >
                        <span className="me-2">{option.label}</span>
                        <span className="text-muted">{option.omniclass}</span>
                    </Flex>,
                }
            },
            {
                prop: "brand",
                type: "select",
                label: TC.BRAND,
                options: brands,
                loading: brands_status === "load",
                error: brands_status === "error" ? { code: TC.GLOBAL_FAILED_LOAD } : null,
            },
            {
                // check: selectCheck,
                prop: "model",
                type: "select",
                label: TC.BRAND_MODEL,
                is_disabled: v => !TB.mongoIdValidator(v?.brand),
                getOptions: (row: T.EquipmentData) => new Promise((resolve, reject) => {
                    if (!TB.mongoIdValidator(row?.brand)) resolve([]);
                    else S.modelsForBrand(row.brand)
                        .then(({ data }) => resolve(data))
                        .catch(reject);
                }),
            },
            {
                size: 2,
                init_value: 1,
                type: "number",
                prop: "quantity",
                label: TC.GLOBAL_QUANTITY,
            },
        ];
        if (props.hide_loc_col) cols = cols.filter(c => c.prop !== "input");
        return cols
    }, [brands, brands_status, gammes, gammes_status, location_status, locations_icons, props.hide_loc_col]);
    //#endregion

    return <QuickInput2<BulkRowData>
        onCheck={check}
        columns={formats}
        onSubmit={props.onSubmit}
    />;
});