import React from 'react'
import useEventListener from "./useEventListener";
import useIsomorphicLayoutEffect from "./useIsomorphicLayoutEffect";

interface WindowSize { width: number, height: number, isMobile: boolean };

function useWindowSize(): WindowSize {
    const [windowSize, setWindowSize] = React.useState<WindowSize>({ width: 0, height: 0, isMobile: false });

    const handleSize = React.useCallback(() => setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: window.innerHeight < 500 || window.innerWidth < 500
    }), []);

    useEventListener('resize', handleSize);

    // Set size at the first client-side load
    useIsomorphicLayoutEffect(() => handleSize(), []);

    return windowSize;
}

export default useWindowSize