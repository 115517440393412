import _ from "lodash";
import moment from "moment";
import ReactDom from "react-dom";
import momentz from "moment-timezone";
import { useLanguage } from "../../../hooks";
import * as US from "../../../services/user.service";
import { EC, URL, TB, RESOURCE, LT, TC } from "../../../Constants";
import { ENTITY_FORMAT_A_TO_B } from "../../ExcelMapping/ExcelFormats";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ExcelMapping, { applyFactor } from "../../ExcelMapping/ExcelMapping";
import { askSelect, askExcelImport, askPrompt, renderLoader, renderErrorModal, askConfirm, renderBlankModal, renderFormModal, Alerts } from "../../Modal";
import { ReduxWrapper } from "../../../Common";

const TEXT_CODES = [
    TC.GP_GAS, TC.GP_ELEC, TC.GP_THCH, TC.GP_THFR, TC.GP_FUEL, TC.GP_WATER, TC.GP_ELEC_PPV,
    TC.GLOBAL_NAME, TC.GLOBAL_LABEL_BUILD, TC.GLOBAL_LABEL_EMPLACEMENT, TC.GLOBAL_LABEL_ENTITY, TC.IMPORT,
];

const EntityPanel = ({ entities, site, addEntity, addNote, onUpdateTree, linkTypes, ...props }) => {
    const modalRef = useRef();
    const [location, setLocations] = useState([]);
    const { getStaticText } = useLanguage(TEXT_CODES);

    //#region Link Types
    const toKeepLinkTypes = useMemo(() => [
        { type: LT.LINK_TYPE_OWN },
        { type: LT.LINK_TYPE_ENERGY },
        { type: LT.LINK_TYPE_GAS, energy: "GAS" },
        { type: LT.LINK_TYPE_ELEC, energy: "ELEC" },
        { type: LT.LINK_TYPE_WATER, energy: "WATER" },
        { type: LT.LINK_TYPE_TH_HOT, energy: "THCH" },
        { type: LT.LINK_TYPE_TH_FUEL, energy: "FUEL" },
        { type: LT.LINK_TYPE_TH_COLD, energy: "THFR" },
        { type: LT.LINK_TYPE_SUN, energy: "ELEC_PPV" },
    ], []);

    const linkTypesOptions = useMemo(() => {
        if (!Array.isArray(linkTypes)) return [];
        let keepLinksCodes = toKeepLinkTypes.map(({ type }) => type);
        let energyObj = Object.fromEntries(toKeepLinkTypes.map(({ type, energy }) => [type, energy]));

        return linkTypes.filter(({ data }) => keepLinksCodes.includes(data?.type))
            .map(({ _id, data }) => ({ label: data.type, value: _id, energy: energyObj[data.type] }));
    }, [toKeepLinkTypes, linkTypes]);

    const defaultLinkType = useMemo(() => linkTypesOptions.filter(({ label }) => label === LT.LINK_TYPE_OWN)[0].value, [linkTypesOptions]);
    //#endregion

    //#region Site
    const siteId = useMemo(() => site?._id, [site]);
    //#endregion

    //#region Entities
    const entitiesList = useMemo(() => Array.isArray(entities) ? entities : [], [entities]);
    const entitiesIds = useMemo(() => entitiesList.map(({ _id }) => _id), [entitiesList]);
    const noEntities = useMemo(() => entitiesList.length === 0, [entitiesList]);

    const entitiesMapOptions = useMemo(() => Object.fromEntries(
        _.flatten(entitiesList
            .map(({ _id, data }) => {
                const getPropsUnits = () => {
                    let THArray = [
                        { prop: "th_tag", label: "Flow", },
                        { prop: "th_tin_tag", label: "T_IN", },
                        { prop: "th_tout_tag", label: "T_OUT", },
                        { prop: "th_power_tag", label: "Power", },
                        { prop: "th_cons_tag", label: "Consumption", },
                        { prop: "th_volume_tag", label: "Volume" }
                    ];
                    switch (data?.typeEnergie) {
                        case "ELEC": return ["tag"];
                        case "ELEC_PPV": return ["tag_ppv"];
                        case "WATER": return ["water_tag"];
                        case "GAS": return ["gas_tag"];
                        case "THCH": return THArray;
                        case "THFR": return THArray;
                        case "FUEL": return ["fuel_tag"];
                        default: return null;
                    }
                }

                let label = TB.validString(data?.name) ? data.name : _id;
                let propsUnit = getPropsUnits();
                if (!Array.isArray(propsUnit)) return null;
                if (propsUnit.length === 1) return [[_id, { label, realName: data.name, tagProp: propsUnit[0] }]];
                return propsUnit.map(({ prop, ...l }) => [_id + "$$" + prop, { label: `${label} / ${l.label}`, realName: data?.name, tagProp: prop }])
            })).filter(Array.isArray)
    ), [entitiesList]);
    //#endregion 

    //#region Energies
    const allEnergies = useMemo(() => [
        { label: getStaticText(TC.GP_GAS), code: "GAS" },
        { label: getStaticText(TC.GP_ELEC), code: "ELEC" },
        { label: getStaticText(TC.GP_THCH), code: "THCH" },
        { label: getStaticText(TC.GP_THFR), code: "THFR" },
        { label: getStaticText(TC.GP_FUEL), code: "FUEL" },
        { label: getStaticText(TC.GP_WATER), code: "WATER" },
        { label: getStaticText(TC.GP_ELEC_PPV), code: "ELEC_PPV" },
    ], [getStaticText]);

    const energyObj = useMemo(() => Object.fromEntries(
        allEnergies.map(({ code, label }) => [code, label])
    ), [allEnergies]);
    //#endregion

    //#region Location
    const locationsEntityId = useMemo(() => location.filter(({ error }) => !error).map(({ id }) => id), [location]);

    const locationObj = useMemo(() => Object.fromEntries(
        location.map(({ id, building, emplacement, local, parking, error }) => [id, { building, emplacement, local, parking, error }])
            .map(([key, obj]) => {
                if (obj?.error) return [key, Object.fromEntries(Object.keys(obj).map(key => [key, { data: { name: "ERROR" } }]))];
                let filterObject = Object.fromEntries(Object.entries(obj).map(([key, array]) => [key, array?.[0] ?? array]));
                return [key, filterObject];
            })
    ), [location]);

    useEffect(() => {
        let isSubscribed = true;

        let toSearch = entitiesIds.filter(id => !locationsEntityId.includes(id));
        if (toSearch.length > 0) US.itemLocater(toSearch, false).then(({ data }) => {
            if (!isSubscribed) return;
            if (Array.isArray(data)) {
                let foundIds = data.map(({ id }) => id);

                if (data.length > 0) setLocations(prev => prev.filter(({ id }) => !foundIds.includes(id)).concat(data));
                else setLocations(prev => prev.concat(toSearch.filter(({ id }) => !foundIds.includes(id)).map(id => ({ id }))));
            }
            else setLocations(prev => _.uniqBy(prev.concat(toSearch.map(id => ({ id, error: true }))), "id"));
        })

        return () => isSubscribed = false;
    }, [entitiesIds, locationsEntityId]);
    //#endregion

    //#region CallBacks
    const showErrorModal = useCallback(errorCode => renderErrorModal({ errorCode }), []);

    const getEmplacement = useCallback(id => {
        let keys = ["parking", "local", "emplacement"];
        let value = keys.map(key => locationObj?.[id]?.[key]?.data?.name).filter(TB.validString)?.[0];
        return TB.validString(value) ? value : "-";
    }, [locationObj]);

    const onEditEntity = useCallback(async id => {
        let entityObj = entitiesList.filter(({ _id }) => _id === id)?.[0];
        if (!TB.mongoIdValidator(entityObj?._id)) return;

        renderFormModal({
            submissionId: id,
            forcedSubmission: [
                { prop: "siteId", value: site?._id },
                { prop: "stationsList", value: site?.data?.stations }
            ],
            _id: entityObj.form,
            title: TC.GLOBAL_LABEL_ENTITY,
        }).then(entity => {
            if (TB.mongoIdValidator(entity?._id)) onUpdateTree?.(({ links, descendance }) => ({
                links,
                descendance: descendance.map(e => e?._id === entity._id ? entity : e)
            }));
        });
    }, [entitiesList, site, onUpdateTree]);

    const onRemoveEntity = useCallback(async id => {
        askConfirm().then(async confirm => {
            if (confirm) {
                let reply = await US.deleteSubmissionsAndRelatives([id]);
                if (reply?.data?.ok === 1) onUpdateTree(({ links, descendance }) => ({
                    descendance: descendance.filter(({ _id }) => _id !== id),
                    links: links.filter(({ input, output }) => ![input, output].includes(id))
                }));
                else showErrorModal(EC.CODE_DB_DELETE_FAIL);
            }
        })
    }, [showErrorModal, onUpdateTree]);

    const onAddEntity = useCallback(() => {
        askSelect({ isRequired: true, title: "Type de lien", options: linkTypesOptions, defaultVal: defaultLinkType }).then(choice => {
            if (choice !== null) {
                let { label, energy } = linkTypesOptions.filter(({ value }) => value === choice)?.[0] ?? {};
                addEntity?.(siteId, undefined, { linkType: label, linkId: choice, energyForced: energy });
            }
        });
    }, [siteId, defaultLinkType, linkTypesOptions, addEntity]);

    const dateFormatValChecker = useCallback((value, example) => {
        if (!moment(example, value, true).isValid()) return { isValid: false, message: "Format is invalid" };
        return true;
    }, []);

    const dateFormatDescription = useCallback((example) => <>
        <div>
            <span className="text-decoration-underline">Example:</span>
            <span className="ms-1">{example}</span>
        </div>
        <div className="text-muted fs-85">
            Pour afficher la liste des formats acceptés:
            <a className="ml-1" href="https://momentjs.com/docs/#/displaying/format/" rel="noreferrer" target="_blank">Cliquer ici</a>
        </div>
    </>, []);

    const prepDataForImport = useCallback(async ({ columns, dataFirstLine, dataLastLine }, csvFile) => {
        if (![columns, csvFile].every(TB.validObject)) return;
        if (typeof dataFirstLine !== "number") return;

        let timeStampCol = columns?.timestamp?.key;
        if (!TB.isExcelColumnName(timeStampCol)) return;

        let timeValues = Object.entries(csvFile)
            .filter(([key]) => key.replace(/\d/g, "") === timeStampCol && parseInt(key.replace(/\D/g, "")) >= dataFirstLine);

        let isTimeFormatted = timeValues
            .every(([key, item]) => TB.validObject(item?.v) && !isNaN(Date.parse(item?.v)));

        let timeStringExample = timeValues.filter(([key, item]) => TB.validString(item?.v))?.[0]?.[1]?.v;

        let dateFormatString;

        if (!isTimeFormatted) {
            let prom = askPrompt({
                isRequired: true,
                title: "Date Format",
                description: dateFormatDescription(timeStringExample),
                valChecker: val => dateFormatValChecker(val, timeStringExample),
                defaultVal: TB.validString(timeStringExample) ? timeStringExample.replace(/[\da-zA-Z]/g, "%") : undefined,
            })

            dateFormatString = await prom;
            ReactDom.unmountComponentAtNode(modalRef.current);
            if (!TB.validString(dateFormatString)) return;
        }

        let timezonePromise = new Promise(resolve => {
            US.getListTimeZones().then(({ data }) => {
                if (Array.isArray(data)) askSelect({ isRequired: true, title: "Timezone des data", defaultVal: TB.getBrowserTimeZone(), options: data.map(tz => ({ label: tz, value: tz })) })
                    .then((tz) => resolve(tz));
                else resolve(false)
            });
        });

        let tz = await timezonePromise;
        if (tz === null) return;
        if (tz === false) return showErrorModal("Erreur lors du chargement des timezones");

        let stations = site?.data?.stations;
        let entityValues = Object.entries(columns)
            .map(([id, data]) => [id.split('$$')[0], data])
            .filter(([id, { key }]) => TB.mongoIdValidator(id) && TB.isExcelColumnName(key))
            .map(([entityId, { key, realName, tagProp, factor, ...r }]) => {
                let arrayValues = [];
                let entityName = realName;

                let entityData = entitiesList.filter(({ _id }) => _id === entityId)?.[0]?.data;
                if (!TB.validObject(entityData)) entityData = {};

                let { th_tag, th_tin_tag, th_tout_tag, fuel_tag, th_power_tag, th_cons_tag, th_volume_tag, tag, tag_ppv, gas_tag, water_tag } = entityData;
                let { tag_id, station_id, comment_ } = [th_tag, th_tin_tag, th_tout_tag, fuel_tag, th_power_tag, th_cons_tag, th_volume_tag, tag, tag_ppv, gas_tag, water_tag]
                    .filter(TB.validObject)
                    // Manual tags always have id > 700000
                    .filter(({ tag_id }) => typeof tag_id === "number" && tag_id >= 700000)?.[0] ?? {};

                for (let i = dataFirstLine; i <= dataLastLine; i++) {
                    let value = !TB.validString(csvFile[key + i]?.v) ? NaN : Number(TB.replaceStringPart(csvFile[key + i]?.v, ",", "."));
                    let timeA = csvFile[timeStampCol + i]?.v;
                    let forcedTimeConversion = TB.validString(dateFormatString) ? moment(timeA, dateFormatString).toDate() : timeA;

                    if (!isNaN(forcedTimeConversion)) {
                        let time = momentz.tz(forcedTimeConversion, tz).unix();

                        if (typeof value === "number" && !isNaN(value)) {
                            if (TB.validString(factor)) {
                                let factoredValue = applyFactor(value, factor);
                                if (typeof factoredValue === "number" && !isNaN(factoredValue)) arrayValues.push({ time, value: factoredValue });
                            }
                            else arrayValues.push({ time, value });
                        }
                    }
                }
                return [entityId, { name: entityName, tagProp, value: arrayValues, tag_id, station_id, comment_ }];
            });

        if (entityValues.length === 0) alert("Aucun compteur selectionné");
        else if (entityValues.map(([key, data]) => data).some(({ name }) => !TB.validString(name))) alert("Impossible to find all the entity Names");
        else {
            let unmount = renderLoader();
            US.entityCSVImport(stations, entityValues, site?.data?.name, site?._id)
                .then(({ data }) => {
                    let { stations, entityUpdate } = TB.validObject(data) ? data : {};
                    if (!Array.isArray(stations)) stations = [];
                    if (!Array.isArray(entityUpdate)) entityUpdate = [];

                    if (data?.hasFailed || !TB.validObject(data)) {
                        unmount();
                        showErrorModal(`An error occured \n${data?.error}`);
                    }
                    else {
                        unmount();
                        onUpdateTree?.(({ links, descendance }) => ({
                            links,
                            descendance: descendance.map(node => {
                                if (node._id === site?._id) return { ...node, data: { ...node.data, stations } };
                                for (let { _id, tagProp, update, stationUpdate } of entityUpdate.filter(TB.validObject)) {
                                    if (node._id === _id) {
                                        let newNode = _.clone(node);
                                        newNode.data[tagProp] = update;
                                        newNode.data.station = stationUpdate;
                                        return newNode;
                                    }
                                }
                                return node;
                            })
                        }))
                    }
                })
                .catch(error => {
                    Alerts.updateError(error);
                    unmount();
                })
        }
    }, [entitiesList, dateFormatDescription, showErrorModal, dateFormatValChecker, onUpdateTree, site]);

    const getFormatExamples = useCallback(resolve => <div className="row g-2 align-items-stretch">
        <div className="col-md-6">
            <div className="card my-2">
                <img className="card-img-top" src={URL.APP_DOMAIN + URL.API_URL + `resources/img/${RESOURCE.ENTITY_CSV_TEMPLATE_A_IMG}`} alt="A" style={{ height: "14rem" }} />
                <div className="card-body">
                    <h5 className="card-title">Format A <small>(Recommended)</small></h5>
                    <p className="card-text">
                        Format recommandé.
                        Le nom du compteur doit être en en-tête de colonne.
                        Le temps est dans sa propre colonne.
                    </p>
                    <div className="d-flex align-items-center justify-content-between">
                        <button onClick={() => resolve('A')} className="btn btn-primary">
                            Choisir format A
                        </button>
                        <a href={URL.APP_DOMAIN + URL.API_URL + `resources/file/${RESOURCE.ENTITY_CSV_TEMPLATE_A}`}>
                            template_A.csv<i className="fa fa-file-download ml-2"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-md-6">
            <div className="card my-2">
                <img className="card-img-top" src={URL.APP_DOMAIN + URL.API_URL + `resources/img/${RESOURCE.ENTITY_CSV_TEMPLATE_B_IMG}`} alt="B" style={{ height: "14rem" }} />
                <div className="card-body">
                    <h5 className="card-title">Format B</h5>
                    <p className="card-text">
                        Le nom du compteur doit être la 1ère valeure.
                        Les lignes sans temps sont ignorées.
                        Seule la 1ère valeur numérique sera utilisée.
                    </p>
                    <div className="d-flex align-items-center justify-content-between">
                        <button onClick={() => resolve('B')} className="btn btn-primary">
                            Choisir format B
                        </button>
                        <a href={URL.APP_DOMAIN + URL.API_URL + `resources/file/${RESOURCE.ENTITY_CSV_TEMPLATE_B}`}>
                            template_B.csv<i className="fa fa-file-download ml-2"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>, []);

    const onImportCSV = useCallback(() => {
        renderBlankModal({ title: 'CSV Format', size: "md", renderContent: resolve => getFormatExamples(resolve) }).then(format => {
            askExcelImport({ onlyCsv: true, required: true, sheetSelect: true }).then(file => {
                if (TB.validObject(file)) {
                    let csvFile = format === "A" ? file : ENTITY_FORMAT_A_TO_B(file);

                    let mappingProm = new Promise(resolve => ReactDom.render(<ReduxWrapper>
                        <ExcelMapping
                            factorChange
                            allowSwapping
                            sheet={csvFile}
                            onValidate={resolve}
                            close={() => resolve(null)}
                            columnFormat={{ timestamp: { label: "TimeStamp", required: true }, ...entitiesMapOptions }}
                        />
                    </ReduxWrapper>, modalRef.current));

                    mappingProm.then(mapping => {
                        ReactDom.unmountComponentAtNode(modalRef.current);
                        if (TB.validObject(mapping)) prepDataForImport(mapping, csvFile);
                    });
                }
            });
        });
    }, [entitiesMapOptions, prepDataForImport, getFormatExamples]);
    //#endregion

    //#region Entity list
    const cols = useMemo(() => [
        getStaticText(TC.GLOBAL_NAME),
        getStaticText(TC.GLOBAL_LABEL_BUILD),
        getStaticText(TC.GLOBAL_LABEL_EMPLACEMENT),
        "Energie",
        "Type",
        ""
    ], [getStaticText]);

    const nbCols = useMemo(() => cols.length, [cols]);
    const tableHeader = useMemo(() => <thead><tr>{cols.map((label, i) => <th key={i}>{label}</th>)}</tr></thead>, [cols]);

    const tableFooter = useMemo(() => <tfoot className="text-start">
        <tr>
            <td className="p-2" colSpan={nbCols}>
                <button className="btn btn-primary mr-2" onClick={onAddEntity}>
                    <i className="fa fa-plus mr-2"></i> {getStaticText(TC.GLOBAL_LABEL_ENTITY)}
                </button>
                <button className="btn btn-primary stop-hiding ml-2" disabled={noEntities} onClick={onImportCSV}>
                    <i className="fa fa-file-csv mr-2"></i> {getStaticText(TC.IMPORT)} CSV
                </button>
            </td>
        </tr>
    </tfoot>, [noEntities, nbCols, onAddEntity, onImportCSV, getStaticText])

    const entitiesBody = useMemo(() => entitiesList.map(({ _id, data }) => <tr key={_id}>
        <td className="align-middle">{data?.name}</td>
        <td className="align-middle">{locationObj?.[_id]?.building?.data?.name ?? "-"}</td>
        <td className="align-middle">{getEmplacement(_id)}</td>
        <td className="align-middle">{energyObj[data?.typeEnergie] ?? data?.typeEnergie}</td>
        <td className="align-middle">{data?.typedecompteur}</td>
        <td>
            <button onClick={() => onEditEntity(_id)} className="btn btn-primary mr-1">
                <i className="fa fa-pencil-alt"></i>
            </button>
            <button onClick={() => onRemoveEntity(_id)} className="btn btn-danger ml-1">
                <i className="fa fa-times"></i>
            </button>
        </td>
    </tr>), [entitiesList, locationObj, energyObj, getEmplacement, onEditEntity, onRemoveEntity]);

    const noEntity = useMemo(() => <tr>
        <td colSpan={nbCols} className="p-2">
            <div className="alert alert-secondary" style={{ fontSize: "1rem" }}>
                No entities
            </div>
        </td>
    </tr>, [nbCols]);

    const tableBody = useMemo(() => <tbody className="fs-85">
        {entitiesList.length === 0 ? noEntity : entitiesBody}
    </tbody>, [entitiesList, noEntity, entitiesBody]);

    const entityTable = useMemo(() => <table className="table table-sm table-bordered text-center">
        {tableHeader}
        {tableBody}
        {tableFooter}
    </table>, [tableHeader, tableBody, tableFooter]);
    //#endregion

    return <div className="p-2">
        {entityTable}
        <div ref={modalRef}></div>
    </div>
};

export default EntityPanel;