import _ from "lodash";
import { LANG, TB, T } from "../Constants";
import * as US from "../services/user.service";

var hasFetch = false;
var lang = localStorage.getItem("lang") || LANG.FR_PROP;

type SYNC_UPDATE_LG_ID = {
    /** What kind of update it is */
    type: "add_object" | "update_object" | "add_static" | "update_static";
    /** The languages object */
    languages: T.AllowArray<T.LanguageObj>;
}

const langNumToProp = (lg: number): T.LanguageProps => {
    let lang = LANG.ALL_LANGUAGES.filter(l => l.index === lg)[0];
    return (lang?.prop as T.LanguageProps) || LANG.EN_PROP;
}

const storedLanguages: T.ReduxFn<T.LanguageStoredRedux, T.StoredLanguageActions> = (state = { static: [], object: [] }, payload) => {
    if (payload?.type === "ASYNC_UPDATE_LG") {
        let [objects, statics] = _.partition(payload.action as T.LanguageObj[], s => TB.mongoIdValidator(s._id));
        return { object: state.object.concat(objects), static: state.static.concat(statics) };
    }
    /* @ts-ignore Changed language, reload */
    else if (payload?.type === "CHANGE_LANG") {
        hasFetch = false;
        lang = langNumToProp(payload?.action);

        US.getLangTranslations(lang)
            .then(({ data }) => data.length > 0 && payload?.asyncDispatch?.({ type: "ASYNC_UPDATE_LG", action: data }))
            .catch(() => hasFetch = false);
        return { static: [], object: [] };
    }
    // Update the state with the new objects
    else if (payload?.type === "SYNC_UPDATE_LG_ID") {
        let new_state = { ...state };
        let updates = TB.arrayWrapper(payload.action as SYNC_UPDATE_LG_ID);
        if (updates.length === 0) return state;
        else {
            for (let update of updates) {
                let languages = TB.arrayWrapper(update.languages);
                if (languages.length > 0) {
                    if (update.type === "add_object") new_state.object = new_state.object.concat(languages);
                    else if (update.type === "add_static") new_state.static = new_state.static.concat(languages);
                    else if (update.type === "update_static") new_state.static = new_state.static.map(o => languages.find(l => l.ref === o.ref) || o);
                    else if (update.type === "update_object") new_state.object = new_state.object.map(o => languages.find(l => l._id === o._id && l.prop === o.prop) || o);
                }
            }
            return new_state;
        }
    }
    // Default load
    else if ((!hasFetch || payload?.type === "FORCE_LG_LOAD") && payload?.asyncDispatch) {
        // Don't reload if already loaded
        if (!hasFetch) {
            hasFetch = true;

            US.getLangTranslations(lang)
                .then(({ data }) => data.length > 0 && payload?.asyncDispatch?.({ type: "ASYNC_UPDATE_LG", action: data }))
                .catch(() => hasFetch = false);
            return { static: [], object: [] };
        }
        else return state;
    }
    else return state;
}

export default storedLanguages;

export const forceLoadLg: T.ReduxDispatch<T.StoredLanguageActions, T.AllowArray<string>> = () => ({ type: "FORCE_LG_LOAD" });
export const getObjectsLg: T.ReduxDispatch<T.StoredLanguageActions, T.AllowArray<string>> = ids => ({ type: "FETCH_OBJECTS", action: ids });
export const getStaticsLg: T.ReduxDispatch<T.StoredLanguageActions, T.AllowArray<string>> = refs => ({ type: "FETCH_STATICS", action: refs });
export const sync_update_objects_lg: T.ReduxDispatch<T.StoredLanguageActions, T.AllowArray<SYNC_UPDATE_LG_ID>> = params => ({ type: "SYNC_UPDATE_LG_ID", action: params });