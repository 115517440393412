import { TB } from "../Constants";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

type useStateNumOptions = {
    min?: number;
    max?: number;
    default?: number;
    allowNaN?: boolean;
    allowFloat?: boolean;
}

interface setParamsType extends Omit<useStateNumOptions, "default"> { };
type setParamsFn = (options: setParamsType) => void;

type useStateNumType = (options?: useStateNumOptions) => [number | undefined, (val?: number | string) => void, setParamsFn];

const useStateNum: useStateNumType = (options) => {
    const [num, setNum] = useState<number>();
    const appliedDefaultRef = useRef<boolean>(false);
    const [params, setParams] = useState<useStateNumOptions>(TB.getObject(options));

    const allowNaN = useMemo(() => params?.allowNaN === true, [params?.allowNaN]);
    const { min, max } = useMemo(() => TB.validObject(params) ? params : {}, [params]);
    const allowFloat = useMemo(() => params?.allowFloat === false ? false : true, [params?.allowFloat]);

    const setState = useCallback((value?: number | string) => {
        if (typeof value !== "number") value = TB.getNumber(value);
        if (isNaN(value)) setNum(allowNaN ? value : undefined);
        else {
            if (typeof min === "number" && value < min) value = min;
            else if (typeof max === "number" && value > max) value = max;

            if (allowFloat) setNum(value);
            else setNum(Math.round(value));
        }
    }, [allowNaN, allowFloat, min, max]);

    const updateParams = useCallback<setParamsFn>(options => setParams(p => ({ ...TB.getObject(p), ...TB.getObject(options) })), []);

    useEffect(() => {
        if (!appliedDefaultRef.current && typeof options?.default === "number") setState(options.default);
        appliedDefaultRef.current = true;
    }, [setState, options?.default]);

    return [num, setState, updateParams];
}

export default useStateNum;