import axios from "axios";
import { getHeader } from "../Headers";
import { T, URL } from "../../Constants";

const { MAINTENANCE_API, ACTION_API } = URL;

//#region Types
type GetPlanner = T.API.ToAxios<T.API.Actions.GetPlanner>;
type UpdateEvent = T.API.ToAxios<T.API.Actions.UpdateEvent>;
type RemoveEvents = T.API.ToAxios<T.API.Actions.RemoveEvents>;
type RemoveTickets = T.API.ToAxios<T.API.Actions.RemoveTickets>;
type GetPlannerActions = T.API.ToAxios<T.API.Actions.GetPlannerActions>;
type TicketClosingData = T.API.ToAxios<T.API.Actions.TicketClosingData>;
type UpdateEventCalendar = T.API.ToAxios<T.API.Actions.UpdateEventCalendar>;
type DeleteEvents = (ids: string | string[], deleteTickets?: boolean) => T.AxiosPromise<{ ok: 1 }>;
type CloseTickets = T.API.ToAxios<(tickets: string[], signingUrl: string, afterImages?: { _id: string, file: T.File }[], emptyRegDoc?: string[]) => void>;
//#endregion

export const getPlanner: GetPlanner = context => axios.post(ACTION_API + "planner", context);
export const getActionsPlanner: GetPlannerActions = ids => axios.post(ACTION_API + "tickets_planner", { ids });
export const updateEvent: UpdateEvent = params => axios.post(ACTION_API + "events/update", params, getHeader("EDIT"));
export const removeEvents: RemoveEvents = params => axios.post(ACTION_API + "events/remove", params, getHeader("DELETE"));
export const removeTickets: RemoveTickets = params => axios.post(ACTION_API + "tickets/remove", params, getHeader("DELETE"));
export const updateEventCalendar: UpdateEventCalendar = params => axios.post(ACTION_API + "event/calendar", params, getHeader("EDIT"));

/**
 * @deprecated
 */
export const deleteEvents: DeleteEvents = (ids, deleteTickets) => axios.post(MAINTENANCE_API + "removeEvents", { ids, deleteTickets }, getHeader("DELETE"));


export const closeTickets: CloseTickets = (t, s, a, e) => axios.post(MAINTENANCE_API + "closeTickets", { tickets: t, signingUrl: s, afterImages: a, emptyRegDoc: e }, getHeader("EDIT"));

/** Get the necessary data to sign a ticket on it's own */
export const ticketClosingData: TicketClosingData = ticket => axios.get(ACTION_API + "tickets/closing_data", { params: { ticket } });