import axios from "axios";
import { getHeader } from "../Headers";
import { T, URL } from "../../Constants";

const { API_URL, NRJ_API } = URL;

type GetData = T.API.ToAxios<T.API.Utils.Energy.GetData>;
type GetEntries = T.API.ToAxios<T.API.Utils.Energy.GetEntries>;
type EditRecord = T.API.ToAxios<T.API.Utils.Energy.EditRecord>;
type RemoveEntry = T.API.ToAxios<T.API.Utils.Energy.RemoveEntry>;
type UpdateAlarm = T.API.ToAxios<T.API.Utils.Energy.UpdateAlarm>;
type RestoreEntry = T.API.ToAxios<T.API.Utils.Energy.RestoreEntry>;
type RemoveAlarms = T.API.ToAxios<T.API.Utils.Energy.RemoveAlarms>;
type GetAlarmsRows = T.API.ToAxios<T.API.Utils.Energy.GetAlarmsRows>;
type ImportRecords = T.API.ToAxios<T.API.Utils.Energy.ImportRecords>;
type GetAlertsRows = T.API.ToAxios<T.API.Utils.Energy.GetAlertsRows>;
type EntityDataCsv = T.API.ToAxios<T.API.Utils.Energy.EntityDataCsv>;
type EditRecordTime = T.API.ToAxios<T.API.Utils.Energy.EditRecordTime>;
type AddManualEntry = T.API.ToAxios<T.API.Utils.Energy.AddManualEntry>;
type EditAlertField = T.API.ToAxios<T.API.Utils.Energy.EditAlertField>;
type EditAlarmField = T.API.ToAxios<T.API.Utils.Energy.EditAlarmField>;
type GetAlarmMailing = T.API.ToAxios<T.API.Utils.Energy.GetAlarmMailing>;
type AddPresetSchedule = T.API.ToAxios<T.API.Utils.Energy.AddPresetSchedule>;
type CreateMailingTask = T.API.ToAxios<T.API.Utils.Energy.CreateMailingTask>;
type UpdateMailingTask = T.API.ToAxios<T.API.Utils.Energy.UpdateMailingTask>;
type RemoveMailingTask = T.API.ToAxios<T.API.Utils.Energy.RemoveMailingTask>;
type CancelPeriodUpdate = T.API.ToAxios<T.API.Utils.Energy.CancelPeriodUpdate>;
type GetPresetsSchedule = T.API.ToAxios<T.API.Utils.Energy.GetPresetsSchedule>;
type RemoveAlarmsAlerts = T.API.ToAxios<T.API.Utils.Energy.RemoveAlarmsAlerts>;
type GetAlarmsRowsDataset = T.API.ToAxios<T.API.Utils.Energy.GetAlarmsRowsDataset>;
type GetAlarmsRowsFromIds = T.API.ToAxios<T.API.Utils.Energy.GetAlarmsRowsFromIds>;
type RemovePresetSchedule = T.API.ToAxios<T.API.Utils.Energy.RemovePresetSchedule>;
type GetAlertsRowsDataset = T.API.ToAxios<T.API.Utils.Energy.GetAlertsRowsDataset>;
type ImportMultipleRecords = T.API.ToAxios<T.API.Utils.Energy.ImportMultipleRecords>;
type UpdateAlarmAssignation = T.API.ToAxios<T.API.Utils.Energy.UpdateAlarmAssignation>;
type UnsubscribeMailingTask = T.API.ToAxios<T.API.Utils.Energy.UnsubscribeMailingTask>;

/** Query data from the data API */
export const getData: GetData = params => axios.post(NRJ_API + "get/data", params);
/** Generate and download an excel of the values of datasets */
export const entityDataCsv: EntityDataCsv = params => axios.post(API_URL + "entityDataCsv", params);
/** Get a dataset's entries */
export const getEntries: GetEntries = params => axios.post(NRJ_API + "entries", params);
/** Create a new entry manually */
export const addManualEntry: AddManualEntry = params => axios.post(NRJ_API + "entry/add", params, getHeader("OTHER"));
/** Remove an existing data entry */
export const removeDataEntry: RemoveEntry = params => axios.post(NRJ_API + "entry/remove", params, getHeader("OTHER"));
/** Re-install a deleted entry */
export const restoreDataEntry: RestoreEntry = params => axios.post(NRJ_API + "entry/restore", params, getHeader("OTHER"));
/** Edit a record */
export const editRecordEntry: EditRecord = params => axios.post(NRJ_API + "entry/edit", params, getHeader("OTHER"));
/** Import a collection on records */
export const importRecords: ImportRecords = params => axios.post(NRJ_API + "entry/import", params, getHeader("OTHER"));
/** Import a collection of records for multiple datasets */
export const importMultipleRecords: ImportMultipleRecords = entries => axios.post(NRJ_API + "entry/multi_import", { entries }, getHeader("OTHER"));
/** Update or create an alarm */
export const updateAlarm: UpdateAlarm = alarm => axios.post(NRJ_API + "alarm/update", alarm, getHeader("EDIT"));
/** Get the alarms rows for in a context */
export const getAlarmsRows: GetAlarmsRows = params => axios.post(NRJ_API + "alarm/rows", params);
/** Get the alarms rows for a dataset */
export const getAlarmsRowsDataset: GetAlarmsRowsDataset = dataset => axios.post(NRJ_API + "alarm/rows_dataset", { dataset });
/** Get alarms rows based on alarms ids */
export const getAlarmsRowsFromIds: GetAlarmsRowsFromIds = ids => axios.post(NRJ_API + "alarms/rows_ids", { ids });
/** Remove an alarms from the database */
export const removeAlarms: RemoveAlarms = ids => axios.post(NRJ_API + "alarm/remove", { ids }, getHeader("DELETE"));
/** Get a list of presets schedules for a dataset id */
export const getPresetsSchedule: GetPresetsSchedule = context => axios.post(NRJ_API + "schedule/get", context);
/** Add or edit an existing schedule preset */
export const addPresetSchedule: AddPresetSchedule = params => axios.post(NRJ_API + "schedule/edit", params, getHeader("EDIT"));
/** Remove an existing schedule preset */
export const removePresetSchedule: RemovePresetSchedule = params => axios.post(NRJ_API + "schedule/remove", params, getHeader("DELETE"));
/** Get a list of alerts for a context */
export const getAlertsRows: GetAlertsRows = params => axios.post(NRJ_API + "alerts/rows", params);
/** Get a list of alerts for a dataset */
export const getAlertsRowsDataset: GetAlertsRowsDataset = params => axios.post(NRJ_API + "alerts/rows_dataset", params);
/** Update an alarm's dataset assignation */
export const updateAlarmAssignation: UpdateAlarmAssignation = params => axios.post(NRJ_API + "alarms/assignation", params, getHeader("EDIT"));
/** Update an alert through the alert table */
export const editAlertField: EditAlertField = params => axios.post(NRJ_API + "alert/edit", params, getHeader("EDIT"));
/** Update an alarm through the alarm table */
export const edit_alarm_field: EditAlarmField = params => axios.post(NRJ_API + "alarm/edit", params, getHeader("EDIT"));
/** Removes all alerts for an alarm */
export const removeAlarmsAlerts: RemoveAlarmsAlerts = alarms => axios.post(NRJ_API + "alert/remove", { alarms }, getHeader("DELETE"));
/** Get the list of mailing tasks for an alarm, and a list of user */
export const getAlarmMailing: GetAlarmMailing = site_id => axios.post(NRJ_API + "alarms/mailing", { site_id });
/** Create a mailing auto task */
export const createMailingTask: CreateMailingTask = site_id => axios.post(NRJ_API + "mailing/create", { site_id }, getHeader("CREATE"));
/** Update a mailing auto task */
export const updateMailingTask: UpdateMailingTask = params => axios.post(NRJ_API + "mailing/update", params, getHeader("EDIT"));
/** Delete a mailing auto task */
export const removeMailingTask: RemoveMailingTask = task_id => axios.post(NRJ_API + "mailing/remove", { task_id }, getHeader("DELETE"));
/** Unsubscribe a user from a mailing task */
export const unsubscribeMailingTask: UnsubscribeMailingTask = task_id => axios.post(NRJ_API + "mailing/unsubscribe", { task_id }, getHeader("EDIT"));
/** Cancel a period update of the entries */
export const cancelPeriodUpdate: CancelPeriodUpdate = params => axios.post(NRJ_API + "entry/cancel_period_update", params, getHeader("EDIT"));
/** Edit a record's time */
export const editRecordTime: EditRecordTime = params => axios.post(NRJ_API + "entry/edit_time", params, getHeader("EDIT"));