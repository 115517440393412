import React from "react";
import * as H from "../../../../hooks";
import { TB } from "../../../../Constants";
import { ExtendedICellRendererParams } from "../../TableTypes";

export const type = "agCustomFrequency";

export const sort = (a: string, b: string) => {
    let freqA = TB.splitFrequency(a),
        freqB = TB.splitFrequency(b);

    // Equal frequency
    if (a === b) return 0;
    // Both null
    if (freqA === null && freqB === null) return 0;
    // One is null but not the other
    if (freqA === null) return -1;
    if (freqB === null) return 1;
    // Compare frequencies
    let scoreA = 0, scoreB = 0;

    if (freqA.unit === "m") scoreA = 1;
    else if (freqA.unit === "h") scoreA = 2;
    else if (freqA.unit === "d") scoreA = 3;
    else if (freqA.unit === "w") scoreA = 4;
    else if (freqA.unit === "M") scoreA = 5;
    else if (freqA.unit === "y") scoreA = 6;

    if (freqB.unit === "m") scoreB = 1;
    else if (freqB.unit === "h") scoreB = 2;
    else if (freqB.unit === "d") scoreB = 3;
    else if (freqB.unit === "w") scoreB = 4;
    else if (freqB.unit === "M") scoreB = 5;
    else if (freqB.unit === "y") scoreB = 6;

    // Same unit, pick based on number
    if (scoreA === scoreB) return freqA.num - freqB.num;
    // Sort based on the unit
    return scoreA - scoreB;
};

export const Cell = React.forwardRef<{}, ExtendedICellRendererParams>((props, ref) => {
    const lg = H.useLanguage();

    const translated = React.useMemo(() => {
        let frequency = props.getValue?.();
        let translation = TB.translate_frequency(frequency || "");
        if (translation.ref) return translation.amount + " " + lg.getStaticText(translation.ref);
        else return "";
    }, [lg, props]);

    return <>{translated}</>;
});