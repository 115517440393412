import { ModalFormulaProps, ModalFormula } from "../Components";
import renderInContainer from "../getContainer";

export type RenderModalFormula = Omit<ModalFormulaProps, "onChange">;

const renderModalFormula = (params: RenderModalFormula) => new Promise<Parameters<ModalFormulaProps["onChange"]>[0]>(resolve => {
    const [render, dismount] = renderInContainer();
    if (render && dismount) {
        const onChange: ModalFormulaProps["onChange"] = formula => dismount(() => resolve(formula));
        render(<ModalFormula {...params} onChange={onChange} />);
    }
    else resolve(null);
});

export default renderModalFormula;