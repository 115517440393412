import * as T from "../Types";

export const InfoBubblesOptions = {
    variant: [
        { bs: "info", value: "info", label: "info" },
        { bs: "danger", value: "error", label: "error" },
        { bs: "warning", value: "warning", label: "warning" },
        { bs: "success", value: "success", label: "success" },
    ] as T.Option<Record<"bs", string>, T.InfosBubble["variant"]>[],
    size: [
        { value: "xs", label: "XS" },
        { value: "sm", label: "SM" },
        { value: "md", label: "MD" },
        { value: "lg", label: "LG" },
        { value: "xl", label: "XL" },
    ] as T.Option<{}, T.InfosBubble["size"]>[],
}