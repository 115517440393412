import React from "react";
import * as M from "../../Modal";
import * as G from "../../Gestion";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import * as S from "../../../services";
import { FP, T, TC, TB, URL } from "../../../Constants";
import * as AR from "@adaptabletools/adaptable-react-aggrid";

export type GeneralProps = {
    /** The data about the site and the building */
    data: ReturnType<T.API.Utils.Missions.MissionResumeNRJ>["general"];
    /** Callback to update the data after a change */
    update_data: (data: GeneralProps["data"]) => void;
    /** Do not allow any data edit */
    read_only: boolean;
    /** The mission's asset _id */
    asset_id: string;
}

type Layout = Record<"style", AR.FormatColumn> & Record<"layout", AR.Layout>;

const General: React.FC<GeneralProps> = ({ update_data, ...props }) => {
    const lg = H.useLanguage();
    const [forms] = H.useFormIds();
    const emp_table = React.useRef<G.EmplacementTableRef>(null);
    const [parking_area, set_parking_area] = React.useState(NaN);
    const table_layout = React.useRef<Layout>({ layout: null, style: null });
    const [regions, set_regions, region_status] = H.useAsyncState<T.Option[]>([]);
    const [countries, set_countries, country_status] = H.useAsyncState<T.Option[]>([]);
    const [affectations, set_affectations, affectations_status] = H.useAsyncState<T.Option[]>([]);
    const [weather_stations, set_weather_stations, weather_stations_status] = H.useAsyncState<T.Option[]>([]);

    //#region Load options
    React.useEffect(() => {
        let isSubscribed = true;
        S.getCountries(lg.prop)
            .then(({ data }) => isSubscribed && set_countries(data, "done"))
            .catch(() => isSubscribed && set_countries([], "error"));
        return () => {
            isSubscribed = false;
            set_countries([], "load");
        }
    }, [set_countries, lg.prop]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getRegions(props.data?.site?.country)
            .then(({ data }) => isSubscribed && set_regions(data, "done"))
            .catch(() => isSubscribed && set_regions([], "error"));
        return () => {
            isSubscribed = false;
            set_regions([], "load");
        }
    }, [set_regions, props.data?.site?.country]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getWeatherStations()
            .then(({ data }) => isSubscribed && set_weather_stations(data, "done"))
            .catch(() => isSubscribed && set_weather_stations([], "error"));
        return () => {
            isSubscribed = false;
            set_weather_stations([], "load");
        }
    }, [set_weather_stations]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getEmplacementResource({ type: "emplacement_affectation", lang: lg.prop })
            .then(({ data }) => isSubscribed && set_affectations(data as T.Option[], "done"))
            .catch(() => isSubscribed && set_affectations([], "error"));
        return () => {
            isSubscribed = false;
            set_affectations([], "load");
        }
    }, [set_affectations, lg.prop]);
    //#endregion

    //#region Events
    const events = React.useMemo(() => ({
        update_site: (prop: keyof GeneralProps["data"]["site"], value: any) => {
            const updatePromise = new Promise<"updated" | "non_updated">((resolve, reject) => {
                let api_params = {
                    field: prop,
                    new_value: value,
                    _id: props.data?.site?._id,
                    old_value: props.data?.site?.[prop],
                } as Parameters<typeof S.editSiteField>[0];

                S.editSiteField(api_params).then(({ data }) => {
                    if (data === "changed") M.askConfirm({ title: TC.UPDATE_FORCE_CHANGE, text: TC.UPDATE_VALUE_UNFRESH }).then(confirmed => {
                        if (confirmed) S.editSiteField({ ...api_params, force_update: true }).then(({ data }) => {
                            if (data === "changed") reject("Error");
                            else resolve("updated");
                        }).catch(reject);
                        else resolve("non_updated");
                    });
                    else resolve("updated");
                }).catch(reject);
            });

            updatePromise.then(state => {
                if (state === "updated") update_data({ ...props.data, site: { ...props.data?.site, [prop]: value } });
            }).catch(M.Alerts.updateError);
        },
        update_building: (prop: keyof GeneralProps["data"]["building"], value: any) => {
            const updatePromise = new Promise<"updated" | "non_updated">((resolve, reject) => {
                let api_params = {
                    field: prop,
                    new_value: value,
                    _id: props.data?.building?._id,
                    old_value: props.data?.building?.[prop],
                } as Parameters<typeof S.editBuildingField>[0];

                S.editBuildingField(api_params).then(({ data }) => {
                    if (data === "changed") M.askConfirm({ title: TC.UPDATE_FORCE_CHANGE, text: TC.UPDATE_VALUE_UNFRESH }).then(confirmed => {
                        if (confirmed) S.editBuildingField({ ...api_params, force_update: true }).then(({ data }) => {
                            if (data === "changed") reject("Error");
                            else resolve("updated");
                        }).catch(reject);
                        else resolve("non_updated");
                    });
                    else resolve("updated");
                }).catch(reject);
            });

            updatePromise.then(state => {
                if (state === "updated") update_data({ ...props.data, building: { ...props.data?.building, [prop]: value } });
            }).catch(M.Alerts.updateError);
        },
        change_picture: (files: T.File[]) => {
            let file = files[0];
            // No file somehow, or file was removed
            if (!file || file.storage === "removed") events.update_building("picture", []);
            // New file uploaded
            else if (file.storage === "temp") {
                // Move the file out of temporary storage
                S.moveTempFile({ name: file.name, id: props.data?.building?._id }).then(() => {
                    let url = URL.CRAFT_FILE_URL(props.data?.building?._id, file.name);
                    events.update_building("picture", [{ ...file, storage: "local", url }]);
                }).catch(M.Alerts.failedFileUpload);
            }
        },
    }), [props.data, update_data]);
    //#endregion

    //#region Calculate parking area
    const calculate_area = React.useCallback(() => {
        setTimeout(() => {
            let rows = emp_table.current?.rows || [];
            let props: (keyof T.EmplacementData)[] = ["surfaceNFA"];
            let sum = rows.reduce((previous, current) => previous + TB.getNumber(current.surfaceNFA, 0), 0);

            // Update the table, leave it some time to update
            if (emp_table.current?.grid?.current) setTimeout(() => {
                let grid = emp_table.current?.grid?.current?.grid;
                let adaptable = emp_table.current?.grid?.current?.adaptable;
                // Expand all the rows
                grid?.api?.expandAll?.();
                // Remove previous layout
                if (table_layout.current.layout) adaptable?.layoutApi?.deleteLayout?.(table_layout.current.layout);
                if (table_layout.current.style) adaptable?.formatColumnApi?.deleteFormatColumn?.(table_layout.current.style);
                // Adapt the layout to the props under review
                if (props.length > 0) {
                    let style = adaptable?.formatColumnApi?.addFormatColumn?.({
                        Source: "User",
                        IncludeGroupedRows: true,
                        Scope: { ColumnIds: props },
                        Rule: { Predicates: [{ Inputs: [], PredicateId: "Blanks" }] },
                        Style: { ClassName: "", FontStyle: "Normal", BackColor: "#FFA500", FontWeight: "Normal", ForeColor: "#FFFFFF", BorderColor: "#FF0000" },
                    });

                    let baseColumns = adaptable?.columnApi?.getColumns?.()?.map?.(c => c.field)?.filter?.(f => !props.includes(f as any));
                    let endCols = baseColumns.splice(1, baseColumns.length - 1, ...props);
                    baseColumns = baseColumns.concat(endCols);
                    let layout = adaptable.layoutApi.createAndSetLayout({ Columns: baseColumns, Name: "nrj_report" });

                    table_layout.current.style = style;
                    if (layout) table_layout.current.layout = layout;
                }
                else {
                    table_layout.current.style = null;
                    table_layout.current.layout = null;
                }
            }, 100);
            set_parking_area(sum);
        }, 1000);
    }, []);
    //#endregion

    return <C.Spinner loading={!props.data}>
        <>
            <div>
                <C.Title level={4} className="mb-3" text={lg.getStaticText(FP.SITE_FORM) + ` - ${props.data?.site?.name}`} />

                <C.Form.Select
                    required
                    no_clear_btn
                    options={countries}
                    labelPosition="left"
                    disabled={props.read_only}
                    value={props.data?.site?.country}
                    loading={country_status === "load"}
                    label={{ _id: forms[FP.SITE_FORM], prop: "country" }}
                    onChange={value => events.update_site("country", value)}
                />

                <C.Form.Select
                    required
                    no_clear_btn
                    options={regions}
                    labelPosition="left"
                    disabled={props.read_only}
                    value={props.data?.site?.region}
                    loading={region_status === "load"}
                    label={{ _id: forms[FP.SITE_FORM], prop: "region" }}
                    onChange={value => events.update_site("region", value)}
                />

                <C.Form.Select
                    required
                    no_clear_btn
                    labelPosition="left"
                    options={weather_stations}
                    disabled={props.read_only}
                    value={props.data?.site?.weather_station}
                    loading={weather_stations_status === "load"}
                    label={{ _id: forms[FP.SITE_FORM], prop: "weather_station" }}
                    onChange={value => events.update_site("weather_station", value)}
                />
            </div>

            <div>
                <C.Title level={4} className="mb-3" text={lg.getStaticText(FP.BUILDING_FORM) + ` - ${props.data?.building?.name}`} />

                <BS.Row>
                    <BS.Col>
                        <C.Form.NumField
                            labelPosition="left"
                            disabled={props.read_only}
                            value={props.data?.building?.ENT_TECH_BUILD_YEAR}
                            label={{ _id: forms[FP.BUILDING_FORM], prop: "ENT_TECH_BUILD_YEAR" }}
                            onChange={value => events.update_building("ENT_TECH_BUILD_YEAR", value)}
                        />

                        <C.Form.Select
                            no_clear_btn
                            labelPosition="left"
                            options={affectations}
                            disabled={props.read_only}
                            loading={affectations_status === "load"}
                            value={props.data?.building?.affectation}
                            label={{ _id: forms[FP.BUILDING_FORM], prop: "affectation" }}
                            onChange={value => events.update_building("affectation", value)}
                        />

                        <C.Form.TextField
                            textArea
                            rows={3}
                            autoExpand
                            labelPosition="left"
                            disabled={props.read_only}
                            value={props.data?.building?.opening_hours}
                            label={{ _id: forms[FP.BUILDING_FORM], prop: "opening_hours" }}
                            onChange={value => events.update_building("opening_hours", value)}
                        />

                        <C.Form.NumField
                            suffix="m²"
                            labelPosition="left"
                            disabled={props.read_only}
                            value={props.data?.building?.surfaceNFA}
                            label={{ _id: forms[FP.BUILDING_FORM], prop: "surfaceNFA" }}
                            onChange={value => events.update_building("surfaceNFA", value)}
                        />

                        <C.Form.NumField
                            required
                            suffix="m²"
                            labelPosition="left"
                            disabled={props.read_only}
                            value={props.data?.building?.surfaceHEAT}
                            label={{ _id: forms[FP.BUILDING_FORM], prop: "surfaceHEAT" }}
                            onChange={value => events.update_building("surfaceHEAT", value)}
                        />

                        <C.Form.NumField
                            suffix="m²"
                            labelPosition="left"
                            disabled={props.read_only}
                            value={props.data?.building?.surfaceCold}
                            label={{ _id: forms[FP.BUILDING_FORM], prop: "surfaceCold" }}
                            onChange={value => events.update_building("surfaceCold", value)}
                        />

                        <C.Form.NumField
                            disabled
                            readonly
                            suffix="m²"
                            value={parking_area}
                            labelPosition="left"
                            label={TC.PARKING_SURFACES_TOTAL}
                        />
                    </BS.Col>
                    <BS.Col md={5}>
                        <C.Form.FileUploader
                            image
                            confirm_remove
                            disabled={props.read_only}
                            onChange={events.change_picture}
                            value={props.data?.building?.picture}
                        />
                    </BS.Col>
                </BS.Row>
            </div>

            <C.Flex className="mt-1" style={{ height: "600px" }}>
                <G.Emplacement
                    ref={emp_table}
                    restrict_type="parking"
                    on_edit={calculate_area}
                    view="nrj_mission_parking"
                    on_create={calculate_area}
                    on_delete={calculate_area}
                    on_data_loaded={calculate_area}
                    context={React.useMemo(() => ({ roots: props.asset_id }), [props.asset_id])}
                />
            </C.Flex>
        </>
    </C.Spinner>;
}

export default General;