import _ from "lodash";
import React from "react";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import { T, TB, TC } from "../../../Constants";
import * as US from "../../../services/user.service";
import { StyleModalProps, default as Modal } from "../BlankModal";

export type TimeFormatterProps = {
    /** A default time format */
    format?: string;
    /** Callback for closing the modal */
    onClose?: () => void;
    /** Extra params to change the modal */
    modal?: StyleModalProps;
    /** A list of date, to test out the format */
    examples?: T.AllowArray<string>;
    /** Callback for validation of the value */
    onConfirm?: (params?: { format: string, timezone: string, timezone_data: string }) => void;
}

const TEXT_CODES = [TC.TIME_FORMATTER_INVALID, TC.TIME_FORMATTER_VALID, TC.TIME_FORMATTER_DESC];

const TimeFormatter: React.FC<TimeFormatterProps> = ({ onConfirm, ...props }) => {
    const lg = H.useLanguage(TEXT_CODES);
    const [format, setFormat] = React.useState(props.format || "");
    const [zones, setZones, status] = H.useAsyncState<T.Option[]>([]);
    const [{ zone, zone_data }, setZone] = React.useState<Record<"zone" | "zone_data", string>>({ zone: "", zone_data: "" });

    //#region Fetch Timezones
    const setTimeZone = React.useCallback((value: string) => {
        let option = zones.filter(z => z.value === value)[0];
        if (option) setZone({ zone: option.label, zone_data: value });
        else setZone({ zone: "", zone_data: "" });
    }, [zones]);

    React.useEffect(() => {
        let isSubscribed = true;
        US.getListTimeZones().then(({ data }) => {
            if (isSubscribed) {
                let option = data.filter(z => z.label === TB.getBrowserTimeZone())[0];
                if (option) setZone({ zone: option.label, zone_data: option.value });
                else setZone({ zone: "", zone_data: "" });
                setZones(data, "done");
            }
        }).catch(() => isSubscribed && setZones([], "error"));
        return () => { isSubscribed = false };
    }, [setZones]);
    //#endregion

    //#region Formatted Date
    const formattedDate = React.useMemo(() => {
        let dates = TB.arrayWrapper(props.examples).filter(TB.validString);

        return dates.map(date => {
            // Local time must not change when timezone change
            let local_time = TB.parseDate(date, format);
            // UTC time will change when timezone change
            let utc_time = zone && zone_data
                ? TB.parseDate(date, format, zone, zone_data)?.utc?.(zone === "Etc/UTC")
                : null;

            return {
                base: date,
                valid: local_time?.isValid?.(),
                utc: utc_time?.format?.("DD-MM-YYYY HH:mm") || "",
                local: local_time?.format?.("DD-MM-YYYY HH:mm") || "",
            }
        });
    }, [props.examples, format, zone, zone_data]);

    const examples = React.useMemo(() => {
        if (formattedDate.length === 0) return null;
        let [valid, invalid] = _.partition(formattedDate, d => d.valid);
        let a = _.take(valid, 6), b = _.take(invalid, 6);
        return { valid: a, invalid: b };
    }, [formattedDate]);

    const examplesContainer = React.useMemo(() => examples && <div className="fs-85 mt-2">
        <BS.Row className="text-center g-1">
            <BS.Col md={8}>
                <BS.Row children={<BS.Col children={<C.Title level={5} text={TC.TIME_FORMATTER_VALID} />} />} />
                <BS.Row className="g-1">
                    <BS.Col>{lg.getStaticText(TC.DATE_FORMATTER_BASE_STR)}</BS.Col>
                    <BS.Col>{lg.getStaticText(TC.DATE_FORMATTER_LOCAL_TIME)}</BS.Col>
                    <BS.Col>{lg.getStaticText(TC.DATE_FORMATTER_UTC_TIME)}</BS.Col>
                </BS.Row>
                {examples.valid.map((d, i) => <BS.Row className="text-success g-1" key={i}>
                    <BS.Col>{d.base}</BS.Col>
                    <BS.Col>{d.local}</BS.Col>
                    <BS.Col>{d.utc}</BS.Col>
                </BS.Row>)}
            </BS.Col>
            <BS.Col md={4}>
                <C.Title level={5} text={TC.TIME_FORMATTER_INVALID} />
                {examples.invalid.map((d, i) => <div key={i} className="text-danger">{d.base}</div>)}
            </BS.Col>
        </BS.Row>
    </div >, [examples, lg]);

    const canConfirm = React.useMemo(() => formattedDate.every(d => d.valid) && zone && zone_data, [formattedDate, zone, zone_data]);
    //#endregion

    //#region Extra
    const footer = React.useMemo(() => <C.Flex justifyContent="end">
        <C.Button icon={{ icon: "check" }} disabled={!canConfirm} onClick={() => onConfirm?.({ format, timezone: zone, timezone_data: zone_data })}>
            {TC.GLOBAL_CONFIRM}
        </C.Button>
    </C.Flex>, [canConfirm, format, zone, zone_data, onConfirm]);

    const description = React.useMemo(() => <div className="fs-85 text-muted">
        {lg.getStaticText(TC.TIME_FORMATTER_DESC)} :
        <a href="https://momentjs.com/docs/#/displaying/format/" target="_blank" rel="noreferrer" className="ms-1">moment.js</a>
    </div>, [lg]);
    //#endregion

    return <Modal
        {...props.modal}
        footer={footer}
        onQuit={props.onClose}
        size={props.modal?.size || "md"}
        title={props.modal?.title || TC.TIME_FORMATTER_TITLE}
    >
        <BS.InputGroup>
            <C.Form.TextField
                required
                uncontrolled
                value={format}
                noBottomMargin
                onChange={setFormat}
                customClass="flex-grow-1"
                description={description}
                placeholder="ex : DD-MM-YYYY"
                label={TC.TIME_FORMATTER_FORMAT}
            />
            <C.Form.Select
                required
                noBottomMargin
                options={zones}
                value={zone_data}
                loading={status === "load"}
                label={TC.TIME_FORMATTER_TIMEZONE}
                onChange={value => setTimeZone(value)}
            />
        </BS.InputGroup>

        {examplesContainer}
    </Modal>;
}

const WrappedTimeFormatter: React.FC<TimeFormatterProps> = props => <C.ReduxWrapper>
    <TimeFormatter {...props} />
</C.ReduxWrapper>;

export default WrappedTimeFormatter;