import _ from "lodash";
import React from "react";
import * as I from "../Input";
import * as IT from "../../Item";
import * as H from "../../../hooks";
import * as S from "../../../services";
import { Modal as M } from "../../../Components";
import { T, TC, TB, LANG } from "../../../Constants";

export type RegPropertiesProps = I.InputProps;

type Row = T.RegAction["extra_properties"][number];
type WorkingRow = Row & Record<"name_default", string>;
type Option = T.Option<Record<"type", "dashboard" | "existing_prop">>;

const DATA_TYPES: T.Option<{}, Row["data_type"]>[] = [
    { label: TC.FORM_REG_PROP_DATE_TYPE, value: "date" },
    { label: TC.FORM_REG_PROP_STRING_TYPE, value: "string" },
    { label: TC.FORM_REG_PROP_NUMBER_TYPE, value: "number" },
    { label: TC.FORM_REG_PROP_BOOL_TYPE, value: "boolean" },
    { label: TC.FORM_REG_PROP_SELECT_TYPE, value: "select" },
];

const RegProperties: React.FC<RegPropertiesProps> = props => {
    const [auth] = H.useAuth();
    const lg = H.useLanguage();
    const is_disabled = React.useMemo(() => props.disabled || props.noOverride, [props.disabled, props.noOverride]);

    const options = React.useMemo(() => {
        let available_dashboards: string[] = props.fullSubmission?.dashboards || [];
        let dashboard_options = (props.options as Option[] || []).filter(o => o.type === "dashboard" && available_dashboards.includes(o.value));
        let existing_prop_options = (props.options as Option[] || []).filter(o => o.type === "existing_prop");
        return { dashboard: dashboard_options, existing_prop: existing_prop_options };
    }, [props.options, props.fullSubmission?.dashboards]);

    const working_rows = React.useMemo(() => {
        let value_row: Row[] = props.value || [{}];
        return value_row.map(r => ({ ...r, name_default: r?.names?.[lg.prop] }));
    }, [props.value, lg.prop]);

    const add_options = React.useCallback(() => {
        M.askSelect({ options: options.existing_prop, isRequired: true, title: TC.FORM_REG_PROP_PICK_EXISTING }).then(selected => {
            let selected_prop = options.existing_prop.find(o => o.value === selected);
            if (selected_prop) {
                let new_rows = _.omit(selected_prop, "value", "labe", "type") as any as Row;
                props.onChange?.((props.value || []).concat(new_rows));
            }
        })
    }, [options.existing_prop, props]);

    const set_labels = React.useCallback(() => {
        let properties: Row[] = props.value || [];
        let rows = [] as (Row["names"] & Partial<Record<"prop" | "self_prop", string>>)[];

        for (let property of properties) {
            rows.push({ ...property.names, self_prop: property.prop });
            if (property.data_type === "select") {
                let options = (property.unit || "").split("|").map(t => t.trim());
                options.forEach((o, i) => rows.push({ ...property.options?.[i], [lg.prop]: o, prop: property.prop }));
            }
            else if (property.unit) rows.push({ ...property.unit_tr, [lg.prop]: property.unit, prop: property.prop });
        }

        let modal_ref: IT.QuickInputProps2<typeof rows[number]>["modal_ref"] = { current: null };
        let columns: IT.QuickInputProps2<typeof rows[number]>["columns"] = LANG.ALL_LANGUAGES.map(l => ({ type: "text", prop: l.prop, label: l.lg }));

        const auto_translate = () => {
            let values = modal_ref.current?.get() || [];
            let complete_lang = LANG.ALL_PROPS.find(l => values.every(v => typeof v[l] === "string" && v[l].trim().length > 0));
            // No lang was had a translation for all rows, notify user
            if (!complete_lang) M.renderAlert({ type: "warning", message: TC.AUTO_TRANSLATE_NO_FULL_BASE });
            else {
                const unmount = M.renderLoader();
                let texts = values.map(v => v[complete_lang]);
                S.translateManyText({ texts, lang: complete_lang }).then(({ data }) => {
                    let new_rows = values.map((value, index) => {
                        let result = data[index];
                        if (result === "failed") return value;
                        else return { ...value, ...result };
                    });
                    modal_ref.current?.set(new_rows);
                })
                    .catch(M.Alerts.loadError)
                    .finally(unmount);
            }
        };

        let footer = <IT.Button variant="info" icon="robot" text={TC.AUTO_TRANSLATE} onClick={auto_translate} />

        M.renderQuickInput<typeof rows[number]>({ columns, rows, modal_ref, footer, modal: { size: "lg", title: TC.FORM_REG_PROP_TRANSLATOR } }).then(labels => {
            if (labels) {
                let new_rows = properties.map(r => {
                    let names = labels.find(l => l.self_prop === r.prop);
                    let unit_tr = r.data_type === "select" ? undefined : labels.find(l => l.prop === r.prop);
                    let options_tr = r.data_type === "select" ? labels.filter(l => l.prop === r.prop) : undefined;
                    if (unit_tr) delete unit_tr.prop;
                    if (names) delete names.self_prop;
                    if (options_tr) options_tr.forEach(o => delete o.prop);
                    return { ...r, names, unit_tr, options: options_tr };
                });
                props.onChange?.(new_rows);
            }
        });
    }, [props, lg.prop]);

    const footer = React.useMemo(() => {
        return <>
            {options.existing_prop.length > 0 && <IT.Button
                icon="plus"
                variant="secondary"
                onClick={add_options}
                text={TC.FORM_REG_PROP_PICK_EXISTING}
            />}

            <IT.Button
                variant="info"
                icon="language"
                onClick={set_labels}
                text={TC.FORM_REG_PROP_TRANSLATOR}
            />
        </>
    }, [options.existing_prop, add_options, set_labels]);

    const submit_rows = React.useCallback<IT.QuickInputProps2<WorkingRow>["onSubmit"]>(rows => {
        let new_rows = rows.map(r => ({
            ...r,
            name_default: undefined,
            names: { ...r.names, [lg.prop]: r.name_default },
            prop: TB.toSnakeCase(r.names?.[lg.prop] || "").toLowerCase(),
        }));

        props.onChange?.(new_rows);
    }, [props, lg.prop]);

    const is_row_disabled = React.useCallback<Exclude<IT.QuickInputProps2<WorkingRow>["columns"][number]["is_disabled"], boolean>>(row => {
        if (auth.isAdmin) return false;
        else return row.locked;
    }, [auth.isAdmin]);

    const columns = React.useMemo<IT.QuickInputProps2<WorkingRow>["columns"]>(() => [
        ...(!auth.isAdmin ? [] : [{ size: 1, type: "boolean" as "boolean", prop: "locked", label: TC.REG_CUSTOM_PROP_LOCKED, is_disabled: is_row_disabled }]),
        { type: "text", prop: "name_default", label: TC.FORM_REG_PROP_NAME, is_disabled: is_row_disabled },
        { type: "select", prop: "data_type", label: TC.FORM_REG_PROP_DATA_TYPE, options: DATA_TYPES, no_clear_btn: true, is_disabled: is_row_disabled },
        {
            type: "text",
            prop: "unit",
            label: TC.FORM_REG_PROP_UNIT,
            tip_props: { icon: "question-circle", children: TC.FORM_REG_PROP_UNIT_TIP },
            is_disabled: row => is_row_disabled(row) || (row.data_type !== "number" && row.data_type !== "select"),
        },
        { size: 1, type: "boolean", prop: "required", label: TC.FORM_REG_PROP_REQUIRED, is_disabled: is_row_disabled },
        { type: "select", prop: "dashboards", label: TC.FORM_REG_PROP_DASHBOARD, options: options.dashboard, multiple: true, no_clear_btn: true, is_disabled: is_row_disabled },
    ], [options.dashboard, auth.isAdmin, is_row_disabled]);

    return <I.ComponentWrapper {...props} disabled={is_disabled}>
        <IT.QuickInput2<WorkingRow>
            controlled
            no_init_focus
            footer={footer}
            columns={columns}
            rows={working_rows}
            onSubmit={submit_rows}
        />
    </I.ComponentWrapper>;
};

export default RegProperties;