import "./Slide.scss";
import "./Element.scss"
import Menu from "./Menu";
import React from "react";
import { useDrag, useDrop } from "react-dnd";
import { useBoolean } from "../../../../hooks";
import { FP, T, TC, TB } from "../../../../Constants";

const isEntity = (node: T.NodeDia): node is T.NodeDia<T.EntityData> => node.path === FP.ENTITY_FORM;

const Node: React.FC<T.MakeRequired<T.DiaElementProps, "onPick">> = ({ node, hidden, allowDrag, rights, allowDrop, onDrop, onPick, ...props }) => {
  const menu = useBoolean(false);

  //#region Drag & Drop
  const [{ canDrop, isOver }, drop] = useDrop<T.DiaElementProps["node"], void, { canDrop: boolean, isOver: boolean }>({
    accept: "node",
    drop: item => onDrop?.(item.id, node.id),
    canDrop: item => typeof allowDrop === "function" ? allowDrop?.(item, node) : true,
    collect: monitor => ({ canDrop: monitor.canDrop(), isOver: monitor.isOver({ shallow: true }) }),
  }, [onDrop, allowDrop, node.id]);

  const [{ opacity }, drag] = useDrag({
    type: 'node',
    item: node,
    canDrag: allowDrag,
    collect: monitor => ({ opacity: monitor.isDragging() ? 0.5 : 1 })
  }, [node.id, allowDrag]);
  //#endregion

  //#region Display
  const entityIcon = React.useMemo(() => {
    if (isEntity(node)) {
      switch (node.data.typedecompteur) {
        case "MANUAL": return <i className='me-2 fa fa-clipboard'></i>;
        case "CALCULATED": return <i className='me-2 fa fa-calculator'></i>;
        case "AUTOMATIC_READING": return <i className='me-2 fa fa-database'></i>;
        default: return null;
      }
    }
  }, [node]);

  const imgUrl = React.useMemo(() => TB.iconIdToUrl(node.icon), [node.icon]);
  const overColor = React.useMemo(() => canDrop ? "#4EFC03" : "#FC0303", [canDrop])
  const elemColor = React.useMemo(() => TB.getColor(node.color) || "#FFFFFF", [node.color]);

  const getHiddenStackEffect = React.useCallback((color: string) => {
    if (!hidden) return "";
    else return `0 1px 1px rgba(0,0,0,0.15),0 10px 0 -5px ${color},0 10px 1px -4px rgba(0,0,0,0.15),0 20px 0 -10px ${color},0 20px 1px -9px rgba(0,0,0,0.15)`;
  }, [hidden]);

  const onOverStyle = React.useMemo<React.CSSProperties>(() => ({
    opacity,
    color: overColor,
    transform: "scale(1.1)",
    backgroundColor: overColor,
    boxShadow: getHiddenStackEffect(overColor),
  }), [overColor, opacity, getHiddenStackEffect]);

  const normalStyle = React.useMemo<React.CSSProperties>(() => ({
    opacity,
    color: elemColor,
    backgroundColor: elemColor,
    boxShadow: getHiddenStackEffect(elemColor),
  }), [elemColor, opacity, getHiddenStackEffect]);
  //#endregion

  const mail = React.useMemo(() => {
    if (node.path === FP.USER_FORM) {
      let mail = (node.data as T.UserData).email;
      return TB.validString(mail) && mail;
    }
  }, [node.path, node.data]);

  return <div key={node.id} className="w-100 h-100" ref={drop}>
    <div ref={drag} className="mainDivElement defaultElem" key={node.id} style={isOver ? onOverStyle : normalStyle}>
      <button className='slideMenuButton' onClick={e => {
        if (e.shiftKey) onPick(TC.TREE_REVEAL, node, true);
        else if (e.ctrlKey) onPick(TC.TREE_REVEAL, node, false);
        else menu.setTrue();
      }}>
        <div className="name">
          <p>{entityIcon} {node.label}</p>
          {mail && <span className="text-muted text-center text-break">{mail}</span>}
        </div>
        {imgUrl && <div className="otherInfos solo">
          <div className='img'>
            <img src={imgUrl} alt="" />
          </div>
        </div>}
      </button>

      <Menu
        {...props}
        node={node}
        onPick={onPick}
        onClose={menu.setValue}
        open={menu.value && props.focused === node.id}
      />
    </div>
  </div>
}

export default Node;