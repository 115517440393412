import _ from "lodash";
import React from "react";
import * as M from "../Modal";
import * as H from "../../hooks";
import * as C from "../../Common";
import * as TABLE from "../Gestion";
import * as DOM from "react-router-dom";
import * as US from "../../services/user.service";
import { MO, TC, FP, T, TB } from "../../Constants";
import { CellsTypes as CT } from "../Gestion/AgGridDefs";
import MatrixSelector, { MatrixOption } from "./MatrixSelector";

type Row = T.MatrixSelector;

const MatrixTable: React.FC = () => {
    const lg = H.useLanguage();
    H.useAuth({ onlyAdmin: true });
    const [urlParams, setUrlParams] = DOM.useSearchParams();
    const [resources, setResources] = React.useState<T.Submission[]>([]);
    const { resetCrumbs, updateCrumbs } = H.useCrumbs(TC.GLOBAL_MATRIX_LABEL);
    const [matrixOptions, setMatrixOptions] = H.useAsyncState<MatrixOption[]>([]);
    const [formPropMatrix, setFormPropMatrix] = H.useAsyncState<T.MatrixObj | null>(null);

    React.useEffect(() => {
        let isSubscribed = true;
        US.getMatrixFormProps().then(({ data }) => {
            if (isSubscribed) {
                if (data.hasFailed || !Array.isArray(data)) setMatrixOptions([], "error");
                else setMatrixOptions(data, "done");
            }
        }).catch(() => isSubscribed && setMatrixOptions([], "error"));
        return () => {
            isSubscribed = false;
            setMatrixOptions([], "load");
        }
    }, [setMatrixOptions]);

    //#region URL Params
    const resetParams = React.useCallback(() => {
        resetCrumbs();
        setUrlParams("", { replace: true })
    }, [setUrlParams, resetCrumbs]);

    const paramsExists = React.useCallback((form: string, prop: string) => {
        let formMatrix = matrixOptions.filter(matrix => matrix.form === form)[0];
        if (formMatrix) return formMatrix.props.includes(prop);
        return false;
    }, [matrixOptions]);

    const { selectedForm, selectedProp, exists } = React.useMemo(() => {
        let form = urlParams.get('form'), prop = urlParams.get("prop");
        if (TB.validString(form) && TB.validString(prop) && paramsExists(form, prop)) return { selectedForm: form, selectedProp: prop, exists: true };
        return { selectedForm: null, selectedProp: null, exists: false };
    }, [urlParams, paramsExists]);

    React.useEffect(() => {
        if (exists) updateCrumbs({ label: selectedForm ?? "", child: { label: selectedProp ?? "" } }, 1);
        else resetCrumbs();
    }, [exists, selectedForm, selectedProp, updateCrumbs, resetCrumbs]);

    const onChangeFormProps = React.useCallback((form: string, prop: string) => {
        if (paramsExists(form, prop)) setUrlParams(`form=${form}&&prop=${prop}`, { replace: true });
        else M.renderAlert({ type: "error", message: TC.MATRIX_INVALID_URL_PARAMS });
    }, [setUrlParams, paramsExists]);

    React.useEffect(() => {
        let isSubscribed = true;
        if (exists) US.getReducedMatrix(selectedForm, selectedProp).then(({ data }) => {
            if (isSubscribed) {
                if (data?.hasFailed) setFormPropMatrix(null, "error");
                else setFormPropMatrix(data, "done");
            }
        }).catch(() => isSubscribed && setFormPropMatrix(null))
        else setFormPropMatrix(null, "done");
        return () => {
            isSubscribed = false;
            setFormPropMatrix(null, "load");
        }
    }, [exists, selectedForm, selectedProp, setFormPropMatrix]);
    //#endregion

    //#region Formatter
    const formatter = React.useMemo(() => ({
        find_sub: (id?: string) => resources.filter(r => r._id === id)[0] || null,
        find_comp_prop: (field?: string) => (field || "").replace(/comparators.\d+./g, ""),
        find_comp_index: (field?: string) => {
            let index = parseInt((field || "").replace("comparators.", "").replace(/.values.[a-zA-Z]*/g, ""));
            return isNaN(index) ? null : index;
        },
        text: (p) => lg.getStaticText(p?.value) || "",
    }), [lg, resources]);
    //#endregion

    //#region Columns
    const maxConditions = React.useMemo(() => _.maxBy(formPropMatrix?.selectors || [], (selector: T.MatrixSelector) => (selector.comparators || []).length)?.comparators?.length || 0, [formPropMatrix]);

    const options = React.useMemo(() => ({
        matrix: matrixOptions.map(m => ({ value: m.form, label: m.form })),
        resources: FP.RESOURCE_FORMS.map(value => ({ value, label: value })),
        operations: MO.ALL_OPERATIONS.map(value => ({ value, label: value })),
    }), [matrixOptions]);

    const columns = React.useMemo<TABLE.TableProps<Row>["columns"]>(() => {
        if (!exists) return [];
        let columns = [
            { headerName: TC.MATRIX_IS_FORM_PROP, field: "isFormProp", type: CT.TYPE_CHECKBOX },
            { headerName: TC.MATRIX_IMPORTANCE, field: "importance", type: CT.TYPE_CHECKBOX, params: { distinctFalseFromEmpty: true } },
        ] as TABLE.TableProps<Row>["columns"];

        for (let i = 0; i < maxConditions; i++) columns.unshift({
            headerName: lg.getStaticText(TC.MATRIX_CONDITION_NUM, i + 1),
            children: [
                { field: `comparators.${i}.propChecked`, headerName: TC.MATRIX_PROP_CHECKED },
                { field: `comparators.${i}.type`, headerName: TC.TYPE, type: CT.TYPE_SELECT, params: { values: options.operations } },
                { field: `comparators.${i}.values.isResource`, headerName: TC.MATRIX_IS_RESOURCE, type: CT.TYPE_CHECKBOX },
                { field: `comparators.${i}.values.valueForm`, headerName: TC.MATRIX_VALUE_FORM, type: CT.TYPE_SELECT, params: { values: options.resources } },
                { field: `comparators.${i}.values.labelProp`, headerName: TC.MATRIX_LABEL_PROP },
                { field: `comparators.${i}.values.equal`, headerName: TC.MATRIX_EQUAL },
                { field: `comparators.${i}.values.contain`, headerName: TC.MATRIX_CONTAIN },
            ]
        });
        return columns;
    }, [exists, lg, maxConditions, options]);
    //#endregion

    //#region Other resources
    const downloadedResourcesIds = React.useMemo(() => resources.map(r => r._id), [resources]);
    /* @ts-ignore */
    const referentIds = React.useMemo(() => _.flattenDeep((formPropMatrix?.selectors || []).map(r => r.comparators.map(c => [c.values.contain, c.values.equal]))).filter(TB.mongoIdValidator), [formPropMatrix?.selectors]);

    React.useEffect(() => {
        let isSubscribed = true;
        let filteredIds = referentIds.filter(id => !downloadedResourcesIds.includes(id));

        if (filteredIds.length > 0) US.getManySubmissionsFromFilter({ _id: filteredIds })
            .then(({ data }) => {
                if (isSubscribed) {
                    if (Array.isArray(data)) setResources(p => data.length === 0 ? p : p.concat(data));
                    else M.renderAlert({ type: "error", message: TC.GLOBAL_FAILED_LOAD });
                }
            });

        return () => { isSubscribed = false; }
    }, [referentIds, downloadedResourcesIds]);
    //#endregion

    //#region Edit table
    const updateMatrix = React.useCallback((selectorId: string, field: string, value: any) => {
        setFormPropMatrix(p => {
            if (p === null) return null;
            return { ...p, selectors: p.selectors.map(sel => sel.id === selectorId ? _.set({ ...sel }, field, value) : sel) }
        });
    }, [setFormPropMatrix])

    const onValueChanged = React.useCallback<TABLE.TableProps<Row>["onValueChange"]>(event => {
        let field: string = event.colDef.field ?? "", selectorId = event.data?.id;

        if (TB.validString(field) && TB.isUuid(selectorId)) {
            if (!field.includes(".")) US.updatePropMatrix(field, event.newValue, selectedForm, selectorId).then(({ data }) => {
                if (data?.ok === 1) updateMatrix(selectorId, field, event.newValue);
                else M.renderAlert({ type: "error", message: TC.GLOBAL_ERROR_UPDATE });
            });
            else {
                let prop = formatter.find_comp_prop(field),
                    index = formatter.find_comp_index(field);

                if (index !== null && TB.validString(prop)) {
                    let update = { field, value: event.newValue };

                    // If the comparator does not exists yet
                    if (!TB.validObject(event.data?.comparators?.[index])) update = {
                        field: `comparators.${index}`,
                        value: _.set({ propChecked: "", type: "EQUALS", values: { isResource: false } }, prop, event.newValue)
                    };

                    US.updatePropMatrix(update.field, update.value, selectedForm, selectorId)
                        .then(({ data }) => {
                            if (data?.ok === 1) updateMatrix(selectorId, update.field, update.value);
                            else M.renderAlert({ type: "error", message: TC.GLOBAL_ERROR_UPDATE });
                        });
                }

            }
        }
    }, [updateMatrix, formatter, selectedForm]);
    //#endregion

    //#region Context Menu
    const addCondition = React.useCallback((selectorId: string) => {
        let selector = formPropMatrix?.selectors?.filter(s => s.id === selectorId)[0];
        if (TB.isMatrixSelector(selector)) {
            let newIndex = selector.comparators.length;
            let field = `comparators.${newIndex}`;
            let update = { propChecked: "", type: "EQUALS", values: { isResource: false } };
            US.updatePropMatrix(field, update, selectedForm, selectorId).then(({ data }) => {
                if (data?.ok === 1) updateMatrix(selectorId, field, update);
                else M.renderAlert({ type: "error", message: TC.GLOBAL_ERROR_UPDATE });
            })
        }
    }, [selectedForm, formPropMatrix, updateMatrix]);

    const removeCondition = React.useCallback((selectorId: string, comparator: T.MatrixComparator, index: number) => {
        if (TB.isUuid(selectorId) && TB.isMatrixComparator(comparator)) {
            let update = { $pull: { "selectors.$.comparators": comparator } };
            let filter = { form: selectedForm, "selectors.id": selectorId };

            US.updateSingleMatrix(filter, update).then(({ data }) => {
                if (data?.ok === 1) setFormPropMatrix(p => {
                    if (p === null) return null;
                    return { ...p, selectors: p.selectors.map(sel => sel.id !== selectorId ? sel : { ...sel, comparators: sel.comparators.filter((comp, i) => i !== index) }) };
                });
                else M.renderAlert({ type: "error", message: TC.GLOBAL_ERROR_DELETE });
            });
        }
    }, [selectedForm, setFormPropMatrix]);

    const addSelector = React.useCallback(() => {
        US.addPropToFormMatrix(selectedForm, selectedProp)
            .then(({ data }) => {
                if (data?.ok === 1) setFormPropMatrix(p => {
                    if (TB.isMatrixObject(p)) return { ...p, selectors: TB.getArray(p?.selectors).concat(data.update) };
                    return null;
                });
                else M.renderAlert({ type: "error", message: TC.GLOBAL_ERROR_UPDATE });
            });
    }, [selectedForm, selectedProp, setFormPropMatrix]);

    const removeSelector = React.useCallback((selectorId: string) => {
        if (TB.isUuid(selectorId)) {
            let update = { $pull: { selectors: { id: selectorId } } };
            US.updateSingleMatrix({ form: selectedForm }, update).then(({ data }) => {
                if (data?.ok) setFormPropMatrix(p => p === null ? null : { ...p, selectors: p.selectors.filter(sel => sel.id !== selectorId) });
                else M.renderAlert({ type: "error", message: TC.GLOBAL_ERROR_DELETE });
            });
        }
    }, [selectedForm, setFormPropMatrix]);

    const onCallContextMenu = React.useCallback<TABLE.TableProps<Row>["getContextMenuItems"]>((params) => {
        let selector = params?.node?.data;
        let itemArray = [] as ReturnType<TABLE.TableProps<Row>["getContextMenuItems"]>;

        if (TB.isMatrixSelector(selector)) {
            let nbComparators = selector.comparators.length;

            itemArray = [
                { name: lg.getStaticText(TC.MATRIX_ADD_CONDITION), icon: "<i class='fa fa-ban'></i>", action: () => addCondition(selector.id) },
            ]

            if (nbComparators > 0) itemArray.push({
                name: lg.getStaticText(TC.MATRIX_REMOVE_COND),
                icon: "<i class='fa fa-minus text-danger'></i>",
                subMenu: [...new Array(nbComparators)].map((x, i) => ({
                    name: lg.getStaticText(TC.MATRIX_DELETE_COMPARATOR_NUM, (i + 1).toString()),
                    action: () => removeCondition(selector.id, selector.comparators[i], i),
                }))
            })
        }

        if (itemArray.length > 0) itemArray.push("separator");
        if (TB.isMatrixSelector(selector)) itemArray.push({ name: lg.getStaticText(TC.MATRIX_DELETE_SELECTOR), icon: "<i class='fa fa-minus'></i>", action: () => removeSelector(selector.id) })
        itemArray.push({ name: lg.getStaticText(TC.MATRIX_ADD_SELECTOR), action: addSelector, icon: "<i class='fa fa-plus'></i>" }, "separator")

        return itemArray.concat(TB.getArray(params.defaultItems));
    }, [addCondition, lg, removeCondition, addSelector, removeSelector]);
    //#endregion

    //#region Form/Prop Selector
    const onChangeForm = React.useCallback((form: string) => {
        let option = matrixOptions.filter(matrix => matrix.form === form)[0];
        if (option) {
            let prop = option.props[0];
            if (paramsExists(form, prop)) return onChangeFormProps(form, prop);
        }
        resetParams();
    }, [resetParams, matrixOptions, onChangeFormProps, paramsExists]);

    const selectForm = React.useMemo(() => <C.Flex alignItems='center' className="me-3">
        <C.Form.Select
            value={selectedForm}
            options={options.matrix}
            label={TC.GLOBAL_RESOURCE_LABEL}
            onChange={val => TB.validString(val?.[0]?.value) ? onChangeForm(val?.[0]?.value) : undefined}
            typeahead={{ renderItem: opt => <div>{opt.label} <span className="text-muted">({opt.value})</span></div> }}
        />
    </C.Flex>, [options.matrix, selectedForm, onChangeForm]);

    const selectProp = React.useMemo(() => {
        let option = matrixOptions.filter(matrix => matrix.form === selectedForm)[0];
        if (option) return <C.Flex alignItems="center" className="me-3">
            <C.Form.Select
                value={selectedProp}
                label={TC.GLOBAL_PROP_LABEL}
                options={option.props.map(prop => ({ value: prop, label: prop }))}
                onChange={val => TB.validString(val?.[0]?.value) ? onChangeFormProps(selectedForm ?? "", val?.[0]?.value) : undefined}
            />
        </C.Flex>;
    }, [matrixOptions, selectedForm, selectedProp, onChangeFormProps]);
    //#endregion

    return <div className="flex-grow-1 pb-1 w-100">
        {!exists && <MatrixSelector onSelectProp={onChangeFormProps} onChangeOptions={setMatrixOptions} options={matrixOptions} />}
        {exists && <C.Flex className="h-100" direction="column">
            <C.Flex className="mb-2" wrap="nowrap" justifyContent='between'>
                <div className="flex-grow-1">
                    <C.Button onClick={resetParams} text={TC.MATRIX_SELECT_SCREEN} />
                </div>
                <C.Flex>
                    {selectForm}
                    {selectProp}
                </C.Flex>
            </C.Flex>
            <div className="flex-grow-1">
                <TABLE.Table<Row>
                    sideBar
                    columns={columns}
                    columns_base="all"
                    adaptableId="matrix_table"
                    onValueChange={onValueChanged}
                    rows={formPropMatrix?.selectors || []}
                    getContextMenuItems={onCallContextMenu}
                />
            </div>
        </C.Flex>}
    </div>;
}

export default MatrixTable;