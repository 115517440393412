import _ from "lodash";
import React from "react";
import * as M from "../Modal";
import * as H from "../../hooks";
import * as S from "../../services";
import { FP, T, TC } from "../../Constants";
import { CellsTypes as CT } from "../Gestion/AgGridDefs";
import { Table, TableProps, TableRef } from "../Gestion/Grid";

type Row = ReturnType<T.API.Utils.Navigator.NavTableSites>[number];

export type SiteTableProps = {
    /** The rows to be shown in the table */
    rows: Row[];
    /** The current status of the loading */
    status: T.AsyncStates;
    /** The type of selection */
    selection: "checkbox" | "button";
    /** The items currently selected */
    selected?: string[];
    /** Callback for an item's selection */
    onSelect?: (id: string) => void;
    /** Callback for loading the heavy format of the table */
    load_heavy: () => void;
    /** Do the rows contain all the data */
    is_heavy?: boolean;
    /** Callback to update a row */
    row_update: (id: string, update: Partial<Row>) => void;
}

export type SiteTableRef = {
    /** Callback to select all active rows in the grid */
    select_all: () => string[];
}

const SiteTable = React.forwardRef<{}, SiteTableProps>(({ row_update, ...props }, ref) => {
    const lg = H.useLanguage();
    const [{ userId }] = H.useAuth();
    const loading = H.useBoolean(false);
    const table = React.useRef<TableRef<Row>>(null);

    const rows = React.useMemo(() => props.rows.map(r => ({
        ...r,
        selected: props.selected?.includes?.(r.id),
        tr_affectations: r.affectations.map(a => lg.getStaticText(a)),
        tr_full_region: lg.getTextObj(r.region_id, "name", r.full_region),
        tr_sub_affectations: r.sub_affectations.map(a => lg.getStaticText(a)),
    })), [props.rows, props.selected, lg]);

    const options = React.useMemo(() => ({
        tags: (row: Row) => new Promise<T.Option[]>((resolve, reject) => {
            S.getNoteTags({ context: { roots: row.id }, type: "site" })
                .then(r => resolve(r.data))
                .catch(reject);
        }),
        create_tag: (text: string, row: Row) => new Promise<T.Option>((resolve, reject) => {
            let submission = { name: text, users: [userId], sites: [], type: "site" } as T.NoteTag;
            S.createSubmission({ submission, path: FP.NOTE_TAG_FORM }).then(({ data }) => {
                let new_tag = data.submissions[0] as T.Submission<T.NoteTag>;
                if (new_tag) {
                    let new_option = { label: new_tag.data.name, value: new_tag._id } as T.Option;
                    resolve(new_option);
                }
                else resolve(null);
            }).catch(reject);
        }),
    }), [userId]);

    const columns = React.useMemo<TableProps<Row>["columns"]>(() => {
        let cols = [] as TableProps<Row>["columns"];

        if (props.selection === "checkbox") cols.push({
            pinned: "left",
            headerName: " ",
            field: "selected",
            type: CT.TYPE_SELECT_BUTTON,
            params: {
                use_is_selected_filters_label: true,
                action: data => data && props.onSelect?.(data.id),
                buttonProps: (data: typeof rows[number]) => ({
                    size: "sm",
                    icon: data.selected ? "check-circle" : "check",
                    variant: data.selected ? "success" : "primary",
                    text: data.selected ? TC.NAV_TABLE_ITEM_SELECTED : TC.NAV_TABLE_ITEM_SELECT,
                }),
            }
        });
        else cols.push({
            pinned: "left",
            headerName: " ",
            field: "select",
            type: CT.TYPE_ACTION_BUTTON,
            params: {
                action: row => props.onSelect?.(row.id),
                buttonProps: { size: "sm", icon: "check" },
            }
        });

        cols.push(
            { field: "picture_link", headerName: TC.GLOBAL_PICTURE, type: CT.TYPE_PICTURES, params: { layout: "profile" } },
            { field: "name", headerName: TC.GLOBAL_NAME },
            { field: "clients_names", headerName: TC.ENTERPRISES_PLURAL_SINGLE },
            (props.is_heavy ? { field: "buildings_names", headerName: TC.BUILDING_PLURAL_SINGLE } : null),
            { field: "full_country", headerName: TC.COUNTRY },
            { field: "tr_full_region", headerName: TC.GLOBAL_REGION_SINGLE },
            {
                editable: true,
                field: "tags_sites",
                type: CT.TYPE_SELECT,
                headerName: TC.NAV_TAG_SITE,
                params: {
                    multiple: true,
                    field_value: "tags",
                    getValues: options.tags,
                    typeahead: { allowNewOption: true, onAddOption: options.create_tag },
                }
            },
            { field: "tags_descendants", headerName: TC.GLOBAL_TAGS },
            (props.is_heavy ? { field: "tr_affectations", headerName: TC.GLOBAL_AFFECTATION_PRINCIPAL } : null),
            (props.is_heavy ? { field: "tr_sub_affectations", headerName: TC.NAV_TABLE_SUB_AFFECTATION } : null),
            (props.is_heavy ? { field: "surfaces", headerName: TC.GLOBAL_SURFACE_BHS, type: CT.TYPE_NUMBER } : null),
        );
        return cols.filter(x => x !== null);
    }, [props, options]);

    const onValueChange = React.useCallback<TableProps<Row>["onValueChange"]>(event => {
        let row = event.data,
            old_value = event.oldValue,
            field = event.colDef.field as keyof typeof rows[number];
        // Can only edit tags
        if (field === "tags_sites") {
            old_value = row.tags;
            field = "tags";

            const updatePromise = new Promise<"cancel" | void>((resolve, reject) => {
                if (!_.isEqual(old_value, event.newValue)) {
                    let api_params = {
                        field,
                        _id: row.id,
                        old_value: old_value,
                        new_value: event.newValue,
                    } as Parameters<typeof S.editSiteField>[0];

                    loading.setTrue();

                    S.editSiteField(api_params).then(({ data }) => {
                        if (data === "changed") M.askConfirm({ title: TC.UPDATE_FORCE_CHANGE, text: TC.UPDATE_VALUE_UNFRESH }).then(confirmed => {
                            if (confirmed) S.editSiteField({ ...api_params, force_update: true }).then(({ data }) => {
                                if (data === "changed") reject("Error");
                                else resolve();
                            }).catch(reject);
                            else resolve("cancel");
                        });
                        else resolve();
                    }).catch(reject);
                }
                else resolve("cancel");
            });

            updatePromise.then(rows => {
                if (rows !== "cancel") options.tags(row).then(tags => {
                    let selected_tags = tags.filter(t => (event.newValue || []).includes(t.value));
                    let tags_names = selected_tags.map(t => t.label);
                    let updated_row = { ...row, tags: event.newValue, tags_sites: tags_names } as Row;
                    row_update(row.id, updated_row);
                })
            })
                .catch(M.Alerts.updateError)
                .finally(loading.setFalse);
        }
    }, [loading, options, row_update]);

    //#region Ref
    const select_all = React.useCallback<SiteTableRef["select_all"]>(() => {
        table.current.grid.api.selectAllFiltered();
        let data = table.current.grid.api.getSelectedRows();
        table.current.grid.api.deselectAll();
        return data.map(d => d.id);
    }, []);

    React.useImperativeHandle(ref, () => ({ select_all }), [select_all]);
    //#endregion

    return <Table<Row>
        count
        ref={table}
        rows={rows}
        columns={columns}
        status={props.status}
        adaptableId="nav_sites"
        sideBar="filters_columns"
        columns_base="all_but_edit"
        onValueChange={onValueChange}
        autoFit={["selected", "select", "picture_link"]}
        extra_buttons={{
            onClick: props.load_heavy,
            label: lg.getStaticText(TC.NAVIGATOR_LOAD_HEAVY),
            icon: { element: "<i class='fa fa-database me-2'></i>" },
        }}
    />;
});

export default SiteTable;