import _ from "lodash";
import Card from "./Card";
import React from "react";
import * as M from "../Modal";
import * as C from "../../Common";
import { DndProvider } from "react-dnd";
import { TB, TC } from "../../Constants";
import { HTML5Backend } from "react-dnd-html5-backend";

//#region Types
export type Item<A extends {} = {}> = { id: string, relative?: string } & A;

export type SortMenuProps<A extends {} = { relative?: string, id: string }> = {
    listItem: Item<A>[];
    onSave: (item: null | Item[]) => void;
    renderCard: (element: Item<A>) => React.ReactNode;
    popUp?: Omit<M.BlankModalFnProps, "renderContent" | "footer">;
}
//#endregion

const SortMenu = <A,>({ listItem, popUp, onSave, renderCard, ...props }: SortMenuProps<A>) => {
    const [cards, setCards] = React.useState<Item<A>[]>([]);
    const isPopUp = React.useMemo(() => TB.validObject(popUp), [popUp]);

    //#region Order Items
    const sortCard = React.useCallback((cards: Item<A>[]) => {
        let cardsIds = cards.map(c => c.id);
        let ordered: Item<A>[] = [], unordered = [...cards];

        for (let item of unordered) {
            // The item has a specific place mentioned in db and that relative item is provided in the list of items
            if (item.relative && cardsIds.includes(item.relative)) {
                let indexRelative = _.findIndex(ordered, o => o.id === item.relative);

                // The relative is in, add this item
                if (indexRelative >= 0) ordered.splice(indexRelative, 0, item);
                // The relative item is not in the array yet, so redo it later
                else unordered.push(item);
            }
            // No places specified, add to the end
            else ordered.push(item);
        }

        return ordered;
    }, []);

    React.useEffect(() => {
        let vItems = sortCard(listItem.filter(l => TB.mongoIdValidator(l?.id)));
        setCards(vItems);
    }, [listItem, sortCard]);
    //#endregion

    //#region Card
    const moveCard = React.useCallback((dragIndex: number, hoverIndex: number) => setCards(p => {
        let dragItem = p[dragIndex];
        let copyArray = [...p];
        copyArray.splice(dragIndex, 1);
        copyArray.splice(hoverIndex, 0, dragItem);
        return copyArray;
    }), []);

    const cardsBanner = React.useMemo(() => {
        if (cards.length === 0) return <C.ErrorBanner type="info" message="todo no cards" />;
        return <>{cards.map((c, i) => <Card key={c.id} index={i} id={c.id} item={c} renderCard={renderCard} moveCard={moveCard} />)}</>
    }, [cards, moveCard, renderCard]);
    //#endregion

    //#region Save
    const save = React.useCallback(() => {
        let results: Item[] = cards.map((c, i, a) => ({ id: c.id, relative: a[i + 1]?.id }));
        onSave?.(results);
    }, [cards, onSave]);

    const saveButton = React.useMemo(() => <C.Button onClick={save} icon="save" text={TC.GLOBAL_SAVE} />, [save]);
    //#endregion

    return React.createElement(
        isPopUp ? M.BlankModal : React.Fragment,
        isPopUp ? { ...popUp, footer: saveButton, onQuit: () => onSave?.(null) } : null,
        // @ts-ignore
        <DndProvider DndProvider backend={HTML5Backend} >
            {cardsBanner}
            {!isPopUp && <C.Flex className="my-3" justifyContent="end">
                {saveButton}
            </C.Flex>}
        </DndProvider>
    );
}

export default SortMenu;