import '@fullcalendar/react/dist/vdom';
import { Draggable } from "@fullcalendar/interaction";
import React, { useEffect, useMemo, useRef } from "react";
import ActionItem, { ActionItemProps } from "./ActionItem";

export type DraggableActionItemProps = ActionItemProps;

const DraggableActionItem: React.FC<DraggableActionItemProps> = props => {
    const container = useRef<HTMLDivElement>(null);

    useEffect(() => {
        new Draggable(container.current);
    }, []);

    const dataEvent = useMemo(() => JSON.stringify({ id: props.action.id }), [props.action.id]);

    return <div data-event={dataEvent} ref={container}>
        <ActionItem {...props} />
    </div>;
}

export default DraggableActionItem;