const asyncDispatchMiddleware = store => next => action => {
    let syncActivityFinished = false;
    let actionQueue = [];

    const flushQueue = () => {
        actionQueue.forEach(a => store.dispatch(a));
        actionQueue = [];
    }

    const asyncDispatch = (asyncAction) => {
        actionQueue = actionQueue.concat([asyncAction]);
        if (syncActivityFinished) flushQueue();
    }

    const actionWithAsyncDispatch = Object.assign({}, action, { asyncDispatch });
    const res = next(actionWithAsyncDispatch);

    syncActivityFinished = true;
    flushQueue();

    return res;
};

export default asyncDispatchMiddleware;