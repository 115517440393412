import { TB } from "../../../Constants";
import { InputProps, ComponentWrapper } from "../Input";
import React from "react";

export type ContentProps = InputProps & {
    html?: string;
};

const Content: React.FC<ContentProps> = ({ html, ...props }) => {
    const container = React.useRef<HTMLDivElement>(null);

    const node = React.useMemo(() => {
        if (!TB.validString(html)) return null;
        let node = document.createElement("div");
        node.innerHTML = html;
        return node;
    }, [html]);

    React.useEffect(() => {
        let parent = container.current, child = node;
        if (child && parent) parent.appendChild(child);
        return () => { parent && child && parent.removeChild(child) };
    }, [node]);

    return <ComponentWrapper {...props} children={<div ref={container} />} />;
}

export default Content;