type EvenPartitionOptions<A = unknown> = {
    /** The number of groups to divide the values into */
    groups?: number;
    /** A callback to pick the very first item */
    pickFirstItem?: (item: A, index: number) => boolean;
}

/**
 * Transforms an array of numbers into k array of relatively equal sum
 */
export const partitionKSubsets = (numbers: number[], k: number) => {
    // No numbers to group or only one group wanted
    if (numbers.length === 0 || k <= 1) return [numbers] as number[][];
    // Calculate the sum of every number of the provided list
    let sum = numbers.reduce((el, acc) => acc + el, 0);
    // Calculate the sum that should be max in each bucket
    let bucket_sum = sum / k;
    // Create the new buckets
    let buckets = [...new Array(k)].map(() => [] as number[]);

    // Temp store the unassigned values
    let unassigned = [];

    for (let index = 0; index < numbers.length; index++) {
        let isAssigned = false;
        let num = numbers[index];

        // Find the most suitable bucket for the value
        for (let bucket_index = 0; bucket_index < buckets.length; bucket_index++) {

            // The sum of items currently in the bucket
            let bucket = buckets[bucket_index].reduce((val, acc) => acc + val, 0);
            // The sum of the bucket + the current value
            let filled_bucket = bucket + num;

            if (filled_bucket < bucket_sum) {
                // Add the current value to the bucket
                buckets[bucket_index].push(num);
                // Stop the loop
                bucket_index = buckets.length;
                // Flag this item as assigned for the post-loop
                isAssigned = true;
            }
        }

        // Value was not assigned to a bucket
        if (!isAssigned) unassigned.push(num);
    }

    // Sort the unassigned value from the highest to the lowest
    let ordered_unassigned = unassigned.sort((a, b) => b - a);

    // Place the unassigned in the less filled buckets
    for (let num of ordered_unassigned) {
        // Calculate the sum of each buckets
        let buckets_sums = buckets.map(b => b.reduce((val, acc) => acc + val, 0));
        // Find the least filled bucket's number of items
        let least_filled_bucket_value = Math.min(...buckets_sums);
        // Use the min number of items to find it's index
        let least_filled_bucket_index = buckets_sums.indexOf(least_filled_bucket_value);

        // Should never run into this error
        if (least_filled_bucket_index === -1) least_filled_bucket_index = 0;
        // Add the value to the it's bucket
        else buckets[least_filled_bucket_index].push(num);
    }

    return buckets;
}

/**
 * Transforms an array of item into k array of roughly the same number of items
 * @param items The array of items
 * @param by A callback to get a numeric value from an item
 */
export const evenPartition = <A = any>(items: A[], by?: (item: A) => number, options?: EvenPartitionOptions<A>) => {
    /* @ts-ignore */
    let numbers = items as number[];
    if (typeof by === "function") numbers = items.map(by);

    let partitions = partitionKSubsets(numbers, options?.groups || 2);

    let grid = [] as { number: number, item: A }[];
    if (typeof by === "function") grid = items.map(i => ({ number: by(i), item: i }));
    /* @ts-ignore */
    else grid = items.map(i => ({ number: i as number, item: i }));

    let chunks: A[][] = Array(partitions.length).map(() => []);

    if (typeof options?.pickFirstItem === "function") {
        // Find the items that should be first
        let firstItem = grid.filter((g, i) => options.pickFirstItem(g.item, i))[0];

        if (firstItem) {
            // Update the partition by reordering the columns and removing the first item
            let newFirstCol = partitions.filter(p => p.includes(firstItem.number))[0];
            let first_index = newFirstCol ? newFirstCol.indexOf(firstItem.number) : -1;

            if (newFirstCol && first_index !== -1) {
                // Update the grid by removing the first items
                grid = grid.filter(g => g.item !== firstItem.item);
                // Reorder the columns
                partitions = [newFirstCol].concat(partitions.filter(p => p !== newFirstCol));
                // Remove the item that will become first
                newFirstCol.splice(first_index, 1);
                // Already add the first item in the chunks
                chunks[0] = [firstItem.item];
            }
        }
    }

    for (let col = 0; col < partitions.length; col++) {
        let column = chunks[col];
        if (!Array.isArray(column)) {
            chunks[col] = [];
            column = chunks[col];
        }

        for (let val of partitions[col]) {
            /* @ts-ignore */
            let item = grid.filter(g => g.number === val)[0];

            if (item) {
                column.push(item.item);
                grid = grid.filter(g => g !== item);
            }
        }
    }

    return chunks;
}