import { TB, T } from "../Constants";

const crumbsContext: T.ReduxFn<T.CrumbsRedux, T.CrumbsActions> = (state, payload) => {
    let { type = null, action = {} } = TB.getObject(payload);
    let { crumb = {}, level = 0, keepChild = false } = TB.getObject(action);

    if (!TB.isValidCrumb(crumb)) crumb = {};
    if (typeof level !== "number" || level < 0) level = 0;

    const recursive = (crumbItem?: T.CrumbsRedux, index = 0) => {
        if (index === level) {
            if (!TB.isValidCrumb(crumb)) return {};
            if (keepChild && !crumb.child) return { ...crumb, child: crumbItem?.child };
            else return crumb;
        }
        if (!TB.isValidCrumb(crumbItem)) return {};
        if (TB.isValidCrumb(crumbItem.child)) return { ...crumbItem, child: recursive(crumbItem.child, index + 1) };
        if (index === level - 1) return { ...crumbItem, child: crumb };
    }

    switch (type) {
        case "REPLACE_CRUMB": return recursive(state) || state;
        case "RESET_CRUMB": return TB.isValidCrumb(crumb) ? crumb : {};
        default: return TB.isValidCrumb(state) ? state : {};
    }
}

export default crumbsContext;

export const RESET_CRUMBS: T.ReduxDispatch<T.CrumbsActions, { crumb?: T.CrumbsRedux }> = action => ({ type: "RESET_CRUMB", action });
export const REPLACE_CRUMBS: T.ReduxDispatch<T.CrumbsActions, { crumb: T.CrumbsRedux, level: number; keepChild?: boolean }> = action => ({ type: "REPLACE_CRUMB", action });