import _ from "lodash";
import React from "react";
import moment from "moment";
import * as M from "../Modal";
import * as H from "../../hooks";
import { v4 as uuid } from "uuid";
import * as C from "../../Common";
import * as S from "../../services";
import * as BS from "react-bootstrap";
import { FlatLabelInput } from "../BuildEditor";
import { TC, T, TB, FP } from "../../Constants";
import * as US from "../../services/user.service";

//#region Types
export type RegDocProps = {
    action?: string;
    elemId?: string;
    regFormId?: string;
    portfolio?: string;
    forceNewDoc?: boolean;
    roots?: string | string[];
    onSave?: (regDoc: T.RegDoc | null, deletedDoc?: T.RegDoc) => void;
}

type Resource = {
    status: Status;
    regDoc?: T.RegDoc;
    elements: Elements[];
    regAction: T.Submission<T.RegAction> | null;
}

type Elements = T.API.Reg.RegElemResume;
type Errors = { [key in ErrorsKey]?: string };
type Status = "load" | "ready" | "error" | "invalidAction" | "invalidElem";
type ErrorsKey = "last_control" | "files" | "controller" | "elements" | "currentElem" | "frequency" | "status";
//#endregion

//#region Constants
const TEXT_CODES = [
    TC.ACTION_SHOW_SPECIFIC, TC.REG_MARK_ALL_AS, TC.BR_TAB_LABEL_DETAILS, TC.ACTION_SHOW_ALL_ELEMS, TC.DATE_TOO_BIG, TC.REG_DEFAULT_FREQ,
    TC.REG_FORM_REGLEMENTATION, TC.REG_FORM_CONTROLLER, TC.REG_FORM_PERIODICAL, TC.REG_FORM_PERIOD, TC.REG_FORM_VALIDITY, TC.REG_FORM_NOTES,
    TC.REG_FORM_FILE, TC.YEAR, TC.GLOBAL_MONTH, TC.GLOBAL_WEEK, TC.GP_GROUP_DAY, TC.ACTION_REG_LAST_DATE, TC.REG_FORM_CONFORM, TC.REG_FORM_NOTE,
    TC.REG_FORM_INFRACTION, TC.GLOBAL_STATUS, TC.GLOBAL_REMARKS, TC.GLOBAL_ELEMENTS, TC.GLOBAL_ELEMENT, TC.GLOBAL_SAVE, TC.ACTION_SHOW_ALL_ACTIONS,
    TC.REG_INVALID_REGION_COUNTRY, TC.REG_INVALID_RESOURCE, TC.REG_NOT_COMPATIBLE_GAMME_EQUIP, TC.REG_FAILED_CONDITION, TC.REG_NO_FORMULA, TC.REG_INVALID_FORMULA,
    TC.REG_MISSING_TERM, TC.REG_CELL_NOT_OCCUPATION, TC.REG_GAMME_UNPRECISE
];

const DF_REG_FORM: Partial<T.RegDoc> = {
    files: [],
    type: "reg",
    frequency: "1y",
    last_control: "",
    formerElement: [],
    currentElement: [],
}

const getDefaultRegForm = (action: string, elem: string) => {
    let clone = { ...DF_REG_FORM };
    clone.regAction = action;
    clone.currentElement = [{ elem, status: "conform", id: uuid() }];
    return clone;
}

const DF_INIT: Resource = { status: "load", regAction: null, elements: [] };
//#endregion

const RegDocForm: React.FC<RegDocProps> = ({ action, elemId, regFormId, onSave, roots, forceNewDoc, portfolio, ...props }) => {
    const [{ userId }] = H.useAuth();
    const lg = H.useLanguage(TEXT_CODES);
    const is_saving = H.useBoolean(false);
    const [rootContext, , updateRoots] = H.useRoots();
    const [errors, setErrors] = React.useState<Errors>({});
    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const [showOnlyConform, setShowOnlyConform] = React.useState(true);
    const [note_tags, set_note_tags, note_tags_status] = H.useAsyncState<T.Option[]>([]);
    const [extra_errors, set_extra_errors] = React.useState<T.Errors<Record<string, any>>>({});
    const [{ status, regAction, regDoc, elements }, setResource] = React.useState<Resource>(DF_INIT);
    const [editedRegDoc, setEdited] = React.useState<Partial<T.RegDoc>>(getDefaultRegForm(action, elemId));

    //#region Fetch Data
    React.useEffect(() => updateRoots({ roots: roots, portfolio }), [updateRoots, roots, portfolio]);
    const apiParams = React.useMemo(() => ({ elemId, action, regFormId, isRegForm: true, ...rootContext }), [action, elemId, regFormId, rootContext]);

    React.useEffect(() => {
        let isSubscribed = true;
        const onError = (status: Status) => setResource({ ...DF_INIT, status });

        US.getFullResources(FP.ACTION_REG_FORM, apiParams)
            .then(({ data }) => {
                if (isSubscribed) {
                    if (data?.hasFailed) {
                        if (data?.error === "invalidAction") onError("invalidAction");
                        else if (data?.error === "invalidElem") onError("invalidElem");
                        else onError("error");
                    }
                    else setResource({
                        ...data,
                        status: "ready",
                        regDoc: forceNewDoc ? undefined : data.regDoc,
                    });
                }
            })
            .catch(() => isSubscribed && onError("error"));

        return () => { isSubscribed = false };
    }, [action, forceNewDoc, apiParams, rootContext]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getNoteTags({ context: { roots, portfolio }, user: userId, type: "regDoc" })
            .then(({ data }) => isSubscribed && set_note_tags(data, 'done'))
            .catch(() => isSubscribed && set_note_tags([], "error"));

        return () => {
            isSubscribed = false;
            set_note_tags([], "load");
        };
    }, [userId, roots, portfolio, set_note_tags]);
    //#endregion

    //#region Error & Load
    const errorBanner = React.useMemo(() => {
        if (status === "load") return <M.Loader />;
        if (status === "error") return <C.ErrorBanner type="danger" textCode={TC.GLOBAL_FAILED_LOAD} />;
        if (status === "invalidElem") return <C.ErrorBanner type="warning" textCode={TC.REG_INVALID_ELEM} />;
        if (status === "invalidAction") return <C.ErrorBanner type="warning" textCode={TC.REG_INVALID_ACTION} />;
        return null;
    }, [status]);
    //#endregion

    //#region Errors
    const invalids = React.useMemo<{ [key in ErrorsKey]?: boolean }>(() => Object.fromEntries(Object.entries(errors).map(([key, error]) => [key, TB.validString(error)])), [errors]);

    React.useEffect(() => {
        let date = TB.getDate(editedRegDoc.last_control);
        if (errors.last_control && date && date.getFullYear() <= 9999) setErrors(p => _.omit({ ...p }, "last_control"));
    }, [errors.last_control, editedRegDoc.last_control]);

    React.useEffect(() => {
        let f = TB.splitFrequency(editedRegDoc.frequency);
        if (errors.frequency && (f || editedRegDoc.frequency === null)) setErrors(p => _.omit({ ...p }, "frequency"));
    }, [errors.frequency, editedRegDoc.frequency])

    React.useEffect(() => {
        if (errors.controller && TB.validString(editedRegDoc.controller)) setErrors(p => _.omit({ ...p }, "controller"));
    }, [errors.controller, editedRegDoc.controller]);

    React.useEffect(() => {
        if (errors.elements && editedRegDoc.currentElement.every(e => TB.mongoIdValidator(e.elem))) setErrors(p => _.omit({ ...p }, "elements"));
    }, [errors.elements, editedRegDoc.currentElement]);
    //#endregion

    //#region Defaults
    React.useEffect(() => {
        if (status === "ready" && regDoc) {
            let updatedRegDoc = _.clone(regDoc);
            let date = TB.getDate(updatedRegDoc.last_control);
            updatedRegDoc.last_control = date === null ? undefined : moment(date).format("YYYY-MM-DD");

            let formerElements = TB.getArray(regDoc.formerElement).filter(f => f.elem === elemId);
            if (formerElements.length > 0) {
                updatedRegDoc.formerElement = updatedRegDoc.formerElement.filter(f => f.elem !== elemId);
                updatedRegDoc.currentElement = formerElements.concat(updatedRegDoc.currentElement);
            }
            setEdited(updatedRegDoc);
        }
    }, [status, regDoc, elemId]);

    React.useEffect(() => {
        if (status === "ready" && regAction && !regDoc) {
            let frequency = null;
            if (!regAction.data.noFreq) {
                if (!!TB.splitFrequency(regAction.data.frequency)) frequency = regAction.data.frequency;
                else frequency = "Y";
            }
            setEdited(p => ({ ...p, frequency, type: regAction.data.type }));
        }
    }, [status, regAction, regDoc]);
    //#endregion

    //#region Frequency
    const timeOptions = React.useMemo(() => [
        { value: "y", label: TC.YEAR },
        { value: "M", label: TC.GLOBAL_MONTH },
        { value: "w", label: TC.GLOBAL_WEEK },
        { value: "d", label: TC.GP_GROUP_DAY },
    ], []);

    const onChangeNumFrequency = React.useCallback((num: string) => {
        let number = TB.getNumber(num);
        if (isNaN(number)) number = 1;
        setEdited(p => {
            let newFreq = p.frequency.replace(/\d+/g, number.toString());
            if (!TB.splitFrequency(newFreq)) newFreq = number + "y";
            return { ...p, frequency: newFreq };
        });
    }, []);

    const defaultFrequency = React.useMemo(() => TB.splitFrequency(regAction?.data?.frequency), [regAction]);
    const onChangeUnitFrequency = React.useCallback((unit: string) => setEdited(p => ({ ...p, frequency: p.frequency.replace(/[a-zA-Z]+/g, unit) })), []);

    const frequency = React.useMemo(() => {
        let freq = TB.splitFrequency(editedRegDoc.frequency);
        if (freq !== null) return freq;
        if (editedRegDoc.frequency === null) return null;
        return { num: "", unit: "y" };
    }, [editedRegDoc.frequency]);

    const switchFrequency = React.useCallback((checked: boolean) => {
        if (checked) setEdited(p => ({ ...p, frequency: regAction?.data?.frequency || "" }));
        else setEdited(p => ({ ...p, frequency: null }));
    }, [regAction?.data?.frequency]);

    const defaultFreqPanel = React.useMemo(() => {
        if (!defaultFrequency) return null;
        if (!frequency || (frequency.unit === defaultFrequency.unit && frequency.num === defaultFrequency.num)) return null;

        let timeUnit = timeOptions.filter(t => t.value === defaultFrequency.unit)[0]?.label;

        return <div className="text-warning text-end">
            {lg.getStaticText(TC.REG_DEFAULT_FREQ)} {defaultFrequency.num} {timeUnit}
        </div>
    }, [timeOptions, frequency, defaultFrequency, lg]);

    const expirationDate = React.useMemo(() => {
        let date = TB.getDate(editedRegDoc.last_control);
        if (date === null || frequency === null) return "";
        /* @ts-ignore */
        return typeof frequency.num === "number" ? moment(date).add(frequency.num, frequency.unit).format("DD/MM/YYYY") : "";
    }, [editedRegDoc.last_control, frequency]);
    //#endregion

    //#region File
    const fileCol = React.useMemo(() => <C.Form.FileUploader
        multiple
        value={editedRegDoc.files}
        error={{ code: errors.files }}
        onChange={files => setEdited(p => ({ ...p, files }))}
    />, [editedRegDoc.files, errors]);
    //#endregion

    //#region Conformity Reason
    const getReason = React.useCallback((elem: Partial<Elements> & { label: string }, isExtra = false) => {
        if (!elem) return null;

        let errors: string[] = [];
        let c = elem.conformity;
        let isWarning = false;
        let isRedAlert = !c.resourceMatch || !(c.gammeMatch || c.unPreciseGamme) || !c.locMatch || (!c.conditionMatch && !c.matchError);

        if (isRedAlert) {
            if (!c.locMatch) errors.push(lg.getStaticText(TC.REG_INVALID_REGION_COUNTRY));
            else if (!c.resourceMatch) errors.push(lg.getStaticText(TC.REG_INVALID_RESOURCE));
            else if (!c.gammeMatch) errors.push(lg.getStaticText(TC.REG_NOT_COMPATIBLE_GAMME_EQUIP));
            else if (!c.conditionMatch) errors.push(lg.getStaticText(TC.REG_FAILED_CONDITION));
        }
        else {
            if (!c.isOccupation) errors.push(lg.getStaticText(TC.REG_CELL_NOT_OCCUPATION));
            else if (c.unPreciseGamme) errors.push(lg.getStaticText(TC.REG_GAMME_UNPRECISE));
            if (c.matchError?.noFormula) errors.push(lg.getStaticText(TC.REG_NO_FORMULA));
            else if (c.matchError?.invalidFormula) errors.push(lg.getStaticText(TC.REG_INVALID_FORMULA));
            else if (c.matchError?.missingTerms?.length > 0) for (let t of c.matchError.missingTerms) errors.push(lg.getStaticText(TC.REG_MISSING_TERM, t));
            if (errors.length > 0) isWarning = true;
        }

        let variant = "text-" + (isRedAlert ? "danger" : (isWarning ? "warning" : "success"));
        let icon = (isRedAlert ? "exclamation" : (isWarning ? "question" : "check")) + "-circle";

        if (!isRedAlert && !isWarning) return isExtra ? null : <i className={`fa fa-${icon} ${variant}`}></i>;

        let content = <i title={errors.join("\n")} className={`fa fa-${icon} ${variant}`} />;
        return content;
    }, [lg])
    //#endregion

    //#region Elements
    const sortElemOptions = React.useCallback((a: Elements, b: Elements) => {
        if (a.path === b.path) {
            if (a.name > b.name) return 1;
            if (b.name > a.name) return 1;
            return 0;
        }
        else return a.path > b.path ? 1 : -1;
    }, []);

    const renderElement = React.useCallback((e: Partial<Elements> & { value: string, label: string }) => <C.Flex justifyContent="between" alignItems="center">
        <i className={`fa fa-${e.path === FP.BUILDING_FORM ? "building" : (e.path === FP.EQUIPEMENT_FORM ? "cog" : "map-marker")}`}></i>
        <div title={e.full_loc} className="mx-2 text-break flex-grow-1 text-wrap text-center" children={e.label} />
        {getReason(e)}
    </C.Flex>, [getReason]);

    const sortByKey = React.useCallback((a: [string, T.RegFormElem[]], b: [string, T.RegFormElem[]]) => {
        if (a[0] === elemId) return -1;
        if (b[0] === elemId) return 1;
        if (a[0] === b[0]) return 0;
        if (TB.mongoIdValidator(a[0])) return -1;
        return 1;
    }, [elemId]);

    const nbGivenElem = React.useMemo(() => editedRegDoc.currentElement.filter(e => e.elem === elemId).length, [editedRegDoc.currentElement, elemId]);
    const groupedElem = React.useMemo(() => Object.entries(_.groupBy(editedRegDoc.currentElement, "elem")).sort(sortByKey), [editedRegDoc, sortByKey]);
    const elemOptions = React.useMemo(() => elements.sort(sortElemOptions).map(e => ({ ...e, value: e._id, label: e.name })), [elements, sortElemOptions]);

    const getElemOptions = React.useCallback((elem?: string) => {
        let alreadyConformStatus = TB.getArray(editedRegDoc?.currentElement)
            .filter(e => e.status === "conform" && TB.mongoIdValidator(e.elem))
            .map(e => e.elem);

        let options = elemOptions.filter(e => !alreadyConformStatus.includes(e.value) || e.value === elem);
        if (showOnlyConform) return options.filter(e => e.conform || e.value === elem);
        return options;
    }, [showOnlyConform, elemOptions, editedRegDoc?.currentElement]);

    const onAddElem = React.useCallback((elem?: string) => {
        setEdited(p => ({ ...p, currentElement: TB.getArray(p.currentElement).concat({ elem, status: "notes", id: uuid() }) }));
    }, []);

    const onChangeElem = React.useCallback((id: string, prop: string, value: string, textAreaRef?: HTMLTextAreaElement) => {
        TB.resizeTextArea(textAreaRef);
        let forcedStatus: T.RegStatus = null;

        setEdited(p => {
            if (prop === "elem") {
                let statuses = TB.getArray(p?.currentElement).filter(e => e.elem === value).map(e => e.status);
                if (statuses.includes("conform")) forcedStatus = "conform";
                else forcedStatus = "notes";
            }

            let newCurrentElement = p.currentElement.map(e => {
                if (e.id === id) {
                    let newE = _.set({ ...e }, prop, value);
                    if (forcedStatus) newE.status = forcedStatus;
                    return newE;
                }
                return e;
            });

            return { ...p, currentElement: newCurrentElement };
        });
    }, []);

    const onChangeStatus = React.useCallback((status: T.RegStatus, elemId: string, id: string) => {
        if (status === "conform") setEdited(p => {
            let isFound = false;
            let newArray = p.currentElement.map(e => {
                if (e.elem === elemId) {
                    if (isFound) return null;
                    else {
                        isFound = true;
                        return { ...e, status };
                    }
                }
                else return e;
            }).filter(e => e !== null);
            return { ...p, currentElement: newArray };
        });
        else setEdited(p => ({ ...p, currentElement: p.currentElement.map(e => e.id === id ? { ...e, status } : e) }));
    }, []);

    const onRemove = React.useCallback((id: string) => {
        setEdited(p => ({ ...p, currentElement: p.currentElement.filter(e => e.id !== id) }));
    }, []);
    //#endregion

    //#region Status
    const statusList = React.useMemo(() => [
        { value: "notes", label: TC.REG_FORM_NOTE, color: "#FFA726" },
        { value: "conform", label: TC.REG_FORM_CONFORM, color: "#4EAE53" },
        { value: "infraction", label: TC.REG_FORM_INFRACTION, color: "#FF7043" },
    ], []);

    const getStatusOptions = React.useCallback((elem: T.RegFormElem) => {
        let list = regAction?.data?.status_list || [];
        if (list.length > 0) return list.map(s => ({ value: s.code, label: s.lang[lg.prop] || Object.values(s.lang)[0], color: s.color }));
        else {
            let alreadyConformStatus = TB.getArray(editedRegDoc?.currentElement).some(e => e?.status === "conform" && e?.elem === elem?.elem && e.id !== elem.id);
            if (!alreadyConformStatus) return statusList;
            return statusList.filter(s => s.value !== "conform");
        }
    }, [lg, editedRegDoc?.currentElement, statusList, regAction?.data?.status_list]);

    const renderStatus = React.useCallback((opt: { label: string, color?: string }) => <span style={{ color: opt.color }}>{opt.label}</span>, []);
    //#endregion

    //#region Save
    const saveForm = React.useCallback(() => {
        let errors: Errors = {};
        let new_extra_errors = {} as typeof extra_errors;
        let date = TB.getDate(editedRegDoc.last_control);
        let has_empty_files = editedRegDoc.files.length === 0 || editedRegDoc.files.every(f => f.storage === "removed");

        if (!date) errors.last_control = TC.GLOBAL_REQUIRED_FIELD;
        else if (date.getFullYear() > 9999) errors.last_control = TC.DATE_TOO_BIG;
        if (has_empty_files) errors.files = TC.GLOBAL_REQUIRED_FIELD;
        if (!TB.validString(editedRegDoc.controller)) errors.controller = TC.GLOBAL_REQUIRED_FIELD;
        if (editedRegDoc.currentElement.length === 0) errors.currentElem = TC.GLOBAL_REQUIRED_FIELD;
        if (editedRegDoc.currentElement.some(e => !TB.mongoIdValidator(e.elem))) errors.elements = TC.GLOBAL_REQUIRED_FIELD;
        if (editedRegDoc.frequency !== null && !TB.splitFrequency(editedRegDoc.frequency)) errors.frequency = TC.GLOBAL_REQUIRED_FIELD;

        let is_missing_status = editedRegDoc.currentElement.some(e => {
            let available_status = getStatusOptions(e);
            return !available_status.some(s => s.value === e.status);
        });
        if (is_missing_status) errors.status = TC.REG_DOC_STATUS_REQUIRED_FIELD;

        for (let item of regAction?.data?.extra_properties || []) {
            let error = null;
            if (item.data_type === "date") {
                let date = TB.getDate(editedRegDoc.extra_properties?.[item.prop]);
                if (!date && item.required) error = TC.GLOBAL_REQUIRED_FIELD;
            }
            else if (item.data_type === "number") {
                let number = TB.getNumber(editedRegDoc.extra_properties?.[item.prop]);
                if (isNaN(number) && item.required) error = TC.GLOBAL_REQUIRED_FIELD;
            }
            else if (item.data_type === "string") {
                let string = TB.getString(editedRegDoc.extra_properties?.[item.prop]);
                if (string.length === 0 && item.required) error = TC.GLOBAL_REQUIRED_FIELD;
            }
            else if (item.data_type === "boolean") {
                let bool = typeof editedRegDoc.extra_properties?.[item.prop] === "boolean" ? editedRegDoc.extra_properties?.[item.prop] : undefined;
                if (typeof bool !== "boolean" && item.required) error = TC.GLOBAL_REQUIRED_FIELD;
            }
            else if (item.data_type === "select") {
                let val = editedRegDoc.extra_properties?.[item.prop];
                if (!TB.validString(val) && item.required) error = TC.GLOBAL_REQUIRED_FIELD;
            }
            if (error) new_extra_errors[item.prop] = error;
        }

        if (Object.keys(errors).length > 0 || Object.keys(new_extra_errors).length > 0) {
            setErrors(errors);
            set_extra_errors(new_extra_errors);
        }
        else {
            is_saving.setTrue();
            let unmount = M.renderLoader();

            let updatePromise: (doc: Partial<T.RegDoc>) => Promise<{ data: T.RegDoc }> | null = null;
            if (TB.mongoIdValidator(regDoc?._id)) updatePromise = S.updateRegForm;
            else updatePromise = S.createRegDoc;
            let actual_files = editedRegDoc.files?.filter?.(f => f.storage !== "removed");

            updatePromise({ ...editedRegDoc, files: actual_files || [] })
                .then(({ data }) => onSave?.(data))
                .catch(M.Alerts.updateError)
                .finally(() => {
                    unmount();
                    is_saving.setFalse();
                });
        }
    }, [onSave, getStatusOptions, editedRegDoc, is_saving, regDoc?._id, regAction?.data?.extra_properties]);

    const deleteForm = React.useCallback(() => {
        if (regDoc && TB.mongoIdValidator(regDoc._id)) M.askConfirm().then(confirmed => {
            if (confirmed) S.removeRegForm(regDoc._id)
                .then(() => onSave?.(null, regDoc))
                .catch(M.Alerts.deleteError);
        });
    }, [regDoc, onSave]);
    //#endregion

    //#region set All as
    const setAllElemAs = React.useCallback((status: T.RegStatus) => {
        if (status === "conform") setEdited(p => {
            let elements = _.uniq(TB.getArray(p.currentElement).map(e => e.elem));
            return ({ ...p, currentElement: elements.map(elem => ({ elem, status, id: uuid() })) });
        });
        else setEdited(p => ({ ...p, currentElement: TB.getArray(p.currentElement).map(e => ({ ...e, status })) }));
    }, []);
    //#endregion

    const render_extra_prop = React.useCallback((item: T.RegAction["extra_properties"][number]) => {
        let content: React.ReactNode = null;
        if (item.data_type === "date") content = <C.Form.DateTime
            noBottomMargin
            required={item.required}
            label={item.names[lg.prop]}
            error={extra_errors[item.prop]}
            value={editedRegDoc.extra_properties?.[item.prop]}
            onChange={date => setEdited(p => ({ ...p, extra_properties: { ...p.extra_properties, [item.prop]: date } }))}
        />;
        else if (item.data_type === "number") content = <C.Form.NumField
            noBottomMargin
            required={item.required}
            label={item.names[lg.prop]}
            error={extra_errors[item.prop]}
            suffix={item.unit_tr?.[lg.prop] || item.unit}
            value={editedRegDoc.extra_properties?.[item.prop]}
            onChange={number => setEdited(p => ({ ...p, extra_properties: { ...p.extra_properties, [item.prop]: number } }))}
        />;
        else if (item.data_type === "string") content = <C.Form.TextField
            noBottomMargin
            required={item.required}
            label={item.names[lg.prop]}
            error={extra_errors[item.prop]}
            suffix={item.unit_tr?.[lg.prop] || item.unit}
            value={editedRegDoc.extra_properties?.[item.prop]}
            onChange={text => setEdited(p => ({ ...p, extra_properties: { ...p.extra_properties, [item.prop]: text } }))}
        />;
        else if (item.data_type === "boolean") content = <C.Form.RadioBool
            noBottomMargin
            name={item.prop}
            required={item.required}
            label={item.names[lg.prop]}
            error={extra_errors[item.prop]}
            value={editedRegDoc.extra_properties?.[item.prop]}
            onChange={value => setEdited(p => ({ ...p, extra_properties: { ...p.extra_properties, [item.prop]: value } }))}
        />;
        else if (item.data_type === "select") content = <C.Form.Select
            noBottomMargin
            required={item.required}
            label={item.names[lg.prop]}
            error={extra_errors[item.prop]}
            value={editedRegDoc.extra_properties?.[item.prop]}
            onChange={value => setEdited(p => ({ ...p, extra_properties: { ...p.extra_properties, [item.prop]: value } }))}
            options={(item.unit || "").split("|").map(t => t.trim()).map((o, i) => ({ value: o, label: item.options?.[i]?.[lg.prop] || o }))}
        />;

        if (content === null) return null;
        return <BS.Col key={item.prop} md={12} className="mb-2" children={content} />;
    }, [editedRegDoc.extra_properties, extra_errors, lg.prop]);

    const add_tag = React.useCallback((text: string) => new Promise((resolve, reject) => {
        let submission = { name: text, users: [userId], sites: [], type: "regDoc" } as T.NoteTag;
        S.createSubmission({ submission, path: FP.NOTE_TAG_FORM }).then(({ data }) => {
            let new_tag = data.submissions[0] as T.Submission<T.NoteTag>;
            if (new_tag) {
                let new_option = { label: new_tag.data.name, value: new_tag._id } as T.Option;
                set_note_tags(p => p.concat(new_option));
                setEdited(p => ({ ...p, tags: p.tags.concat(new_option.value) }));
                resolve(new_option);
            }
            else resolve(null);
        }).catch(reject);
    }), [set_note_tags, userId]);

    if (errorBanner) return <div>{errorBanner}</div>;
    return <div className="p-2" ref={containerRef}>
        <C.Flex justifyContent='between' alignItems="center" className="mb-3">
            <span className="h5">
                {lg.getElemObj(regAction?._id, "name", regAction?.data?.name)} {regDoc && ` - ${moment(regDoc.last_control).format("DD/MM/YY")}`}
            </span>
            <BS.ButtonGroup>
                <C.Button disabled={is_saving.value} onClick={saveForm} text={TC.GLOBAL_SAVE} icon="save" />
                <C.Button onClick={deleteForm} disabled={!TB.mongoIdValidator(regDoc?._id)} icon="trash" variant="danger" />
            </BS.ButtonGroup>
        </C.Flex>
        <BS.Row className="align-items-center mb-4">
            <BS.Col className="mb-2" md={12}>
                <FlatLabelInput label={lg.getStaticText(TC.REG_FORM_REGLEMENTATION)}>
                    <span className="fw-bold">{lg.getElemObj(regAction?._id, "name", regAction?.data?.name)}</span>
                </FlatLabelInput>
            </BS.Col>
            <BS.Col className="mb-2" md={6}>
                <FlatLabelInput label={lg.getStaticText(TC.ACTION_REG_LAST_DATE)} required>
                    <BS.FormControl type="date" isInvalid={invalids.last_control} value={editedRegDoc.last_control || ""} onChange={e => setEdited(p => _.set({ ...p }, "last_control", e.target.value))} />
                    <C.Feedback text={errors.last_control} />
                </FlatLabelInput>
            </BS.Col>
            <BS.Col className="mb-2" md={6}>
                <FlatLabelInput label={lg.getStaticText(TC.REG_FORM_CONTROLLER)} required>
                    <BS.FormControl isInvalid={invalids.controller} value={editedRegDoc.controller || ""} onChange={e => setEdited(p => _.set({ ...p }, "controller", e.target.value))} />
                    <C.Feedback text={errors.controller} />
                </FlatLabelInput>
            </BS.Col>
            <BS.Col className="mb-2" md={6}>
                <FlatLabelInput label={lg.getStaticText(TC.REG_FORM_PERIODICAL)}>
                    <BS.Form.Check type="switch" checked={editedRegDoc.frequency !== null} onChange={e => switchFrequency(e.target.checked)} />
                </FlatLabelInput>
            </BS.Col>
            {frequency !== null && <>
                <BS.Col className="mb-2" md={6}>
                    <FlatLabelInput required label={lg.getStaticText(TC.REG_FORM_PERIOD)}>
                        <C.Flex>
                            <BS.FormControl type="number" min={1} value={frequency.num} onChange={e => onChangeNumFrequency(e.target.value)} />
                            <C.TypeAhead
                                options={timeOptions}
                                selectedItems={frequency.unit}
                                onChange={opt => onChangeUnitFrequency(opt[0]?.value)}
                            />
                        </C.Flex>
                        <C.Feedback text={errors.frequency} />
                    </FlatLabelInput>
                    {defaultFreqPanel}
                </BS.Col>
                <BS.Col className="mb-2" md={6}>
                    <FlatLabelInput label={lg.getStaticText(TC.REG_FORM_VALIDITY)}>
                        {expirationDate}
                    </FlatLabelInput>
                </BS.Col>
            </>}
            <BS.Col className="mb-2" md={12}>
                <FlatLabelInput label={lg.getStaticText(TC.REG_FORM_NOTES)}>
                    <BS.FormControl as="textarea" value={editedRegDoc.notes || ""} onChange={e => setEdited(p => _.set({ ...p }, "notes", e.target.value))} />
                </FlatLabelInput>
            </BS.Col>
            <BS.Col className="mb-2" md={12}>
                <FlatLabelInput required label={lg.getStaticText(TC.REG_FORM_FILE)} children={fileCol} />
            </BS.Col>
            <BS.Col className="mb-2" md={12}>
                <C.Form.Select
                    multiple
                    options={note_tags}
                    labelPosition="left"
                    label={TC.DATASET_TAGS}
                    value={editedRegDoc.tags}
                    loading={note_tags_status === "load"}
                    onChange={tags => setEdited(p => ({ ...p, tags }))}
                    typeahead={{ allowNewOption: true, onAddOption: add_tag }}
                />
            </BS.Col>
        </BS.Row>

        {regAction?.data?.extra_properties && <BS.Row className="g-1 mb-3" children={regAction.data.extra_properties.map(render_extra_prop)} />}

        <div>
            <C.Flex className="h4 mb-3" justifyContent="between" alignItems="center">
                <div>
                    {lg.getStaticText(TC.GLOBAL_ELEMENTS)}
                    <BS.Button onClick={() => onAddElem()} size="sm" className="ml-3"><i className="fa fa-plus"></i></BS.Button>
                </div>
                {groupedElem.length > 0 && <div>
                    <BS.FormLabel className="me-2 mb-0">{lg.getStaticText(TC.REG_MARK_ALL_AS)} :</BS.FormLabel>
                    <BS.ButtonGroup>
                        <BS.Button onClick={() => setAllElemAs("conform")} variant="success">{lg.getStaticText(TC.REG_FORM_CONFORM)}</BS.Button>
                        <BS.Button onClick={() => setAllElemAs("notes")} variant="warning">{lg.getStaticText(TC.REG_FORM_NOTE)}</BS.Button>
                        <BS.Button onClick={() => setAllElemAs("infraction")} variant="danger">{lg.getStaticText(TC.REG_FORM_INFRACTION)}</BS.Button>
                    </BS.ButtonGroup>
                </div>}
            </C.Flex>
            <C.Feedback text={errors.currentElem} />

            {groupedElem.length > 0 && <BS.Row className="mb-2 text-center fw-bold g-2">
                <BS.Col md={4}>{lg.getStaticText(TC.GLOBAL_ELEMENT)}<span className="ms-1 text-danger">*</span></BS.Col>
                <BS.Col md={2}>{lg.getStaticText(TC.GLOBAL_STATUS)}</BS.Col>
                <BS.Col md={4}>{lg.getStaticText(TC.BR_TAB_LABEL_DETAILS)}</BS.Col>
                <BS.Col md={2}></BS.Col>
            </BS.Row>}

            {groupedElem.map(([elem, elemArray]) => <React.Fragment key={elem}>
                {elemArray.map((ce, index) => <BS.Row key={ce.id} className="text-center g-2 mb-1">
                    <BS.Col md={4}>
                        <C.TypeAhead
                            selectedItems={ce.elem}
                            renderItem={renderElement}
                            options={getElemOptions(ce.elem)}
                            renderInputExtra={([opt]) => getReason(opt, true)}
                            onChange={opt => onChangeElem(ce.id, "elem", opt[0]?.value)}
                            invalid={invalids.elements && !TB.mongoIdValidator(ce.elem)}
                            disabled={TB.mongoIdValidator(ce.elem) && ce.elem === elemId && nbGivenElem <= 1}
                        />
                        {!TB.mongoIdValidator(ce.elem) && <C.Feedback text={errors.elements} />}
                    </BS.Col>
                    <BS.Col md={2}>
                        <C.TypeAhead
                            hideClearButton
                            renderItem={renderStatus}
                            selectedItems={ce.status}
                            options={getStatusOptions(ce)}
                            onChange={opt => onChangeStatus(opt[0]?.value as T.RegStatus, ce.elem, ce.id)}
                            colorSelected={(options: ReturnType<typeof getStatusOptions>) => options[0]?.color}
                        />
                        <C.Feedback text={errors.status} />
                    </BS.Col>
                    <BS.Col md={4}>
                        {/* @ts-ignore */}
                        <BS.FormControl ref={TB.resizeTextArea} as="textarea" value={ce.notes || ""} onChange={e => onChangeElem(ce.id, "notes", e.target.value, e.target)} />
                    </BS.Col>
                    <BS.Col md={2}>
                        <C.Flex justifyContent="start">
                            <BS.Button disabled={TB.mongoIdValidator(ce.elem) && ce.elem === elemId && nbGivenElem <= 1} onClick={() => onRemove(ce.id)} className="me-2" size="sm" variant="danger">
                                <i className="fa fa-times"></i>
                            </BS.Button>
                            {ce.status !== "conform" && index === elemArray.length - 1 && TB.mongoIdValidator(ce.elem) && <BS.Button size="sm" onClick={() => onAddElem(ce.elem)}>
                                <i className="fa fa-plus"></i>
                            </BS.Button>}
                        </C.Flex>
                    </BS.Col>
                </BS.Row>)}
            </React.Fragment>)}

            {groupedElem.length > 0 && <BS.Row className="mt-2">
                <BS.Col className="d-flex">
                    <BS.FormLabel className="me-2">{lg.getStaticText(TC.ACTION_SHOW_ALL_ELEMS)}</BS.FormLabel>
                    <BS.FormCheck type="switch" checked={!showOnlyConform} onChange={() => setShowOnlyConform(p => !p)} />
                </BS.Col>
            </BS.Row>}
        </div>
    </div>;
}

export default RegDocForm;