import classNames from 'classnames';
import { ChangeEventHandler, FC } from 'react';
import { Form, Button } from 'react-bootstrap';

type RadioItemProps = {
    name: string;
    image: string;
    label: string;
    active?: boolean;
    onChange: ChangeEventHandler<HTMLInputElement>;
};

const RadioItem: FC<RadioItemProps> = ({ name, label, active = false, onChange, image }) => <Button variant="theme-default" className={classNames({ active })}>
    <Form.Label htmlFor={`${name}-${label}`} className="cursor-pointer hover-overlay" >
        <img className="w-100" src={image} alt="" />
    </Form.Label>
    <Form.Check
        name={name}
        type="radio"
        checked={active}
        onChange={onChange}
        id={`${name}-${label}`}
        label={label.charAt(0).toUpperCase() + label.slice(1)}
    />
</Button>;

export default RadioItem;