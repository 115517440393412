import React from 'react';
import { Flex } from '../../../Common';
import { T } from '../../../Constants';

const NavbarVerticalMenuItem: React.FC<{ route: T.RoutesType }> = ({ route }) => <Flex alignItems="center">
  {route.icon && <span className="nav-link-icon">
    <i className={`fa fa-${route.icon}`}></i>
  </span>}
  <span className={"nav-link-text ps-1"}>{route.name}</span>
</Flex>;

export default React.memo(NavbarVerticalMenuItem);