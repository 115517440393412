export const remToPx = (rem: number) => rem * parseFloat(getComputedStyle(document.documentElement).fontSize);

export const mobileScreen = () => [window.innerHeight, window.innerWidth].some(x => x <= 600);

export const getVH = (percent = 100) => {
    var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    return (percent * h) / 100;
}

export const getVW = (percent = 100) => {
    var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return (percent * w) / 100;
}

export const resizeTextArea = (textAreaRef: HTMLElement, minPx?:number) => {
    if (textAreaRef) {
        // We need to reset the height momentarily to get the correct scrollHeight for the textarea
        textAreaRef.style.height = "0px";
        let scrollHeight = textAreaRef.scrollHeight;
        if(typeof minPx === "number" && scrollHeight < minPx) scrollHeight = minPx;

        // We then set the height directly, outside of the render loop
        // Trying to set this with state or a ref will product an incorrect value.
        textAreaRef.style.height = scrollHeight + "px";
    }
}

export const copySimulation = (text: string) => new Promise<boolean>((resolve, reject) => {
    if (!navigator?.clipboard) reject();
    // Try to save to clipboard then save it in the state if worked
    else try {
        navigator.clipboard.writeText(text)
            .then(() => resolve(true))
            .catch(reject);
    }
    catch (error) {
        reject(error);
    }
});