import React from "react";
import * as H from "../../hooks";
import * as BS from "react-bootstrap";

//#region Types
type FeedbackProps = {
    /** The text to display in the feedback, no feedback if no text */
    text?: string;
    /** The type of feedback */
    isValid?: boolean;
    /** The size of the text */
    isTextSmall?: boolean;
}
//endregion

const Feedback: React.FC<FeedbackProps> = props => {
    const lg = H.useLanguage();
    const type = React.useMemo(() => props.isValid ? "valid" : "invalid", [props.isValid]);

    if (!props.text) return null;
    else return <BS.FormControl.Feedback
        type={type}
        style={{ display: "initial" }}
        children={lg.getStaticElem(props.text)}
        className={props.isTextSmall ? "fs-85" : ""}
    />;
}

export default Feedback;