import React from "react";
import * as G from "../Grid";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as S from "../../../services";
import { CellsTypes as CT } from "../AgGridDefs";
import { FP, T, TC, TABS, GAMMES } from "../../../Constants";

type FormatRow = (row: Row) => FormattedRows;
type SetRows = React.Dispatch<React.SetStateAction<Row[]>>;
type GammeOptions = ReturnType<T.API.Utils.Gammes.GetGammesOptions>;
type Row = ReturnType<T.API.Utils.Tables.GetEquipGammesRows>[number];
type Column = ReturnType<T.API.Utils.Tables.GetEquipGammesColumns>[number];

type FormattedRows = Row & Record<
    "last_level" | "tr_name_level_1" | "tr_name_level_2" | "tr_name_level_3" |
    "tr_name_level_4" | "tr_name_level_5" | "tr_name_level_6" | "tr_name_level_7" |
    "tr_red_flag" | "tr_active" | "tr_cdm_props", string>

export type GammesProps = {
    /** The table id */
    origin: string;
    /** Do not create Sub-Columns for the properties loaded */
    no_sub_columns_for_loaded?: boolean;
};

export const Gammes: React.FC<GammesProps> = props => {
    const lg = H.useLanguage();
    const [forms] = H.useFormIds();
    const loading = H.useBoolean(false);
    const rows = React.useRef<Row[]>([]);
    const grid = React.useRef<G.TableRef<Row>>(null);
    H.useAuth({ tabName: TABS.EQUIPMENTS_GAMMES_TABLE });
    const gammes_options = React.useRef<GammeOptions>([]);
    const [status, set_status] = React.useState<T.AsyncStates>("load");
    const form_id = React.useMemo(() => forms[FP.EQUIPEMENT_FORM], [forms]);
    const [columns, set_columns, col_status] = H.useAsyncState<Column[]>([]);

    const format_row = React.useCallback<FormatRow>(r => {
        let all_levels = [
            { id: r.id_level_7, name: r.name_level_7 },
            { id: r.id_level_6, name: r.name_level_6 },
            { id: r.id_level_5, name: r.name_level_5 },
            { id: r.id_level_4, name: r.name_level_4 },
            { id: r.id_level_3, name: r.name_level_3 },
            { id: r.id_level_2, name: r.name_level_2 },
            { id: r.id_level_1, name: r.name_level_1 },
        ];
        let last_level = all_levels.find(l => l.id);

        return {
            ...r,
            last_level: lg.getTextObj(last_level.id, "name", last_level.name),
            tr_name_level_1: lg.getTextObj(r.id_level_1, "name", r.name_level_1),
            tr_name_level_2: lg.getTextObj(r.id_level_2, "name", r.name_level_2),
            tr_name_level_3: lg.getTextObj(r.id_level_3, "name", r.name_level_3),
            tr_name_level_4: lg.getTextObj(r.id_level_4, "name", r.name_level_4),
            tr_name_level_5: lg.getTextObj(r.id_level_5, "name", r.name_level_5),
            tr_name_level_6: lg.getTextObj(r.id_level_6, "name", r.name_level_6),
            tr_name_level_7: lg.getTextObj(r.id_level_7, "name", r.name_level_7),
            tr_cdm_props: (r.cdm_props || []).map(p => lg.getTextObj(form_id, p, p)).join(),
            tr_red_flag: lg.getStaticText(GAMMES.RED_FLAG_CAT.find(c => c.value === r.red_flag)?.label),
            tr_active: (r.active || []).map(a => lg.getStaticText(GAMMES.ACTIVE.find(c => c.value === a)?.label)).join(),
        }
    }, [lg, form_id]);

    const set_rows = React.useCallback<SetRows>((setter) => {
        let updated_rows: Row[] = [];
        if (typeof setter === "function") updated_rows = setter(rows.current);
        else updated_rows = setter;
        rows.current = updated_rows;
        gammes_options.current = updated_rows.map(r => ({
            value: r._id,
            omniclass: r.omniclass,
            isEquipment: r.isEquipment,
            parent: r["id_level_" + (r.level - 1)],
            label: lg.getTextObj(r._id, "name", r.name),
        }));
    }, [lg]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getEquipGammesColumns()
            .then(({ data }) => isSubscribed && set_columns(data, "done"))
            .catch(() => isSubscribed && set_columns([], "error"));
        return () => {
            isSubscribed = false;
            set_columns([], "load");
        }
    }, [set_columns]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getEquipGammesRows(null).then(({ data }) => {
            if (isSubscribed) {
                set_status("done");
                set_rows(data);
            }
        }).catch(() => isSubscribed && set_status("error"));
        return () => {
            set_status("load");
            isSubscribed = false;
        }
    }, [set_rows]);

    React.useEffect(() => {
        if (status === "done") {
            let formatted_rows = rows.current.map(format_row);
            if (grid.current.grid.api.getDisplayedRowCount() === 0) grid.current.grid.api.applyTransaction({ add: formatted_rows });
            else grid.current.grid.api.applyTransaction({ update: formatted_rows });
        }
    }, [format_row, status]);

    const columns_no_group = React.useMemo(() => {
        let no_group_columns: Column[] = [];

        const recursive = (element: Column) => {
            if (element.col_type === "group") element.content.forEach(recursive);
            else if (element.og_type !== "file" && element.og_type !== "other") no_group_columns.push(element);
        };

        for (let elem of columns) recursive(elem);
        return no_group_columns;
    }, [columns]);

    const options = React.useMemo(() => ({
        cdm_props: (row => {
            let available_props = columns_no_group.filter(c => row[c.prop] || row.cdm_props?.includes?.(c.prop as any));
            let prop_options: T.Option[] = available_props.map(c => ({ label: lg.getTextObj(form_id, c.prop, c.label), value: c.prop }));
            return prop_options;
        }) as G.ColDefParams<Row>["getValues"],
    }), [columns_no_group, form_id, lg]);

    const cols = React.useMemo(() => {
        let fixed_columns: G.TableProps<Row>["columns"] = [
            { field: "last_level", headerName: FP.EQUIPMENT_GAMME, editable: false },
            { field: "tr_name_level_1", headerName: TC.EQUIP_GAMME_LABELS, editable: false, params: { header_template: 1 } },
            { field: "tr_name_level_2", headerName: TC.EQUIP_GAMME_LABELS, editable: false, params: { header_template: 2 } },
            { field: "tr_name_level_3", headerName: TC.EQUIP_GAMME_LABELS, editable: false, params: { header_template: 3 } },
            { field: "tr_name_level_4", headerName: TC.EQUIP_GAMME_LABELS, editable: false, params: { header_template: 4 } },
            { field: "tr_name_level_5", headerName: TC.EQUIP_GAMME_LABELS, editable: false, params: { header_template: 5 } },
            { field: "tr_name_level_6", headerName: TC.EQUIP_GAMME_LABELS, editable: false, params: { header_template: 6 } },
            { field: "tr_name_level_7", headerName: TC.EQUIP_GAMME_LABELS, editable: false, params: { header_template: 7 } },
            { field: "omniclass", headerName: TC.EQUIP_STORE_OMNICLASS, editable: false },
            { field: "isEquipment", headerName: TC.GAMME_TABLE_IS_EQUIPMENT, type: CT.TYPE_CHECKBOX },
            { field: "definition", headerName: TC.GAMME_TABLE_DEFINITION },
            { field: "gamme_lifespan", headerName: TC.GAMME_TABLE_LIFESPAN, type: CT.TYPE_NUMBER },
            { field: "tr_active", headerName: TC.ALARM_ACTIVE, type: CT.TYPE_SELECT, params: { values: GAMMES.ACTIVE, field_value: "active", multiple: true }, },
            { field: "tr_red_flag", headerName: TC.CATEGORIES_RED_FLAG, type: CT.TYPE_SELECT, params: { values: GAMMES.RED_FLAG_CAT, field_value: "red_flag", empty_option: true } },
            { field: "tr_cdm_props", headerName: "cdm_props", type: CT.TYPE_SELECT, params: { header_id: form_id, multiple: true, field_value: "cdm_props", getValues: options.cdm_props } },
            { field: "equip_color", headerName: TC.GLOBAL_COLOR, type: CT.TYPE_COLOR },
            { field: "brand", headerName: "brand", hide: true, type: CT.TYPE_CHECKBOX, params: { header_id: form_id } },
            { field: "model", headerName: "model", hide: true, type: CT.TYPE_CHECKBOX, params: { header_id: form_id } },
        ];

        const recursive = (element: Column, parent = fixed_columns) => {
            if (element.col_type === "group") {
                if (props.no_sub_columns_for_loaded) {
                    for (let sub_elem of element.content) recursive(sub_elem);
                }
                else {
                    let name = "";
                    if (Array.isArray(element.labels)) name = element.labels.map(l => lg.getStaticText(l)).join(" - ");
                    else name = lg.getStaticText(element.label)

                    let new_col = { children: [], headerName: name } as typeof parent[number];
                    for (let sub_elem of element.content) recursive(sub_elem, (new_col as any).children);
                    parent.push(new_col);
                }
            }
            else {
                let editable = true,
                    field = element.prop,
                    type = CT.TYPE_CHECKBOX;
                if (field !== "tags") {
                    let name = lg.getTextObj(form_id, element.prop, element.prop);
                    if (element.extra?.unit) name += " [" + lg.getStaticText(element.extra.unit) + "]";
                    parent.push({ field, headerName: name, type, hide: true, editable });
                }
            }
        }

        for (let elem of columns) recursive(elem);
        return fixed_columns;
    }, [columns, options, form_id, lg, props.no_sub_columns_for_loaded]);

    const events = React.useMemo(() => ({
        create: (parent_gamme?: Row) => {
            M.renderGammeForm({ parent: parent_gamme?._id, options: gammes_options.current }).then(gammes => {
                if (gammes) {
                    let to_add_rows_ids = [] as string[];

                    for (let gamme of gammes) {
                        let current_node = grid.current.grid.api.getRowNode(gamme._id);
                        if (!current_node) to_add_rows_ids.push(gamme._id);
                    }

                    let to_add_rows = gammes.filter(g => to_add_rows_ids.includes(g._id))
                    set_rows(p => p.concat(to_add_rows));
                    grid.current.grid.api.applyTransaction({ add: to_add_rows.map(format_row) });
                }
            });
        },
        edit: (row: Row) => {
            if (row) M.renderGammeForm({ gamme_id: row._id, options: gammes_options.current }).then(gammes => {
                if (gammes) {
                    let new_gammes = gammes.map(g => g._id);
                    grid.current.grid.api.applyTransaction({ update: gammes.map(format_row) });
                    set_rows(p => p.map(r => new_gammes.includes(r._id) ? gammes.find(g => g._id === r._id) : r));
                }
            });
        },
        reassign: (row?: Row) => {
            M.renderGammeAssigner({ to_be_reassigned: row?._id, options: gammes_options.current }).then(params => {
                if (params) S.reassignGamme({ from: params.reassigned_from, to: params.reassigned_to }).then(() => {
                    let removed_row = grid.current.grid.api.getRowNode(params.reassigned_from);
                    if (removed_row) {
                        // Update the grid via the API
                        grid.current.grid.api.applyTransaction({ remove: [removed_row.data] });
                        // Update the ref that contains the initial rows
                        set_rows(p => p.filter(r => r._id !== params.reassigned_from));
                    }
                }).catch(M.Alerts.updateError);
            });
        },
        update_inheritance: () => {
            loading.setTrue();
            S.updateGammesInheritance()
                .then(({ data }) => {
                    set_rows(data);
                    grid.current.grid.api.setRowData(data.map(format_row));
                })
                .catch(M.Alerts.updateError)
                .finally(loading.setFalse);
        },
    }), [loading, set_rows, format_row]);

    const context_menu = React.useCallback<G.TableProps<Row>["getContextMenuItems"]>(event => {
        let row = event.node?.data;
        let actions = [] as ReturnType<G.TableProps<Row>["getContextMenuItems"]>;

        // Create a new gamme
        actions.push({
            action: () => events.create(),
            icon: `<i class="me-2 fa fa-plus"></i>`,
            name: lg.getStaticText(TC.TABLE_GAMME_CREATE_NEW),
        });
        if (row) {
            // Create a new gamme under current one
            actions.push({
                action: () => events.create(row),
                icon: `<i class="me-2 fa fa-plus"></i>`,
                name: lg.getStaticText(TC.TABLE_GAMME_CREATE_NEW_UNDER_CURRENT),
            });
            // Add a separator
            actions.push("separator");
            // Edit the current gamme
            actions.push({
                action: () => events.edit(row),
                icon: `<i class="me-2 fa fa-pencil-alt"></i>`,
                name: lg.getStaticText(TC.TABLE_GAMME_EDIT_CURRENT),
            });
            // Reassign the current gamme
            actions.push({
                disabled: row.has_children,
                action: () => events.reassign(row),
                icon: `<i class="me-2 fa fa-exchange-alt"></i>`,
                name: lg.getStaticText(TC.TABLE_GAMME_REASSIGN_CURRENT),
            });
        }
        // Add a separator before the default items
        if (event.defaultItems) actions.push("separator", ...event.defaultItems);
        return actions;
    }, [events, lg]);

    //#region Inline Editing
    const always_allow_edit_fields = React.useMemo<(keyof FormattedRows)[]>(() => [
        "isEquipment", "definition", "gamme_lifespan", "tr_active", "tr_red_flag", "equip_color", "tr_cdm_props",
    ], []);

    const check_edit_possible = React.useCallback<G.TableProps<Row>["onCellEditingStarted"]>(event => {
        let row = event.data;
        let field = event.colDef.field as keyof FormattedRows;
        // Trying to edit a name field
        if (field.startsWith("tr_name_level_")) event.api.stopEditing(true);
        // Trying to edit a property that can only be edit if the element is an equipment
        else if (!always_allow_edit_fields.includes(field) && !row.isEquipment) {
            event.api.stopEditing(true);
            M.renderAlert({ type: "warning", message: TC.GAMME_TABLE_EDIT_ONLY_EQUIP });
        }
    }, [always_allow_edit_fields]);

    const onValueChange = React.useCallback<G.TableProps<Row>["onValueChange"]>(event => {
        let row = event.data,
            old_value = event.oldValue,
            field = event.colDef.field as keyof FormattedRows;

        // Update the gamme's attribute
        if (always_allow_edit_fields.includes(field)) {
            // Update the 'active' property, and apply it to the children
            if (field === "tr_active") {

                const warning_promise = new Promise<boolean>(resolve => {
                    let new_active = event.newValue || [];
                    if (new_active.length > 0) resolve(true);
                    else M.askConfirm({ title: TC.GAMME_DEACTIVATION_WARNING, text: TC.GAMME_DEACTIVATION_WARNING_TEXT }).then(resolve);
                });

                warning_promise.then(confirmed => {
                    if (confirmed) {
                        loading.setTrue();
                        S.toggleEquipGamme({ gamme: event.node.data._id, active: event.newValue || [] })
                            .then(({ data }) => {
                                // Update the grid via the API
                                let updates: FormattedRows[] = [];
                                for (let [id, active] of Object.entries(data)) {
                                    // Retrieve the row to be updated
                                    let row = grid.current.grid.api.getRowNode(id);
                                    // Push the updated and formatted row to await the transaction
                                    if (row) updates.push(format_row({ ...row.data, active }));
                                }
                                // Update all the rows at once
                                grid.current.grid.api.applyTransaction({ update: updates });
                                // Update the ref that contains the initial rows
                                set_rows(p => p.map(r => data[r._id] ? { ...r, active: data[r._id] } : r))
                            })
                            .catch(M.Alerts.updateError)
                            .catch(loading.setFalse);
                    }
                });
            }
            // Update another property, only on this gamme
            else {
                let sub_field: keyof T.EquipGammeData = null;
                if (field === "tr_red_flag") {
                    old_value = row.red_flag;
                    sub_field = "red_flag";
                }
                else if (field === "tr_cdm_props") {
                    old_value = row.cdm_props;
                    sub_field = "cdm_props";
                }
                else sub_field = field as any;
                loading.setTrue();

                S.edit_gamme_field({ _id: row._id, field: sub_field, old_value, new_value: event.newValue }).then(({ data }) => {
                    let update = { [sub_field]: event.newValue };
                    if (field === "tr_cdm_props") update["tr_cdm_props"] = (event.newValue || []).map(p => lg.getTextObj(form_id, p, p)).join();
                    // Check if the data had changed in the meantime
                    if (data === "changed") M.askConfirm({ title: TC.UPDATE_FORCE_CHANGE, text: TC.UPDATE_VALUE_UNFRESH }).then(confirmed => {
                        if (confirmed) S.edit_gamme_field({ _id: row._id, field: sub_field, old_value: old_value, new_value: event.newValue, force_update: true }).then(({ data }) => {
                            if (data === "changed") M.Alerts.updateError();
                            else {
                                // Update the grid via the API
                                event.node.updateData({ ...row, ...update });
                                // Update the ref that contains the initial rows
                                set_rows(p => p.map(r => r._id === row._id ? { ...r, ...update } : r));
                            }
                            loading.setFalse();
                        }).catch(e => {
                            loading.setFalse();
                            M.Alerts.updateError(e);
                        });
                    });
                    else {
                        loading.setFalse();
                        // Update the grid via the API
                        event.node.updateData({ ...row, ...update });
                        // Update the ref that contains the initial rows
                        set_rows(p => p.map(r => r._id === row._id ? { ...r, ...update } : r));
                    }
                }).catch(e => {
                    loading.setFalse();
                    M.Alerts.updateError(e);
                });
            }
        }
        // Toggle a property for the gamme and it's descendants
        else if (!field.startsWith("tr_name_level_") && row[field] !== event.newValue) {
            // Update the data in the database, then update the state with the new data
            S.toggleEquipGammeProperty({ _id: row._id, field: field as any, activated: event.newValue })
                .then(({ data }) => {
                    // Update the grid via the API
                    let updates: FormattedRows[] = [];
                    for (let id of data) {
                        // Retrieve the row to be updated
                        let row = grid.current.grid.api.getRowNode(id);
                        // Push the updated and formatted row to await the transaction
                        if (row) updates.push(format_row({ ...row.data, [field]: event.newValue }));
                    }
                    // Update all the rows at once
                    grid.current.grid.api.applyTransaction({ update: updates });
                    // Update the ref that contains the initial rows
                    set_rows(p => p.map(r => data.includes(r._id) ? { ...r, [field]: event.newValue } : r));
                })
                .catch(M.Alerts.updateError)
        }
    }, [always_allow_edit_fields, loading, form_id, lg, set_rows, format_row]);
    //#endregion

    const extra_buttons = React.useMemo<G.TableProps<Row>["extra_buttons"]>(() => {
        let buttons = [
            // Create a new gamme
            { icon: "plus", label: TC.TABLE_GAMME_CREATE_NEW, onClick: () => events.create() },
            // Reassign a gamme
            { icon: "exchange-alt", label: TC.TABLE_GAMME_REASSIGN, onClick: () => events.reassign() },
            // Update Gamme inheritance
            { icon: "sort-amount-down-alt", label: TC.GAMME_TABLE_FORCE_INHERITANCE, onClick: events.update_inheritance },
        ];
        return buttons.map(b => ({ ...b, label: lg.getStaticText(b.label), icon: { element: `<i class="me-2 fa fa-${b.icon}"></i>` } }));
    }, [events, lg]);

    return <div className="w-100">
        <C.Spinner error={status === "error" || col_status === "error"}>
            <G.Table<Row>
                sideBar
                ref={grid}
                columns={cols}
                api_provided_rows
                columns_base="all"
                getRowId={r => r.data._id}
                extra_buttons={extra_buttons}
                onValueChange={onValueChange}
                getContextMenuItems={context_menu}
                onCellEditingStarted={check_edit_possible}
                adaptableId={props.origin || TABS.EQUIPMENTS_GAMMES_TABLE}
                loading={status === "load" || col_status === "load" || loading.value}
                // Doesn't work when wrapped in a useCallback
                onReadyGrid={grid => grid.columnApi.applyColumnState({ state: [{ colId: "omniclass", sort: "asc" }] })}
            />
        </C.Spinner>
    </div>;
};

export const GammesContext: React.FC = () => {
    H.useAuth({ tabName: TABS.EQUIPMENTS_GAMMES_TABLE });
    return <Gammes origin={TABS.EQUIPMENTS_GAMMES_TABLE} />;
}