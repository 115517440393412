import React from "react";
import * as H from "../../../hooks";
import * as S from "../../../services";
import { T, TC } from "../../../Constants";
import { Flex, Form, Button } from "../../../Common";
import { StyleModalProps, default as Modal } from "../BlankModal";

export type FilePickerProps = Form.FileUploaderProps & {
    /** Callback for cancellation */
    onQuit?: () => void;
    /** Callback for confirmation */
    onValidate?: (files: T.File[]) => void;
    /** Validate button label */
    validate_text?: string;
    /** Cancel button label */
    cancel_text?: string;
    /** Extra params to change the modal */
    modal?: StyleModalProps;
}

const FilePicker: React.FC<FilePickerProps> = ({ onQuit, onValidate, ...props }) => {
    const checking = H.useBoolean(false);
    const fail_import = H.useBoolean(false);
    const [pictures, set_pictures] = React.useState<T.File[]>([]);

    //#region Save
    const disabled = React.useMemo(() => {
        if (props.required) return pictures.length === 0;
        return false;
    }, [pictures, props.required]);

    const save = React.useCallback(() => {
        checking.setTrue();
        let promises: Promise<boolean>[] = [];

        for (let pic of pictures) {
            promises.push(new Promise(resolve => {
                S.tempFileExists(pic.name)
                    .then(({ data }) => resolve(data))
                    .catch(() => resolve(false))
            }));
        }

        Promise.all(promises)
            .then(results => {
                if (results.some(r => r === false)) fail_import.setTrue();
                else onValidate(pictures);
            })
            .catch(fail_import.setTrue)
            .finally(checking.setFalse);
    }, [checking, fail_import, onValidate, pictures]);
    //#endregion

    //#region Footer
    const footer = React.useMemo(() => <Flex alignItems="end">
        <Button onClick={() => onValidate?.(null)} className="mx-2" variant="danger">
            {props.cancel_text || TC.GLOBAL_CANCEL}
        </Button>
        <Button disabled={disabled} onClick={save} icon={{ spin: checking.value, spinIcon: "spinner", icon: "save" }}>
            {props.validate_text || TC.GLOBAL_CONFIRM}
        </Button>
    </Flex>, [props.cancel_text, props.validate_text, disabled, onValidate, save, checking.value]);
    //#endregion

    return <Modal
        {...props.modal}
        footer={footer}
        onQuit={onQuit}
        size={props.modal?.size || "sm"}
        title={props.modal?.title || TC.GLOBAL_FILE_UPLOAD}
    >
        <Form.FileUploader
            {...props}
            value={pictures}
            label={props.label}
            onChange={set_pictures}
            error={fail_import.value ? { code: TC.FILE_FAILED_UPLOAD } : undefined}
        />
    </Modal>
}

export default FilePicker;