import { validString } from "./string";

/** Force a conversion of a value into a number */
export const getNumber = (value: any, defaultValue?: number) => {
    if (typeof value === "number") return value;
    if (validString(value) && !isNaN(parseFloat(value))) return parseFloat(value);
    if (typeof defaultValue === "number") return defaultValue;
    return NaN;
}

/** Force a number to be a percent, between 0 and 100 */
export const getPct = (value?: string | number, allowFloat = false) => {
    let numVal = getNumber(value);
    if (!isNaN(numVal)) {
        if (!allowFloat && numVal % 1 > 0) numVal = Math.floor(numVal);
        if (numVal > 100) numVal = 100;
        else if (numVal < 0) numVal = 0;
        return numVal;
    }
    else return NaN;
}

/** Calculate the amount in percent of one value out of another */
export const toPercent = (value: number, outOf: number) => {
    value = getNumber(value);
    outOf = getNumber(outOf);
    if (isNaN(value) || isNaN(outOf)) return NaN;
    return (value / outOf) * 100;
}

/** Format a number to be displayed with a unit next to it */
export const numberFormat = (num: number | string, unit?: string) => {
    if (!validString(unit)) unit = "";
    else unit = " " + unit;
    let number = getNumber(num);
    if (isNaN(number)) return "-" + unit;
    return number.toLocaleString() + unit;
}

/** Format a number to be displayed as an amount of money */
export const moneyFormat = (amount: number | string, currency: ("EUR" | "USD") = "EUR") => {
    let formatter = Intl.NumberFormat('fr-FR', { style: "currency", currency });
    let number = getNumber(amount);
    if (isNaN(number)) return formatter.format(number).replace("NaN", "-");
    return formatter.format(number);
};

/** Format a number to be displayed as an amount of money - Backend version, because local is ignored */
export const b_moneyFormat = (amount: number | string, currency: ("EUR" | "USD") = "EUR") => {
    let format = moneyFormat(amount, currency);
    if (format[0] === (currency === "EUR" ? "€" : "$")) return format.slice(1) + (currency === "EUR" ? " €" : " $");
    else return format;
};

/** Generate a random number between two delimiters */
export const randomNumber = (min = 0, max = 100) => {
    let difference = max - min;
    let rand = Math.random();
    rand = Math.floor(rand * difference);
    return rand + min;
}

/** Get a number within a set range */
export const getNumberRange = (number?: number, options?: Partial<Record<"min" | "max" | "default", number> & Record<"random", boolean>>) => {
    let [vNumber, vMin, vMax, vDefault] = [number, options?.min, options?.max, options?.default].map(n => getNumber(n));

    if (options?.random) return randomNumber(vMin, vMax);
    if (isNaN(vNumber) && !isNaN(vDefault)) return vDefault;
    if (!isNaN(vMin) && vNumber < vMin) return vMin;
    if (!isNaN(vMax) && vNumber > vMax) return vMax;
    return vNumber;
}

/** Get the smallest number from a list */
export const getMin = (min: number, ...numbers: number[]) => {
    let arrayMin = Math.min(...numbers);
    if (arrayMin < min) return min;
    return arrayMin;
}

/** Find all the numbers that exists in a given range */
export const getNumberBetween = (limit_one: number, limit_two: number): number[] => {
    if (typeof limit_one !== "number" || typeof limit_two !== "number") return [];
    if (isNaN(limit_one) || isNaN(limit_two)) return [];
    let end = limit_one > limit_two ? limit_one : limit_two,
        start = limit_one > limit_two ? limit_two : limit_one;
    let numbers = [];
    for (let i = start + 1; i < end; i++) numbers.push(i);
    return numbers;
}

/** Test if a value is in a provided range */
export const isInRange = (from: number, to: number, testedValue: number) => {
    let numValue = getNumber(testedValue);
    if (isNaN(numValue)) return false;
    let [numFrom, numTo] = [from, to].map(getNumber);
    if (!isNaN(numFrom) && numFrom > numValue) return false;
    if (!isNaN(numTo) && numTo < numValue) return false;
    return true;
}

/** Get a random element in an array of elements */
export const getRandomElement = <T>(array: T[]): T => {
    if (!Array.isArray(array) || array.length === 0) return null;
    let rand = randomNumber(0, array.length);
    return array[rand];
}

/** Get the number of decimal found after the dot */
export const countDecimals = (value: number) => {
    if (Math.floor(value) === value) return 0;
    return value.toString().split(".")[1].length || 0;
}

/** Round a decimal to a certain amount of number after the decimal point */
export const roundDecimal = (value: number, decimal = 4) => {
    let decimals = countDecimals(value);
    if (decimals <= decimal) return value;
    return parseFloat(value.toFixed(decimal));
}

/** Format a number to keep a certain amount of significant number */
export const significant_numbers = (number: number, amount = 4) => {
    if (typeof number !== "number" || isNaN(number)) return NaN;
    else if (number >= 10 ** (amount - 1)) return Intl.NumberFormat(undefined, { useGrouping: false }).format(Math.round(number));
    else return Intl.NumberFormat(undefined, { maximumSignificantDigits: amount, useGrouping: false }).format(number);
};