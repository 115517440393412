import React from "react";
import Report from "./Report";
import * as M from "../../Modal";
import * as C from "../../../Common";
import { T, TC } from "../../../Constants";
import Quality, { QualityProps } from "./Quality";
import Configuration, { Training } from "./Configuration";
import Validation, { ValidationProps } from "./Validation";

export type PredictionProps = {
    /** Show the report page */
    is_report?: boolean;
    /** The dataset to create a prediction from */
    dataset?: string;
    /** An existing prediction */
    prediction?: string;
    /** Modal Props */
    modal?: Omit<M.StyleModalProps, "title">;
    /** Show the form in a popup */
    popup?: boolean;
    /** The context to search datasets & weather station in */
    context: T.ContextParams;
    /** Callback when the prediction process is canceled or finished */
    on_finish: (datasets?: T.DataSet[]) => void;
}

type Panel = "settings" | "quality" | "final" | "report";

const Prediction: React.FC<PredictionProps> = props => {
    const [training, set_training] = React.useState<Training>(null);
    const [footer, set_footer] = React.useState<React.ReactElement>(null);
    const [panel, set_panel] = React.useState<Panel>(props.is_report ? "report" : "settings");

    const update = React.useMemo(() => ({
        canceled: () => props.on_finish?.(null),
        created: ((datasets) => props.on_finish?.(datasets)) as ValidationProps["validated"],
        trained: (training: Training) => {
            set_training(training);
            set_panel("quality");
        },
        validated: (results => {
            set_training(results);
            set_panel("final");
        }) as QualityProps["on_validate"],
    }), [props]);

    const panel_content = React.useMemo(() => {
        if (panel === "settings") return {
            title: TC.PRED_CONFIG_TITLE,
            content: <Configuration
                context={props.context}
                dataset={props.dataset}
                set_footer={set_footer}
                prediction={props.prediction}
                init_success={update.trained}
            />,
        }
        else if (panel === "quality") return {
            title: TC.PRED_QUALITY_TITLE,
            content: <Quality
                training={training}
                set_footer={set_footer}
                update_params={set_training}
                on_validate={update.validated}
            />,
        }
        else if (panel === "final") return {
            title: TC.PRED_VALIDATION_TITLE,
            content: <Validation
                training={training}
                set_footer={set_footer}
                validated={update.created}
                og_prediction={props.prediction}
                prediction={training.prediction._id}
                dataset={training.prediction.dataset}
            />
        }
        else if (panel === "report") return { title: TC.PRED_REPORT, content: <Report prediction={props.prediction} /> };
    }, [panel, training, update, props.context, props.prediction, props.dataset]);

    return React.createElement(
        props.popup ? M.BlankModal : React.Fragment,
        props.popup ? {
            ...props.modal,
            footer: footer,
            onQuit: update.canceled,
            title: panel_content.title,
            size: props.modal?.size || "lg",
            maxBodyHeight: props.modal?.maxBodyHeight || "75vh",
        } as M.BlankModalProps : null,
        <>
            {!props.popup && <C.Title className="mb-2" level={1} text={panel_content.title} />}
            {panel_content.content}
            {!props.popup && footer}
        </>
    );
}

export default Prediction;