import React from "react";
import * as G from "../../Gestion";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import * as S from "../../../services";
import { FP, T, TC } from "../../../Constants";
import * as AR from "@adaptabletools/adaptable-react-aggrid";

//#region Types
type Data = ReturnType<T.API.Utils.Missions.MissionEquipmentsNRJ>;
type Layout = Record<"style", AR.FormatColumn> & Record<"layout", AR.Layout>;

export type EquipmentsProps = {
    /** Do not allow any data edit */
    read_only: boolean;
    /** The mission's asset _id */
    asset_id: string;
    /** Extra about the mission */
    extra: T.Mission["extra"]["nrj"];
    /** Update the mission's extra */
    set_extra: (params: Parameters<T.API.Utils.Missions.SaveMissionExtra>[0]) => void;
}
//#endregion

const Equipments: React.FC<EquipmentsProps> = ({ set_extra, ...props }) => {
    const lg = H.useLanguage();
    const table = React.useRef<G.EquipmentTableRef>(null);
    const [active_gamme, set_active_gamme] = React.useState<string>("");
    const [data, set_data, status] = H.useAsyncState<Data>({ gammes: [] });
    const table_layout = React.useRef<Layout>({ layout: null, style: null });

    //#region Manage Gammes
    const gamme_calculated = React.useMemo(() => {
        return data.gammes.map(gamme => {
            let nb_missing_props = 0;
            let gammes_list = [gamme._id].concat(gamme.sub_categories);
            for (let equip of gamme.equipments) {
                for (let prop of gamme.props) {
                    if (equip[prop] === undefined || equip[prop] === null || equip[prop] === "") nb_missing_props++;
                }
            }
            return { ...gamme, nb_missing_props, gammes_list };
        });
    }, [data.gammes]);

    const activate_gamme = React.useCallback((gamme: "data_center" | typeof data["gammes"][number]) => {
        if (gamme !== "data_center") {
            set_active_gamme(gamme._id);
            let props = [] as (keyof G.EquipmentRow)[];
            // Some props needs to be change, because they don't appear under the same name in the table
            for (let prop of gamme.props) {
                if (prop === "brand") props.push("brand_name");
                else if (prop === "model") props.push("model_name");
                else props.push(prop);
            }
            // Update the table, leave it some time to update
            if (table.current?.current) setTimeout(() => {
                let grid = table.current?.current?.grid;
                let adaptable = table.current?.current?.adaptable;
                // Expand all the rows
                grid?.api?.expandAll?.();
                // Remove previous layout
                if (table_layout.current.layout) adaptable?.layoutApi?.deleteLayout?.(table_layout.current.layout);
                if (table_layout.current.style) adaptable?.formatColumnApi?.deleteFormatColumn?.(table_layout.current.style);
                // Adapt the layout to the props under review
                if (props.length > 0) {
                    let style = adaptable?.formatColumnApi?.addFormatColumn?.({
                        Source: "User",
                        IncludeGroupedRows: true,
                        Scope: { ColumnIds: props },
                        Rule: { Predicates: [{ Inputs: [], PredicateId: "Blanks" }] },
                        Style: { ClassName: "", FontStyle: "Normal", BackColor: "#FFA500", FontWeight: "Normal", ForeColor: "#FFFFFF", BorderColor: "#FF0000" },
                    });

                    let baseColumns = adaptable?.columnApi?.getColumns?.()?.map?.(c => c.field)?.filter?.(f => !props.includes(f as any));
                    let endCols = baseColumns.splice(3, baseColumns.length - 3, ...props);
                    baseColumns = baseColumns.concat(endCols);
                    let layout = adaptable.layoutApi.createAndSetLayout({ Columns: baseColumns, Name: "nrj_report" });

                    table_layout.current.style = style;
                    if (layout) table_layout.current.layout = layout;
                }
                else {
                    table_layout.current.style = null;
                    table_layout.current.layout = null;
                }
            }, 100);
        }
        else {
            set_active_gamme(gamme);
            table_layout.current.style = null;
            table_layout.current.layout = null;
        }
    }, []);

    const current_gamme = React.useMemo(() => {
        let gamme = gamme_calculated.filter(g => g._id === active_gamme)[0];
        return gamme || { _id: "", equipments: [], name: "", omniclass: "", props: [], nb_missing_props: 0, gammes_list: [] };
    }, [gamme_calculated, active_gamme]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.missionEquipmentsNRJ().then(({ data }) => {
            if (isSubscribed) {
                set_data(data, "done");
                if (data.gammes[0]) activate_gamme(data.gammes[0]);
            }
        }).catch(() => isSubscribed && set_data({ gammes: [] }, "error"));
        return () => {
            isSubscribed = false;
            set_data({ gammes: [] }, "load");
        }
    }, [set_data, activate_gamme]);
    //#endregion

    const onEquipChanges = React.useMemo<G.EquipmentTableProps["onChange"]>(() => {
        let on_delete: G.EquipmentTableProps["onChange"]["delete"] = ids => {
            // Remove the deleted equipments from the lists
            set_data(p => ({
                ...p,
                gammes: p.gammes.map(g => {
                    if (g._id !== active_gamme) return g;
                    return { ...g, equipments: g.equipments.filter(e => !ids.includes(e._id)) }
                })
            }));
        };

        let edit: G.EquipmentTableProps["onChange"]["edit"] = rows => {
            // Replace each rows by it's updated values, and check in case the category has changed
            set_data(p => {
                // Create a copy of the data
                let new_data = { ...p, gammes: [...p.gammes] } as typeof p;
                // Set aside the currently active gamme
                let selected_gamme = p.gammes.filter(g => g._id === active_gamme)[0];
                // Safe guard, but shouldn't be a problem
                if (selected_gamme) {
                    // Loop through each new row found
                    for (let row of rows) {
                        // Find the original row in the local state
                        let og_row = selected_gamme.equipments.filter(e => e._id === row._id)[0];
                        // The equipment was not in our original list
                        if (!og_row) new_data.gammes = new_data.gammes.map(g => ({
                            ...g,
                            equipments: g._id === row.category || g.sub_categories.includes(row.category) ? g.equipments.concat(row) : g.equipments
                        }));
                        // The category has changed
                        else if (row.category !== og_row.category) new_data.gammes = new_data.gammes.map(g => {
                            // Remove the row from the current category
                            if (g._id === active_gamme) return { ...g, equipments: g.equipments.filter(e => e._id !== row._id) };
                            // Add the row to the category, if it fits the criteria
                            else if (g._id === row.category || g.sub_categories.includes(row.category)) return { ...g, equipments: g.equipments.concat(row) };
                            // Leave the category untouched
                            else return g;
                        });
                        // The rest has changed
                        else new_data.gammes = new_data.gammes.map(g => {
                            if (g._id !== active_gamme) return g;
                            else return { ...g, equipments: g.equipments.map(e => e._id !== row._id ? e : row) };
                        });
                    }
                }
                return new_data;
            });
        };

        return { edit, add: edit, delete: on_delete };
    }, [set_data, active_gamme]);

    const table_props = React.useMemo(() => ({
        table: { noBottomPadding: true },
        context: { roots: props.asset_id },
    }), [props.asset_id]);

    const update_extra = React.useCallback((prop: keyof T.Mission["extra"]["nrj"], value: any) => {
        let updates: Parameters<EquipmentsProps["set_extra"]>[0] = [{ type: "nrj", prop, value }];
        // Reset description when no data-centers
        if (prop === "has_data_center" && !value) updates.push({ type: "nrj", prop: "data_center_description", value: "" });
        set_extra(updates);
    }, [set_extra]);

    return <BS.Row className="g-2 h-100">
        <BS.Col md={3}>
            <BS.Card className="h-100">
                <BS.Card.Header>
                    <C.Title level={5} text={FP.EQUIPMENT_GAMME} />
                </BS.Card.Header>
                <BS.Card.Body className="flew-grow-1">
                    <C.Spinner loader_className="h-100" status={status}>
                        <BS.ListGroup>
                            {gamme_calculated.map(g => <BS.ListGroup.Item
                                key={g._id}
                                className="pointer"
                                active={active_gamme === g._id}
                                onClick={() => activate_gamme(g)}
                            >
                                <C.Flex alignItems="center" justifyContent="between">
                                    <div>
                                        <div>{lg.getTextObj(g._id, "name", g.name)}</div>
                                        <div className="text-muted fs-85">({g.omniclass})</div>
                                    </div>
                                    <div>
                                        <C.SoftBadge
                                            pill
                                            bg={g.nb_missing_props === 0 ? "success" : "warning"}
                                            children={g.nb_missing_props === 0 ? <i className="fa fa-check"></i> : g.nb_missing_props}
                                        />
                                    </div>
                                </C.Flex>
                            </BS.ListGroup.Item>)}

                            <BS.ListGroup.Item className="pointer" active={active_gamme === "data_center"} onClick={() => activate_gamme("data_center")}>
                                {lg.getStaticText(TC.NRJ_MISSION_DATA_CENTER_TITLE)}
                            </BS.ListGroup.Item>

                        </BS.ListGroup>
                    </C.Spinner>
                </BS.Card.Body>
            </BS.Card>
        </BS.Col>
        <BS.Col className="d-flex">
            {active_gamme === "data_center"
                ? <div className="flex-grow-1 ms-2">
                    <C.Title level={4} text={TC.NRJ_MISSION_DATA_CENTER_TITLE} />

                    <C.Form.RadioBool
                        labelPosition="left"
                        value={props?.extra?.has_data_center}
                        label={TC.NRJ_MISSION_DATA_CENTER_PRESENCE}
                        onChange={value => update_extra("has_data_center", value)}
                    />

                    <C.Form.TextField
                        textArea
                        labelPosition="left"
                        label={TC.NRJ_MISSION_DATA_CENTER_DESC}
                        disabled={!props.extra?.has_data_center}
                        value={props?.extra?.data_center_description}
                        onChange={value => update_extra("data_center_description", value)}
                    />
                </div>
                : <G.Equipment
                    ref={table}
                    hide_state_saver
                    {...table_props}
                    add_only_same_category
                    show_properties={false}
                    no_sub_columns_for_loaded
                    onChange={onEquipChanges}
                    read_only={props.read_only}
                    rows={current_gamme.equipments}
                    default_category={current_gamme._id}
                    restricted_categories={current_gamme.gammes_list}
                />
            }
        </BS.Col>
    </BS.Row>;
}

export default Equipments;