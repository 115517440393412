import { TB } from "../../Constants";
import BlankModal from "./BlankModal";
import { QrReader } from "react-qr-reader";
import React, { FC, useCallback } from "react";
import renderInContainer from "./getContainer";

type QRScanProps = {
    title?: string;
    onQuit?: () => void;
    onResult?: (value: string) => void;
    size?: "xs" | "sm" | "md" | "lg" | "xl";
    constraints?: {
        ideal?: "user" | "environment" | "left" | "right";
        exact?: "user" | "environment" | "left" | "right";
    }
}

const QRScan: FC<QRScanProps> = ({ title, size, onQuit, onResult, ...props }) => {
    const checkResults = useCallback((result, error) => {
        if (!error) {
            let text = result?.text;
            onResult?.(text);
        }
    }, [onResult]);

    return <BlankModal {...{ title, size, onQuit }} >
        {/* @ts-ignore */}
        <QrReader
            videoId="qr_scan"
            onResult={checkResults}
            ViewFinder={ScanOverlay}
            containerStyle={{ padding: "0px" }}
            constraints={{ facingMode: { ideal: "environment" } }}
        />
    </BlankModal>
}

export default QRScan;

const ScanOverlay = () => <svg viewBox="0 0 100 100" className="position-absolute top-0 start-0 " style={{ zIndex: 1, boxSizing: "border-box", border: "50px solid transparent", width: "100%", height: "100%" }}>
    <path fill="none" d="M13,0 L0,0 L0,13" stroke="rgba(255, 0, 0, 0.9)" strokeWidth="3"></path>
    <path fill="none" d="M0,87 L0,100 L13,100" stroke="rgba(255, 0, 0, 0.9)" strokeWidth="3"></path>
    <path fill="none" d="M87,100 L100,100 L100,87" stroke="rgba(255, 0, 0, 0.9)" strokeWidth="3"></path>
    <path fill="none" d="M100,13 L100,0 87,0" stroke="rgba(255, 0, 0, 0.9)" strokeWidth="3"></path>
</svg>;

type askScanProps = Omit<QRScanProps, "onQuit" | "onResult">;

export const askScan = (params?: askScanProps) => new Promise<string | null>(resolve => {
    let [render, dismount] = renderInContainer();
    if (render && dismount) {
        if (!TB.validObject(params)) params = {};
        let onQuit = () => dismount(() => resolve(null));
        let onResult = (value: string) => dismount(() => resolve(value));
        render(<QRScan {...params} onQuit={onQuit} onResult={onResult} />);
    }
});