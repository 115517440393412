import ReactDom from "react-dom";
import { useSelector } from "react-redux";
import DatePicker from "react-date-picker";
import { useNavigate } from "react-router-dom";
import * as Text from "../../../Constants/text";
import RenovationComponent from "./RenovationComponent";
import { GAMMES, TB, FP, EC, LT } from "../../../Constants";
import { renderAlert, Loader, ErrorModal, renderFormModal } from "../../Modal";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import AttachMultipleObjParents from "../../../helpers/AttachMultipleObjParents";
import { deleteSubmissionsAndRelatives, findBuildingRocketStateId, getManySubmissionsFromFilter, removeSubmission, updateSubmission } from "../../../services/user.service";
import { Form } from "../../../Common";

const BuildingPanel = ({ clientId, siteId, buildings, childrenLinks, addEntity, forms, linkTypes, equipGammes, onUpdateDescendants, onUpdateTree, addNote, ...props }) => {
    const [gammes, setGammes] = useState([]);
    const [{ user }, setUser] = useState({});
    const [activeBuilding, setActiveBuilding] = useState();

    const modalRef = useRef();
    const navigate = useNavigate();
    const auth = useSelector(({ auth }) => auth);
    const language = useSelector(({ language }) => language);
    const prevSiteRef = useRef(siteId);

    //#region User
    useEffect(() => auth.then(setUser), [auth]);
    //#endregion

    //#region Forms
    const equipFormId = useMemo(() => forms?.filter?.(({ path }) => path === FP.EQUIPEMENT_FORM)?.[0]?._id, [forms]);
    //#endregion

    //#region LinkTypes
    const ownTypeLink = useMemo(() => linkTypes?.filter?.(({ data }) => data.type === LT.LINK_TYPE_OWN)?.[0]?._id, [linkTypes]);
    //#endregion

    //#region Equip Gammes
    const roofId = useMemo(() => equipGammes?.filter?.(({ data }) => data.omniclass === GAMMES.ROOF)?.[0]?._id, [equipGammes]);
    const wallId = useMemo(() => equipGammes?.filter?.(({ data }) => data.omniclass === GAMMES.WALLS)?.[0]?._id, [equipGammes]);
    const windowId = useMemo(() => equipGammes?.filter?.(({ data }) => data.omniclass === GAMMES.WINDOWS)?.[0]?._id, [equipGammes]);
    //#endregion

    //#region Utilities
    const showErrorModal = useCallback(errorCode =>
        new Promise(resolve => ReactDom.render(<ErrorModal onQuit={resolve} language={language} errorCode={errorCode} />, modalRef.current))
            .then(() => ReactDom.unmountComponentAtNode(modalRef.current))
        , [language]);
    //#endregion

    //#region Buildings
    const buildingList = useMemo(() => Array.isArray(buildings) ? buildings : [], [buildings]);
    const currentBuildingChildren = useMemo(() => buildingList.filter(({ _id }) => _id === activeBuilding)?.[0]?.children ?? [], [buildingList, activeBuilding]);
    const buildEquipments = useMemo(() => currentBuildingChildren.filter(({ form }) => form === equipFormId), [currentBuildingChildren, equipFormId]);

    const gammeObj = useMemo(() => {
        let gammeArray = Array.isArray(gammes) ? gammes : [];
        return Object.fromEntries(gammeArray.map(({ _id, data }) => [_id, data?.name]));
    }, [gammes]);

    useEffect(() => {
        let isSubscribed = true;
        let equipCategory = buildEquipments.map(({ data }) => data?.category).filter(TB.mongoIdValidator);

        const getGammes = async () => {
            let reply = await getManySubmissionsFromFilter({ _id: equipCategory });
            return Array.isArray(reply?.data) ? reply?.data : [];
        }

        if (equipCategory.length > 0) getGammes().then(gammes => isSubscribed ? setGammes(gammes) : undefined);
        return () => isSubscribed = false;
    }, [buildEquipments]);
    //#endregion

    //#region Nav Bar
    useEffect(() => TB.mongoIdValidator(activeBuilding) && prevSiteRef.current === siteId ? undefined : setActiveBuilding(buildingList[0]?._id), [activeBuilding, buildingList, siteId]);
    const addBuilding = useCallback(() => navigate(`/editor/building?root=${siteId}`), [navigate, siteId]);
    useEffect(() => prevSiteRef.current = siteId, [siteId]);

    const defaultNotesTitlesBuilding = useMemo(() => [
        "Dernière rénovation import (+ description)",
        "Évolution de la superficie depuis 2010",
        "Énergies Renouvelable",
        "Présence de partie privatives non louées",
        "Propriété",
    ], []);

    const navBar = useMemo(() => <div className="h-100 w-25 p-2 position-relative">
        <ul className="h-100 overflow-auto list-group p-2 border" style={{ maxHeight: "50rem" }}>
            <h5 className="mb-3">{Text.GLOBAL_LABEL_BUILD[language]}</h5>
            {buildingList.map(({ _id, data, note }) => {
                let isActive = _id === activeBuilding;
                return <li key={_id} className={`d-flex list-group-item ${isActive ? "active" : ""}`}>
                    <button onClick={() => setActiveBuilding(_id)} className={`d-flex align-items-center flex-grow-1 btn-none text-start w-100 ${isActive ? "text-white" : ""}`}>
                        <span className="flex-grow-1">{data?.name}</span>
                        <span className="mr-2">({note}%)</span>
                    </button>
                    <button className={`btn btn-${isActive ? "light" : "primary"}`} onClick={() => addNote(_id, defaultNotesTitlesBuilding)}><i className="fa fa-comment-alt"></i></button>
                </li>
            })}
            <div className="flex-grow-1"></div>
            <div className="position-sticky bottom-0 bg-white mt-2">
                <button onClick={addBuilding} className="btn btn-primary">
                    <i className="fa fa-plus pr-2"></i>
                    {Text.GLOBAL_LABEL_BUILD[language]}
                </button>
            </div>
        </ul>
    </div>, [buildingList, activeBuilding, language, defaultNotesTitlesBuilding, addBuilding, addNote]);
    //#endregion

    //#region Building Edit
    const onChangeBuildData = useCallback(async (key, value) => {
        let update = {};
        update["data." + key] = value;
        let reply = await updateSubmission(activeBuilding, update);
        if (TB.mongoIdValidator(reply.data?._id)) onUpdateDescendants?.(prev => prev.map(node => {
            if (node._id !== activeBuilding) return node;
            node.data[key] = value;
            return { ...node, data: { ...node.data } };
        }));
        else showErrorModal(EC.CODE_DB_UPDATE_FAIL);
    }, [activeBuilding, showErrorModal, onUpdateDescendants]);

    const currentBuildingData = useMemo(() => buildings?.filter?.(({ _id }) => _id === activeBuilding)?.[0]?.data ?? {}, [activeBuilding, buildings]);

    //#region RockEstate API
    const openRockEstateTab = useCallback(() => {
        findBuildingRocketStateId(activeBuilding)
            .then(({ data }) => {
                let errorMessage = [data?.error, data?.error?.message].filter(TB.validString)[0] ?? "";
                if (data?.hasFailed) renderAlert({ type: "error", message: "FAILED! " + errorMessage });
                else window.open(data, "_blank");
            })
    }, [activeBuilding]);

    const rockEstateAPIButton = useMemo(() => <button className="btn btn-primary stop-hiding" onClick={openRockEstateTab}>
        <i className="fa fa-city mr-2"></i>
        Rock.Estate 3D Model view
    </button>, [openRockEstateTab]);
    //#endregion

    const buildAddressForm = useMemo(() => <div className="mt-2 mb-3">
        <p className="h5 mb-1">Localisation </p>
        <div className="row g-3">
            <div className="col-md-3">
                <Form.TextField
                    label="N°"
                    placeholder="Numéro de rue..."
                    value={currentBuildingData?.number}
                    onChange={value => onChangeBuildData("number", value)}
                />
            </div>
            <div className="col-md-9">
                <Form.TextField
                    label="Rue"
                    placeholder="Nom de la rue..."
                    value={currentBuildingData?.street}
                    onChange={value => onChangeBuildData("street", value)}
                />
            </div>
            <div className="col-md-4">
                <Form.TextField
                    label="Code Postal"
                    placeholder="Code postal..."
                    value={currentBuildingData?.zipcode}
                    onChange={value => onChangeBuildData("zipcode", value)}
                />
            </div>
            <div className="col-md-4">
                <Form.TextField
                    label="Ville"
                    placeholder="Nom de la ville..."
                    value={currentBuildingData?.town}
                    onChange={value => onChangeBuildData("town", value)}
                />
            </div>
            <div className="col-md-12 position-relative d-flex align-items-center justify-content-center">
                {rockEstateAPIButton}
            </div>
        </div>
    </div>, [currentBuildingData, rockEstateAPIButton, onChangeBuildData]);

    const constructionForm = useMemo(() => <div>
        <div className="mt-2 mb-3">
            <p className="h5 mb-1">Construction & Rénovation</p>
            <div className="row g-3">
                <div className="col-md-4">
                    <Form.NumField
                        label="Date de construction"
                        value={currentBuildingData.ENT_TECH_BUILD_YEAR}
                        onChange={date => onChangeBuildData("ENT_TECH_BUILD_YEAR", parseInt(date))}
                    />
                </div>
                <div className="col-md-4">
                    <label className="form-label">Date de référence</label>
                    <DatePicker
                        className="w-100"
                        minDetail="decade"
                        maxDetail="decade"
                        onChange={date => onChangeBuildData("ENT_REF_CONSUMPTION_ACTUAL", new Date(date).toISOString())}
                        value={isNaN(Date.parse(currentBuildingData?.ENT_REF_CONSUMPTION_ACTUAL)) ? undefined : new Date(currentBuildingData.ENT_REF_CONSUMPTION_ACTUAL)}
                    />
                </div>
            </div>
            <div className="row g-3">
                <div className="col-md-12">
                    <RenovationComponent value={currentBuildingData.renovations} setter={val => onChangeBuildData("renovations", val)} />
                </div>
            </div>
        </div>
    </div>, [currentBuildingData, onChangeBuildData]);
    //#endregion

    //#region Equipments
    const onAddEquip = useCallback((category, submissionId) => {
        renderFormModal({
            submissionId,
            path: FP.EQUIPEMENT_FORM,
            modalProps: { size: "lg" },
            forcedSubmission: [{ prop: "category", value: category }],
        }).then(async equip => {
            if (TB.mongoIdValidator(equip?._id)) {
                // Update Existing equipment
                if (TB.mongoIdValidator(submissionId)) onUpdateTree?.(({ descendance, ...r }) => ({
                    ...r,
                    descendance: descendance.map(node => node?._id !== equip?._id ? node : equip),
                }))
                // Create New Equipment
                else {
                    let id = equip._id;
                    let link = { input: activeBuilding, output: id, type: ownTypeLink };
                    let reply = await AttachMultipleObjParents([link], user);
                    if (reply === true) onUpdateTree?.(({ descendance, links }) => ({
                        descendance: descendance.concat(equip),
                        links: links.concat(link)
                    }));
                    else {
                        removeSubmission(id);
                        showErrorModal(EC.CODE_DB_UPDATE_FAIL);
                    }
                }
            }
        });
    }, [activeBuilding, ownTypeLink, user, showErrorModal, onUpdateTree]);

    const onRemoveEquip = useCallback(async id => {
        if (TB.mongoIdValidator(id)) {
            ReactDom.render(<Loader />, modalRef.current);
            let reply = await deleteSubmissionsAndRelatives([id]);
            ReactDom.unmountComponentAtNode(modalRef.current);
            if (reply?.data?.ok === 1) onUpdateTree?.(({ descendance, links }) => ({
                descendance: descendance.filter(({ _id }) => _id !== id),
                links: links.filter(({ input, output }) => ![input, output].includes(id)),
            }));
            else showErrorModal(EC.CODE_DB_UPDATE_FAIL);
        }
    }, [onUpdateTree, showErrorModal]);

    const addEquipButtons = useMemo(() => [
        { label: "Toiture", funct: () => onAddEquip(roofId) },
        { label: "Vitrage", funct: () => onAddEquip(windowId) },
        { label: "Murs", funct: () => onAddEquip(wallId) },
        { label: "Autres", funct: () => onAddEquip() },
        { label: Text.GLOBAL_LABEL_ENTITY[language], funct: () => addEntity?.(siteId, activeBuilding) }
    ], [roofId, wallId, windowId, activeBuilding, language, onAddEquip, siteId, addEntity]);

    const emptyEquipFrame = useMemo(() => <div className="position-absolute top-50 start-50 translate-middle">
        <div className="btn-group">
            {addEquipButtons.map(({ label, funct }, i) => <button key={i} className="btn btn-outline-secondary" onClick={funct}>
                <i className="fa fa-plus mr-2"></i>{label}
            </button>)}
        </div>
    </div>, [addEquipButtons]);

    const equipListingHeader = useMemo(() => <thead>
        <tr>
            <th className="border-end">#</th>
            <th>{Text.GLOBAL_NAME[language]}</th>
            <th>Omniclass</th>
            <th>Valeur U</th>
            <th>Inclinaison</th>
            <th>Surface</th>
            <th></th>
        </tr>
    </thead>, [language]);

    const equipListing = useMemo(() => <div>
        <table className="table table-sm text-center">
            {equipListingHeader}
            <tbody className="overflow-scroll" style={{ maxHeight: "30vh" }}>
                {buildEquipments.map(({ _id, data }, i) => <tr key={_id}>
                    <th className="border-end align-middle">{i + 1}</th>
                    <td className="align-middle">{data?.name}</td>
                    <td className="align-middle">{gammeObj[data?.category] ?? ""}</td>
                    <td className="align-middle">{data?.uValue}</td>
                    <td className="align-middle">{data?.incline}</td>
                    <td className="align-middle">{data?.surface}</td>
                    <td className="align-middle">
                        <button onClick={() => onAddEquip(undefined, _id)} className="btn btn-primary mr-1">
                            <i className="fa fa-pencil-alt"></i>
                        </button>
                        <button onClick={() => onRemoveEquip(_id)} className="btn btn-danger ml-1">
                            <i className="fa fa-times"></i>
                        </button>
                    </td>
                </tr>)}
            </tbody>
        </table>
        <div>
            {addEquipButtons.map(({ label, funct }) => <button onClick={funct} className="btn btn-primary m-1">
                <i className="fa fa-plus mr-2"></i> {label}
            </button>)}
        </div>
    </div>, [buildEquipments, equipListingHeader, gammeObj, addEquipButtons, onAddEquip, onRemoveEquip]);

    const equipPanel = useMemo(() => <div className="position-relative" style={{ minHeight: "10rem" }}>
        <label>{Text.GLOBAL_LABEL_EQUIPMENT[language]}</label>
        {Array.isArray(buildEquipments) && buildEquipments.length > 0 ? equipListing : emptyEquipFrame}
    </div>, [emptyEquipFrame, equipListing, buildEquipments, language]);
    //#endregion

    //#region No Building Panel
    const noBuilding = useMemo(() => <div className="position-absolute top-50 start-50 translate-middle">
        <div className="border rounded bg-light p-3">
            Aucun bâtiment n'a été selectionné
        </div>
    </div>, []);
    //#endregion

    return <div className="h-100 d-flex">
        {navBar}
        <div className="h-100 flex-grow-1 position-relative">
            {!TB.mongoIdValidator(activeBuilding) ? noBuilding : <div className="p-4 overflow-auto">
                {buildAddressForm}
                {constructionForm}
                {equipPanel}
            </div>}
        </div>
        <div ref={modalRef}></div>
    </div>
}

export default BuildingPanel;