import React from "react";
import * as M from "../Modal";
import * as H from "../../hooks";
import RoleForm from "./RoleForm";
import * as C from "../../Common";
import * as S from "../../services";
import * as BS from "react-bootstrap";
import { T, TC } from "../../Constants";

type Selected = T.API.Access.FlatAccessRole;
const TEXT_CODES = [TC.GLOBAL_EDIT, TC.ACCESS_USER_ROLES, TC.ACCESS_USER_NEW_ROLE];
const getInitSelected = (): Selected => ({ packages: [], pages: [], panels: [], rights: [] });

type AccessRoleProps = {
    /** Only show the "special" roles */
    is_special_roles?: boolean;
}

const AccessRoles: React.FC<AccessRoleProps> = props => {
    const lg = H.useLanguage(TEXT_CODES);
    const [{ userId, isAdmin }] = H.useAuth();
    const [roles, setRoles, roleStatus] = H.useAsyncState<T.AccessRole[]>([]);
    const [packages, setPackages, pck_status] = H.useAsyncState<T.PCK.Package[]>([]);
    const [userAccesses, setUserAccesses, access_status] = H.useAsyncState(getInitSelected());
    const [addRole, setAddRole] = React.useState<{ add: boolean, role?: T.AccessRole }>({ add: false });

    const status = React.useMemo(() => [pck_status, roleStatus, access_status], [pck_status, roleStatus, access_status]);

    React.useEffect(() => {
        let isSubscribed = true;
        let getter = props.is_special_roles ? S.getSpecialRoles : S.getUsersRoles;

        getter()
            .then(({ data }) => isSubscribed && setRoles(data, "done"))
            .catch(() => isSubscribed && setRoles([], "error"));
        return () => { isSubscribed = false };
    }, [props.is_special_roles, setRoles]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getAllUserAccesses()
            .then(({ data }) => isSubscribed && setUserAccesses(data, "done"))
            .catch(() => isSubscribed && setUserAccesses(getInitSelected(), "error"));
        return () => { isSubscribed = false };
    }, [setUserAccesses]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getPackages()
            .then(({ data }) => isSubscribed && setPackages(data, "done"))
            .catch(() => isSubscribed && setPackages([], "error"));
        return () => { isSubscribed = false };
    }, [setPackages]);

    const onNewRole = React.useCallback((role?: T.AccessRole) => {
        if (role) setRoles(p => {
            let existingIds = p.map(r => r._id);
            if (existingIds.includes(role._id)) return p.map(r => r._id === role._id ? role : r);
            else return p.concat(role);
        });
        setAddRole({ add: false })
    }, [setRoles]);

    const delete_role = React.useCallback((role: T.AccessRole) => {
        M.askConfirm().then(confirmed => {
            if (confirmed) S.removeRoles(role._id)
                .then(() => setRoles(p => p.filter(r => r._id !== role._id)))
                .catch(M.Alerts.deleteError)
        });
    }, [setRoles]);

    return <>
        {addRole.add
            ? <RoleForm
                onSave={onNewRole}
                role={addRole.role}
                packages={packages}
                userAccesses={userAccesses}
                show_special={props.is_special_roles}
            />
            : <C.Spinner status={status}>
                <C.Flex className="mb-3" alignItems="center" justifyContent="between">
                    <h5>{lg.getStaticText(TC.ACCESS_USER_ROLES)}</h5>
                    <BS.Button onClick={() => setAddRole({ add: true })}>
                        <i className="fa fa-plus me-2"></i>
                        {lg.getStaticText(TC.ACCESS_USER_NEW_ROLE)}
                    </BS.Button>
                </C.Flex>

                {roles.length === 0
                    ? <C.ErrorBanner type="info" message={TC.ACCESS_ROLES_NO_ROLES} />
                    : <BS.Accordion>
                        {roles.map(r => <BS.Accordion.Item key={r._id} eventKey={r._id}>
                            <BS.Accordion.Header children={r.name} />
                            <BS.Accordion.Body>
                                <RoleForm
                                    readOnly role={r}
                                    packages={packages}
                                    userAccesses={userAccesses}
                                    show_special={props.is_special_roles}
                                />
                                {(r.owner === userId || isAdmin) && <C.Flex justifyContent="end">
                                    <C.Button className="me-2" onClick={() => setAddRole({ add: true, role: r })} icon="pencil-alt" text={TC.GLOBAL_EDIT} />
                                    <C.Button variant="danger" onClick={() => delete_role(r)} icon="times" text={TC.GLOBAL_DELETE} />
                                </C.Flex>}
                            </BS.Accordion.Body>
                        </BS.Accordion.Item>)}
                    </BS.Accordion>}
            </C.Spinner>}
    </>
}

export default AccessRoles;