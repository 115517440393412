import * as F from "../Form";
import * as C from "../../Common";
import * as N from "../Navigator";
import * as S from "../../services";
import { Alerts } from "./MiscModal";
import * as ET from "../Events_Tickets_QR";
import { T, TB, TC } from "../../Constants";
import renderInContainer from "./getContainer";
import { EventForm, EventFormProps } from "../Calendar";
import { PortfolioForm, PortfolioFormProps } from "../Form";
import { default as RegDocForm, RegDocProps } from "../RegDoc/RegDocForm";
import { renderBlankModal, default as BlankModal, BlankModalFnProps } from "./BlankModal";

//#region Types
type EventFormResults = {
    /** Actions to reassigned to the pool, if event deleted*/
    actions: EventFormProps["actions"];
    /** The deleted or created event */
    event: EventFormProps["event"];
    /** The action done to the event */
    type: "add" | "remove";
}

type RenderDataFormModal = (params: Omit<F.DataSets.DataFormProps, "popup" | "onSave"> & {
    /** Will load the dataset by itself, if the whole dataset is not provided in the params */
    dataset_id?: string
}) => Promise<Parameters<F.DataSets.DataFormProps["onSave"]>[0]>;

type RenderMissionForm = (params: Omit<F.Missions.MissionFormProps, "onSave" | "onQuit" | "popup">)
    => Promise<Exclude<Parameters<F.Missions.MissionFormProps["onSave"]>[0], "error">>;

type RenderValueIgnorer = (params?: Omit<C.Import.ValueIgnorerProps, "onSave" | "onQuit" | "popup">)
    => Promise<Parameters<C.Import.ValueIgnorerProps["onSave"]>[0]>;

type RenderEmplacementMapper = (params?: Omit<C.Import.EmplacementMapperProps, "onSave" | "onQuit" | "popup">)
    => Promise<Parameters<C.Import.EmplacementMapperProps["onSave"]>[0]>;

type RenderValueMapper = (params?: Omit<C.Import.ValueMapperProps, "onSave" | "onQuit" | "popup">)
    => Promise<Parameters<C.Import.ValueMapperProps["onSave"]>[0]>;

type RenderTagMapper = (params?: Omit<F.DataSets.TagMapProps, "on_validate" | "popup" | "quit">)
    => Promise<Parameters<F.DataSets.TagMapProps["on_validate"]>[0]>;

type RenderAlertBubbleForm = (params?: Omit<F.InfosBubbles.FormProps, "popup" | "on_save">)
    => Promise<Parameters<F.InfosBubbles.FormProps["on_save"]>[0]>;

type RenderGammeAssigner = (params?: Omit<F.Gamme.AssignProps, "popup" | "on_validate">)
    => Promise<Parameters<F.Gamme.AssignProps["on_validate"]>[0]>;

type RenderGammeForm = (params?: Omit<F.Gamme.FormProps, "popup" | "on_validate">)
    => Promise<Parameters<F.Gamme.FormProps["on_validate"]>[0]>;

type RenderReportMailForm = (params?: Omit<F.Missions.SendMailFormProps, "popup" | "on_validate">)
    => Promise<Parameters<F.Missions.SendMailFormProps["on_validate"]>[0]>;

type RenderStandALoneTicketSigner = (params?: Omit<ET.StandaloneTickerSignerProps, "quit" | "on_signed" | "popUp">)
    => Promise<Parameters<ET.StandaloneTickerSignerProps["on_signed"]>[0]>;

type RenderAlarmMailing = (params?: Omit<F.DataSets.AlarmMailingProps, "popup" | "onQuit">) => Promise<void>;

type RenderSearchContext = (params?: Omit<N.SearchContextProps, "portfolio" | "onSelect" | "select_many">) => Promise<{
    /** Id of the selected item */
    id: Parameters<N.SearchContextProps["onSelect"]>[0];
    /** Name of the selected item */
    name: Parameters<N.SearchContextProps["onSelect"]>[1];
    /** Type of the selected item */
    type: Parameters<N.SearchContextProps["onSelect"]>[2];
    /** If the selected item is a building, the name of it's site */
    site?: Parameters<N.SearchContextProps["onSelect"]>[3];
}>;

type RenderSelectManyContext = (params?: Omit<N.SearchContextProps, "portfolio" | "onSelect" | "select_many">) => Promise<{
    /** A list of selected items and their names */
    items: Parameters<N.SearchContextProps["select_many"]>[0];
    /** The type of elements selected */
    panel: Parameters<N.SearchContextProps["select_many"]>[1];
}>;

type RenderBailForm = (params: Omit<F.Money.RentProps, "onQuit" | "popup">) => Promise<void>;
//#endregion

//#region PopUp RegDoc
interface RegDocModalProps extends Omit<RegDocProps, "onSave"> { title?: string };

export const renderRegDoc = (params?: RegDocModalProps, containerId?: string) => new Promise<null | { doc: T.RegDoc, action: "deleted" | "saved" }>(resolve => {
    if (!TB.validObject(params)) resolve(null);
    else {
        let { title, ...props } = params;
        let [render, dismount] = renderInContainer();

        if (render && dismount) {
            let onQuit = () => dismount(() => resolve(null));

            let onSave = (saved: T.RegDoc | null, deleted?: T.RegDoc) => dismount(() => {
                if (saved) resolve({ doc: saved, action: "saved" });
                else if (deleted) resolve({ doc: deleted, action: "deleted" });
                else resolve(null);
            });

            render(<BlankModal title={title} size="md" onQuit={onQuit}>
                <RegDocForm {...props} onSave={onSave} />
            </BlankModal>);
        }
        else resolve(null);
    }
});
//#endregion

//#region PopUp Forms
export const renderPopUpFormModal = <D,>(params?: Omit<F.PopUpFormProps<D>, "handleForm">) => new Promise<T.Submission<D>>(resolve => {
    let [render, dismount] = renderInContainer();

    if (render && dismount) {
        if (!TB.validObject(params)) params = {};
        let handleForm: F.PopUpFormProps<D>["handleForm"] = sub => dismount(() => resolve(sub));
        render(<F.PopUpForm {...params} handleForm={handleForm} />);
    }
    else resolve(null);
});
//#endregion

//#region Pop Up Notes
type PopUpNote = F.NoteManagerProps & Omit<BlankModalFnProps, "renderContent" | "children">;

export const renderNoteModal = (params?: PopUpNote) => new Promise<number>(resolve => {
    if (TB.validObject(params) && TB.mongoIdValidator(params.origin)) {
        let note_amount_difference = 0;
        let { title = TC.GLOBAL_NOTES, defaultSubject, origin, ...props } = params;
        renderBlankModal({
            ...props,
            title,
            children: <F.NoteManager
                origin={origin}
                defaultSubject={defaultSubject}
                on_new_note={() => note_amount_difference++}
                on_delete_note={() => note_amount_difference--}
            />
        })
            .then(() => resolve(note_amount_difference));
    }
    else resolve(null);
});
//#endregion

//#region New Form
export const renderFormModal = <D,>(params: Omit<F.FormProps<D>, "modal" | "onSave">) => new Promise<T.Submission<D>>(resolve => {
    let [render, dismount] = renderInContainer();

    if (render && dismount) {
        if (!TB.validObject(params)) params = {};
        let handleForm: F.FormProps<D>["onSave"] = (sub) => dismount(() => resolve(sub || null));
        render(<F.Form {...params} modal onSave={handleForm} />);
    }
    else resolve(null);
});
//#endregion

/**
 * Render an event form modal
 */
export const renderEventFormModal = (params: Omit<EventFormProps, "popUp" | "onSave" | "onRemove">) => new Promise<EventFormResults | null>(resolve => {
    const [render, dismount] = renderInContainer();

    if (render && dismount && params?.event) {
        if (!Array.isArray(params.actions)) params.actions = [];

        let onRemove: EventFormProps["onRemove"] = (event, actions) => dismount(() => resolve({ event, type: "remove", actions }));
        let onSave: EventFormProps["onSave"] = event => dismount(() => event ? resolve({ event, type: "add", actions: [] }) : resolve(null));

        let navigate: EventFormProps["navigate"] = (...arg) => dismount(() => {
            resolve(null);
            params.navigate?.(arg[0], arg[1]);
        });

        render(<EventForm {...params} navigate={navigate} popUp onSave={onSave} onRemove={onRemove} />);
    }
    else resolve(null);
});

/**
 * Render a dataset form modal
 */
export const renderDataFormModal: RenderDataFormModal = params => new Promise(resolve => {
    const [render, dismount] = renderInContainer();

    if (render && dismount && TB.mongoIdValidator(params?.origin)) {
        const dataset_promise = new Promise<T.DataSet>((resolve, reject) => {
            if (params.dataset) resolve(params.dataset);
            else if (params.dataset_id) S.getDataset(params.dataset_id)
                .then(({ data }) => resolve(data))
                .catch(reject);
            else resolve(undefined);
        });

        const quit: F.DataSets.DataFormProps["modalProps"]["onQuit"] = () => dismount(() => resolve(null));
        const save: F.DataSets.DataFormProps["onSave"] = dataset => dismount(() => resolve(dataset || null));

        dataset_promise.then(dataset => {
            render(<F.DataSets.DataForm
                {...params}
                popup
                onSave={save}
                dataset={dataset}
                modalProps={{ ...params?.modalProps, onQuit: quit }}
            />);
        })
            .catch(e => {
                Alerts.loadError(e);
                params?.modalProps?.onQuit?.();
            });
    }
    else resolve(null);
});

/**
 * Render an Alarm form modal
 */
export const renderAlarmFormModal = (params: Omit<F.DataSets.AlarmFormProps, "popup" | "onSave">) => new Promise<Parameters<F.DataSets.AlarmFormProps["onSave"]>[0]>(resolve => {
    const [render, dismount] = renderInContainer();

    if (render && dismount && Array.isArray(params.datasets) /* && params.datasets.length > 0 */) {
        const save: F.DataSets.AlarmFormProps["onSave"] = alarm => dismount(() => resolve(alarm || null));
        const quit: F.DataSets.AlarmFormProps["modalProps"]["onQuit"] = () => dismount(() => resolve(null));

        render(<F.DataSets.AlarmForm
            {...params}
            popup
            onSave={save}
            modalProps={{ ...params?.modalProps, onQuit: quit }}
        />);
    }
    else resolve(null);
});

/**
 * Render a manual entry form modal
 */
export const renderEntryFormModal = (params: Omit<F.DataSets.EntryFormProps, "popup" | "onSubmit">) => new Promise<Parameters<F.DataSets.EntryFormProps["onSubmit"]>[0]>(resolve => {
    const [render, dismount] = renderInContainer();

    if (render && dismount && params.dataset) {
        const save: F.DataSets.EntryFormProps["onSubmit"] = entry => dismount(() => resolve(entry || null));
        const quit: F.DataSets.EntryFormProps["modalStyle"]["onQuit"] = () => dismount(() => resolve(null));

        render(<F.DataSets.EntryForm
            {...params}
            popup
            onSubmit={save}
            modalStyle={{ ...params?.modalStyle, onQuit: quit }}
        />);
    }
    else resolve(null);
});

/**
 * Render a dataset chart modal
 */
export const renderDataChartModal = (params: Omit<F.DataSets.DataChartProps, "popup">) => new Promise<void>(resolve => {
    const [render, dismount] = renderInContainer();

    if (render && dismount) {
        const quit: F.DataSets.DataFormProps["modalProps"]["onQuit"] = () => dismount(() => resolve(null));

        render(<F.DataSets.DataChart
            {...params}
            popup
            modalProps={{ ...params?.modalProps, onQuit: quit }}
        />);
    }
    else resolve(null);
});

/**
 * Render a portfolio form modal
 */
export const renderPortfolioForm = (params?: Omit<PortfolioFormProps, "onSubmit">) => new Promise<Parameters<PortfolioFormProps["onSubmit"]>[0]>(resolve => {
    const [render, dismount] = renderInContainer();

    if (render && dismount) {
        const onQuit = () => dismount(() => resolve(null));
        const onSubmit: PortfolioFormProps["onSubmit"] = portfolio => dismount(() => resolve(portfolio));

        let title = params?.submission?._id ? TC.NAV_EDIT_PORTFOLIO : TC.NAV_ADD_PORTFOLIO;

        render(<BlankModal isFullScreen title={title} onQuit={onQuit} >
            <PortfolioForm {...params} onSubmit={onSubmit} />
        </BlankModal>);
    }
    else resolve(null);
});

/**
 * Render a list of context options
 */
export const renderSearchContext: RenderSearchContext = params => new Promise(resolve => {
    const [render, dismount] = renderInContainer();
    if (render && dismount) {
        const onQuit = () => dismount?.(() => resolve(null));
        const onSelect: N.SearchContextProps["onSelect"] = (id, name, type, site) => dismount(() => resolve({ id, name, type, site }));
        render(<BlankModal isFullScreen title={TC.NAV_RECENT_SELECTION} onQuit={onQuit}>
            <N.SearchContext {...params} onSelect={onSelect} />
        </BlankModal>);
    }
    else resolve(null);
});

/** 
 * Render a list of context options, to choose many between them
*/
export const renderSelectManyContext: RenderSelectManyContext = params => new Promise(resolve => {
    const [render, dismount] = renderInContainer();
    if (render && dismount) {
        const onQuit = () => dismount?.(() => resolve(null));
        const select_many: N.SearchContextProps["select_many"] = (items, panel) => dismount(() => resolve({ items, panel }));
        render(<BlankModal isFullScreen title={TC.NAV_RECENT_SELECTION} onQuit={onQuit}>
            <N.SearchContextManyBuffer {...params} select_many={select_many} />
        </BlankModal>);
    }
    else resolve(null);
});

/**
 * Render a mission form
 */
export const renderMissionForm: RenderMissionForm = params => new Promise(resolve => {
    const [render, dismount] = renderInContainer();
    if (render && dismount) {
        const onQuit = () => dismount(() => resolve(null));
        const onSave: F.Missions.MissionFormProps["onSave"] = mission => mission !== "error" && dismount(() => resolve(mission));
        render(<F.Missions.MissionForm {...params} popup onQuit={onQuit} onSave={onSave} />);
    }
    else resolve(null);
});

/**
 * Render a Mission report note modal
 */
export const renderMissionReportModal = (params: Omit<F.Missions.Tasks.ReportFormProps, "onConfirm">) => new Promise<Parameters<F.Missions.Tasks.ReportFormProps["onConfirm"]>[0]>(resolve => {
    const [render, dismount] = renderInContainer();

    if (render && dismount) {
        const onSave: F.Missions.Tasks.ReportFormProps["onConfirm"] = note => dismount(() => resolve(note));
        render(<F.Missions.Tasks.ReportForm {...params} popup onConfirm={onSave} />);
    }
    else resolve(null);
});

/**
 * Render a form to manage the rents of a cell
 */
export const renderBailForm: RenderBailForm = params => new Promise(resolve => {
    const [render, dismount] = renderInContainer();
    if (render && dismount) {
        const onQuit = () => dismount(() => resolve(null));
        render(<F.Money.Rent {...params} popup onQuit={onQuit} />);
    }
    else resolve(null);
});

/**
 * Render the modal to pick & choose values to ignore from a list
 */
export const renderValueIgnorer: RenderValueIgnorer = params => new Promise(resolve => {
    const [render, dismount] = renderInContainer();
    if (render && dismount) {
        // No values to ignore, no need to render the modal
        if (params?.values?.length === 0) resolve([]);
        else {
            const onQuit = () => dismount(() => resolve(null));
            const onSave: C.Import.ValueIgnorerProps["onSave"] = values => dismount(() => resolve(values));
            render(<C.Import.ValueIgnorer {...params} popup onQuit={onQuit} onSave={onSave} />);
        }
    }
    else resolve(null);
});

/** 
 * Render the modal to create or assign emplacements for an equip import
 */
export const renderEmplacementMapper: RenderEmplacementMapper = params => new Promise(resolve => {
    const [render, dismount] = renderInContainer();

    if (render && dismount) {
        const onQuit = () => dismount(() => resolve(null));
        const onSave: C.Import.EmplacementMapperProps["onSave"] = todo => dismount(() => resolve(todo));
        render(<C.Import.EmplacementMapper {...params} popup onQuit={onQuit} onSave={onSave} />);
    }
    else resolve(null);
});

/** 
 * Render the modal to assign equipments "constant" values, and the brand / models creation
 */
export const renderValueMapper: RenderValueMapper = params => new Promise(resolve => {
    const [render, dismount] = renderInContainer();
    if (render && dismount) {
        const onQuit = () => dismount(() => resolve(null));
        const onSave: C.Import.ValueMapperProps["onSave"] = todo => dismount(() => resolve(todo));
        render(<C.Import.ValueMapper {...params} popup onQuit={onQuit} onSave={onSave} />);
    }
    else resolve(null);
});

/**
 * Render the modal to manage the mailing of the alarms
 */
export const renderAlarmMailing: RenderAlarmMailing = params => new Promise(resolve => {
    const [render, dismount] = renderInContainer();
    if (render && dismount) {
        const on_quit: F.DataSets.AlarmMailingProps["onQuit"] = () => dismount(() => resolve(null));
        render(<F.DataSets.AlarmMailing {...params} onQuit={on_quit} popup />);
    }
    else resolve();
});

/** Render the Tag/Station Mapper in a modal */
export const renderTagMapper: RenderTagMapper = params => new Promise(resolve => {
    const [render, dismount] = renderInContainer();
    if (render && dismount) {
        const quit: F.DataSets.TagMapProps["quit"] = () => dismount(() => resolve(null));
        const validate: F.DataSets.TagMapProps["on_validate"] = datasets => dismount(() => resolve(datasets));
        render(<F.DataSets.TagMap {...params} popup on_validate={validate} quit={quit} />);
    }
    else resolve(null);
});

/** Render the Alert Bubble form in a modal */
export const renderAlertBubbleForm: RenderAlertBubbleForm = params => new Promise(resolve => {
    const [render, dismount] = renderInContainer();
    if (render && dismount) {
        const on_save: F.InfosBubbles.FormProps["on_save"] = bubble => dismount(() => resolve(bubble || null));
        render(<F.InfosBubbles.Form {...params} popup on_save={on_save} />);
    }
    else resolve(null);
});

/** Render the form to choose which gamme to reassign into which */
export const renderGammeAssigner: RenderGammeAssigner = params => new Promise(resolve => {
    const [render, dismount] = renderInContainer();
    if (render && dismount) {
        const on_save: F.Gamme.AssignProps["on_validate"] = params => dismount(() => resolve(params || null));
        render(<F.Gamme.Assign {...params} popup on_validate={on_save} />);
    }
    else resolve(null);
});

/** Render the form to create or edit a gamme */
export const renderGammeForm: RenderGammeForm = params => new Promise(resolve => {
    const [render, dismount] = renderInContainer();
    if (render && dismount) {
        const on_validate: F.Gamme.FormProps["on_validate"] = params => dismount(() => resolve(params || null));
        render(<F.Gamme.Form {...params} popup on_validate={on_validate} />);
    }
    else resolve(null);
});

/** Render the form to send out a version of a mission report */
export const renderReportMailForm: RenderReportMailForm = params => new Promise(resolve => {
    const [render, dismount] = renderInContainer();
    if (render && dismount) {
        const on_validate: F.Missions.SendMailFormProps["on_validate"] = params => dismount(() => resolve(params || null));
        render(<F.Missions.SendMailForm {...params} popup on_validate={on_validate} />);
    }
    else resolve(null);
});

/** Renders a version of the ticket signer to close a ticket without an event */
export const renderStandALoneTicketSigner: RenderStandALoneTicketSigner = params => new Promise(resolve => {
    const [render, dismount] = renderInContainer();
    if (render && dismount) {
        const quit: ET.StandaloneTickerSignerProps["quit"] = () => dismount(() => resolve(null));
        const on_signed: ET.StandaloneTickerSignerProps["on_signed"] = ticket => dismount(() => resolve(ticket || null));
        render(<ET.StandaloneTickerSigner {...params} popUp on_signed={on_signed} quit={quit} />);
    }
    else resolve(null);
});