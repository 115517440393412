import React from 'react';
import { Link } from 'react-router-dom';

type LogoProps = {
  /** HTML Id of the 'img' tag */
  id?: string;
  /** Text to be displayed under the logo */
  label?: string;
  /** The source of the image */
  source: string;
  /** Width of the logo */
  width?: number;
  /** Additional classes for the container of the logo */
  className?: string;
  /** Classes for the label 'span' */
  textClass?: string;
  /** Additional Layout of the logo, based on it's emplacement */
  at?: 'navbar-vertical' | 'navbar-top' | 'auth';
}

const Logo: React.FC<LogoProps> = ({ at = 'auth', width = 150, ...props }) => {

  const containerClass = React.useMemo(() => {
    let classes = ["d-flex"];
    if (at === 'navbar-top') classes.push("align-items-center");
    else if (at === 'auth') classes.push("flex-center fw-bolder fs-5 mb-4");
    else if (at === 'navbar-vertical') classes.push("align-items-center py-3");
    if (props.className) classes.push(props.className);
    return classes.join(" ");
  }, [at, props.className]);

  const linkClasses = React.useMemo(() => {
    let classes = ["text-decoration-none"];
    if (at !== "auth") classes.push("navbar-brand text-left");
    return classes.join(" ");
  }, [at]);

  return <Link to="/" className={linkClasses}>
    <div className={containerClass}>
      <img id={props.id} className="me-2" src={props.source} alt="" width={width} />
      {props.label && <span className={'font-sans-serif' + (props.textClass || "")}>{props.label}</span>}
    </div>
  </Link>;
};

export default Logo;