import React from "react";
import * as L from "./MainLayout";
import * as C from "../Components";
import * as EL from "./ErrorsLayout";
import * as DOM from "react-router-dom";

const RouterSwitch: React.FC<Record<"children", React.ReactNode>> = props => <DOM.BrowserRouter basename={process.env.PUBLIC_URL}>
    {props.children}
    <DOM.Routes>
        {/* Error Layout */}
        <DOM.Route element={<EL.ErrorLayout />}>
            {/* The URL entered does not exists */}
            <DOM.Route path="errors/404" element={<EL.Error404 />} />
            {/* The resources is not available to the user */}
            <DOM.Route path="/unavailable" element={<EL.Unavailable />} />
        </DOM.Route>

        {/* IFrames Layout */}
        <DOM.Route element={<L.Frame />}>
            {/* An IFrame containing an equipment sheet */}
            <DOM.Route path="/equipment/sheet" element={<C.Dashboard.EquipSheet.SheetPage />} />
            {/* An IFrame containing visual data graphs about an alert */}
            <DOM.Route path="/alerts/graphs" element={<C.Dashboard.AlertDashboardsStandalone />} />
        </DOM.Route>

        {/* Card Layout */}
        <DOM.Route element={<L.Card />}>
            <DOM.Route path="/login" element={<C.Login.Login />} />
        </DOM.Route>

        {/* Full Width Layout */}
        <DOM.Route element={<L.Main fluid />}>
            {/* Tree Visualization */}
            <DOM.Route path="/tree" element={<C.Dia.Dia />} />
            {/* Matrix Page */}
            <DOM.Route path="/matrix" element={<C.Matrix.MatrixTable />} />
            {/* Calendar Page */}
            <DOM.Route path="/planning" element={<C.Calendar.Planner />} />
            {/* Access Page */}
            <DOM.Route path="/access" element={<C.Access.AccessUsers />} />
            {/* Site Editor */}
            <DOM.Route path="/siteForm" element={<C.OldForms.SiteForm />} />
            {/* CDM Mission */}
            <DOM.Route path="/mission/cdm" element={<C.Mission.CDM.Main />} />
            {/* NRJ Report Mission */}
            <DOM.Route path="/mission/nrj_report" element={<C.Mission.NRJ.Main />} />
            {/* Red Flag Mission */}
            <DOM.Route path="/mission/red_flag" element={<C.Mission.RED_FLAG.Main />} />
            {/* Reg Mission */}
            <DOM.Route path="/mission/reg" element={<C.Mission.REG.Main />} />
            {/* EPRA Mission */}
            <DOM.Route path="/mission/epra" element={<C.Mission.EPRA.Main />} />
            {/* RSC01 Mission */}
            <DOM.Route path="/mission/rsc01" element={<C.Mission.RSC01.Main />} />
            {/* EU Taxonomy mission */}
            <DOM.Route path="mission/eu_taxonomy" element={<C.Mission.EU_Taxonomy.Main />} />
            {/* Ticket Editor */}
            <DOM.Route path="/ticket/event/:id" element={<C.QR.EventCloser />} />
            {/* Test JS Report Page */}
            <DOM.Route path="/template" element={<C.Dashboard.TestDashboard />} />
            {/* Translation Table */}
            <DOM.Route path="/admin/translations" element={<C.Table.Translations />} />
            {/* Store Equipment Table */}
            <DOM.Route path="/storeEquipement" element={<C.Table.EquipStoreContext />} />
            {/* Ticket Dashboard */}
            <DOM.Route path="/dashboard/tickets" element={<C.Dashboard.TicketDashboard />} />
            {/* An admin's table of all created missions */}
            <DOM.Route path="/admin/history" element={<C.Table.Mission />} />
            {/* An admin table of all the station / Tags available, for data correction */}
            <DOM.Route path="/admin/data" element={<C.Table.DataCorrectContext />} />
            {/* An admin's table of system's alerts */}
            <DOM.Route path="/admin/announcements" element={<C.Table.Announcements />} />
            {/* ESG Dashboard */}
            <DOM.Route path="/dash/env" element={<C.Dashboard.Environment />} />

            {/* Tables */}
            <DOM.Route path="/table/options" element={<C.Table.Options />} />
            <DOM.Route path="/table/notes" element={<C.Table.NotesContext />} />
            <DOM.Route path="/table/reg" element={<C.Table.RegDocsContext />} />
            <DOM.Route path="/table/capex" element={<C.Table.CapexContext />} />
            <DOM.Route path="/table/alarms" element={<C.Table.AlarmContext />} />
            <DOM.Route path="/table/alerts" element={<C.Table.AlertContext />} />
            <DOM.Route path="/table/reglementation" element={<C.Reg.RegPage />} />
            <DOM.Route path="/table/tickets" element={<C.Table.TicketsContext />} />
            <DOM.Route path="/table/contracts" element={<C.Table.ContractsTable />} />
            <DOM.Route path="/table/doc_types" element={<C.Table.RegActionTable />} />
            <DOM.Route path="/table/remarques" element={<C.Table.RemarquesContext />} />
            <DOM.Route path="/table/equipments" element={<C.Table.EquipmentContext />} />
            <DOM.Route path="/table/maintenance" element={<C.Table.MaintenanceGamme />} />
            <DOM.Route path="/table/equipmentGammes" element={<C.Table.GammesContext />} />
            <DOM.Route path="/table/entity" element={<C.Form.DataSets.DataPanelContext />} />
            <DOM.Route path="/table/emplacements" element={<C.Table.EmplacementContext />} />

            {/* Profile Page */}
            <DOM.Route path="/profile" element={<C.Profile.Profile />} />
            {/* Building Dashboard */}
            <DOM.Route path="/card_id" element={<C.Dashboard.IDCard />} />
            {/* Admin Tools */}
            <DOM.Route path="/admin/board" element={<C.Profile.BoardAdmin />} />
            {/* Building Editor */}
            <DOM.Route path="/editor/building" element={<C.Build.BuildEditorContext />} />
            {/* Enseignes Dashboard */}
            <DOM.Route path="/enseignes" element={<C.Dashboard.RentersDashboard />} />
            {/* Context Dashboard */}
            <DOM.Route path="/portfolio" element={<C.Dashboard.PortfolioIdCard />} />
            {/* Action Plan Simulator */}
            <DOM.Route path="/simulator/actionPlan" element={<C.Dashboard.ActionPlanSimulator />} />

            {/* Form Building & Custom Formio Instance */}
            <DOM.Route path="/formBuilding" element={<C.Formio.FormManager />} />
            <DOM.Route path="/forms/:type/:action" element={<C.Formio.FormViewer />} />
            <DOM.Route path="/forms/:type/:formId/:action" element={<C.Formio.FormViewer />} />
            <DOM.Route path="/forms/submission/:formId" element={<C.Formio.SubmissionManager />} />
            <DOM.Route path="formBuilding/forms/:type/:action" element={<C.Formio.FormViewer />} />

            {/* OLD - GMAO / Tickets / Entity / ... (MAYBE SHOULD BE DELETED) */}
            <DOM.Route path="/ticket" element={<C.QR.Ticket />} />
            <DOM.Route path="/QR/compteur/:id" element={<C.QR.Entity />} />
            <DOM.Route path="/QR/:buildingId/:equipmentId" element={<C.QR.QRCode />} />

            {/* Home Pages */}
            <DOM.Route path='/home' element={<C.Dashboard.HomeCard />} />
            <DOM.Route path='/' element={<C.Dashboard.HomeCard />} />

            {/* Fallback */}
            <DOM.Route path="/logout" element={<DOM.Navigate to="/" replace />} />
            <DOM.Route path="*" element={<DOM.Navigate to="/errors/404" replace />} />
        </DOM.Route>
    </DOM.Routes>
</DOM.BrowserRouter>;

export default RouterSwitch;